import { pdf } from "@react-pdf/renderer";
import { useState } from "react";
import api from "../../services/api";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { PrimaryButton } from "../../components/Button";


const makeApiRequest = async (
  path: string,
  body?: any
): Promise<any> => {

  const headers = new Headers();
  headers.append("Accept", "application/json");
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${api.getSessionToken()}`);

  try {
    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body),
      redirect: "follow" as RequestRedirect
    };
    const response = await fetch(path, requestOptions);

    if (!response.ok) {
      const message = await api.parseErrorMessage(response);
      throw new Error(`${response.status}: ${response.statusText}. ${message}`);
    }
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    console.warn(`Failed request ${path}`, err);
    throw err;
  }
};


const exportPdf = async (projectId: number, base64: string ) => {
  let url = "https://lcc.eurac.edu/BIM-P-integration/api/v1/export-resut-pdf"
  const payload = {
      projectId: projectId,
      base64: base64
  };
  const response = await makeApiRequest(url, payload);
  if (!response[0]) {
    throw new Error(`Export pdf: ${response[0]}`);
  }
  return response;
};


const blobToBase64 = async (pdf_blob: Blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(pdf_blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};


const doExport = async (projectId: number, document: any) => {
  var pdf_doc = pdf(document);
  var pdf_blob = await pdf_doc.toBlob();
  var dataUrl = await blobToBase64(pdf_blob);
  var base64 = (dataUrl as string).split(',')[1];
  await exportPdf(projectId, base64);
};


type Props = {
  document: any;
  pdfName: string;
  loadingText?: string;
  projectId: number;
};

export const PDFExportButton: React.FC<Props> = (props) => {

  const { t } = useTranslation();
  const toast = useToast();
  const [loading, setLoading] = useState(false);


  if (loading) {
    return <a>{"Loading..."}</a>;
  }

  return (
    <PrimaryButton
      marginTop={"1rem"}
      onClick={ async () => {
        setLoading(true);

        try {
          await doExport(props.projectId, props.document);

          toast({
            title: t("bimp.exportSuccess"),
            status: "success",
            duration: 4000,
            isClosable: true,
          });
        } catch (e) {
          toast({
            title: t("bimp.exportError"),
            status: "error",
            duration: 4000,
            isClosable: true,
          });
        } finally {
          setLoading(false);
        }
      }}
    >
        {"Export PDF"}
    </PrimaryButton>
  );
};
