import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../hooks/useStores";
import { Link, Redirect } from "react-router-dom";

import { ChevronDownIcon } from "@chakra-ui/icons";
import styled from "styled-components";
import { AiFillFolder } from "react-icons/ai";
import { Fancade, Project } from "../../../types";
import { GeneralInfoTable } from "./tables/GeneralInfoTable";
import { DetailsTable } from "../summary/details/DetailsTable";
import {
  buildingSiteToTable,
  endOfLifeToTable,
  fancadeProjectToTable,
  fancadeTechnicalProjectToTable,
  getActualizationComparisonRows,
  getComparisonRows,
  getKpiComparisonRows,
  getKpiRows,
  maintenanceToTable,
  productionToTable,
  serviceLifeToTable,
  transportToTable,
} from "../../../services/tabToTable";
import {
  getActualizationLifeServiceRows,
  getActualizationMaintenanceRows,
  getLCCInvestmentPerPhasePlotValues,
} from "../../../services/utils";
import { PDFDownloadLink } from "../../PDFDownloadLink";
import { PDFDocument } from "../../PDFDocument";
import { ProjectPDF } from "../../ProjectPDF";
import { ReportPDF } from "../summary/report/ReportPDF";
import Plot from "react-plotly.js";

export const Comparison: React.FC = observer((props) => {
  const { t } = useTranslation();
  const { session, ui, projects } = useStores();
  ui.setActiveScreen("projects");

  const [fancadeIndex1, setFancadeIndex1] = useState(0);
  const [fancadeIndex2, setFancadeIndex2] = useState(0);

  const lccInvestmentPerPhasePlotTitle = t("plotTitles.lccInvestmentPerPhase");
  const lccInvestmentPerPhasePlotLabels = [
    t("tablesVoices.report.priceForPhase.project"),
    t("tablesVoices.report.priceForPhase.fancadeProduction"),
    t("tablesVoices.report.priceForPhase.transportToConstructionSite"),
    t("tablesVoices.report.priceForPhase.installation"),

    t("tablesVoices.report.priceForPhase.serviceLife"),
    t("tablesVoices.report.priceForPhase.maintenance"),
    t("tablesVoices.report.priceForPhase.disposal"),
    t("tablesVoices.report.priceForPhase.recycling"),
  ];

  const isProjectSelected = (element: Project, index: any, array: any) => {
    return projects.selectedProjectsIds.has(element.id);
  };

  const selectedProjects = useMemo(() => {
    return [...projects.projects.filter(isProjectSelected)];
  }, [projects]);

  useEffect(() => {
    projects
      .getProjectFancades(parseInt(selectedProjects[0].id.toString()))
      .then((fancades: Fancade[]) => {
        if (selectedProjects[0]) selectedProjects[0].fancades = fancades;
      });
    projects
      .getProjectFancades(parseInt(selectedProjects[1].id.toString()))
      .then((fancades: Fancade[]) => {
        if (selectedProjects[1]) selectedProjects[1].fancades = fancades;
      });
  }, [projects, selectedProjects]);

  if (selectedProjects.length != 2) return <Redirect to={"/"} />;
  if (session.user)
    return (
      <Stack w={"90%"} marginLeft={"4%"} overflowY={"scroll"}>
        <Flex paddingTop={"2rem"}>
          <Spacer />
          {(selectedProjects[0].fancades || selectedProjects[1].fancades) &&
            ((selectedProjects[0].fancades &&
              selectedProjects[0].fancades.length > 0) ||
              (selectedProjects[1].fancades &&
                selectedProjects[1].fancades.length > 0)) && (
              <Menu>
                {({ isOpen }) => (
                  <>
                    <MenuButton isActive={isOpen} as={Box} marginRight={"1rem"}>
                      <Text fontSize={"xl"}>
                        {t("buttons.export")}
                        <ChevronDownIcon />
                      </Text>
                    </MenuButton>
                    <MenuList>
                      <Link
                        to={{
                          pathname: "/download",
                          state: {
                            pdf: (
                              <ReportPDF
                                project1={selectedProjects[0]}
                                project2={selectedProjects[1]}
                                fancadeIndex1={fancadeIndex1}
                                fancadeIndex2={fancadeIndex2}
                              />
                            ),
                            redirectUrl:
                              "/user/" + session.user?.id + "/comparison",
                          },
                        }}
                      >
                        <MenuItem>{t("buttons.pdf")}</MenuItem>
                      </Link>
                    </MenuList>
                  </>
                )}
              </Menu>
            )}
        </Flex>

        <GeneralInfoTable />
        {(!selectedProjects[0].fancades && !selectedProjects[1].fancades) ||
        (selectedProjects[0].fancades &&
          selectedProjects[0].fancades.length == 0 &&
          selectedProjects[1].fancades &&
          selectedProjects[1].fancades.length == 0) ? (
          <Text fontSize={"2xl"} textAlign={"center"} paddingTop={"3rem"}>
            {t("errors.noFancades")}
          </Text>
        ) : (
          <>
            <Flex w={"100%"} paddingTop={"2rem"}>
              <Stack w={"49%"} marginRight={"1rem"}>
                <Text fontSize={"xl"}>
                  {t("selectFancade") + selectedProjects[0].id}
                </Text>
                <Select
                  value={fancadeIndex1}
                  onChange={(e) => setFancadeIndex1(+e.target.value)}
                >
                  {selectedProjects &&
                    selectedProjects[0].fancades &&
                    selectedProjects[0].fancades.map((fancade, index) => (
                      <option value={index}>
                        {t("fancade") + " #" + (+index + 1)}
                      </option>
                    ))}
                </Select>
              </Stack>
              <Stack w={"49%"} marginLeft={"1%"}>
                <Text fontSize={"xl"}>
                  {t("selectFancade") + selectedProjects[1].id}
                </Text>
                <Select
                  value={fancadeIndex2}
                  onChange={(e) => setFancadeIndex2(+e.target.value)}
                >
                  {selectedProjects &&
                    selectedProjects[1].fancades &&
                    selectedProjects[1].fancades.map((fancade, index) => (
                      <option value={index}>
                        {t("fancade") + " #" + (+index + 1)}
                      </option>
                    ))}
                </Select>
              </Stack>
            </Flex>
            <Flex w={"100%"} justifyContent={"center"} alignItems={"center"}>
              <Flex w={"50%"}>
                {getLCCInvestmentPerPhasePlotValues(
                  selectedProjects[0],
                  fancadeIndex1
                ).length > 0 ? (
                  <Plot
                    data={[
                      {
                        values: getLCCInvestmentPerPhasePlotValues(
                          selectedProjects[0],
                          fancadeIndex1
                        ),
                        labels: lccInvestmentPerPhasePlotLabels,
                        type: "pie",
                      },
                    ]}
                    layout={{
                      title: lccInvestmentPerPhasePlotTitle,
                      height: 500,
                      width: 500,
                      margin: {
                        t: 200,
                      },
                    }}
                  />
                ) : (
                  <Text textAlign={"center"} fontSize={"2xl"}>
                    {t("errors.noValuesPlot")}
                  </Text>
                )}
              </Flex>
              <Flex w={"50%"}>
                {getLCCInvestmentPerPhasePlotValues(
                  selectedProjects[1],
                  fancadeIndex2
                ).length > 0 ? (
                  <Plot
                    data={[
                      {
                        values: getLCCInvestmentPerPhasePlotValues(
                          selectedProjects[1],
                          fancadeIndex2
                        ),
                        labels: lccInvestmentPerPhasePlotLabels,
                        type: "pie",
                      },
                    ]}
                    layout={{
                      title: lccInvestmentPerPhasePlotTitle,
                      width: 500,
                      margin: {
                        t: 200,
                      },
                    }}
                  />
                ) : (
                  <Text textAlign={"center"} fontSize={"2xl"}>
                    {t("errors.noValuesPlot")}
                  </Text>
                )}
              </Flex>
            </Flex>
            <DetailsTable
              style={{ marginBottom: "2rem" }}
              columnsTitles={[
                t("titles.details.fancadeTechnicalProject"),
                "#" + selectedProjects[0].id,
                "#" + selectedProjects[1].id,
              ]}
              rows={getComparisonRows(
                fancadeTechnicalProjectToTable(selectedProjects[0]),
                fancadeTechnicalProjectToTable(selectedProjects[1]),
                fancadeIndex1,
                fancadeIndex2
              )}
            />
            <DetailsTable
              style={{ marginBottom: "2rem" }}
              columnsTitles={[
                t("titles.details.fancadeProject"),
                "#" + selectedProjects[0].id,
                "#" + selectedProjects[1].id,
              ]}
              rows={getComparisonRows(
                fancadeProjectToTable(selectedProjects[0]),
                fancadeProjectToTable(selectedProjects[1]),
                fancadeIndex1,
                fancadeIndex2
              )}
            />
            <DetailsTable
              style={{ marginBottom: "2rem" }}
              columnsTitles={[
                t("titles.details.fancadeProduction"),
                "#" + selectedProjects[0].id,
                "#" + selectedProjects[1].id,
              ]}
              rows={getComparisonRows(
                productionToTable(selectedProjects[0]),
                productionToTable(selectedProjects[1]),
                fancadeIndex1,
                fancadeIndex2
              )}
            />
            <DetailsTable
              style={{ marginBottom: "2rem" }}
              columnsTitles={[
                t("titles.details.transport"),
                "#" + selectedProjects[0].id,
                "#" + selectedProjects[1].id,
              ]}
              rows={getComparisonRows(
                transportToTable(selectedProjects[0]),
                transportToTable(selectedProjects[1]),
                fancadeIndex1,
                fancadeIndex2
              )}
            />
            <DetailsTable
              style={{ marginBottom: "2rem" }}
              columnsTitles={[
                t("titles.details.buildingSite"),
                "#" + selectedProjects[0].id,
                "#" + selectedProjects[1].id,
              ]}
              rows={getComparisonRows(
                buildingSiteToTable(selectedProjects[0]),
                buildingSiteToTable(selectedProjects[1]),
                fancadeIndex1,
                fancadeIndex2
              )}
            />
            <DetailsTable
              style={{ marginBottom: "2rem" }}
              columnsTitles={[
                t("titles.details.serviceLife"),
                "#" + selectedProjects[0].id,
                "#" + selectedProjects[1].id,
              ]}
              rows={getComparisonRows(
                serviceLifeToTable(selectedProjects[0]),
                serviceLifeToTable(selectedProjects[1]),
                fancadeIndex1,
                fancadeIndex2
              )}
            />
            <DetailsTable
              style={{ marginBottom: "2rem" }}
              columnsTitles={[
                t("titles.details.maintenance"),
                "#" + selectedProjects[0].id,
                "#" + selectedProjects[1].id,
              ]}
              rows={getComparisonRows(
                maintenanceToTable(selectedProjects[0]),
                maintenanceToTable(selectedProjects[1]),
                fancadeIndex1,
                fancadeIndex2
              )}
            />
            <Text fontSize={"2xl"}>
              {t("tablesVoices.costsActualization.serviceOfLife")}
            </Text>
            <DetailsTable
              style={{ marginBottom: "2rem" }}
              columnsTitles={[
                t("tablesVoices.costsActualization.year"),
                "#" +
                  selectedProjects[0].id +
                  " " +
                  t("tablesVoices.costsActualization.value"),
                "#" +
                  selectedProjects[0].id +
                  " " +
                  t("tablesVoices.costsActualization.total"),
                "#" +
                  selectedProjects[1].id +
                  " " +
                  t("tablesVoices.costsActualization.value"),
                "#" +
                  selectedProjects[1].id +
                  " " +
                  t("tablesVoices.costsActualization.total"),
              ]}
              rows={
                selectedProjects[0].fancades && selectedProjects[1].fancades
                  ? getActualizationComparisonRows(
                      getActualizationLifeServiceRows(
                        selectedProjects[0].fancades[fancadeIndex1]
                      ),
                      getActualizationLifeServiceRows(
                        selectedProjects[1].fancades[fancadeIndex2]
                      )
                    )
                  : []
              }
            />
            <Text fontSize={"2xl"}>
              {t("tablesVoices.costsActualization.maintenance")}
            </Text>
            <DetailsTable
              style={{ marginBottom: "2rem" }}
              columnsTitles={[
                t("tablesVoices.costsActualization.year"),
                "#" +
                  selectedProjects[0].id +
                  " " +
                  t("tablesVoices.costsActualization.value"),
                "#" +
                  selectedProjects[0].id +
                  " " +
                  t("tablesVoices.costsActualization.total"),
                "#" +
                  selectedProjects[1].id +
                  " " +
                  t("tablesVoices.costsActualization.value"),
                "#" +
                  selectedProjects[1].id +
                  " " +
                  t("tablesVoices.costsActualization.total"),
              ]}
              rows={
                selectedProjects[0].fancades && selectedProjects[1].fancades
                  ? getActualizationComparisonRows(
                      getActualizationMaintenanceRows(
                        selectedProjects[0].fancades[fancadeIndex1]
                      ),
                      getActualizationMaintenanceRows(
                        selectedProjects[1].fancades[fancadeIndex2]
                      )
                    )
                  : []
              }
            />
            <DetailsTable
              style={{ marginBottom: "2rem" }}
              columnsTitles={[
                t("titles.details.endOfLife"),
                "#" + selectedProjects[0].id,
                "#" + selectedProjects[1].id,
              ]}
              rows={getComparisonRows(
                endOfLifeToTable(selectedProjects[0]),
                endOfLifeToTable(selectedProjects[1]),
                fancadeIndex1,
                fancadeIndex2
              )}
            />
            <DetailsTable
              style={{ marginBottom: "2rem" }}
              columnsTitles={[
                "KPI",
                "#" + selectedProjects[0].id,
                "#" + selectedProjects[1].id,
              ]}
              rows={getKpiComparisonRows(
                getKpiRows(selectedProjects[0], fancadeIndex1),
                getKpiRows(selectedProjects[1], fancadeIndex2)
              )}
            />{" "}
          </>
        )}
      </Stack>
    );
  else return <Redirect to={"/"} />;
});

const StyledAiFillFolder = styled(AiFillFolder)`
  margin-left: 2em;
  margin-right: 0.5em;
`;

const StyledFlex = styled(Flex)`
  cursor: pointer;
`;
