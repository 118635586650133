import React, { useCallback } from "react";
import ReactDOM from "react-dom";
import { Button, Center, Flex, Stack, Text, useToast } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "./../../hooks/useStores";

import styled from "styled-components";
import theme from "./../../theme/theme";
import { LoginForm } from "../../components/LoginForm";
import { Redirect } from "react-router-dom";

export const Login: React.FC = observer(() => {
  const { t } = useTranslation();
  const { session } = useStores();

  const toast = useToast();

  const handleSubmit = useCallback(
    async (email: string, password: string) => {
      try {
        await session.login(email, password);
      } catch (err) {
        toast({
          title: t("errors.login.failedTitle"),
          description: t("errors.login.failedDescription"),
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    },
    [session]
  );

  if (session.isLogged) {
    return <Redirect to={"/"} />;
  }
  return (
    <Center bg={theme.colors.background} style={{ minHeight: "100vh" }}>
      <StyledExternalFlex
        bg={theme.colors.formBackground}
        rounded={"10"}
        justifyContent={"center"}
      >
        <LoginForm onSubmit={handleSubmit} isLoading={session.isSubmitting} />
      </StyledExternalFlex>
    </Center>
  );
});

const StyledExternalFlex = styled(Flex)`
  min-height: 60vh;
  min-width: 400px;
  width: 25vw;
`;
