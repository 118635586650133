import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";

import React from "react";
import { Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";
import theme from "../../../../theme/theme";
import { TableRow } from "../../../../types";

type Props = {
  columnsTitles: string[];
  rows: TableRow[];
  style?: any;
  colorValues?: boolean;
  valuesAlignment?: "left" | "right" | "center";
};

export const DetailsTable: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  let rows: any = [];
  let colSpan = 0;

  if (props.rows && props.rows.length > 0) {
    props.rows.map((row) => {
      if (row.name) {
        rows.push(
          <Tr>
            {row.name && row.name != "" && (
              <Td color={theme.colors.euracOrange} fontWeight={"semibold"}>
                {row.name}
              </Td>
            )}

            {row.values &&
              row.values.length > 0 &&
              row.values.map((value, index) => {
                let color: string = "";
                if (props.colorValues && index === 2) {
                  if (typeof value === "number") {
                    if (value > 0) color = "green";
                    if (value < 0) color = "red";
                  } else if (typeof value === "string") {
                    const tempValue = value.replace(/[^\d.-]/g, "");
                    if (!isNaN(tempValue as any)) {
                      if (+tempValue > 0) color = "green";
                      if (+tempValue < 0) color = "red";
                    }
                  }
                }
                return (
                  <Td
                    textAlign={props.valuesAlignment || "center"}
                    color={color} //If the value is a number, apply a color according to its sign
                    fontWeight={index === 2 ? "bold" : undefined}
                  >
                    {value}
                  </Td>
                );
              })}
          </Tr>
        );
      }
    });
  }
  if (rows.length == 0) {
    rows.push(
      <Tr>
        <Td textAlign={"center"} colSpan={3}>
          {t("errors.emptyTable")}
        </Td>
      </Tr>
    );
  }
  if (
    props.columnsTitles &&
    props.columnsTitles.length == 1 &&
    props.rows &&
    props.rows.length > 0
  )
    colSpan = props.rows[0].values.length + 1;

  return (
    <Flex w={"100%"} style={props.style}>
      <StyledTable variant={"striped"} colorScheme={"blackAlpha"}>
        {props.columnsTitles.length > 0 && (
          <Thead>
            <Tr>
              {props.columnsTitles?.map((title) => (
                <Th colSpan={colSpan} fontSize={"md"}>
                  {title}
                </Th>
              ))}
            </Tr>
          </Thead>
        )}

        <Tbody>{rows}</Tbody>
      </StyledTable>
    </Flex>
  );
};

const StyledTable = styled(Table)`
  margin-bot: 2%;
`;
