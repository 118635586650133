import { Flex, Stack, Switch, Tooltip } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../../../../hooks/useStores";
import { calculateFancadeDesignTotalCost } from "../../../../../../services/utils";
import theme from "../../../../../../theme/theme";
import { Fancade } from "../../../../../../types";
import { Container } from "../../../../../Container";
import { FormRow } from "../../../../../FormRow";
import { SimpleFormGenerator } from "../../../../../SimpleFormGenerator";

type Props = {
  currentFancade: Fancade;
  fancadeNumber: number;
  formikProps: any;
  setUpdateKpi: any;
  updateKpi: boolean;
};

export const FancadeProjectForm: React.FC<Props> = observer((props) => {
  const { t } = useTranslation();
  const { ui } = useStores();
  const [isTotalManual, setIsTotalManual] = useState(
    !props.formikProps.values.fancades[props.fancadeNumber - 1]?.fancadeProject
      ?.totalDesignPriceCalculation ||
      props.formikProps.values.fancades[props.fancadeNumber - 1]?.fancadeProject
        ?.totalDesignPriceCalculation == 0
  );

  let priceRows: any[] = [];
  let totalFieldManual: any;
  let totalPrice: number = 0;
  //Calculate the total for the automatic TotalDesignPrice field
  if (props.currentFancade) {
    const currentFancadeNumber = props.fancadeNumber - 1;
    const temp =
      props.formikProps.values.fancades[currentFancadeNumber]?.fancadeProject;
    let k: keyof typeof temp;
    let counter = 0;
    for (k in temp) {
      if (counter > 0) {
        totalPrice += +temp[k];
      }
      counter++;
    }
  }
  //Iterate over FancadeProject attributes to create the fields
  if (props.currentFancade) {
    let k: keyof typeof props.currentFancade.fancadeProject;
    let counter = 0;
    for (k in props.currentFancade?.fancadeProject) {
      if (k.toString() !== "totalDesignPriceCalculation") {
        if (counter > 0) {
          priceRows.push({
            name: `fancades[${props.fancadeNumber - 1}].fancadeProject.` + k,
            label: t(`formLabels.details.fancadePrice.${k}`) + " (€)",
            placeholder:
              t(`formPlaceholders.details.fancadePrice.${k}`) + " (€)",
            type: "number",
            width: 100,
          });
        } else {
          totalFieldManual = {
            name: `fancades[${props.fancadeNumber - 1}].fancadeProject.` + k,
            label: t(`formLabels.details.fancadePrice.${k}`) + " (€)",
            placeholder:
              t(`formPlaceholders.details.fancadePrice.${k}`) + " (€)",
            type: "number",
            width: 100,
          };
        }
        counter++;
      }
    }
  }
  return (
    <>
      <Tooltip label={t("warnings.calculationChange")} bg={"red.500"}>
        <Flex>
          <Switch
            isChecked={!isTotalManual}
            colorScheme={"red"}
            onChange={() => {
              if (
                isTotalManual &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  ?.fancadeProject
              )
                props.formikProps.values.fancades[
                  props.fancadeNumber - 1
                ].fancadeProject.totalDesignPriceCalculation = 1;
              if (!isTotalManual)
                props.formikProps.values.fancades[
                  props.fancadeNumber - 1
                ].fancadeProject.totalDesignPriceCalculation = 0;
              setIsTotalManual(!isTotalManual);
              ui.setProjectModified(true);
              props.setUpdateKpi(!props.updateKpi);
            }}
            color={isTotalManual ? "black" : "rgba(0,0,0,0.5)"}
          >
            {t(`formLabels.details.fancadePrice.totalCheckbox`)}
          </Switch>
        </Flex>
      </Tooltip>
      {isTotalManual ? (
        <FormRow
          fields={[
            {
              name: `fancades[${
                props.fancadeNumber - 1
              }].fancadeProject.totalDesignPrice`,
              label:
                t(`formLabels.details.fancadePrice.totalDesignPrice`) + " (€)",
              placeholder:
                t(`formPlaceholders.details.fancadePrice.totalDesignPrice`) +
                " (€)",
              type: "number",
              width: 100,
            },
          ]}
          paddingBottom={"1rem"}
        />
      ) : (
        <Container
          title={
            t(`formLabels.details.fancadePrice.totalDesignPrice`) +
            t(`titles.calculatedSection`)
          }
          bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
          direction={"column"}
          spacing={"1rem"}
        >
          <FormRow
            fields={[
              {
                name: `fancades[${
                  props.fancadeNumber - 1
                }].fancadeProject.totalDesignPrice`,
                label:
                  t(`formLabels.details.fancadePrice.totalDesignPrice`) +
                  " (€)",
                placeholder:
                  t(`formPlaceholders.details.fancadePrice.totalDesignPrice`) +
                  " (€)",
                type: "number",
                width: 100,
                value: calculateFancadeDesignTotalCost(
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeProject
                ),
                disabled: true,
              },
            ]}
            paddingBottom={"1rem"}
          />
          <Stack spacing={"1rem"}>
            <SimpleFormGenerator
              fields={priceRows}
              formStructure={[2, 2, 2, 2, 2, 2, 2]}
              spacing={"1rem"}
            />
          </Stack>
        </Container>
      )}
    </>
  );
});
