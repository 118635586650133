import { Flex, FormControl, Switch, Text, Tooltip } from "@chakra-ui/react";
import { Field, FieldProps } from "formik";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../hooks/useStores";
import { transparentize } from "polished";
import theme from "../theme/theme";

type Props = {
  name?: string;
  checked?: any;
  defaultChecked?: any;
  onChange?: any;
  paddingTop?: any;
  marginTop?: any;
  leftText?: string;
  customLabel?: string;
};

export const FormikCheckbox: React.FC<Props> = observer((props) => {
  const { ui } = useStores();
  const { t } = useTranslation();

  return (
    <Field name={props.children}>
      {({ field, form: { setFieldValue }, meta }: FieldProps) => (
        <FormControl>
          <Tooltip label={t("warnings.calculationChange")} bg={"red.500"}>
            <Flex
              alignItems={"center"}
              marginTop={
                props.paddingTop ? props.paddingTop : props.leftText && "1rem"
              }
              marginBottom={props.leftText && "1rem"}
              bg={
                props.leftText && transparentize(0.9, theme.colors.euracOrange)
              }
              rounded={10}
              padding={props.leftText && "0.2rem"}
            >
              {props.leftText && (
                <Text
                  fontSize={"md"}
                  color={props.checked ? "rgba(0,0,0,0.5)" : "black"}
                  marginRight={"0.5rem"}
                >
                  {props.leftText}
                </Text>
              )}
              <Switch
                {...field}
                name={props.name}
                colorScheme={"red"}
                isChecked={!props.checked}
                defaultChecked={!props.defaultChecked}
                onChange={(e) => {
                  ui.setProjectModified(true);
                  if (props.onChange) props.onChange(e);
                }}
                fontSize={"md"}
                color={"black"}
              />
              <Text fontSize={"md"} color={"black"} marginLeft={"0.5rem"}>
                {props.customLabel || props.children || t("manualInput")}
              </Text>
            </Flex>
          </Tooltip>
        </FormControl>
      )}
    </Field>
  );
});
