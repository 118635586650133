import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Checkbox,
  CircularProgress,
} from "@chakra-ui/react";

import React from "react";
import ReactDOM from "react-dom";
import {
  Button,
  ButtonGroup,
  Center,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../../../hooks/useStores";

import styled from "styled-components";
import { transparentize } from "polished";
import theme from "../../../../../theme/theme";
import { Fancade, Project, TableRow } from "../../../../../types";
import { DetailsTable } from "../../details/DetailsTable";
import {
  calculateAerialPlatformsToolsKPI,
  calculateBenchmark,
  calculateBuildingSiteDurationKPI,
  calculateBuildingSiteDurationPerM2KPI,
  calculateBuildingSiteInstallationConstructionKPI,
  calculateBuildingSiteInstallationKPI,
  calculateBuildingSiteKPI,
  calculateBuildingSiteLaborInstallationKPI,
  calculateBuildingSiteMaterialsInstallationKPI,
  calculateBuildingSiteSecurityConstructionKPI,
  calculateBuildingSiteSecurityKPI,
  calculateBuildingSiteToolsInstallationKPI,
  calculateCleaningMaintenanceKPI,
  calculateCraneToolsKPI,
  calculateElevatorPlatformsToolsKPI,
  calculateEndOfLifeKPI,
  calculateExtraordinaryMaintenanceMaintenanceKPI,
  calculateFancadeKPI,
  calculateInvestmentKPI,
  calculateLaborCostKPI,
  calculateLaborCostProductionKPI,
  calculateLaborProductionAndConstructionKPI,
  calculateLCCInvestmentKPI,
  calculateMaintenanceKPI,
  calculateMaterialsCostKPI,
  calculateMaterialsCostProductionKPI,
  calculateMaterialsProductionAndConstructionKPI,
  calculateOrdinaryMaintenanceMaintenanceKPI,
  calculateProductionKPI,
  calculateProjectKPI,
  calculateRecyclingProductionKPI,
  calculateScaffoldingsToolsKPI,
  calculateToolsKPI,
  calculateTransportKPI,
  calculateTransportPerKmKPI,
  calculateUFancadeAverageKPI,
  calculateUwallKPI,
  calculateUwindowKPI,
  calculateWindowOpaqueSurfaceRatio,
  calculateWWRKPI,
} from "../../../../../services/utils";

type Props = {
  project: Project;
  fancadeIndex: number;
  benchmark?: boolean;
};

export const KPITable: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  let rows: TableRow[] = [];
  let fancade: Fancade | undefined = undefined;
  let benchmark: Fancade | undefined = undefined;
  let project: Project | undefined = undefined;

  if (props.fancadeIndex != -1) {
    if (
      props.project &&
      props.project.fancades &&
      props.project.fancades.length > 0
    ) {
      fancade = props.project.fancades[props.fancadeIndex];
      if (props.benchmark) benchmark = props.project.fancades[0];
    }
  } else {
    project = props.project;
  }

  rows.push({
    name: t("tablesVoices.report.kpi.investment"),
    values: [
      (props.benchmark
        ? calculateBenchmark(
            +calculateInvestmentKPI(benchmark, project),
            +calculateInvestmentKPI(fancade, project)
          )
        : +calculateInvestmentKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " €/m²",
    ],
  });
  rows.push({
    name: t("tablesVoices.report.kpi.investmentLCC"),
    values: [
      (props.benchmark
        ? calculateBenchmark(
            +calculateLCCInvestmentKPI(benchmark, project),
            +calculateLCCInvestmentKPI(fancade, project)
          )
        : +calculateLCCInvestmentKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " €/m²",
    ],
  });
  rows.push({
    name: t("tablesVoices.report.kpi.project"),
    values: [
      (props.benchmark
        ? calculateBenchmark(
            +calculateProjectKPI(benchmark, project),
            +calculateProjectKPI(fancade, project)
          )
        : +calculateProjectKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " €/m²",
    ],
  });

  rows.push({
    name: t("tablesVoices.report.kpi.materialsProductionRatio"),
    values: [
      (props.benchmark
        ? +calculateBenchmark(
            +calculateMaterialsCostProductionKPI(benchmark, project),
            +calculateMaterialsCostProductionKPI(fancade, project)
          ) * 100
        : +calculateMaterialsCostProductionKPI(fancade, project) * 100
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " %",
    ],
  });

  rows.push({
    name: t("tablesVoices.report.kpi.laborProductionRatio"),
    values: [
      (props.benchmark
        ? +calculateBenchmark(
            +calculateLaborCostProductionKPI(benchmark, project),
            +calculateLaborCostProductionKPI(fancade, project)
          ) * 100
        : +calculateLaborCostProductionKPI(fancade, project) * 100
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " %",
    ],
  });

  rows.push({
    name: t("tablesVoices.report.kpi.transport"),
    values: [
      (props.benchmark
        ? +calculateBenchmark(
            +calculateTransportKPI(benchmark, project),
            +calculateTransportKPI(fancade, project)
          )
        : +calculateTransportKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " €/m²",
    ],
  });

  rows.push({
    name: t("tablesVoices.report.kpi.trasportPerKm"),
    values: [
      (props.benchmark
        ? calculateBenchmark(
            +calculateTransportPerKmKPI(benchmark, project),
            +calculateTransportPerKmKPI(fancade, project)
          )
        : +calculateTransportPerKmKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " €/Km",
    ],
  });
  rows.push({
    name: t("tablesVoices.report.kpi.installationOpera"),
    values: [
      (props.benchmark
        ? calculateBenchmark(
            +calculateBuildingSiteKPI(benchmark, project),
            +calculateBuildingSiteKPI(fancade, project)
          )
        : +calculateBuildingSiteKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " €/m²",
    ],
  });
  rows.push({
    name: t("tablesVoices.report.kpi.installation"),
    values: [
      (props.benchmark
        ? calculateBenchmark(
            +calculateBuildingSiteInstallationKPI(benchmark, project),
            +calculateBuildingSiteInstallationKPI(fancade, project)
          )
        : +calculateBuildingSiteInstallationKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " €/m²",
    ],
  });
  rows.push({
    name: t("tablesVoices.report.kpi.security"),
    values: [
      (props.benchmark
        ? calculateBenchmark(
            +calculateBuildingSiteSecurityKPI(benchmark, project),
            +calculateBuildingSiteSecurityKPI(fancade, project)
          )
        : +calculateBuildingSiteSecurityKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " €/m²",
    ],
  });
  rows.push({
    name: t("tablesVoices.report.kpi.installationConstructionRatio"),
    values: [
      (props.benchmark
        ? +calculateBenchmark(
            +calculateBuildingSiteInstallationConstructionKPI(
              benchmark,
              project
            ),
            +calculateBuildingSiteInstallationConstructionKPI(fancade, project)
          ) * 100
        : +calculateBuildingSiteInstallationConstructionKPI(fancade, project) *
          100
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " %",
    ],
  });
  rows.push({
    name: t("tablesVoices.report.kpi.securityConstructionRatio"),
    values: [
      (props.benchmark
        ? +calculateBenchmark(
            +calculateBuildingSiteSecurityConstructionKPI(benchmark, project),
            +calculateBuildingSiteSecurityConstructionKPI(fancade, project)
          ) * 100
        : +calculateBuildingSiteSecurityConstructionKPI(fancade, project) * 100
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " %",
    ],
  });
  rows.push({
    name: t("tablesVoices.report.kpi.materalsInstallationRatio"),
    values: [
      (props.benchmark
        ? +calculateBenchmark(
            +calculateBuildingSiteMaterialsInstallationKPI(benchmark, project),
            +calculateBuildingSiteMaterialsInstallationKPI(fancade, project)
          ) * 100
        : +calculateBuildingSiteMaterialsInstallationKPI(fancade, project) * 100
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " %",
    ],
  });
  rows.push({
    name: t("tablesVoices.report.kpi.laborInstallationRatio"),
    values: [
      +(
        calculateBuildingSiteLaborInstallationKPI(fancade, project) * 100
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " %",
    ],
  });
  rows.push({
    name: t("tablesVoices.report.kpi.toolsInstallationRatio"),
    values: [
      (props.benchmark
        ? +calculateBenchmark(
            +calculateBuildingSiteToolsInstallationKPI(benchmark, project),
            +calculateBuildingSiteToolsInstallationKPI(fancade, project)
          ) * 100
        : +calculateBuildingSiteToolsInstallationKPI(fancade, project) * 100
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " %",
    ],
  });
  rows.push({
    name: t("tablesVoices.report.kpi.tools"),
    values: [
      (props.benchmark
        ? calculateBenchmark(
            +calculateToolsKPI(benchmark, project),
            +calculateToolsKPI(fancade, project)
          )
        : +calculateToolsKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " €/m²",
    ],
  });
  rows.push({
    name: t("tablesVoices.report.kpi.crane"),
    values: [
      (props.benchmark
        ? +calculateBenchmark(
            +calculateCraneToolsKPI(benchmark, project),
            +calculateCraneToolsKPI(fancade, project)
          ) * 100
        : +calculateCraneToolsKPI(fancade, project) * 100
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " %",
    ],
  });
  rows.push({
    name: t("tablesVoices.report.kpi.elevatorPlatforms"),
    values: [
      (props.benchmark
        ? +calculateBenchmark(
            +calculateElevatorPlatformsToolsKPI(benchmark, project),
            +calculateElevatorPlatformsToolsKPI(fancade, project)
          ) * 100
        : +calculateElevatorPlatformsToolsKPI(fancade, project) * 100
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " %",
    ],
  });
  rows.push({
    name: t("tablesVoices.report.kpi.aerialPlatforms"),
    values: [
      (props.benchmark
        ? +calculateBenchmark(
            +calculateAerialPlatformsToolsKPI(benchmark, project),
            +calculateAerialPlatformsToolsKPI(fancade, project)
          ) * 100
        : +calculateAerialPlatformsToolsKPI(fancade, project) * 100
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " %",
    ],
  });
  rows.push({
    name: t("tablesVoices.report.kpi.scaffolding"),
    values: [
      (props.benchmark
        ? +calculateBenchmark(
            +calculateScaffoldingsToolsKPI(benchmark, project),
            +calculateScaffoldingsToolsKPI(fancade, project)
          ) * 100
        : +calculateScaffoldingsToolsKPI(fancade, project) * 100
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " %",
    ],
  });
  rows.push({
    name: t("tablesVoices.report.kpi.buildingSiteDuration"),
    values: [
      (props.benchmark
        ? calculateBenchmark(
            +calculateBuildingSiteDurationKPI(benchmark, project),
            +calculateBuildingSiteDurationKPI(fancade, project)
          )
        : +calculateBuildingSiteDurationKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " giorni",
    ],
  });
  rows.push({
    name: t("tablesVoices.report.kpi.buildingSiteDurationPerM2"),
    values: [
      (props.benchmark
        ? calculateBenchmark(
            +calculateBuildingSiteDurationPerM2KPI(benchmark, project),
            +calculateBuildingSiteDurationPerM2KPI(fancade, project)
          )
        : +calculateBuildingSiteDurationPerM2KPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " Gg/m²",
    ],
  });
  rows.push({
    name: t("tablesVoices.report.kpi.maintenance"),
    values: [
      (props.benchmark
        ? calculateBenchmark(
            +calculateMaintenanceKPI(benchmark, project),
            +calculateMaintenanceKPI(fancade, project)
          )
        : +calculateMaintenanceKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " €/m²",
    ],
  });
  rows.push({
    name: t("tablesVoices.report.kpi.ordinaryMaintenance"),
    values: [
      (props.benchmark
        ? +calculateBenchmark(
            +calculateOrdinaryMaintenanceMaintenanceKPI(benchmark, project),
            +calculateOrdinaryMaintenanceMaintenanceKPI(fancade, project)
          ) * 100
        : +calculateOrdinaryMaintenanceMaintenanceKPI(fancade, project) * 100
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " %",
    ],
  });
  rows.push({
    name: t("tablesVoices.report.kpi.extraordinaryMaintenance"),
    values: [
      (props.benchmark
        ? +calculateBenchmark(
            +calculateExtraordinaryMaintenanceMaintenanceKPI(
              benchmark,
              project
            ),
            +calculateExtraordinaryMaintenanceMaintenanceKPI(fancade, project)
          ) * 100
        : +calculateExtraordinaryMaintenanceMaintenanceKPI(fancade, project) *
          100
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " %",
    ],
  });
  rows.push({
    name: t("tablesVoices.report.kpi.cleaning"),
    values: [
      (props.benchmark
        ? +calculateBenchmark(
            +calculateCleaningMaintenanceKPI(benchmark, project),
            +calculateCleaningMaintenanceKPI(fancade, project)
          ) * 100
        : +calculateCleaningMaintenanceKPI(fancade, project) * 100
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " %",
    ],
  });
  rows.push({
    name: t("tablesVoices.report.kpi.endOfLife"),
    values: [
      (props.benchmark
        ? calculateBenchmark(
            +calculateEndOfLifeKPI(benchmark, project),
            +calculateEndOfLifeKPI(fancade, project)
          )
        : +calculateEndOfLifeKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " €/m²",
    ],
  });
  rows.push({
    name: t("tablesVoices.report.kpi.recyclingProductionRatio"),
    values: [
      (props.benchmark
        ? +calculateBenchmark(
            +calculateRecyclingProductionKPI(benchmark, project),
            +calculateRecyclingProductionKPI(fancade, project)
          ) * 100
        : +calculateRecyclingProductionKPI(fancade, project) * 100
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " %",
    ],
  });
  rows.push({
    name: t("tablesVoices.report.kpi.materialsTotal"),
    values: [
      (props.benchmark
        ? calculateBenchmark(
            +calculateMaterialsProductionAndConstructionKPI(benchmark, project),
            +calculateMaterialsProductionAndConstructionKPI(fancade, project)
          )
        : +calculateMaterialsProductionAndConstructionKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " €",
    ],
  });
  rows.push({
    name: t("tablesVoices.report.kpi.materialsCost"),
    values: [
      (props.benchmark
        ? calculateBenchmark(
            +calculateMaterialsCostKPI(benchmark, project),
            +calculateMaterialsCostKPI(fancade, project)
          )
        : +calculateMaterialsCostKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " €/m²",
    ],
  });
  rows.push({
    name: t("tablesVoices.report.kpi.laborTotal"),
    values: [
      (props.benchmark
        ? calculateBenchmark(
            +calculateLaborProductionAndConstructionKPI(benchmark, project),
            +calculateLaborProductionAndConstructionKPI(fancade, project)
          )
        : +calculateLaborProductionAndConstructionKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " €",
    ],
  });
  rows.push({
    name: t("tablesVoices.report.kpi.laborCost"),
    values: [
      (props.benchmark
        ? calculateBenchmark(
            +calculateLaborCostKPI(benchmark, project),
            +calculateLaborCostKPI(fancade, project)
          )
        : +calculateLaborCostKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " €/m²",
    ],
  });
  rows.push({
    name: t("tablesVoices.report.kpi.technicalParameters"),
    values: [""],
  });
  rows.push({
    name: t("tablesVoices.report.kpi.surface"),
    values: [
      (props.benchmark
        ? calculateBenchmark(
            +calculateFancadeKPI(benchmark, project),
            +calculateFancadeKPI(fancade, project)
          )
        : +calculateFancadeKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " m²",
    ],
  });
  rows.push({
    name: t("tablesVoices.report.kpi.wwr"),
    values: [
      (props.benchmark
        ? +calculateBenchmark(
            +calculateWWRKPI(benchmark, project),
            +calculateWWRKPI(fancade, project)
          ) * 100
        : +calculateWWRKPI(fancade, project) * 100
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " %",
    ],
  });
  rows.push({
    name: t("tablesVoices.report.kpi.uparete"),
    values: [
      (props.benchmark
        ? calculateBenchmark(
            +calculateUwallKPI(benchmark, project),
            +calculateUwallKPI(fancade, project)
          )
        : +calculateUwallKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " W/m²K",
    ],
  });
  rows.push({
    name: t("tablesVoices.report.kpi.ufinestra"),
    values: [
      (props.benchmark
        ? calculateBenchmark(
            +calculateUwindowKPI(benchmark, project),
            +calculateUwindowKPI(fancade, project)
          )
        : +calculateUwindowKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " W/m²K",
    ],
  });
  rows.push({
    name: t("tablesVoices.report.kpi.ufacciata"),
    values: [
      (props.benchmark
        ? calculateBenchmark(
            +calculateUFancadeAverageKPI(benchmark, project),
            +calculateUFancadeAverageKPI(fancade, project)
          )
        : +calculateUFancadeAverageKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " W/m²K",
    ],
  });
  return (
    <Flex w={"99%"}>
      {props.benchmark ? (
        <Stack w={"100%"}>
          <Text fontSize={"2xl"}>{t("tablesHeaders.report.kpiBenchmark")}</Text>
          <DetailsTable
            rows={rows}
            columnsTitles={[
              t("tablesHeaders.report.kpiBenchmark"),
              t("tablesHeaders.report.value"),
            ]}
            colorValues={true}
            valuesAlignment={"left"}
          />
        </Stack>
      ) : (
        <DetailsTable
          rows={rows}
          columnsTitles={[
            t("tablesHeaders.report.kpi"),
            t("tablesHeaders.report.value"),
          ]}
          colorValues={true}
          valuesAlignment={"left"}
        />
      )}
    </Flex>
  );
};
