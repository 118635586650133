import { CloseIcon } from "@chakra-ui/icons";
import { Button, Flex, Select, Stack, Text } from "@chakra-ui/react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useStores } from "../../../../../../hooks/useStores";
import {
  calculateHardwareTotalPrice,
  calculateOpaqueWindowsTotal,
  calculateOpaqueWindowTotal,
  calculateScrapTotal,
  calculateTotalLoomLength,
  calculateTotalMainFrameLength,
  calculateTransparentWindowsTotal,
  getScrapUnitPrice,
} from "../../../../../../services/utils";
import theme from "../../../../../../theme/theme";
import { transparentize } from "polished";
import {
  Fancade,
  FancadeProductionType,
  Hardware,
  OpaqueWindow,
} from "../../../../../../types";
import { Container } from "../../../../../Container";
import { FormRow } from "../../../../../FormRow";
import { FormikCheckbox } from "../../../../../FormikCheckbox";

type Props = {
  fancadeNumber: number;
  formikProps: any;
};
export const ScrapForm: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [update, setUpdate] = useState(false);
  const { projects } = useStores();

  return (
    <Container title={t("scrap")} direction={"column"}>
      {/* Total Scrap */}
      <FormikCheckbox
        checked={
          !(
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject.totalScrapPriceCalculation &&
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject.totalScrapPriceCalculation == 1
          )
        }
        defaultChecked={
          !(
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject.totalScrapPriceCalculation &&
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject.totalScrapPriceCalculation == 1
          )
        }
        onChange={() => {
          if (
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject
          ) {
            if (
              !props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeTechnicalProject.totalScrapPriceCalculation ||
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeTechnicalProject.totalScrapPriceCalculation == 0
            )
              props.formikProps.values.fancades[
                props.fancadeNumber - 1
              ].fancadeTechnicalProject.totalScrapPriceCalculation = 1;
            else
              props.formikProps.values.fancades[
                props.fancadeNumber - 1
              ].fancadeTechnicalProject.totalScrapPriceCalculation = 0;
            setUpdate(!update);
          }
        }}
      >
        {t(`formLabels.details.fancadeProduction.scrapPriceByTotal`)}
      </FormikCheckbox>
      {!(
        props.formikProps.values.fancades[props.fancadeNumber - 1]
          .fancadeTechnicalProject.totalScrapPriceCalculation &&
        props.formikProps.values.fancades[props.fancadeNumber - 1]
          .fancadeTechnicalProject.totalScrapPriceCalculation == 1
      ) ? (
        <>
          <FormRow
            fields={[
              {
                name: `fancades[${
                  props.fancadeNumber - 1
                }].fancadeTechnicalProject.totalScrapPrice`,
                label: t(
                  `formLabels.details.fancadeProduction.totalScrapPrice`
                ),
                placeholder: t(
                  `formPlaceholders.details.fancadeProduction.totalScrapPrice`
                ),
                type: "number",
                width: 100,
              },
            ]}
          />
        </>
      ) : (
        <Container
          title={
            t(`formLabels.details.fancadeProduction.totalScrapPrice`) +
            t(`titles.calculatedSection`)
          }
          bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
          direction={"column"}
          spacing={"1rem"}
        >
          <FormRow
            fields={[
              {
                name: `fancades[${
                  props.fancadeNumber - 1
                }].fancadeTechnicalProject.totalScrapPrice`,
                label: t(
                  `formLabels.details.fancadeProduction.totalScrapPrice`
                ),
                placeholder: t(
                  `formPlaceholders.details.fancadeProduction.totalScrapPrice`
                ),
                type: "number",
                width: 100,
                disabled: true,
                value: +calculateScrapTotal(
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                ),
              },
            ]}
          />
          {/* Double Glazing */}
          <FormikCheckbox
            paddingTop={"2rem"}
            checked={
              !(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalDoubleGlazingScrapPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalDoubleGlazingScrapPriceCalculation == 1
              )
            }
            defaultChecked={
              !(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalDoubleGlazingScrapPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalDoubleGlazingScrapPriceCalculation == 1
              )
            }
            onChange={() => {
              if (
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
              ) {
                if (
                  !props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject
                    .totalDoubleGlazingScrapPriceCalculation ||
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject
                    .totalDoubleGlazingScrapPriceCalculation == 0
                )
                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].fancadeTechnicalProject.totalDoubleGlazingScrapPriceCalculation = 1;
                else
                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].fancadeTechnicalProject.totalDoubleGlazingScrapPriceCalculation = 0;
                setUpdate(!update);
              }
            }}
          >
            {t(
              `formLabels.details.fancadeProduction.doubleGlazingScrapByTotal`
            )}
          </FormikCheckbox>
          {!(
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject
              .totalDoubleGlazingScrapPriceCalculation &&
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject
              .totalDoubleGlazingScrapPriceCalculation == 1
          ) ? (
            <>
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.totalDoubleGlazingScrapPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.totalDoubleGlazingScrapPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.totalDoubleGlazingScrapPrice`
                    ),
                    type: "number",
                    width: 100,
                  },
                ]}
              />
            </>
          ) : (
            <Container
              title={
                t(
                  `formLabels.details.fancadeProduction.totalDoubleGlazingScrapPrice`
                ) + t(`titles.calculatedSection`)
              }
              bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
              direction={"column"}
              spacing={"1rem"}
            >
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.totalDoubleGlazingScrapPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.totalDoubleGlazingScrapPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.totalDoubleGlazingScrapPrice`
                    ),
                    type: "number",
                    width: 100,
                    disabled: true,
                    value:
                      +props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.doubleGlazingScrapQuantity *
                      +getScrapUnitPrice(
                        "doubleglazing",
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ]
                      ),
                  },
                ]}
              />
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.doubleGlazingScrapQuantity`,
                    label: t(
                      `formLabels.details.fancadeProduction.doubleGlazingScrapQuantity`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.doubleGlazingScrapQuantity`
                    ),
                    type: "number",
                    width: 100,
                  },
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.doubleGlazingScrapUnitPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.doubleGlazingScrapUnitPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.doubleGlazingScrapUnitPrice`
                    ),
                    type: "number",
                    width: 100,
                    disabled: true,
                    value: +getScrapUnitPrice(
                      "doubleglazing",
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                    ),
                  },
                ]}
              />
            </Container>
          )}
          {/* Main Frame or Loom */}
          <FormikCheckbox
            paddingTop={"2rem"}
            checked={
              !(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalMainFrameOrLoomScrapPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalMainFrameOrLoomScrapPriceCalculation == 1
              )
            }
            defaultChecked={
              !(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalMainFrameOrLoomScrapPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalMainFrameOrLoomScrapPriceCalculation == 1
              )
            }
            onChange={() => {
              if (
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
              ) {
                if (
                  !props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject
                    .totalMainFrameOrLoomScrapPriceCalculation ||
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject
                    .totalMainFrameOrLoomScrapPriceCalculation == 0
                )
                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].fancadeTechnicalProject.totalMainFrameOrLoomScrapPriceCalculation = 1;
                else
                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].fancadeTechnicalProject.totalMainFrameOrLoomScrapPriceCalculation = 0;
                setUpdate(!update);
              }
            }}
          >
            {t(`formLabels.details.fancadeProduction.mainFrameOrLoomByTotal`)}
          </FormikCheckbox>
          {!(
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject
              .totalMainFrameOrLoomScrapPriceCalculation &&
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject
              .totalMainFrameOrLoomScrapPriceCalculation == 1
          ) ? (
            <>
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.totalMainFrameOrLoomScrapPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.totalMainFrameOrLoomScrapPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.totalMainFrameOrLoomScrapPrice`
                    ),
                    type: "number",
                    width: 100,
                  },
                ]}
              />
            </>
          ) : (
            <Container
              title={
                t(
                  `formLabels.details.fancadeProduction.totalMainFrameOrLoomScrapPrice`
                ) + t(`titles.calculatedSection`)
              }
              bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
              direction={"column"}
              spacing={"1rem"}
            >
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.totalMainFrameOrLoomScrapPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.totalMainFrameOrLoomScrapPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.totalMainFrameOrLoomScrapPrice`
                    ),
                    type: "number",
                    width: 100,
                    disabled: true,
                    value:
                      +props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.mainFrameOrLoomScrapQuantity *
                      +getScrapUnitPrice(
                        "mainframeorloop",
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ]
                      ),
                  },
                ]}
              />
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.mainFrameOrLoomScrapQuantity`,
                    label: t(
                      `formLabels.details.fancadeProduction.mainFrameOrLoomScrapQuantity`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.mainFrameOrLoomScrapQuantity`
                    ),
                    type: "number",
                    width: 100,
                  },
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.mainFrameOrLoomScrapUnitPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.mainFrameOrLoomScrapUnitPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.mainFrameOrLoomScrapUnitPrice`
                    ),
                    type: "number",
                    width: 100,
                    disabled: true,
                    value: +getScrapUnitPrice(
                      "mainframeorloop",
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                    ),
                  },
                ]}
              />
            </Container>
          )}
          {/* Gasket */}
          <FormikCheckbox
            paddingTop={"2rem"}
            checked={
              !(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject.totalGasketScrapPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject.totalGasketScrapPriceCalculation ===
                  1
              )
            }
            defaultChecked={
              !(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject.totalGasketScrapPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject.totalGasketScrapPriceCalculation ===
                  1
              )
            }
            onChange={() => {
              if (
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
              ) {
                if (
                  !props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject.totalGasketScrapPriceCalculation ||
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject.totalGasketScrapPriceCalculation ==
                    0
                )
                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].fancadeTechnicalProject.totalGasketScrapPriceCalculation = 1;
                else
                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].fancadeTechnicalProject.totalGasketScrapPriceCalculation = 0;
                setUpdate(!update);
              }
            }}
          >
            {t(`formLabels.details.fancadeProduction.gasketScrapByTotal`)}
          </FormikCheckbox>
          {!(
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject.totalGasketScrapPriceCalculation &&
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject.totalGasketScrapPriceCalculation == 1
          ) ? (
            <>
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.totalGasketScrapPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.totalGasketScrapPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.totalGasketScrapPrice`
                    ),
                    type: "number",
                    width: 100,
                  },
                ]}
              />
            </>
          ) : (
            <Container
              title={
                t(
                  `formLabels.details.fancadeProduction.totalGasketScrapPrice`
                ) + t(`titles.calculatedSection`)
              }
              bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
              direction={"column"}
              spacing={"1rem"}
            >
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.totalGasketScrapPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.totalGasketScrapPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.totalGasketScrapPrice`
                    ),
                    type: "number",
                    width: 100,
                    disabled: true,
                    value:
                      +props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.gasketScrapQuantity *
                      +getScrapUnitPrice(
                        "gasket",
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ]
                      ),
                  },
                ]}
              />
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.gasketScrapQuantity`,
                    label: t(
                      `formLabels.details.fancadeProduction.gasketScrapQuantity`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.gasketScrapQuantity`
                    ),
                    type: "number",
                    width: 100,
                  },
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.gasketScrapUnitPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.gasketScrapUnitPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.gasketScrapUnitPrice`
                    ),
                    type: "number",
                    width: 100,
                    disabled: true,
                    value: +getScrapUnitPrice(
                      "gasket",
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                    ),
                  },
                ]}
              />
            </Container>
          )}
          {props.formikProps.values.fancades[
            props.fancadeNumber - 1
          ].fancadeTechnicalProject?.type
            ?.toLowerCase()
            .indexOf("opaca") != -1 ? (
            <>
              {/* Opaque - Windows */}
              <FormikCheckbox
                paddingTop={"2rem"}
                checked={
                  !(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .totalOpaqueWindowsScrapPriceCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .totalOpaqueWindowsScrapPriceCalculation == 1
                  )
                }
                defaultChecked={
                  !(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .totalOpaqueWindowsScrapPriceCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .totalOpaqueWindowsScrapPriceCalculation == 1
                  )
                }
                onChange={() => {
                  if (
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                  ) {
                    if (
                      !props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject
                        .totalOpaqueWindowsScrapPriceCalculation ||
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .fancadeTechnicalProject
                        .totalOpaqueWindowsScrapPriceCalculation == 0
                    )
                      props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.totalOpaqueWindowsScrapPriceCalculation = 1;
                    else
                      props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.totalOpaqueWindowsScrapPriceCalculation = 0;
                    setUpdate(!update);
                  }
                }}
              >
                {t(
                  `formLabels.details.fancadeProduction.opaqueWindowsScrapByTotal`
                )}
              </FormikCheckbox>
              {!(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalOpaqueWindowsScrapPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalOpaqueWindowsScrapPriceCalculation == 1
              ) ? (
                <>
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.totalOpaqueWindowsScrapPrice`,
                        label: t(
                          `formLabels.details.fancadeProduction.totalOpaqueWindowsScrapPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.totalOpaqueWindowsScrapPrice`
                        ),
                        type: "number",
                        width: 100,
                      },
                    ]}
                  />
                </>
              ) : (
                <Container
                  title={
                    t(
                      `formLabels.details.fancadeProduction.totalOpaqueWindowsScrapPrice`
                    ) + t(`titles.calculatedSection`)
                  }
                  bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                  direction={"column"}
                  spacing={"1rem"}
                >
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.totalOpaqueWindowsScrapPrice`,
                        label: t(
                          `formLabels.details.fancadeProduction.totalOpaqueWindowsScrapPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.totalOpaqueWindowsScrapPrice`
                        ),
                        type: "number",
                        width: 100,
                        disabled: true,
                        value:
                          +props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject.opaqueWindowsScrapQuantity *
                          +getScrapUnitPrice(
                            "opaquewindows",
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ]
                          ),
                      },
                    ]}
                  />
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.opaqueWindowsScrapQuantity`,
                        label: t(
                          `formLabels.details.fancadeProduction.opaqueWindowsScrapQuantity`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.opaqueWindowsScrapQuantity`
                        ),
                        type: "number",
                        width: 100,
                      },
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.opaqueWindowsScrapUnitPrice`,
                        label: t(
                          `formLabels.details.fancadeProduction.opaqueWindowsScrapUnitPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.opaqueWindowsScrapUnitPrice`
                        ),
                        type: "number",
                        width: 100,
                        disabled: true,
                        value: +getScrapUnitPrice(
                          "opaquewindows",
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ]
                        ),
                      },
                    ]}
                  />
                </Container>
              )}

              {/* Opaque - External Cladding */}
              <FormikCheckbox
                paddingTop={"2rem"}
                checked={
                  !(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .totalOpaqueExternalCladdingScrapPriceCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .totalOpaqueExternalCladdingScrapPriceCalculation == 1
                  )
                }
                defaultChecked={
                  !(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .totalOpaqueExternalCladdingScrapPriceCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .totalOpaqueExternalCladdingScrapPriceCalculation == 1
                  )
                }
                onChange={() => {
                  if (
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                  ) {
                    if (
                      !props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject
                        .totalOpaqueExternalCladdingScrapPriceCalculation ||
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .fancadeTechnicalProject
                        .totalOpaqueExternalCladdingScrapPriceCalculation == 0
                    )
                      props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.totalOpaqueExternalCladdingScrapPriceCalculation = 1;
                    else
                      props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.totalOpaqueExternalCladdingScrapPriceCalculation = 0;
                    setUpdate(!update);
                  }
                }}
              >
                {t(
                  `formLabels.details.fancadeProduction.opaqueExternalCladdingScrapByTotal`
                )}
              </FormikCheckbox>
              {!(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalOpaqueExternalCladdingScrapPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalOpaqueExternalCladdingScrapPriceCalculation == 1
              ) ? (
                <>
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.totalOpaqueExternalCladdingScrapPrice`,
                        label: t(
                          `formLabels.details.fancadeProduction.totalOpaqueExternalCladdingScrapPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.totalOpaqueExternalCladdingScrapPrice`
                        ),
                        type: "number",
                        width: 100,
                      },
                    ]}
                  />
                </>
              ) : (
                <Container
                  title={
                    t(
                      `formLabels.details.fancadeProduction.totalOpaqueExternalCladdingScrapPrice`
                    ) + t(`titles.calculatedSection`)
                  }
                  bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                  direction={"column"}
                  spacing={"1rem"}
                >
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.totalOpaqueExternalCladdingScrapPrice`,
                        label: t(
                          `formLabels.details.fancadeProduction.totalOpaqueExternalCladdingScrapPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.totalOpaqueExternalCladdingScrapPrice`
                        ),
                        type: "number",
                        width: 100,
                        disabled: true,
                        value:
                          +props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                            .opaqueExternalCladdingScrapQuantity *
                          +getScrapUnitPrice(
                            "externalcladding",
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ]
                          ),
                      },
                    ]}
                  />
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.opaqueExternalCladdingScrapQuantity`,
                        label: t(
                          `formLabels.details.fancadeProduction.opaqueExternalCladdingScrapQuantity`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.opaqueExternalCladdingScrapQuantity`
                        ),
                        type: "number",
                        width: 100,
                      },
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.opaqueExternalCladdingScrapUnitPrice`,
                        label: t(
                          `formLabels.details.fancadeProduction.opaqueExternalCladdingScrapUnitPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.opaqueExternalCladdingScrapUnitPrice`
                        ),
                        type: "number",
                        width: 100,
                        disabled: true,
                        value: +getScrapUnitPrice(
                          "externalcladding",
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ]
                        ),
                      },
                    ]}
                  />
                </Container>
              )}

              {/* Opaque - Insulation */}
              <FormikCheckbox
                paddingTop={"2rem"}
                checked={
                  !(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .totalOpaqueInsulationScrapPriceCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .totalOpaqueInsulationScrapPriceCalculation == 1
                  )
                }
                defaultChecked={
                  !(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .totalOpaqueInsulationScrapPriceCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .totalOpaqueInsulationScrapPriceCalculation == 1
                  )
                }
                onChange={() => {
                  if (
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                  ) {
                    if (
                      !props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject
                        .totalOpaqueInsulationScrapPriceCalculation ||
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .fancadeTechnicalProject
                        .totalOpaqueInsulationScrapPriceCalculation == 0
                    )
                      props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.totalOpaqueInsulationScrapPriceCalculation = 1;
                    else
                      props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.totalOpaqueInsulationScrapPriceCalculation = 0;
                    setUpdate(!update);
                  }
                }}
              >
                {t(
                  `formLabels.details.fancadeProduction.opaqueInsulationScrapByTotal`
                )}
              </FormikCheckbox>
              {!(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalOpaqueInsulationScrapPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalOpaqueInsulationScrapPriceCalculation == 1
              ) ? (
                <>
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.totalOpaqueInsulationScrapPrice`,
                        label: t(
                          `formLabels.details.fancadeProduction.totalOpaqueInsulationScrapPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.totalOpaqueInsulationScrapPrice`
                        ),
                        type: "number",
                        width: 100,
                      },
                    ]}
                  />
                </>
              ) : (
                <Container
                  title={
                    t(
                      `formLabels.details.fancadeProduction.totalOpaqueInsulationScrapPrice`
                    ) + t(`titles.calculatedSection`)
                  }
                  bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                  direction={"column"}
                  spacing={"1rem"}
                >
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.totalOpaqueInsulationScrapPrice`,
                        label: t(
                          `formLabels.details.fancadeProduction.totalOpaqueInsulationScrapPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.totalOpaqueInsulationScrapPrice`
                        ),
                        type: "number",
                        width: 100,
                        disabled: true,
                        value:
                          +props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                            .opaqueInsulationScrapQuantity *
                          +getScrapUnitPrice(
                            "insulation",
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ]
                          ),
                      },
                    ]}
                  />
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.opaqueInsulationScrapQuantity`,
                        label: t(
                          `formLabels.details.fancadeProduction.opaqueInsulationScrapQuantity`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.opaqueInsulationScrapQuantity`
                        ),
                        type: "number",
                        width: 100,
                      },
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.opaqueInsulationScrapUnitPrice`,
                        label: t(
                          `formLabels.details.fancadeProduction.opaqueInsulationScrapUnitPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.opaqueInsulationScrapUnitPrice`
                        ),
                        type: "number",
                        width: 100,
                        disabled: true,
                        value: +getScrapUnitPrice(
                          "insulation",
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ]
                        ),
                      },
                    ]}
                  />
                </Container>
              )}

              {/* Opaque - Supporting Elements */}
              <FormikCheckbox
                paddingTop={"2rem"}
                checked={
                  !(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .totalOpaqueSupportingElementsScrapPriceCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .totalOpaqueSupportingElementsScrapPriceCalculation == 1
                  )
                }
                defaultChecked={
                  !(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .totalOpaqueSupportingElementsScrapPriceCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .totalOpaqueSupportingElementsScrapPriceCalculation == 1
                  )
                }
                onChange={() => {
                  if (
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                  ) {
                    if (
                      !props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject
                        .totalOpaqueSupportingElementsScrapPriceCalculation ||
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .fancadeTechnicalProject
                        .totalOpaqueSupportingElementsScrapPriceCalculation ===
                        0
                    )
                      props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.totalOpaqueSupportingElementsScrapPriceCalculation = 1;
                    else
                      props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.totalOpaqueSupportingElementsScrapPriceCalculation = 0;
                    setUpdate(!update);
                  }
                }}
              >
                {t(
                  `formLabels.details.fancadeProduction.opaqueSupportingElementsScrapByTotal`
                )}
              </FormikCheckbox>
              {!(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalOpaqueSupportingElementsScrapPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalOpaqueSupportingElementsScrapPriceCalculation == 1
              ) ? (
                <>
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.totalOpaqueSupportingElementsScrapPrice`,
                        label: t(
                          `formLabels.details.fancadeProduction.totalOpaqueSupportingElementsScrapPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.totalOpaqueSupportingElementsScrapPrice`
                        ),
                        type: "number",
                        width: 100,
                      },
                    ]}
                  />
                </>
              ) : (
                <Container
                  title={
                    t(
                      `formLabels.details.fancadeProduction.totalOpaqueSupportingElementsScrapPrice`
                    ) + t(`titles.calculatedSection`)
                  }
                  bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                  direction={"column"}
                  spacing={"1rem"}
                >
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.totalOpaqueSupportingElementsScrapPrice`,
                        label: t(
                          `formLabels.details.fancadeProduction.totalOpaqueSupportingElementsScrapPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.totalOpaqueSupportingElementsScrapPrice`
                        ),
                        type: "number",
                        width: 100,
                        disabled: true,
                        value:
                          +props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                            .opaqueSupportingElementsScrapQuantity *
                          +getScrapUnitPrice(
                            "supportingelements",
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ]
                          ),
                      },
                    ]}
                  />
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.opaqueSupportingElementsScrapQuantity`,
                        label: t(
                          `formLabels.details.fancadeProduction.opaqueSupportingElementsScrapQuantity`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.opaqueSupportingElementsScrapQuantity`
                        ),
                        type: "number",
                        width: 100,
                      },
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.opaqueSupportingElementsScrapUnitPrice`,
                        label: t(
                          `formLabels.details.fancadeProduction.opaqueSupportingElementsScrapUnitPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.opaqueSupportingElementsScrapUnitPrice`
                        ),
                        type: "number",
                        width: 100,
                        disabled: true,
                        value: +getScrapUnitPrice(
                          "supportingelements",
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ]
                        ),
                      },
                    ]}
                  />
                </Container>
              )}
            </>
          ) : props.formikProps.values.fancades[
              props.fancadeNumber - 1
            ].fancadeTechnicalProject?.type
              ?.toLowerCase()
              .indexOf("trasparente") != -1 ? (
            <>
              {/* Transparent - Glazed Cells */}
              <FormikCheckbox
                paddingTop={"2rem"}
                checked={
                  !(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .totalTransparentGlazedCellScrapPriceCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .totalTransparentGlazedCellScrapPriceCalculation == 1
                  )
                }
                defaultChecked={
                  !(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .totalTransparentGlazedCellScrapPriceCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .totalTransparentGlazedCellScrapPriceCalculation == 1
                  )
                }
                onChange={() => {
                  if (
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                  ) {
                    if (
                      !props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject
                        .totalTransparentGlazedCellScrapPriceCalculation ||
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .fancadeTechnicalProject
                        .totalTransparentGlazedCellScrapPriceCalculation == 0
                    )
                      props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.totalTransparentGlazedCellScrapPriceCalculation = 1;
                    else
                      props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.totalTransparentGlazedCellScrapPriceCalculation = 0;
                    setUpdate(!update);
                  }
                }}
              >
                {t(
                  `formLabels.details.fancadeProduction.transparentGlazedCellScrapByTotal`
                )}
              </FormikCheckbox>
              {!(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalTransparentGlazedCellScrapPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalTransparentGlazedCellScrapPriceCalculation == 1
              ) ? (
                <>
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.totalTransparentGlazedCellScrapPrice`,
                        label: t(
                          `formLabels.details.fancadeProduction.totalTransparentGlazedCellScrapPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.totalTransparentGlazedCellScrapPrice`
                        ),
                        type: "number",
                        width: 100,
                      },
                    ]}
                  />
                </>
              ) : (
                <Container
                  title={
                    t(
                      `formLabels.details.fancadeProduction.totalTransparentGlazedCellScrapPrice`
                    ) + t(`titles.calculatedSection`)
                  }
                  bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                  direction={"column"}
                  spacing={"1rem"}
                >
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.totalTransparentGlazedCellScrapPrice`,
                        label: t(
                          `formLabels.details.fancadeProduction.totalTransparentGlazedCellScrapPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.totalTransparentGlazedCellScrapPrice`
                        ),
                        type: "number",
                        width: 100,
                        disabled: true,
                        value:
                          +props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                            .transparentGlazedCellScrapQuantity *
                          +getScrapUnitPrice(
                            "glazedcells",
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ]
                          ),
                      },
                    ]}
                  />
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.transparentGlazedCellScrapQuantity`,
                        label: t(
                          `formLabels.details.fancadeProduction.transparentGlazedCellScrapQuantity`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.transparentGlazedCellScrapQuantity`
                        ),
                        type: "number",
                        width: 100,
                      },
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.transparentGlazedCellsScrapUnitPrice`,
                        label: t(
                          `formLabels.details.fancadeProduction.transparentGlazedCellsScrapUnitPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.transparentGlazedCellsScrapUnitPrice`
                        ),
                        type: "number",
                        width: 100,
                        disabled: true,
                        value: +getScrapUnitPrice(
                          "glazedcells",
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ]
                        ),
                      },
                    ]}
                  />
                </Container>
              )}

              {/* Transparent - Opaque Spandrel Modules */}
              <FormikCheckbox
                paddingTop={"2rem"}
                checked={
                  !(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .totalTransparentOpaqueSpandrelModulesPriceCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .totalTransparentOpaqueSpandrelModulesPriceCalculation ==
                      1
                  )
                }
                defaultChecked={
                  !(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .totalTransparentOpaqueSpandrelModulesPriceCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .totalTransparentOpaqueSpandrelModulesPriceCalculation ==
                      1
                  )
                }
                onChange={() => {
                  if (
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                  ) {
                    if (
                      !props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject
                        .totalTransparentOpaqueSpandrelModulesPriceCalculation ||
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .fancadeTechnicalProject
                        .totalTransparentOpaqueSpandrelModulesPriceCalculation ==
                        0
                    )
                      props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.totalTransparentOpaqueSpandrelModulesPriceCalculation = 1;
                    else
                      props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.totalTransparentOpaqueSpandrelModulesPriceCalculation = 0;
                    setUpdate(!update);
                  }
                }}
              >
                {t(
                  `formLabels.details.fancadeProduction.transparentOpaqueSpandrelModulesScrapByTotal`
                )}
              </FormikCheckbox>
              {!(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalTransparentOpaqueSpandrelModulesPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalTransparentOpaqueSpandrelModulesPriceCalculation == 1
              ) ? (
                <>
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.totalTransparentOpaqueSpandrelModulesPrice`,
                        label: t(
                          `formLabels.details.fancadeProduction.totalTransparentOpaqueSpandrelModulesPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.totalTransparentOpaqueSpandrelModulesPrice`
                        ),
                        type: "number",
                        width: 100,
                      },
                    ]}
                  />
                </>
              ) : (
                <Container
                  title={
                    t(
                      `formLabels.details.fancadeProduction.totalTransparentOpaqueSpandrelModulesPrice`
                    ) + t(`titles.calculatedSection`)
                  }
                  bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                  direction={"column"}
                  spacing={"1rem"}
                >
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.totalTransparentOpaqueSpandrelModulesPrice`,
                        label: t(
                          `formLabels.details.fancadeProduction.totalTransparentOpaqueSpandrelModulesPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.totalTransparentOpaqueSpandrelModulesPrice`
                        ),
                        type: "number",
                        width: 100,
                        disabled: true,
                        value:
                          +props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                            .transparentOpaqueSpandrelModulesQuantity *
                          +getScrapUnitPrice(
                            "opaquespandrelmodules",
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ]
                          ),
                      },
                    ]}
                  />
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.transparentOpaqueSpandrelModulesQuantity`,
                        label: t(
                          `formLabels.details.fancadeProduction.transparentOpaqueSpandrelModulesQuantity`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.transparentOpaqueSpandrelModulesQuantity`
                        ),
                        type: "number",
                        width: 100,
                      },
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.transparentOpaqueSpandrelModulesScrapUnitPrice`,
                        label: t(
                          `formLabels.details.fancadeProduction.transparentOpaqueSpandrelModulesScrapUnitPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.transparentOpaqueSpandrelModulesScrapUnitPrice`
                        ),
                        type: "number",
                        width: 100,
                        disabled: true,
                        value: +getScrapUnitPrice(
                          "opaquespandrelmodules",
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ]
                        ),
                      },
                    ]}
                  />
                </Container>
              )}
            </>
          ) : (
            <></>
          )}
          {/* Fixings */}
          <FormikCheckbox
            paddingTop={"2rem"}
            checked={
              !(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject.totalFixingsScrapPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject.totalFixingsScrapPriceCalculation ==
                  1
              )
            }
            defaultChecked={
              !(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject.totalFixingsScrapPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject.totalFixingsScrapPriceCalculation ==
                  1
              )
            }
            onChange={() => {
              if (
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
              ) {
                if (
                  !props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject
                    .totalFixingsScrapPriceCalculation ||
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject
                    .totalFixingsScrapPriceCalculation == 0
                )
                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].fancadeTechnicalProject.totalFixingsScrapPriceCalculation = 1;
                else
                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].fancadeTechnicalProject.totalFixingsScrapPriceCalculation = 0;
                setUpdate(!update);
              }
            }}
          >
            {t(`formLabels.details.fancadeProduction.fixingsScrapByTotal`)}
          </FormikCheckbox>
          {!(
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject.totalFixingsScrapPriceCalculation &&
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject.totalFixingsScrapPriceCalculation == 1
          ) ? (
            <>
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.totalFixingsScrapPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.totalFixingsScrapPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.totalFixingsScrapPrice`
                    ),
                    type: "number",
                    width: 100,
                  },
                ]}
              />
            </>
          ) : (
            <Container
              title={
                t(
                  `formLabels.details.fancadeProduction.totalFixingsScrapPrice`
                ) + t(`titles.calculatedSection`)
              }
              bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
              direction={"column"}
              spacing={"1rem"}
            >
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.totalFixingsScrapPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.totalFixingsScrapPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.totalFixingsScrapPrice`
                    ),
                    type: "number",
                    width: 100,
                    disabled: true,
                    value:
                      +props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.fixingsScrapQuantity *
                      +getScrapUnitPrice(
                        "fixings",
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ]
                      ),
                  },
                ]}
              />
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.fixingsScrapQuantity`,
                    label: t(
                      `formLabels.details.fancadeProduction.fixingsScrapQuantity`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.fixingsScrapQuantity`
                    ),
                    type: "number",
                    width: 100,
                  },
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.fixingsScrapUnitPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.fixingsScrapUnitPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.fixingsScrapUnitPrice`
                    ),
                    type: "number",
                    width: 100,
                    disabled: true,
                    value: +getScrapUnitPrice(
                      "fixings",
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                    ),
                  },
                ]}
              />
            </Container>
          )}
          {/* Anchorings */}
          <FormikCheckbox
            paddingTop={"2rem"}
            checked={
              !(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalAnchoringsScrapPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalAnchoringsScrapPriceCalculation == 1
              )
            }
            defaultChecked={
              !(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalAnchoringsScrapPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalAnchoringsScrapPriceCalculation == 1
              )
            }
            onChange={() => {
              if (
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
              ) {
                if (
                  !props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject
                    .totalAnchoringsScrapPriceCalculation ||
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject
                    .totalAnchoringsScrapPriceCalculation == 0
                )
                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].fancadeTechnicalProject.totalAnchoringsScrapPriceCalculation = 1;
                else
                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].fancadeTechnicalProject.totalAnchoringsScrapPriceCalculation = 0;
                setUpdate(!update);
              }
            }}
          >
            {t(`formLabels.details.fancadeProduction.anchoringsScrapByTotal`)}
          </FormikCheckbox>
          {!(
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject.totalAnchoringsScrapPriceCalculation &&
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject.totalAnchoringsScrapPriceCalculation ===
              1
          ) ? (
            <>
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.totalAnchoringsScrapPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.totalAnchoringsScrapPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.totalAnchoringsScrapPrice`
                    ),
                    type: "number",
                    width: 100,
                  },
                ]}
              />
            </>
          ) : (
            <Container
              title={
                t(
                  `formLabels.details.fancadeProduction.totalAnchoringsScrapPrice`
                ) + t(`titles.calculatedSection`)
              }
              bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
              direction={"column"}
              spacing={"1rem"}
            >
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.totalAnchoringsScrapPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.totalAnchoringsScrapPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.totalAnchoringsScrapPrice`
                    ),
                    type: "number",
                    width: 100,
                    disabled: true,
                    value:
                      +props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.anchoringsScrapQuantity *
                      +getScrapUnitPrice(
                        "anchoring",
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ]
                      ),
                  },
                ]}
              />
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.anchoringsScrapQuantity`,
                    label: t(
                      `formLabels.details.fancadeProduction.anchoringsScrapQuantity`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.anchoringsScrapQuantity`
                    ),
                    type: "number",
                    width: 100,
                  },
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.anchoringsScrapUnitPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.anchoringsScrapUnitPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.anchoringsScrapUnitPrice`
                    ),
                    type: "number",
                    width: 100,
                    disabled: true,
                    value: +getScrapUnitPrice(
                      "anchoring",
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                    ),
                  },
                ]}
              />
            </Container>
          )}
          {/* Accessories */}
          <FormRow
            fields={[
              {
                name: `fancades[${
                  props.fancadeNumber - 1
                }].fancadeTechnicalProject.accessoriesScrap`,
                label: t(
                  `formLabels.details.fancadeProduction.accessoriesScrap`
                ),
                placeholder: t(
                  `formPlaceholders.details.fancadeProduction.accessoriesScrap`
                ),
                type: "number",
                width: 100,
              },
            ]}
          />{" "}
        </Container>
      )}
    </Container>
  );
};

const StyledButton = styled(Button)`
  color: white;
  background-color: ${theme.colors.euracOrange};
  border-radius: 500rem;

  &:hover {
    background-color: ${transparentize(0.5, theme.colors.euracOrange)};
  }
`;
