import React, { useCallback } from "react";
import {
  Button,
  Flex,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../../../../hooks/useStores";
import { Redirect, useParams } from "react-router-dom";
import { BuildingSiteForm } from "./BuildingSiteForm";
import { Fancade, Project } from "../../../../../../types";

import theme from "../../../../../../theme/theme";
import { AiFillEdit } from "react-icons/ai";
import { DetailsTable } from "../../DetailsTable";
import {
  calculateBenchmark,
  calculateInstallationTotal,
  calculateSecurityTotal,
  toFancadeAPI,
} from "../../../../../../services/utils";
import { DeleteAlert } from "../../../../../Alert";
import { ChangeFancadeNameModal } from "../../../../../ChangeFancadeNameModal";

interface RouteParams {
  projectId: string;
}

type Props = {
  formikProps: any;
  tabIndex: any;
  setTabIndex: any;
  isBenchmark: boolean;
};
export const BuildingSite: React.FC<Props> = observer((props) => {
  const { t } = useTranslation();
  const { session, ui, projects } = useStores();
  const { projectId } = useParams<RouteParams>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [deleteFancadeOpen, setDeleteFancadeOpen] = React.useState(false);
  const [fancadeToUpdate, setFancadeToUpdate] = React.useState<number>(0);
  const [update, setUpdate] = React.useState(false);

  ui.setActiveScreen("projects");

  const handleTabsChange = (index: number) => {
    props.setTabIndex(index);
  };

  let currentProject: Project = props.formikProps.values;
  const addFancade = useCallback(() => {
    if (!currentProject.fancades) currentProject.fancades = [];
    currentProject.fancades.push(projects.getEmptyFancade(+projectId));
    if (currentProject && currentProject.fancades) {
      props.setTabIndex(currentProject.fancades.length - 1);
    }
    setUpdate(!update);
  }, [props.tabIndex, projects, currentProject, props.formikProps, update]);

  const deleteFancade = useCallback(
    (fancade: Fancade, index: number) => {
      if (fancade.id != -1) projects.deleteFancade(toFancadeAPI(fancade));
      props.formikProps.values.fancades.splice(index, 1);
      if (props.tabIndex == index && props.tabIndex > 0) {
        props.setTabIndex(props.tabIndex - 1);
      }
    },
    [
      props.tabIndex,
      projects,
      currentProject,
      props.formikProps.values.fancades,
    ]
  );

  const getOneFancadeKPIsRows = (fancade: Fancade) => {
    if (fancade && fancade.buildingSite) {
      let rows: any[] = [];

      let installationSecurity = 0;
      let installation = 0;
      let security = 0;
      let surface = 0;

      let installationSecuritySurfaceRatio = 0;
      let installationSurfaceRatio = 0;
      let securitySurfaceRatio = 0;

      let installationSecurityInstallationRatio = 0;
      let installationSecuritySecurityRatio = 0;

      installationSecurity =
        +calculateInstallationTotal(fancade) + +calculateSecurityTotal(fancade);
      installation = +calculateInstallationTotal(fancade);
      security = +calculateSecurityTotal(fancade);
      if (fancade.fancadeTechnicalProject.surface)
        surface = +fancade.fancadeTechnicalProject.surface;

      if (installationSecurity && surface)
        installationSecuritySurfaceRatio = +installationSecurity / +surface;
      if (installation && surface)
        installationSurfaceRatio = +installation / +surface;
      if (security && surface) securitySurfaceRatio = +security / +surface;

      if (installation && installationSecurity)
        installationSecurityInstallationRatio =
          +installation / +installationSecurity;
      if (security && installationSecurity)
        installationSecuritySecurityRatio = +security / +installationSecurity;

      rows = [
        {
          name: t(
            "formLabels.details.buildingSite.totalInstallationSecurityPrice"
          ),
          values: [
            installationSecurity.toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " €",
          ],
        },
        {
          name: t("formLabels.details.buildingSite.totalInstallationPrice"),
          values: [
            installation.toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " €",
          ],
        },
        {
          name: t("formLabels.details.buildingSite.totalSecurityPrice"),
          values: [
            security.toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " €",
          ],
        },
        {
          name: t("formLabels.details.buildingSite.surface"),
          values: [
            (+surface).toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " m²",
          ],
        },
        {
          name: t(
            "formLabels.details.buildingSite.installationSecuritySurfaceRatio"
          ),
          values: [
            installationSecuritySurfaceRatio.toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " €/m²",
          ],
        },
        {
          name: t("formLabels.details.buildingSite.installationSurfaceRatio"),
          values: [
            installationSurfaceRatio.toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " €/m²",
          ],
        },
        {
          name: t("formLabels.details.buildingSite.securitySurfaceRatio"),
          values: [
            securitySurfaceRatio.toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " €/m²",
          ],
        },
        {
          name: t("formLabels.details.buildingSite.installationTotalRatio"),
          values: [
            (installationSecurityInstallationRatio * 100).toLocaleString(
              "cs-CZ",
              {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }
            ) + " %",
          ],
        },
        {
          name: t("formLabels.details.buildingSite.securityTotalRatio"),
          values: [
            (installationSecuritySecurityRatio * 100).toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " %",
          ],
        },
      ];
      return rows;
    }
    return [];
  };

  const getBenchmarkKPIRows = (
    project: Project,
    currentFancadeIndex: number
  ) => {
    let rows: any[] = [];
    let benchmark;
    let current;

    if (project && project.fancades && project?.fancades[0])
      benchmark = project?.fancades[0];

    if (project && project.fancades && project?.fancades[currentFancadeIndex])
      current = project?.fancades[currentFancadeIndex];

    let currentTotalCost = 0;
    let benchmarkTotalCost = 0;

    let currentTotalCostRatio = 0;
    let benchmarkTotalCostRatio = 0;

    if (current) {
      currentTotalCost =
        +calculateInstallationTotal(current) + +calculateSecurityTotal(current);
      if (
        current.fancadeTechnicalProject.surface &&
        current.fancadeTechnicalProject.surface != 0
      )
        currentTotalCostRatio =
          +currentTotalCost / +current.fancadeTechnicalProject.surface;
    }
    if (benchmark) {
      benchmarkTotalCost =
        +calculateInstallationTotal(benchmark) +
        +calculateSecurityTotal(benchmark);
      if (
        benchmark.fancadeTechnicalProject.surface &&
        benchmark.fancadeTechnicalProject.surface != 0
      )
        benchmarkTotalCostRatio =
          +benchmarkTotalCost / +benchmark.fancadeTechnicalProject.surface;
    }

    rows = [
      {
        name: t("formLabels.details.buildingSite.buildingSiteTotal"),
        values: [
          calculateBenchmark(
            +benchmarkTotalCost,
            +currentTotalCost
          ).toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " €",
        ],
      },
      {
        name: t("formLabels.details.buildingSite.buildingSiteTotalKpi"),
        values: [
          calculateBenchmark(
            +benchmarkTotalCostRatio,
            +currentTotalCostRatio
          ).toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " €/m²",
        ],
      },
    ];

    return rows;
  };

  const getAllFancadesKPIRows = (
    project: Project,
    currentFancadeIndex: number
  ) => {
    let rows: any[] = [];

    let installationSecurity = 0;
    let installation = 0;
    let security = 0;
    let surface = 0;

    let installationSecuritySurfaceRatio = 0;
    let installationSurfaceRatio = 0;
    let securitySurfaceRatio = 0;

    let installationSecurityInstallationRatio = 0;
    let installationSecuritySecurityRatio = 0;

    project.fancades?.map((fancade, index) => {
      installationSecurity +=
        +calculateInstallationTotal(fancade) + +calculateSecurityTotal(fancade);
      installation += +calculateInstallationTotal(fancade);
      security += +calculateSecurityTotal(fancade);
      if (fancade.fancadeTechnicalProject.surface)
        surface += +fancade.fancadeTechnicalProject.surface;
    });
    if (installationSecurity && surface)
      installationSecuritySurfaceRatio = +installationSecurity / +surface;
    if (installation && surface)
      installationSurfaceRatio = +installation / +surface;
    if (security && surface) securitySurfaceRatio = +security / +surface;

    if (installation && installationSecurity)
      installationSecurityInstallationRatio =
        +installation / +installationSecurity;
    if (security && installationSecurity)
      installationSecuritySecurityRatio = +security / +installationSecurity;

    //Store project kpis
    project.kpiBuildingSite = {
      totalInstallationSecurityPrice: installationSecurity,
      installation: installation,
      security: security,

      installationSecuritySurfaceRatio: installationSecuritySurfaceRatio,
      installationSurfaceRatio: installationSurfaceRatio,
      securitySurfaceRatio: securitySurfaceRatio,

      installationTotalRatio: installationSecurityInstallationRatio,
      securityTotalRatio: installationSecuritySecurityRatio,
    };
    rows = [
      {
        name: t(
          "formLabels.details.buildingSite.totalInstallationSecurityPrice"
        ),
        values: [
          installationSecurity.toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " €",
        ],
      },
      {
        name: t("formLabels.details.buildingSite.totalInstallationPrice"),
        values: [
          installation.toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " €",
        ],
      },
      {
        name: t("formLabels.details.buildingSite.totalSecurityPrice"),
        values: [
          security.toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " €",
        ],
      },
      {
        name: t("formLabels.details.buildingSite.surface"),
        values: [
          (+surface).toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " m²",
        ],
      },
      {
        name: t(
          "formLabels.details.buildingSite.installationSecuritySurfaceRatio"
        ),
        values: [
          installationSecuritySurfaceRatio.toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " €/m²",
        ],
      },
      {
        name: t("formLabels.details.buildingSite.installationSurfaceRatio"),
        values: [
          installationSurfaceRatio.toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " €/m²",
        ],
      },
      {
        name: t("formLabels.details.buildingSite.securitySurfaceRatio"),
        values: [
          securitySurfaceRatio.toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " €/m²",
        ],
      },
      {
        name: t("formLabels.details.buildingSite.installationTotalRatio"),
        values: [
          (installationSecurityInstallationRatio * 100).toLocaleString(
            "cs-CZ",
            {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }
          ) + " %",
        ],
      },
      {
        name: t("formLabels.details.buildingSite.securityTotalRatio"),
        values: [
          (installationSecuritySecurityRatio * 100).toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " %",
        ],
      },
    ];
    return rows;
  };
  if (session.user)
    return (
      <Flex w={"100%"}>
        <ChangeFancadeNameModal
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={(values: any) => {
            if (currentProject && currentProject.fancades)
              currentProject.fancades[fancadeToUpdate].customName =
                values.customName;
            onClose();
          }}
        />
        <Tabs
          index={props.tabIndex}
          onChange={handleTabsChange}
          variant={"enclosed"}
          w={"100%"}
        >
          <TabList>
            {currentProject?.fancades?.map((fancade, index) => (
              <Tab alignItems={"center"}>
                <DeleteAlert
                  title={t("warnings.deleteFancadeTitle")}
                  body={t("warnings.deleteFancade")}
                  isOpen={deleteFancadeOpen}
                  setIsOpen={setDeleteFancadeOpen}
                  cancelText={t("warnings.cancel")}
                  continueText={t("warnings.confirm")}
                  onContinue={async () => {
                    deleteFancade(fancade, index);
                  }}
                />
                <Text color={theme.colors.darkGrey2}>
                  {fancade.customName
                    ? currentProject.isBenchmark && index == 0
                      ? t("tabs.details.fancadeTechnicalProject.benchmark") +
                        fancade.customName
                      : fancade.customName
                    : currentProject.isBenchmark && index == 0
                    ? t("tabs.details.fancadeTechnicalProject.firstTab")
                    : t("tabs.details.fancadeTechnicalProject.tabsAfterFirst") +
                      (index + 1)}
                </Text>
                <AiFillEdit
                  style={{ marginLeft: "0.3rem" }}
                  onClick={() => {
                    setFancadeToUpdate(index);
                    onOpen();
                  }}
                />
                <Text
                  onClick={() => setDeleteFancadeOpen(true)}
                  color={theme.colors.euracOrange}
                  paddingLeft={"0.5rem"}
                >
                  <strong>x</strong>
                </Text>
              </Tab>
            ))}
            <Button variant={"ghost"} onClick={addFancade}>
              <Text color={theme.colors.euracOrange}>+</Text>
            </Button>
          </TabList>
          <TabPanels>
            {currentProject?.fancades?.map((fancade, index) => (
              <TabPanel>
                <Stack w={"100%"} spacing={"2rem"}>
                  <BuildingSiteForm
                    fancadeNumber={index + 1}
                    fancadeId={fancade.id}
                    fancadeType={fancade?.fancadeTechnicalProject?.type}
                    formikProps={props.formikProps}
                  />
                  <DetailsTable
                    columnsTitles={[
                      t("formLabels.details.buildingSite.kpiTitle"),
                    ]}
                    rows={getOneFancadeKPIsRows(fancade)}
                  />
                  {index > 0 && props.isBenchmark && (
                    <DetailsTable
                      columnsTitles={[
                        t("formLabels.details.buildingSite.benchmarkTitle"),
                      ]}
                      rows={getBenchmarkKPIRows(currentProject, index)}
                    />
                  )}

                  {currentProject.fancades &&
                    currentProject.fancades.length > 1 && (
                      <DetailsTable
                        columnsTitles={[
                          t("formLabels.details.buildingSite.kpiAllTitle"),
                        ]}
                        rows={getAllFancadesKPIRows(currentProject, index)}
                      />
                    )}
                </Stack>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Flex>
    );
  else return <Redirect to={"/"} />;
});
