import React, { useState } from "react";
import { Button, Center, Divider, Flex, Stack, Text } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../../../../hooks/useStores";
import { Redirect, useParams } from "react-router-dom";

import styled from "styled-components";
import { transparentize } from "polished";
import theme from "../../../../../../theme/theme";
import { FormRow } from "../../../../../FormRow";
import { Action, Project } from "../../../../../../types";
import {
  calculateExtraordinaryMaintenance,
  calculateMaintenancePeriodCost,
  calculateMaintenanceTotal,
  calculateOrdinaryMaintenance,
} from "../../../../../../services/utils";
import { FormikCheckbox } from "../../../../../FormikCheckbox";
import { getProjectRefLifeOptions } from "../../../../../../services/selectsOptions";
import { Container } from "../../../../../Container";
import { PeriodFields } from "../../../../../PeriodFields";

interface RouteParams {
  projectId: string;
}

type props = {
  fancadeNumber: number;
  fancadeId: number;
  fancadeType: string | null;
  formikProps: any;
};

export const MaintenanceForm: React.FC<props> = observer((props) => {
  const { t } = useTranslation();
  const { session, ui, projects } = useStores();
  const { projectId } = useParams<RouteParams>();
  const [update, setUpdate] = useState(false);

  ui.setActiveScreen("projects");

  const currentProject: Project = props.formikProps.values;

  const currentFancade = currentProject?.fancades?.find((element) => {
    return +element.id == props.fancadeId;
  });

  const refLifeOptions = getProjectRefLifeOptions();

  if (session.user)
    return (
      <Stack w={"100%"}>
        <FormRow
          fields={[
            {
              name: `fancades[${props.fancadeNumber - 1}].maintenance.refLife`,
              label: t(`formLabels.details.maintenance.refLife`),
              type: "number",
              width: 100,
            },
          ]}
        />

        <FormRow
          fields={[
            {
              name: `fancades[${
                props.fancadeNumber - 1
              }].maintenance.totalMaintenancePriceCalculation`,
              label: t(`formLabels.details.maintenance.maintenanceSelect`),
              type: "select",
              selectOptions: [
                {
                  text: t(`formLabels.details.maintenance.manualOption`),
                  value: 0,
                },
                {
                  text: t(`formLabels.details.maintenance.percentageOption`),
                  value: 1,
                },
                {
                  text: t(`formLabels.details.maintenance.sumOption`),
                  value: 2,
                },
              ],
              width: 100,
            },
          ]}
        />
        {!props.formikProps.values.fancades[props.fancadeNumber - 1].maintenance
          .totalMaintenancePriceCalculation ||
        props.formikProps.values.fancades[props.fancadeNumber - 1].maintenance
          .totalMaintenancePriceCalculation == 0 ? (
          <>
            <FormRow
              fields={[
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].maintenance.totalMaintenancePrice`,
                  label: t(
                    `formLabels.details.maintenance.totalMaintenancePrice`
                  ),
                  placeholder: t(
                    `formPlaceholders.details.maintenance.totalMaintenancePrice`
                  ),
                  type: "number",
                  width: 100,
                },
              ]}
            />
          </>
        ) : props.formikProps.values.fancades[props.fancadeNumber - 1]
            .maintenance.totalMaintenancePriceCalculation &&
          +props.formikProps.values.fancades[props.fancadeNumber - 1]
            .maintenance.totalMaintenancePriceCalculation == 1 ? (
          <>
            {/* Percentage */}
            <FormRow
              fields={[
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].maintenance.totalMaintenancePrice`,
                  label: t(
                    `formLabels.details.maintenance.totalMaintenancePrice`
                  ),
                  placeholder: t(
                    `formPlaceholders.details.maintenance.totalMaintenancePrice`
                  ),
                  type: "number",
                  width: 100,
                  disabled: true,
                  value: calculateMaintenanceTotal(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                  ),
                },
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].maintenance.totalMaintenancePercentage`,
                  label: t(
                    `formLabels.details.maintenance.totalMaintenancePercentage`
                  ),
                  placeholder: t(
                    `formPlaceholders.details.maintenance.totalMaintenancePercentage`
                  ),
                  type: "number",
                  width: 100,
                },
              ]}
            />
          </>
        ) : (
          <Container
            title={
              t(`formLabels.details.maintenance.totalMaintenancePrice`) +
              t(`titles.calculatedSection`)
            }
            bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
            direction={"column"}
            spacing={"1rem"}
          >
            {/* Components */}
            <FormRow
              fields={[
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].maintenance.totalMaintenancePrice`,
                  label: t(
                    `formLabels.details.maintenance.totalMaintenancePrice`
                  ),
                  placeholder: t(
                    `formPlaceholders.details.maintenance.totalMaintenancePrice`
                  ),
                  type: "number",
                  width: 100,
                  disabled: true,
                  value: calculateMaintenanceTotal(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                  ),
                },
              ]}
            />

            {/* Ordinary Maintenance */}
            <Container title={t('formLabels.details.maintenance.ordinaryMaitenance')}>
              <Stack w={"100%"}>
                <FormRow
                  fields={[
                    {
                      name: `fancades[${
                        props.fancadeNumber - 1
                      }].maintenance.totalOrdinaryMaintenancePriceCalculation`,
                      label: t(
                        `formLabels.details.maintenance.totalOrdinaryMaintenancePriceSelect`
                      ),
                      type: "select",
                      selectOptions: [
                        {
                          text: t(
                            `formLabels.details.maintenance.manualOption`
                          ),
                          value: 0,
                        },
                        {
                          text: t(
                            `formLabels.details.maintenance.percentageOption`
                          ),
                          value: 1,
                        },
                        {
                          text: t(`formLabels.details.maintenance.sumOption`),
                          value: 2,
                        },
                      ],
                      width: 100,
                    },
                  ]}
                />
                {!props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .maintenance.totalOrdinaryMaintenancePriceCalculation ||
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .maintenance.totalOrdinaryMaintenancePriceCalculation == 0 ? (
                  <>
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].maintenance.totalOrdinaryMaintenancePrice`,
                          label: t(
                            `formLabels.details.maintenance.totalOrdinaryMaintenancePrice`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.maintenance.totalOrdinaryMaintenancePrice`
                          ),
                          type: "number",
                          width: 100,
                        },
                      ]}
                    />
                    <PeriodFields
                      calculation={
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].maintenance.totalOrdinaryMaintenancePeriodCalculation
                      }
                      setCalculation={(value: number) => {
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].maintenance.totalOrdinaryMaintenancePeriodCalculation =
                          value;
                      }}
                      everyHowManyYears={
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].maintenance.totalOrdinaryMaintenanceEveryHowManyYears
                      }
                      name={`fancades[${
                        props.fancadeNumber - 1
                      }].maintenance.totalOrdinaryMaintenanceEveryHowManyYears`}
                      update={update}
                      setUpdate={setUpdate}
                    />
                    {/* <FormikCheckbox
                      checked={
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].maintenance
                          .totalOrdinaryMaintenancePeriodCalculation === 1
                      }
                      leftText={t(
                        "formLabels.details.maintenance.periodByEveryHowManyYears"
                      )}
                      onChange={(event: any) => {
                        let calculation =
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance
                            .totalOrdinaryMaintenancePeriodCalculation;

                        if (!calculation || calculation === 0) {
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance.totalOrdinaryMaintenancePeriodCalculation = 1;
                        } else {
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance.totalOrdinaryMaintenancePeriodCalculation = 0;
                        }
                        setUpdate(!update);
                      }}
                    >
                      {t("formLabels.details.maintenance.periodByTimesInAYear")}
                    </FormikCheckbox>

                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].maintenance.totalOrdinaryMaintenanceEveryHowManyYears`,
                          label: t(
                            `formLabels.details.maintenance.everyHowManyYears`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.maintenance.everyHowManyYears`
                          ),
                          type: "number",
                          width: 100,
                          keepField: true,
                          value:
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].maintenance
                              .totalOrdinaryMaintenancePeriodCalculation === 1
                              ? 1 /
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalOrdinaryMaintenanceEveryHowManyYears
                              : +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalOrdinaryMaintenanceEveryHowManyYears,
                          disabled:
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].maintenance
                              .totalOrdinaryMaintenancePeriodCalculation === 1,
                        },
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].maintenance.totalOrdinaryMaintenanceEveryHowManyYears`,
                          label: t(
                            `formLabels.details.maintenance.timesInAYear`
                          ),
                          placeholder: t(
                            `formLabels.details.maintenance.timesInAYear`
                          ),
                          type: "number",
                          width: 100,
                          keepField: true,
                          value:
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].maintenance
                              .totalOrdinaryMaintenancePeriodCalculation === 1
                              ? +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalOrdinaryMaintenanceEveryHowManyYears
                              : 1 /
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalOrdinaryMaintenanceEveryHowManyYears,
                          disabled: !(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].maintenance
                              .totalOrdinaryMaintenancePeriodCalculation === 1
                          ),
                        },
                      ]}
                    /> */}
                  </>
                ) : props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .maintenance.totalOrdinaryMaintenancePriceCalculation &&
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .maintenance.totalOrdinaryMaintenancePriceCalculation ==
                    1 ? (
                  <>
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].maintenance.totalOrdinaryMaintenancePrice`,
                          label: t(
                            `formLabels.details.maintenance.totalOrdinaryMaintenancePriceByYear`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.maintenance.totalOrdinaryMaintenancePrice`
                          ),
                          type: "number",
                          width: 100,
                          disabled: true,
                          value: calculateOrdinaryMaintenance(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ]
                          ),
                        },
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].maintenance.totalOrdinaryMaintenancePercentage`,
                          label: t(
                            `formLabels.details.maintenance.totalOrdinaryMaintenancePercentage`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.maintenance.totalOrdinaryMaintenancePercentage`
                          ),
                          type: "number",
                          width: 100,
                        },
                      ]}
                    />
                  </>
                ) : (
                  <Container
                    title={
                      t(
                        `formLabels.details.maintenance.totalOrdinaryMaintenancePriceByYear`
                      ) + t(`titles.calculatedSection`)
                    }
                    bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                    direction={"column"}
                    spacing={"1rem"}
                  >
                    {/* Ordinary Maintenance by components  */}
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].maintenance.totalOrdinaryMaintenancePrice`,
                          label: t(
                            `formLabels.details.maintenance.totalOrdinaryMaintenancePriceByYear`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.maintenance.totalOrdinaryMaintenancePrice`
                          ),
                          type: "number",
                          width: 100,
                          disabled: true,
                          value: calculateOrdinaryMaintenance(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ]
                          ),
                        },
                      ]}
                    />
                    {props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject.type &&
                    props.formikProps.values.fancades[
                      props.fancadeNumber - 1
                    ].fancadeTechnicalProject.type
                      .toLowerCase()
                      .indexOf("opaca") != -1 ? (
                      <>
                        {/* Opaque fancade */}
                        {/* Window */}
                        <FormikCheckbox
                          paddingTop={"2rem"}
                          checked={
                            !(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalWindowReplacementPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalWindowReplacementPriceCalculation == 1
                            )
                          }
                          defaultChecked={
                            !(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalWindowReplacementPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalWindowReplacementPriceCalculation == 1
                            )
                          }
                          onChange={() => {
                            if (
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                            ) {
                              if (
                                !props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalWindowReplacementPriceCalculation ||
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalWindowReplacementPriceCalculation == 0
                              )
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.totalWindowReplacementPriceCalculation = 1;
                              else
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.totalWindowReplacementPriceCalculation = 0;
                              setUpdate(!update);
                            }
                          }}
                        >
                          {t(
                            `formLabels.details.maintenance.totalWindowReplacementPriceIsTotal`
                          )}
                        </FormikCheckbox>
                        {!(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance
                            .totalWindowReplacementPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance
                            .totalWindowReplacementPriceCalculation == 1
                        ) ? (
                          <>
                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.totalWindowReplacementPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.totalWindowReplacementPrice`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.totalWindowReplacementPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                              ]}
                            />
                            <PeriodFields
                              calculation={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.windowPeriodCalculation
                              }
                              setCalculation={(value: number) => {
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.windowPeriodCalculation = value;
                              }}
                              everyHowManyYears={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.windowEveryHowManyYears
                              }
                              name={`fancades[${
                                props.fancadeNumber - 1
                              }].maintenance.windowEveryHowManyYears`}
                              update={update}
                              setUpdate={setUpdate}
                            />
                          </>
                        ) : (
                          <Container
                            title={
                              t(
                                `formLabels.details.maintenance.totalWindowReplacementPriceByYear`
                              ) + t(`titles.calculatedSection`)
                            }
                            bodyStyle={{
                              backgroundColor: theme.colors.lightOrange,
                            }}
                            direction={"column"}
                            spacing={"1rem"}
                          >
                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.totalWindowReplacementPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.totalWindowReplacementPriceByYear`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.totalWindowReplacementPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                  disabled: true,
                                  value: calculateMaintenancePeriodCost(
                                    props.formikProps.values.fancades[
                                      +props.fancadeNumber - 1
                                    ].maintenance.windowPeriodCalculation,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance.windowEveryHowManyYears,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance.windowUnitPrice,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance.windowsNumber,
                                    undefined
                                  ),
                                },
                              ]}
                            />

                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.windowsNumber`,
                                  label: t(
                                    `formLabels.details.maintenance.windowsNumber`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.windowsNumber`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.windowUnitPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.windowUnitPrice`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.windowUnitPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                              ]}
                            />
                            <PeriodFields
                              calculation={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.windowPeriodCalculation
                              }
                              setCalculation={(value: number) => {
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.windowPeriodCalculation = value;
                              }}
                              everyHowManyYears={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.windowEveryHowManyYears
                              }
                              name={`fancades[${
                                props.fancadeNumber - 1
                              }].maintenance.windowEveryHowManyYears`}
                              update={update}
                              setUpdate={setUpdate}
                            />
                          </Container>
                        )}

                        {/* Loom or Fixings */}
                        <FormikCheckbox
                          paddingTop={"2rem"}
                          checked={
                            !(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalOpaqueLoomFixingsReplacementPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalOpaqueLoomFixingsReplacementPriceCalculation ==
                                1
                            )
                          }
                          defaultChecked={
                            !(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalOpaqueLoomFixingsReplacementPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalOpaqueLoomFixingsReplacementPriceCalculation ==
                                1
                            )
                          }
                          onChange={() => {
                            if (
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                            ) {
                              if (
                                !props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalOpaqueLoomFixingsReplacementPriceCalculation ||
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalOpaqueLoomFixingsReplacementPriceCalculation ==
                                  0
                              )
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.totalOpaqueLoomFixingsReplacementPriceCalculation = 1;
                              else
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.totalOpaqueLoomFixingsReplacementPriceCalculation = 0;
                              setUpdate(!update);
                            }
                          }}
                        >
                          {t(
                            `formLabels.details.maintenance.totalOpaqueLoomFixingsReplacementPriceIsTotal`
                          )}
                        </FormikCheckbox>
                        {!(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance
                            .totalOpaqueLoomFixingsReplacementPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance
                            .totalOpaqueLoomFixingsReplacementPriceCalculation ==
                            1
                        ) ? (
                          <>
                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.totalOpaqueLoomFixingsReplacementPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.totalOpaqueLoomFixingsReplacementPrice`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.totalOpaqueLoomFixingsReplacementPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                              ]}
                            />

                            <PeriodFields
                              calculation={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.opaqueLoomFixingsPeriodCalculation
                              }
                              setCalculation={(value: number) => {
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.opaqueLoomFixingsPeriodCalculation =
                                  value;
                              }}
                              everyHowManyYears={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.opaqueLoomFixingsEveryHowManyYears
                              }
                              name={`fancades[${
                                props.fancadeNumber - 1
                              }].maintenance.opaqueLoomFixingsEveryHowManyYears`}
                              update={update}
                              setUpdate={setUpdate}
                            />
                          </>
                        ) : (
                          <Container
                            title={
                              t(
                                `formLabels.details.maintenance.totalOpaqueLoomFixingsReplacementPriceByYear`
                              ) + t(`titles.calculatedSection`)
                            }
                            bodyStyle={{
                              backgroundColor: theme.colors.lightOrange,
                            }}
                            direction={"column"}
                            spacing={"1rem"}
                          >
                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.totalOpaqueLoomFixingsReplacementPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.totalOpaqueLoomFixingsReplacementPriceByYear`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.totalOpaqueLoomFixingsReplacementPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                  disabled: true,
                                  value: calculateMaintenancePeriodCost(
                                    props.formikProps.values.fancades[
                                      +props.fancadeNumber - 1
                                    ].maintenance
                                      .opaqueLoomFixingsPeriodCalculation,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance
                                      .opaqueLoomFixingsEveryHowManyYears,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance.opaqueLoomFixingsUnitPrice,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance.opaqueLoomFixingsQuantity,
                                    undefined
                                  ),
                                },
                              ]}
                            />

                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.opaqueLoomFixingsQuantity`,
                                  label: t(
                                    `formLabels.details.maintenance.opaqueLoomFixingsQuantity`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.opaqueLoomFixingsQuantity`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.opaqueLoomFixingsUnitPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.opaqueLoomFixingsUnitPrice`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.opaqueLoomFixingsUnitPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                              ]}
                            />
                            <PeriodFields
                              calculation={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.opaqueLoomFixingsPeriodCalculation
                              }
                              setCalculation={(value: number) => {
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.opaqueLoomFixingsPeriodCalculation =
                                  value;
                              }}
                              everyHowManyYears={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.opaqueLoomFixingsEveryHowManyYears
                              }
                              name={`fancades[${
                                props.fancadeNumber - 1
                              }].maintenance.opaqueLoomFixingsEveryHowManyYears`}
                              update={update}
                              setUpdate={setUpdate}
                            />
                          </Container>
                        )}

                        {/* Opaque Double Glazing */}
                        <FormikCheckbox
                          paddingTop={"2rem"}
                          checked={
                            !(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalOpaqueDoubleGlazingReplacementPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalOpaqueDoubleGlazingReplacementPriceCalculation ==
                                1
                            )
                          }
                          defaultChecked={
                            !(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalOpaqueDoubleGlazingReplacementPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalOpaqueDoubleGlazingReplacementPriceCalculation ==
                                1
                            )
                          }
                          onChange={() => {
                            if (
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                            ) {
                              if (
                                !props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalOpaqueDoubleGlazingReplacementPriceCalculation ||
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalOpaqueDoubleGlazingReplacementPriceCalculation ==
                                  0
                              )
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.totalOpaqueDoubleGlazingReplacementPriceCalculation = 1;
                              else
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.totalOpaqueDoubleGlazingReplacementPriceCalculation = 0;
                              setUpdate(!update);
                            }
                          }}
                        >
                          {t(
                            `formLabels.details.maintenance.totalOpaqueDoubleGlazingReplacementPriceIsTotal`
                          )}
                        </FormikCheckbox>
                        {!(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance
                            .totalOpaqueDoubleGlazingReplacementPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance
                            .totalOpaqueDoubleGlazingReplacementPriceCalculation ==
                            1
                        ) ? (
                          <>
                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.totalOpaqueDoubleGlazingReplacementPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.totalOpaqueDoubleGlazingReplacementPrice`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.totalOpaqueDoubleGlazingReplacementPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.opaqueDoubleGlazingEveryHowManyYears`,
                                  label: t(
                                    `formLabels.details.maintenance.everyHowManyYears`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.everyHowManyYears`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                              ]}
                            />

                            <PeriodFields
                              calculation={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .opaqueDoubleGlazingPeriodCalculation
                              }
                              setCalculation={(value: number) => {
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.opaqueDoubleGlazingPeriodCalculation =
                                  value;
                              }}
                              everyHowManyYears={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .opaqueDoubleGlazingEveryHowManyYears
                              }
                              name={`fancades[${
                                props.fancadeNumber - 1
                              }].maintenance.opaqueDoubleGlazingEveryHowManyYears`}
                              update={update}
                              setUpdate={setUpdate}
                            />
                          </>
                        ) : (
                          <Container
                            title={
                              t(
                                `formLabels.details.maintenance.totalOpaqueDoubleGlazingReplacementPriceByYear`
                              ) + t(`titles.calculatedSection`)
                            }
                            bodyStyle={{
                              backgroundColor: theme.colors.lightOrange,
                            }}
                            direction={"column"}
                            spacing={"1rem"}
                          >
                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.totalOpaqueDoubleGlazingReplacementPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.totalOpaqueDoubleGlazingReplacementPriceByYear`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.totalOpaqueDoubleGlazingReplacementPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                  disabled: true,
                                  value: calculateMaintenancePeriodCost(
                                    props.formikProps.values.fancades[
                                      +props.fancadeNumber - 1
                                    ].maintenance
                                      .opaqueDoubleGlazingPeriodCalculation,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance
                                      .opaqueDoubleGlazingEveryHowManyYears,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance.opaqueDoubleGlazingUnitPrice,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance.opaqueDoubleGlazingQuantity,
                                    undefined
                                  ),
                                },
                              ]}
                            />

                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.opaqueDoubleGlazingQuantity`,
                                  label: t(
                                    `formLabels.details.maintenance.opaqueDoubleGlazingQuantity`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.opaqueDoubleGlazingQuantity`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.opaqueDoubleGlazingUnitPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.opaqueDoubleGlazingUnitPrice`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.opaqueDoubleGlazingUnitPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                              ]}
                            />
                            <PeriodFields
                              calculation={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .opaqueDoubleGlazingPeriodCalculation
                              }
                              setCalculation={(value: number) => {
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.opaqueDoubleGlazingPeriodCalculation =
                                  value;
                              }}
                              everyHowManyYears={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .opaqueDoubleGlazingEveryHowManyYears
                              }
                              name={`fancades[${
                                props.fancadeNumber - 1
                              }].maintenance.opaqueDoubleGlazingEveryHowManyYears`}
                              update={update}
                              setUpdate={setUpdate}
                            />
                          </Container>
                        )}

                        {/* Opaque Shielding */}
                        <FormikCheckbox
                          paddingTop={"2rem"}
                          checked={
                            !(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalOpaqueShieldingsReplacementPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalOpaqueShieldingsReplacementPriceCalculation ==
                                1
                            )
                          }
                          defaultChecked={
                            !(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalOpaqueShieldingsReplacementPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalOpaqueShieldingsReplacementPriceCalculation ==
                                1
                            )
                          }
                          onChange={() => {
                            if (
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                            ) {
                              if (
                                !props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalOpaqueShieldingsReplacementPriceCalculation ||
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalOpaqueShieldingsReplacementPriceCalculation ==
                                  0
                              )
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.totalOpaqueShieldingsReplacementPriceCalculation = 1;
                              else
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.totalOpaqueShieldingsReplacementPriceCalculation = 0;
                              setUpdate(!update);
                            }
                          }}
                        >
                          {t(
                            `formLabels.details.maintenance.totalOpaqueShieldingsReplacementPriceIsTotal`
                          )}
                        </FormikCheckbox>
                        {!(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance
                            .totalOpaqueShieldingsReplacementPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance
                            .totalOpaqueShieldingsReplacementPriceCalculation ==
                            1
                        ) ? (
                          <>
                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.totalOpaqueShieldingsReplacementPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.totalOpaqueShieldingsReplacementPrice`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.totalOpaqueShieldingsReplacementPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.opaqueShieldingEveryHowManyYears`,
                                  label: t(
                                    `formLabels.details.maintenance.everyHowManyYears`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.everyHowManyYears`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                              ]}
                            />

                            <PeriodFields
                              calculation={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.opaqueShieldingPeriodCalculation
                              }
                              setCalculation={(value: number) => {
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.opaqueShieldingPeriodCalculation =
                                  value;
                              }}
                              everyHowManyYears={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.opaqueShieldingEveryHowManyYears
                              }
                              name={`fancades[${
                                props.fancadeNumber - 1
                              }].maintenance.opaqueShieldingEveryHowManyYears`}
                              update={update}
                              setUpdate={setUpdate}
                            />
                          </>
                        ) : (
                          <Container
                            title={
                              t(
                                `formLabels.details.maintenance.totalOpaqueShieldingsReplacementPriceByYear`
                              ) + t(`titles.calculatedSection`)
                            }
                            bodyStyle={{
                              backgroundColor: theme.colors.lightOrange,
                            }}
                            direction={"column"}
                            spacing={"1rem"}
                          >
                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.totalOpaqueShieldingsReplacementPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.totalOpaqueShieldingsReplacementPriceByYear`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.totalOpaqueShieldingsReplacementPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                  disabled: true,
                                  value: calculateMaintenancePeriodCost(
                                    props.formikProps.values.fancades[
                                      +props.fancadeNumber - 1
                                    ].maintenance
                                      .opaqueShieldingPeriodCalculation,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance
                                      .opaqueShieldingEveryHowManyYears,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance.opaqueShieldingsUnitPrice,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance.opaqueShieldingsQuantity,
                                    undefined
                                  ),
                                },
                              ]}
                            />

                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.opaqueShieldingsQuantity`,
                                  label: t(
                                    `formLabels.details.maintenance.opaqueShieldingsQuantity`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.opaqueShieldingsQuantity`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.opaqueShieldingsUnitPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.opaqueShieldingsUnitPrice`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.opaqueShieldingsUnitPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                              ]}
                            />

                            <PeriodFields
                              calculation={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.opaqueShieldingPeriodCalculation
                              }
                              setCalculation={(value: number) => {
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.opaqueShieldingPeriodCalculation =
                                  value;
                              }}
                              everyHowManyYears={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.opaqueShieldingEveryHowManyYears
                              }
                              name={`fancades[${
                                props.fancadeNumber - 1
                              }].maintenance.opaqueShieldingEveryHowManyYears`}
                              update={update}
                              setUpdate={setUpdate}
                            />
                          </Container>
                        )}

                        {/* External Cladding */}
                        <FormikCheckbox
                          paddingTop={"2rem"}
                          checked={
                            !(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalExternalCladdingReplacementPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalExternalCladdingReplacementPriceCalculation ==
                                1
                            )
                          }
                          defaultChecked={
                            !(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalExternalCladdingReplacementPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalExternalCladdingReplacementPriceCalculation ==
                                1
                            )
                          }
                          onChange={() => {
                            if (
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                            ) {
                              if (
                                !props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalExternalCladdingReplacementPriceCalculation ||
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalExternalCladdingReplacementPriceCalculation ==
                                  0
                              )
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.totalExternalCladdingReplacementPriceCalculation = 1;
                              else
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.totalExternalCladdingReplacementPriceCalculation = 0;
                              setUpdate(!update);
                            }
                          }}
                        >
                          {t(
                            `formLabels.details.maintenance.totalExternalCladdingReplacementPriceIsTotal`
                          )}
                        </FormikCheckbox>
                        {!(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance
                            .totalExternalCladdingReplacementPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance
                            .totalExternalCladdingReplacementPriceCalculation ==
                            1
                        ) ? (
                          <>
                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.totalExternalCladdingReplacementPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.totalExternalCladdingReplacementPrice`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.totalExternalCladdingReplacementPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                              ]}
                            />
                            <PeriodFields
                              calculation={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.externalCladdingPeriodCalculation
                              }
                              setCalculation={(value: number) => {
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.externalCladdingPeriodCalculation =
                                  value;
                              }}
                              everyHowManyYears={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.externalCladdingEveryHowManyYears
                              }
                              name={`fancades[${
                                props.fancadeNumber - 1
                              }].maintenance.externalCladdingEveryHowManyYears`}
                              update={update}
                              setUpdate={setUpdate}
                            />
                          </>
                        ) : (
                          <Container
                            title={
                              t(
                                `formLabels.details.maintenance.totalExternalCladdingReplacementPriceByYear`
                              ) + t(`titles.calculatedSection`)
                            }
                            bodyStyle={{
                              backgroundColor: theme.colors.lightOrange,
                            }}
                            direction={"column"}
                            spacing={"1rem"}
                          >
                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.totalExternalCladdingReplacementPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.totalExternalCladdingReplacementPriceByYear`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.totalExternalCladdingReplacementPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                  disabled: true,
                                  value: calculateMaintenancePeriodCost(
                                    props.formikProps.values.fancades[
                                      +props.fancadeNumber - 1
                                    ].maintenance
                                      .externalCladdingPeriodCalculation,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance
                                      .externalCladdingEveryHowManyYears,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance.externalCladdingUnitPrice,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance.externalCladdingQuantity,
                                    undefined
                                  ),
                                },
                              ]}
                            />

                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.externalCladdingQuantity`,
                                  label: t(
                                    `formLabels.details.maintenance.externalCladdingQuantity`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.externalCladdingQuantity`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.externalCladdingUnitPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.externalCladdingUnitPrice`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.externalCladdingUnitPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                              ]}
                            />
                            <PeriodFields
                              calculation={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.externalCladdingPeriodCalculation
                              }
                              setCalculation={(value: number) => {
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.externalCladdingPeriodCalculation =
                                  value;
                              }}
                              everyHowManyYears={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.externalCladdingEveryHowManyYears
                              }
                              name={`fancades[${
                                props.fancadeNumber - 1
                              }].maintenance.externalCladdingEveryHowManyYears`}
                              update={update}
                              setUpdate={setUpdate}
                            />
                          </Container>
                        )}

                        {/* Insulation */}
                        <FormikCheckbox
                          paddingTop={"2rem"}
                          checked={
                            !(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalInsulationReplacementPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalInsulationReplacementPriceCalculation ==
                                1
                            )
                          }
                          defaultChecked={
                            !(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalInsulationReplacementPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalInsulationReplacementPriceCalculation ==
                                1
                            )
                          }
                          onChange={() => {
                            if (
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                            ) {
                              if (
                                !props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalInsulationReplacementPriceCalculation ||
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalInsulationReplacementPriceCalculation ==
                                  0
                              )
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.totalInsulationReplacementPriceCalculation = 1;
                              else
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.totalInsulationReplacementPriceCalculation = 0;
                              setUpdate(!update);
                            }
                          }}
                        >
                          {t(
                            `formLabels.details.maintenance.totalInsulationReplacementPriceIsTotal`
                          )}
                        </FormikCheckbox>
                        {!(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance
                            .totalInsulationReplacementPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance
                            .totalInsulationReplacementPriceCalculation == 1
                        ) ? (
                          <>
                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.totalInsulationReplacementPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.totalInsulationReplacementPrice`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.totalInsulationReplacementPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                              ]}
                            />
                            <PeriodFields
                              calculation={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.insulationPeriodCalculation
                              }
                              setCalculation={(value: number) => {
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.insulationPeriodCalculation = value;
                              }}
                              everyHowManyYears={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.insulationEveryHowManyYears
                              }
                              name={`fancades[${
                                props.fancadeNumber - 1
                              }].maintenance.insulationEveryHowManyYears`}
                              update={update}
                              setUpdate={setUpdate}
                            />
                          </>
                        ) : (
                          <Container
                            title={
                              t(
                                `formLabels.details.maintenance.totalInsulationReplacementPriceByYear`
                              ) + t(`titles.calculatedSection`)
                            }
                            bodyStyle={{
                              backgroundColor: theme.colors.lightOrange,
                            }}
                            direction={"column"}
                            spacing={"1rem"}
                          >
                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.totalInsulationReplacementPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.totalInsulationReplacementPriceByYear`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.totalInsulationReplacementPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                  disabled: true,
                                  value: calculateMaintenancePeriodCost(
                                    props.formikProps.values.fancades[
                                      +props.fancadeNumber - 1
                                    ].maintenance.insulationPeriodCalculation,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance.insulationEveryHowManyYears,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance.insulationUnitPrice,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance.insulationSurface,
                                    undefined
                                  ),
                                },
                              ]}
                            />

                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.insulationSurface`,
                                  label: t(
                                    `formLabels.details.maintenance.insulationSurface`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.insulationSurface`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.insulationUnitPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.insulationUnitPrice`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.insulationUnitPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                              ]}
                            />

                            <PeriodFields
                              calculation={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.insulationPeriodCalculation
                              }
                              setCalculation={(value: number) => {
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.insulationPeriodCalculation = value;
                              }}
                              everyHowManyYears={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.insulationEveryHowManyYears
                              }
                              name={`fancades[${
                                props.fancadeNumber - 1
                              }].maintenance.insulationEveryHowManyYears`}
                              update={update}
                              setUpdate={setUpdate}
                            />
                          </Container>
                        )}

                        {/* Supporting Elements */}
                        <FormikCheckbox
                          paddingTop={"2rem"}
                          checked={
                            !(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalSupportingElementsReplacementPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalSupportingElementsReplacementPriceCalculation ==
                                1
                            )
                          }
                          defaultChecked={
                            !(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalSupportingElementsReplacementPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalSupportingElementsReplacementPriceCalculation ==
                                1
                            )
                          }
                          onChange={() => {
                            if (
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                            ) {
                              if (
                                !props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalSupportingElementsReplacementPriceCalculation ||
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalSupportingElementsReplacementPriceCalculation ==
                                  0
                              )
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.totalSupportingElementsReplacementPriceCalculation = 1;
                              else
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.totalSupportingElementsReplacementPriceCalculation = 0;
                              setUpdate(!update);
                            }
                          }}
                        >
                          {t(
                            `formLabels.details.maintenance.totalSupportingElementsReplacementPriceIsTotal`
                          )}
                        </FormikCheckbox>
                        {!(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance
                            .totalSupportingElementsReplacementPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance
                            .totalSupportingElementsReplacementPriceCalculation ==
                            1
                        ) ? (
                          <>
                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.totalSupportingElementsReplacementPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.totalSupportingElementsReplacementPrice`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.totalSupportingElementsReplacementPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                              ]}
                            />

                            <PeriodFields
                              calculation={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .supportingElementsPeriodCalculation
                              }
                              setCalculation={(value: number) => {
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.supportingElementsPeriodCalculation =
                                  value;
                              }}
                              everyHowManyYears={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .supportingElementsEveryHowManyYears
                              }
                              name={`fancades[${
                                props.fancadeNumber - 1
                              }].maintenance.supportingElementsEveryHowManyYears`}
                              update={update}
                              setUpdate={setUpdate}
                            />
                          </>
                        ) : (
                          <Container
                            title={
                              t(
                                `formLabels.details.maintenance.totalSupportingElementsReplacementPriceByYear`
                              ) + t(`titles.calculatedSection`)
                            }
                            bodyStyle={{
                              backgroundColor: theme.colors.lightOrange,
                            }}
                            direction={"column"}
                            spacing={"1rem"}
                          >
                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.totalSupportingElementsReplacementPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.totalSupportingElementsReplacementPriceByYear`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.totalSupportingElementsReplacementPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                  disabled: true,
                                  value: calculateMaintenancePeriodCost(
                                    props.formikProps.values.fancades[
                                      +props.fancadeNumber - 1
                                    ].maintenance
                                      .supportingElementsPeriodCalculation,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance
                                      .supportingElementsEveryHowManyYears,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance.supportingElementUnitPrice,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance.supportingElementsQuantity,
                                    undefined
                                  ),
                                },
                              ]}
                            />

                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.supportingElementsQuantity`,
                                  label: t(
                                    `formLabels.details.maintenance.supportingElementsQuantity`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.supportingElementsQuantity`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.supportingElementUnitPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.supportingElementUnitPrice`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.supportingElementUnitPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                              ]}
                            />

                            <PeriodFields
                              calculation={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .supportingElementsPeriodCalculation
                              }
                              setCalculation={(value: number) => {
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.supportingElementsPeriodCalculation =
                                  value;
                              }}
                              everyHowManyYears={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .supportingElementsEveryHowManyYears
                              }
                              name={`fancades[${
                                props.fancadeNumber - 1
                              }].maintenance.supportingElementsEveryHowManyYears`}
                              update={update}
                              setUpdate={setUpdate}
                            />
                          </Container>
                        )}

                        {/* Prefabricated Modules */}
                        <FormikCheckbox
                          paddingTop={"2rem"}
                          checked={
                            !(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalPrefabricatedModulesReplacementPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalPrefabricatedModulesReplacementPriceCalculation ==
                                1
                            )
                          }
                          defaultChecked={
                            !(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalPrefabricatedModulesReplacementPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalPrefabricatedModulesReplacementPriceCalculation ==
                                1
                            )
                          }
                          onChange={() => {
                            if (
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                            ) {
                              if (
                                !props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalPrefabricatedModulesReplacementPriceCalculation ||
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalPrefabricatedModulesReplacementPriceCalculation ==
                                  0
                              )
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.totalPrefabricatedModulesReplacementPriceCalculation = 1;
                              else
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.totalPrefabricatedModulesReplacementPriceCalculation = 0;
                              setUpdate(!update);
                            }
                          }}
                        >
                          {t(
                            `formLabels.details.maintenance.totalPrefabricatedModulesReplacementPriceIsTotal`
                          )}
                        </FormikCheckbox>
                        {!(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance
                            .totalPrefabricatedModulesReplacementPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance
                            .totalPrefabricatedModulesReplacementPriceCalculation ==
                            1
                        ) ? (
                          <>
                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.totalPrefabricatedModulesReplacementPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.totalPrefabricatedModulesReplacementPrice`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.totalPrefabricatedModulesReplacementPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                              ]}
                            />

                            <PeriodFields
                              calculation={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .prefabricatedModulesPeriodCalculation
                              }
                              setCalculation={(value: number) => {
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.prefabricatedModulesPeriodCalculation =
                                  value;
                              }}
                              everyHowManyYears={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .prefabricatedModulesEveryHowManyYears
                              }
                              name={`fancades[${
                                props.fancadeNumber - 1
                              }].maintenance.prefabricatedModulesEveryHowManyYears`}
                              update={update}
                              setUpdate={setUpdate}
                            />
                          </>
                        ) : (
                          <Container
                            title={
                              t(
                                `formLabels.details.maintenance.totalPrefabricatedModulesReplacementPriceByYear`
                              ) + t(`titles.calculatedSection`)
                            }
                            bodyStyle={{
                              backgroundColor: theme.colors.lightOrange,
                            }}
                            direction={"column"}
                            spacing={"1rem"}
                          >
                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.totalPrefabricatedModulesReplacementPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.totalPrefabricatedModulesReplacementPriceByYear`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.totalPrefabricatedModulesReplacementPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                  disabled: true,
                                  value: calculateMaintenancePeriodCost(
                                    props.formikProps.values.fancades[
                                      +props.fancadeNumber - 1
                                    ].maintenance
                                      .prefabricatedModulesPeriodCalculation,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance
                                      .prefabricatedModulesEveryHowManyYears,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance.prefabricatedModulesUnitPrice,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance.prefabricatedModulesQuantity,
                                    undefined
                                  ),
                                },
                              ]}
                            />

                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.prefabricatedModulesQuantity`,
                                  label: t(
                                    `formLabels.details.maintenance.prefabricatedModulesQuantity`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.prefabricatedModulesQuantity`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.prefabricatedModulesUnitPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.prefabricatedModulesUnitPrice`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.prefabricatedModulesUnitPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                              ]}
                            />
                            <PeriodFields
                              calculation={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .prefabricatedModulesPeriodCalculation
                              }
                              setCalculation={(value: number) => {
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.prefabricatedModulesPeriodCalculation =
                                  value;
                              }}
                              everyHowManyYears={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .prefabricatedModulesEveryHowManyYears
                              }
                              name={`fancades[${
                                props.fancadeNumber - 1
                              }].maintenance.prefabricatedModulesEveryHowManyYears`}
                              update={update}
                              setUpdate={setUpdate}
                            />
                          </Container>
                        )}
                      </>
                    ) : props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.type &&
                      props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.type
                        .toLowerCase()
                        .indexOf("trasparente") != -1 ? (
                      <>
                        {/* Transparent fancade */}
                        {/* Glazed Cells */}
                        <FormikCheckbox
                          paddingTop={"2rem"}
                          checked={
                            !(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalGlazedCellsReplacementPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalGlazedCellsReplacementPriceCalculation ==
                                1
                            )
                          }
                          defaultChecked={
                            !(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalGlazedCellsReplacementPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalGlazedCellsReplacementPriceCalculation ==
                                1
                            )
                          }
                          onChange={() => {
                            if (
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                            ) {
                              if (
                                !props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalGlazedCellsReplacementPriceCalculation ||
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalGlazedCellsReplacementPriceCalculation ==
                                  0
                              )
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.totalGlazedCellsReplacementPriceCalculation = 1;
                              else
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.totalGlazedCellsReplacementPriceCalculation = 0;
                              setUpdate(!update);
                            }
                          }}
                        >
                          {t(
                            `formLabels.details.maintenance.totalGlazedCellsReplacementPriceIsTotal`
                          )}
                        </FormikCheckbox>
                        {!(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance
                            .totalGlazedCellsReplacementPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance
                            .totalGlazedCellsReplacementPriceCalculation == 1
                        ) ? (
                          <>
                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.totalGlazedCellsReplacementPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.totalGlazedCellsReplacementPrice`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.totalGlazedCellsReplacementPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                              ]}
                            />
                            <PeriodFields
                              calculation={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.glazedCellsPeriodCalculation
                              }
                              setCalculation={(value: number) => {
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.glazedCellsPeriodCalculation = value;
                              }}
                              everyHowManyYears={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.glazedCellsEveryHowManyYears
                              }
                              name={`fancades[${
                                props.fancadeNumber - 1
                              }].maintenance.glazedCellsEveryHowManyYears`}
                              update={update}
                              setUpdate={setUpdate}
                            />
                          </>
                        ) : (
                          <Container
                            title={
                              t(
                                `formLabels.details.maintenance.totalGlazedCellsReplacementPriceByYear`
                              ) + t(`titles.calculatedSection`)
                            }
                            bodyStyle={{
                              backgroundColor: theme.colors.lightOrange,
                            }}
                            direction={"column"}
                            spacing={"1rem"}
                          >
                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.totalGlazedCellsReplacementPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.totalGlazedCellsReplacementPriceByYear`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.totalGlazedCellsReplacementPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                  disabled: true,
                                  value: calculateMaintenancePeriodCost(
                                    props.formikProps.values.fancades[
                                      +props.fancadeNumber - 1
                                    ].maintenance.glazedCellsPeriodCalculation,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance.glazedCellsEveryHowManyYears,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance.glazedCellUnitPrice,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance.glazedCellsNumber,
                                    undefined
                                  ),
                                },
                              ]}
                            />

                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.glazedCellsNumber`,
                                  label: t(
                                    `formLabels.details.maintenance.glazedCellsNumber`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.glazedCellsNumber`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.glazedCellUnitPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.glazedCellUnitPrice`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.glazedCellUnitPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                              ]}
                            />
                            <PeriodFields
                              calculation={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.glazedCellsPeriodCalculation
                              }
                              setCalculation={(value: number) => {
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.glazedCellsPeriodCalculation = value;
                              }}
                              everyHowManyYears={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.glazedCellsEveryHowManyYears
                              }
                              name={`fancades[${
                                props.fancadeNumber - 1
                              }].maintenance.glazedCellsEveryHowManyYears`}
                              update={update}
                              setUpdate={setUpdate}
                            />
                          </Container>
                        )}

                        {/* Transparent Double Glazing */}
                        <FormikCheckbox
                          paddingTop={"2rem"}
                          checked={
                            !(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalDoubleGlazingReplacementPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalDoubleGlazingReplacementPriceCalculation ==
                                1
                            )
                          }
                          defaultChecked={
                            !(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalDoubleGlazingReplacementPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalDoubleGlazingReplacementPriceCalculation ==
                                1
                            )
                          }
                          onChange={() => {
                            if (
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                            ) {
                              if (
                                !props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalDoubleGlazingReplacementPriceCalculation ||
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalDoubleGlazingReplacementPriceCalculation ==
                                  0
                              )
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.totalDoubleGlazingReplacementPriceCalculation = 1;
                              else
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.totalDoubleGlazingReplacementPriceCalculation = 0;
                              setUpdate(!update);
                            }
                          }}
                        >
                          {t(
                            `formLabels.details.maintenance.totalDoubleGlazingReplacementPriceIsTotal`
                          )}
                        </FormikCheckbox>
                        {!(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance
                            .totalDoubleGlazingReplacementPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance
                            .totalDoubleGlazingReplacementPriceCalculation == 1
                        ) ? (
                          <>
                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.totalDoubleGlazingReplacementPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.totalDoubleGlazingReplacementPrice`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.totalDoubleGlazingReplacementPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                              ]}
                            />
                            <PeriodFields
                              calculation={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.doubleGlazingPeriodCalculation
                              }
                              setCalculation={(value: number) => {
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.doubleGlazingPeriodCalculation = value;
                              }}
                              everyHowManyYears={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.doubleGlazingEveryHowManyYears
                              }
                              name={`fancades[${
                                props.fancadeNumber - 1
                              }].maintenance.doubleGlazingEveryHowManyYears`}
                              update={update}
                              setUpdate={setUpdate}
                            />
                          </>
                        ) : (
                          <Container
                            title={
                              t(
                                `formLabels.details.maintenance.totalDoubleGlazingReplacementPriceByYear`
                              ) + t(`titles.calculatedSection`)
                            }
                            bodyStyle={{
                              backgroundColor: theme.colors.lightOrange,
                            }}
                            direction={"column"}
                            spacing={"1rem"}
                          >
                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.totalDoubleGlazingReplacementPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.totalDoubleGlazingReplacementPriceByYear`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.totalDoubleGlazingReplacementPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                  disabled: true,
                                  value: calculateMaintenancePeriodCost(
                                    props.formikProps.values.fancades[
                                      +props.fancadeNumber - 1
                                    ].maintenance
                                      .doubleGlazingPeriodCalculation,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance
                                      .doubleGlazingEveryHowManyYears,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance.doubleGlazingUnitPrice,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance.doubleGlazingQuantity,
                                    undefined
                                  ),
                                },
                              ]}
                            />

                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.doubleGlazingQuantity`,
                                  label: t(
                                    `formLabels.details.maintenance.doubleGlazingQuantity`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.doubleGlazingQuantity`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.doubleGlazingUnitPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.doubleGlazingUnitPrice`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.doubleGlazingUnitPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                              ]}
                            />
                            <PeriodFields
                              calculation={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.doubleGlazingPeriodCalculation
                              }
                              setCalculation={(value: number) => {
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.doubleGlazingPeriodCalculation = value;
                              }}
                              everyHowManyYears={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.doubleGlazingEveryHowManyYears
                              }
                              name={`fancades[${
                                props.fancadeNumber - 1
                              }].maintenance.doubleGlazingEveryHowManyYears`}
                              update={update}
                              setUpdate={setUpdate}
                            />
                          </Container>
                        )}

                        {/* Transparent Loom or Fixings */}
                        <FormikCheckbox
                          paddingTop={"2rem"}
                          checked={
                            !(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalTransparentLoomFixingsReplacementPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalTransparentLoomFixingsReplacementPriceCalculation ==
                                1
                            )
                          }
                          defaultChecked={
                            !(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalTransparentLoomFixingsReplacementPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalTransparentLoomFixingsReplacementPriceCalculation ==
                                1
                            )
                          }
                          onChange={() => {
                            if (
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                            ) {
                              if (
                                !props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalTransparentLoomFixingsReplacementPriceCalculation ||
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalTransparentLoomFixingsReplacementPriceCalculation ==
                                  0
                              )
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.totalTransparentLoomFixingsReplacementPriceCalculation = 1;
                              else
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.totalTransparentLoomFixingsReplacementPriceCalculation = 0;
                              setUpdate(!update);
                            }
                          }}
                        >
                          {t(
                            `formLabels.details.maintenance.totalTransparentLoomFixingsReplacementPriceIsTotal`
                          )}
                        </FormikCheckbox>
                        {!(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance
                            .totalTransparentLoomFixingsReplacementPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance
                            .totalTransparentLoomFixingsReplacementPriceCalculation ==
                            1
                        ) ? (
                          <>
                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.totalTransparentLoomFixingsReplacementPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.totalTransparentLoomFixingsReplacementPrice`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.totalTransparentLoomFixingsReplacementPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                              ]}
                            />

                            <PeriodFields
                              calculation={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.transparentLoomPeriodCalculation
                              }
                              setCalculation={(value: number) => {
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.transparentLoomPeriodCalculation =
                                  value;
                              }}
                              everyHowManyYears={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .transparentLoomFixingsEveryHowManyYears
                              }
                              name={`fancades[${
                                props.fancadeNumber - 1
                              }].maintenance.doubleGlazingEveryHowManyYears`}
                              update={update}
                              setUpdate={setUpdate}
                            />
                          </>
                        ) : (
                          <Container
                            title={
                              t(
                                `formLabels.details.maintenance.totalTransparentLoomFixingsReplacementPriceByYear`
                              ) + t(`titles.calculatedSection`)
                            }
                            bodyStyle={{
                              backgroundColor: theme.colors.lightOrange,
                            }}
                            direction={"column"}
                            spacing={"1rem"}
                          >
                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.totalTransparentLoomFixingsReplacementPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.totalTransparentLoomFixingsReplacementPriceByYear`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.totalTransparentLoomFixingsReplacementPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                  disabled: true,
                                  value: calculateMaintenancePeriodCost(
                                    props.formikProps.values.fancades[
                                      +props.fancadeNumber - 1
                                    ].maintenance
                                      .transparentLoomPeriodCalculation,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance
                                      .transparentLoomFixingsEveryHowManyYears,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance
                                      .transparentLoomFixingsUnitPrice,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance
                                      .transparentLoomFixingsQuantity,
                                    undefined
                                  ),
                                },
                              ]}
                            />

                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.transparentLoomFixingsQuantity`,
                                  label: t(
                                    `formLabels.details.maintenance.transparentLoomFixingsQuantity`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.transparentLoomFixingsQuantity`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.transparentLoomFixingsUnitPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.transparentLoomFixingsUnitPrice`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.transparentLoomFixingsUnitPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                              ]}
                            />
                            <PeriodFields
                              calculation={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.transparentLoomPeriodCalculation
                              }
                              setCalculation={(value: number) => {
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.transparentLoomPeriodCalculation =
                                  value;
                              }}
                              everyHowManyYears={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .transparentLoomFixingsEveryHowManyYears
                              }
                              name={`fancades[${
                                props.fancadeNumber - 1
                              }].maintenance.doubleGlazingEveryHowManyYears`}
                              update={update}
                              setUpdate={setUpdate}
                            />
                          </Container>
                        )}

                        {/* Transparent Shielding */}
                        <FormikCheckbox
                          paddingTop={"2rem"}
                          checked={
                            !(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalTransparentShieldingsReplacementPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalTransparentShieldingsReplacementPriceCalculation ==
                                1
                            )
                          }
                          defaultChecked={
                            !(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalTransparentShieldingsReplacementPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalTransparentShieldingsReplacementPriceCalculation ==
                                1
                            )
                          }
                          onChange={() => {
                            if (
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                            ) {
                              if (
                                !props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalTransparentShieldingsReplacementPriceCalculation ||
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalTransparentShieldingsReplacementPriceCalculation ==
                                  0
                              )
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.totalTransparentShieldingsReplacementPriceCalculation = 1;
                              else
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.totalTransparentShieldingsReplacementPriceCalculation = 0;
                              setUpdate(!update);
                            }
                          }}
                        >
                          {t(
                            `formLabels.details.maintenance.totalTransparentShieldingsReplacementPriceIsTotal`
                          )}
                        </FormikCheckbox>
                        {!(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance
                            .totalTransparentShieldingsReplacementPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance
                            .totalTransparentShieldingsReplacementPriceCalculation ==
                            1
                        ) ? (
                          <>
                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.totalTransparentShieldingsReplacementPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.totalTransparentShieldingsReplacementPrice`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.totalTransparentShieldingsReplacementPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                              ]}
                            />
                            <PeriodFields
                              calculation={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .transparentShieldingsPeriodCalculation
                              }
                              setCalculation={(value: number) => {
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.transparentShieldingsPeriodCalculation =
                                  value;
                              }}
                              everyHowManyYears={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .transparentShieldingsEveryHowManyYears
                              }
                              name={`fancades[${
                                props.fancadeNumber - 1
                              }].maintenance.transparentShieldingsEveryHowManyYears`}
                              update={update}
                              setUpdate={setUpdate}
                            />
                          </>
                        ) : (
                          <Container
                            title={
                              t(
                                `formLabels.details.maintenance.totalTransparentShieldingsReplacementPriceByYear`
                              ) + t(`titles.calculatedSection`)
                            }
                            bodyStyle={{
                              backgroundColor: theme.colors.lightOrange,
                            }}
                            direction={"column"}
                            spacing={"1rem"}
                          >
                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.totalTransparentShieldingsReplacementPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.totalTransparentShieldingsReplacementPriceByYear`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.totalTransparentShieldingsReplacementPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                  disabled: true,
                                  value: calculateMaintenancePeriodCost(
                                    props.formikProps.values.fancades[
                                      +props.fancadeNumber - 1
                                    ].maintenance
                                      .transparentShieldingsPeriodCalculation,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance
                                      .transparentShieldingsEveryHowManyYears,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance
                                      .transparentShieldingsUnitPrice,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance.transparentShieldingsQuantity,
                                    undefined
                                  ),
                                },
                              ]}
                            />

                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.transparentShieldingsQuantity`,
                                  label: t(
                                    `formLabels.details.maintenance.transparentShieldingsQuantity`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.transparentShieldingsQuantity`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.transparentShieldingsUnitPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.transparentShieldingsUnitPrice`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.transparentShieldingsUnitPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                              ]}
                            />
                            <PeriodFields
                              calculation={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .transparentShieldingsPeriodCalculation
                              }
                              setCalculation={(value: number) => {
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.transparentShieldingsPeriodCalculation =
                                  value;
                              }}
                              everyHowManyYears={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .transparentShieldingsEveryHowManyYears
                              }
                              name={`fancades[${
                                props.fancadeNumber - 1
                              }].maintenance.transparentShieldingsEveryHowManyYears`}
                              update={update}
                              setUpdate={setUpdate}
                            />
                          </Container>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {/* Systems Integrations */}
                    <Container title={t('formLabels.details.maintenance.plantIntegration')}>
                      <Stack w={"100%"}>
                        {/* Photovoltaic Modules */}
                        <FormikCheckbox
                          paddingTop={"2rem"}
                          checked={
                            !(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalPhotovoltaicModulesReplacementPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalPhotovoltaicModulesReplacementPriceCalculation ==
                                1
                            )
                          }
                          defaultChecked={
                            !(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalPhotovoltaicModulesReplacementPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalPhotovoltaicModulesReplacementPriceCalculation ==
                                1
                            )
                          }
                          onChange={() => {
                            if (
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                            ) {
                              if (
                                !props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalPhotovoltaicModulesReplacementPriceCalculation ||
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalPhotovoltaicModulesReplacementPriceCalculation ==
                                  0
                              )
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.totalPhotovoltaicModulesReplacementPriceCalculation = 1;
                              else
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.totalPhotovoltaicModulesReplacementPriceCalculation = 0;
                              setUpdate(!update);
                            }
                          }}
                        >
                          {t(
                            `formLabels.details.maintenance.totalPhotovoltaicModulesReplacementPriceIsTotal`
                          )}
                        </FormikCheckbox>
                        {!(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance
                            .totalPhotovoltaicModulesReplacementPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance
                            .totalPhotovoltaicModulesReplacementPriceCalculation ==
                            1
                        ) ? (
                          <>
                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.totalPhotovoltaicModulesReplacementPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.totalPhotovoltaicModulesReplacementPrice`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.totalPhotovoltaicModulesReplacementPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                              ]}
                            />
                            <PeriodFields
                              calculation={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .photovoltaicModulesPeriodCalculation
                              }
                              setCalculation={(value: number) => {
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.photovoltaicModulesPeriodCalculation =
                                  value;
                              }}
                              everyHowManyYears={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .photovoltaicModulesEveryHowManyYears
                              }
                              name={`fancades[${
                                props.fancadeNumber - 1
                              }].maintenance.photovoltaicModulesEveryHowManyYears`}
                              update={update}
                              setUpdate={setUpdate}
                            />
                          </>
                        ) : (
                          <Container
                            title={
                              t(
                                `formLabels.details.maintenance.totalPhotovoltaicModulesReplacementPriceByYear`
                              ) + t(`titles.calculatedSection`)
                            }
                            bodyStyle={{
                              backgroundColor: theme.colors.lightOrange,
                            }}
                            direction={"column"}
                            spacing={"1rem"}
                          >
                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.totalPhotovoltaicModulesReplacementPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.totalPhotovoltaicModulesReplacementPriceByYear`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.totalPhotovoltaicModulesReplacementPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                  disabled: true,
                                  value: calculateMaintenancePeriodCost(
                                    props.formikProps.values.fancades[
                                      +props.fancadeNumber - 1
                                    ].maintenance
                                      .photovoltaicModulesPeriodCalculation,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance
                                      .photovoltaicModulesEveryHowManyYears,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance.photovoltaicModuleUnitPrice,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance.photovoltaicModulesNumber,
                                    undefined
                                  ),
                                },
                              ]}
                            />

                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.photovoltaicModulesNumber`,
                                  label: t(
                                    `formLabels.details.maintenance.photovoltaicModulesNumber`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.photovoltaicModulesNumber`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.photovoltaicModuleUnitPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.photovoltaicModuleUnitPrice`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.photovoltaicModuleUnitPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                              ]}
                            />

                            <PeriodFields
                              calculation={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .photovoltaicModulesPeriodCalculation
                              }
                              setCalculation={(value: number) => {
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.photovoltaicModulesPeriodCalculation =
                                  value;
                              }}
                              everyHowManyYears={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .photovoltaicModulesEveryHowManyYears
                              }
                              name={`fancades[${
                                props.fancadeNumber - 1
                              }].maintenance.photovoltaicModulesEveryHowManyYears`}
                              update={update}
                              setUpdate={setUpdate}
                            />
                          </Container>
                        )}

                        {/* Solar Panels */}
                        <FormikCheckbox
                          paddingTop={"2rem"}
                          checked={
                            !(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalSolarPanelsReplacementPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalSolarPanelsReplacementPriceCalculation ==
                                1
                            )
                          }
                          defaultChecked={
                            !(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalSolarPanelsReplacementPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalSolarPanelsReplacementPriceCalculation ==
                                1
                            )
                          }
                          onChange={() => {
                            if (
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                            ) {
                              if (
                                !props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalSolarPanelsReplacementPriceCalculation ||
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalSolarPanelsReplacementPriceCalculation ==
                                  0
                              )
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.totalSolarPanelsReplacementPriceCalculation = 1;
                              else
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.totalSolarPanelsReplacementPriceCalculation = 0;
                              setUpdate(!update);
                            }
                          }}
                        >
                          {t(
                            `formLabels.details.maintenance.totalSolarPanelsReplacementPriceIsTotal`
                          )}
                        </FormikCheckbox>
                        {!(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance
                            .totalSolarPanelsReplacementPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance
                            .totalSolarPanelsReplacementPriceCalculation == 1
                        ) ? (
                          <>
                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.totalSolarPanelsReplacementPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.totalSolarPanelsReplacementPrice`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.totalSolarPanelsReplacementPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                              ]}
                            />
                            <PeriodFields
                              calculation={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.solarPanelsPeriodCalculation
                              }
                              setCalculation={(value: number) => {
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.solarPanelsPeriodCalculation = value;
                              }}
                              everyHowManyYears={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.solarPanelsEveryHowManyYears
                              }
                              name={`fancades[${
                                props.fancadeNumber - 1
                              }].maintenance.solarPanelsEveryHowManyYears`}
                              update={update}
                              setUpdate={setUpdate}
                            />
                          </>
                        ) : (
                          <Container
                            title={
                              t(
                                `formLabels.details.maintenance.totalSolarPanelsReplacementPriceByYear`
                              ) + t(`titles.calculatedSection`)
                            }
                            bodyStyle={{
                              backgroundColor: theme.colors.lightOrange,
                            }}
                            direction={"column"}
                            spacing={"1rem"}
                          >
                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.totalSolarPanelsReplacementPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.totalSolarPanelsReplacementPriceByYear`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.totalSolarPanelsReplacementPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                  disabled: true,
                                  value: calculateMaintenancePeriodCost(
                                    props.formikProps.values.fancades[
                                      +props.fancadeNumber - 1
                                    ].maintenance.solarPanelsPeriodCalculation,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance.solarPanelsEveryHowManyYears,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance.solarPanelUnitPrice,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance.solarPanelsNumber,
                                    undefined
                                  ),
                                },
                              ]}
                            />

                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.solarPanelsNumber`,
                                  label: t(
                                    `formLabels.details.maintenance.solarPanelsNumber`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.solarPanelsNumber`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.solarPanelUnitPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.solarPanelUnitPrice`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.solarPanelUnitPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                              ]}
                            />
                            <PeriodFields
                              calculation={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.solarPanelsPeriodCalculation
                              }
                              setCalculation={(value: number) => {
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.solarPanelsPeriodCalculation = value;
                              }}
                              everyHowManyYears={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.solarPanelsEveryHowManyYears
                              }
                              name={`fancades[${
                                props.fancadeNumber - 1
                              }].maintenance.solarPanelsEveryHowManyYears`}
                              update={update}
                              setUpdate={setUpdate}
                            />
                          </Container>
                        )}

                        {/* Ventilation devices */}
                        <FormikCheckbox
                          paddingTop={"2rem"}
                          checked={
                            !(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalVentilationDevicesReplacementPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalVentilationDevicesReplacementPriceCalculation ==
                                1
                            )
                          }
                          defaultChecked={
                            !(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalVentilationDevicesReplacementPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalVentilationDevicesReplacementPriceCalculation ==
                                1
                            )
                          }
                          onChange={() => {
                            if (
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                            ) {
                              if (
                                !props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalVentilationDevicesReplacementPriceCalculation ||
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalVentilationDevicesReplacementPriceCalculation ==
                                  0
                              )
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.totalVentilationDevicesReplacementPriceCalculation = 1;
                              else
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.totalVentilationDevicesReplacementPriceCalculation = 0;
                              setUpdate(!update);
                            }
                          }}
                        >
                          {t(
                            `formLabels.details.maintenance.totalVentilationDevicesReplacementPriceIsTotal`
                          )}
                        </FormikCheckbox>
                        {!(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance
                            .totalVentilationDevicesReplacementPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance
                            .totalVentilationDevicesReplacementPriceCalculation ==
                            1
                        ) ? (
                          <>
                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.totalVentilationDevicesReplacementPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.totalVentilationDevicesReplacementPrice`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.totalVentilationDevicesReplacementPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                              ]}
                            />
                            <PeriodFields
                              calculation={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .ventilationDevicesPeriodCalculation
                              }
                              setCalculation={(value: number) => {
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.ventilationDevicesPeriodCalculation =
                                  value;
                              }}
                              everyHowManyYears={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .ventilationDevicesEveryHowManyYears
                              }
                              name={`fancades[${
                                props.fancadeNumber - 1
                              }].maintenance.ventilationDevicesEveryHowManyYears`}
                              update={update}
                              setUpdate={setUpdate}
                            />
                          </>
                        ) : (
                          <Container
                            title={
                              t(
                                `formLabels.details.maintenance.totalVentilationDevicesReplacementPriceByYear`
                              ) + t(`titles.calculatedSection`)
                            }
                            bodyStyle={{
                              backgroundColor: theme.colors.lightOrange,
                            }}
                            direction={"column"}
                            spacing={"1rem"}
                          >
                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.totalVentilationDevicesReplacementPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.totalVentilationDevicesReplacementPriceByYear`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.totalVentilationDevicesReplacementPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                  disabled: true,
                                  value: calculateMaintenancePeriodCost(
                                    props.formikProps.values.fancades[
                                      +props.fancadeNumber - 1
                                    ].maintenance
                                      .ventilationDevicesPeriodCalculation,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance
                                      .ventilationDevicesEveryHowManyYears,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance.ventilationDeviceUnitPrice,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance.ventilationDevicesNumber,
                                    undefined
                                  ),
                                },
                              ]}
                            />

                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.ventilationDevicesNumber`,
                                  label: t(
                                    `formLabels.details.maintenance.ventilationDevicesNumber`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.ventilationDevicesNumber`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.ventilationDeviceUnitPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.ventilationDeviceUnitPrice`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.ventilationDeviceUnitPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                              ]}
                            />
                            <PeriodFields
                              calculation={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .ventilationDevicesPeriodCalculation
                              }
                              setCalculation={(value: number) => {
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.ventilationDevicesPeriodCalculation =
                                  value;
                              }}
                              everyHowManyYears={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .ventilationDevicesEveryHowManyYears
                              }
                              name={`fancades[${
                                props.fancadeNumber - 1
                              }].maintenance.ventilationDevicesEveryHowManyYears`}
                              update={update}
                              setUpdate={setUpdate}
                            />
                          </Container>
                        )}

                        {/* Air Conditioning */}
                        <FormikCheckbox
                          paddingTop={"2rem"}
                          checked={
                            !(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalAirConditioningDevicesReplacementPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalAirConditioningDevicesReplacementPriceCalculation ==
                                1
                            )
                          }
                          defaultChecked={
                            !(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalAirConditioningDevicesReplacementPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                                ?.totalAirConditioningDevicesReplacementPriceCalculation ==
                                1
                            )
                          }
                          onChange={() => {
                            if (
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance
                            ) {
                              if (
                                !props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalAirConditioningDevicesReplacementPriceCalculation ||
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .totalAirConditioningDevicesReplacementPriceCalculation ==
                                  0
                              )
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.totalAirConditioningDevicesReplacementPriceCalculation = 1;
                              else
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.totalAirConditioningDevicesReplacementPriceCalculation = 0;
                              setUpdate(!update);
                            }
                          }}
                        >
                          {t(
                            `formLabels.details.maintenance.totalAirConditioningDevicesReplacementPriceIsTotal`
                          )}
                        </FormikCheckbox>
                        {!(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance
                            .totalAirConditioningDevicesReplacementPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance
                            .totalAirConditioningDevicesReplacementPriceCalculation ==
                            1
                        ) ? (
                          <>
                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.totalAirConditioningDevicesReplacementPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.totalAirConditioningDevicesReplacementPrice`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.totalAirConditioningDevicesReplacementPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                              ]}
                            />
                            <PeriodFields
                              calculation={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.airConditioningPeriodCalculation
                              }
                              setCalculation={(value: number) => {
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.airConditioningPeriodCalculation =
                                  value;
                              }}
                              everyHowManyYears={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .airConditioningDevicesEveryHowManyYears
                              }
                              name={`fancades[${
                                props.fancadeNumber - 1
                              }].maintenance.airConditioningDevicesEveryHowManyYears`}
                              update={update}
                              setUpdate={setUpdate}
                            />
                          </>
                        ) : (
                          <Container
                            title={
                              t(
                                `formLabels.details.maintenance.totalAirConditioningDevicesReplacementPriceByYear`
                              ) + t(`titles.calculatedSection`)
                            }
                            bodyStyle={{
                              backgroundColor: theme.colors.lightOrange,
                            }}
                            direction={"column"}
                            spacing={"1rem"}
                          >
                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.totalAirConditioningDevicesReplacementPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.totalAirConditioningDevicesReplacementPriceByYear`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.totalAirConditioningDevicesReplacementPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                  disabled: true,
                                  value: calculateMaintenancePeriodCost(
                                    props.formikProps.values.fancades[
                                      +props.fancadeNumber - 1
                                    ].maintenance
                                      .airConditioningPeriodCalculation,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance
                                      .airConditioningDevicesEveryHowManyYears,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance
                                      .airConditioningDeviceUnitPrice,
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].maintenance.airConditioningDevicesNumber,
                                    undefined
                                  ),
                                },
                              ]}
                            />

                            <FormRow
                              fields={[
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.airConditioningDevicesNumber`,
                                  label: t(
                                    `formLabels.details.maintenance.airConditioningDevicesNumber`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.airConditioningDevicesNumber`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                                {
                                  name: `fancades[${
                                    props.fancadeNumber - 1
                                  }].maintenance.airConditioningDeviceUnitPrice`,
                                  label: t(
                                    `formLabels.details.maintenance.airConditioningDeviceUnitPrice`
                                  ),
                                  placeholder: t(
                                    `formPlaceholders.details.maintenance.airConditioningDeviceUnitPrice`
                                  ),
                                  type: "number",
                                  width: 100,
                                },
                              ]}
                            />
                            <PeriodFields
                              calculation={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.airConditioningPeriodCalculation
                              }
                              setCalculation={(value: number) => {
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.airConditioningPeriodCalculation =
                                  value;
                              }}
                              everyHowManyYears={
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance
                                  .airConditioningDevicesEveryHowManyYears
                              }
                              name={`fancades[${
                                props.fancadeNumber - 1
                              }].maintenance.airConditioningDevicesEveryHowManyYears`}
                              update={update}
                              setUpdate={setUpdate}
                            />
                          </Container>
                        )}
                      </Stack>
                    </Container>
                  </Container>
                )}
              </Stack>
            </Container>

            {/* Cleaning */}
            <Container title={t("formLabels.details.maintenance.cleaning")}>
              <Stack w={"100%"}>
                <FormikCheckbox
                  checked={
                    !(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .maintenance?.totalCleaningPriceCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .maintenance?.totalCleaningPriceCalculation == 1
                    )
                  }
                  defaultChecked={
                    !(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .maintenance?.totalCleaningPriceCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .maintenance?.totalCleaningPriceCalculation == 1
                    )
                  }
                  onChange={() => {
                    if (
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .maintenance
                    ) {
                      if (
                        !props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].maintenance.totalCleaningPriceCalculation ||
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].maintenance.totalCleaningPriceCalculation == 0
                      )
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].maintenance.totalCleaningPriceCalculation = 1;
                      else
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].maintenance.totalCleaningPriceCalculation = 0;
                      setUpdate(!update);
                    }
                  }}
                >
                  {t(
                    `formLabels.details.maintenance.totalCleaningPriceIsTotal`
                  )}
                </FormikCheckbox>
                {!(
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .maintenance.totalCleaningPriceCalculation &&
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .maintenance.totalCleaningPriceCalculation == 1
                ) ? (
                  <>
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].maintenance.totalCleaningPrice`,
                          label: t(
                            `formLabels.details.maintenance.totalCleaningPrice`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.maintenance.totalCleaningPrice`
                          ),
                          type: "number",
                          width: 100,
                        },
                      ]}
                    />
                  </>
                ) : (
                  <Container
                    title={
                      t(
                        `formLabels.details.maintenance.totalCleaningPriceByYear`
                      ) + t(`titles.calculatedSection`)
                    }
                    bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                    direction={"column"}
                    spacing={"1rem"}
                  >
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].maintenance.totalCleaningPrice`,
                          label: t(
                            `formLabels.details.maintenance.totalCleaningPriceByYear`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.maintenance.totalCleaningPrice`
                          ),
                          type: "number",
                          width: 100,
                          disabled: true,
                          value: calculateMaintenancePeriodCost(
                            props.formikProps.values.fancades[
                              +props.fancadeNumber - 1
                            ].maintenance.cleaningsPeriodCalculation,
                            +props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].maintenance.cleaningsEveryHowManyYears,
                            undefined,
                            undefined,
                            +props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].maintenance.cleaningUnitPrice
                          ),
                        },
                      ]}
                    />

                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].maintenance.cleaningUnitPrice`,
                          label: t(
                            `formLabels.details.maintenance.cleaningUnitPrice`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.maintenance.cleaningUnitPrice`
                          ),
                          type: "number",
                          width: 100,
                        },
                      ]}
                    />

                    <PeriodFields
                      calculation={
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].maintenance.cleaningsPeriodCalculation
                      }
                      setCalculation={(value: number) => {
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].maintenance.cleaningsPeriodCalculation = value;
                      }}
                      everyHowManyYears={
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].maintenance.cleaningsEveryHowManyYears
                      }
                      name={`fancades[${
                        props.fancadeNumber - 1
                      }].maintenance.cleaningsEveryHowManyYears`}
                      update={update}
                      setUpdate={setUpdate}
                    />
                  </Container>
                )}
              </Stack>
            </Container>

            {/* Extraordinary Maintenance */}
            <Container title={t("formLabels.details.maintenance.extraordinaryMaintenance")}>
              <Stack w={"100%"}>
                <FormRow
                  fields={[
                    {
                      name: `fancades[${
                        props.fancadeNumber - 1
                      }].maintenance.totalExtraordinaryMaintenancePriceCalculation`,
                      label: t(
                        `formLabels.details.maintenance.totalExtraordinaryMaintenancePriceIsTotal`
                      ),
                      type: "select",
                      selectOptions: [
                        {
                          text: t(
                            `formLabels.details.maintenance.manualOption`
                          ),
                          value: 0,
                        },
                        {
                          text: t(
                            `formLabels.details.maintenance.percentageOption`
                          ),
                          value: 1,
                        },
                        {
                          text: t(`formLabels.details.maintenance.sumOption`),
                          value: 2,
                        },
                      ],
                      width: 100,
                    },
                  ]}
                />
                {!props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .maintenance.totalExtraordinaryMaintenancePriceCalculation ||
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .maintenance.totalExtraordinaryMaintenancePriceCalculation ==
                  0 ? (
                  <>
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].maintenance.totalExtraordinaryMaintenancePrice`,
                          label: t(
                            `formLabels.details.maintenance.totalExtraordinaryMaintenancePrice`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.maintenance.totalExtraordinaryMaintenancePrice`
                          ),
                          type: "number",
                          width: 100,
                        },
                      ]}
                    />
                    <PeriodFields
                      calculation={
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].maintenance
                          .totalExtraordinaryMaintenancePeriodCalculation
                      }
                      setCalculation={(value: number) => {
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].maintenance.totalExtraordinaryMaintenancePeriodCalculation =
                          value;
                      }}
                      everyHowManyYears={
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].maintenance
                          .totalExtraordinaryMaintenanceEveryHowManyYears
                      }
                      name={`fancades[${
                        props.fancadeNumber - 1
                      }].maintenance.totalExtraordinaryMaintenanceEveryHowManyYears`}
                      update={update}
                      setUpdate={setUpdate}
                    />
                  </>
                ) : props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .maintenance
                    .totalExtraordinaryMaintenancePriceCalculation &&
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .maintenance
                    .totalExtraordinaryMaintenancePriceCalculation == 1 ? (
                  <>
                    {/*Percentage*/}
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].maintenance.totalExtraordinaryMaintenancePrice`,
                          label: t(
                            `formLabels.details.maintenance.totalExtraordinaryMaintenancePriceByYear`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.maintenance.totalExtraordinaryMaintenancePrice`
                          ),
                          type: "number",
                          width: 100,
                          disabled: true,
                          value: calculateExtraordinaryMaintenance(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ]
                          ),
                        },
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].maintenance.totalExtraordinaryMaintenancePercentage`,
                          label: t(
                            `formLabels.details.maintenance.totalExtraordinaryMaintenancePercentage`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.maintenance.totalExtraordinaryMaintenancePercentage`
                          ),
                          type: "number",
                          width: 100,
                        },
                      ]}
                    />
                  </>
                ) : (
                  <Container
                    title={
                      t(
                        `formLabels.details.maintenance.totalExtraordinaryMaintenancePriceByYear`
                      ) + t(`titles.calculatedSection`)
                    }
                    bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                    direction={"column"}
                    spacing={"1rem"}
                  >
                    {/* By components */}
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].maintenance.totalExtraordinaryMaintenancePrice`,
                          label: t(
                            `formLabels.details.maintenance.totalExtraordinaryMaintenancePriceByYear`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.maintenance.totalExtraordinaryMaintenancePrice`
                          ),
                          type: "number",
                          width: 100,
                          disabled: true,
                          value: calculateExtraordinaryMaintenance(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ]
                          ),
                        },
                      ]}
                    />
                    <Text fontSize={"2xl"} color={theme.colors.darkGrey2}>
                      {t(`formLabels.details.maintenance.actions`)}
                    </Text>
                    {props.formikProps.values.fancades[
                      props.fancadeNumber - 1
                    ].maintenance.actions?.map(
                      (action: Action, index: number) => (
                        <>
                          <Divider orientation="horizontal" />
                          <Flex w={"100%"} alignItems={"center"}>
                            <Text
                              fontSize={"xl"}
                              color={theme.colors.darkGrey2}
                            >
                              {t(`formLabels.details.maintenance.action`) +
                                " #" +
                                (index + 1)}
                            </Text>
                            <CloseIcon
                              color={theme.colors.euracOrange}
                              w={"0.7rem"}
                              marginLeft={"0.5rem"}
                              marginTop={"0.5rem"}
                              onClick={() => {
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].maintenance.actions.splice(index, 1);
                                setUpdate(!update);
                              }}
                            />
                          </Flex>
                          <FormRow
                            fields={[
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].maintenance.actions[${index}].name`,
                                placeholder: t(
                                  `formPlaceholders.details.maintenance.actionName`
                                ),
                                label: t(
                                  `formLabels.details.maintenance.actionName`
                                ),
                                type: "text",
                                width: 100,
                              },
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].maintenance.actions[${index}].value`,
                                placeholder: t(
                                  `formPlaceholders.details.maintenance.actionValue`
                                ),
                                label: t(
                                  `formLabels.details.maintenance.actionValue`
                                ),
                                type: "number",
                                width: 100,
                              },
                            ]}
                          />

                          <PeriodFields
                            calculation={
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance.periodCalculation
                            }
                            setCalculation={(value: number) => {
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance.periodCalculation = value;
                            }}
                            everyHowManyYears={
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].maintenance.everyHowManyYears
                            }
                            name={`fancades[${
                              props.fancadeNumber - 1
                            }].maintenance.everyHowManyYears`}
                            update={update}
                            setUpdate={setUpdate}
                          />
                        </>
                      )
                    )}
                    <Center paddingTop={"1rem"}>
                      <StyledButton
                        onClick={() => {
                          if (
                            !props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].maintenance.actions
                          )
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].maintenance.actions = [];
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].maintenance.actions.push(null);
                          setUpdate(!update);
                        }}
                      >
                        {t("buttons.addAction")}
                      </StyledButton>
                    </Center>
                  </Container>
                )}
              </Stack>
            </Container>
          </Container>
        )}
      </Stack>
    );
  else return <Redirect to={"/"} />;
});

const StyledButton = styled(Button)`
  color: white;
  background-color: ${theme.colors.euracOrange};
  border-radius: 500rem;
  &:hover {
    background-color: ${transparentize(0.5, theme.colors.euracOrange)};
  }
`;
