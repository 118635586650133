import React from "react";
import ReactDOM from "react-dom";
import { Button, Center, Flex, Stack, Text } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Route } from "react-router-dom";
import { useStores } from "../hooks/useStores";

import styled from "styled-components";
import theme from "../theme/theme";

import { Sidebar } from "../components/sidebar/Sidebar";
import { Projects } from "./Projects";
import { Users } from "./Users";
import { Comparison } from "../components/projects/comparison/Comparison";

export const AppLayout: React.FC = observer(() => {
  const { t } = useTranslation();
  const { session, ui } = useStores();

  ui.setActiveScreen("");
  return (
    <Flex bg={theme.colors.euracGrey} h={"100vh"}>
      <Flex minW={"180px"} maxW={"180px"} h={"100vh"}>
        <Sidebar />
      </Flex>
      <Flex
        flexGrow={1}
        h={"100vh"}
        bg={theme.colors.formBackground}
        boxShadow={"lg"}
      >
        <Route path={"/user/:uid/projects"} component={Projects} />
        <Route path={"/user/:uid/users"} component={Users} />
        <Route path={"/user/:uid/comparison"} component={Comparison} />
      </Flex>
    </Flex>
  );
});
