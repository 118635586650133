export default {
  colors: {
    background: "#ececec",
    formBackground: "#ffffff",
    euracOrange: "#df1b12",
    lightGrey: "#989ea5",
    lightGrey2: "#f6f6f6",
    darkGrey: "#e5e5e5",
    darkGrey2: "#4c5257",
    euracGrey: "#d8dada",
    lightOrange: "#fcfaf9",
    productionMarker: "#F77F11",
    maintenanceMarker: "#1F77B3",
    recyclingMarker: "#35A02C",
    transportMarker: "#C9B3DE",
    installationMarker: "#8C564B",
    projectMarker: "#D62728",
    serviceLifeMarker: "#FABF86",
    disposalMarker: "#E377C2",
    laborMarker: "##35A02C",
    materialsMarker: "#1F77B3",
    securityMarker: "#1F77B3",
    toolsMarker: "#F77F11",
    ordinaryMaintenanceMarker: "#1F77B3",
    extraordinaryMaintenanceMarker: "#F77F11",
    cleaningMarker: "#35A02C",
    craneMarker: "#1F77B3",
    elevatorPlatformsMarker: "#35A02C",
    aerialPlatformsMarker: "#F77F11",
    scaffoldingMarker: "#D62728",
  },
};
