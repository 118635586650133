import {
  Action,
  BuildingSiteType,
  Category,
  EndOfLifeType,
  Fancade,
  FancadeAPI,
  FancadeProductionType,
  FancadeProjectType,
  FancadeTechnicalProjectType,
  MaintenanceType,
  OpaqueWindow,
  Project,
  ServiceLifeType,
  TableRow,
  TransportType,
  User,
} from "./../types";

export const calculateBenchmark = (
  benchmarkValue: number,
  fancadeValue: number
) => fancadeValue - benchmarkValue;

export const toUser = (data: any): User => {
  return {
    id: data.id,
    email: data.email,
    nominative: data.nominative,
    password: data.password || undefined,
    role: data.role,
    disabled: data.disabled || false,
  };
};

export const toCategory = (data: any): Category => {
  return {
    id: data.id,
    name: data.name,
    projects: data.projects || [],
  };
};

export const getUserInitials = (user: User | null) => {
  if (user && user.nominative)
    return user.nominative
      .toUpperCase()
      .split(" ")
      .map((n) => n[0])
      .join("")
      .substr(0, 2);
  return "MR";
};

export const toProject = (data: any): Project => {
  return {
    id: data.id,
    name: data.name,
    type: data.type,
    useDestination: data.useDestination,
    constructionYear: data.constructionYear,
    location: data.location,
    reportAuthor: data.reportAuthor,
    description: data.description,
    certification: data.certification,
    isBenchmark: data.isBenchmark,
    importedFromBIMP: data.importedFromBIMP,
    fancades: data.fancades,
    createdById: data.createdById,
    categoryId: data.categoryId,
    kpiFancadeProject: data.kpiFancadeProject,
    kpiFancadeProduction: data.kpiFancadeProduction,
    kpiFancadeTransport: data.kpiFancadeTransport,
    kpiBuildingSite: data.kpiBuildingSite,
    kpiMaintenance: data.kpiMaintenance,
    kpiEndOfLife: data.kpiEndOfLife,
  };
};

export const toFancadeAPI = (data: any): FancadeAPI => {
  let fancadeProject = data.fancadeProject;
  if (!fancadeProject) {
    fancadeProject = JSON.stringify({
      totalDesignPrice: null,
      technicalProject: null,
      structuralSismicProject: null,
      lightingTechnicalProject: null,
      ventilationProject: null,
      acusticProject: null,
      airQualityCheck: null,
      constructionPrinceAnalysis: null,
      firePreventionDesign: null,
      buildingSiteManagement: null,
      buildingSiteSafety: null,
      energeticPerformanceAnalysis: null,
      competitionProject: null,
      maintenancePlan: null,
      userManual: null,
    });
  }
  return {
    id: data.id,
    customName: data.customName,
    fancadeTechnicalProject: JSON.stringify(data.fancadeTechnicalProject),
    fancadeProject: JSON.stringify(fancadeProject),
    fancadeProduction: JSON.stringify(data.fancadeProduction),
    transport: JSON.stringify(data.transport),
    buildingSite: JSON.stringify(data.buildingSite),
    serviceLife: JSON.stringify(data.serviceLife),
    maintenance: JSON.stringify(data.maintenance),
    costsActualization: JSON.stringify(data.costsActualization),
    endOfLife: JSON.stringify(data.endOfLife),
    notes: JSON.stringify(data.notes),
    projectId: data.projectId,
  };
};

export const toFancadeFromAPI = (data: any): Fancade => {
  let fancadeTechnicalProject;
  try {
    fancadeTechnicalProject = JSON.parse(data.fancadeTechnicalProject);
  } catch (e) {}
  if (
    !fancadeTechnicalProject ||
    Object.keys(fancadeTechnicalProject).length === 0
  ) {
    fancadeTechnicalProject = {
      type: null,
      surface: null,
      //Involucro opaco
      windows: [],
      windowsTotalPrice: null,
      windowsTotalPriceCalculation: null,
      externalCladdingUnitsNumber: null, //rivestimento
      totalExternalCladdingSurface: null, //rivestimento
      insulationSurface: null, //isolamento
      supportingElementsQuantity: null, //elementi portanti
      prefabricatedModulesQuantity: null,
      modulesSurface: null,
      opaqueAnchoringsNumber: null, //ancoraggi
      opaqueFixingsNumber: null, //fissaggi
      //Involucro opaco production
      externalCladdingTotalPrice: null,
      externalCladdingTotalPriceCalculation: null,
      externalCladdingUnitPrice: null,
      insulationTotalPrice: null,
      insulationTotalPriceCalculation: null,
      insulationUnitPrice: null,
      supportingElementsTotalPrice: null,
      supportingElementsTotalPriceCalculation: null,
      supportingElementsUnitPrice: null,

      prefabricatedModulesTotalPrice: null,
      prefabricatedModulesTotalPriceCalculation: null,
      prefabricatedModulesUnitPrice: null,

      opaqueAnchoringTotalPrice: null,
      opaqueAnchoringTotalPriceCalculation: null,
      opaqueAnchoringUnitPrice: null,

      opaqueFixingsTotalPrice: null,
      opaqueFixingsTotalPriceCalculation: null,
      opaqueFixingsUnitPrice: null,

      //Involucro trasparente
      transparentTotalPrice: null,
      transparentTotalPriceCalculation: null,
      glazedCellsNumber: null,
      glazedCellsSurface: null,
      doubleGlazingQuantity: null,
      doubleGlazingSurface: null,
      mainFrameHeightPerUnit: null,
      mainFrameWidthPerUnit: null,
      totalMainFrameLength: null,
      totalGasketLength: null,
      opaqueSpandrelPanelsQuantity: null,
      opaqueSpandrelPanelsSurface: null,
      transparentAnchoringsNumber: null, //ancoraggi
      transparentFixingsNumber: null, //fissaggi

      //Involucro trasparente production
      glazedCellsUnitPrice: null,
      totalGlazedCellsPrice: null,
      totalGlazedCellsPriceCalculation: null,
      totalMainFramePrice: null,
      totalMainFramePriceCalculation: null,
      mainFrameUnitPrice: null,
      totalDoubleGlazingPrice: null,
      totalDoubleGlazingPriceCalculation: null,
      doubleGlazingUnitPrice: null,
      gasketUnitPrice: null,
      totalGasketPrice: null,
      totalGasketPriceCalculation: null,
      opaqueSpandrelModulesUnitPrice: null,
      totalOpaqueSpandrelModulesPrice: null,
      totalOpaqueSpandrelModulesPriceCalculation: null,
      transparentAnchoringsUnitPrice: null,
      totalTransparentAnchoringsPrice: null,
      totalTransparentAnchoringsPriceCalculation: null,
      totalTransparentFixingsNumberPrice: null,
      totalTransparentFixingsNumberPriceCalculation: null,
      transparentFixingsNumberUnitPrice: null,

      //Systems Integration production
      totalSystemsIntegrationPrice: null,
      totalSystemsIntegrationPriceCalculation: null,

      shieldingsUnitPrice: null,
      totalShieldingsPrice: null,
      totalShieldingsPriceCalculation: null,

      ventilationSystemsUnitPrice: null,
      totalVentilationSystemsPrice: null,
      totalVentilationSystemsPriceCalculation: null,

      airConditioningSystemsUnitPrice: null,
      totalAirConditioningSystemsPrice: null,
      totalAirConditioningSystemsPriceCalculation: null,

      thermicSolarPanelsUnitPrice: null,
      totalThermicSolarPanelsPrice: null,
      totalThermicSolarPanelsPriceCalculation: null,

      photovoltaicModulesUnitPrice: null,
      totalPhotovoltaicModulesPrice: null,
      totalPhotovoltaicModulesPriceCalculation: null,

      //Scrap production use getScrapUnitPrice(varName, string)
      totalScrapPrice: null,
      totalScrapPriceCalculation: null,

      doubleGlazingScrapQuantity: null,
      totalDoubleGlazingScrapPrice: null,
      totalDoubleGlazingScrapPriceCalculation: null,

      mainFrameOrLoomScrapQuantity: null,
      totalMainFrameOrLoomScrapPrice: null,
      totalMainFrameOrLoomScrapPriceCalculation: null,

      gasketScrapQuantity: null,
      totalGasketScrapPrice: null,
      totalGasketScrapPriceCalculation: null,

      opaqueWindowsScrapQuantity: null, //Solo opache
      totalOpaqueWindowsScrapPrice: null, //Solo opache
      totalOpaqueWindowsScrapPriceCalculation: null, //Solo opache

      opaqueExternalCladdingScrapQuantity: null, //Solo opache
      totalOpaqueExternalCladdingScrapPrice: null, //Solo opache
      totalOpaqueExternalCladdingScrapPriceCalculation: null, //Solo opache

      opaqueInsulationScrapQuantity: null, //Solo opache
      totalOpaqueInsulationScrapPrice: null, //Solo opache
      totalOpaqueInsulationScrapPriceCalculation: null, //Solo opache

      opaqueSupportingElementsScrapQuantity: null, //Solo opache
      totalOpaqueSupportingElementsScrapPrice: null, //Solo opache
      totalOpaqueSupportingElementsScrapPriceCalculation: null, //Solo opache

      transparentGlazedCellScrapQuantity: null, //Solo trasparenti
      totalTransparentGlazedCellScrapPrice: null, //Solo trasparenti
      totalTransparentGlazedCellScrapPriceCalculation: null, //Solo trasparenti

      transparentOpaqueSpandrelModulesQuantity: null, //Solo trasparenti
      totalTransparentOpaqueSpandrelModulesPrice: null, //Solo trasparenti
      totalTransparentOpaqueSpandrelModulesPriceCalculation: null, //Solo trasparenti

      fixingsScrapQuantity: null,
      totalFixingsScrapPrice: null,
      totalFixingsScrapPriceCalculation: null,

      anchoringsScrapQuantity: null,
      totalAnchoringsScrapPrice: null,
      totalAnchoringsScrapPriceCalculation: null,

      accessoriesScrap: null,

      //Altro
      shieldingSystemsNumber: null,
      ventilationDevicesNumber: null,
      airConditioningDevicesNumber: null,
      thermicSolarPanelsNumber: null,
      windowOpaqueSurfaceRatio: null,
      thermicSolarPanelsSurface: null,
      thermicSolarPanelsUnitSurface: null,
      photovoltaicModulesSurface: null,
      photovoltaicModulesNumber: null,
      photovoltaicModulesUnitSurface: null,
      opaqueUValue: null,
      transparentUValue: null,
      windowGlassGValue: null,
      fancadeGlassGValue: null,
      prefabricationLevel: null,
      geometricDetails: [],
      systemsIntegration: 0,
      systemsSurfacePercentage: null,
      systemsIntegrations: [],
      customFields: [],
    };
  }

  let fancadeProject;
  try {
    fancadeProject = JSON.parse(data.fancadeProject);
  } catch (e) {}
  if (!fancadeProject || Object.keys(fancadeProject).length === 0) {
    fancadeProject = {
      totalDesignPrice: null,
      technicalProject: null,
      structuralSismicProject: null,
      lightingTechnicalProject: null,
      ventilationProject: null,
      acusticProject: null,
      airQualityCheck: null,
      constructionPrinceAnalysis: null,
      firePreventionDesign: null,
      buildingSiteManagement: null,
      buildingSiteSafety: null,
      energeticPerformanceAnalysis: null,
      competitionProject: null,
      maintenancePlan: null,
      userManual: null,
    };
  }
  let fancadeKpiFancadeProject; /* = JSON.parse(data.fancadeKpiFancadeProject); */
  if (!fancadeKpiFancadeProject) {
    fancadeKpiFancadeProject = {
      totalDesignPrice: null,
      totalDesignPriceKPI: null,
    };
  }
  let fancadeProduction;
  try {
    fancadeProduction = JSON.parse(data.fancadeProduction);
  } catch (e) {}
  if (!fancadeProduction || Object.keys(fancadeProduction).length === 0) {
    fancadeProduction = {
      totalProductionPrice: null,
      totalMaterialsPrice: null,
      totalLaborPrice: null,
      materials: [],
    };
  }

  let transport;
  try {
    transport = JSON.parse(data.transport);
  } catch (e) {}
  if (!transport || Object.keys(transport).length === 0) {
    transport = {
      totalTransportPrice: null,
      totalTransportPriceCalculation: null,

      //Packaging
      totalPackagingPrice: null,
      totalPackagingPriceCalculation: null,
      packagings: [],

      //Transport
      totalTransportPartPrice: null,
      totalTransportPartPriceCalculation: null,
      workSiteProductionSiteDistance: null,
      transportPartUnitPrice: null,
    };
  }

  let buildingSite;
  try {
    buildingSite = JSON.parse(data.buildingSite);
  } catch (e) {}
  if (!buildingSite || Object.keys(buildingSite).length === 0) {
    buildingSite = {
      //-------------Fancade Installation-------------
      totalInstallationPrice: null,
      totalInstallationPriceCalculation: null,

      totalBuildingSiteMaterialsPrice: null,
      totalBuildingSiteMaterialsPriceCalculation: null,
      buildingSiteMaterials: [],

      buildingSiteDuration: null,

      //Labor
      totalLaborPrice: null,
      totalLaborPriceCalculation: null,

      totalBaseLaborPrice: null,
      totalBaseLaborPriceCalculation: null,
      baseLaborHours: null,
      baseLaborHourPrice: null,

      totalSkilledLaborPrice: null,
      totalSkilledLaborPriceCalculation: null,
      skilledLaborHours: null,
      skilledLaborHourPrice: null,

      //Tools
      totalToolsPrice: null,
      totalToolsPriceCalculation: null,

      totalTowerCranePrice: null,
      totalTowerCranePriceCalculation: null,
      towerCraneHours: null,
      towerCraneHourPrice: null,

      totalElevatorPlatformPrice: null,
      totalElevatorPlatformPriceCalculation: null,
      elevatorPlatformHours: null,
      elevatorPlatformHourPrice: null,

      totalAerialPlatformPrice: null,
      totalAerialPlatformPriceCalculation: null,
      aerialPlatformHours: null,
      aerialPlatformHourPrice: null,

      totalScaffoldingPrice: null,
      totalScaffoldingPriceCalculation: null,
      scaffoldingSurface: null,
      scaffoldingSurfaceUnitPrice: null,
      scaffoldingHours: null,
      scaffoldingHourPrice: null,

      totalOtherPrice: null,
      totalOtherPriceCalculation: null,
      otherHours: null,
      otherHourPrice: null,

      //-------------Security-------------
      totalSecurityPrice: null,
      totalSecurityPriceCalculation: null,

      totalStaffSecurityPrice: null,
      totalStaffSecurityPriceCalculation: null,
      staffSecurityHours: null,
      staffSecurityHourPrice: null,

      totalStaffBuildingSitePrice: null,
      totalStaffBuildingSitePriceCalculation: null,
      staffBuildingSiteHours: null,
      staffBuildingSiteHourPrice: null,

      taxes: null,
      other: null,

      //Kpi
      totalInstallationSecurityPrice: null,

      installationSecuritySurfaceRatio: null,
      installationSurfaceRatio: null,
      securitySurfaceRatio: null,

      installationTotalRatio: null,
      securityTotalRatio: null,
    };
  }

  let serviceLife;
  try {
    serviceLife = JSON.parse(data.serviceLife);
  } catch (e) {}
  if (!serviceLife || Object.keys(serviceLife).length === 0) {
    serviceLife = {
      annualLifeCost: null,
      annualLifeCostCalculation: null,

      //------------- Annual Integrated Implants Cost -------------
      annualIntegratedImplantsCost: null,
      annualIntegratedImplantsCostCalculation: null,

      annualShieldingsCost: null,
      annualShieldingCostsCalculation: null,
      //shieldingsNumber: null,
      annualShieldingsConsume: null,
      energeticShieldingVectorUnitPrice: null,

      annualVentilationCost: null,
      annualVentilationCostsCalculation: null,
      //ventilationsNumber: null,
      annualVentilationConsume: null,
      energeticVentilationVectorUnitPrice: null,

      annualConditioningCost: null,
      annualConditioningCostsCalculation: null,
      //ConditioningNumber: null,
      annualConditioningConsume: null,
      energeticConditioningVectorUnitPrice: null,

      annualThermicSolarCost: null,
      annualThermicSolarCostsCalculation: null,
      //ThermicSolarNumber: null,
      annualThermicSolarConsume: null,
      energeticThermicSolarVectorUnitPrice: null,

      annualPhotovoltaicIsActive: false,
      annualPhotovoltaicCost: null,
      annualPhotovoltaicCostsCalculation: null,
      //PhotovoltaicNumber: null,
      annualPhotovoltaicConsume: null,
      energeticPhotovoltaicVectorUnitPrice: null,

      //------------- Other implants -------------
      annualOtherImplantsCost: null,
      annualOtherImplantsCostCalculation: null,

      implants: [],
    };
  }

  let maintenance;
  try {
    maintenance = JSON.parse(data.maintenance);
  } catch (e) {}
  if (!maintenance || Object.keys(maintenance).length === 0) {
    maintenance = {
      refLife: 25,
      totalMaintenancePrice: null,
      totalMaintenancePriceCalculation: null,
      totalMaintenancePercentage: null,

      //---------------Ordinary maintenance---------------
      totalOrdinaryMaintenancePrice: null,
      totalOrdinaryMaintenancePriceCalculation: null,
      totalOrdinaryMaintenancePercentage: null,

      //Opaque
      totalWindowReplacementPrice: null,
      totalWindowReplacementPriceCalculation: null,
      windowsNumber: null,
      windowUnitPrice: null,

      totalOpaqueLoomFixingsReplacementPrice: null,
      totalOpaqueLoomFixingsReplacementPriceCalculation: null,
      opaqueLoomFixingsQuantity: null,
      opaqueLoomFixingsUnitPrice: null,

      totalOpaqueDoubleGlazingReplacementPrice: null,
      totalOpaqueDoubleGlazingReplacementPriceCalculation: null,
      opaqueDoubleGlazingQuantity: null,
      opaqueDoubleGlazingUnitPrice: null,

      totalOpaqueShieldingsReplacementPrice: null,
      totalOpaqueShieldingsReplacementPriceCalculation: null,
      opaqueShieldingsQuantity: null,
      opaqueShieldingsUnitPrice: null,

      totalExternalCladdingReplacementPrice: null,
      totalExternalCladdingReplacementPriceCalculation: null,
      externalCladdingQuantity: null,
      externalCladdingUnitPrice: null,

      totalInsulationReplacementPrice: null,
      totalInsulationReplacementPriceCalculation: null,
      insulationSurface: null,
      insulationUnitPrice: null,

      totalSupportingElementsReplacementPrice: null,
      totalSupportingElementsReplacementPriceCalculation: null,
      supportingElementsQuantity: null,
      supportingElementUnitPrice: null,

      totalPrefabricatedModulesReplacementPrice: null,
      totalPrefabricatedModulesReplacementPriceCalculation: null,
      prefabricatedModulesQuantity: null,
      prefabricatedModulesUnitPrice: null,

      //Transparent
      totalGlazedCellsReplacementPrice: null,
      totalGlazedCellsReplacementPriceCalculation: null,
      glazedCellsNumber: null,
      glazedCellUnitPrice: null,

      totalDoubleGlazingReplacementPrice: null,
      totalDoubleGlazingReplacementPriceCalculation: null,
      doubleGlazingQuantity: null,
      doubleGlazingUnitPrice: null,

      totalTransparentLoomFixingsReplacementPrice: null,
      totalTransparentLoomFixingsReplacementPriceCalculation: null,
      transparentLoomFixingsQuantity: null,
      transparentLoomFixingsUnitPrice: null,

      totalTransparentShieldingsReplacementPrice: null,
      totalTransparentShieldingsReplacementPriceCalculation: null,
      transparentShieldingsQuantity: null,
      transparentShieldingsUnitPrice: null,

      //Systems Integrations
      totalPhotovoltaicModulesReplacementPrice: null,
      totalPhotovoltaicModulesReplacementPriceCalculation: null,
      photovoltaicModulesNumber: null,
      photovoltaicModuleUnitPrice: null,

      totalSolarPanelsReplacementPrice: null,
      totalSolarPanelsReplacementPriceCalculation: null,
      solarPanelsNumber: null,
      solarPanelUnitPrice: null,

      totalVentilationDevicesReplacementPrice: null,
      totalVentilationDevicesReplacementPriceCalculation: null,
      ventilationDevicesNumber: null,
      ventilationDeviceUnitPrice: null,

      totalAirConditioningDevicesReplacementPrice: null,
      totalAirConditioningDevicesReplacementPriceCalculation: null,
      airConditioningDevicesNumber: null,
      airConditioningDeviceUnitPrice: null,

      //Cleaning
      totalCleaningPrice: null,
      totalCleaningPriceCalculation: null,
      cleaningsNumber: null,
      cleaningUnitPrice: null,

      //---------------Extraordinary maintenance---------------
      totalExtraordinaryMaintenancePrice: null,
      totalExtraordinaryMaintenancePriceCalculation: null,

      actions: [],
    };
  }

  let endOfLife;
  try {
    endOfLife = JSON.parse(data.endOfLife);
  } catch (e) {}
  if (!endOfLife || Object.keys(endOfLife).length === 0) {
    endOfLife = {
      totalDemolitionAndDisassemblyPrice: null,
      totalDemolitionAndDisassemblyPriceCalculation: null,

      surfaceDemolitionOrDisassembly: null,

      //---------------Demolition---------------
      totalDemolitionPrice: null,
      totalDemolitionPriceCalculation: null,

      demolitionQuantity: null,
      demolitionUnitPrice: null,

      //---------------Disassebly---------------
      totalDisassemblyPrice: null,
      totalDisassemblyPriceCalculation: null,

      //Opaque disassembly
      totalWindowsDisassemblyPrice: null,
      totalWindowsDisassemblyPriceCalculation: null,
      windowsQuantity: null,
      windowsUnitPrice: null,

      totalExternalCladdingDisassemblyPrice: null,
      totalExternalCladdingDisassemblyPriceCalculation: null,
      externalCladdingQuantity: null,
      externalCladdingUnitPrice: null,

      totalInsulationDisassemblyPrice: null,
      totalInsulationDisassemblyPriceCalculation: null,
      insulationQuantity: null,
      insulationUnitPrice: null,

      totalSupportingElementsAndFixingsDisassemblyPrice: null,
      totalSupportingElementsAndFixingsDisassemblyPriceCalculation: null,
      supportingElementsAndFixingsQuantity: null,
      supportingElementsAndFixingsUnitPrice: null,

      //Transparent disassembly
      totalGlazedCellsDisassemblyPrice: null,
      totalGlazedCellsDisassemblyPriceCalculation: null,
      glazedCellsQuantity: null,
      glazedCellsUnitPrice: null,

      totalDoubleGlazingDisassemblyPrice: null,
      totalDoubleGlazingDisassemblyPriceCalculation: null,
      doubleGlazingQuantity: null,
      doubleGlazingUnitPrice: null,

      totalUprightsCrosspiecesFixingsDisassemblyPrice: null,
      totalUprightsCrosspiecesFixingsDisassemblyPriceCalculation: null,
      uprightsCrosspiecesFixingsQuantity: null,
      uprightsCrosspiecesFixingsUnitPrice: null,

      //---------------Transport---------------
      totalTransportPrice: null,
      totalTransportPriceCalculation: null,

      //Disposal transport
      totalDisposalTransportPrice: null,
      totalDisposalTransportPriceCalculation: null,
      disposalTransportQuantity: null,
      disposalTransportUnitPrice: null,

      //Recycling transport
      totalRecyclingTransportPrice: null,
      totalRecyclingTransportPriceCalculation: null,
      recyclingTransportQuantity: null,
      recyclingTransportUnitPrice: null,

      //---------------Recycling---------------
      totalRecyclingRevenue: null,
      totalRecyclingRevenueCalculation: null,

      //Windows
      totalWindowsRecyclingRevenue: null,
      totalWindowsRecyclingRevenueCalculation: null,
      windowsRecyclingQuantity: null,
      windowsRecyclingUnitPrice: null,

      //External cladding
      totalExternalCladdingRecyclingRevenue: null,
      totalExternalCladdingRevenueCalculation: null,
      externalCladdingRecyclingQuantity: null,
      externalCladdingRecyclingUnitPrice: null,

      //Insulation
      totalInsulationRecyclingRevenue: null,
      totalInsulationRevenueCalculation: null,
      insulationRecyclingQuantity: null,
      insulationRecyclingUnitPrice: null,

      //Supporting elements and fixings
      totalSupportingElementsAndFixingsRecyclingRevenue: null,
      totalSupportingElementsAndFixingsRecyclingRevenueCalculation: null,
      supportingElementsAndFixingsRecyclingQuantity: null,
      supportingElementsAndFixingsRecyclingUnitPrice: null,

      //Glazed cells
      totalGlazedCellsRecyclingRevenue: null,
      totalGlazedCellsRecyclingRevenueCalculation: null,
      glazedCellsRecyclingQuantity: null,
      glazedCellsUnitRecyclingPrice: null,

      //Double glazing
      totalDoubleGlazingRecyclingRevenue: null,
      totalDoubleGlazingRecyclingRevenueCalculation: null,
      doubleGlazingRecyclingQuantity: null,
      doubleGlazingRecyclingUnitPrice: null,

      //Uprights, Crosspieces and Fixings
      totalUprightsCrosspiecesFixingsRecyclingRevenue: null,
      totalUprightsCrosspiecesFixingsRecyclingRevenueCalculation: null,
      uprightsCrosspiecesFixingsRecyclingQuantity: null,
      uprightsCrosspiecesFixingsRecyclingUnitPrice: null,
      //---------------ReUse---------------
      totalReUseRevenue: null,
      totalReUseRevenueCalculation: null,

      //Windows
      totalWindowsReUseRevenue: null,
      totalWindowsReUseRevenueCalculation: null,
      windowsReUseQuantity: null,
      windowsReUseUnitPrice: null,

      //External cladding
      totalExternalCladdingReUseRevenue: null,
      totalExternalCladdingReUseRevenueCalculation: null,
      externalCladdingReUseQuantity: null,
      externalCladdingReUseUnitPrice: null,

      //Insulation
      totalInsulationReUseRevenue: null,
      totalInsulationReUseRevenueCalculation: null,
      insulationReUseQuantity: null,
      insulationReUseUnitPrice: null,

      //Supporting elements and fixings
      totalSupportingElementsAndFixingsReUseRevenue: null,
      totalSupportingElementsAndFixingsReUseRevenueCalculation: null,
      supportingElementsAndFixingsReUseQuantity: null,
      supportingElementsAndFixingsReUseUnitPrice: null,

      //Glazed cells
      totalGlazedCellsReUseRevenue: null,
      totalGlazedCellsReUseRevenueCalculation: null,
      glazedCellsReUseQuantity: null,
      glazedCellsUnitReUsePrice: null,

      //Double glazing
      totalDoubleGlazingReUseRevenue: null,
      totalDoubleGlazingReUseRevenueCalculation: null,
      doubleGlazingReUseQuantity: null,
      doubleGlazingReUseUnitPrice: null,

      //Uprights, Crosspieces and Fixings
      totalUprightsCrosspiecesFixingsReUseRevenue: null,
      totalUprightsCrosspiecesFixingsReUseRevenueCalculation: null,
      uprightsCrosspiecesFixingsReUseQuantity: null,
      uprightsCrosspiecesFixingsReUseUnitPrice: null,
    };
  }
  let costsActualization;
  try {
    costsActualization = JSON.parse(data.costsActualization);
  } catch (e) {}
  if (!costsActualization || Object.keys(costsActualization).length === 0) {
    costsActualization = {
      interestRate: null,
      priceVectorVariation: 0,
      costOfLifeActualization: [],
      maintenanceCostActualization: [],
    };
  }

  let notes;
  try {
    notes = JSON.parse(data.notes);
  } catch (e) {}

  return {
    id: data.id,
    customName: data.customName,
    fancadeTechnicalProject: fancadeTechnicalProject,
    fancadeProject: fancadeProject,
    fancadeKpiFancadeProject: fancadeKpiFancadeProject,
    fancadeProduction: fancadeProduction,
    transport: transport,
    buildingSite: buildingSite,
    serviceLife: serviceLife,
    maintenance: maintenance,
    endOfLife: endOfLife,
    costsActualization: costsActualization,
    projectId: data.projectId,
    notes: notes,
  };
};

export const copyFancadeData = (
  from: Fancade,
  to: Fancade,
  section:
    | "all"
    | "fancadeTechnicalProject"
    | "fancadeProject"
    | "fancadeProduction"
    | "transport"
    | "buildingSite"
    | "serviceLife"
    | "maintenance"
    | "endOfLife"
) => {
  let toReturn: any = JSON.parse(JSON.stringify(to));
  switch (section) {
    case "fancadeTechnicalProject":
      toReturn.fancadeTechnicalProject = JSON.parse(
        JSON.stringify(from.fancadeTechnicalProject)
      );
      break;
    case "fancadeProject":
      toReturn.fancadeProject = JSON.parse(JSON.stringify(from.fancadeProject));
      break;
    case "fancadeProduction":
      toReturn.fancadeProduction = JSON.parse(
        JSON.stringify(from.fancadeProduction)
      );
      break;
    case "transport":
      toReturn.transport = JSON.parse(JSON.stringify(from.transport));
      break;
    case "buildingSite":
      toReturn.buildingSite = JSON.parse(JSON.stringify(from.buildingSite));
      break;
    case "serviceLife":
      toReturn.serviceLife = JSON.parse(JSON.stringify(from.serviceLife));
      break;
    case "maintenance":
      toReturn.maintenance = JSON.parse(JSON.stringify(from.maintenance));
      break;
    case "endOfLife":
      toReturn.endOfLife = JSON.parse(JSON.stringify(from.endOfLife));
      break;
    default:
      toReturn.fancadeTechnicalProject = JSON.parse(
        JSON.stringify(from.fancadeTechnicalProject)
      );
      toReturn.fancadeProject = JSON.parse(JSON.stringify(from.fancadeProject));
      toReturn.fancadeProduction = JSON.parse(
        JSON.stringify(from.fancadeProduction)
      );
      toReturn.transport = JSON.parse(JSON.stringify(from.transport));
      toReturn.buildingSite = JSON.parse(JSON.stringify(from.buildingSite));
      toReturn.serviceLife = JSON.parse(JSON.stringify(from.serviceLife));
      toReturn.maintenance = JSON.parse(JSON.stringify(from.maintenance));
      toReturn.endOfLife = JSON.parse(JSON.stringify(from.endOfLife));
      break;
  }

  return toReturn;
};

export const calculateMaintenancePeriodCost = (
  periodCalculation: number | null,
  period: number,
  unitPrice?: number,
  quantity?: number,
  totalPrice?: number
): number => {
  let toReturn: number = 0;

  let pricePerQuantity =
    unitPrice && quantity ? unitPrice * quantity : totalPrice;
  if (pricePerQuantity) {
    if (!periodCalculation || periodCalculation === 0) {
      toReturn = +pricePerQuantity / +period;
      if (period === 0) {
        toReturn = 0;
      }
    } else if (periodCalculation === 1) {
      toReturn = +pricePerQuantity * +period;
    }
  }

  return toReturn;
};

//------------------------Tables utils----------------------------------
export const sumRowsValues = (tables: TableRow[][]): TableRow[] => {
  let toReturn: TableRow[] = [];

  let ok = true;
  let currentIndex = -1;

  //Calculate the final table size
  let maxW = 0;
  let maxH = 0;

  tables.map((table) => {
    if (table.length > maxH) maxH = table.length;
    table.map((row) => {
      if (row.values.length > maxW) maxW = row.values.length;
    });
  });

  for (let i = 0; i < maxH; i++) {
    let values: number[] = [];
    for (let j = 0; j < maxW; j++) {
      if (values.length <= j) values.push(0);

      tables.map((table, index) => {
        if (table.length > i && table[i].values.length > j) {
          if (typeof table[i].values[j] == "number")
            values[j] += table[i].values[j] as number;
          else
            values[j] += parseFloat(
              (table[i].values[j] as string).replace(",", ".")
            );
        }
      });
    }

    toReturn.push({ name: tables[0][i].name, values });
  }

  return toReturn;
};

export const formatNumericTable = (
  table: TableRow[],
  units?: string[],
  maxDecimals?: number
): TableRow[] => {
  let toReturn: TableRow[] = [];

  table.map((row, index) => {
    let values: any[] = [];

    row.values.map((value, valueIndex) => {
      let currentValue: any;
      if (maxDecimals && typeof value === "number") {
        currentValue = value.toLocaleString("cs-CZ", {
          maximumFractionDigits: maxDecimals,
        });
      } else {
        currentValue = value.toLocaleString();
      }

      if (units && units[valueIndex]) {
        currentValue = currentValue + units[valueIndex];
      }

      values.push(currentValue);
    });
    toReturn.push({ name: row.name, values: values });
  });

  return toReturn;
};

//------------------------Project Values Getters------------------------
export const getFancadeTechnicalProject = (
  project: Project,
  fancadeIndex: number
): FancadeTechnicalProjectType | undefined => {
  if (!project.fancades || project.fancades.length < fancadeIndex + 1) {
    return undefined;
  }
  const fancade = project.fancades[fancadeIndex];
  let toReturn: FancadeTechnicalProjectType = {
    ...fancade.fancadeTechnicalProject,
  };
  if (toReturn.type && toReturn.type.toLowerCase().indexOf("opaca") != -1) {
    //Make all the "transparent fields" null
    toReturn.transparentTotalPrice = null;
    toReturn.transparentTotalPriceCalculation = null;
    toReturn.glazedCellsNumber = null;
    toReturn.glazedCellsSurface = null;
    toReturn.doubleGlazingQuantity = null;
    toReturn.doubleGlazingSurface = null;
    toReturn.mainFrameHeightPerUnit = null;
    toReturn.mainFrameWidthPerUnit = null;
    toReturn.totalMainFrameLength = null;
    toReturn.totalGasketLength = null;
    toReturn.opaqueSpandrelPanelsQuantity = null;
    toReturn.opaqueSpandrelPanelsSurface = null;
    toReturn.transparentAnchoringsNumber = null; //ancoraggi
    toReturn.transparentFixingsNumber = null; //fissaggi
    toReturn.glazedCellsUnitPrice = null;
    toReturn.totalGlazedCellsPrice = null;
    toReturn.totalGlazedCellsPriceCalculation = null;
    toReturn.totalMainFramePrice = null;
    toReturn.totalMainFramePriceCalculation = null;
    toReturn.mainFrameUnitPrice = null;
    toReturn.totalDoubleGlazingPrice = null;
    toReturn.totalDoubleGlazingPriceCalculation = null;
    toReturn.doubleGlazingUnitPrice = null;
    toReturn.gasketUnitPrice = null;
    toReturn.totalGasketPrice = null;
    toReturn.totalGasketPriceCalculation = null;
    toReturn.opaqueSpandrelModulesUnitPrice = null;
    toReturn.totalOpaqueSpandrelModulesPrice = null;
    toReturn.totalOpaqueSpandrelModulesPriceCalculation = null;
    toReturn.transparentAnchoringsUnitPrice = null;
    toReturn.totalTransparentAnchoringsPrice = null;
    toReturn.totalTransparentAnchoringsPriceCalculation = null;
    toReturn.totalTransparentFixingsNumberPrice = null;
    toReturn.totalTransparentFixingsNumberPriceCalculation = null;
    toReturn.transparentFixingsNumberUnitPrice = null;

    //------Calculate fields-----
    //Windows
    toReturn.windowsTotalPrice = calculateOpaqueWindowsTotal(fancade);
    toReturn.windows.map((window, index) => {
      toReturn.windows[index].totalPrice = calculateOpaqueWindowTotal(
        fancade,
        index
      );

      //Loom
      if (
        toReturn.windows[index].loomComponentsPriceCalculation &&
        toReturn.windows[index].loomComponentsPriceCalculation == 1
      ) {
        if (
          toReturn.windows[index].loomUnitPrice &&
          toReturn.windows[index].loomHeight &&
          toReturn.windows[index].loomWidth &&
          toReturn.windows[index].doubleGlazingNumber
        ) {
          toReturn.windows[index].loomComponentsPrice = //@ts-ignore
            +toReturn.windows[index].loomUnitPrice *
            +calculateTotalLoomLength(
              //@ts-ignore
              toReturn.windows[index].loomHeight,
              toReturn.windows[index].loomWidth,
              toReturn.windows[index].doubleGlazingNumber
            );
        } else toReturn.windows[index].loomComponentsPrice = 0;
      }

      //Double glazing
      if (
        toReturn.windows[index].doubleGlazingComponentsPriceCalculation &&
        toReturn.windows[index].doubleGlazingComponentsPriceCalculation == 1
      ) {
        if (
          toReturn.windows[index].doubleGlazingUnitPrice &&
          toReturn.windows[index].doubleGlazingNumber
        ) {
          toReturn.windows[index].doubleGlazingComponentsPrice = //@ts-ignore
            +toReturn.windows[index].doubleGlazingUnitPrice * //@ts-ignore
            +toReturn.windows[index].doubleGlazingNumber;
        } else toReturn.windows[index].doubleGlazingComponentsPrice = 0;
      }

      //Gasket
      if (
        toReturn.windows[index].gasketsComponentsPriceCalculation &&
        toReturn.windows[index].gasketsComponentsPriceCalculation == 1
      ) {
        if (
          toReturn.windows[index].gasketsUnitPrice &&
          toReturn.windows[index].totalGasketLength
        ) {
          toReturn.windows[index].gasketsComponentsPrice = //@ts-ignore
            +toReturn.windows[index].gasketsUnitPrice * //@ts-ignore
            +toReturn.windows[index].doubleGlazingNumber;
        } else toReturn.windows[index].totalGasketLength = 0;
      }
    });
    //External cladding
    if (
      toReturn.externalCladdingTotalPriceCalculation &&
      toReturn.externalCladdingTotalPriceCalculation == 1
    ) {
      if (
        toReturn.externalCladdingUnitPrice &&
        toReturn.externalCladdingUnitsNumber
      )
        toReturn.externalCladdingTotalPrice =
          +toReturn.externalCladdingUnitPrice *
          +toReturn.externalCladdingUnitsNumber;
      else toReturn.externalCladdingTotalPrice = 0;
    }

    //Insultation
    if (
      toReturn.insulationTotalPriceCalculation &&
      toReturn.insulationTotalPriceCalculation == 1
    ) {
      if (toReturn.insulationUnitPrice && toReturn.insulationSurface)
        toReturn.insulationTotalPrice =
          +toReturn.insulationUnitPrice * +toReturn.insulationSurface;
      else toReturn.insulationTotalPrice = 0;
    }

    //Supporting elements
    if (
      toReturn.supportingElementsTotalPriceCalculation &&
      toReturn.supportingElementsTotalPriceCalculation == 1
    ) {
      if (
        toReturn.supportingElementsUnitPrice &&
        toReturn.supportingElementsQuantity
      )
        toReturn.supportingElementsTotalPrice =
          +toReturn.supportingElementsUnitPrice *
          +toReturn.supportingElementsQuantity;
      else toReturn.supportingElementsTotalPrice = 0;
    }

    //Prefabricated modules
    if (
      toReturn.prefabricatedModulesTotalPriceCalculation &&
      toReturn.prefabricatedModulesTotalPriceCalculation == 1
    ) {
      if (toReturn.prefabricatedModulesUnitPrice && toReturn.modulesSurface)
        toReturn.prefabricatedModulesTotalPrice =
          +toReturn.prefabricatedModulesUnitPrice * +toReturn.modulesSurface;
      else toReturn.prefabricatedModulesTotalPrice = 0;
    }

    //Opaque anchorings
    if (
      toReturn.opaqueAnchoringTotalPriceCalculation &&
      toReturn.opaqueAnchoringTotalPriceCalculation == 1
    ) {
      if (toReturn.opaqueAnchoringUnitPrice && toReturn.opaqueAnchoringsNumber)
        toReturn.opaqueAnchoringTotalPrice =
          +toReturn.opaqueAnchoringUnitPrice * +toReturn.opaqueAnchoringsNumber;
      else toReturn.opaqueAnchoringTotalPrice = 0;
    }

    //Opaque anchorings
    if (
      toReturn.opaqueFixingsTotalPriceCalculation &&
      toReturn.opaqueFixingsTotalPriceCalculation == 1
    ) {
      if (toReturn.opaqueFixingsUnitPrice && toReturn.opaqueFixingsNumber)
        toReturn.opaqueFixingsTotalPrice =
          +toReturn.opaqueFixingsUnitPrice * +toReturn.opaqueFixingsNumber;
      else toReturn.opaqueFixingsTotalPrice = 0;
    }
  } else if (
    toReturn.type &&
    toReturn.type.toLowerCase().indexOf("trasparente") != -1
  ) {
    toReturn.windows = [];
    toReturn.windowsTotalPrice = null;
    toReturn.externalCladdingUnitsNumber = null; //rivestimento
    toReturn.totalExternalCladdingSurface = null; //rivestimento
    toReturn.insulationSurface = null; //isolamento
    toReturn.supportingElementsQuantity = null; //elementi portanti
    toReturn.prefabricatedModulesQuantity = null;
    toReturn.modulesSurface = null;
    toReturn.opaqueAnchoringsNumber = null; //ancoraggi
    toReturn.opaqueFixingsNumber = null; //fissaggi

    //Production
    toReturn.externalCladdingTotalPrice = null;
    toReturn.externalCladdingTotalPriceCalculation = null;
    toReturn.externalCladdingUnitPrice = null;
    toReturn.insulationTotalPrice = null;
    toReturn.insulationTotalPriceCalculation = null;
    toReturn.insulationUnitPrice = null;
    toReturn.supportingElementsTotalPrice = null;
    toReturn.supportingElementsTotalPriceCalculation = null;
    toReturn.supportingElementsUnitPrice = null;

    toReturn.prefabricatedModulesTotalPrice = null;
    toReturn.prefabricatedModulesTotalPriceCalculation = null;
    toReturn.prefabricatedModulesUnitPrice = null;

    toReturn.opaqueAnchoringTotalPrice = null;
    toReturn.opaqueAnchoringTotalPriceCalculation = null;
    toReturn.opaqueAnchoringUnitPrice = null;

    toReturn.opaqueFixingsTotalPrice = null;
    toReturn.opaqueFixingsTotalPriceCalculation = null;
    toReturn.opaqueFixingsUnitPrice = null;

    //------Calculate fields-----
    //Transparent windows
    if (
      toReturn.transparentTotalPriceCalculation &&
      toReturn.transparentTotalPriceCalculation == 1
    ) {
      toReturn.transparentTotalPrice =
        calculateTransparentWindowsTotal(fancade);
    }

    //Mainframe length
    if (toReturn.mainFrameHeightPerUnit && toReturn.mainFrameWidthPerUnit)
      toReturn.transparentTotalPrice =
        +toReturn.mainFrameHeightPerUnit * +toReturn.mainFrameWidthPerUnit;
    else toReturn.transparentTotalPrice = 0;
  }

  //Systems integration
  if (
    toReturn.totalSystemsIntegrationPriceCalculation &&
    toReturn.totalSystemsIntegrationPriceCalculation == 1
  ) {
    toReturn.totalSystemsIntegrationPrice =
      calculateSystemsIntegrationTotal(fancade);
  }

  //Shieldings
  if (
    toReturn.totalShieldingsPriceCalculation &&
    toReturn.totalShieldingsPriceCalculation == 1
  ) {
    if (toReturn.shieldingsUnitPrice && toReturn.shieldingSystemsNumber)
      toReturn.totalShieldingsPrice =
        +toReturn.shieldingsUnitPrice * +toReturn.shieldingSystemsNumber;
    else toReturn.totalShieldingsPrice = 0;
  }

  //Ventilation
  if (
    toReturn.totalVentilationSystemsPriceCalculation &&
    toReturn.totalVentilationSystemsPriceCalculation == 1
  ) {
    if (
      toReturn.ventilationSystemsUnitPrice &&
      toReturn.ventilationDevicesNumber
    )
      toReturn.totalVentilationSystemsPrice =
        +toReturn.ventilationSystemsUnitPrice *
        +toReturn.ventilationDevicesNumber;
    else toReturn.totalVentilationSystemsPrice = 0;
  }

  //Air Conditioning
  if (
    toReturn.totalAirConditioningSystemsPriceCalculation &&
    toReturn.totalAirConditioningSystemsPriceCalculation == 1
  ) {
    if (
      toReturn.airConditioningSystemsUnitPrice &&
      toReturn.airConditioningDevicesNumber
    )
      toReturn.totalAirConditioningSystemsPrice =
        +toReturn.airConditioningSystemsUnitPrice *
        +toReturn.airConditioningDevicesNumber;
    else toReturn.totalAirConditioningSystemsPrice = 0;
  }

  //Thermic solar panels
  if (
    toReturn.totalThermicSolarPanelsPriceCalculation &&
    toReturn.totalThermicSolarPanelsPriceCalculation == 1
  ) {
    if (
      toReturn.thermicSolarPanelsUnitPrice &&
      toReturn.thermicSolarPanelsNumber
    )
      toReturn.totalThermicSolarPanelsPrice =
        +toReturn.thermicSolarPanelsUnitPrice *
        +toReturn.thermicSolarPanelsNumber;
    else toReturn.totalThermicSolarPanelsPrice = 0;
  }

  //Photovoltaic modules
  if (
    toReturn.totalPhotovoltaicModulesPriceCalculation &&
    toReturn.totalPhotovoltaicModulesPriceCalculation == 1
  ) {
    if (
      toReturn.photovoltaicModulesUnitPrice &&
      toReturn.photovoltaicModulesNumber
    )
      toReturn.totalPhotovoltaicModulesPrice =
        +toReturn.photovoltaicModulesUnitPrice *
        +toReturn.photovoltaicModulesUnitPrice;
    else toReturn.totalPhotovoltaicModulesPrice = 0;
  }

  //-----Scrap-----
  //Scrap
  if (
    toReturn.totalScrapPriceCalculation &&
    toReturn.totalScrapPriceCalculation == 1
  ) {
    toReturn.totalScrapPrice = calculateScrapTotal(fancade);
  }
  //Double Glazing scrap
  if (
    toReturn.totalDoubleGlazingScrapPriceCalculation &&
    toReturn.totalDoubleGlazingScrapPriceCalculation == 1
  ) {
    if (
      getScrapUnitPrice("doubleglazing", fancade) &&
      toReturn.doubleGlazingScrapQuantity
    )
      toReturn.totalDoubleGlazingScrapPrice =
        +getScrapUnitPrice("doubleglazing", fancade) *
        +toReturn.doubleGlazingScrapQuantity;
    else toReturn.totalPhotovoltaicModulesPrice = 0;
  }

  //Mainframe scrap
  if (
    toReturn.totalMainFrameOrLoomScrapPriceCalculation &&
    toReturn.totalMainFrameOrLoomScrapPriceCalculation == 1
  ) {
    if (
      getScrapUnitPrice("mainframeorloop", fancade) &&
      toReturn.mainFrameOrLoomScrapQuantity
    )
      toReturn.totalMainFrameOrLoomScrapPrice =
        +getScrapUnitPrice("mainframeorloop", fancade) *
        +toReturn.mainFrameOrLoomScrapQuantity;
    else toReturn.totalMainFrameOrLoomScrapPrice = 0;
  }

  //Gasket scrap
  if (
    toReturn.totalGasketScrapPriceCalculation &&
    toReturn.totalGasketScrapPriceCalculation == 1
  ) {
    if (getScrapUnitPrice("gasket", fancade) && toReturn.gasketScrapQuantity)
      toReturn.totalGasketScrapPrice =
        +getScrapUnitPrice("gasket", fancade) * +toReturn.gasketScrapQuantity;
    else toReturn.totalGasketScrapPrice = 0;
  }

  if (toReturn.type && toReturn.type.toLowerCase().indexOf("opaca") != -1) {
    //Opaque windows
    if (
      toReturn.totalOpaqueWindowsScrapPriceCalculation &&
      toReturn.totalOpaqueWindowsScrapPriceCalculation == 1
    ) {
      if (
        getScrapUnitPrice("opaquewindows", fancade) &&
        toReturn.opaqueWindowsScrapQuantity
      )
        toReturn.totalOpaqueWindowsScrapPrice =
          +getScrapUnitPrice("opaquewindows", fancade) *
          +toReturn.opaqueWindowsScrapQuantity;
      else toReturn.totalOpaqueWindowsScrapPrice = 0;
    }

    //Opaque external cladding
    if (
      toReturn.totalOpaqueExternalCladdingScrapPriceCalculation &&
      toReturn.totalOpaqueExternalCladdingScrapPriceCalculation == 1
    ) {
      if (
        getScrapUnitPrice("externalcladding", fancade) &&
        toReturn.opaqueExternalCladdingScrapQuantity
      )
        toReturn.totalOpaqueExternalCladdingScrapPrice =
          +getScrapUnitPrice("externalcladding", fancade) *
          +toReturn.opaqueExternalCladdingScrapQuantity;
      else toReturn.totalOpaqueExternalCladdingScrapPrice = 0;
    }

    //Opaque insulation
    if (
      toReturn.totalOpaqueInsulationScrapPriceCalculation &&
      toReturn.totalOpaqueInsulationScrapPriceCalculation == 1
    ) {
      if (
        getScrapUnitPrice("insulation", fancade) &&
        toReturn.opaqueInsulationScrapQuantity
      )
        toReturn.totalOpaqueInsulationScrapPrice =
          +getScrapUnitPrice("insulation", fancade) *
          +toReturn.opaqueInsulationScrapQuantity;
      else toReturn.totalOpaqueInsulationScrapPrice = 0;
    }

    //Opaque supporting elements
    if (
      toReturn.totalOpaqueSupportingElementsScrapPriceCalculation &&
      toReturn.totalOpaqueSupportingElementsScrapPriceCalculation == 1
    ) {
      if (
        getScrapUnitPrice("insulation", fancade) &&
        toReturn.opaqueSupportingElementsScrapQuantity
      )
        toReturn.totalOpaqueSupportingElementsScrapPrice =
          +getScrapUnitPrice("insulation", fancade) *
          +toReturn.opaqueSupportingElementsScrapQuantity;
      else toReturn.totalOpaqueSupportingElementsScrapPrice = 0;
    }
  } else if (
    toReturn.type &&
    toReturn.type.toLowerCase().indexOf("trasparente") != -1
  ) {
    //Transparent glazed cells
    if (
      toReturn.totalTransparentGlazedCellScrapPriceCalculation &&
      toReturn.totalTransparentGlazedCellScrapPriceCalculation == 1
    ) {
      if (
        getScrapUnitPrice("glazedcells", fancade) &&
        toReturn.transparentGlazedCellScrapQuantity
      )
        toReturn.totalTransparentGlazedCellScrapPrice =
          +getScrapUnitPrice("glazedcells", fancade) *
          +toReturn.transparentGlazedCellScrapQuantity;
      else toReturn.totalTransparentGlazedCellScrapPrice = 0;
    }

    //Transparent opaque spandrel
    if (
      toReturn.totalTransparentOpaqueSpandrelModulesPriceCalculation &&
      toReturn.totalTransparentOpaqueSpandrelModulesPriceCalculation == 1
    ) {
      if (
        getScrapUnitPrice("opaquespandrelmodules", fancade) &&
        toReturn.transparentOpaqueSpandrelModulesQuantity
      )
        toReturn.totalTransparentOpaqueSpandrelModulesPrice =
          +getScrapUnitPrice("opaquespandrelmodules", fancade) *
          +toReturn.transparentOpaqueSpandrelModulesQuantity;
      else toReturn.totalTransparentOpaqueSpandrelModulesPrice = 0;
    }
  }

  //Fixings
  if (
    toReturn.totalFixingsScrapPriceCalculation &&
    toReturn.totalFixingsScrapPriceCalculation == 1
  ) {
    if (getScrapUnitPrice("fixings", fancade) && toReturn.fixingsScrapQuantity)
      toReturn.totalFixingsScrapPrice =
        +getScrapUnitPrice("fixings", fancade) * +toReturn.fixingsScrapQuantity;
    else toReturn.totalFixingsScrapPrice = 0;
  }

  //Anchorings
  if (
    toReturn.totalAnchoringsScrapPriceCalculation &&
    toReturn.totalAnchoringsScrapPriceCalculation == 1
  ) {
    if (
      getScrapUnitPrice("anchoring", fancade) &&
      toReturn.anchoringsScrapQuantity
    )
      toReturn.totalAnchoringsScrapPrice =
        +getScrapUnitPrice("anchoring", fancade) *
        +toReturn.anchoringsScrapQuantity;
    else toReturn.totalAnchoringsScrapPrice = 0;
  }

  return toReturn;
};

export const getFancadeProject = (
  project: Project,
  fancadeIndex: number
): FancadeProjectType | undefined => {
  if (!project.fancades || project.fancades.length < fancadeIndex + 1) {
    return undefined;
  }
  const fancade = project.fancades[fancadeIndex];
  let toReturn: FancadeProjectType = {
    ...fancade.fancadeProject,
  };

  toReturn.totalDesignPrice = calculateFancadeDesignTotalCost(
    fancade.fancadeProject
  );

  return toReturn;
};

export const getFancadeProduction = (
  project: Project,
  fancadeIndex: number
): FancadeProductionType | undefined => {
  if (!project.fancades || project.fancades.length < fancadeIndex + 1) {
    return undefined;
  }
  const fancade = project.fancades[fancadeIndex];
  let toReturn: FancadeProductionType = {
    ...fancade.fancadeProduction,
  };

  //Production
  if (
    toReturn.totalProductionPriceCalculation &&
    toReturn.totalProductionPriceCalculation == 1
  ) {
    toReturn.totalProductionPrice = calculateProductionTotal(fancade);
  }

  //Materials
  if (
    toReturn.totalMaterialsPriceCalculation &&
    toReturn.totalMaterialsPriceCalculation == 1
  ) {
    toReturn.totalMaterialsPrice = calculateMaterialsTotal(fancade);
  }

  //System integrations
  if (
    toReturn.totalSystemIntegrationPriceCalculation &&
    toReturn.totalSystemIntegrationPriceCalculation == 1
  ) {
    toReturn.totalSystemIntegrationPrice =
      calculateSystemsIntegrationTotal(fancade);
  }

  //Labor
  if (
    toReturn.totalLaborPriceCalculation &&
    toReturn.totalLaborPriceCalculation == 1
  ) {
    toReturn.totalLaborPrice = calculateLaborTotal(fancade);
  }

  //Base labor
  if (
    toReturn.totalBaseLaborPriceCalculation &&
    toReturn.totalBaseLaborPriceCalculation == 1
  ) {
    if (toReturn.baseLaborHourPrice && toReturn.baseLaborHours)
      toReturn.totalBaseLaborPrice =
        +toReturn.baseLaborHourPrice * +toReturn.baseLaborHours;
    else toReturn.totalBaseLaborPrice = 0;
  }

  //Skilled labor
  if (
    toReturn.totalSkilledLaborPriceCalculation &&
    toReturn.totalSkilledLaborPriceCalculation == 1
  ) {
    if (toReturn.skilledLaborHourPrice && toReturn.skilledLaborHours)
      toReturn.totalSkilledLaborPrice =
        +toReturn.skilledLaborHourPrice * +toReturn.skilledLaborHours;
    else toReturn.totalSkilledLaborPrice = 0;
  }

  //Hardware
  if (
    toReturn.hardwareTotalPriceCalculation &&
    toReturn.hardwareTotalPriceCalculation == 1
  ) {
    toReturn.hardwareTotalPrice = calculateHardwareTotalPrice(fancade);
  }

  if (toReturn.hardware) {
    toReturn.hardware.map((hardware, index) => {
      //Hardware
      if (
        hardware.totalPriceCalculation &&
        hardware.totalPriceCalculation == 1
      ) {
        if (toReturn.hardware) {
          if (hardware.unitPrice && hardware.quantity)
            toReturn.hardware[index].totalPrice =
              +hardware.unitPrice * +hardware.quantity;
          else toReturn.hardware[index].totalPrice = 0;
        }
      }
    });
  }

  return toReturn;
};

export const getTransport = (
  project: Project,
  fancadeIndex: number
): TransportType | undefined => {
  if (!project.fancades || project.fancades.length < fancadeIndex + 1) {
    return undefined;
  }
  const fancade = project.fancades[fancadeIndex];
  let toReturn: TransportType = {
    ...fancade.transport,
  };

  //Transport total
  if (
    toReturn.totalTransportPriceCalculation &&
    toReturn.totalTransportPriceCalculation == 1
  ) {
    toReturn.totalTransportPrice = calculateTransportTotal(fancade);
  }

  //Packaging
  if (
    toReturn.totalPackagingPriceCalculation &&
    toReturn.totalPackagingPriceCalculation == 1
  ) {
    toReturn.totalPackagingPrice = calculatePackagingsTotal(fancade);
  }

  toReturn.packagings?.map((packaging, index) => {
    if (
      packaging.totalPriceCalculation &&
      packaging.totalPriceCalculation == 1
    ) {
      if (toReturn.packagings) {
        if (packaging.unitPrice && packaging.unitsNumber)
          toReturn.packagings[index].totalPrice =
            +packaging.unitPrice * +packaging.unitsNumber;
        else toReturn.packagings[index].totalPrice = 0;
      }
    }
  });

  //Transport part
  if (
    toReturn.totalTransportPartPriceCalculation &&
    toReturn.totalTransportPartPriceCalculation == 1
  ) {
    toReturn.totalTransportPartPrice = calculateTransportPartTotal(fancade);
  }

  return toReturn;
};

export const getBuildingSite = (
  project: Project,
  fancadeIndex: number
): BuildingSiteType | undefined => {
  if (!project.fancades || project.fancades.length < fancadeIndex + 1) {
    return undefined;
  }
  const fancade = project.fancades[fancadeIndex];
  let toReturn: BuildingSiteType = {
    ...fancade.buildingSite,
  };

  //-------------Fancade Installation-------------
  if (
    toReturn.totalInstallationPriceCalculation &&
    toReturn.totalInstallationPriceCalculation == 1
  ) {
    toReturn.totalInstallationPrice = calculateInstallationTotal(fancade);
  }

  //Building site materials
  if (
    toReturn.totalBuildingSiteMaterialsPriceCalculation &&
    toReturn.totalBuildingSiteMaterialsPriceCalculation == 1
  ) {
    toReturn.totalInstallationPrice =
      calculateBuildingSiteMaterialsTotal(fancade);
  }

  toReturn.buildingSiteMaterials.map((material, index) => {
    if (
      material.totalAdditionalMaterialPriceCalculation &&
      material.totalAdditionalMaterialPriceCalculation == 1
    ) {
      if (
        material.additionalMaterialUnitPrice &&
        material.additionalMaterialQuantity
      )
        toReturn.buildingSiteMaterials[index].totalAdditionalMaterialPrice =
          +material.additionalMaterialUnitPrice *
          +material.additionalMaterialQuantity;
      else
        toReturn.buildingSiteMaterials[index].totalAdditionalMaterialPrice = 0;
    }
  });

  //Base labor
  if (
    toReturn.totalBaseLaborPriceCalculation &&
    toReturn.totalBaseLaborPriceCalculation == 1
  ) {
    if (toReturn.baseLaborHourPrice && toReturn.baseLaborHours)
      toReturn.totalBaseLaborPrice =
        +toReturn.baseLaborHourPrice * +toReturn.baseLaborHours;
    else toReturn.totalBaseLaborPrice = 0;
  }

  //Skilled labor
  if (
    toReturn.totalSkilledLaborPriceCalculation &&
    toReturn.totalSkilledLaborPriceCalculation == 1
  ) {
    if (toReturn.skilledLaborHourPrice && toReturn.skilledLaborHours)
      toReturn.totalSkilledLaborPrice =
        +toReturn.skilledLaborHourPrice * +toReturn.skilledLaborHours;
    else toReturn.totalSkilledLaborPrice = 0;
  }

  //Tools
  if (
    toReturn.totalToolsPriceCalculation &&
    toReturn.totalToolsPriceCalculation == 1
  ) {
    toReturn.totalToolsPrice = calculateBuildingSiteToolsTotal(fancade);
  }

  //Crane
  if (
    toReturn.totalTowerCranePriceCalculation &&
    toReturn.totalTowerCranePriceCalculation == 1
  ) {
    if (toReturn.towerCraneHourPrice && toReturn.towerCraneHours)
      toReturn.totalTowerCranePrice =
        +toReturn.towerCraneHourPrice * +toReturn.towerCraneHours;
    else toReturn.totalTowerCranePrice = 0;
  }

  //Elevator platform
  if (
    toReturn.totalElevatorPlatformPriceCalculation &&
    toReturn.totalElevatorPlatformPriceCalculation == 1
  ) {
    if (toReturn.elevatorPlatformHourPrice && toReturn.elevatorPlatformHours)
      toReturn.totalElevatorPlatformPrice =
        +toReturn.elevatorPlatformHourPrice * +toReturn.elevatorPlatformHours;
    else toReturn.totalElevatorPlatformPrice = 0;
  }

  //Aerial platform
  if (
    toReturn.totalAerialPlatformPriceCalculation &&
    toReturn.totalAerialPlatformPriceCalculation == 1
  ) {
    if (toReturn.aerialPlatformHourPrice && toReturn.aerialPlatformHours)
      toReturn.totalAerialPlatformPrice =
        +toReturn.aerialPlatformHourPrice * +toReturn.aerialPlatformHours;
    else toReturn.totalAerialPlatformPrice = 0;
  }

  //Scaffolding
  if (
    toReturn.totalScaffoldingPriceCalculation &&
    toReturn.totalScaffoldingPriceCalculation == 1
  ) {
    if (toReturn.scaffoldingHourPrice && toReturn.scaffoldingHours)
      toReturn.totalScaffoldingPrice =
        +toReturn.scaffoldingHourPrice * +toReturn.scaffoldingHours;
    else toReturn.totalScaffoldingPrice = 0;
  }

  //Others
  if (
    toReturn.totalOtherPriceCalculation &&
    toReturn.totalOtherPriceCalculation == 1
  ) {
    if (toReturn.otherHourPrice && toReturn.otherHours)
      toReturn.totalOtherPrice =
        +toReturn.otherHourPrice * +toReturn.otherHours;
    else toReturn.totalOtherPrice = 0;
  }

  //-------------------Security-------------------
  //Security
  if (
    toReturn.totalSecurityPriceCalculation &&
    toReturn.totalSecurityPriceCalculation == 1
  ) {
    calculateSecurityTotal(fancade);
  }

  //Staff security
  if (
    toReturn.totalStaffSecurityPriceCalculation &&
    toReturn.totalStaffSecurityPriceCalculation == 1
  ) {
    if (toReturn.staffSecurityHourPrice && toReturn.staffSecurityHours)
      toReturn.totalStaffSecurityPrice =
        +toReturn.staffSecurityHourPrice * +toReturn.staffSecurityHours;
    else toReturn.totalStaffSecurityPrice = 0;
  }

  //Staff building site
  if (
    toReturn.totalStaffBuildingSitePriceCalculation &&
    toReturn.totalStaffBuildingSitePriceCalculation == 1
  ) {
    if (toReturn.staffBuildingSiteHourPrice && toReturn.staffBuildingSiteHours)
      toReturn.totalStaffBuildingSitePrice =
        +toReturn.staffBuildingSiteHourPrice * +toReturn.staffBuildingSiteHours;
    else toReturn.totalStaffBuildingSitePrice = 0;
  }

  return toReturn;
};

export const getServiceLife = (
  project: Project,
  fancadeIndex: number
): ServiceLifeType | undefined => {
  if (!project.fancades || project.fancades.length < fancadeIndex + 1) {
    return undefined;
  }
  const fancade = project.fancades[fancadeIndex];
  let toReturn: ServiceLifeType = {
    ...fancade.serviceLife,
  };

  //ServiceLife
  if (
    toReturn.annualLifeCostCalculation &&
    toReturn.annualLifeCostCalculation == 1
  ) {
    toReturn.annualLifeCost = calculateServiceOfLifeTotal(fancade);
  }

  //Integrated implants
  if (
    toReturn.annualIntegratedImplantsCostCalculation &&
    toReturn.annualIntegratedImplantsCostCalculation == 1
  ) {
    toReturn.annualIntegratedImplantsCost =
      calculateServiceOfLifeIntegratedImplantsTotal(fancade);

    const fancadeTechnicalProject =
      project.fancades[fancadeIndex].fancadeTechnicalProject;

    //Annual shielding cost
    if (
      toReturn.annualShieldingCostsCalculation &&
      toReturn.annualShieldingCostsCalculation == 1
    ) {
      if (
        toReturn.annualShieldingsConsume &&
        fancadeTechnicalProject.shieldingSystemsNumber &&
        toReturn.energeticShieldingVectorUnitPrice
      )
        toReturn.annualShieldingsCost =
          +toReturn.annualShieldingsConsume *
          +fancadeTechnicalProject.shieldingSystemsNumber *
          +toReturn.energeticShieldingVectorUnitPrice;
      else toReturn.annualShieldingsCost = 0;
    }

    //Annual ventilation cost
    if (
      toReturn.annualVentilationCostsCalculation &&
      toReturn.annualVentilationCostsCalculation == 1
    ) {
      if (
        toReturn.annualVentilationConsume &&
        fancadeTechnicalProject.ventilationDevicesNumber &&
        toReturn.energeticVentilationVectorUnitPrice
      )
        toReturn.annualVentilationCost =
          +toReturn.annualVentilationConsume *
          +fancadeTechnicalProject.ventilationDevicesNumber *
          +toReturn.energeticVentilationVectorUnitPrice;
      else toReturn.annualVentilationCost = 0;
    }

    //Annual conditioning cost
    if (
      toReturn.annualConditioningCostsCalculation &&
      toReturn.annualConditioningCostsCalculation == 1
    ) {
      if (
        toReturn.annualConditioningConsume &&
        fancadeTechnicalProject.airConditioningDevicesNumber &&
        toReturn.energeticConditioningVectorUnitPrice
      )
        toReturn.annualConditioningCost =
          +toReturn.annualConditioningConsume *
          +fancadeTechnicalProject.airConditioningDevicesNumber *
          +toReturn.energeticConditioningVectorUnitPrice;
      else toReturn.annualConditioningCost = 0;
    }

    //Annual thermic solar cost
    if (
      toReturn.annualThermicSolarCostsCalculation &&
      toReturn.annualThermicSolarCostsCalculation == 1
    ) {
      if (
        toReturn.annualThermicSolarConsume &&
        fancadeTechnicalProject.thermicSolarPanelsNumber &&
        toReturn.energeticThermicSolarVectorUnitPrice
      )
        toReturn.annualThermicSolarCost =
          +toReturn.annualThermicSolarConsume *
          +fancadeTechnicalProject.thermicSolarPanelsNumber *
          +toReturn.energeticThermicSolarVectorUnitPrice;
      else toReturn.annualThermicSolarCost = 0;
    }

    //Annual photovoltaic cost
    if (
      toReturn.annualPhotovoltaicCostsCalculation &&
      toReturn.annualPhotovoltaicCostsCalculation == 1
    ) {
      if (
        toReturn.annualPhotovoltaicConsume &&
        fancadeTechnicalProject.photovoltaicModulesNumber &&
        toReturn.energeticPhotovoltaicVectorUnitPrice
      )
        toReturn.annualPhotovoltaicCost =
          +toReturn.annualPhotovoltaicConsume *
          +fancadeTechnicalProject.photovoltaicModulesNumber *
          +toReturn.energeticPhotovoltaicVectorUnitPrice;
      else toReturn.annualPhotovoltaicCost = 0;
    }
  }

  //Other implants
  if (
    toReturn.annualOtherImplantsCostCalculation &&
    toReturn.annualOtherImplantsCostCalculation == 1
  ) {
    toReturn.annualOtherImplantsCost =
      calculateServiceOfLifeOtherImplantsTotal(fancade);
  }

  return toReturn;
};

export const getMaintenance = (
  project: Project,
  fancadeIndex: number
): MaintenanceType | undefined => {
  if (!project.fancades || project.fancades.length < fancadeIndex + 1) {
    return undefined;
  }
  const fancade = project.fancades[fancadeIndex];
  let toReturn: MaintenanceType = {
    ...fancade.maintenance,
  };

  //Maintenance
  toReturn.totalMaintenancePrice = calculateMaintenanceTotal(fancade);

  //Ordinary Maintenance
  toReturn.totalOrdinaryMaintenancePrice =
    calculateOrdinaryMaintenance(fancade);
  if (
    fancade.fancadeTechnicalProject.type &&
    fancade.fancadeTechnicalProject.type.toLowerCase().indexOf("opaca") != -1
  ) {
    //Windows
    if (
      !toReturn.totalWindowReplacementPriceCalculation ||
      toReturn.totalWindowReplacementPriceCalculation == 0
    ) {
      if (
        toReturn.totalWindowReplacementPrice &&
        toReturn.windowEveryHowManyYears
      )
        toReturn.totalWindowReplacementPrice = calculateMaintenancePeriodCost(
          toReturn.windowPeriodCalculation,
          toReturn.windowEveryHowManyYears,
          undefined,
          undefined,
          +toReturn.totalWindowReplacementPrice
        );
      else toReturn.totalWindowReplacementPrice = 0;
    } else if (
      toReturn.totalWindowReplacementPriceCalculation &&
      toReturn.totalWindowReplacementPriceCalculation == 1
    ) {
      if (
        toReturn.windowUnitPrice &&
        toReturn.windowsNumber &&
        toReturn.windowEveryHowManyYears
      )
        toReturn.totalWindowReplacementPrice = calculateMaintenancePeriodCost(
          toReturn.windowPeriodCalculation,
          toReturn.windowEveryHowManyYears,
          +toReturn.windowUnitPrice,
          +toReturn.windowsNumber,
          undefined
        );
      else toReturn.totalWindowReplacementPrice = 0;
    }

    //Loom or fixings
    if (
      !toReturn.totalOpaqueLoomFixingsReplacementPriceCalculation ||
      toReturn.totalOpaqueLoomFixingsReplacementPriceCalculation == 0
    ) {
      if (
        toReturn.totalOpaqueLoomFixingsReplacementPrice &&
        toReturn.opaqueLoomFixingsEveryHowManyYears
      )
        toReturn.totalOpaqueLoomFixingsReplacementPrice =
          +calculateMaintenancePeriodCost(
            toReturn.opaqueLoomFixingsPeriodCalculation,
            toReturn.opaqueLoomFixingsEveryHowManyYears,
            undefined,
            undefined,
            toReturn.totalOpaqueLoomFixingsReplacementPrice
          );
    } else {
      if (
        toReturn.opaqueLoomFixingsQuantity &&
        toReturn.opaqueLoomFixingsUnitPrice &&
        toReturn.opaqueLoomFixingsEveryHowManyYears
      )
        toReturn.totalOpaqueLoomFixingsReplacementPrice =
          +calculateMaintenancePeriodCost(
            toReturn.opaqueLoomFixingsPeriodCalculation,
            toReturn.opaqueLoomFixingsEveryHowManyYears,
            +toReturn.opaqueLoomFixingsUnitPrice,
            +toReturn.opaqueLoomFixingsQuantity,
            undefined
          );
    }

    //Opaque Double Glazing
    if (
      !toReturn.totalOpaqueDoubleGlazingReplacementPriceCalculation ||
      toReturn.totalOpaqueDoubleGlazingReplacementPriceCalculation == 0
    ) {
      if (
        toReturn.totalOpaqueDoubleGlazingReplacementPrice &&
        toReturn.opaqueDoubleGlazingEveryHowManyYears
      )
        toReturn.totalOpaqueDoubleGlazingReplacementPrice =
          +calculateMaintenancePeriodCost(
            toReturn.opaqueDoubleGlazingPeriodCalculation,
            toReturn.opaqueDoubleGlazingEveryHowManyYears,
            undefined,
            undefined,
            toReturn.totalOpaqueDoubleGlazingReplacementPrice
          );
    } else {
      if (
        toReturn.opaqueDoubleGlazingQuantity &&
        toReturn.opaqueDoubleGlazingUnitPrice &&
        toReturn.opaqueDoubleGlazingEveryHowManyYears
      )
        toReturn.totalOpaqueDoubleGlazingReplacementPrice =
          +calculateMaintenancePeriodCost(
            toReturn.opaqueDoubleGlazingPeriodCalculation,
            toReturn.opaqueDoubleGlazingEveryHowManyYears,
            +toReturn.opaqueDoubleGlazingUnitPrice,
            +toReturn.opaqueDoubleGlazingQuantity,
            undefined
          );
    }

    //Opaque shieldings
    if (
      !toReturn.totalOpaqueShieldingsReplacementPriceCalculation ||
      toReturn.totalOpaqueShieldingsReplacementPriceCalculation == 0
    ) {
      if (
        toReturn.totalOpaqueShieldingsReplacementPrice &&
        toReturn.opaqueShieldingEveryHowManyYears
      )
        toReturn.totalOpaqueShieldingsReplacementPrice =
          +calculateMaintenancePeriodCost(
            toReturn.opaqueShieldingPeriodCalculation,
            toReturn.opaqueShieldingEveryHowManyYears,
            undefined,
            undefined,
            toReturn.totalOpaqueShieldingsReplacementPrice
          );
    } else {
      if (
        toReturn.opaqueShieldingsQuantity &&
        toReturn.opaqueShieldingsUnitPrice &&
        toReturn.opaqueShieldingEveryHowManyYears
      )
        toReturn.totalOpaqueShieldingsReplacementPrice =
          +calculateMaintenancePeriodCost(
            toReturn.opaqueShieldingPeriodCalculation,
            toReturn.opaqueShieldingEveryHowManyYears,
            +toReturn.opaqueShieldingsUnitPrice,
            +toReturn.opaqueShieldingsQuantity,
            undefined
          );
    }

    //External cladding
    if (
      !toReturn.totalExternalCladdingReplacementPriceCalculation ||
      toReturn.totalExternalCladdingReplacementPriceCalculation == 0
    ) {
      if (
        toReturn.totalExternalCladdingReplacementPrice &&
        toReturn.externalCladdingEveryHowManyYears
      )
        toReturn.totalExternalCladdingReplacementPrice =
          +calculateMaintenancePeriodCost(
            toReturn.externalCladdingPeriodCalculation,
            toReturn.externalCladdingEveryHowManyYears,
            undefined,
            undefined,
            toReturn.totalExternalCladdingReplacementPrice
          );
    } else {
      if (
        toReturn.externalCladdingQuantity &&
        toReturn.externalCladdingUnitPrice &&
        toReturn.externalCladdingEveryHowManyYears
      )
        toReturn.totalExternalCladdingReplacementPrice =
          +calculateMaintenancePeriodCost(
            toReturn.externalCladdingPeriodCalculation,
            toReturn.externalCladdingEveryHowManyYears,
            +toReturn.externalCladdingUnitPrice,
            +toReturn.externalCladdingQuantity,
            undefined
          );
    }

    //Insulation
    if (
      !toReturn.totalInsulationReplacementPriceCalculation ||
      toReturn.totalInsulationReplacementPriceCalculation == 0
    ) {
      if (
        toReturn.totalInsulationReplacementPrice &&
        toReturn.insulationEveryHowManyYears
      )
        toReturn.totalInsulationReplacementPrice =
          +calculateMaintenancePeriodCost(
            toReturn.insulationPeriodCalculation,
            toReturn.insulationEveryHowManyYears,
            undefined,
            undefined,
            toReturn.totalInsulationReplacementPrice
          );
    } else {
      if (
        toReturn.insulationSurface &&
        toReturn.insulationUnitPrice &&
        toReturn.insulationEveryHowManyYears
      )
        toReturn.totalInsulationReplacementPrice =
          +calculateMaintenancePeriodCost(
            toReturn.insulationPeriodCalculation,
            toReturn.insulationEveryHowManyYears,
            +toReturn.insulationUnitPrice,
            +toReturn.insulationSurface,
            undefined
          );
    }

    //Supporting elements
    if (
      !toReturn.totalSupportingElementsReplacementPriceCalculation ||
      toReturn.totalSupportingElementsReplacementPriceCalculation == 0
    ) {
      if (
        toReturn.totalSupportingElementsReplacementPrice &&
        toReturn.supportingElementsEveryHowManyYears
      )
        toReturn.totalSupportingElementsReplacementPrice =
          +calculateMaintenancePeriodCost(
            toReturn.supportingElementsPeriodCalculation,
            toReturn.supportingElementsEveryHowManyYears,
            undefined,
            undefined,
            toReturn.totalSupportingElementsReplacementPrice
          );
    } else {
      if (
        toReturn.supportingElementsQuantity &&
        toReturn.supportingElementUnitPrice &&
        toReturn.supportingElementsEveryHowManyYears
      )
        toReturn.totalSupportingElementsReplacementPrice =
          +calculateMaintenancePeriodCost(
            toReturn.supportingElementsPeriodCalculation,
            toReturn.supportingElementsEveryHowManyYears,
            +toReturn.supportingElementUnitPrice,
            +toReturn.supportingElementsQuantity,
            undefined
          );
    }

    //Prefabricated modules
    if (
      !toReturn.totalPrefabricatedModulesReplacementPriceCalculation ||
      toReturn.totalPrefabricatedModulesReplacementPriceCalculation == 0
    ) {
      if (
        toReturn.totalPrefabricatedModulesReplacementPrice &&
        toReturn.prefabricatedModulesEveryHowManyYears
      )
        toReturn.totalPrefabricatedModulesReplacementPrice =
          +calculateMaintenancePeriodCost(
            toReturn.prefabricatedModulesPeriodCalculation,
            toReturn.prefabricatedModulesEveryHowManyYears,
            undefined,
            undefined,
            toReturn.totalPrefabricatedModulesReplacementPrice
          );
    } else {
      if (
        toReturn.prefabricatedModulesQuantity &&
        toReturn.prefabricatedModulesUnitPrice &&
        toReturn.prefabricatedModulesEveryHowManyYears
      )
        toReturn.totalPrefabricatedModulesReplacementPrice =
          +calculateMaintenancePeriodCost(
            toReturn.prefabricatedModulesPeriodCalculation,
            toReturn.prefabricatedModulesEveryHowManyYears,
            +toReturn.prefabricatedModulesUnitPrice,
            +toReturn.prefabricatedModulesQuantity,
            undefined
          );
    }
  } else if (
    fancade.fancadeTechnicalProject.type &&
    fancade.fancadeTechnicalProject.type.toLowerCase().indexOf("trasparente") !=
      -1
  ) {
    //Glazed Cells
    if (
      !toReturn.totalGlazedCellsReplacementPriceCalculation ||
      toReturn.totalGlazedCellsReplacementPriceCalculation == 0
    ) {
      if (
        toReturn.totalGlazedCellsReplacementPrice &&
        toReturn.glazedCellsEveryHowManyYears
      )
        toReturn.totalGlazedCellsReplacementPrice =
          +calculateMaintenancePeriodCost(
            toReturn.glazedCellsPeriodCalculation,
            toReturn.glazedCellsEveryHowManyYears,
            undefined,
            undefined,
            toReturn.totalGlazedCellsReplacementPrice
          );
    } else {
      if (
        toReturn.glazedCellsNumber &&
        toReturn.glazedCellUnitPrice &&
        toReturn.glazedCellsEveryHowManyYears
      )
        toReturn.totalGlazedCellsReplacementPrice =
          +calculateMaintenancePeriodCost(
            toReturn.glazedCellsPeriodCalculation,
            toReturn.glazedCellsEveryHowManyYears,
            +toReturn.glazedCellUnitPrice,
            +toReturn.glazedCellsNumber,
            undefined
          );
    }

    //Double Glazing
    if (
      !toReturn.totalDoubleGlazingReplacementPriceCalculation ||
      toReturn.totalDoubleGlazingReplacementPriceCalculation == 0
    ) {
      if (
        toReturn.totalDoubleGlazingReplacementPrice &&
        toReturn.doubleGlazingEveryHowManyYears
      )
        toReturn.totalDoubleGlazingReplacementPrice =
          +calculateMaintenancePeriodCost(
            toReturn.doubleGlazingPeriodCalculation,
            toReturn.doubleGlazingEveryHowManyYears,
            undefined,
            undefined,
            toReturn.totalDoubleGlazingReplacementPrice
          );
    } else {
      if (
        toReturn.doubleGlazingQuantity &&
        toReturn.doubleGlazingUnitPrice &&
        toReturn.doubleGlazingEveryHowManyYears
      )
        toReturn.totalDoubleGlazingReplacementPrice =
          +calculateMaintenancePeriodCost(
            toReturn.doubleGlazingPeriodCalculation,
            toReturn.doubleGlazingEveryHowManyYears,
            +toReturn.doubleGlazingUnitPrice,
            +toReturn.doubleGlazingQuantity,
            undefined
          );
    }

    //Loom or Fixings
    if (
      !toReturn.totalTransparentLoomFixingsReplacementPriceCalculation ||
      toReturn.totalTransparentLoomFixingsReplacementPriceCalculation == 0
    ) {
      if (
        toReturn.totalTransparentLoomFixingsReplacementPrice &&
        toReturn.transparentLoomFixingsEveryHowManyYears
      )
        toReturn.totalTransparentLoomFixingsReplacementPrice =
          +calculateMaintenancePeriodCost(
            toReturn.transparentLoomPeriodCalculation,
            toReturn.transparentLoomFixingsEveryHowManyYears,
            undefined,
            undefined,
            toReturn.totalTransparentLoomFixingsReplacementPrice
          );
    } else {
      if (
        toReturn.transparentLoomFixingsQuantity &&
        toReturn.transparentLoomFixingsUnitPrice &&
        toReturn.transparentLoomFixingsEveryHowManyYears
      )
        toReturn.totalTransparentLoomFixingsReplacementPrice =
          +calculateMaintenancePeriodCost(
            toReturn.transparentLoomPeriodCalculation,
            toReturn.transparentLoomFixingsEveryHowManyYears,
            +toReturn.transparentLoomFixingsUnitPrice,
            +toReturn.transparentLoomFixingsQuantity,
            undefined
          );
    }

    //Shieldings
    if (
      !toReturn.totalTransparentShieldingsReplacementPriceCalculation ||
      toReturn.totalTransparentShieldingsReplacementPriceCalculation == 0
    ) {
      if (
        toReturn.totalTransparentShieldingsReplacementPrice &&
        toReturn.transparentShieldingsEveryHowManyYears
      )
        toReturn.totalTransparentShieldingsReplacementPrice =
          +calculateMaintenancePeriodCost(
            toReturn.transparentShieldingsPeriodCalculation,
            toReturn.transparentShieldingsEveryHowManyYears,
            undefined,
            undefined,
            toReturn.totalTransparentShieldingsReplacementPrice
          );
    } else {
      if (
        toReturn.transparentShieldingsQuantity &&
        toReturn.transparentShieldingsUnitPrice &&
        toReturn.transparentShieldingsEveryHowManyYears
      )
        toReturn.totalTransparentShieldingsReplacementPrice =
          +calculateMaintenancePeriodCost(
            toReturn.transparentShieldingsPeriodCalculation,
            toReturn.transparentShieldingsEveryHowManyYears,
            +toReturn.transparentShieldingsUnitPrice,
            +toReturn.transparentShieldingsQuantity,
            undefined
          );
    }
  }

  //System integrations
  //Photovoltaic
  if (
    !toReturn.totalPhotovoltaicModulesReplacementPriceCalculation ||
    toReturn.totalPhotovoltaicModulesReplacementPriceCalculation == 0
  ) {
    if (
      toReturn.totalPhotovoltaicModulesReplacementPrice &&
      toReturn.photovoltaicModulesEveryHowManyYears
    )
      toReturn.totalPhotovoltaicModulesReplacementPrice =
        +calculateMaintenancePeriodCost(
          toReturn.photovoltaicModulesPeriodCalculation,
          toReturn.photovoltaicModulesEveryHowManyYears,
          undefined,
          undefined,
          toReturn.totalPhotovoltaicModulesReplacementPrice
        );
  } else {
    if (
      toReturn.photovoltaicModulesNumber &&
      toReturn.photovoltaicModuleUnitPrice &&
      toReturn.photovoltaicModulesEveryHowManyYears
    )
      toReturn.totalPhotovoltaicModulesReplacementPrice =
        +calculateMaintenancePeriodCost(
          toReturn.photovoltaicModulesPeriodCalculation,
          toReturn.photovoltaicModulesEveryHowManyYears,
          +toReturn.photovoltaicModuleUnitPrice,
          +toReturn.photovoltaicModulesNumber,
          undefined
        );
  }

  //Solar Panels
  if (
    !toReturn.totalSolarPanelsReplacementPriceCalculation ||
    toReturn.totalSolarPanelsReplacementPriceCalculation == 0
  ) {
    if (
      toReturn.totalSolarPanelsReplacementPrice &&
      toReturn.solarPanelsEveryHowManyYears
    )
      toReturn.totalSolarPanelsReplacementPrice =
        +calculateMaintenancePeriodCost(
          toReturn.solarPanelsPeriodCalculation,
          toReturn.solarPanelsEveryHowManyYears,
          undefined,
          undefined,
          toReturn.totalSolarPanelsReplacementPrice
        );
  } else {
    if (
      toReturn.solarPanelsNumber &&
      toReturn.solarPanelUnitPrice &&
      toReturn.solarPanelsEveryHowManyYears
    )
      toReturn.totalSolarPanelsReplacementPrice =
        +calculateMaintenancePeriodCost(
          toReturn.solarPanelsPeriodCalculation,
          toReturn.solarPanelsEveryHowManyYears,
          +toReturn.solarPanelUnitPrice,
          +toReturn.solarPanelsNumber,
          undefined
        );
  }

  //Ventilation
  if (
    !toReturn.totalVentilationDevicesReplacementPriceCalculation ||
    toReturn.totalVentilationDevicesReplacementPriceCalculation == 0
  ) {
    if (
      toReturn.totalVentilationDevicesReplacementPrice &&
      toReturn.ventilationDevicesEveryHowManyYears
    )
      toReturn.totalVentilationDevicesReplacementPrice =
        +calculateMaintenancePeriodCost(
          toReturn.ventilationDevicesPeriodCalculation,
          toReturn.ventilationDevicesEveryHowManyYears,
          undefined,
          undefined,
          toReturn.totalVentilationDevicesReplacementPrice
        );
  } else {
    if (
      toReturn.ventilationDevicesNumber &&
      toReturn.ventilationDeviceUnitPrice &&
      toReturn.ventilationDevicesEveryHowManyYears
    )
      toReturn.totalVentilationDevicesReplacementPrice =
        +calculateMaintenancePeriodCost(
          toReturn.ventilationDevicesPeriodCalculation,
          toReturn.ventilationDevicesEveryHowManyYears,
          +toReturn.ventilationDeviceUnitPrice,
          +toReturn.ventilationDevicesNumber,
          undefined
        );
  }

  //Air Conditioning
  if (
    !toReturn.totalAirConditioningDevicesReplacementPriceCalculation ||
    toReturn.totalAirConditioningDevicesReplacementPriceCalculation == 0
  ) {
    if (
      toReturn.totalAirConditioningDevicesReplacementPrice &&
      toReturn.airConditioningDevicesEveryHowManyYears
    )
      toReturn.totalAirConditioningDevicesReplacementPrice =
        +calculateMaintenancePeriodCost(
          toReturn.airConditioningPeriodCalculation,
          toReturn.airConditioningDevicesEveryHowManyYears,
          undefined,
          undefined,
          toReturn.totalAirConditioningDevicesReplacementPrice
        );
  } else {
    if (
      toReturn.airConditioningDevicesNumber &&
      toReturn.airConditioningDeviceUnitPrice &&
      toReturn.airConditioningDevicesEveryHowManyYears
    )
      toReturn.totalAirConditioningDevicesReplacementPrice =
        +calculateMaintenancePeriodCost(
          toReturn.airConditioningPeriodCalculation,
          toReturn.airConditioningDevicesEveryHowManyYears,
          +toReturn.airConditioningDeviceUnitPrice,
          +toReturn.airConditioningDevicesNumber,
          undefined
        );
  }

  //Cleaning
  if (
    toReturn.totalCleaningPriceCalculation &&
    toReturn.totalCleaningPriceCalculation == 1
  ) {
    if (toReturn.cleaningUnitPrice && toReturn.cleaningsEveryHowManyYears)
      toReturn.totalCleaningPrice = +calculateMaintenancePeriodCost(
        toReturn.cleaningsPeriodCalculation,
        toReturn.cleaningsEveryHowManyYears,
        undefined,
        undefined,
        toReturn.cleaningUnitPrice
      );
    else toReturn.totalCleaningPrice = 0;
  }
  //Extraordinary maintenance
  if (
    toReturn.totalExtraordinaryMaintenancePriceCalculation &&
    toReturn.totalExtraordinaryMaintenancePriceCalculation == 1
  ) {
    toReturn.totalExtraordinaryMaintenancePrice =
      calculateExtraordinaryMaintenance(fancade);
  }

  return toReturn;
};

export const getEndOfLife = (
  project: Project,
  fancadeIndex: number
): EndOfLifeType | undefined => {
  if (!project.fancades || project.fancades.length < fancadeIndex + 1) {
    return undefined;
  }
  const fancade = project.fancades[fancadeIndex];
  let toReturn: EndOfLifeType = {
    ...fancade.endOfLife,
  };

  //Demolition and disassembly
  if (
    toReturn.totalDemolitionAndDisassemblyPriceCalculation &&
    toReturn.totalDemolitionAndDisassemblyPriceCalculation == 1
  ) {
    toReturn.totalDemolitionAndDisassemblyPrice =
      calculateDemolitionAndDisassemblyTotal(fancade);
  }

  //---------------Demolition---------------
  //Demolition
  if (
    toReturn.totalDemolitionPriceCalculation &&
    toReturn.totalDemolitionPriceCalculation == 1
  ) {
    if (toReturn.demolitionUnitPrice && toReturn.demolitionQuantity)
      toReturn.totalDemolitionPrice =
        +toReturn.demolitionUnitPrice * +toReturn.demolitionQuantity;
    else toReturn.totalDemolitionPrice = 0;
  }

  //---------------Disassembly---------------
  //Disassembly
  if (
    toReturn.totalDisassemblyPriceCalculation &&
    toReturn.totalDisassemblyPriceCalculation == 1
  ) {
    toReturn.totalDisassemblyPrice = calculateDisassemblyTotal(fancade);
  }

  if (
    fancade.fancadeTechnicalProject.type &&
    fancade.fancadeTechnicalProject.type.toLowerCase().indexOf("opaca") != -1
  ) {
    //Windows
    if (
      toReturn.totalWindowsDisassemblyPriceCalculation &&
      toReturn.totalWindowsDisassemblyPriceCalculation == 1
    ) {
      if (toReturn.windowsUnitPrice && toReturn.windowsQuantity)
        toReturn.totalWindowsDisassemblyPrice =
          +toReturn.windowsUnitPrice * +toReturn.windowsQuantity;
      else toReturn.totalWindowsDisassemblyPrice = 0;
    }

    //External cladding
    if (
      toReturn.totalExternalCladdingDisassemblyPriceCalculation &&
      toReturn.totalExternalCladdingDisassemblyPriceCalculation == 1
    ) {
      if (
        toReturn.externalCladdingUnitPrice &&
        toReturn.externalCladdingQuantity
      )
        toReturn.totalExternalCladdingDisassemblyPrice =
          +toReturn.externalCladdingUnitPrice *
          +toReturn.externalCladdingQuantity;
      else toReturn.totalExternalCladdingDisassemblyPrice = 0;
    }

    //Insulation
    if (
      toReturn.totalInsulationDisassemblyPriceCalculation &&
      toReturn.totalInsulationDisassemblyPriceCalculation == 1
    ) {
      if (toReturn.insulationUnitPrice && toReturn.insulationQuantity)
        toReturn.totalInsulationDisassemblyPrice =
          +toReturn.insulationUnitPrice * +toReturn.insulationQuantity;
      else toReturn.totalInsulationDisassemblyPrice = 0;
    }

    //Supporting elements and fixings
    if (
      toReturn.totalSupportingElementsAndFixingsDisassemblyPriceCalculation &&
      toReturn.totalSupportingElementsAndFixingsDisassemblyPriceCalculation == 1
    ) {
      if (
        toReturn.supportingElementsAndFixingsUnitPrice &&
        toReturn.supportingElementsAndFixingsQuantity
      )
        toReturn.totalSupportingElementsAndFixingsDisassemblyPrice =
          +toReturn.supportingElementsAndFixingsUnitPrice *
          +toReturn.supportingElementsAndFixingsQuantity;
      else toReturn.totalSupportingElementsAndFixingsDisassemblyPrice = 0;
    }
  } else if (
    fancade.fancadeTechnicalProject.type &&
    fancade.fancadeTechnicalProject.type.toLowerCase().indexOf("trasparente") !=
      -1
  ) {
    //Glazed cells
    if (
      toReturn.totalGlazedCellsDisassemblyPriceCalculation &&
      toReturn.totalGlazedCellsDisassemblyPriceCalculation == 1
    ) {
      if (toReturn.glazedCellsUnitPrice && toReturn.glazedCellsQuantity)
        toReturn.totalGlazedCellsDisassemblyPrice =
          +toReturn.glazedCellsUnitPrice * +toReturn.glazedCellsQuantity;
      else toReturn.totalGlazedCellsDisassemblyPrice = 0;
    }

    //Double glazing
    if (
      toReturn.totalDoubleGlazingDisassemblyPriceCalculation &&
      toReturn.totalDoubleGlazingDisassemblyPriceCalculation == 1
    ) {
      if (toReturn.doubleGlazingUnitPrice && toReturn.doubleGlazingQuantity)
        toReturn.totalDoubleGlazingDisassemblyPrice =
          +toReturn.doubleGlazingUnitPrice * +toReturn.doubleGlazingQuantity;
      else toReturn.totalDoubleGlazingDisassemblyPrice = 0;
    }

    //Uprights, crosspiecies and fixings
    if (
      toReturn.totalUprightsCrosspiecesFixingsDisassemblyPriceCalculation &&
      toReturn.totalUprightsCrosspiecesFixingsDisassemblyPriceCalculation == 1
    ) {
      if (
        toReturn.uprightsCrosspiecesFixingsUnitPrice &&
        toReturn.uprightsCrosspiecesFixingsQuantity
      )
        toReturn.totalUprightsCrosspiecesFixingsDisassemblyPrice =
          +toReturn.uprightsCrosspiecesFixingsUnitPrice *
          +toReturn.uprightsCrosspiecesFixingsQuantity;
      else toReturn.totalUprightsCrosspiecesFixingsDisassemblyPrice = 0;
    }
  }

  //---------------Transport---------------
  //Transport
  if (
    toReturn.totalTransportPriceCalculation &&
    toReturn.totalTransportPriceCalculation == 1
  ) {
    toReturn.totalTransportPrice = calculateEndOfLifeTransportTotal(fancade);
  }

  //Disposal
  if (
    toReturn.totalDisposalTransportPriceCalculation &&
    toReturn.totalDisposalTransportPriceCalculation == 1
  ) {
    if (
      toReturn.disposalTransportUnitPrice &&
      toReturn.disposalTransportQuantity
    )
      toReturn.totalDisposalTransportPrice =
        +toReturn.disposalTransportUnitPrice *
        +toReturn.disposalTransportQuantity;
    else toReturn.totalDisposalTransportPrice = 0;
  }

  //Recycling
  if (
    toReturn.totalRecyclingTransportPriceCalculation &&
    toReturn.totalRecyclingTransportPriceCalculation == 1
  ) {
    if (
      toReturn.recyclingTransportUnitPrice &&
      toReturn.recyclingTransportQuantity
    )
      toReturn.totalRecyclingTransportPrice =
        +toReturn.recyclingTransportUnitPrice *
        +toReturn.recyclingTransportQuantity;
    else toReturn.totalRecyclingTransportPrice = 0;
  }

  //---------------Recycling---------------
  //Recycling
  if (
    toReturn.totalRecyclingRevenueCalculation &&
    toReturn.totalRecyclingRevenueCalculation == 1
  ) {
    toReturn.totalRecyclingRevenue = calculateRecyclingTotalRevenue(fancade);
  }

  if (
    fancade.fancadeTechnicalProject.type &&
    fancade.fancadeTechnicalProject.type.toLowerCase().indexOf("opaca") != -1
  ) {
    //Windows
    if (
      toReturn.totalWindowsRecyclingRevenueCalculation &&
      toReturn.totalWindowsRecyclingRevenueCalculation == 1
    ) {
      if (
        toReturn.windowsRecyclingUnitPrice &&
        toReturn.windowsRecyclingQuantity
      )
        toReturn.totalWindowsRecyclingRevenue =
          +toReturn.windowsRecyclingUnitPrice *
          +toReturn.windowsRecyclingQuantity;
      else toReturn.totalWindowsRecyclingRevenue = 0;
    }

    //External cladding
    if (
      toReturn.totalExternalCladdingRevenueCalculation &&
      toReturn.totalExternalCladdingRevenueCalculation == 1
    ) {
      if (
        toReturn.externalCladdingRecyclingUnitPrice &&
        toReturn.externalCladdingRecyclingQuantity
      )
        toReturn.totalExternalCladdingRecyclingRevenue =
          +toReturn.externalCladdingRecyclingUnitPrice *
          +toReturn.externalCladdingRecyclingQuantity;
      else toReturn.totalExternalCladdingRecyclingRevenue = 0;
    }

    //Insulation
    if (
      toReturn.totalInsulationRevenueCalculation &&
      toReturn.totalInsulationRevenueCalculation == 1
    ) {
      if (
        toReturn.insulationRecyclingUnitPrice &&
        toReturn.insulationRecyclingQuantity
      )
        toReturn.totalInsulationRecyclingRevenue =
          +toReturn.insulationRecyclingUnitPrice *
          +toReturn.insulationRecyclingQuantity;
      else toReturn.totalInsulationRecyclingRevenue = 0;
    }

    //Supporting elements and fixings
    if (
      toReturn.totalSupportingElementsAndFixingsRecyclingRevenueCalculation &&
      toReturn.totalSupportingElementsAndFixingsRecyclingRevenueCalculation == 1
    ) {
      if (
        toReturn.supportingElementsAndFixingsRecyclingUnitPrice &&
        toReturn.supportingElementsAndFixingsRecyclingQuantity
      )
        toReturn.totalSupportingElementsAndFixingsRecyclingRevenue =
          +toReturn.supportingElementsAndFixingsRecyclingUnitPrice *
          +toReturn.supportingElementsAndFixingsRecyclingQuantity;
      else toReturn.totalSupportingElementsAndFixingsRecyclingRevenue = 0;
    }
  } else if (
    fancade.fancadeTechnicalProject.type &&
    fancade.fancadeTechnicalProject.type.toLowerCase().indexOf("trasparente") !=
      -1
  ) {
    //Glazed cells
    if (
      toReturn.totalGlazedCellsRecyclingRevenueCalculation &&
      toReturn.totalGlazedCellsRecyclingRevenueCalculation == 1
    ) {
      if (
        toReturn.glazedCellsUnitRecyclingPrice &&
        toReturn.glazedCellsRecyclingQuantity
      )
        toReturn.totalGlazedCellsRecyclingRevenue =
          +toReturn.glazedCellsUnitRecyclingPrice *
          +toReturn.glazedCellsRecyclingQuantity;
      else toReturn.totalGlazedCellsRecyclingRevenue = 0;
    }

    //Double glazing
    if (
      toReturn.totalDoubleGlazingRecyclingRevenueCalculation &&
      toReturn.totalDoubleGlazingRecyclingRevenueCalculation == 1
    ) {
      if (
        toReturn.doubleGlazingRecyclingUnitPrice &&
        toReturn.doubleGlazingRecyclingQuantity
      )
        toReturn.totalDoubleGlazingRecyclingRevenue =
          +toReturn.doubleGlazingRecyclingUnitPrice *
          +toReturn.doubleGlazingRecyclingQuantity;
      else toReturn.totalDoubleGlazingRecyclingRevenue = 0;
    }

    //Uprights, crosspiecies and fixings
    if (
      toReturn.totalUprightsCrosspiecesFixingsRecyclingRevenueCalculation &&
      toReturn.totalUprightsCrosspiecesFixingsRecyclingRevenueCalculation == 1
    ) {
      if (
        toReturn.uprightsCrosspiecesFixingsRecyclingUnitPrice &&
        toReturn.uprightsCrosspiecesFixingsRecyclingQuantity
      )
        toReturn.totalUprightsCrosspiecesFixingsRecyclingRevenue =
          +toReturn.uprightsCrosspiecesFixingsRecyclingUnitPrice *
          +toReturn.uprightsCrosspiecesFixingsRecyclingQuantity;
      else toReturn.totalUprightsCrosspiecesFixingsRecyclingRevenue = 0;
    }
  }
  //---------------ReUse---------------
  //ReUse
  if (
    toReturn.totalReUseRevenueCalculation &&
    toReturn.totalReUseRevenueCalculation == 1
  ) {
    toReturn.totalReUseRevenue = calculateReUseTotalRevenue(fancade);
  }

  if (
    fancade.fancadeTechnicalProject.type &&
    fancade.fancadeTechnicalProject.type.toLowerCase().indexOf("opaca") != -1
  ) {
    //Windows
    if (
      toReturn.totalWindowsReUseRevenueCalculation &&
      toReturn.totalWindowsReUseRevenueCalculation == 1
    ) {
      if (toReturn.windowsReUseUnitPrice && toReturn.windowsReUseQuantity)
        toReturn.totalWindowsReUseRevenue =
          +toReturn.windowsReUseUnitPrice * +toReturn.windowsReUseQuantity;
      else toReturn.totalWindowsReUseRevenue = 0;
    }

    //External cladding
    if (
      toReturn.totalExternalCladdingReUseRevenueCalculation &&
      toReturn.totalExternalCladdingReUseRevenueCalculation == 1
    ) {
      if (
        toReturn.externalCladdingReUseUnitPrice &&
        toReturn.externalCladdingReUseQuantity
      )
        toReturn.totalExternalCladdingReUseRevenue =
          +toReturn.externalCladdingReUseUnitPrice *
          +toReturn.externalCladdingReUseQuantity;
      else toReturn.totalExternalCladdingReUseRevenue = 0;
    }

    //Insulation
    if (
      toReturn.totalInsulationReUseRevenueCalculation &&
      toReturn.totalInsulationReUseRevenueCalculation == 1
    ) {
      if (toReturn.insulationReUseUnitPrice && toReturn.insulationReUseQuantity)
        toReturn.totalInsulationReUseRevenue =
          +toReturn.insulationReUseUnitPrice *
          +toReturn.insulationReUseQuantity;
      else toReturn.totalInsulationReUseRevenue = 0;
    }

    //Supporting elements and fixings
    if (
      toReturn.totalSupportingElementsAndFixingsReUseRevenueCalculation &&
      toReturn.totalSupportingElementsAndFixingsReUseRevenueCalculation == 1
    ) {
      if (
        toReturn.supportingElementsAndFixingsReUseUnitPrice &&
        toReturn.supportingElementsAndFixingsReUseQuantity
      )
        toReturn.totalSupportingElementsAndFixingsReUseRevenue =
          +toReturn.supportingElementsAndFixingsReUseUnitPrice *
          +toReturn.supportingElementsAndFixingsReUseQuantity;
      else toReturn.totalSupportingElementsAndFixingsReUseRevenue = 0;
    }
  } else if (
    fancade.fancadeTechnicalProject.type &&
    fancade.fancadeTechnicalProject.type.toLowerCase().indexOf("trasparente") !=
      -1
  ) {
    //Glazed cells
    if (
      toReturn.totalGlazedCellsReUseRevenueCalculation &&
      toReturn.totalGlazedCellsReUseRevenueCalculation == 1
    ) {
      if (
        toReturn.glazedCellsUnitReUsePrice &&
        toReturn.glazedCellsReUseQuantity
      )
        toReturn.totalGlazedCellsReUseRevenue =
          +toReturn.glazedCellsUnitReUsePrice *
          +toReturn.glazedCellsReUseQuantity;
      else toReturn.totalGlazedCellsReUseRevenue = 0;
    }

    //Double glazing
    if (
      toReturn.totalDoubleGlazingReUseRevenueCalculation &&
      toReturn.totalDoubleGlazingReUseRevenueCalculation == 1
    ) {
      if (
        toReturn.doubleGlazingReUseUnitPrice &&
        toReturn.doubleGlazingReUseQuantity
      )
        toReturn.totalDoubleGlazingReUseRevenue =
          +toReturn.doubleGlazingReUseUnitPrice *
          +toReturn.doubleGlazingReUseQuantity;
      else toReturn.totalDoubleGlazingReUseRevenue = 0;
    }

    //Uprights, crosspiecies and fixings
    if (
      toReturn.totalUprightsCrosspiecesFixingsReUseRevenueCalculation &&
      toReturn.totalUprightsCrosspiecesFixingsReUseRevenueCalculation == 1
    ) {
      if (
        toReturn.uprightsCrosspiecesFixingsReUseUnitPrice &&
        toReturn.uprightsCrosspiecesFixingsReUseQuantity
      )
        toReturn.totalUprightsCrosspiecesFixingsReUseRevenue =
          +toReturn.uprightsCrosspiecesFixingsReUseUnitPrice *
          +toReturn.uprightsCrosspiecesFixingsReUseQuantity;
      else toReturn.totalUprightsCrosspiecesFixingsReUseRevenue = 0;
    }
  }
  return toReturn;
};

//------------------------Calculations------------------------
export const calculateTotalLoomLength = (
  loomHeight: number,
  loomWidth: number,
  doubleGlazingQuantity: number
): number => {
  if (!loomHeight || !loomWidth || !doubleGlazingQuantity) return 0;
  return (+loomHeight + +loomWidth) * 2 * +doubleGlazingQuantity;
};

export const calculateTotalMainFrameLength = (
  mainFrameHeightPerUnit: number,
  mainFrameWidthPerUnit: number,
  doubleGlazingQuantity: number
): number => {
  if (
    !mainFrameHeightPerUnit ||
    !mainFrameWidthPerUnit ||
    !doubleGlazingQuantity
  )
    return 0;
  return (
    (+mainFrameHeightPerUnit + +mainFrameWidthPerUnit) *
    2 *
    +doubleGlazingQuantity
  );
};

export const calculateWindowOpaqueSurfaceRatio = (
  fancadeSurface: number,
  windowsSurfaces: number[]
) => {
  if (!fancadeSurface || !windowsSurfaces) return 0;
  let toReturn = 0;
  windowsSurfaces.map((surface) => (toReturn += +surface));

  return (toReturn / +fancadeSurface) * 100;
};

export const calculateFancadeDesignTotalCost = (
  fancadeProject: FancadeProjectType
) => {
  let totalPrice = 0;
  if (!fancadeProject) return 0;
  if (
    !fancadeProject.totalDesignPriceCalculation ||
    fancadeProject.totalDesignPriceCalculation == 0
  ) {
    if (fancadeProject.totalDesignPrice)
      totalPrice = fancadeProject.totalDesignPrice;
  } else {
    let k: keyof typeof fancadeProject;
    let counter = 0;
    for (k in fancadeProject) {
      if (
        counter > 0 &&
        fancadeProject &&
        fancadeProject[k] &&
        k.toString() != "totalDesignPrice" &&
        k.toString() != "totalDesignPriceCalculation"
      ) {
        //@ts-ignore
        totalPrice += +fancadeProject[k];
      }
      counter++;
    }
    /*  if (fancadeProject.totalDesignPrice)
      totalPrice -= fancadeProject.totalDesignPrice;
    if (fancadeProject.totalDesignPriceCalculation)
      totalPrice -= fancadeProject.totalDesignPriceCalculation; */
  }
  if (totalPrice < 0) totalPrice = 0;
  return totalPrice;
};

//---------------Production---------------
export const calculateProductionTotal = (fancade: Fancade): number => {
  if (fancade.fancadeProduction) {
    let totalProductionPrice = fancade.fancadeProduction.totalProductionPrice;
    if (
      totalProductionPrice &&
      (!fancade?.fancadeProduction?.totalProductionPriceCalculation ||
        fancade?.fancadeProduction?.totalProductionPriceCalculation == 0)
    )
      return +totalProductionPrice;

    return +calculateMaterialsTotal(fancade) + +calculateLaborTotal(fancade);
  } else {
    return 0;
  }
};

export const calculateOpaqueWindowsTotal = (fancade: Fancade): number => {
  if (
    !fancade.fancadeProduction ||
    !fancade.fancadeProduction.totalProductionPriceCalculation ||
    fancade.fancadeProduction.totalProductionPriceCalculation == 0 ||
    (fancade &&
      fancade.fancadeTechnicalProject.type &&
      fancade.fancadeTechnicalProject.type.toLowerCase() == "finestre")
  ) {
    return 0;
  }

  if (fancade.fancadeTechnicalProject) {
    let windowsTotalPrice = fancade?.fancadeTechnicalProject?.windowsTotalPrice;
    if (
      !fancade?.fancadeTechnicalProject?.windowsTotalPriceCalculation ||
      fancade?.fancadeTechnicalProject?.windowsTotalPriceCalculation == 0
    ) {
      if (!windowsTotalPrice) return 0;
      return +windowsTotalPrice;
    } else {
      windowsTotalPrice = 0;
      fancade?.fancadeTechnicalProject?.windows?.map((window, index) => {
        //@ts-ignore
        windowsTotalPrice += calculateOpaqueWindowTotal(fancade, index);
      });

      windowsTotalPrice += +calculateHardwareTotalPrice(fancade);

      return +windowsTotalPrice;
    }
  } else {
    return 0;
  }
};

export const calculateOpaqueWindowTotal = (
  fancade: Fancade,
  windowIndex: number
): number => {
  if (
    !fancade.fancadeProduction ||
    !fancade.fancadeProduction.totalProductionPriceCalculation ||
    fancade.fancadeProduction.totalProductionPriceCalculation == 0
  ) {
    return 0;
  }
  if (fancade.fancadeTechnicalProject) {
    const window = fancade?.fancadeTechnicalProject?.windows[windowIndex];

    if (
      !fancade?.fancadeTechnicalProject?.windows[windowIndex]
        ?.totalPriceCalculation ||
      fancade?.fancadeTechnicalProject?.windows[windowIndex]
        ?.totalPriceCalculation == 0
    ) {
      if (!window?.totalPrice) return 0;
      return +window?.totalPrice;
    } else if (
      //window?.windowsNumber &&
      window?.unitPrice &&
      window?.windowsSurface &&
      window?.totalPriceCalculation &&
      window?.totalPriceCalculation == 1
    ) {
      return +window?.windowsSurface * +window?.unitPrice;
    } else if (
      window?.totalPriceCalculation &&
      window?.totalPriceCalculation == 2
    ) {
      let toReturn = 0;

      let loomComponentsPrice = window?.loomComponentsPrice;
      let doubleGlazingComponentsPrice = window?.doubleGlazingComponentsPrice;
      let gasketsComponentsPrice = window?.gasketsComponentsPrice;

      if (
        window?.loomComponentsPriceCalculation &&
        +window?.loomComponentsPriceCalculation == 1
      ) {
        //loomComponentsPrice has to be calculated
        if (
          window?.loomHeight &&
          window?.loomWidth &&
          window?.doubleGlazingNumber &&
          window.loomUnitPrice
        )
          loomComponentsPrice =
            +calculateTotalLoomLength(
              window?.loomHeight,
              window?.loomWidth,
              window?.doubleGlazingNumber
            ) * +window.loomUnitPrice;
        else loomComponentsPrice = 0;
      }
      if (loomComponentsPrice) toReturn += +loomComponentsPrice;
      if (
        window?.doubleGlazingComponentsPriceCalculation &&
        +window?.doubleGlazingComponentsPriceCalculation == 1
      ) {
        //doubleGlazingComponentsPrice has to be calculated
        if (window.doubleGlazingNumber && window.doubleGlazingUnitPrice)
          doubleGlazingComponentsPrice =
            +window.doubleGlazingNumber * +window.doubleGlazingUnitPrice;
        else doubleGlazingComponentsPrice = 0;
      }
      if (doubleGlazingComponentsPrice)
        toReturn += +doubleGlazingComponentsPrice;

      if (
        window?.gasketsComponentsPriceCalculation &&
        +window?.gasketsComponentsPriceCalculation == 1
      ) {
        //doubleGlazingComponentsPrice has to be calculated
        if (window.totalGasketLength && window.gasketsUnitPrice)
          gasketsComponentsPrice =
            +window.totalGasketLength * +window.gasketsUnitPrice;
        else gasketsComponentsPrice = 0;
      }
      if (gasketsComponentsPrice) toReturn += +gasketsComponentsPrice;
      return toReturn;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

export const calculateHardwareTotalPrice = (fancade: Fancade): number => {
  if (
    !fancade.fancadeProduction ||
    !fancade.fancadeProduction.totalProductionPriceCalculation ||
    fancade.fancadeProduction.totalProductionPriceCalculation == 0
  ) {
    return 0;
  }
  if (fancade.fancadeProduction) {
    let hardwareTotalPrice = fancade.fancadeProduction.hardwareTotalPrice;
    if (fancade.fancadeProduction.hardwareTotalPriceCalculation == 1) {
      hardwareTotalPrice = 0;
      if (
        fancade?.fancadeProduction?.hardware &&
        fancade?.fancadeProduction?.hardware?.length > 0
      ) {
        fancade.fancadeProduction.hardware.map((hardware) => {
          if (
            hardware.totalPriceCalculation &&
            hardware.totalPriceCalculation == 1
          ) {
            if (hardware.unitPrice && hardware.quantity) {
              //@ts-ignore
              hardwareTotalPrice += +hardware.unitPrice * +hardware.quantity;
            }
          } else if (hardware.totalPrice) {
            //@ts-ignore
            hardwareTotalPrice += +hardware.totalPrice;
          }
        });
      }
    } else if (!hardwareTotalPrice) return 0;
    return hardwareTotalPrice;
  } else {
    return 0;
  }
};

export const calculateTransparentWindowsTotal = (fancade: Fancade): number => {
  if (
    !fancade.fancadeProduction ||
    !fancade.fancadeProduction.totalProductionPriceCalculation ||
    fancade.fancadeProduction.totalProductionPriceCalculation == 0
  ) {
    return 0;
  }

  if (fancade.fancadeTechnicalProject) {
    let transparentTotalPrice =
      fancade?.fancadeTechnicalProject?.transparentTotalPrice;
    if (
      transparentTotalPrice &&
      (!fancade?.fancadeTechnicalProject?.transparentTotalPriceCalculation ||
        fancade?.fancadeTechnicalProject?.transparentTotalPriceCalculation == 0)
    ) {
      return +transparentTotalPrice;
    } else {
      transparentTotalPrice = 0;
      let glazedCellsUnitPrice =
        fancade?.fancadeTechnicalProject?.glazedCellsUnitPrice;
      let glazedCellsNumber =
        fancade?.fancadeTechnicalProject?.glazedCellsNumber;

      if (
        fancade?.fancadeTechnicalProject?.totalGlazedCellsPriceCalculation &&
        fancade?.fancadeTechnicalProject?.totalGlazedCellsPriceCalculation == 1
      ) {
        if (
          glazedCellsUnitPrice &&
          +glazedCellsUnitPrice != 0 &&
          glazedCellsNumber &&
          +glazedCellsNumber != 0
        )
          transparentTotalPrice += +glazedCellsUnitPrice * +glazedCellsNumber;
      } else if (
        fancade?.fancadeTechnicalProject?.totalGlazedCellsPriceCalculation &&
        fancade?.fancadeTechnicalProject?.totalGlazedCellsPriceCalculation == 2
      ) {
        let totalMainFramePrice =
          fancade?.fancadeTechnicalProject?.totalMainFramePrice;
        let totalDoubleGlazingPrice =
          fancade?.fancadeTechnicalProject?.totalDoubleGlazingPrice;
        let totalGasketPrice =
          fancade?.fancadeTechnicalProject?.totalGasketPrice;
        let totalOpaqueSpandrelModulesPrice =
          fancade?.fancadeTechnicalProject?.totalOpaqueSpandrelModulesPrice;

        let totalTransparentFixingsNumberPrice =
          fancade?.fancadeTechnicalProject?.totalTransparentFixingsNumberPrice;

        if (
          fancade?.fancadeTechnicalProject?.totalMainFramePriceCalculation &&
          fancade?.fancadeTechnicalProject?.totalMainFramePriceCalculation == 1
        ) {
          totalMainFramePrice = 0;
          if (
            fancade?.fancadeTechnicalProject?.totalMainFrameLength &&
            fancade?.fancadeTechnicalProject?.mainFrameUnitPrice
          ) {
            totalMainFramePrice =
              +fancade.fancadeTechnicalProject.totalMainFrameLength *
              +fancade.fancadeTechnicalProject.mainFrameUnitPrice;
          }
        }
        if (totalMainFramePrice) transparentTotalPrice += +totalMainFramePrice;

        if (
          fancade?.fancadeTechnicalProject
            ?.totalDoubleGlazingPriceCalculation &&
          fancade?.fancadeTechnicalProject
            ?.totalDoubleGlazingPriceCalculation == 1
        ) {
          totalDoubleGlazingPrice = 0;
          if (
            fancade?.fancadeTechnicalProject?.doubleGlazingQuantity &&
            fancade?.fancadeTechnicalProject?.doubleGlazingUnitPrice
          ) {
            totalDoubleGlazingPrice =
              +fancade.fancadeTechnicalProject.doubleGlazingQuantity *
              +fancade.fancadeTechnicalProject.doubleGlazingUnitPrice;
          }
        }
        if (totalDoubleGlazingPrice)
          transparentTotalPrice += +totalDoubleGlazingPrice;

        if (
          fancade?.fancadeTechnicalProject?.totalGasketPriceCalculation &&
          fancade?.fancadeTechnicalProject?.totalGasketPriceCalculation == 1
        ) {
          totalGasketPrice = 0;
          if (
            fancade?.fancadeTechnicalProject?.totalGasketLength &&
            fancade?.fancadeTechnicalProject?.gasketUnitPrice
          ) {
            totalGasketPrice =
              +fancade.fancadeTechnicalProject.totalGasketLength *
              +fancade.fancadeTechnicalProject.gasketUnitPrice;
          }
        }
        if (totalGasketPrice) transparentTotalPrice += +totalGasketPrice;

        if (
          fancade?.fancadeTechnicalProject
            ?.totalOpaqueSpandrelModulesPriceCalculation &&
          fancade?.fancadeTechnicalProject
            ?.totalOpaqueSpandrelModulesPriceCalculation == 1
        ) {
          totalOpaqueSpandrelModulesPrice = 0;
          if (
            fancade?.fancadeTechnicalProject?.opaqueSpandrelPanelsQuantity &&
            fancade?.fancadeTechnicalProject?.opaqueSpandrelModulesUnitPrice
          ) {
            totalOpaqueSpandrelModulesPrice =
              +fancade.fancadeTechnicalProject.opaqueSpandrelPanelsQuantity *
              +fancade.fancadeTechnicalProject.opaqueSpandrelModulesUnitPrice;
          }
        }
        if (
          totalOpaqueSpandrelModulesPrice &&
          fancade.fancadeTechnicalProject.type &&
          fancade.fancadeTechnicalProject.type.toLowerCase() !=
            "trasparente - a cellule senza pannello spandrel opaco"
        )
          transparentTotalPrice += +totalOpaqueSpandrelModulesPrice;

        if (
          fancade?.fancadeTechnicalProject
            ?.totalTransparentFixingsNumberPriceCalculation &&
          fancade?.fancadeTechnicalProject
            ?.totalTransparentFixingsNumberPriceCalculation == 1
        ) {
          totalTransparentFixingsNumberPrice = 0;
          if (
            fancade?.fancadeTechnicalProject?.transparentFixingsNumber &&
            fancade?.fancadeTechnicalProject?.transparentFixingsNumberUnitPrice
          ) {
            totalTransparentFixingsNumberPrice =
              +fancade.fancadeTechnicalProject.transparentFixingsNumber *
              +fancade.fancadeTechnicalProject
                .transparentFixingsNumberUnitPrice;
          }
        }
        if (totalTransparentFixingsNumberPrice)
          transparentTotalPrice += +totalTransparentFixingsNumberPrice;
      } else if (
        !fancade?.fancadeTechnicalProject?.totalGlazedCellsPriceCalculation ||
        fancade?.fancadeTechnicalProject?.totalGlazedCellsPriceCalculation == 1
      ) {
        if (fancade?.fancadeTechnicalProject?.totalGlazedCellsPrice)
          transparentTotalPrice +=
            +fancade.fancadeTechnicalProject.totalGlazedCellsPrice;
      }
      let totalTransparentAnchoringsPrice =
        fancade?.fancadeTechnicalProject?.totalTransparentAnchoringsPrice;

      if (
        fancade?.fancadeTechnicalProject
          ?.totalTransparentAnchoringsPriceCalculation &&
        fancade?.fancadeTechnicalProject
          ?.totalTransparentAnchoringsPriceCalculation == 1
      ) {
        totalTransparentAnchoringsPrice = 0;
        if (
          fancade?.fancadeTechnicalProject?.transparentAnchoringsNumber &&
          fancade?.fancadeTechnicalProject?.transparentAnchoringsUnitPrice
        ) {
          totalTransparentAnchoringsPrice =
            +fancade.fancadeTechnicalProject.transparentAnchoringsNumber *
            +fancade.fancadeTechnicalProject.transparentAnchoringsUnitPrice;
        }
      }
      if (totalTransparentAnchoringsPrice)
        transparentTotalPrice += +totalTransparentAnchoringsPrice;

      return +transparentTotalPrice;
    }
  } else {
    return 0;
  }
};

export const calculateSystemsIntegrationTotal = (fancade: Fancade): number => {
  if (
    !fancade.fancadeProduction ||
    !fancade.fancadeProduction.totalProductionPriceCalculation ||
    fancade.fancadeProduction.totalProductionPriceCalculation == 0
  ) {
    return 0;
  }

  if (fancade.fancadeTechnicalProject) {
    let totalSystemsIntegrationPrice =
      fancade?.fancadeTechnicalProject?.totalSystemsIntegrationPrice;
    if (
      !fancade?.fancadeTechnicalProject
        ?.totalSystemsIntegrationPriceCalculation ||
      fancade?.fancadeTechnicalProject
        ?.totalSystemsIntegrationPriceCalculation == 0
    ) {
      if (!totalSystemsIntegrationPrice) return 0;
      return +totalSystemsIntegrationPrice;
    } else {
      totalSystemsIntegrationPrice = 0;
      let totalShieldingsPrice =
        fancade?.fancadeTechnicalProject?.totalShieldingsPrice;
      let totalVentilationSystemsPrice =
        fancade?.fancadeTechnicalProject?.totalVentilationSystemsPrice;
      let totalAirConditioningSystemsPrice =
        fancade?.fancadeTechnicalProject?.totalAirConditioningSystemsPrice;
      let totalThermicSolarPanelsPrice =
        fancade?.fancadeTechnicalProject?.totalThermicSolarPanelsPrice;
      let totalPhotovoltaicModulesPrice =
        fancade?.fancadeTechnicalProject?.totalPhotovoltaicModulesPrice;

      if (
        fancade?.fancadeTechnicalProject?.totalShieldingsPriceCalculation &&
        +fancade?.fancadeTechnicalProject?.totalShieldingsPriceCalculation == 1
      ) {
        totalShieldingsPrice = 0;
        if (
          fancade?.fancadeTechnicalProject?.shieldingSystemsNumber &&
          fancade?.fancadeTechnicalProject?.shieldingsUnitPrice
        ) {
          totalShieldingsPrice =
            +fancade.fancadeTechnicalProject.shieldingSystemsNumber *
            +fancade.fancadeTechnicalProject.shieldingsUnitPrice;
        }
      }
      if (totalShieldingsPrice)
        totalSystemsIntegrationPrice += +totalShieldingsPrice;

      if (
        fancade?.fancadeTechnicalProject
          ?.totalVentilationSystemsPriceCalculation &&
        fancade?.fancadeTechnicalProject
          ?.totalVentilationSystemsPriceCalculation == 1
      ) {
        totalVentilationSystemsPrice = 0;
        if (
          fancade?.fancadeTechnicalProject?.ventilationDevicesNumber &&
          fancade?.fancadeTechnicalProject?.ventilationSystemsUnitPrice
        ) {
          totalVentilationSystemsPrice =
            +fancade.fancadeTechnicalProject.ventilationDevicesNumber *
            +fancade.fancadeTechnicalProject.ventilationSystemsUnitPrice;
        }
      }
      if (totalVentilationSystemsPrice)
        totalSystemsIntegrationPrice += +totalVentilationSystemsPrice;

      if (
        fancade?.fancadeTechnicalProject
          ?.totalAirConditioningSystemsPriceCalculation &&
        +fancade?.fancadeTechnicalProject
          ?.totalAirConditioningSystemsPriceCalculation == 1
      ) {
        totalAirConditioningSystemsPrice = 0;
        if (
          fancade?.fancadeTechnicalProject?.airConditioningDevicesNumber &&
          fancade?.fancadeTechnicalProject?.airConditioningSystemsUnitPrice
        ) {
          totalAirConditioningSystemsPrice =
            +fancade.fancadeTechnicalProject.airConditioningDevicesNumber *
            +fancade.fancadeTechnicalProject.airConditioningSystemsUnitPrice;
        }
      }
      if (totalAirConditioningSystemsPrice)
        totalSystemsIntegrationPrice += +totalAirConditioningSystemsPrice;

      if (!totalThermicSolarPanelsPrice || +totalThermicSolarPanelsPrice == 0) {
        totalThermicSolarPanelsPrice = 0;
        if (
          fancade?.fancadeTechnicalProject?.thermicSolarPanelsNumber &&
          fancade?.fancadeTechnicalProject?.thermicSolarPanelsUnitPrice
        ) {
          totalThermicSolarPanelsPrice =
            +fancade.fancadeTechnicalProject.thermicSolarPanelsNumber *
            +fancade.fancadeTechnicalProject.thermicSolarPanelsUnitPrice;
        }
      }
      totalSystemsIntegrationPrice += +totalThermicSolarPanelsPrice;

      if (
        fancade.fancadeTechnicalProject
          .totalPhotovoltaicModulesPriceCalculation &&
        +fancade.fancadeTechnicalProject
          .totalPhotovoltaicModulesPriceCalculation == 1
      ) {
        totalPhotovoltaicModulesPrice = 0;
        if (
          fancade?.fancadeTechnicalProject?.photovoltaicModulesNumber &&
          fancade?.fancadeTechnicalProject?.photovoltaicModulesUnitPrice
        ) {
          totalPhotovoltaicModulesPrice =
            +fancade.fancadeTechnicalProject.photovoltaicModulesNumber *
            +fancade.fancadeTechnicalProject.photovoltaicModulesUnitPrice;
        }
      }
      if (totalPhotovoltaicModulesPrice)
        totalSystemsIntegrationPrice += +totalPhotovoltaicModulesPrice;

      fancade.fancadeTechnicalProject.systemsIntegrations.map(
        (systemsIntegration, index) => {
          let totalSystemIntegrationPrice = systemsIntegration.totalPrice;
          if (
            systemsIntegration.totalPriceCalculation &&
            +systemsIntegration.totalPriceCalculation == 1
          ) {
            totalSystemIntegrationPrice = 0;
            if (systemsIntegration.units && systemsIntegration.unitPrice) {
              totalSystemIntegrationPrice =
                +systemsIntegration.units * +systemsIntegration.unitPrice;
            }
          }

          if (!totalSystemsIntegrationPrice) {
            totalSystemsIntegrationPrice = 0;
          }
          if (totalSystemIntegrationPrice)
            totalSystemsIntegrationPrice += +totalSystemIntegrationPrice;
        }
      );
      return +totalSystemsIntegrationPrice;
    }
  } else {
    return 0;
  }
};

export const calculateScrapTotal = (fancade: Fancade): number => {
  if (
    !fancade.fancadeProduction ||
    !fancade.fancadeProduction.totalProductionPriceCalculation ||
    fancade.fancadeProduction.totalProductionPriceCalculation == 0
  ) {
    return 0;
  }

  if (fancade.fancadeTechnicalProject) {
    let totalScrapPrice = fancade?.fancadeTechnicalProject?.totalScrapPrice;

    if (
      !fancade?.fancadeTechnicalProject?.totalScrapPriceCalculation ||
      fancade?.fancadeTechnicalProject?.totalScrapPriceCalculation == 0
    ) {
      if (!totalScrapPrice) return 0;
      return +totalScrapPrice;
    } else {
      totalScrapPrice = 0;

      let totalDoubleGlazingScrapPrice =
        fancade?.fancadeTechnicalProject?.totalDoubleGlazingScrapPrice;
      let totalMainFrameOrLoomScrapPrice =
        fancade?.fancadeTechnicalProject?.totalMainFrameOrLoomScrapPrice;
      let totalGasketScrapPrice =
        fancade?.fancadeTechnicalProject?.totalGasketScrapPrice;
      let totalOpaqueWindowsScrapPrice =
        fancade?.fancadeTechnicalProject?.totalOpaqueWindowsScrapPrice;
      let totalOpaqueExternalCladdingScrapPrice =
        fancade?.fancadeTechnicalProject?.totalOpaqueExternalCladdingScrapPrice;
      let totalOpaqueInsulationScrapPrice =
        fancade?.fancadeTechnicalProject?.totalOpaqueInsulationScrapPrice;
      let totalOpaqueSupportingElementsScrapPrice =
        fancade?.fancadeTechnicalProject
          ?.totalOpaqueSupportingElementsScrapPrice;
      let totalTransparentGlazedCellScrapPrice =
        fancade?.fancadeTechnicalProject?.totalTransparentGlazedCellScrapPrice;
      let totalTransparentOpaqueSpandrelModulesPrice =
        fancade?.fancadeTechnicalProject
          ?.totalTransparentOpaqueSpandrelModulesPrice;
      let totalFixingsScrapPrice =
        fancade?.fancadeTechnicalProject?.totalFixingsScrapPrice;
      let totalAnchoringsScrapPrice =
        fancade?.fancadeTechnicalProject?.totalAnchoringsScrapPrice;
      let accessoriesScrap = fancade?.fancadeTechnicalProject?.accessoriesScrap;

      if (
        fancade?.fancadeTechnicalProject
          ?.totalDoubleGlazingScrapPriceCalculation &&
        +fancade?.fancadeTechnicalProject
          ?.totalDoubleGlazingScrapPriceCalculation == 1
      ) {
        totalDoubleGlazingScrapPrice = 0;
        if (
          fancade.fancadeTechnicalProject.type
            ?.toLowerCase()
            .indexOf("opaca") != -1
        ) {
          if (
            fancade?.fancadeTechnicalProject?.windows.length > 0 &&
            fancade?.fancadeTechnicalProject?.windows[0]
              ?.doubleGlazingUnitPrice &&
            fancade?.fancadeTechnicalProject?.doubleGlazingScrapQuantity
          )
            totalScrapPrice =
              +fancade?.fancadeTechnicalProject?.windows[0]
                ?.doubleGlazingUnitPrice *
              +fancade?.fancadeTechnicalProject?.doubleGlazingScrapQuantity;
        } else {
          if (
            fancade.fancadeTechnicalProject.doubleGlazingUnitPrice &&
            fancade?.fancadeTechnicalProject?.doubleGlazingScrapQuantity
          )
            totalScrapPrice = totalScrapPrice =
              +fancade.fancadeTechnicalProject.doubleGlazingUnitPrice *
              +fancade?.fancadeTechnicalProject?.doubleGlazingScrapQuantity;
        }
      }
      if (totalDoubleGlazingScrapPrice)
        totalScrapPrice += +totalDoubleGlazingScrapPrice;

      if (
        fancade?.fancadeTechnicalProject
          ?.totalMainFrameOrLoomScrapPriceCalculation &&
        +fancade?.fancadeTechnicalProject
          ?.totalMainFrameOrLoomScrapPriceCalculation == 1
      ) {
        totalMainFrameOrLoomScrapPrice = 0;
        if (
          fancade?.fancadeTechnicalProject?.type
            ?.toLowerCase()
            .indexOf("opaca") != -1
        ) {
          if (
            fancade?.fancadeTechnicalProject?.windows.length > 0 &&
            fancade?.fancadeTechnicalProject?.windows[0]?.loomUnitPrice &&
            fancade?.fancadeTechnicalProject?.mainFrameOrLoomScrapQuantity
          )
            totalMainFrameOrLoomScrapPrice =
              +fancade?.fancadeTechnicalProject?.windows[0]?.loomUnitPrice *
              +fancade?.fancadeTechnicalProject?.mainFrameOrLoomScrapQuantity;
        } else {
          if (
            fancade?.fancadeTechnicalProject?.mainFrameUnitPrice &&
            fancade?.fancadeTechnicalProject?.mainFrameOrLoomScrapQuantity
          )
            totalMainFrameOrLoomScrapPrice =
              +fancade?.fancadeTechnicalProject?.mainFrameUnitPrice *
              +fancade?.fancadeTechnicalProject?.mainFrameOrLoomScrapQuantity;
        }
      }
      if (totalMainFrameOrLoomScrapPrice)
        totalScrapPrice += +totalMainFrameOrLoomScrapPrice;

      if (
        fancade?.fancadeTechnicalProject?.totalGasketScrapPriceCalculation &&
        +fancade?.fancadeTechnicalProject?.totalGasketScrapPriceCalculation ===
          1
      ) {
        totalGasketScrapPrice = 0;
        if (
          fancade?.fancadeTechnicalProject?.type
            ?.toLowerCase()
            .indexOf("opaca") != -1
        ) {
          if (
            fancade?.fancadeTechnicalProject?.windows.length > 0 &&
            fancade?.fancadeTechnicalProject?.windows[0]?.gasketsUnitPrice &&
            fancade?.fancadeTechnicalProject?.gasketScrapQuantity
          )
            totalGasketScrapPrice =
              +fancade?.fancadeTechnicalProject?.windows[0]?.gasketsUnitPrice *
              +fancade?.fancadeTechnicalProject?.gasketScrapQuantity;
        } else {
          if (
            fancade?.fancadeTechnicalProject?.gasketUnitPrice &&
            fancade?.fancadeTechnicalProject?.gasketScrapQuantity
          )
            totalGasketScrapPrice =
              +fancade?.fancadeTechnicalProject?.gasketUnitPrice *
              +fancade?.fancadeTechnicalProject?.gasketScrapQuantity;
        }
      }
      if (totalGasketScrapPrice) totalScrapPrice += +totalGasketScrapPrice;

      if (
        fancade?.fancadeTechnicalProject
          ?.totalOpaqueWindowsScrapPriceCalculation &&
        +fancade?.fancadeTechnicalProject
          ?.totalOpaqueWindowsScrapPriceCalculation == 1
      ) {
        totalOpaqueWindowsScrapPrice = 0;
        if (
          fancade?.fancadeTechnicalProject?.type
            ?.toLowerCase()
            .indexOf("opaca") != -1
        ) {
          if (
            fancade?.fancadeTechnicalProject?.windows.length > 0 &&
            fancade?.fancadeTechnicalProject?.windows[0]?.unitPrice &&
            fancade?.fancadeTechnicalProject?.opaqueWindowsScrapQuantity
          )
            totalOpaqueWindowsScrapPrice =
              +fancade?.fancadeTechnicalProject?.windows[0]?.unitPrice *
              +fancade?.fancadeTechnicalProject?.opaqueWindowsScrapQuantity;
        } else {
          totalOpaqueWindowsScrapPrice = 0;
        }
      }
      if (totalOpaqueWindowsScrapPrice)
        totalScrapPrice += +totalOpaqueWindowsScrapPrice;

      if (
        fancade?.fancadeTechnicalProject
          ?.totalOpaqueExternalCladdingScrapPriceCalculation &&
        +fancade?.fancadeTechnicalProject
          ?.totalOpaqueExternalCladdingScrapPriceCalculation == 1
      ) {
        totalOpaqueExternalCladdingScrapPrice = 0;
        if (
          fancade?.fancadeTechnicalProject?.type
            ?.toLowerCase()
            .indexOf("opaca") != -1
        ) {
          if (
            fancade?.fancadeTechnicalProject?.externalCladdingUnitPrice &&
            fancade?.fancadeTechnicalProject
              ?.opaqueExternalCladdingScrapQuantity
          )
            totalOpaqueExternalCladdingScrapPrice =
              +fancade?.fancadeTechnicalProject?.externalCladdingUnitPrice *
              +fancade?.fancadeTechnicalProject
                ?.opaqueExternalCladdingScrapQuantity;
        } else {
          totalOpaqueExternalCladdingScrapPrice = 0;
        }
      }
      if (totalOpaqueExternalCladdingScrapPrice)
        totalScrapPrice += +totalOpaqueExternalCladdingScrapPrice;

      if (
        fancade?.fancadeTechnicalProject
          ?.totalOpaqueInsulationScrapPriceCalculation &&
        +fancade?.fancadeTechnicalProject
          ?.totalOpaqueInsulationScrapPriceCalculation == 1
      ) {
        totalOpaqueInsulationScrapPrice = 0;
        if (
          fancade?.fancadeTechnicalProject?.type
            ?.toLowerCase()
            .indexOf("opaca") != -1
        ) {
          if (
            fancade?.fancadeTechnicalProject?.insulationUnitPrice &&
            fancade?.fancadeTechnicalProject?.opaqueInsulationScrapQuantity
          )
            totalOpaqueInsulationScrapPrice =
              +fancade?.fancadeTechnicalProject?.insulationUnitPrice *
              +fancade?.fancadeTechnicalProject?.opaqueInsulationScrapQuantity;
        } else {
          totalOpaqueInsulationScrapPrice = 0;
        }
      }
      if (totalOpaqueInsulationScrapPrice)
        totalScrapPrice += +totalOpaqueInsulationScrapPrice;

      if (
        fancade?.fancadeTechnicalProject
          ?.totalOpaqueSupportingElementsScrapPriceCalculation &&
        +fancade?.fancadeTechnicalProject
          ?.totalOpaqueSupportingElementsScrapPriceCalculation == 1
      ) {
        totalOpaqueSupportingElementsScrapPrice = 0;
        if (
          fancade?.fancadeTechnicalProject?.type
            ?.toLowerCase()
            .indexOf("opaca") != -1
        ) {
          if (
            fancade?.fancadeTechnicalProject?.supportingElementsUnitPrice &&
            fancade?.fancadeTechnicalProject
              ?.opaqueSupportingElementsScrapQuantity
          )
            totalOpaqueSupportingElementsScrapPrice =
              +fancade?.fancadeTechnicalProject?.supportingElementsUnitPrice *
              +fancade?.fancadeTechnicalProject
                ?.opaqueSupportingElementsScrapQuantity;
        } else {
          totalOpaqueSupportingElementsScrapPrice = 0;
        }
      }
      if (totalOpaqueSupportingElementsScrapPrice)
        totalScrapPrice += +totalOpaqueSupportingElementsScrapPrice;

      if (
        fancade?.fancadeTechnicalProject
          ?.totalTransparentGlazedCellScrapPriceCalculation &&
        +fancade?.fancadeTechnicalProject
          ?.totalTransparentGlazedCellScrapPriceCalculation == 1
      ) {
        totalTransparentGlazedCellScrapPrice = 0;
        if (
          fancade?.fancadeTechnicalProject?.type
            ?.toLowerCase()
            .indexOf("trasparente") != -1
        ) {
          if (
            fancade?.fancadeTechnicalProject?.glazedCellsUnitPrice &&
            fancade?.fancadeTechnicalProject?.transparentGlazedCellScrapQuantity
          )
            totalTransparentGlazedCellScrapPrice =
              +fancade?.fancadeTechnicalProject?.glazedCellsUnitPrice *
              +fancade?.fancadeTechnicalProject
                ?.transparentGlazedCellScrapQuantity;
        } else {
          totalTransparentGlazedCellScrapPrice = 0;
        }
      }
      if (totalTransparentGlazedCellScrapPrice)
        totalScrapPrice += +totalTransparentGlazedCellScrapPrice;

      if (
        fancade?.fancadeTechnicalProject
          ?.totalTransparentOpaqueSpandrelModulesPriceCalculation &&
        +fancade?.fancadeTechnicalProject
          ?.totalTransparentOpaqueSpandrelModulesPriceCalculation == 1
      ) {
        totalTransparentOpaqueSpandrelModulesPrice = 0;
        if (
          fancade?.fancadeTechnicalProject?.type
            ?.toLowerCase()
            .indexOf("trasparente") != -1
        ) {
          if (
            fancade?.fancadeTechnicalProject?.opaqueSpandrelModulesUnitPrice &&
            fancade?.fancadeTechnicalProject
              ?.transparentOpaqueSpandrelModulesQuantity
          )
            totalTransparentOpaqueSpandrelModulesPrice =
              +fancade?.fancadeTechnicalProject
                ?.opaqueSpandrelModulesUnitPrice *
              +fancade?.fancadeTechnicalProject
                ?.transparentOpaqueSpandrelModulesQuantity;
        } else {
          totalTransparentOpaqueSpandrelModulesPrice = 0;
        }
      }
      if (totalTransparentOpaqueSpandrelModulesPrice)
        totalScrapPrice += +totalTransparentOpaqueSpandrelModulesPrice;

      if (
        fancade?.fancadeTechnicalProject?.totalFixingsScrapPriceCalculation &&
        +fancade?.fancadeTechnicalProject?.totalFixingsScrapPriceCalculation ==
          1
      ) {
        totalFixingsScrapPrice = 0;
        if (
          fancade?.fancadeTechnicalProject?.type
            ?.toLowerCase()
            .indexOf("opaca") != -1
        ) {
          if (
            fancade?.fancadeTechnicalProject?.opaqueFixingsUnitPrice &&
            fancade?.fancadeTechnicalProject?.fixingsScrapQuantity
          )
            totalFixingsScrapPrice =
              +fancade?.fancadeTechnicalProject?.opaqueFixingsUnitPrice *
              +fancade?.fancadeTechnicalProject?.fixingsScrapQuantity;
        } else {
          if (
            fancade?.fancadeTechnicalProject
              ?.transparentFixingsNumberUnitPrice &&
            fancade?.fancadeTechnicalProject?.fixingsScrapQuantity
          )
            totalFixingsScrapPrice =
              +fancade?.fancadeTechnicalProject
                ?.transparentFixingsNumberUnitPrice *
              +fancade?.fancadeTechnicalProject?.fixingsScrapQuantity;
        }
      }
      if (totalFixingsScrapPrice) totalScrapPrice += +totalFixingsScrapPrice;

      if (
        fancade?.fancadeTechnicalProject
          ?.totalAnchoringsScrapPriceCalculation &&
        +fancade?.fancadeTechnicalProject
          ?.totalAnchoringsScrapPriceCalculation == 1
      ) {
        totalAnchoringsScrapPrice = 0;
        if (
          fancade?.fancadeTechnicalProject?.type
            ?.toLowerCase()
            .indexOf("opaca") != -1
        ) {
          if (
            fancade?.fancadeTechnicalProject?.opaqueAnchoringUnitPrice &&
            fancade?.fancadeTechnicalProject?.anchoringsScrapQuantity
          )
            totalAnchoringsScrapPrice =
              +fancade?.fancadeTechnicalProject?.opaqueAnchoringUnitPrice *
              +fancade?.fancadeTechnicalProject?.anchoringsScrapQuantity;
        } else {
          if (
            fancade?.fancadeTechnicalProject?.transparentAnchoringsUnitPrice &&
            fancade?.fancadeTechnicalProject?.anchoringsScrapQuantity
          )
            totalAnchoringsScrapPrice =
              +fancade?.fancadeTechnicalProject
                ?.transparentAnchoringsUnitPrice *
              +fancade?.fancadeTechnicalProject?.anchoringsScrapQuantity;
        }
      }
      if (totalAnchoringsScrapPrice)
        totalScrapPrice += +totalAnchoringsScrapPrice;

      if (accessoriesScrap) totalScrapPrice += +accessoriesScrap;

      return +totalScrapPrice;
    }
  } else {
    return 0;
  }
};

export const calculateMaterialsTotal = (fancade: Fancade): number => {
  if (
    !fancade.fancadeProduction ||
    !fancade.fancadeProduction.totalProductionPriceCalculation ||
    fancade.fancadeProduction.totalProductionPriceCalculation == 0
  ) {
    return 0;
  }
  if (fancade.fancadeProduction) {
    let totalMaterialsPrice = fancade.fancadeProduction.totalMaterialsPrice;
    if (fancade.fancadeProduction.totalMaterialsPriceCalculation == 1) {
      totalMaterialsPrice = 0;
      let windowsTotalPrice = fancade.fancadeTechnicalProject.windowsTotalPrice;
      let totalSystemIntegrationPrice =
        calculateSystemsIntegrationTotal(fancade);
      let scrapTotal = fancade.fancadeProduction.totalScrapPrice;

      if (
        windowsTotalPrice &&
        (!fancade.fancadeTechnicalProject.windowsTotalPriceCalculation ||
          fancade.fancadeTechnicalProject.windowsTotalPriceCalculation == 0)
      )
        totalMaterialsPrice += +windowsTotalPrice;
      if (!windowsTotalPrice || +windowsTotalPrice == 0) {
        if (
          fancade.fancadeTechnicalProject.type
            ?.toLowerCase()
            .indexOf("opaca") != -1
        ) {
          windowsTotalPrice = calculateOpaqueWindowsTotal(fancade);
        } else if (
          fancade.fancadeTechnicalProject.type
            ?.toLowerCase()
            .indexOf("trasparente") != -1
        ) {
          windowsTotalPrice = calculateTransparentWindowsTotal(fancade);
        }
        if (windowsTotalPrice) totalMaterialsPrice += +windowsTotalPrice;
      }

      if (
        fancade.fancadeTechnicalProject.type?.toLowerCase().indexOf("opaca") !=
        -1
      ) {
        totalMaterialsPrice += +calculateOpaqueOtherMaterialsTotal(fancade);
      } else if (
        fancade.fancadeTechnicalProject.type
          ?.toLowerCase()
          .indexOf("trasparente") != -1
      ) {
        totalMaterialsPrice += +calculateHardwareTotalPrice(fancade);
      }

      totalMaterialsPrice += +totalSystemIntegrationPrice;

      if (!scrapTotal || +scrapTotal == 0) {
        scrapTotal = calculateScrapTotal(fancade);
      }
      if (scrapTotal) totalMaterialsPrice += +scrapTotal;

      return +totalMaterialsPrice;
    } else if (totalMaterialsPrice) {
      return +totalMaterialsPrice;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

export const calculateOpaqueOtherMaterialsTotal = (
  fancade: Fancade
): number => {
  let toReturn = 0;

  let externalCladdingTotalPrice =
    fancade.fancadeTechnicalProject.externalCladdingTotalPrice;
  let insulationTotalPrice =
    fancade.fancadeTechnicalProject.insulationTotalPrice;
  let supportingElementsTotalPrice =
    fancade.fancadeTechnicalProject.supportingElementsTotalPrice;
  let prefabricatedModulesTotalPrice =
    fancade.fancadeTechnicalProject.prefabricatedModulesTotalPrice;
  let opaqueAnchoringTotalPrice =
    fancade.fancadeTechnicalProject.opaqueAnchoringTotalPrice;
  let opaqueFixingsTotalPrice =
    fancade.fancadeTechnicalProject.opaqueFixingsTotalPrice;

  if (!externalCladdingTotalPrice || externalCladdingTotalPrice == 0) {
    if (
      fancade.fancadeTechnicalProject.externalCladdingUnitPrice &&
      fancade.fancadeTechnicalProject.externalCladdingUnitsNumber &&
      fancade.fancadeTechnicalProject.externalCladdingUnitsSurface
    ) {
      externalCladdingTotalPrice =
        +fancade.fancadeTechnicalProject.externalCladdingUnitPrice *
        +fancade.fancadeTechnicalProject.externalCladdingUnitsNumber *
        +fancade.fancadeTechnicalProject.externalCladdingUnitsSurface;
    } else {
      externalCladdingTotalPrice = 0;
    }
  }
  if (
    fancade.fancadeTechnicalProject.type &&
    fancade.fancadeTechnicalProject.type.toLowerCase() !=
      "opaca - retrofit modulare"
  )
    toReturn += +externalCladdingTotalPrice;

  if (!insulationTotalPrice || insulationTotalPrice == 0) {
    if (
      fancade.fancadeTechnicalProject.insulationUnitPrice &&
      fancade.fancadeTechnicalProject.insulationSurface
    ) {
      insulationTotalPrice =
        +fancade.fancadeTechnicalProject.insulationUnitPrice *
        +fancade.fancadeTechnicalProject.insulationSurface;
    } else {
      insulationTotalPrice = 0;
    }
  }
  if (
    fancade.fancadeTechnicalProject.type &&
    fancade.fancadeTechnicalProject.type.toLowerCase() !=
      "opaca - retrofit modulare"
  )
    toReturn += +insulationTotalPrice;

  if (!supportingElementsTotalPrice || supportingElementsTotalPrice == 0) {
    if (
      fancade.fancadeTechnicalProject.supportingElementsUnitPrice &&
      fancade.fancadeTechnicalProject.supportingElementsQuantity
    ) {
      supportingElementsTotalPrice =
        +fancade.fancadeTechnicalProject.supportingElementsUnitPrice *
        +fancade.fancadeTechnicalProject.supportingElementsQuantity;
    } else {
      supportingElementsTotalPrice = 0;
    }
  }
  if (
    fancade.fancadeTechnicalProject.type &&
    fancade.fancadeTechnicalProject.type.toLowerCase() !=
      "opaca - retrofit modulare"
  )
    toReturn += +supportingElementsTotalPrice;

  if (!prefabricatedModulesTotalPrice || prefabricatedModulesTotalPrice == 0) {
    if (
      fancade.fancadeTechnicalProject.prefabricatedModulesUnitPrice &&
      fancade.fancadeTechnicalProject.modulesSurface
    ) {
      prefabricatedModulesTotalPrice =
        +fancade.fancadeTechnicalProject.prefabricatedModulesUnitPrice *
        +fancade.fancadeTechnicalProject.modulesSurface;
    } else {
      prefabricatedModulesTotalPrice = 0;
    }
  }
  if (
    fancade.fancadeTechnicalProject.type &&
    (fancade.fancadeTechnicalProject.type.toLowerCase() !=
        "opaca - finestre")
  )
    toReturn += +prefabricatedModulesTotalPrice;

  if (!opaqueAnchoringTotalPrice || opaqueAnchoringTotalPrice == 0) {
    if (
      fancade.fancadeTechnicalProject.opaqueAnchoringUnitPrice &&
      fancade.fancadeTechnicalProject.opaqueAnchoringsNumber
    ) {
      opaqueAnchoringTotalPrice =
        +fancade.fancadeTechnicalProject.opaqueAnchoringUnitPrice *
        +fancade.fancadeTechnicalProject.opaqueAnchoringsNumber;
    } else {
      opaqueAnchoringTotalPrice = 0;
    }
  }
  toReturn += +opaqueAnchoringTotalPrice;

  if (!opaqueFixingsTotalPrice || opaqueFixingsTotalPrice == 0) {
    if (
      fancade.fancadeTechnicalProject.opaqueFixingsUnitPrice &&
      fancade.fancadeTechnicalProject.opaqueFixingsNumber
    ) {
      opaqueFixingsTotalPrice =
        +fancade.fancadeTechnicalProject.opaqueFixingsUnitPrice *
        +fancade.fancadeTechnicalProject.opaqueFixingsNumber;
    } else {
      opaqueFixingsTotalPrice = 0;
    }
  }
  toReturn += +opaqueFixingsTotalPrice;

  return toReturn;
};

export const calculateLaborTotal = (fancade: Fancade): number => {
  if (
    !fancade.fancadeProduction ||
    !fancade.fancadeProduction.totalProductionPriceCalculation ||
    fancade.fancadeProduction.totalProductionPriceCalculation == 0
  ) {
    return 0;
  }
  if (
    !fancade?.fancadeProduction?.totalLaborPriceCalculation ||
    fancade?.fancadeProduction?.totalLaborPriceCalculation == 0
  ) {
    if (
      !fancade.fancadeProduction ||
      !fancade?.fancadeProduction?.totalLaborPrice
    )
      return 0;
    return +fancade.fancadeProduction.totalLaborPrice;
  } else {
    return (
      +calculateTotalBaseLaborPrice(fancade) +
      +calculateTotalSkilledLaborPrice(fancade)
    );
  }
};

export const calculateTotalBaseLaborPrice = (fancade: Fancade): number => {
  if (
    !fancade.fancadeProduction ||
    !fancade.fancadeProduction.totalProductionPriceCalculation ||
    fancade.fancadeProduction.totalProductionPriceCalculation == 0
  ) {
    return 0;
  }
  if (fancade.fancadeProduction) {
    if (
      fancade?.fancadeProduction?.totalBaseLaborPrice &&
      fancade?.fancadeProduction?.totalBaseLaborPriceCalculation &&
      fancade?.fancadeProduction?.totalBaseLaborPriceCalculation == 0
    ) {
      return +fancade.fancadeProduction?.totalBaseLaborPrice;
    } else if (
      fancade?.fancadeProduction?.baseLaborHours &&
      fancade?.fancadeProduction?.baseLaborHours
    ) {
      return (
        +fancade?.fancadeProduction?.baseLaborHours *
        +fancade?.fancadeProduction?.baseLaborHours
      );
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

export const calculateTotalSkilledLaborPrice = (fancade: Fancade): number => {
  if (
    !fancade.fancadeProduction ||
    !fancade.fancadeProduction.totalProductionPriceCalculation ||
    fancade.fancadeProduction.totalProductionPriceCalculation == 0
  ) {
    return 0;
  }
  if (fancade.fancadeProduction) {
    if (
      (!fancade?.fancadeProduction?.totalSkilledLaborPriceCalculation ||
        fancade?.fancadeProduction?.totalSkilledLaborPriceCalculation == 0) &&
      fancade.fancadeProduction?.totalSkilledLaborPrice
    ) {
      return +fancade.fancadeProduction?.totalSkilledLaborPrice;
    } else if (
      fancade?.fancadeProduction?.skilledLaborHours &&
      fancade?.fancadeProduction?.skilledLaborHours
    ) {
      return (
        +fancade?.fancadeProduction?.skilledLaborHours *
        +fancade?.fancadeProduction?.skilledLaborHours
      );
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

export const getScrapUnitPrice = (
  varName:
    | "doubleglazing"
    | "mainframeorloop"
    | "gasket"
    | "opaquewindows"
    | "externalcladding"
    | "insulation"
    | "supportingelements"
    | "glazedcells"
    | "opaquespandrelmodules"
    | "fixings"
    | "anchoring",
  fancade: Fancade
): number => {
  switch (varName.toLowerCase()) {
    case "doubleglazing":
      if (
        fancade?.fancadeTechnicalProject?.type
          ?.toLowerCase()
          .indexOf("opaca") != -1
      ) {
        if (
          fancade?.fancadeTechnicalProject?.windows.length > 0 &&
          fancade?.fancadeTechnicalProject?.windows[0]?.doubleGlazingUnitPrice
        )
          return +fancade?.fancadeTechnicalProject?.windows[0]
            ?.doubleGlazingUnitPrice;
        else return 0;
      } else if (
        fancade?.fancadeTechnicalProject?.type
          ?.toLowerCase()
          .indexOf("trasparente") != -1
      ) {
        if (fancade?.fancadeTechnicalProject?.doubleGlazingUnitPrice)
          return fancade?.fancadeTechnicalProject?.doubleGlazingUnitPrice;
        else return 0;
      } else return 0;
      break;

    case "mainframeorloop":
      if (
        fancade?.fancadeTechnicalProject?.type
          ?.toLowerCase()
          .indexOf("opaca") != -1
      ) {
        if (
          fancade?.fancadeTechnicalProject?.windows.length > 0 &&
          fancade?.fancadeTechnicalProject?.windows[0]?.loomUnitPrice
        )
          return +fancade?.fancadeTechnicalProject?.windows[0]?.loomUnitPrice;
        else return 0;
      } else if (
        fancade?.fancadeTechnicalProject?.type
          ?.toLowerCase()
          .indexOf("trasparente") != -1
      ) {
        if (fancade?.fancadeTechnicalProject?.mainFrameUnitPrice)
          return fancade?.fancadeTechnicalProject?.mainFrameUnitPrice;
        else return 0;
      } else return 0;
      break;

    case "gasket":
      if (
        fancade?.fancadeTechnicalProject?.type
          ?.toLowerCase()
          .indexOf("opaca") != -1
      ) {
        if (
          fancade?.fancadeTechnicalProject?.windows.length > 0 &&
          fancade?.fancadeTechnicalProject?.windows[0]?.gasketsUnitPrice
        )
          return +fancade?.fancadeTechnicalProject?.windows[0]
            ?.gasketsUnitPrice;
        else return 0;
      } else if (
        fancade?.fancadeTechnicalProject?.type
          ?.toLowerCase()
          .indexOf("trasparente") != -1
      ) {
        if (fancade?.fancadeTechnicalProject?.gasketUnitPrice)
          return fancade?.fancadeTechnicalProject?.gasketUnitPrice;
        else return 0;
      } else return 0;

    case "opaquewindows":
      if (
        fancade?.fancadeTechnicalProject?.type
          ?.toLowerCase()
          .indexOf("opaca") != -1
      ) {
        if (
          fancade?.fancadeTechnicalProject?.windows.length > 0 &&
          fancade?.fancadeTechnicalProject?.windows[0]?.unitPrice
        )
          return +fancade?.fancadeTechnicalProject?.windows[0]?.unitPrice;
        else return 0;
      } else return 0;
      break;

    case "externalcladding":
      if (
        fancade?.fancadeTechnicalProject?.type
          ?.toLowerCase()
          .indexOf("opaca") != -1
      ) {
        if (fancade?.fancadeTechnicalProject?.externalCladdingUnitPrice)
          return +fancade?.fancadeTechnicalProject?.externalCladdingUnitPrice;
        else return 0;
      } else return 0;
      break;

    case "insulation":
      if (
        fancade?.fancadeTechnicalProject?.type
          ?.toLowerCase()
          .indexOf("opaca") != -1
      ) {
        if (fancade?.fancadeTechnicalProject?.insulationUnitPrice)
          return +fancade?.fancadeTechnicalProject?.insulationUnitPrice;
        else return 0;
      } else return 0;
      break;

    case "supportingelements":
      if (
        fancade?.fancadeTechnicalProject?.type
          ?.toLowerCase()
          .indexOf("opaca") != -1
      ) {
        if (fancade?.fancadeTechnicalProject?.supportingElementsUnitPrice)
          return +fancade?.fancadeTechnicalProject?.supportingElementsUnitPrice;
        else return 0;
      } else return 0;
      break;

    case "glazedcells":
      if (
        fancade?.fancadeTechnicalProject?.type
          ?.toLowerCase()
          .indexOf("trasparente") != -1
      ) {
        if (fancade?.fancadeTechnicalProject?.glazedCellsUnitPrice)
          return +fancade?.fancadeTechnicalProject?.glazedCellsUnitPrice;
        else return 0;
      } else return 0;
      break;

    case "opaquespandrelmodules":
      if (
        fancade?.fancadeTechnicalProject?.type
          ?.toLowerCase()
          .indexOf("trasparente") != -1
      ) {
        if (fancade?.fancadeTechnicalProject?.opaqueSpandrelModulesUnitPrice)
          return +fancade?.fancadeTechnicalProject
            ?.opaqueSpandrelModulesUnitPrice;
        else return 0;
      } else return 0;
      break;

    case "fixings":
      if (
        fancade?.fancadeTechnicalProject?.type
          ?.toLowerCase()
          .indexOf("opaca") != -1
      ) {
        if (fancade?.fancadeTechnicalProject?.opaqueFixingsUnitPrice)
          return +fancade?.fancadeTechnicalProject?.opaqueFixingsUnitPrice;
        else return 0;
      } else if (
        fancade?.fancadeTechnicalProject?.type
          ?.toLowerCase()
          .indexOf("trasparente") != -1
      ) {
        if (fancade?.fancadeTechnicalProject?.transparentFixingsNumberUnitPrice)
          return fancade?.fancadeTechnicalProject
            ?.transparentFixingsNumberUnitPrice;
        else return 0;
      } else return 0;

    case "anchoring":
      if (
        fancade?.fancadeTechnicalProject?.type
          ?.toLowerCase()
          .indexOf("opaca") != -1
      ) {
        if (fancade?.fancadeTechnicalProject?.opaqueAnchoringUnitPrice)
          return +fancade?.fancadeTechnicalProject?.opaqueAnchoringUnitPrice;
        else return 0;
      } else if (
        fancade?.fancadeTechnicalProject?.type
          ?.toLowerCase()
          .indexOf("trasparente") != -1
      ) {
        if (fancade?.fancadeTechnicalProject?.transparentAnchoringsUnitPrice)
          return fancade?.fancadeTechnicalProject
            ?.transparentAnchoringsUnitPrice;
        else return 0;
      } else return 0;

    default:
      return 0;
      break;
  }
};

//---------------Transport---------------
export const calculateTransportTotal = (fancade: Fancade): number => {
  if (fancade.transport) {
    let totalTransportPrice = fancade.transport.totalTransportPrice;
    if (
      !fancade.transport.totalTransportPriceCalculation ||
      fancade.transport.totalTransportPriceCalculation == 0
    ) {
      if (totalTransportPrice) return +totalTransportPrice;
      return 0;
    } else {
      totalTransportPrice = 0;

      totalTransportPrice += +calculatePackagingsTotal(fancade);
      totalTransportPrice += +calculateTransportPartTotal(fancade);

      return totalTransportPrice;
    }
  } else {
    return 0;
  }
};

export const calculatePackagingsTotal = (fancade: Fancade): number => {
  if (
    !fancade.transport ||
    !fancade.transport.totalTransportPriceCalculation ||
    fancade.transport.totalTransportPriceCalculation == 0
  ) {
    return 0;
  }

  if (fancade.transport) {
    let packagingTotal = fancade.transport.totalPackagingPrice;
    if (
      !fancade.transport.totalPackagingPriceCalculation ||
      fancade.transport.totalPackagingPriceCalculation == 0
    ) {
      if (packagingTotal) return +packagingTotal;
      return 0;
    } else {
      packagingTotal = 0;
      fancade.transport.packagings?.map((packaging) => {
        if (
          !packaging.totalPriceCalculation ||
          packaging.totalPriceCalculation == 0
        ) {
          if (packaging.totalPrice)
            //@ts-ignore
            packagingTotal += +packaging.totalPrice;
        } else {
          if (packaging.unitPrice && packaging.unitsNumber)
            //@ts-ignore
            packagingTotal += +packaging.unitPrice * +packaging.unitsNumber;
        }
      });
      return packagingTotal;
    }
  } else {
    return 0;
  }
};

export const calculateTransportPartTotal = (fancade: Fancade): number => {
  if (
    !fancade.transport ||
    !fancade.transport.totalTransportPriceCalculation ||
    fancade.transport.totalTransportPriceCalculation == 0
  ) {
    return 0;
  }
  if (fancade.transport) {
    let transportPartTotal = fancade.transport.totalTransportPartPrice;

    if (
      !fancade.transport.totalTransportPartPriceCalculation ||
      fancade.transport.totalTransportPartPriceCalculation == 0
    ) {
      if (transportPartTotal) return +transportPartTotal;
      return 0;
    } else {
      transportPartTotal = 0;
      if (
        fancade.transport.workSiteProductionSiteDistance &&
        fancade.transport.transportPartUnitPrice
      )
        transportPartTotal +=
          fancade.transport.workSiteProductionSiteDistance *
          fancade.transport.transportPartUnitPrice;

      return transportPartTotal;
    }
  } else {
    return 0;
  }
};

//---------------Building Site---------------
export const calculateInstallationTotal = (fancade: Fancade): number => {
  if (
    !fancade.buildingSite.totalInstallationPriceCalculation ||
    fancade.buildingSite.totalInstallationPriceCalculation == 0
  ) {
    if (fancade.buildingSite && fancade.buildingSite.totalInstallationPrice)
      return fancade.buildingSite.totalInstallationPrice;
    return 0;
  } else {
    let toReturn = 0;

    toReturn += +calculateBuildingSiteMaterialsTotal(fancade);
    toReturn += +calculateBuildingSiteLaborTotal(fancade);
    toReturn += +calculateBuildingSiteToolsTotal(fancade);

    return toReturn;
  }
};

export const calculateBuildingSiteMaterialsTotal = (
  fancade: Fancade
): number => {
  if (fancade.buildingSite) {
    if (
      !fancade.buildingSite.totalBuildingSiteMaterialsPriceCalculation ||
      fancade.buildingSite.totalBuildingSiteMaterialsPriceCalculation == 0
    ) {
      if (
        fancade.buildingSite &&
        fancade.buildingSite.totalBuildingSiteMaterialsPrice
      )
        return fancade.buildingSite.totalBuildingSiteMaterialsPrice;
      return 0;
    } else {
      let toReturn = 0;
      fancade.buildingSite.buildingSiteMaterials?.map((material, index) => {
        if (
          !material.totalAdditionalMaterialPriceCalculation ||
          material.totalAdditionalMaterialPriceCalculation == 0
        ) {
          if (material.totalAdditionalMaterialPrice)
            toReturn += +material.totalAdditionalMaterialPrice;
        } else {
          if (
            material.additionalMaterialQuantity &&
            material.additionalMaterialUnitPrice
          )
            toReturn +=
              +material.additionalMaterialQuantity *
              +material.additionalMaterialUnitPrice;
        }
      });
      return toReturn;
    }
  } else {
    return 0;
  }
};

export const calculateBuildingSiteLaborTotal = (fancade: Fancade): number => {
  if (
    fancade.buildingSite &&
    fancade.buildingSite.totalInstallationPriceCalculation &&
    fancade.buildingSite.totalInstallationPriceCalculation == 1
  ) {
    if (
      !fancade.buildingSite.totalLaborPriceCalculation ||
      fancade.buildingSite.totalLaborPriceCalculation == 0
    ) {
      if (fancade.buildingSite && fancade.buildingSite.totalLaborPrice)
        return fancade.buildingSite.totalLaborPrice;
      return 0;
    } else {
      let toReturn = 0;
      //Base labor
      if (
        !fancade.buildingSite.totalBaseLaborPriceCalculation ||
        fancade.buildingSite.totalBaseLaborPriceCalculation == 0
      ) {
        if (fancade.buildingSite.totalBaseLaborPrice)
          toReturn += +fancade.buildingSite.totalBaseLaborPrice;
      } else {
        if (
          fancade.buildingSite.baseLaborHours &&
          fancade.buildingSite.baseLaborHourPrice
        )
          toReturn +=
            +fancade.buildingSite.baseLaborHours *
            +fancade.buildingSite.baseLaborHourPrice;
      }

      //Skilled labor
      if (
        !fancade.buildingSite.totalSkilledLaborPriceCalculation ||
        fancade.buildingSite.totalSkilledLaborPriceCalculation == 0
      ) {
        if (fancade.buildingSite.totalSkilledLaborPrice)
          toReturn += +fancade.buildingSite.totalSkilledLaborPrice;
      } else {
        if (
          fancade.buildingSite.skilledLaborHours &&
          fancade.buildingSite.skilledLaborHourPrice
        )
          toReturn +=
            +fancade.buildingSite.skilledLaborHours *
            +fancade.buildingSite.skilledLaborHourPrice;
      }

      return toReturn;
    }
  } else {
    return 0;
  }
};

export const calculateScaffoldingTotal = (fancade: Fancade): number => {
  let toReturn = 0;
  if (
    fancade.buildingSite &&
    fancade.buildingSite.totalInstallationPriceCalculation &&
    fancade.buildingSite.totalInstallationPriceCalculation == 1
  ) {
    //Scaffolding
    if (
      !fancade.buildingSite.totalScaffoldingPriceCalculation ||
      fancade.buildingSite.totalScaffoldingPriceCalculation == 0
    ) {
      if (fancade.buildingSite && fancade.buildingSite.totalScaffoldingPrice)
        toReturn += +fancade.buildingSite.totalScaffoldingPrice;
    } else if (fancade.buildingSite.totalScaffoldingPriceCalculation == 1) {
      if (
        fancade.buildingSite.scaffoldingSurface &&
        fancade.buildingSite.scaffoldingSurfaceUnitPrice
      )
        toReturn +=
          +fancade.buildingSite.scaffoldingSurface *
          +fancade.buildingSite.scaffoldingSurfaceUnitPrice;
    } else if (fancade.buildingSite.totalScaffoldingPriceCalculation == 2) {
      if (
        fancade.buildingSite.scaffoldingHours &&
        fancade.buildingSite.scaffoldingHourPrice
      )
        toReturn +=
          +fancade.buildingSite.scaffoldingHours *
          +fancade.buildingSite.scaffoldingHourPrice;
    }
  }
  return toReturn;
};

export const calculateBuildingSiteToolsTotal = (fancade: Fancade): number => {
  if (
    fancade.buildingSite &&
    fancade.buildingSite.totalInstallationPriceCalculation &&
    fancade.buildingSite.totalInstallationPriceCalculation == 1
  ) {
    if (
      !fancade.buildingSite.totalToolsPriceCalculation ||
      fancade.buildingSite.totalToolsPriceCalculation == 0
    ) {
      if (fancade.buildingSite.totalToolsPrice)
        return fancade.buildingSite.totalToolsPrice;
      return 0;
    } else {
      let toReturn = 0;

      //Crane
      if (
        !fancade.buildingSite.totalTowerCranePriceCalculation ||
        fancade.buildingSite.totalTowerCranePriceCalculation == 0
      ) {
        if (fancade.buildingSite.totalTowerCranePrice)
          toReturn += +fancade.buildingSite.totalTowerCranePrice;
      } else {
        if (
          fancade.buildingSite.towerCraneHours &&
          fancade.buildingSite.towerCraneHourPrice
        )
          toReturn +=
            +fancade.buildingSite.towerCraneHours *
            +fancade.buildingSite.towerCraneHourPrice;
      }

      //Elevator platforms
      if (
        !fancade.buildingSite.totalElevatorPlatformPriceCalculation ||
        fancade.buildingSite.totalElevatorPlatformPriceCalculation == 0
      ) {
        if (fancade.buildingSite.totalElevatorPlatformPrice)
          toReturn += +fancade.buildingSite.totalElevatorPlatformPrice;
      } else {
        if (
          fancade.buildingSite.elevatorPlatformHours &&
          fancade.buildingSite.elevatorPlatformHourPrice
        )
          toReturn +=
            +fancade.buildingSite.elevatorPlatformHours *
            +fancade.buildingSite.elevatorPlatformHourPrice;
      }

      //Aerial platforms
      if (
        !fancade.buildingSite.totalAerialPlatformPriceCalculation ||
        fancade.buildingSite.totalAerialPlatformPriceCalculation == 0
      ) {
        if (fancade.buildingSite.totalAerialPlatformPrice)
          toReturn += +fancade.buildingSite.totalAerialPlatformPrice;
      } else {
        if (
          fancade.buildingSite.aerialPlatformHours &&
          fancade.buildingSite.aerialPlatformHourPrice
        )
          toReturn +=
            +fancade.buildingSite.aerialPlatformHours *
            +fancade.buildingSite.aerialPlatformHourPrice;
      }

      //Scaffolding
      toReturn += +calculateScaffoldingTotal(fancade);

      //Aerial platforms
      if (
        !fancade.buildingSite.totalOtherPriceCalculation ||
        fancade.buildingSite.totalOtherPriceCalculation == 0
      ) {
        if (fancade.buildingSite.totalOtherPrice)
          toReturn += +fancade.buildingSite.totalOtherPrice;
      } else {
        if (
          fancade.buildingSite.otherHours &&
          fancade.buildingSite.otherHourPrice
        )
          toReturn +=
            +fancade.buildingSite.otherHours *
            +fancade.buildingSite.otherHourPrice;
      }

      return toReturn;
    }
  } else {
    return 0;
  }
};

export const calculateSecurityTotal = (fancade: Fancade): number => {
  if (fancade.buildingSite) {
    if (
      !fancade.buildingSite.totalSecurityPriceCalculation ||
      fancade.buildingSite.totalSecurityPriceCalculation == 0
    ) {
      if (fancade.buildingSite.totalSecurityPrice)
        return fancade.buildingSite.totalSecurityPrice;
      return 0;
    } else {
      let toReturn = 0;

      //Staff security
      if (
        !fancade.buildingSite.totalStaffSecurityPriceCalculation ||
        fancade.buildingSite.totalStaffSecurityPriceCalculation == 0
      ) {
        if (fancade.buildingSite.totalStaffSecurityPrice)
          toReturn += +fancade.buildingSite.totalStaffSecurityPrice;
      } else {
        if (
          fancade.buildingSite.staffSecurityHours &&
          fancade.buildingSite.staffSecurityHourPrice
        )
          toReturn +=
            +fancade.buildingSite.staffSecurityHours *
            +fancade.buildingSite.staffSecurityHourPrice;
      }

      //Staff
      if (
        !fancade.buildingSite.totalStaffBuildingSitePriceCalculation ||
        fancade.buildingSite.totalStaffBuildingSitePriceCalculation == 0
      ) {
        if (fancade.buildingSite.totalStaffBuildingSitePrice)
          toReturn += +fancade.buildingSite.totalStaffBuildingSitePrice;
      } else {
        if (
          fancade.buildingSite.staffBuildingSiteHours &&
          fancade.buildingSite.staffBuildingSiteHourPrice
        )
          toReturn +=
            +fancade.buildingSite.staffBuildingSiteHours *
            +fancade.buildingSite.staffBuildingSiteHourPrice;
      }

      fancade.buildingSite.taxes?.map((tax) => {
        if (tax) toReturn += +tax;
      });

      if (fancade.buildingSite.other) toReturn += +fancade.buildingSite.other;

      return toReturn;
    }
  } else {
    return 0;
  }
};

//---------------Service of Life---------------
export const calculateServiceOfLifeTotal = (fancade: Fancade): number => {
  if (fancade.serviceLife) {
    let totalServiceLifePrice = fancade.serviceLife.annualLifeCost;
    if (
      !fancade.serviceLife.annualLifeCostCalculation ||
      fancade.serviceLife.annualLifeCostCalculation == 0
    ) {
      if (totalServiceLifePrice) return +totalServiceLifePrice;
      return 0;
    } else {
      totalServiceLifePrice = 0;
      totalServiceLifePrice +=
        +calculateServiceOfLifeIntegratedImplantsTotal(fancade);
      totalServiceLifePrice +=
        +calculateServiceOfLifeOtherImplantsTotal(fancade);
      return totalServiceLifePrice;
    }
  } else {
    return 0;
  }
};

export const calculateServiceOfLifeIntegratedImplantsTotal = (
  fancade: Fancade
): number => {
  if (fancade.serviceLife) {
    let annualIntegratedImplantsCost =
      fancade.serviceLife.annualIntegratedImplantsCost;
    if (
      !fancade.serviceLife.annualIntegratedImplantsCostCalculation ||
      fancade.serviceLife.annualIntegratedImplantsCostCalculation == 0
    ) {
      if (annualIntegratedImplantsCost) return +annualIntegratedImplantsCost;
      return 0;
    } else {
      annualIntegratedImplantsCost = 0;

      //Shieldings
      if (
        !fancade.serviceLife.annualShieldingCostsCalculation ||
        fancade.serviceLife.annualShieldingCostsCalculation == 0
      ) {
        if (fancade.serviceLife.annualShieldingsCost)
          annualIntegratedImplantsCost +=
            +fancade.serviceLife.annualShieldingsCost;
      } else {
        if (
          fancade.fancadeTechnicalProject.shieldingSystemsNumber &&
          fancade.serviceLife.annualShieldingsConsume &&
          fancade.serviceLife.energeticShieldingVectorUnitPrice
        )
          annualIntegratedImplantsCost +=
            +fancade.fancadeTechnicalProject.shieldingSystemsNumber *
            +fancade.serviceLife.annualShieldingsConsume *
            +fancade.serviceLife.energeticShieldingVectorUnitPrice;
      }

      //Ventilation
      if (
        !fancade.serviceLife.annualVentilationCostsCalculation ||
        fancade.serviceLife.annualVentilationCostsCalculation == 0
      ) {
        if (fancade.serviceLife.annualVentilationCost)
          annualIntegratedImplantsCost +=
            +fancade.serviceLife.annualVentilationCost;
      } else {
        if (
          fancade.fancadeTechnicalProject.ventilationDevicesNumber &&
          fancade.serviceLife.annualVentilationConsume &&
          fancade.serviceLife.energeticVentilationVectorUnitPrice
        )
          annualIntegratedImplantsCost +=
            +fancade.fancadeTechnicalProject.ventilationDevicesNumber *
            +fancade.serviceLife.annualVentilationConsume *
            +fancade.serviceLife.energeticVentilationVectorUnitPrice;
      }

      //Air conditioning
      if (
        !fancade.serviceLife.annualConditioningCostsCalculation ||
        fancade.serviceLife.annualConditioningCostsCalculation == 0
      ) {
        if (fancade.serviceLife.annualConditioningCost)
          annualIntegratedImplantsCost +=
            +fancade.serviceLife.annualConditioningCost;
      } else {
        if (
          fancade.fancadeTechnicalProject.airConditioningDevicesNumber &&
          fancade.serviceLife.annualConditioningConsume &&
          fancade.serviceLife.energeticConditioningVectorUnitPrice
        )
          annualIntegratedImplantsCost +=
            +fancade.fancadeTechnicalProject.airConditioningDevicesNumber *
            +fancade.serviceLife.annualConditioningConsume *
            +fancade.serviceLife.energeticConditioningVectorUnitPrice;
      }

      //Thermic solar
      if (
        !fancade.serviceLife.annualThermicSolarCostsCalculation ||
        fancade.serviceLife.annualThermicSolarCostsCalculation == 0
      ) {
        if (fancade.serviceLife.annualThermicSolarCost)
          annualIntegratedImplantsCost +=
            +fancade.serviceLife.annualThermicSolarCost;
      } else {
        if (
          fancade.fancadeTechnicalProject.thermicSolarPanelsNumber &&
          fancade.serviceLife.annualThermicSolarConsume &&
          fancade.serviceLife.energeticThermicSolarVectorUnitPrice
        )
          annualIntegratedImplantsCost +=
            +fancade.fancadeTechnicalProject.thermicSolarPanelsNumber *
            +fancade.serviceLife.annualThermicSolarConsume *
            +fancade.serviceLife.energeticThermicSolarVectorUnitPrice;
      }

      //Photovoltaic
      if (fancade.serviceLife.annualPhotovoltaicIsActive) {
        if (
          !fancade.serviceLife.annualPhotovoltaicCostsCalculation ||
          fancade.serviceLife.annualPhotovoltaicCostsCalculation == 0
        ) {
          if (fancade.serviceLife.annualPhotovoltaicCost)
            annualIntegratedImplantsCost -=
              +fancade.serviceLife.annualPhotovoltaicCost;
        } else {
          if (
            fancade.fancadeTechnicalProject.photovoltaicModulesNumber &&
            fancade.serviceLife.annualPhotovoltaicConsume &&
            fancade.serviceLife.energeticPhotovoltaicVectorUnitPrice
          )
            annualIntegratedImplantsCost -=
              +fancade.fancadeTechnicalProject.photovoltaicModulesNumber *
              +fancade.serviceLife.annualPhotovoltaicConsume *
              +fancade.serviceLife.energeticPhotovoltaicVectorUnitPrice;
        }
      }

      return annualIntegratedImplantsCost;
    }
  } else {
    return 0;
  }
};

export const calculateServiceOfLifeOtherImplantsTotal = (
  fancade: Fancade
): number => {
  if (fancade.serviceLife) {
    let annualOtherImplantsCost = fancade.serviceLife.annualOtherImplantsCost;
    if (
      !fancade.serviceLife.annualOtherImplantsCostCalculation ||
      fancade.serviceLife.annualOtherImplantsCostCalculation == 0
    ) {
      if (annualOtherImplantsCost) return +annualOtherImplantsCost;
      return 0;
    } else {
      annualOtherImplantsCost = 0;

      fancade.serviceLife.implants.map((implant) => {
        if (
          !implant.annualImplantCostCalculation ||
          implant.annualImplantCostCalculation == 0
        ) {
          if (implant.annualImplantCost && annualOtherImplantsCost != null)
            annualOtherImplantsCost += +implant.annualImplantCost;
        } else {
          if (
            implant.annualImplantConsume &&
            implant.energeticImplantVectorUnitPrice &&
            annualOtherImplantsCost
          ) {
            annualOtherImplantsCost +=
              +implant.annualImplantConsume *
              implant.energeticImplantVectorUnitPrice;
          }
        }
      });

      return annualOtherImplantsCost;
    }
  } else {
    return 0;
  }
};

//---------------Maintenance---------------
export const calculateMaintenanceTotal = (fancade: Fancade): number => {
  if (fancade.maintenance) {
    let totalMaintenancePrice = fancade.maintenance.totalMaintenancePrice;
    if (
      !fancade.maintenance.totalMaintenancePriceCalculation ||
      fancade.maintenance.totalMaintenancePriceCalculation == 0
    ) {
      if (totalMaintenancePrice) return +totalMaintenancePrice;
      return 0;
    } else if (
      fancade.maintenance.totalMaintenancePriceCalculation &&
      fancade.maintenance.totalMaintenancePriceCalculation == 1
    ) {
      if (
        fancade.maintenance.totalMaintenancePercentage &&
        calculateProductionTotal(fancade)
      ) {
        return (
          (+calculateProductionTotal(fancade) *
            +fancade.maintenance.totalMaintenancePercentage) /
          100
        );
      } else {
        return 0;
      }
    } else {
      totalMaintenancePrice = 0;
      totalMaintenancePrice += +calculateOrdinaryMaintenance(fancade);
      totalMaintenancePrice += +calculateExtraordinaryMaintenance(fancade);
      totalMaintenancePrice += +calculateCleaningMaintenance(fancade);

      return totalMaintenancePrice;
    }
  } else {
    return 0;
  }
};

export const calculateExtraordinaryMaintenance = (fancade: Fancade): number => {
  let toReturn = 0;
  if (
    fancade.maintenance &&
    (!fancade.maintenance.totalExtraordinaryMaintenancePriceCalculation ||
      fancade.maintenance.totalExtraordinaryMaintenancePriceCalculation == 0)
  ) {
    if (fancade.maintenance.totalExtraordinaryMaintenancePrice)
      return fancade.maintenance.totalExtraordinaryMaintenancePrice;
  } else if (
    fancade.maintenance &&
    fancade.maintenance.totalExtraordinaryMaintenancePriceCalculation &&
    fancade.maintenance.totalExtraordinaryMaintenancePriceCalculation == 1
  ) {
    if (
      fancade.maintenance.totalExtraordinaryMaintenancePercentage &&
      calculateProductionTotal(fancade)
    ) {
      return (
        (+calculateProductionTotal(fancade) *
          +fancade.maintenance.totalExtraordinaryMaintenancePercentage) /
        100
      );
    }
  } else if (
    fancade.maintenance &&
    fancade.maintenance.totalExtraordinaryMaintenancePriceCalculation &&
    fancade.maintenance.totalExtraordinaryMaintenancePriceCalculation == 2
  ) {
    fancade.maintenance.actions?.map((action: Action) => {
      if (action && action.value && action.everyHowManyYears)
        toReturn += +calculateMaintenancePeriodCost(
          action.periodCalculation,
          +action.everyHowManyYears,
          undefined,
          undefined,
          +action.value
        );
    });
  }
  return toReturn;
};

export const calculateOrdinaryMaintenance = (fancade: Fancade): number => {
  let toReturn = 0;
  if (
    fancade.maintenance &&
    fancade.maintenance.totalMaintenancePriceCalculation &&
    fancade.maintenance.totalMaintenancePriceCalculation == 2
  ) {
    if (
      !fancade.maintenance.totalOrdinaryMaintenancePriceCalculation ||
      fancade.maintenance.totalOrdinaryMaintenancePriceCalculation == 0
    ) {
      if (
        fancade.maintenance.totalOrdinaryMaintenancePrice &&
        fancade.maintenance.totalOrdinaryMaintenanceEveryHowManyYears
      )
        toReturn = +calculateMaintenancePeriodCost(
          fancade.maintenance.totalOrdinaryMaintenancePeriodCalculation,
          +fancade.maintenance.totalOrdinaryMaintenanceEveryHowManyYears,
          undefined,
          undefined,
          +fancade.maintenance.totalOrdinaryMaintenancePrice
        );
      else return 0;
    } else if (
      fancade.maintenance.totalOrdinaryMaintenancePriceCalculation &&
      fancade.maintenance.totalOrdinaryMaintenancePriceCalculation == 2
    ) {
      if (
        fancade.fancadeTechnicalProject &&
        fancade.fancadeTechnicalProject.type?.toLowerCase().indexOf("opaca") !==
          -1
      ) {
        toReturn +=
          +calculateOpaqueOrdinaryMaintenance(fancade) +
          +calculateSystemsIntegrationsMaintenance(fancade);
      } else if (
        fancade.fancadeTechnicalProject &&
        fancade.fancadeTechnicalProject.type
          ?.toLowerCase()
          .indexOf("trasparente") != -1
      ) {
        toReturn +=
          +calculateTransparentOrdinaryMaintenance(fancade) +
          +calculateSystemsIntegrationsMaintenance(fancade);
      }
    } else if (
      fancade.maintenance.totalOrdinaryMaintenancePriceCalculation &&
      fancade.maintenance.totalOrdinaryMaintenancePriceCalculation == 1
    ) {
      if (
        fancade.maintenance.totalOrdinaryMaintenancePercentage &&
        calculateProductionTotal(fancade)
      ) {
        toReturn =
          (+calculateProductionTotal(fancade) *
            +fancade.maintenance.totalOrdinaryMaintenancePercentage) /
          100;
      }
    } else {
      if (
        fancade.maintenance.totalOrdinaryMaintenancePrice &&
        fancade.maintenance.totalOrdinaryMaintenanceEveryHowManyYears
      )
        toReturn = +calculateMaintenancePeriodCost(
          fancade.maintenance.totalOrdinaryMaintenancePeriodCalculation,
          +fancade.maintenance.totalOrdinaryMaintenanceEveryHowManyYears,
          undefined,
          undefined,
          +fancade.maintenance.totalOrdinaryMaintenancePrice
        );
    }
  }

  return toReturn;
};

export const calculateOpaqueOrdinaryMaintenance = (
  fancade: Fancade
): number => {
  let toReturn = 0;
  if (
    fancade.maintenance &&
    fancade.maintenance.totalMaintenancePriceCalculation &&
    fancade.maintenance.totalMaintenancePriceCalculation == 2
  ) {
    //Windows
    if (
      !fancade.maintenance.totalWindowReplacementPriceCalculation ||
      fancade.maintenance.totalWindowReplacementPriceCalculation == 0
    ) {
      if (
        fancade.maintenance.totalWindowReplacementPrice &&
        fancade.maintenance.windowEveryHowManyYears
      )
        toReturn += +calculateMaintenancePeriodCost(
          fancade.maintenance.windowPeriodCalculation,
          +fancade.maintenance.windowEveryHowManyYears,
          undefined,
          undefined,
          +fancade.maintenance.totalWindowReplacementPrice
        );
    } else {
      if (
        fancade.maintenance.windowsNumber &&
        fancade.maintenance.windowUnitPrice &&
        fancade.maintenance.windowEveryHowManyYears
      )
        toReturn += +calculateMaintenancePeriodCost(
          fancade.maintenance.windowPeriodCalculation,
          +fancade.maintenance.windowEveryHowManyYears,
          +fancade.maintenance.windowUnitPrice,
          +fancade.maintenance.windowsNumber,
          undefined
        );
    }

    //Loom or fixings
    if (
      !fancade.maintenance.totalOpaqueLoomFixingsReplacementPriceCalculation ||
      fancade.maintenance.totalOpaqueLoomFixingsReplacementPriceCalculation == 0
    ) {
      if (
        fancade.maintenance.totalOpaqueLoomFixingsReplacementPrice &&
        fancade.maintenance.opaqueLoomFixingsEveryHowManyYears
      )
        toReturn += +calculateMaintenancePeriodCost(
          fancade.maintenance.opaqueLoomFixingsPeriodCalculation,
          +fancade.maintenance.opaqueLoomFixingsEveryHowManyYears,
          undefined,
          undefined,
          +fancade.maintenance.totalOpaqueLoomFixingsReplacementPrice
        );
    } else {
      if (
        fancade.maintenance.opaqueLoomFixingsQuantity &&
        fancade.maintenance.opaqueLoomFixingsUnitPrice &&
        fancade.maintenance.opaqueLoomFixingsEveryHowManyYears
      )
        toReturn += +calculateMaintenancePeriodCost(
          fancade.maintenance.windowPeriodCalculation,
          +fancade.maintenance.opaqueLoomFixingsEveryHowManyYears,
          +fancade.maintenance.opaqueLoomFixingsUnitPrice,
          +fancade.maintenance.opaqueLoomFixingsQuantity,
          undefined
        );
    }

    //Opaque Double Glazing
    if (
      !fancade.maintenance
        .totalOpaqueDoubleGlazingReplacementPriceCalculation ||
      fancade.maintenance.totalOpaqueDoubleGlazingReplacementPriceCalculation ==
        0
    ) {
      if (
        fancade.maintenance.totalOpaqueDoubleGlazingReplacementPrice &&
        fancade.maintenance.opaqueDoubleGlazingEveryHowManyYears
      )
        toReturn += +calculateMaintenancePeriodCost(
          fancade.maintenance.opaqueDoubleGlazingPeriodCalculation,
          +fancade.maintenance.opaqueDoubleGlazingEveryHowManyYears,
          undefined,
          undefined,
          +fancade.maintenance.totalOpaqueDoubleGlazingReplacementPrice
        );
    } else {
      if (
        fancade.maintenance.opaqueDoubleGlazingQuantity &&
        fancade.maintenance.opaqueDoubleGlazingUnitPrice &&
        fancade.maintenance.opaqueDoubleGlazingEveryHowManyYears
      )
        toReturn += +calculateMaintenancePeriodCost(
          fancade.maintenance.opaqueDoubleGlazingPeriodCalculation,
          +fancade.maintenance.opaqueDoubleGlazingEveryHowManyYears,
          +fancade.maintenance.opaqueDoubleGlazingUnitPrice,
          +fancade.maintenance.opaqueDoubleGlazingQuantity,
          undefined
        );
    }

    //Opaque shieldings
    if (
      !fancade.maintenance.totalOpaqueShieldingsReplacementPriceCalculation ||
      fancade.maintenance.totalOpaqueShieldingsReplacementPriceCalculation == 0
    ) {
      if (
        fancade.maintenance.totalOpaqueShieldingsReplacementPrice &&
        fancade.maintenance.opaqueShieldingEveryHowManyYears
      )
        toReturn += +calculateMaintenancePeriodCost(
          fancade.maintenance.opaqueShieldingPeriodCalculation,
          +fancade.maintenance.opaqueShieldingEveryHowManyYears,
          undefined,
          undefined,
          +fancade.maintenance.totalOpaqueShieldingsReplacementPrice
        );
    } else {
      if (
        fancade.maintenance.opaqueShieldingsQuantity &&
        fancade.maintenance.opaqueShieldingsUnitPrice &&
        fancade.maintenance.opaqueShieldingEveryHowManyYears
      )
        toReturn += +calculateMaintenancePeriodCost(
          fancade.maintenance.opaqueShieldingPeriodCalculation,
          +fancade.maintenance.opaqueShieldingEveryHowManyYears,
          +fancade.maintenance.opaqueShieldingsUnitPrice,
          +fancade.maintenance.opaqueShieldingsQuantity,
          undefined
        );
    }

    //External cladding
    if (
      !fancade.maintenance.totalExternalCladdingReplacementPriceCalculation ||
      fancade.maintenance.totalExternalCladdingReplacementPriceCalculation == 0
    ) {
      if (
        fancade.maintenance.totalExternalCladdingReplacementPrice &&
        fancade.maintenance.externalCladdingEveryHowManyYears
      )
        toReturn += +calculateMaintenancePeriodCost(
          fancade.maintenance.externalCladdingPeriodCalculation,
          +fancade.maintenance.externalCladdingEveryHowManyYears,
          undefined,
          undefined,
          +fancade.maintenance.totalExternalCladdingReplacementPrice
        );
    } else {
      if (
        fancade.maintenance.externalCladdingQuantity &&
        fancade.maintenance.externalCladdingUnitPrice &&
        fancade.maintenance.externalCladdingEveryHowManyYears
      )
        toReturn += +calculateMaintenancePeriodCost(
          fancade.maintenance.externalCladdingPeriodCalculation,
          +fancade.maintenance.externalCladdingEveryHowManyYears,
          +fancade.maintenance.externalCladdingUnitPrice,
          +fancade.maintenance.externalCladdingQuantity,
          undefined
        );
    }

    //Insulation
    if (
      !fancade.maintenance.totalInsulationReplacementPriceCalculation ||
      fancade.maintenance.totalInsulationReplacementPriceCalculation == 0
    ) {
      if (
        fancade.maintenance.totalInsulationReplacementPrice &&
        fancade.maintenance.insulationEveryHowManyYears
      )
        toReturn += +calculateMaintenancePeriodCost(
          fancade.maintenance.insulationPeriodCalculation,
          +fancade.maintenance.insulationEveryHowManyYears,
          undefined,
          undefined,
          +fancade.maintenance.totalInsulationReplacementPrice
        );
    } else {
      if (
        fancade.maintenance.insulationSurface &&
        fancade.maintenance.insulationUnitPrice &&
        fancade.maintenance.insulationEveryHowManyYears
      )
        toReturn += +calculateMaintenancePeriodCost(
          fancade.maintenance.insulationPeriodCalculation,
          +fancade.maintenance.insulationEveryHowManyYears,
          +fancade.maintenance.insulationUnitPrice,
          +fancade.maintenance.insulationSurface,
          undefined
        );
    }

    //Supporting elements
    if (
      !fancade.maintenance.totalSupportingElementsReplacementPriceCalculation ||
      fancade.maintenance.totalSupportingElementsReplacementPriceCalculation ==
        0
    ) {
      if (
        fancade.maintenance.totalSupportingElementsReplacementPrice &&
        fancade.maintenance.supportingElementsEveryHowManyYears
      )
        toReturn += +calculateMaintenancePeriodCost(
          fancade.maintenance.supportingElementsPeriodCalculation,
          +fancade.maintenance.supportingElementsEveryHowManyYears,
          undefined,
          undefined,
          +fancade.maintenance.totalSupportingElementsReplacementPrice
        );
    } else {
      if (
        fancade.maintenance.supportingElementsQuantity &&
        fancade.maintenance.supportingElementUnitPrice &&
        fancade.maintenance.supportingElementsEveryHowManyYears
      )
        toReturn += +calculateMaintenancePeriodCost(
          fancade.maintenance.supportingElementsPeriodCalculation,
          +fancade.maintenance.supportingElementsEveryHowManyYears,
          +fancade.maintenance.supportingElementUnitPrice,
          +fancade.maintenance.supportingElementsQuantity,
          undefined
        );
    }

    //Prefabricated modules
    if (
      !fancade.maintenance
        .totalPrefabricatedModulesReplacementPriceCalculation ||
      fancade.maintenance
        .totalPrefabricatedModulesReplacementPriceCalculation == 0
    ) {
      if (
        fancade.maintenance.totalPrefabricatedModulesReplacementPrice &&
        fancade.maintenance.prefabricatedModulesEveryHowManyYears
      )
        toReturn += +calculateMaintenancePeriodCost(
          fancade.maintenance.prefabricatedModulesPeriodCalculation,
          +fancade.maintenance.prefabricatedModulesEveryHowManyYears,
          undefined,
          undefined,
          +fancade.maintenance.totalPrefabricatedModulesReplacementPrice
        );
    } else {
      if (
        fancade.maintenance.prefabricatedModulesQuantity &&
        fancade.maintenance.prefabricatedModulesUnitPrice &&
        fancade.maintenance.prefabricatedModulesEveryHowManyYears
      )
        toReturn += +calculateMaintenancePeriodCost(
          fancade.maintenance.prefabricatedModulesPeriodCalculation,
          +fancade.maintenance.prefabricatedModulesEveryHowManyYears,
          +fancade.maintenance.prefabricatedModulesUnitPrice,
          +fancade.maintenance.prefabricatedModulesQuantity,
          undefined
        );
    }
  }
  return toReturn;
};

export const calculateTransparentOrdinaryMaintenance = (
  fancade: Fancade
): number => {
  let toReturn = 0;
  if (
    fancade.maintenance &&
    fancade.maintenance.totalMaintenancePriceCalculation &&
    fancade.maintenance.totalMaintenancePriceCalculation == 2
  ) {
    //Glazed Cells
    if (
      !fancade.maintenance.totalGlazedCellsReplacementPriceCalculation ||
      fancade.maintenance.totalGlazedCellsReplacementPriceCalculation == 0
    ) {
      if (
        fancade.maintenance.totalGlazedCellsReplacementPrice &&
        fancade.maintenance.glazedCellsEveryHowManyYears
      )
        toReturn += +calculateMaintenancePeriodCost(
          fancade.maintenance.glazedCellsPeriodCalculation,
          +fancade.maintenance.glazedCellsEveryHowManyYears,
          undefined,
          undefined,
          +fancade.maintenance.totalGlazedCellsReplacementPrice
        );
    } else {
      if (
        fancade.maintenance.glazedCellsNumber &&
        fancade.maintenance.glazedCellUnitPrice &&
        fancade.maintenance.glazedCellsEveryHowManyYears
      )
        toReturn += +calculateMaintenancePeriodCost(
          fancade.maintenance.glazedCellsPeriodCalculation,
          +fancade.maintenance.glazedCellsEveryHowManyYears,
          +fancade.maintenance.glazedCellUnitPrice,
          +fancade.maintenance.glazedCellsNumber,
          undefined
        );
    }

    //Double Glazing
    if (
      !fancade.maintenance.totalDoubleGlazingReplacementPriceCalculation ||
      fancade.maintenance.totalDoubleGlazingReplacementPriceCalculation == 0
    ) {
      if (
        fancade.maintenance.totalDoubleGlazingReplacementPrice &&
        fancade.maintenance.doubleGlazingEveryHowManyYears
      )
        toReturn += +calculateMaintenancePeriodCost(
          fancade.maintenance.doubleGlazingPeriodCalculation,
          +fancade.maintenance.doubleGlazingEveryHowManyYears,
          undefined,
          undefined,
          +fancade.maintenance.totalDoubleGlazingReplacementPrice
        );
    } else {
      if (
        fancade.maintenance.doubleGlazingQuantity &&
        fancade.maintenance.doubleGlazingUnitPrice &&
        fancade.maintenance.doubleGlazingEveryHowManyYears
      )
        toReturn += +calculateMaintenancePeriodCost(
          fancade.maintenance.doubleGlazingPeriodCalculation,
          +fancade.maintenance.doubleGlazingEveryHowManyYears,
          +fancade.maintenance.doubleGlazingUnitPrice,
          +fancade.maintenance.doubleGlazingQuantity,
          undefined
        );
    }

    //Loom or Fixings
    if (
      !fancade.maintenance
        .totalTransparentLoomFixingsReplacementPriceCalculation ||
      fancade.maintenance
        .totalTransparentLoomFixingsReplacementPriceCalculation == 0
    ) {
      if (
        fancade.maintenance.totalTransparentLoomFixingsReplacementPrice &&
        fancade.maintenance.transparentLoomFixingsEveryHowManyYears
      )
        toReturn += +calculateMaintenancePeriodCost(
          fancade.maintenance.transparentLoomPeriodCalculation,
          +fancade.maintenance.transparentLoomFixingsEveryHowManyYears,
          undefined,
          undefined,
          +fancade.maintenance.totalTransparentLoomFixingsReplacementPrice
        );
    } else {
      if (
        fancade.maintenance.transparentLoomFixingsQuantity &&
        fancade.maintenance.transparentLoomFixingsUnitPrice &&
        fancade.maintenance.transparentLoomFixingsEveryHowManyYears
      )
        toReturn += +calculateMaintenancePeriodCost(
          fancade.maintenance.transparentLoomPeriodCalculation,
          +fancade.maintenance.transparentLoomFixingsEveryHowManyYears,
          +fancade.maintenance.transparentLoomFixingsUnitPrice,
          +fancade.maintenance.transparentLoomFixingsQuantity,
          undefined
        );
    }

    //Shieldings
    if (
      !fancade.maintenance
        .totalTransparentShieldingsReplacementPriceCalculation ||
      fancade.maintenance
        .totalTransparentShieldingsReplacementPriceCalculation == 0
    ) {
      if (
        fancade.maintenance.totalTransparentShieldingsReplacementPrice &&
        fancade.maintenance.transparentShieldingsEveryHowManyYears
      )
        toReturn += +calculateMaintenancePeriodCost(
          fancade.maintenance.transparentShieldingsPeriodCalculation,
          +fancade.maintenance.transparentShieldingsEveryHowManyYears,
          undefined,
          undefined,
          +fancade.maintenance.totalTransparentShieldingsReplacementPrice
        );
    } else {
      if (
        fancade.maintenance.transparentShieldingsQuantity &&
        fancade.maintenance.transparentShieldingsUnitPrice &&
        fancade.maintenance.transparentShieldingsEveryHowManyYears
      )
        toReturn += +calculateMaintenancePeriodCost(
          fancade.maintenance.transparentShieldingsPeriodCalculation,
          +fancade.maintenance.transparentShieldingsEveryHowManyYears,
          +fancade.maintenance.transparentShieldingsUnitPrice,
          +fancade.maintenance.transparentShieldingsQuantity,
          undefined
        );
    }
  }
  return toReturn;
};

export const calculateSystemsIntegrationsMaintenance = (
  fancade: Fancade
): number => {
  let toReturn = 0;
  if (
    fancade.maintenance &&
    fancade.maintenance.totalMaintenancePriceCalculation &&
    fancade.maintenance.totalMaintenancePriceCalculation == 2
  ) {
    //Photovoltaic
    if (
      !fancade.maintenance
        .totalPhotovoltaicModulesReplacementPriceCalculation ||
      fancade.maintenance.totalPhotovoltaicModulesReplacementPriceCalculation ==
        0
    ) {
      if (
        fancade.maintenance.totalPhotovoltaicModulesReplacementPrice &&
        fancade.maintenance.photovoltaicModulesEveryHowManyYears
      )
        toReturn += +calculateMaintenancePeriodCost(
          fancade.maintenance.photovoltaicModulesPeriodCalculation,
          +fancade.maintenance.photovoltaicModulesEveryHowManyYears,
          undefined,
          undefined,
          +fancade.maintenance.totalPhotovoltaicModulesReplacementPrice
        );
    } else {
      if (
        fancade.maintenance.photovoltaicModulesNumber &&
        fancade.maintenance.photovoltaicModuleUnitPrice &&
        fancade.maintenance.photovoltaicModulesEveryHowManyYears
      )
        toReturn += +calculateMaintenancePeriodCost(
          fancade.maintenance.photovoltaicModulesPeriodCalculation,
          +fancade.maintenance.photovoltaicModulesEveryHowManyYears,
          +fancade.maintenance.photovoltaicModuleUnitPrice,
          +fancade.maintenance.photovoltaicModulesNumber,
          undefined
        );
    }

    //Solar Panels
    if (
      !fancade.maintenance.totalSolarPanelsReplacementPriceCalculation ||
      fancade.maintenance.totalSolarPanelsReplacementPriceCalculation == 0
    ) {
      if (
        fancade.maintenance.totalSolarPanelsReplacementPrice &&
        fancade.maintenance.solarPanelsEveryHowManyYears
      )
        toReturn += +calculateMaintenancePeriodCost(
          fancade.maintenance.solarPanelsPeriodCalculation,
          +fancade.maintenance.solarPanelsEveryHowManyYears,
          undefined,
          undefined,
          +fancade.maintenance.totalSolarPanelsReplacementPrice
        );
    } else {
      if (
        fancade.maintenance.solarPanelsNumber &&
        fancade.maintenance.solarPanelUnitPrice &&
        fancade.maintenance.solarPanelsEveryHowManyYears
      )
        toReturn += +calculateMaintenancePeriodCost(
          fancade.maintenance.solarPanelsPeriodCalculation,
          +fancade.maintenance.solarPanelsEveryHowManyYears,
          +fancade.maintenance.solarPanelUnitPrice,
          +fancade.maintenance.solarPanelsNumber,
          undefined
        );
    }

    //Ventilation
    if (
      !fancade.maintenance.totalVentilationDevicesReplacementPriceCalculation ||
      fancade.maintenance.totalVentilationDevicesReplacementPriceCalculation ==
        0
    ) {
      if (
        fancade.maintenance.totalVentilationDevicesReplacementPrice &&
        fancade.maintenance.ventilationDevicesEveryHowManyYears
      )
        toReturn += +calculateMaintenancePeriodCost(
          fancade.maintenance.ventilationDevicesPeriodCalculation,
          +fancade.maintenance.ventilationDevicesEveryHowManyYears,
          undefined,
          undefined,
          +fancade.maintenance.totalVentilationDevicesReplacementPrice
        );
    } else {
      if (
        fancade.maintenance.ventilationDevicesNumber &&
        fancade.maintenance.ventilationDeviceUnitPrice &&
        fancade.maintenance.ventilationDevicesEveryHowManyYears
      )
        toReturn += +calculateMaintenancePeriodCost(
          fancade.maintenance.ventilationDevicesPeriodCalculation,
          +fancade.maintenance.ventilationDevicesEveryHowManyYears,
          +fancade.maintenance.ventilationDeviceUnitPrice,
          +fancade.maintenance.ventilationDevicesNumber,
          undefined
        );
    }

    //Air Conditioning
    if (
      !fancade.maintenance
        .totalAirConditioningDevicesReplacementPriceCalculation ||
      fancade.maintenance
        .totalAirConditioningDevicesReplacementPriceCalculation == 0
    ) {
      if (
        fancade.maintenance.totalAirConditioningDevicesReplacementPrice &&
        fancade.maintenance.airConditioningDevicesEveryHowManyYears
      )
        toReturn += +calculateMaintenancePeriodCost(
          fancade.maintenance.airConditioningPeriodCalculation,
          +fancade.maintenance.airConditioningDevicesEveryHowManyYears,
          undefined,
          undefined,
          +fancade.maintenance.totalAirConditioningDevicesReplacementPrice
        );
    } else {
      if (
        fancade.maintenance.airConditioningDevicesNumber &&
        fancade.maintenance.airConditioningDeviceUnitPrice &&
        fancade.maintenance.airConditioningDevicesEveryHowManyYears
      )
        toReturn += +calculateMaintenancePeriodCost(
          fancade.maintenance.airConditioningPeriodCalculation,
          +fancade.maintenance.airConditioningDevicesEveryHowManyYears,
          +fancade.maintenance.airConditioningDeviceUnitPrice,
          +fancade.maintenance.airConditioningDevicesNumber,
          undefined
        );
    }
  }
  return toReturn;
};

export const calculateCleaningMaintenance = (fancade: Fancade): number => {
  let toReturn = 0;
  if (
    fancade.maintenance &&
    fancade.maintenance.totalMaintenancePriceCalculation &&
    fancade.maintenance.totalMaintenancePriceCalculation == 2
  ) {
    //Cleaning
    if (
      !fancade.maintenance.totalCleaningPriceCalculation ||
      fancade.maintenance.totalCleaningPriceCalculation == 0
    ) {
      if (fancade.maintenance.totalCleaningPrice)
        toReturn += +fancade.maintenance.totalCleaningPrice;
    } else {
      if (
        fancade.maintenance.cleaningsEveryHowManyYears &&
        fancade.maintenance.cleaningUnitPrice
      )
        toReturn += +calculateMaintenancePeriodCost(
          fancade.maintenance.cleaningsPeriodCalculation,
          +fancade.maintenance.cleaningsEveryHowManyYears,
          undefined,
          undefined,
          +fancade.maintenance.cleaningUnitPrice
        );
    }
  }
  return toReturn;
};

//---------------EndOfLife---------------
export const calculateDemolitionAndDisassemblyTotal = (
  fancade: Fancade
): number => {
  if (fancade.endOfLife) {
    let totalDemolitionAndDisassemblyPrice =
      fancade?.endOfLife?.totalDemolitionAndDisassemblyPrice;
    if (
      !fancade.endOfLife.totalDemolitionAndDisassemblyPriceCalculation ||
      fancade.endOfLife.totalDemolitionAndDisassemblyPriceCalculation == 0
    ) {
      if (totalDemolitionAndDisassemblyPrice)
        return +totalDemolitionAndDisassemblyPrice;
      return 0;
    } else {
      totalDemolitionAndDisassemblyPrice = 0;
      totalDemolitionAndDisassemblyPrice += +calculateDemolitionTotal(fancade);
      totalDemolitionAndDisassemblyPrice += +calculateDisassemblyTotal(fancade);

      return totalDemolitionAndDisassemblyPrice;
    }
  } else {
    return 0;
  }
};

export const calculateDemolitionTotal = (fancade: Fancade): number => {
  let toReturn = 0;
  if (
    fancade.endOfLife &&
    fancade.endOfLife.totalDemolitionAndDisassemblyPriceCalculation &&
    fancade.endOfLife.totalDemolitionAndDisassemblyPriceCalculation == 1
  ) {
    if (
      !fancade.endOfLife.totalDemolitionPriceCalculation ||
      fancade.endOfLife.totalDemolitionPriceCalculation == 0
    ) {
      if (fancade.endOfLife.totalDemolitionPrice)
        toReturn += +fancade.endOfLife.totalDemolitionPrice;
    } else {
      if (
        fancade.endOfLife.demolitionQuantity &&
        fancade.endOfLife.demolitionUnitPrice
      )
        toReturn +=
          +fancade.endOfLife.demolitionQuantity *
          +fancade.endOfLife.demolitionUnitPrice;
    }
  }

  return toReturn;
};

export const calculateDisassemblyTotal = (fancade: Fancade): number => {
  let toReturn = 0;
  if (
    fancade.endOfLife &&
    fancade.endOfLife.totalDemolitionAndDisassemblyPriceCalculation &&
    fancade.endOfLife.totalDemolitionAndDisassemblyPriceCalculation == 1
  ) {
    if (
      !fancade.endOfLife.totalDisassemblyPriceCalculation ||
      fancade.endOfLife.totalDisassemblyPriceCalculation == 0
    ) {
      if (fancade.endOfLife.totalDisassemblyPrice)
        toReturn += +fancade.endOfLife.totalDisassemblyPrice;
    } else if (
      fancade.fancadeTechnicalProject.type?.toLowerCase().indexOf("opaca") !==
      -1
    ) {
      //Windows
      if (
        !fancade.endOfLife.totalWindowsDisassemblyPriceCalculation ||
        fancade.endOfLife.totalWindowsDisassemblyPriceCalculation == 0
      ) {
        if (fancade.endOfLife.totalWindowsDisassemblyPrice)
          toReturn += +fancade.endOfLife.totalWindowsDisassemblyPrice;
      } else {
        if (
          fancade.endOfLife.windowsQuantity &&
          fancade.endOfLife.windowsUnitPrice
        )
          toReturn +=
            +fancade.endOfLife.windowsQuantity *
            +fancade.endOfLife.windowsUnitPrice;
      }

      //External cladding
      if (
        !fancade.endOfLife.totalExternalCladdingDisassemblyPriceCalculation ||
        fancade.endOfLife.totalExternalCladdingDisassemblyPriceCalculation == 0
      ) {
        if (fancade.endOfLife.totalExternalCladdingDisassemblyPrice)
          toReturn += +fancade.endOfLife.totalExternalCladdingDisassemblyPrice;
      } else {
        if (
          fancade.endOfLife.externalCladdingQuantity &&
          fancade.endOfLife.externalCladdingUnitPrice
        )
          toReturn +=
            +fancade.endOfLife.externalCladdingQuantity *
            +fancade.endOfLife.externalCladdingUnitPrice;
      }

      //Insulation
      if (
        !fancade.endOfLife.totalInsulationDisassemblyPriceCalculation ||
        fancade.endOfLife.totalInsulationDisassemblyPriceCalculation == 0
      ) {
        if (fancade.endOfLife.totalInsulationDisassemblyPrice)
          toReturn += +fancade.endOfLife.totalInsulationDisassemblyPrice;
      } else {
        if (
          fancade.endOfLife.insulationQuantity &&
          fancade.endOfLife.insulationUnitPrice
        )
          toReturn +=
            +fancade.endOfLife.insulationQuantity *
            +fancade.endOfLife.insulationUnitPrice;
      }

      //Pannello portante e fissaggio
      if (
        !fancade.endOfLife
          .totalSupportingElementsAndFixingsDisassemblyPriceCalculation ||
        fancade.endOfLife
          .totalSupportingElementsAndFixingsDisassemblyPriceCalculation == 0
      ) {
        if (fancade.endOfLife.totalSupportingElementsAndFixingsDisassemblyPrice)
          toReturn +=
            +fancade.endOfLife
              .totalSupportingElementsAndFixingsDisassemblyPrice;
      } else {
        if (
          fancade.endOfLife.supportingElementsAndFixingsQuantity &&
          fancade.endOfLife.supportingElementsAndFixingsUnitPrice
        )
          toReturn +=
            +fancade.endOfLife.supportingElementsAndFixingsQuantity *
            +fancade.endOfLife.supportingElementsAndFixingsUnitPrice;
      }
    } else if (
      fancade.fancadeTechnicalProject.type
        ?.toLowerCase()
        .indexOf("trasparente") != -1
    ) {
      //Glazed cells
      if (
        !fancade.endOfLife.totalGlazedCellsDisassemblyPriceCalculation ||
        fancade.endOfLife.totalGlazedCellsDisassemblyPriceCalculation == 0
      ) {
        if (fancade.endOfLife.totalGlazedCellsDisassemblyPrice)
          toReturn += +fancade.endOfLife.totalGlazedCellsDisassemblyPrice;
      } else {
        if (
          fancade.endOfLife.glazedCellsQuantity &&
          fancade.endOfLife.glazedCellsUnitPrice
        )
          toReturn +=
            +fancade.endOfLife.glazedCellsQuantity *
            +fancade.endOfLife.glazedCellsUnitPrice;
      }

      //Double Glazing
      if (
        !fancade.endOfLife.totalDoubleGlazingDisassemblyPriceCalculation ||
        fancade.endOfLife.totalDoubleGlazingDisassemblyPriceCalculation == 0
      ) {
        if (fancade.endOfLife.totalDoubleGlazingDisassemblyPrice)
          toReturn += +fancade.endOfLife.totalDoubleGlazingDisassemblyPrice;
      } else {
        if (
          fancade.endOfLife.doubleGlazingQuantity &&
          fancade.endOfLife.doubleGlazingUnitPrice
        )
          toReturn +=
            +fancade.endOfLife.doubleGlazingQuantity *
            +fancade.endOfLife.doubleGlazingUnitPrice;
      }

      //Montanti, traversi e fissaggi
      if (
        !fancade.endOfLife
          .totalUprightsCrosspiecesFixingsDisassemblyPriceCalculation ||
        fancade.endOfLife
          .totalUprightsCrosspiecesFixingsDisassemblyPriceCalculation == 0
      ) {
        if (fancade.endOfLife.totalUprightsCrosspiecesFixingsDisassemblyPrice)
          toReturn +=
            +fancade.endOfLife.totalUprightsCrosspiecesFixingsDisassemblyPrice;
      } else {
        if (
          fancade.endOfLife.uprightsCrosspiecesFixingsQuantity &&
          fancade.endOfLife.uprightsCrosspiecesFixingsUnitPrice
        )
          toReturn +=
            +fancade.endOfLife.uprightsCrosspiecesFixingsQuantity *
            +fancade.endOfLife.uprightsCrosspiecesFixingsUnitPrice;
      }
    }
  }

  return toReturn;
};

export const calculateEndOfLifeTransportTotal = (fancade: Fancade): number => {
  let toReturn = 0;
  if (fancade.endOfLife) {
    if (
      !fancade.endOfLife.totalTransportPriceCalculation ||
      fancade.endOfLife.totalTransportPriceCalculation == 0
    ) {
      if (fancade.endOfLife.totalTransportPrice)
        toReturn += +fancade.endOfLife.totalTransportPrice;
    } else {
      //Transposal
      if (
        !fancade.endOfLife.totalDisposalTransportPriceCalculation ||
        fancade.endOfLife.totalDisposalTransportPriceCalculation == 0
      ) {
        if (fancade.endOfLife.totalDisposalTransportPrice)
          toReturn += +fancade.endOfLife.totalDisposalTransportPrice;
      } else {
        if (
          fancade.endOfLife.disposalTransportQuantity &&
          fancade.endOfLife.disposalTransportUnitPrice
        )
          toReturn +=
            +fancade.endOfLife.disposalTransportQuantity *
            +fancade.endOfLife.disposalTransportUnitPrice;
      }

      //Recycling
      if (
        !fancade.endOfLife.totalRecyclingTransportPriceCalculation ||
        fancade.endOfLife.totalRecyclingTransportPriceCalculation == 0
      ) {
        if (fancade.endOfLife.totalRecyclingTransportPrice)
          toReturn += +fancade.endOfLife.totalRecyclingTransportPrice;
      } else {
        if (
          fancade.endOfLife.recyclingTransportQuantity &&
          fancade.endOfLife.recyclingTransportUnitPrice
        )
          toReturn +=
            +fancade.endOfLife.recyclingTransportQuantity *
            +fancade.endOfLife.recyclingTransportUnitPrice;
      }
    }
  }
  return toReturn;
};

export const calculateRecyclingTotalRevenue = (fancade: Fancade): number => {
  let toReturn = 0;
  if (fancade.endOfLife) {
    if (
      !fancade.endOfLife.totalRecyclingRevenueCalculation ||
      fancade.endOfLife.totalRecyclingRevenueCalculation == 0
    ) {
      if (fancade.endOfLife.totalRecyclingRevenue)
        toReturn += +fancade.endOfLife.totalRecyclingRevenue;
    } else {
      if (
        fancade.fancadeTechnicalProject.type?.toLowerCase().indexOf("opaca") !=
        -1
      ) {
        //Windows
        if (
          !fancade.endOfLife.totalWindowsRecyclingRevenueCalculation ||
          fancade.endOfLife.totalWindowsRecyclingRevenueCalculation == 0
        ) {
          if (fancade.endOfLife.totalWindowsRecyclingRevenue)
            toReturn += +fancade.endOfLife.totalWindowsRecyclingRevenue;
        } else {
          if (
            fancade.endOfLife.windowsRecyclingQuantity &&
            fancade.endOfLife.windowsRecyclingUnitPrice
          )
            toReturn +=
              +fancade.endOfLife.windowsRecyclingQuantity *
              +fancade.endOfLife.windowsRecyclingUnitPrice;
        }

        //External Cladding
        if (
          !fancade.endOfLife.totalExternalCladdingRevenueCalculation ||
          fancade.endOfLife.totalExternalCladdingRevenueCalculation == 0
        ) {
          if (fancade.endOfLife.totalExternalCladdingRecyclingRevenue)
            toReturn +=
              +fancade.endOfLife.totalExternalCladdingRecyclingRevenue;
        } else {
          if (
            fancade.endOfLife.externalCladdingRecyclingQuantity &&
            fancade.endOfLife.externalCladdingRecyclingUnitPrice
          )
            toReturn +=
              +fancade.endOfLife.externalCladdingRecyclingQuantity *
              +fancade.endOfLife.externalCladdingRecyclingUnitPrice;
        }

        //Insulation
        if (
          !fancade.endOfLife.totalInsulationRevenueCalculation ||
          fancade.endOfLife.totalInsulationRevenueCalculation == 0
        ) {
          if (fancade.endOfLife.totalInsulationRecyclingRevenue)
            toReturn += +fancade.endOfLife.totalInsulationRecyclingRevenue;
        } else {
          if (
            fancade.endOfLife.insulationRecyclingQuantity &&
            fancade.endOfLife.insulationRecyclingUnitPrice
          )
            toReturn +=
              +fancade.endOfLife.insulationRecyclingQuantity *
              +fancade.endOfLife.insulationRecyclingUnitPrice;
        }

        //Supporting elements and fixings
        if (
          !fancade.endOfLife
            .totalSupportingElementsAndFixingsRecyclingRevenueCalculation ||
          fancade.endOfLife
            .totalSupportingElementsAndFixingsRecyclingRevenueCalculation == 0
        ) {
          if (
            fancade.endOfLife.totalSupportingElementsAndFixingsRecyclingRevenue
          )
            toReturn +=
              +fancade.endOfLife
                .totalSupportingElementsAndFixingsRecyclingRevenue;
        } else {
          if (
            fancade.endOfLife.supportingElementsAndFixingsRecyclingQuantity &&
            fancade.endOfLife.supportingElementsAndFixingsRecyclingUnitPrice
          )
            toReturn +=
              +fancade.endOfLife.supportingElementsAndFixingsRecyclingQuantity *
              +fancade.endOfLife.supportingElementsAndFixingsRecyclingUnitPrice;
        }
      } else if (
        fancade.fancadeTechnicalProject.type
          ?.toLowerCase()
          .indexOf("trasparente") != -1
      ) {
        //Glazed cells
        if (
          !fancade.endOfLife.totalGlazedCellsRecyclingRevenueCalculation ||
          fancade.endOfLife.totalGlazedCellsRecyclingRevenueCalculation == 0
        ) {
          if (fancade.endOfLife.totalGlazedCellsRecyclingRevenue)
            toReturn += +fancade.endOfLife.totalGlazedCellsRecyclingRevenue;
        } else {
          if (
            fancade.endOfLife.glazedCellsRecyclingQuantity &&
            fancade.endOfLife.glazedCellsUnitRecyclingPrice
          )
            toReturn +=
              +fancade.endOfLife.glazedCellsRecyclingQuantity *
              +fancade.endOfLife.glazedCellsUnitRecyclingPrice;
        }

        //Double glazing
        if (
          !fancade.endOfLife.totalDoubleGlazingRecyclingRevenueCalculation ||
          fancade.endOfLife.totalDoubleGlazingRecyclingRevenueCalculation == 0
        ) {
          if (fancade.endOfLife.totalDoubleGlazingRecyclingRevenue)
            toReturn += +fancade.endOfLife.totalDoubleGlazingRecyclingRevenue;
        } else {
          if (
            fancade.endOfLife.doubleGlazingRecyclingQuantity &&
            fancade.endOfLife.doubleGlazingRecyclingUnitPrice
          )
            toReturn +=
              +fancade.endOfLife.doubleGlazingRecyclingQuantity *
              +fancade.endOfLife.doubleGlazingRecyclingUnitPrice;
        }

        //Montanti, traversi e fissaggi
        if (
          !fancade.endOfLife
            .totalUprightsCrosspiecesFixingsRecyclingRevenueCalculation ||
          fancade.endOfLife
            .totalUprightsCrosspiecesFixingsRecyclingRevenueCalculation == 0
        ) {
          if (fancade.endOfLife.totalUprightsCrosspiecesFixingsRecyclingRevenue)
            toReturn +=
              +fancade.endOfLife
                .totalUprightsCrosspiecesFixingsRecyclingRevenue;
        } else {
          if (
            fancade.endOfLife.uprightsCrosspiecesFixingsRecyclingQuantity &&
            fancade.endOfLife.uprightsCrosspiecesFixingsRecyclingUnitPrice
          )
            toReturn +=
              +fancade.endOfLife.uprightsCrosspiecesFixingsRecyclingQuantity *
              +fancade.endOfLife.uprightsCrosspiecesFixingsRecyclingUnitPrice;
        }
      }
    }
  }
  return toReturn;
};

export const calculateReUseTotalRevenue = (fancade: Fancade): number => {
  let toReturn = 0;
  if (fancade.endOfLife) {
    if (
      !fancade.endOfLife.totalReUseRevenueCalculation ||
      fancade.endOfLife.totalReUseRevenueCalculation == 0
    ) {
      if (fancade.endOfLife.totalReUseRevenue)
        toReturn += +fancade.endOfLife.totalReUseRevenue;
    } else {
      if (
        fancade.fancadeTechnicalProject.type?.toLowerCase().indexOf("opaca") !=
        -1
      ) {
        //Windows
        if (
          !fancade.endOfLife.totalWindowsReUseRevenueCalculation ||
          fancade.endOfLife.totalWindowsReUseRevenueCalculation == 0
        ) {
          if (fancade.endOfLife.totalWindowsReUseRevenue)
            toReturn += +fancade.endOfLife.totalWindowsReUseRevenue;
        } else {
          if (
            fancade.endOfLife.windowsReUseQuantity &&
            fancade.endOfLife.windowsReUseUnitPrice
          )
            toReturn +=
              +fancade.endOfLife.windowsReUseQuantity *
              +fancade.endOfLife.windowsReUseUnitPrice;
        }

        //External Cladding
        if (
          !fancade.endOfLife.totalExternalCladdingReUseRevenueCalculation ||
          fancade.endOfLife.totalExternalCladdingReUseRevenueCalculation == 0
        ) {
          if (fancade.endOfLife.totalExternalCladdingReUseRevenue)
            toReturn += +fancade.endOfLife.totalExternalCladdingReUseRevenue;
        } else {
          if (
            fancade.endOfLife.externalCladdingReUseQuantity &&
            fancade.endOfLife.externalCladdingReUseUnitPrice
          )
            toReturn +=
              +fancade.endOfLife.externalCladdingReUseQuantity *
              +fancade.endOfLife.externalCladdingReUseUnitPrice;
        }

        //Insulation
        if (
          !fancade.endOfLife.totalInsulationReUseRevenueCalculation ||
          fancade.endOfLife.totalInsulationReUseRevenueCalculation == 0
        ) {
          if (fancade.endOfLife.totalInsulationReUseRevenue)
            toReturn += +fancade.endOfLife.totalInsulationReUseRevenue;
        } else {
          if (
            fancade.endOfLife.insulationReUseQuantity &&
            fancade.endOfLife.insulationReUseUnitPrice
          )
            toReturn +=
              +fancade.endOfLife.insulationReUseQuantity *
              +fancade.endOfLife.insulationReUseUnitPrice;
        }

        //Supporting elements and fixings
        if (
          !fancade.endOfLife
            .totalSupportingElementsAndFixingsReUseRevenueCalculation ||
          fancade.endOfLife
            .totalSupportingElementsAndFixingsReUseRevenueCalculation == 0
        ) {
          if (fancade.endOfLife.totalSupportingElementsAndFixingsReUseRevenue)
            toReturn +=
              +fancade.endOfLife.totalSupportingElementsAndFixingsReUseRevenue;
        } else {
          if (
            fancade.endOfLife.supportingElementsAndFixingsReUseQuantity &&
            fancade.endOfLife.supportingElementsAndFixingsReUseUnitPrice
          )
            toReturn +=
              +fancade.endOfLife.supportingElementsAndFixingsReUseQuantity *
              +fancade.endOfLife.supportingElementsAndFixingsReUseUnitPrice;
        }
      } else if (
        fancade.fancadeTechnicalProject.type
          ?.toLowerCase()
          .indexOf("trasparente") != -1
      ) {
        //Glazed cells
        if (
          !fancade.endOfLife.totalGlazedCellsReUseRevenueCalculation ||
          fancade.endOfLife.totalGlazedCellsReUseRevenueCalculation == 0
        ) {
          if (fancade.endOfLife.totalGlazedCellsReUseRevenue)
            toReturn += +fancade.endOfLife.totalGlazedCellsReUseRevenue;
        } else {
          if (
            fancade.endOfLife.glazedCellsReUseQuantity &&
            fancade.endOfLife.glazedCellsUnitReUsePrice
          )
            toReturn +=
              +fancade.endOfLife.glazedCellsReUseQuantity *
              +fancade.endOfLife.glazedCellsUnitReUsePrice;
        }

        //Double glazing
        if (
          !fancade.endOfLife.totalDoubleGlazingReUseRevenueCalculation ||
          fancade.endOfLife.totalDoubleGlazingReUseRevenueCalculation == 0
        ) {
          if (fancade.endOfLife.totalDoubleGlazingReUseRevenue)
            toReturn += +fancade.endOfLife.totalDoubleGlazingReUseRevenue;
        } else {
          if (
            fancade.endOfLife.doubleGlazingReUseQuantity &&
            fancade.endOfLife.doubleGlazingReUseUnitPrice
          )
            toReturn +=
              +fancade.endOfLife.doubleGlazingReUseQuantity *
              +fancade.endOfLife.doubleGlazingReUseUnitPrice;
        }

        //Montanti, traversi e fissaggi
        if (
          !fancade.endOfLife
            .totalUprightsCrosspiecesFixingsReUseRevenueCalculation ||
          fancade.endOfLife
            .totalUprightsCrosspiecesFixingsReUseRevenueCalculation == 0
        ) {
          if (fancade.endOfLife.totalUprightsCrosspiecesFixingsReUseRevenue)
            toReturn +=
              +fancade.endOfLife.totalUprightsCrosspiecesFixingsReUseRevenue;
        } else {
          if (
            fancade.endOfLife.uprightsCrosspiecesFixingsReUseQuantity &&
            fancade.endOfLife.uprightsCrosspiecesFixingsReUseUnitPrice
          )
            toReturn +=
              +fancade.endOfLife.uprightsCrosspiecesFixingsReUseQuantity *
              +fancade.endOfLife.uprightsCrosspiecesFixingsReUseUnitPrice;
        }
      }
    }
  }
  return toReturn;
};

//---------------Costs actualization---------------
export const calculateCostOfLifeActualization = (
  year: number,
  fancade: Fancade
): number => {
  const interestRate = fancade.costsActualization.interestRate || 0;
  const annualLifeCost = calculateServiceOfLifeTotal(fancade);

  if (year == 0) return 0;
  if (!fancade.costsActualization.priceVectorVariation)
    fancade.costsActualization.priceVectorVariation = 0;
  return (
    (+annualLifeCost +
      +annualLifeCost *
        (+fancade.costsActualization.priceVectorVariation / 100)) /
    Math.pow(1 + +interestRate / 100, year)
  );
};

export const calculateMaintenanceCostActualization = (
  year: number,
  fancade: Fancade
): number => {
  const interestRate = fancade.costsActualization.interestRate;
  const annualMaintenanceCost = calculateMaintenanceTotal(fancade);

  if (year == 0 || !interestRate) return 0;
  if (!fancade.costsActualization.priceVectorVariation)
    fancade.costsActualization.priceVectorVariation = 0;
  return (
    (+annualMaintenanceCost +
      +annualMaintenanceCost *
        (+fancade.costsActualization.priceVectorVariation / 100)) /
    Math.pow(1 + +interestRate / 100, year)
  );
};

export const getActualizationLifeServiceRows = (
  fancade: Fancade,
  index?: number
): TableRow[] => {
  let rows: TableRow[] = [];
  let sum = 0;
  if (fancade && fancade.maintenance && fancade.maintenance.refLife) {
    for (let i = 0; i != +fancade.maintenance.refLife + 1; i++) {
      sum += calculateCostOfLifeActualization(i, fancade);
      rows.push({
        name: i.toString(),
        values: [
          calculateCostOfLifeActualization(i, fancade).toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }),
          sum.toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }),
        ],
        variation: i % 2 === 0 ? "highlight" : undefined,
      });
    }
  }
  return rows;
};

export const getActualizationMaintenanceRows = (
  fancade: Fancade,
  index?: number
): TableRow[] => {
  let rows: TableRow[] = [];
  let sum = 0;
  if (fancade && fancade.maintenance && fancade.maintenance.refLife) {
    for (let i = 0; i != +fancade.maintenance.refLife + 1; i++) {
      sum += calculateMaintenanceCostActualization(i, fancade);
      rows.push({
        name: i.toString(),
        values: [
          calculateMaintenanceCostActualization(i, fancade).toLocaleString(
            "cs-CZ",
            {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }
          ),
          sum.toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }),
        ],
        variation: i % 2 === 0 ? "highlight" : undefined,
      });
    }
  }
  return rows;
};

export const getActualizationBenchmarkRows = (
  currentProject: Project,
  fancade: Fancade,
  index: number
): TableRow[] => {
  let rows: TableRow[] = [];
  if (
    fancade.maintenance?.refLife &&
    currentProject.fancades &&
    currentProject.fancades.length > 0
  ) {
    for (let i = 0; i != +fancade.maintenance.refLife + 1; i++) {
      rows.push({
        name: i.toString(),
        values: [
          calculateBenchmark(
            +calculateCostOfLifeActualization(i, fancade),
            +calculateCostOfLifeActualization(i, currentProject.fancades[0])
          ).toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }),
          calculateBenchmark(
            +calculateMaintenanceCostActualization(i, fancade),
            +calculateMaintenanceCostActualization(
              i,
              currentProject.fancades[0]
            )
          ).toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }),
        ],
        variation: i % 2 === 0 ? "highlight" : undefined,
      });
    }
  }
  return rows;
};

export const getActualizazionAllRows = (
  project: Project,
  index: number
): TableRow[] => {
  let rows: TableRow[] = [];

  if (project.fancades && project.fancades.length > 0) {
    let i = 0;
    let toCalculate = true;
    while (toCalculate) {
      let currentLifeServiceSum = 0;
      let currentLifeMaintenanceSum = 0;
      toCalculate = false;
      project.fancades.map((fancade, index) => {
        if (i <= fancade.maintenance.refLife) {
          currentLifeServiceSum += +calculateCostOfLifeActualization(
            i,
            fancade
          );
          currentLifeMaintenanceSum += +calculateMaintenanceCostActualization(
            i,
            fancade
          );
          toCalculate = true;
        }
      });
      if (toCalculate)
        rows.push({
          name: i.toString(),
          values: [
            currentLifeServiceSum.toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }),
            currentLifeMaintenanceSum.toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }),
          ],
          variation: i % 2 === 0 ? "highlight" : undefined,
        });
      i++;
    }
  }

  return rows;
};

export const getTotalActualizationRows = (
  fancade: Fancade,
  index?: number
): TableRow[] => {
  let toReturn = sumRowsValues([
    getActualizationMaintenanceRows(fancade),
    getActualizationLifeServiceRows(fancade),
  ]);
  if (typeof toReturn[0].values[0] === "number")
    toReturn[0].values[0] += +getFancadeInvestment(fancade);

  toReturn.map((row, index) => {
    if (typeof toReturn[index].values[1] === "number")
      toReturn[index].values[1] =
        +toReturn[index].values[1] + +getFancadeInvestment(fancade);
  });

  return formatNumericTable(toReturn, ["€", "€"], 2);
};

//---------------Final report---------------
export const getFancadeInvestment = (fancade: Fancade): number => {
  let toReturn = 0;

  toReturn += +calculateFancadeDesignTotalCost(fancade.fancadeProject);
  toReturn += +calculateProductionTotal(fancade);
  toReturn += +calculateTransportTotal(fancade);
  toReturn += +calculateInstallationTotal(fancade);
  toReturn += +calculateSecurityTotal(fancade);

  return toReturn;
};

export const getFancadeLCCInvestment = (fancade: Fancade): number => {
  let toReturn = 0;

  toReturn += +getFancadeInvestment(fancade);

  //Service life
  let serviceLifeActualized = 0;
  if (fancade.maintenance.refLife) {
    for (let i = 0; i != +fancade.maintenance.refLife + 1; i++) {
      serviceLifeActualized += +calculateCostOfLifeActualization(i, fancade);
    }
  }
  toReturn += +serviceLifeActualized;

  //Maintenance
  let maintenanceActualized = 0;
  if (fancade.maintenance.refLife) {
    for (let i = 0; i != +fancade.maintenance.refLife + 1; i++) {
      maintenanceActualized += +calculateMaintenanceCostActualization(
        i,
        fancade
      );
    }
  }
  toReturn += +maintenanceActualized;

  //End of Life
  toReturn +=
    +calculateDemolitionAndDisassemblyTotal(fancade) +
    +calculateEndOfLifeTransportTotal(fancade);

  //Recycling
  toReturn += +calculateRecyclingTotalRevenue(fancade);

  return toReturn;
};

export const getCostsPerPhaseRows = (
  project: Project,
  fancadeIndex: number
): TableRow[] => {
  let toReturn: TableRow[] = [];
  let totalInvestment = 0;
  let lccTotal = 0;
  if (project.fancades && project.fancades.length > 0) {
    //Project
    toReturn.push({
      name: "",
      values: [
        (+calculateFancadeDesignTotalCost(
          project.fancades[fancadeIndex].fancadeProject
        )).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
      ],
    });
    totalInvestment += +calculateFancadeDesignTotalCost(
      project.fancades[fancadeIndex].fancadeProject
    );

    //Production
    toReturn.push({
      name: "",
      values: [
        (+calculateProductionTotal(
          project.fancades[fancadeIndex]
        )).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
      ],
    });
    totalInvestment += +calculateProductionTotal(
      project.fancades[fancadeIndex]
    );

    //Transport to building site
    toReturn.push({
      name: "",
      values: [
        (+calculateTransportTotal(
          project.fancades[fancadeIndex]
        )).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
      ],
    });
    totalInvestment += +calculateTransportTotal(project.fancades[fancadeIndex]);

    //Building site
    toReturn.push({
      name: "",
      values: [
        (
          +calculateInstallationTotal(project.fancades[fancadeIndex]) +
          +calculateSecurityTotal(project.fancades[fancadeIndex])
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
      ],
    });
    totalInvestment +=
      +calculateInstallationTotal(project.fancades[fancadeIndex]) +
      +calculateSecurityTotal(project.fancades[fancadeIndex]);
    lccTotal = +totalInvestment;

    //Calculate total investment percentage
    toReturn.map((row: TableRow, index) => {
      if (project.fancades && project.fancades[fancadeIndex]) {
        if (toReturn[index] && toReturn[index].values) {
          if (!totalInvestment || totalInvestment == 0)
            toReturn[index].values.push(
              //@ts-ignore
              0 + "%"
            );
          else {
            if (typeof toReturn[index].values[0] === "string") {
              toReturn[index].values.push(
                //@ts-ignore
                (
                  (+toReturn[index].values[0]
                    .toString()
                    .replace(/\./g, "")
                    .replace(/€/g, "")
                    .replace(/\s+/g, "") /
                    +totalInvestment) *
                  100
                ).toLocaleString("cs-CZ", {
                  maximumFractionDigits: 0,
                }) + "%"
              );
            } else {
              toReturn[index].values.push(
                //@ts-ignore
                (
                  (+toReturn[index].values[0] / +totalInvestment) *
                  100
                ).toLocaleString("cs-CZ", {
                  maximumFractionDigits: 0,
                }) + "%"
              );
            }
          }
        }
      }
    });

    //Service life
    let serviceLifeActualized = 0;
    if (project.fancades[fancadeIndex].maintenance.refLife) {
      for (
        let i = 0;
        i != +project.fancades[fancadeIndex].maintenance.refLife + 1;
        i++
      ) {
        serviceLifeActualized += +calculateCostOfLifeActualization(
          i,
          project.fancades[fancadeIndex]
        );
      }
    }
    toReturn.push({
      name: "",
      values: [
        serviceLifeActualized.toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
      ],
    });

    lccTotal += +serviceLifeActualized;

    //Maintenance
    let maintenanceActualized = 0;
    if (project.fancades[fancadeIndex].maintenance.refLife) {
      for (
        let i = 0;
        i != +project.fancades[fancadeIndex].maintenance.refLife + 1;
        i++
      ) {
        maintenanceActualized += +calculateMaintenanceCostActualization(
          i,
          project.fancades[fancadeIndex]
        );
      }
    }

    toReturn.push({
      name: "",
      values: [
        (+maintenanceActualized).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
      ],
    });
    lccTotal += +maintenanceActualized;
    //End of life
    toReturn.push({
      name: "",
      values: [
        +calculateDemolitionAndDisassemblyTotal(
          project.fancades[fancadeIndex]
        ) +
          +calculateEndOfLifeTransportTotal(
            project.fancades[fancadeIndex]
          ).toLocaleString("cs-CZ", {
            maximumFractionDigits: 0,
          }) +
          "€",
      ],
    });
    lccTotal +=
      +calculateDemolitionAndDisassemblyTotal(project.fancades[fancadeIndex]) +
      +calculateEndOfLifeTransportTotal(project.fancades[fancadeIndex]);

    //Recycling
    toReturn.push({
      name: "",
      values: [
        +calculateRecyclingTotalRevenue(
          project.fancades[fancadeIndex]
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
      ],
    });

    lccTotal += +calculateRecyclingTotalRevenue(project.fancades[fancadeIndex]);
  }

  //Calculate LCC investment percentage
  toReturn.map((row: TableRow, index) => {
    if (project.fancades && project.fancades[fancadeIndex]) {
      if (toReturn[index] && toReturn[index].values) {
        if (toReturn[index].values.length < 2) {
          toReturn[index].values.push(
            //@ts-ignore
            "//"
          );
        }
        if (lccTotal == 0)
          toReturn[index].values.push(
            //@ts-ignore
            0 + "%"
          );
        else {
          toReturn[index].values.push(
            //@ts-ignore
            (
              (+toReturn[index].values[0]
                .toString()
                .replace(/\./g, "")
                .replace(/€/g, "")
                .replace(/\s+/g, "") /
                +lccTotal) *
              100
            ).toLocaleString("cs-CZ", {
              maximumFractionDigits: 0,
            }) + "%"
          );
        }
      }
    }
  });

  toReturn.push({
    name: "",
    values: [
      totalInvestment.toLocaleString("cs-CZ", {
        maximumFractionDigits: 0,
      }) + "€",
    ],
  });
  toReturn.push({
    name: "",
    values: [
      lccTotal.toLocaleString("cs-CZ", {
        maximumFractionDigits: 0,
      }) + "€",
    ],
  });

  return toReturn;
};

export const getCostsPerPhaseBenchmarkRows = (
  project: Project,
  fancadeIndex: number
): TableRow[] => {
  let toReturn: TableRow[] = [];
  let totalInvestment = 0;
  let lccTotal = 0;
  if (project.fancades && project.fancades.length > 0) {
    /* //Get investment values for all fancades
    let rows = getAllFancadesCostsPerPhaseRows(project, true);
    const investmentAll: number = +rows[rows.length - 2].values[0]
      .toString()
      .replace(/\./g, "")
      .replace(/€/g, "")
      .replace(/\s+/g, "");
    const lccAll: number = +rows[rows.length - 1].values[0]
      .toString()
      .replace(/\./g, "")
      .replace(/€/g, "")
      .replace(/\s+/g, ""); */

    //Project
    toReturn.push({
      name: "",
      values: [
        calculateFancadeDesignTotalCost(
          project.fancades[fancadeIndex].fancadeProject
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
        calculateFancadeDesignTotalCost(
          project.fancades[0].fancadeProject
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
        calculateBenchmark(
          +calculateFancadeDesignTotalCost(project.fancades[0].fancadeProject),
          +calculateFancadeDesignTotalCost(
            project.fancades[fancadeIndex].fancadeProject
          )
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
      ],
    });
    totalInvestment += calculateBenchmark(
      +calculateFancadeDesignTotalCost(project.fancades[0].fancadeProject),
      +calculateFancadeDesignTotalCost(
        project.fancades[fancadeIndex].fancadeProject
      )
    );

    //Production
    toReturn.push({
      name: "",
      values: [
        calculateProductionTotal(project.fancades[fancadeIndex]).toLocaleString(
          "cs-CZ",
          {
            maximumFractionDigits: 0,
          }
        ) + "€",
        calculateProductionTotal(project.fancades[0]).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
        calculateBenchmark(
          +calculateProductionTotal(project.fancades[0]),
          +calculateProductionTotal(project.fancades[fancadeIndex])
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
      ],
    });
    totalInvestment += calculateBenchmark(
      +calculateProductionTotal(project.fancades[0]),
      +calculateProductionTotal(project.fancades[fancadeIndex])
    );

    //Transport to building site
    toReturn.push({
      name: "",
      values: [
        calculateTransportTotal(project.fancades[fancadeIndex]).toLocaleString(
          "cs-CZ",
          {
            maximumFractionDigits: 0,
          }
        ) + "€",
        calculateTransportTotal(project.fancades[0]).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
        calculateBenchmark(
          +calculateTransportTotal(project.fancades[0]),
          +calculateTransportTotal(project.fancades[fancadeIndex])
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
      ],
    });
    totalInvestment += calculateBenchmark(
      +calculateTransportTotal(project.fancades[0]),
      +calculateTransportTotal(project.fancades[fancadeIndex])
    );

    //Building site
    toReturn.push({
      name: "",
      values: [
        (
          +calculateInstallationTotal(project.fancades[fancadeIndex]) +
          +calculateSecurityTotal(project.fancades[fancadeIndex])
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
        (
          +calculateInstallationTotal(project.fancades[0]) +
          +calculateSecurityTotal(project.fancades[0])
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
        calculateBenchmark(
          +calculateInstallationTotal(project.fancades[0]) +
            +calculateSecurityTotal(project.fancades[0]),
          +calculateInstallationTotal(project.fancades[fancadeIndex]) +
            +calculateSecurityTotal(project.fancades[fancadeIndex])
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
      ],
    });
    totalInvestment += calculateBenchmark(
      +calculateInstallationTotal(project.fancades[0]) +
        +calculateSecurityTotal(project.fancades[0]),
      +calculateInstallationTotal(project.fancades[fancadeIndex]) +
        +calculateSecurityTotal(project.fancades[fancadeIndex])
    );
    lccTotal = +totalInvestment;

    /* //Calculate total investment percentage
    toReturn.map((row: TableRow, index) => {
      if (project.fancades && project.fancades[fancadeIndex]) {
        if (toReturn[index] && toReturn[index].values) {
          if (investmentAll == 0)
            toReturn[index].values.push(
              //@ts-ignore
              0 + "%"
            );
          else {
            toReturn[index].values.push(
              //@ts-ignore
              (
                (+toReturn[index].values[0]
                  .toString()
                  .replace(/\./g, "")
                  .replace(/€/g, "")
                  .replace(/\s+/g, "") /
                  +investmentAll) *
                100
              ).toLocaleString("cs-CZ", {
                maximumFractionDigits: 2, minimumFractionDigits: 2,
              }) + "%"
            );
          }
        }
      }
    }); */

    //Service life
    let serviceLifeActualized = 0;
    let benchmarkServiceLifeActualized = 0;
    if (project.fancades[fancadeIndex].maintenance.refLife) {
      for (
        let i = 0;
        i != +project.fancades[fancadeIndex].maintenance.refLife + 1;
        i++
      ) {
        serviceLifeActualized += +calculateCostOfLifeActualization(
          i,
          project.fancades[fancadeIndex]
        );
      }
      if (project.fancades[0].maintenance.refLife) {
        for (let i = 0; i != project.fancades[0].maintenance.refLife; i++) {
          benchmarkServiceLifeActualized += +calculateCostOfLifeActualization(
            i,
            project.fancades[0]
          );
        }
      }
    }

    toReturn.push({
      name: "",
      values: [
        serviceLifeActualized.toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
        benchmarkServiceLifeActualized.toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
        calculateBenchmark(
          +benchmarkServiceLifeActualized,
          +serviceLifeActualized
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
      ],
    });

    lccTotal += calculateBenchmark(
      +benchmarkServiceLifeActualized,
      +serviceLifeActualized
    );

    //Maintenance
    let maintenanceActualized = 0;
    let benchmarkMaintenanceActualized = 0;
    if (project.fancades[fancadeIndex].maintenance.refLife) {
      for (
        let i = 0;
        i != +project.fancades[fancadeIndex].maintenance.refLife + 1;
        i++
      ) {
        maintenanceActualized += +calculateMaintenanceCostActualization(
          i,
          project.fancades[fancadeIndex]
        );
      }
    }
    if (project.fancades[0].maintenance.refLife) {
      for (let i = 0; i != project.fancades[0].maintenance.refLife; i++) {
        benchmarkMaintenanceActualized +=
          +calculateMaintenanceCostActualization(i, project.fancades[0]);
      }
    }
    toReturn.push({
      name: "",
      values: [
        maintenanceActualized.toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
        benchmarkMaintenanceActualized.toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
        calculateBenchmark(
          +benchmarkMaintenanceActualized,
          +maintenanceActualized
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
      ],
    });
    lccTotal += calculateBenchmark(
      +benchmarkMaintenanceActualized,
      +maintenanceActualized
    );

    //End of life
    toReturn.push({
      name: "",
      values: [
        (
          +calculateDemolitionAndDisassemblyTotal(
            project.fancades[fancadeIndex]
          ) + +calculateEndOfLifeTransportTotal(project.fancades[fancadeIndex])
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
        (
          +calculateDemolitionAndDisassemblyTotal(project.fancades[0]) +
          +calculateEndOfLifeTransportTotal(project.fancades[0])
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
        calculateBenchmark(
          +calculateDemolitionAndDisassemblyTotal(project.fancades[0]) +
            +calculateEndOfLifeTransportTotal(project.fancades[0]),
          +calculateDemolitionAndDisassemblyTotal(
            project.fancades[fancadeIndex]
          ) + +calculateEndOfLifeTransportTotal(project.fancades[fancadeIndex])
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
      ],
    });
    lccTotal += calculateBenchmark(
      +calculateInstallationTotal(project.fancades[0]) +
        +calculateSecurityTotal(project.fancades[0]),
      +calculateDemolitionAndDisassemblyTotal(project.fancades[fancadeIndex]) +
        +calculateEndOfLifeTransportTotal(project.fancades[fancadeIndex])
    );

    //Recycling
    toReturn.push({
      name: "",
      values: [
        calculateRecyclingTotalRevenue(
          project.fancades[fancadeIndex]
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
        calculateRecyclingTotalRevenue(
          project.fancades[fancadeIndex]
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
        calculateBenchmark(
          +calculateRecyclingTotalRevenue(project.fancades[fancadeIndex]),
          +calculateRecyclingTotalRevenue(project.fancades[fancadeIndex])
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
      ],
    });

    lccTotal += calculateBenchmark(
      +calculateRecyclingTotalRevenue(project.fancades[0]),
      +calculateRecyclingTotalRevenue(project.fancades[fancadeIndex])
    );

    //Calculate LCC investment percentage
    /* toReturn.map((row: TableRow, index) => {
      if (project.fancades && project.fancades[fancadeIndex]) {
        if (toReturn[index] && toReturn[index].values) {
          if (toReturn[index].values.length < 2) {
            toReturn[index].values.push(
              //@ts-ignore
              " "
            );
          }
          if (lccAll == 0)
            toReturn[index].values.push(
              //@ts-ignore
              0 + "%"
            );
          else
            toReturn[index].values.push(
              //@ts-ignore
              (
                (+toReturn[index].values[0]
                  .toString()
                  .replace(/\./g, "")
                  .replace(/€/g, "")
                  .replace(/\s+/g, "") /
                  +lccAll) *
                100
              ).toLocaleString("cs-CZ", {
                maximumFractionDigits: 2, minimumFractionDigits: 2,
              }) + "%"
            );
        }
      }
    }); */

    toReturn.push({
      name: "",
      values: [
        getFancadeInvestment(project.fancades[fancadeIndex]).toLocaleString(
          "cs-CZ",
          {
            maximumFractionDigits: 0,
          }
        ) + "€",
        getFancadeInvestment(project.fancades[0]).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
        totalInvestment.toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
      ],
    });
    toReturn.push({
      name: "",
      values: [
        getFancadeLCCInvestment(project.fancades[fancadeIndex]).toLocaleString(
          "cs-CZ",
          {
            maximumFractionDigits: 0,
          }
        ) + "€",
        getFancadeLCCInvestment(project.fancades[0]).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
        lccTotal.toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
      ],
    });
  }

  return toReturn;
};

export const getAllFancadesCostsPerPhaseRows = (
  project: Project,
  benchmark: boolean
): TableRow[] => {
  let toReturn: TableRow[] = [];
  let totalInvestment = 0;
  let lccTotal = 0;
  if (project.fancades && project.fancades.length > 0) {
    //Project
    let projectValue = 0;
    let production = 0;
    let transportToBuildingSite = 0;
    let buildingSite = 0;
    let serviceLife = 0;
    let maintenance = 0;
    let endOfLife = 0;
    let recycling = 0;

    project.fancades.map((fancade, index) => {
      if (!benchmark || index !== 0) {
        projectValue += +calculateFancadeDesignTotalCost(
          fancade.fancadeProject
        );
        production += +calculateProductionTotal(fancade);
        transportToBuildingSite += +calculateTransportTotal(fancade);
        buildingSite +=
          +calculateInstallationTotal(fancade) +
          +calculateSecurityTotal(fancade);
        if (fancade.maintenance.refLife) {
          for (let i = 0; i != fancade.maintenance.refLife; i++) {
            serviceLife += +calculateCostOfLifeActualization(i, fancade);
            maintenance += +calculateMaintenanceCostActualization(i, fancade);
          }
        }

        endOfLife +=
          +calculateDemolitionAndDisassemblyTotal(fancade) +
          +calculateEndOfLifeTransportTotal(fancade);
        recycling += +calculateRecyclingTotalRevenue(fancade);
      }
    });

    //Project
    toReturn.push({
      name: "",
      values: [
        (+projectValue).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
      ],
    });

    //Production
    toReturn.push({
      name: "",
      values: [
        (+production).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
      ],
    });

    //Transport to building site
    toReturn.push({
      name: "",
      values: [
        (+transportToBuildingSite).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
      ],
    });

    //Building site
    toReturn.push({
      name: "",
      values: [
        (+buildingSite).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
      ],
    });

    //Service life
    toReturn.push({
      name: "",
      values: [
        (+serviceLife).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
      ],
    });

    //Maintenance
    toReturn.push({
      name: "",
      values: [
        (+maintenance).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
      ],
    });

    //End of life
    toReturn.push({
      name: "",
      values: [
        (+endOfLife).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
      ],
    });

    //Recycling
    toReturn.push({
      name: "",
      values: [
        (+recycling).toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
      ],
    });

    totalInvestment =
      +projectValue + +production + +transportToBuildingSite + +buildingSite;
    lccTotal =
      +totalInvestment + +serviceLife + +maintenance + +endOfLife + +recycling;

    //Calculate total investment percentage
    toReturn.map((row: TableRow, index) => {
      //Has to be calculated only for the first 4 phases
      if (index < 4) {
        if (toReturn[index] && toReturn[index].values) {
          if (totalInvestment == 0)
            toReturn[index].values.push(
              //@ts-ignore
              0 + "%"
            );
          else
            toReturn[index].values.push(
              //@ts-ignore
              (
                (+toReturn[index].values[0]
                  .toString()
                  .replace(/\./g, "")
                  .replace(/€/g, "")
                  .replace(/\s+/g, "") /
                  +totalInvestment) *
                100
              ).toLocaleString("cs-CZ", {
                maximumFractionDigits: 0,
              }) + "%"
            );
        }
      }
    });
    //Calculate LCC investment percentage
    toReturn.map((row: TableRow, index) => {
      if (toReturn[index] && toReturn[index].values) {
        if (toReturn[index].values.length < 2) {
          toReturn[index].values.push(
            //@ts-ignore
            " "
          );
        }
        if (lccTotal == 0)
          toReturn[index].values.push(
            //@ts-ignore
            0 + "%"
          );
        else
          toReturn[index].values.push(
            //@ts-ignore
            (
              (+toReturn[index].values[0]
                .toString()
                .replace(/\./g, "")
                .replace(/€/g, "")
                .replace(/\s+/g, "") /
                +lccTotal) *
              100
            ).toLocaleString("cs-CZ", {
              maximumFractionDigits: 0,
            }) + "%"
          );
      }
    });

    toReturn.push({
      name: "",
      values: [
        totalInvestment.toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
      ],
    });
    toReturn.push({
      name: "",
      values: [
        lccTotal.toLocaleString("cs-CZ", {
          maximumFractionDigits: 0,
        }) + "€",
      ],
    });
  }

  return toReturn;
};

export const calculateInvestmentKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  if (fancade) {
    //Calculate KPI for one fancade
    let totalInvestment = 0;
    let projectValue = 0;
    let production = 0;
    let transportToBuildingSite = 0;
    let buildingSite = 0;
    let serviceLife = 0;
    let maintenance = 0;
    let endOfLife = 0;
    let recycling = 0;

    let surface = 0;

    projectValue += +calculateFancadeDesignTotalCost(fancade.fancadeProject);
    production += +calculateProductionTotal(fancade);
    transportToBuildingSite += +calculateTransportTotal(fancade);
    buildingSite +=
      +calculateInstallationTotal(fancade) + +calculateSecurityTotal(fancade);
    if (fancade.maintenance.refLife) {
      for (let i = 0; i != fancade.maintenance.refLife; i++) {
        serviceLife += +calculateCostOfLifeActualization(i, fancade);
        maintenance += +calculateMaintenanceCostActualization(i, fancade);
      }
    }
    endOfLife +=
      +calculateDemolitionAndDisassemblyTotal(fancade) +
      +calculateEndOfLifeTransportTotal(fancade);
    recycling += +calculateRecyclingTotalRevenue(fancade);

    if (fancade.fancadeTechnicalProject.surface)
      surface += +fancade.fancadeTechnicalProject.surface;

    totalInvestment =
      +projectValue + +production + +transportToBuildingSite + +buildingSite;
    if (surface != 0) return +totalInvestment / +surface;
    else return 0;
  } else if (project && project.fancades && project.fancades.length > 0) {
    //Calculate KPI for all fancades
    let totalInvestment = 0;
    let projectValue = 0;
    let production = 0;
    let transportToBuildingSite = 0;
    let buildingSite = 0;
    let serviceLife = 0;
    let maintenance = 0;
    let endOfLife = 0;
    let recycling = 0;

    let surface = 0;

    project.fancades.map((fancade, index) => {
      projectValue += +calculateFancadeDesignTotalCost(fancade.fancadeProject);
      production += +calculateProductionTotal(fancade);
      transportToBuildingSite += +calculateTransportTotal(fancade);
      buildingSite +=
        +calculateInstallationTotal(fancade) + +calculateSecurityTotal(fancade);
      if (fancade.maintenance.refLife) {
        for (let i = 0; i != fancade.maintenance.refLife; i++) {
          serviceLife += +calculateCostOfLifeActualization(i, fancade);
          maintenance += +calculateMaintenanceCostActualization(i, fancade);
        }
      }
      endOfLife +=
        +calculateDemolitionAndDisassemblyTotal(fancade) +
        +calculateEndOfLifeTransportTotal(fancade);
      recycling += +calculateRecyclingTotalRevenue(fancade);

      if (fancade.fancadeTechnicalProject.surface)
        surface += +fancade.fancadeTechnicalProject.surface;
    });

    totalInvestment =
      +projectValue + +production + +transportToBuildingSite + +buildingSite;
    if (surface != 0) return +totalInvestment / +surface;
    else return 0;
  } else {
    return 0;
  }
};

export const calculateLCCInvestmentKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  if (fancade) {
    //Calculate KPI for one fancade
    let lccTotal = 0;
    let projectValue = 0;
    let production = 0;
    let transportToBuildingSite = 0;
    let buildingSite = 0;
    let serviceLife = 0;
    let maintenance = 0;
    let endOfLife = 0;
    let recycling = 0;

    let surface = 0;

    projectValue += +calculateFancadeDesignTotalCost(fancade.fancadeProject);
    production += +calculateProductionTotal(fancade);
    transportToBuildingSite += +calculateTransportTotal(fancade);
    buildingSite +=
      +calculateInstallationTotal(fancade) + +calculateSecurityTotal(fancade);
    if (fancade.maintenance.refLife) {
      for (let i = 0; i != fancade.maintenance.refLife; i++) {
        serviceLife += +calculateCostOfLifeActualization(i, fancade);
        maintenance += +calculateMaintenanceCostActualization(i, fancade);
      }
    }
    endOfLife +=
      +calculateDemolitionAndDisassemblyTotal(fancade) +
      +calculateEndOfLifeTransportTotal(fancade);
    recycling += +calculateRecyclingTotalRevenue(fancade);

    if (fancade.fancadeTechnicalProject.surface)
      surface += +fancade.fancadeTechnicalProject.surface;

    lccTotal =
      +projectValue +
      +production +
      +transportToBuildingSite +
      +buildingSite +
      +serviceLife +
      +maintenance +
      +endOfLife +
      +recycling;
    if (surface != 0) return +lccTotal / +surface;
    else return 0;
  } else if (project && project.fancades && project.fancades.length > 0) {
    //Calculate KPI for all fancades
    let lccTotal = 0;
    let projectValue = 0;
    let production = 0;
    let transportToBuildingSite = 0;
    let buildingSite = 0;
    let serviceLife = 0;
    let maintenance = 0;
    let endOfLife = 0;
    let recycling = 0;

    let surface = 0;

    project.fancades.map((fancade, index) => {
      projectValue += +calculateFancadeDesignTotalCost(fancade.fancadeProject);
      production += +calculateProductionTotal(fancade);
      transportToBuildingSite += +calculateTransportTotal(fancade);
      buildingSite +=
        +calculateInstallationTotal(fancade) + +calculateSecurityTotal(fancade);
      if (fancade.maintenance.refLife) {
        for (let i = 0; i != fancade.maintenance.refLife; i++) {
          serviceLife += +calculateCostOfLifeActualization(i, fancade);
          maintenance += +calculateMaintenanceCostActualization(i, fancade);
        }
      }
      endOfLife +=
        +calculateDemolitionAndDisassemblyTotal(fancade) +
        +calculateEndOfLifeTransportTotal(fancade);
      recycling += +calculateRecyclingTotalRevenue(fancade);

      if (fancade.fancadeTechnicalProject.surface)
        surface += +fancade.fancadeTechnicalProject.surface;
    });

    lccTotal =
      +projectValue +
      +production +
      +transportToBuildingSite +
      +buildingSite +
      +serviceLife +
      +maintenance +
      +endOfLife +
      +recycling;
    if (surface != 0) return +lccTotal / +surface;
    else return 0;
  } else {
    return 0;
  }
};

export const calculateProjectKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  if (fancade) {
    if (
      fancade.fancadeTechnicalProject.surface &&
      fancade.fancadeTechnicalProject.surface > 0
    ) {
      return (
        +calculateFancadeDesignTotalCost(fancade.fancadeProject) /
        +fancade.fancadeTechnicalProject.surface
      );
    }
  } else if (project && project.fancades && project.fancades.length > 0) {
    let surface = 0;
    let design = 0;
    project.fancades.map((fancade, index) => {
      if (
        fancade.fancadeTechnicalProject.surface &&
        fancade.fancadeTechnicalProject.surface > 0
      ) {
        design += +calculateFancadeDesignTotalCost(fancade.fancadeProject);
        surface += +fancade.fancadeTechnicalProject.surface;
      }
    });
    if (surface != 0) return design / surface;
  }
  return 0;
};

export const calculateProductionKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  if (fancade) {
    if (
      fancade.fancadeTechnicalProject.surface &&
      fancade.fancadeTechnicalProject.surface > 0
    ) {
      return (
        +calculateProductionTotal(fancade) /
        +fancade.fancadeTechnicalProject.surface
      );
    }
  } else if (project && project.fancades && project.fancades.length > 0) {
    let production = 0;
    let surface = 0;

    project.fancades.map((fancade, index) => {
      production += +calculateProductionTotal(fancade);
      if (fancade.fancadeTechnicalProject.surface) {
        surface += +fancade.fancadeTechnicalProject.surface;
      }
    });

    if (surface != 0) return +production / +surface;
  }
  return 0;
};

export const calculateMaterialsCostProductionKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  if (fancade) {
    let materials = 0;
    let production = 0;

    materials = calculateMaterialsTotal(fancade);
    production = calculateProductionTotal(fancade);

    if (production != 0) return materials / production;
    else return 0;
  } else if (project && project.fancades && project.fancades.length > 0) {
    let materials = 0;
    let production = 0;
    project.fancades.map((fancade, index) => {
      materials += +calculateMaterialsTotal(fancade);
      production += +calculateProductionTotal(fancade);
    });
    if (production != 0) return materials / production;
    else return 0;
  }
  return 0;
};

export const calculateLaborCostProductionKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  if (fancade) {
    let labor = 0;
    let production = 0;

    labor = calculateLaborTotal(fancade);
    production = calculateProductionTotal(fancade);

    if (production != 0) return labor / production;
    else return 0;
  } else if (project && project.fancades && project.fancades.length > 0) {
    let labor = 0;
    let production = 0;
    project.fancades.map((fancade, index) => {
      labor += +calculateLaborTotal(fancade);
      production += +calculateProductionTotal(fancade);
    });
    if (production != 0) return labor / production;
    else return 0;
  }
  return 0;
};

export const calculateTransportKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  let transport = 0;
  let surface = 0;
  if (fancade) {
    transport = calculateTransportTotal(fancade);
    if (fancade.fancadeTechnicalProject.surface)
      surface = fancade.fancadeTechnicalProject.surface;
    if (surface != 0) return transport / surface;
  } else if (project && project.fancades && project.fancades.length > 0) {
    project.fancades.map((fancade, index) => {
      transport += +calculateTransportTotal(fancade);
      if (fancade.fancadeTechnicalProject.surface)
        surface += +fancade.fancadeTechnicalProject.surface;
    });
    if (surface != 0) return transport / surface;
  }
  return 0;
};

export const calculateTransportPerKmKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  let transport = 0;
  let distance = 0;
  if (fancade) {
    transport = calculateTransportTotal(fancade);
    if (fancade.transport.workSiteProductionSiteDistance)
      distance = fancade.transport.workSiteProductionSiteDistance;
    if (distance != 0) return transport / distance;
  } else if (project && project.fancades && project.fancades.length > 0) {
    project.fancades.map((fancade, index) => {
      transport += +calculateTransportTotal(fancade);
      if (fancade.transport.workSiteProductionSiteDistance)
        distance += +fancade.transport.workSiteProductionSiteDistance;
    });
    if (distance != 0) return transport / distance;
  }
  return 0;
};

export const calculateBuildingSiteKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  let buildingSite = 0;
  let surface = 0;
  if (fancade) {
    buildingSite =
      +calculateInstallationTotal(fancade) + +calculateSecurityTotal(fancade);
    if (fancade.fancadeTechnicalProject.surface)
      surface = fancade.fancadeTechnicalProject.surface;
    if (surface != 0) return buildingSite / surface;
  } else if (project && project.fancades && project.fancades.length > 0) {
    project.fancades.map((fancade, index) => {
      buildingSite +=
        +calculateInstallationTotal(fancade) + +calculateSecurityTotal(fancade);
      if (fancade.fancadeTechnicalProject.surface)
        surface += +fancade.fancadeTechnicalProject.surface;
    });
    if (surface != 0) return buildingSite / surface;
  }
  return 0;
};

export const calculateBuildingSiteInstallationKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  let buildingSite = 0;
  let surface = 0;
  if (fancade) {
    buildingSite = +calculateInstallationTotal(fancade);
    if (fancade.fancadeTechnicalProject.surface)
      surface = fancade.fancadeTechnicalProject.surface;
    if (surface != 0) return buildingSite / surface;
  } else if (project && project.fancades && project.fancades.length > 0) {
    project.fancades.map((fancade, index) => {
      buildingSite += +calculateInstallationTotal(fancade);
      if (fancade.fancadeTechnicalProject.surface)
        surface += +fancade.fancadeTechnicalProject.surface;
    });
    if (surface != 0) return buildingSite / surface;
  }
  return 0;
};

export const calculateBuildingSiteSecurityKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  let buildingSite = 0;
  let surface = 0;
  if (fancade) {
    buildingSite = +calculateSecurityTotal(fancade);
    if (fancade.fancadeTechnicalProject.surface)
      surface = fancade.fancadeTechnicalProject.surface;
    if (surface != 0) return buildingSite / surface;
  } else if (project && project.fancades && project.fancades.length > 0) {
    project.fancades.map((fancade, index) => {
      buildingSite += +calculateSecurityTotal(fancade);
      if (fancade.fancadeTechnicalProject.surface)
        surface += +fancade.fancadeTechnicalProject.surface;
    });
    if (surface != 0) return buildingSite / surface;
  }
  return 0;
};

export const calculateBuildingSiteInstallationConstructionKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  let buildingSite = 0;
  let construction = 0;
  if (fancade) {
    buildingSite = +calculateInstallationTotal(fancade);
    construction =
      +calculateInstallationTotal(fancade) + +calculateSecurityTotal(fancade);
    if (construction != 0) return buildingSite / construction;
  } else if (project && project.fancades && project.fancades.length > 0) {
    project.fancades.map((fancade, index) => {
      buildingSite += +calculateInstallationTotal(fancade);
      construction +=
        +calculateInstallationTotal(fancade) + +calculateSecurityTotal(fancade);
    });
    if (construction != 0) return buildingSite / construction;
  }
  return 0;
};

export const calculateBuildingSiteSecurityConstructionKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  let buildingSite = 0;
  let construction = 0;
  if (fancade) {
    buildingSite = +calculateSecurityTotal(fancade);
    construction =
      +calculateInstallationTotal(fancade) + +calculateSecurityTotal(fancade);
    if (construction != 0) return buildingSite / construction;
  } else if (project && project.fancades && project.fancades.length > 0) {
    project.fancades.map((fancade, index) => {
      buildingSite += +calculateSecurityTotal(fancade);
      construction +=
        +calculateInstallationTotal(fancade) + +calculateSecurityTotal(fancade);
    });
    if (construction != 0) return buildingSite / construction;
  }
  return 0;
};

export const calculateBuildingSiteMaterialsInstallationKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  let buildingSite = 0;
  let materials = 0;
  if (fancade) {
    buildingSite = +calculateInstallationTotal(fancade);
    materials = +calculateMaterialsTotal(fancade);
    if (buildingSite != 0) return materials / materials;
  } else if (project && project.fancades && project.fancades.length > 0) {
    project.fancades.map((fancade, index) => {
      buildingSite += +calculateInstallationTotal(fancade);
      materials += +calculateMaterialsTotal(fancade);
    });
    if (buildingSite != 0) return materials / buildingSite;
  }
  return 0;
};

export const calculateBuildingSiteLaborInstallationKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  let buildingSite = 0;
  let labor = 0;
  if (fancade) {
    buildingSite = +calculateInstallationTotal(fancade);
    labor = +calculateLaborTotal(fancade);
    if (buildingSite != 0) return labor / buildingSite;
  } else if (project && project.fancades && project.fancades.length > 0) {
    project.fancades.map((fancade, index) => {
      buildingSite += +calculateInstallationTotal(fancade);
      labor += +calculateLaborTotal(fancade);
    });
    if (buildingSite != 0) return labor / buildingSite;
  }
  return 0;
};

export const calculateBuildingSiteToolsInstallationKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  let buildingSite = 0;
  let tools = 0;
  if (fancade) {
    buildingSite = +calculateInstallationTotal(fancade);
    tools = +calculateBuildingSiteToolsTotal(fancade);
    if (buildingSite != 0) return tools / buildingSite;
  } else if (project && project.fancades && project.fancades.length > 0) {
    project.fancades.map((fancade, index) => {
      buildingSite += +calculateInstallationTotal(fancade);
      tools += +calculateBuildingSiteToolsTotal(fancade);
    });
    if (buildingSite != 0) return tools / buildingSite;
  }
  return 0;
};

export const calculateToolsKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  let tools = 0;
  let surface = 0;
  if (fancade) {
    tools = +calculateBuildingSiteToolsTotal(fancade);
    if (fancade.fancadeTechnicalProject.surface)
      surface = +fancade.fancadeTechnicalProject.surface;

    if (surface != 0) return +tools / +surface;
  } else if (project && project.fancades && project.fancades.length > 0) {
    project.fancades.map((fancade, index) => {
      tools += +calculateBuildingSiteToolsTotal(fancade);
      if (fancade.fancadeTechnicalProject.surface)
        surface += +fancade.fancadeTechnicalProject.surface;
    });
    if (surface != 0) return +tools / +surface;
  }
  return 0;
};

export const calculateCraneToolsKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  let tools = 0;
  let crane = 0;
  if (fancade) {
    tools = +calculateBuildingSiteToolsTotal(fancade);
    //Calculate crane total
    if (
      !fancade.buildingSite.totalTowerCranePriceCalculation ||
      fancade.buildingSite.totalTowerCranePriceCalculation == 0
    ) {
      if (fancade.buildingSite.totalTowerCranePrice)
        crane += +fancade.buildingSite.totalTowerCranePrice;
    } else {
      if (
        fancade.buildingSite.towerCraneHours &&
        fancade.buildingSite.towerCraneHourPrice
      )
        crane +=
          +fancade.buildingSite.towerCraneHours *
          +fancade.buildingSite.towerCraneHourPrice;
    }
    if (tools != 0) return +crane / +tools;
  } else if (project && project.fancades && project.fancades.length > 0) {
    project.fancades.map((fancade, index) => {
      tools += +calculateBuildingSiteToolsTotal(fancade);
      //Calculate crane total
      if (
        !fancade.buildingSite.totalTowerCranePriceCalculation ||
        fancade.buildingSite.totalTowerCranePriceCalculation == 0
      ) {
        if (fancade.buildingSite.totalTowerCranePrice)
          crane += +fancade.buildingSite.totalTowerCranePrice;
      } else {
        if (
          fancade.buildingSite.towerCraneHours &&
          fancade.buildingSite.towerCraneHourPrice
        )
          crane +=
            +fancade.buildingSite.towerCraneHours *
            +fancade.buildingSite.towerCraneHourPrice;
      }
    });
    if (tools != 0) return +crane / +tools;
  }
  return 0;
};

export const calculateElevatorPlatformsToolsKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  let tools = 0;
  let elevatorPlatforms = 0;
  if (fancade) {
    tools = +calculateBuildingSiteToolsTotal(fancade);
    //Elevator platforms
    if (
      !fancade.buildingSite.totalElevatorPlatformPriceCalculation ||
      fancade.buildingSite.totalElevatorPlatformPriceCalculation == 0
    ) {
      if (fancade.buildingSite.totalElevatorPlatformPrice)
        elevatorPlatforms += +fancade.buildingSite.totalElevatorPlatformPrice;
    } else {
      if (
        fancade.buildingSite.elevatorPlatformHours &&
        fancade.buildingSite.elevatorPlatformHourPrice
      )
        elevatorPlatforms +=
          +fancade.buildingSite.elevatorPlatformHours *
          +fancade.buildingSite.elevatorPlatformHourPrice;
    }
    if (tools != 0) return +elevatorPlatforms / +tools;
  } else if (project && project.fancades && project.fancades.length > 0) {
    project.fancades.map((fancade, index) => {
      tools += +calculateBuildingSiteToolsTotal(fancade);
      //Elevator platforms
      if (
        !fancade.buildingSite.totalElevatorPlatformPriceCalculation ||
        fancade.buildingSite.totalElevatorPlatformPriceCalculation == 0
      ) {
        if (fancade.buildingSite.totalElevatorPlatformPrice)
          elevatorPlatforms += +fancade.buildingSite.totalElevatorPlatformPrice;
      } else {
        if (
          fancade.buildingSite.elevatorPlatformHours &&
          fancade.buildingSite.elevatorPlatformHourPrice
        )
          elevatorPlatforms +=
            +fancade.buildingSite.elevatorPlatformHours *
            +fancade.buildingSite.elevatorPlatformHourPrice;
      }
    });
    if (tools != 0) return +elevatorPlatforms / +tools;
  }
  return 0;
};

export const calculateAerialPlatformsToolsKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  let tools = 0;
  let aerialPlatforms = 0;
  if (fancade) {
    tools = +calculateBuildingSiteToolsTotal(fancade);
    //Elevator platforms
    if (
      !fancade.buildingSite.totalAerialPlatformPriceCalculation ||
      fancade.buildingSite.totalAerialPlatformPriceCalculation == 0
    ) {
      if (fancade.buildingSite.totalAerialPlatformPrice)
        aerialPlatforms += +fancade.buildingSite.totalAerialPlatformPrice;
    } else {
      if (
        fancade.buildingSite.aerialPlatformHours &&
        fancade.buildingSite.aerialPlatformHourPrice
      )
        aerialPlatforms +=
          +fancade.buildingSite.aerialPlatformHours *
          +fancade.buildingSite.aerialPlatformHourPrice;
    }
    if (tools != 0) return +aerialPlatforms / +tools;
  } else if (project && project.fancades && project.fancades.length > 0) {
    project.fancades.map((fancade, index) => {
      tools += +calculateBuildingSiteToolsTotal(fancade);
      //Elevator platforms
      if (
        !fancade.buildingSite.totalAerialPlatformPriceCalculation ||
        fancade.buildingSite.totalAerialPlatformPriceCalculation == 0
      ) {
        if (fancade.buildingSite.totalAerialPlatformPrice)
          aerialPlatforms += +fancade.buildingSite.totalAerialPlatformPrice;
      } else {
        if (
          fancade.buildingSite.aerialPlatformHours &&
          fancade.buildingSite.aerialPlatformHourPrice
        )
          aerialPlatforms +=
            +fancade.buildingSite.aerialPlatformHours *
            +fancade.buildingSite.aerialPlatformHourPrice;
      }
    });
    if (tools != 0) return +aerialPlatforms / +tools;
  }
  return 0;
};

export const calculateScaffoldingsToolsKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  let tools = 0;
  let scaffoldings = 0;
  if (fancade) {
    tools = +calculateBuildingSiteToolsTotal(fancade);
    scaffoldings = +calculateScaffoldingTotal(fancade);
    if (tools != 0) return +scaffoldings / +tools;
  } else if (project && project.fancades && project.fancades.length > 0) {
    project.fancades.map((fancade, index) => {
      tools += +calculateBuildingSiteToolsTotal(fancade);
      scaffoldings += +calculateScaffoldingTotal(fancade);
      if (tools != 0) return +scaffoldings / +tools;
    });
  }
  return 0;
};

export const calculateBuildingSiteDurationKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  let duration = 0;
  if (fancade) {
    if (fancade.buildingSite.buildingSiteDuration)
      duration = fancade.buildingSite.buildingSiteDuration;
    return duration / 8;
  } else if (project && project.fancades && project.fancades.length > 0) {
    project.fancades.map((fancade, index) => {
      if (fancade.buildingSite.buildingSiteDuration)
        duration += +fancade.buildingSite.buildingSiteDuration;
    });
    return duration / 8;
  }
  return 0;
};

export const calculateBuildingSiteDurationPerM2KPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  let duration = 0;
  let surface = 0;
  if (fancade) {
    if (fancade.buildingSite.buildingSiteDuration)
      duration = fancade.buildingSite.buildingSiteDuration;
    if (fancade.fancadeTechnicalProject.surface)
      surface += +fancade.fancadeTechnicalProject.surface;
    if (surface != 0) return (duration / 8) * surface;
  } else if (project && project.fancades && project.fancades.length > 0) {
    project.fancades.map((fancade, index) => {
      if (fancade.buildingSite.buildingSiteDuration)
        duration += +fancade.buildingSite.buildingSiteDuration;
      if (fancade.fancadeTechnicalProject.surface)
        surface += +fancade.fancadeTechnicalProject.surface;
    });
    if (surface != 0) return (duration / 8) * surface;
  }
  return 0;
};

export const calculateMaintenanceKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  let maintenance = 0;
  let surface = 0;
  if (fancade) {
    maintenance += +calculateMaintenanceTotal(fancade);
    if (fancade.fancadeTechnicalProject.surface)
      surface += +fancade.fancadeTechnicalProject.surface;
    if (surface != 0) return maintenance / surface;
  } else if (project && project.fancades && project.fancades.length > 0) {
    project.fancades.map((fancade, index) => {
      maintenance += +calculateMaintenanceTotal(fancade);
      if (fancade.fancadeTechnicalProject.surface)
        surface += +fancade.fancadeTechnicalProject.surface;
    });
    if (surface != 0) return maintenance / surface;
  }
  return 0;
};

export const calculateOrdinaryMaintenanceMaintenanceKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  let maintenance = 0;
  let ordinaryMaintenance = 0;
  if (fancade) {
    maintenance += +calculateMaintenanceTotal(fancade);
    ordinaryMaintenance += +calculateOrdinaryMaintenance(fancade);
    if (maintenance != 0) return ordinaryMaintenance / maintenance;
  } else if (project && project.fancades && project.fancades.length > 0) {
    project.fancades.map((fancade, index) => {
      maintenance += +calculateMaintenanceTotal(fancade);
      ordinaryMaintenance += +calculateOrdinaryMaintenance(fancade);
    });
    if (maintenance != 0) return ordinaryMaintenance / maintenance;
  }
  return 0;
};

export const calculateExtraordinaryMaintenanceMaintenanceKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  let maintenance = 0;
  let extraordinaryMaintenance = 0;
  if (fancade) {
    maintenance += +calculateMaintenanceTotal(fancade);
    extraordinaryMaintenance += +calculateExtraordinaryMaintenance(fancade);
    if (maintenance != 0) return extraordinaryMaintenance / maintenance;
  } else if (project && project.fancades && project.fancades.length > 0) {
    project.fancades.map((fancade, index) => {
      maintenance += +calculateMaintenanceTotal(fancade);
      extraordinaryMaintenance += +calculateExtraordinaryMaintenance(fancade);
    });
    if (maintenance != 0) return extraordinaryMaintenance / maintenance;
  }
  return 0;
};

export const calculateCleaningMaintenanceKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  let maintenance = 0;
  let cleaning = 0;
  if (fancade) {
    maintenance += +calculateMaintenanceTotal(fancade);
    cleaning += +calculateCleaningMaintenance(fancade);
    if (maintenance != 0) return cleaning / maintenance;
  } else if (project && project.fancades && project.fancades.length > 0) {
    project.fancades.map((fancade, index) => {
      maintenance += +calculateMaintenanceTotal(fancade);
      cleaning += +calculateCleaningMaintenance(fancade);
    });
    if (maintenance != 0) return cleaning / maintenance;
  }
  return 0;
};

export const calculateEndOfLifeKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  let endOfLife = 0;
  let surface = 0;
  if (fancade) {
    endOfLife += +calculateDemolitionAndDisassemblyTotal(fancade);
    endOfLife += +calculateEndOfLifeTransportTotal(fancade);
    endOfLife -= +calculateRecyclingTotalRevenue(fancade);

    if (fancade.fancadeTechnicalProject.surface)
      surface += +fancade.fancadeTechnicalProject.surface;
    if (surface != 0) return +endOfLife / +surface;
  } else if (project && project.fancades && project.fancades.length > 0) {
    project.fancades.map((fancade, index) => {
      endOfLife += +calculateDemolitionAndDisassemblyTotal(fancade);
      endOfLife += +calculateEndOfLifeTransportTotal(fancade);
      endOfLife -= +calculateRecyclingTotalRevenue(fancade);

      if (fancade.fancadeTechnicalProject.surface)
        surface += +fancade.fancadeTechnicalProject.surface;
    });
    if (surface != 0) return +endOfLife / +surface;
  }
  return 0;
};

export const calculateRecyclingProductionKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  let recycling = 0;
  let production = 0;
  if (fancade) {
    recycling += -1 * +calculateRecyclingTotalRevenue(fancade);
    production +=
      +calculateProductionTotal(fancade) +
      +calculateTransportTotal(fancade) +
      +calculateInstallationTotal(fancade) +
      +calculateSecurityTotal(fancade);
    if (production != 0) return +recycling / +production;
  } else if (project && project.fancades && project.fancades.length > 0) {
    project.fancades.map((fancade, index) => {
      recycling += -1 * +calculateRecyclingTotalRevenue(fancade);
      production +=
        +calculateProductionTotal(fancade) +
        +calculateTransportTotal(fancade) +
        +calculateInstallationTotal(fancade) +
        +calculateSecurityTotal(fancade);
    });

    if (production != 0) return +recycling / +production;
  }
  return 0;
};

export const calculateMaterialsProductionAndConstructionKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  let materials = 0;
  let buildingSiteMaterials = 0;
  if (fancade) {
    materials += +calculateMaterialsTotal(fancade);
    buildingSiteMaterials += +calculateBuildingSiteMaterialsTotal(fancade);

    return +materials + +buildingSiteMaterials;
  } else if (project && project.fancades && project.fancades.length > 0) {
    project.fancades.map((fancade, index) => {
      materials += +calculateMaterialsTotal(fancade);
      buildingSiteMaterials += +calculateBuildingSiteMaterialsTotal(fancade);
    });
    return +materials + +buildingSiteMaterials;
  }
  return 0;
};

export const calculateMaterialsCostKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  let materials = 0;
  let surface = 0;
  if (fancade) {
    materials += +calculateMaterialsProductionAndConstructionKPI(fancade);
    if (fancade.fancadeTechnicalProject.surface)
      surface += +fancade.fancadeTechnicalProject.surface;

    if (surface != 0) return +materials / +surface;
  } else if (project && project.fancades && project.fancades.length > 0) {
    project.fancades.map((fancade, index) => {
      materials += +calculateMaterialsProductionAndConstructionKPI(fancade);
      if (fancade.fancadeTechnicalProject.surface)
        surface += +fancade.fancadeTechnicalProject.surface;
    });

    if (surface != 0) return +materials / +surface;
  }
  return 0;
};

export const calculateLaborProductionAndConstructionKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  let labor = 0;
  let buildingSiteLabor = 0;
  if (fancade) {
    labor += +calculateLaborTotal(fancade);
    buildingSiteLabor += +calculateBuildingSiteLaborTotal(fancade);

    return +labor + +buildingSiteLabor;
  } else if (project && project.fancades && project.fancades.length > 0) {
    project.fancades.map((fancade, index) => {
      labor += +calculateLaborTotal(fancade);
      buildingSiteLabor += +calculateBuildingSiteLaborTotal(fancade);
    });
    return +labor + +buildingSiteLabor;
  }
  return 0;
};

export const calculateLaborCostKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  let labors = 0;
  let surface = 0;
  if (fancade) {
    labors += +calculateLaborProductionAndConstructionKPI(fancade);
    if (fancade.fancadeTechnicalProject.surface)
      surface += +fancade.fancadeTechnicalProject.surface;

    if (surface != 0) return +labors / +surface;
  } else if (project && project.fancades && project.fancades.length > 0) {
    project.fancades.map((fancade, index) => {
      labors += +calculateLaborProductionAndConstructionKPI(fancade);
      if (fancade.fancadeTechnicalProject.surface)
        surface += +fancade.fancadeTechnicalProject.surface;
    });

    if (surface != 0) return +labors / +surface;
  }
  return 0;
};

export const calculateFancadeKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  if (fancade) {
    if (fancade.fancadeTechnicalProject.surface)
      return fancade.fancadeTechnicalProject.surface;
  } else if (project && project.fancades && project.fancades.length > 0) {
    let surface = 0;
    project.fancades.map((fancade, index) => {
      if (fancade.fancadeTechnicalProject.surface)
        surface += +fancade.fancadeTechnicalProject.surface;
    });
    return surface;
  }
  return 0;
};

export const calculateWWRKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  let surface = 0;
  let windowsSurfaces: number[] = [];
  if (fancade) {
    if (fancade.fancadeTechnicalProject.surface)
      surface += +fancade.fancadeTechnicalProject.surface;

    fancade.fancadeTechnicalProject?.windows?.map((window: OpaqueWindow) => {
      if (window.windowsSurface) windowsSurfaces.push(window.windowsSurface);
    });

    return calculateWindowOpaqueSurfaceRatio(surface, windowsSurfaces);
  } else if (project && project.fancades && project.fancades.length > 0) {
    project.fancades.map((fancade, index) => {
      if (fancade.fancadeTechnicalProject.surface)
        surface += +fancade.fancadeTechnicalProject.surface;

      fancade.fancadeTechnicalProject?.windows?.map((window: OpaqueWindow) => {
        if (window.windowsSurface) windowsSurfaces.push(window.windowsSurface);
      });
    });

    return calculateWindowOpaqueSurfaceRatio(surface, windowsSurfaces);
  }
  return 0;
};

export const calculateUwallKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  let uparete = 0;
  if (fancade) {
    if (fancade.fancadeTechnicalProject.opaqueUValue)
      uparete += +fancade.fancadeTechnicalProject.opaqueUValue;
    return uparete;
  } else if (project && project.fancades && project.fancades.length > 0) {
    let uTotal = 0;
    project.fancades.map((fancade, index) => {
      /*  if (fancade.fancadeTechnicalProject.opaqueUValue)
        uparete += +fancade.fancadeTechnicalProject.opaqueUValue; */
      uTotal += calculateUwallKPI(fancade);
    });
    //return uparete;
    return +uTotal / +project.fancades.length;
  }
  return 0;
};

export const calculateUwindowKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  let ufinestra = 0;
  if (fancade) {
    if (fancade.fancadeTechnicalProject.transparentUValue)
      ufinestra += +fancade.fancadeTechnicalProject.transparentUValue;
    return ufinestra;
  } else if (project && project.fancades && project.fancades.length > 0) {
    let uTotal = 0;
    project.fancades.map((fancade, index) => {
      /* if (fancade.fancadeTechnicalProject.transparentUValue){
        ufinestra += +fancade.fancadeTechnicalProject.transparentUValue;
        
      } */
      uTotal += calculateUwindowKPI(fancade);
    });
    //return ufinestra;
    return +uTotal / +project.fancades.length;
  }
  return 0;
};

export const calculateUFancadeAverageKPI = (
  fancade?: Fancade,
  project?: Project
): number => {
  let wwr = 0;
  let uWall = 0;
  let uWindow = 0;

  if (fancade) {
    wwr += +calculateWWRKPI(fancade);
    uWall += +calculateUwindowKPI(fancade);
    uWindow += +calculateUwallKPI(fancade);

    return +uWindow * +wwr + +uWall * (1 - +wwr);
  } else if (project && project.fancades && project.fancades.length > 0) {
    let uTotal = 0;
    project.fancades.map((fancade, index) => {
      /* wwr += +calculateWWRKPI(fancade);
      uWall += +calculateUwindowKPI(fancade);
      uWindow += +calculateUwallKPI(fancade); */
      uTotal += calculateUFancadeAverageKPI(fancade);
    });

    //return +uWindow * +wwr + +uWall * (1 - +wwr);
    return +uTotal / +project.fancades.length;
  }
  return 0;
};

//---------------Plots---------------

export const getInvestmentPerPhasePlotValues = (
  project: Project,
  fancadeIndex: number,
  isBenchmark?: boolean
): number[] => {
  let values: number[] = [];

  if (fancadeIndex != -1) {
    if (project.fancades && project.fancades.length > 0) {
      //Project
      values.push(
        +calculateFancadeDesignTotalCost(
          project.fancades[fancadeIndex].fancadeProject
        )
      );
      //Production
      values.push(+calculateProductionTotal(project.fancades[fancadeIndex]));
      //Transport to building site
      values.push(+calculateTransportTotal(project.fancades[fancadeIndex]));
      //Building site
      values.push(
        +calculateInstallationTotal(project.fancades[fancadeIndex]) +
          +calculateSecurityTotal(project.fancades[fancadeIndex])
      );
    }
  } else {
    let projectValue = 0;
    let production = 0;
    let transport = 0;
    let buildingSite = 0;

    project.fancades?.map((fancade, index) => {
      if (!isBenchmark || index !== 0) {
        projectValue += +calculateFancadeDesignTotalCost(
          fancade.fancadeProject
        );
        production += +calculateProductionTotal(fancade);
        transport += +calculateTransportTotal(fancade);
        buildingSite +=
          +calculateInstallationTotal(fancade) +
          +calculateSecurityTotal(fancade);
      }
    });

    values.push(projectValue);
    values.push(production);
    values.push(transport);
    values.push(buildingSite);
  }
  let isAllZeros = true;
  values.map((value) => {
    if (value != 0) isAllZeros = false;
  });
  if (isAllZeros) return [];
  return values;
};

export const getLCCInvestmentPerPhasePlotValues = (
  project: Project,
  fancadeIndex: number,
  isBenchmark?: boolean
): number[] => {
  let values: number[] = [];

  if (fancadeIndex != -1) {
    if (project.fancades && project.fancades.length > 0) {
      //Project
      values.push(
        +calculateFancadeDesignTotalCost(
          project.fancades[fancadeIndex].fancadeProject
        )
      );
      //Production
      values.push(+calculateProductionTotal(project.fancades[fancadeIndex]));
      //Transport to building site
      values.push(+calculateTransportTotal(project.fancades[fancadeIndex]));
      //Building site
      values.push(
        +calculateInstallationTotal(project.fancades[fancadeIndex]) +
          +calculateSecurityTotal(project.fancades[fancadeIndex])
      );
      //ServiceLife actualized
      let serviceLifeActualized = 0;
      if (project.fancades[fancadeIndex].maintenance.refLife) {
        for (
          let i = 0;
          i != +project.fancades[fancadeIndex].maintenance.refLife + 1;
          i++
        ) {
          serviceLifeActualized += +calculateCostOfLifeActualization(
            i,
            project.fancades[fancadeIndex]
          );
        }
      }
      values.push(serviceLifeActualized);
      //Maintenance actualized
      let maintenanceActualized = 0;
      if (project.fancades[fancadeIndex].maintenance.refLife) {
        for (
          let i = 0;
          i != +project.fancades[fancadeIndex].maintenance.refLife + 1;
          i++
        ) {
          maintenanceActualized += +calculateMaintenanceCostActualization(
            i,
            project.fancades[fancadeIndex]
          );
        }
      }

      values.push(+maintenanceActualized);
      //EndOfLife
      values.push(
        +calculateDemolitionAndDisassemblyTotal(project.fancades[fancadeIndex])
      );

      //Recycling
      values.push(
        +calculateRecyclingTotalRevenue(project.fancades[fancadeIndex])
      );
    }
  } else {
    let projectValue = 0;
    let production = 0;
    let transport = 0;
    let buildingSite = 0;
    let serviceLife = 0;
    let maintenance = 0;
    let endOfLife = 0;
    let recycling = 0;

    project.fancades?.map((fancade, index) => {
      if (!isBenchmark || index !== 0) {
        projectValue += +calculateFancadeDesignTotalCost(
          fancade.fancadeProject
        );
        production += +calculateProductionTotal(fancade);
        transport += +calculateTransportTotal(fancade);
        buildingSite +=
          +calculateInstallationTotal(fancade) +
          +calculateSecurityTotal(fancade);

        if (fancade.maintenance.refLife) {
          for (let i = 0; i != fancade.maintenance.refLife; i++) {
            serviceLife += +calculateCostOfLifeActualization(i, fancade);
          }

          if (fancade.maintenance.refLife) {
            for (let i = 0; i != fancade.maintenance.refLife; i++) {
              maintenance += +calculateMaintenanceCostActualization(i, fancade);
            }
          }
        }
        endOfLife += +calculateDemolitionAndDisassemblyTotal(fancade);
        recycling += +calculateRecyclingTotalRevenue(fancade);
      }
    });

    values.push(projectValue);
    values.push(production);
    values.push(transport);
    values.push(buildingSite);
    values.push(serviceLife);
    values.push(maintenance);
    values.push(endOfLife);
    values.push(recycling);
  }

  let isAllZeros = true;
  values.map((value) => {
    if (value != 0) isAllZeros = false;
  });
  if (isAllZeros) return [];

  return values;
};

export const getProductionPlotValues = (
  project: Project,
  fancadeIndex: number,
  isBenchmark?: boolean
): number[] => {
  let values: number[] = [];

  if (fancadeIndex != -1) {
    if (project.fancades && project.fancades.length > 0) {
      //Materials
      values.push(+calculateMaterialsTotal(project.fancades[fancadeIndex]));
      //Labor
      values.push(+calculateLaborTotal(project.fancades[fancadeIndex]));
    }
  } else {
    let materials = 0;
    let labor = 0;

    project.fancades?.map((fancade, index) => {
      if (!isBenchmark || index !== 0) {
        materials += +calculateMaterialsTotal(fancade);
        labor += +calculateLaborTotal(fancade);
      }
    });

    values.push(materials);
    values.push(labor);
  }
  const total = +values[0] + +values[1];
  values[0] = +values[0] / +total;
  values[1] = +values[1] / +total;
  let isAllZeros = true;
  values.map((value) => {
    if (value != 0) isAllZeros = false;
  });
  if (isAllZeros) return [];
  return values;
};

export const getBuildingSiteScompositionPlotValues = (
  project: Project,
  fancadeIndex: number,
  isBenchmark?: boolean
): number[] => {
  let values: number[] = [];

  if (fancadeIndex != -1) {
    if (project.fancades && project.fancades.length > 0) {
      //Installation
      values.push(+calculateInstallationTotal(project.fancades[fancadeIndex]));
      //Security
      values.push(+calculateSecurityTotal(project.fancades[fancadeIndex]));
    }
  } else {
    let installation = 0;
    let security = 0;

    project.fancades?.map((fancade, index) => {
      if (!isBenchmark || index !== 0) {
        installation += +calculateInstallationTotal(fancade);
        security += +calculateSecurityTotal(fancade);
      }
    });

    values.push(installation);
    values.push(security);
  }
  const total = +values[0] + +values[1];
  if (total == 0) return [];
  values[0] = +values[0] / +total;
  values[1] = +values[1] / +total;
  let isAllZeros = true;
  values.map((value) => {
    if (value != 0) isAllZeros = false;
  });
  if (isAllZeros) return [];
  return values;
};

export const getInstallationScompositionPlotValues = (
  project: Project,
  fancadeIndex: number,
  isBenchmark?: boolean
): number[] => {
  let values: number[] = [];

  if (fancadeIndex != -1) {
    if (project.fancades && project.fancades.length > 0) {
      //Materials
      values.push(
        +calculateBuildingSiteMaterialsTotal(project.fancades[fancadeIndex])
      );
      //Labor
      values.push(
        +calculateBuildingSiteLaborTotal(project.fancades[fancadeIndex])
      );
      //Tools
      values.push(
        +calculateBuildingSiteToolsTotal(project.fancades[fancadeIndex])
      );
    }
  } else {
    let materials = 0;
    let labor = 0;
    let tools = 0;

    project.fancades?.map((fancade, index) => {
      if (!isBenchmark || index !== 0) {
        materials += +calculateBuildingSiteMaterialsTotal(fancade);
        labor += +calculateBuildingSiteLaborTotal(fancade);
        tools += +calculateBuildingSiteToolsTotal(fancade);
      }
    });

    values.push(materials);
    values.push(labor);
    values.push(tools);
  }
  const total = +values[0] + +values[1] + +values[2];
  if (total == 0) return [];
  values[0] = +values[0] / +total;
  values[1] = +values[1] / +total;
  values[2] = +values[2] / +total;
  let isAllZeros = true;
  values.map((value) => {
    if (value != 0) isAllZeros = false;
  });
  if (isAllZeros) return [];
  return values;
};

export const getToolsScompositionPlotValues = (
  project: Project,
  fancadeIndex: number,
  isBenchmark?: boolean
): number[] => {
  let values: number[] = [];
  let crane = 0;
  let elevatorPlatforms = 0;
  let aerialPlatforms = 0;
  let scaffolding = 0;

  if (fancadeIndex != -1) {
    if (project.fancades && project.fancades.length > 0) {
      const fancade = project.fancades[fancadeIndex];
      if (
        fancade.buildingSite &&
        fancade.buildingSite.totalInstallationPriceCalculation &&
        fancade.buildingSite.totalInstallationPriceCalculation == 1
      ) {
        //Crane
        if (
          !fancade.buildingSite.totalTowerCranePriceCalculation ||
          fancade.buildingSite.totalTowerCranePriceCalculation == 0
        ) {
          if (fancade.buildingSite.totalTowerCranePrice)
            crane += +fancade.buildingSite.totalTowerCranePrice;
        } else {
          if (
            fancade.buildingSite.towerCraneHours &&
            fancade.buildingSite.towerCraneHourPrice
          )
            crane +=
              +fancade.buildingSite.towerCraneHours *
              +fancade.buildingSite.towerCraneHourPrice;
        }

        //Elevator platforms
        if (
          !fancade.buildingSite.totalElevatorPlatformPriceCalculation ||
          fancade.buildingSite.totalElevatorPlatformPriceCalculation == 0
        ) {
          if (fancade.buildingSite.totalElevatorPlatformPrice)
            elevatorPlatforms +=
              +fancade.buildingSite.totalElevatorPlatformPrice;
        } else {
          if (
            fancade.buildingSite.elevatorPlatformHours &&
            fancade.buildingSite.elevatorPlatformHourPrice
          )
            elevatorPlatforms +=
              +fancade.buildingSite.elevatorPlatformHours *
              +fancade.buildingSite.elevatorPlatformHourPrice;
        }

        //Aerial platforms
        if (
          !fancade.buildingSite.totalAerialPlatformPriceCalculation ||
          fancade.buildingSite.totalAerialPlatformPriceCalculation == 0
        ) {
          if (fancade.buildingSite.totalAerialPlatformPrice)
            aerialPlatforms += +fancade.buildingSite.totalAerialPlatformPrice;
        } else {
          if (
            fancade.buildingSite.aerialPlatformHours &&
            fancade.buildingSite.aerialPlatformHourPrice
          )
            aerialPlatforms +=
              +fancade.buildingSite.aerialPlatformHours *
              +fancade.buildingSite.aerialPlatformHourPrice;
        }

        //Scaffolding
        scaffolding += +calculateScaffoldingTotal(fancade);
      }
    }
  } else {
    project.fancades?.map((fancade, index) => {
      if (!isBenchmark || index !== 0) {
        if (
          fancade.buildingSite &&
          fancade.buildingSite.totalInstallationPriceCalculation &&
          fancade.buildingSite.totalInstallationPriceCalculation == 1
        ) {
          //Crane
          if (
            !fancade.buildingSite.totalTowerCranePriceCalculation ||
            fancade.buildingSite.totalTowerCranePriceCalculation == 0
          ) {
            if (fancade.buildingSite.totalTowerCranePrice)
              crane += +fancade.buildingSite.totalTowerCranePrice;
          } else {
            if (
              fancade.buildingSite.towerCraneHours &&
              fancade.buildingSite.towerCraneHourPrice
            )
              crane +=
                +fancade.buildingSite.towerCraneHours *
                +fancade.buildingSite.towerCraneHourPrice;
          }

          //Elevator platforms
          if (
            !fancade.buildingSite.totalElevatorPlatformPriceCalculation ||
            fancade.buildingSite.totalElevatorPlatformPriceCalculation == 0
          ) {
            if (fancade.buildingSite.totalElevatorPlatformPrice)
              elevatorPlatforms +=
                +fancade.buildingSite.totalElevatorPlatformPrice;
          } else {
            if (
              fancade.buildingSite.elevatorPlatformHours &&
              fancade.buildingSite.elevatorPlatformHourPrice
            )
              elevatorPlatforms +=
                +fancade.buildingSite.elevatorPlatformHours *
                +fancade.buildingSite.elevatorPlatformHourPrice;
          }

          //Aerial platforms
          if (
            !fancade.buildingSite.totalAerialPlatformPriceCalculation ||
            fancade.buildingSite.totalAerialPlatformPriceCalculation == 0
          ) {
            if (fancade.buildingSite.totalAerialPlatformPrice)
              aerialPlatforms += +fancade.buildingSite.totalAerialPlatformPrice;
          } else {
            if (
              fancade.buildingSite.aerialPlatformHours &&
              fancade.buildingSite.aerialPlatformHourPrice
            )
              aerialPlatforms +=
                +fancade.buildingSite.aerialPlatformHours *
                +fancade.buildingSite.aerialPlatformHourPrice;
          }

          //Scaffolding
          scaffolding += +calculateScaffoldingTotal(fancade);
        }
      }
    });
  }
  const total = +crane + +elevatorPlatforms + +aerialPlatforms + +scaffolding;
  if (total == 0) return [];
  values.push(+crane / +total);
  values.push(+elevatorPlatforms / +total);
  values.push(+aerialPlatforms / +total);
  values.push(+scaffolding / +total);
  let isAllZeros = true;
  values.map((value) => {
    if (value != 0) isAllZeros = false;
  });
  if (isAllZeros) return [];
  return values;
};

export const getMaintenanceScompositionPlotValues = (
  project: Project,
  fancadeIndex: number,
  isBenchmark?: boolean
): number[] => {
  let values: number[] = [];

  if (fancadeIndex != -1) {
    if (project.fancades && project.fancades.length > 0) {
      //Extraordinary Maintenance
      values.push(
        +calculateExtraordinaryMaintenance(project.fancades[fancadeIndex])
      );
      //Cleaning
      values.push(
        +calculateCleaningMaintenance(project.fancades[fancadeIndex])
      );
      //Ordinary Maintenance
      values.push(
        +calculateOrdinaryMaintenance(project.fancades[fancadeIndex])
      );
    }
  } else {
    let extraordinaryMaintenance = 0;
    let cleaning = 0;
    let ordinaryMaintenance = 0;

    project.fancades?.map((fancade, index) => {
      if (!isBenchmark || index !== 0) {
        extraordinaryMaintenance += +calculateOrdinaryMaintenance(fancade);
        cleaning += +calculateCleaningMaintenance(fancade);
        ordinaryMaintenance += +calculateExtraordinaryMaintenance(fancade);
      }
    });

    values.push(extraordinaryMaintenance);
    values.push(cleaning);
    values.push(ordinaryMaintenance);
  }
  const total = +values[0] + +values[1] + +values[2];
  if (total == 0) return [];
  values[0] = +values[0] / +total;
  values[1] = +values[1] / +total;
  values[2] = +values[2] / +total;
  let isAllZeros = true;
  values.map((value) => {
    if (value != 0) isAllZeros = false;
  });
  if (isAllZeros) return [];
  return values;
};
