import React, { useCallback, useMemo, useState } from "react";
import { Center, Divider, Flex, Stack, Text } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../../../../hooks/useStores";
import { Redirect, useParams } from "react-router-dom";

import theme from "../../../../../../theme/theme";
import { FormRow } from "../../../../../FormRow";
import { Implant, ServiceLifeType } from "../../../../../../types";
import {
  calculateServiceOfLifeIntegratedImplantsTotal,
  calculateServiceOfLifeOtherImplantsTotal,
  calculateServiceOfLifeTotal,
} from "../../../../../../services/utils";
import { FormikCheckbox } from "../../../../../FormikCheckbox";
import { Container } from "../../../../../Container";
import { PrimaryButton } from "../../../../../Button";
import { CloseIcon } from "@chakra-ui/icons";

interface RouteParams {
  projectId: string;
}

type props = {
  fancadeNumber: number;
  fancadeId: number;
  fancadeType: string | null;
  formikProps: any;
};

export const ServiceOfLifeForm: React.FC<props> = observer((props) => {
  const { t } = useTranslation();
  const { session, ui, projects } = useStores();
  const { projectId } = useParams<RouteParams>();
  const [update, setUpdate] = useState(false);

  ui.setActiveScreen("projects");

  const currentProject = useMemo(() => {
    return projects.projects.find((element) => {
      return element.id == parseInt(projectId);
    });
  }, [projects, projectId]);

  const currentFancade = currentProject?.fancades?.find((element) => {
    return +element.id == props.fancadeId;
  });

  const addImplant = useCallback(
    (serviceLife: ServiceLifeType) => {
      if (!serviceLife.implants) {
        serviceLife.implants = [];
      }

      serviceLife.implants.push({
        annualImplantName: null,
        annualImplantCost: null,
        annualImplantCostCalculation: 0,
        annualImplantConsume: null,
        energeticImplantVectorUnitPrice: null,
      });

      if (currentProject && currentProject.fancades)
        currentProject.fancades[props.fancadeNumber - 1].serviceLife.implants =
          projects.setOtherImplantsToFancade(
            currentProject.id,
            currentProject.fancades[props.fancadeNumber - 1].id,
            serviceLife.implants
          );
      setUpdate(!update);
    },

    [projects, currentProject, props.formikProps, update]
  );
  const deleteImplant = useCallback(
    (serviceLife: ServiceLifeType, index: number) => {
      serviceLife.implants?.splice(index, 1);
      if (currentProject && currentProject.fancades && serviceLife.implants)
        currentProject.fancades[props.fancadeNumber - 1].serviceLife.implants =
          projects.setOtherImplantsToFancade(
            currentProject.id,
            currentProject.fancades[props.fancadeNumber - 1].id,
            serviceLife.implants
          );

      setUpdate(!update);
    },
    [update, currentProject]
  );

  if (session.user)
    return (
      <Stack w={"100%"}>
        <FormikCheckbox
          checked={
            !(
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .serviceLife?.annualLifeCostCalculation &&
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .serviceLife?.annualLifeCostCalculation == 1
            )
          }
          defaultChecked={
            !(
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .serviceLife?.annualLifeCostCalculation &&
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .serviceLife?.annualLifeCostCalculation == 1
            )
          }
          onChange={() => {
            if (
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .serviceLife
            ) {
              if (
                !props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .serviceLife.annualLifeCostCalculation ||
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .serviceLife.annualLifeCostCalculation == 0
              )
                props.formikProps.values.fancades[
                  props.fancadeNumber - 1
                ].serviceLife.annualLifeCostCalculation = 1;
              else
                props.formikProps.values.fancades[
                  props.fancadeNumber - 1
                ].serviceLife.annualLifeCostCalculation = 0;
              setUpdate(!update);
            }
          }}
        >
          {t(`formLabels.details.serviceLife.annualLifeCostCalculation`)}
        </FormikCheckbox>
        {!(
          props.formikProps.values.fancades[props.fancadeNumber - 1].serviceLife
            .annualLifeCostCalculation &&
          props.formikProps.values.fancades[props.fancadeNumber - 1].serviceLife
            .annualLifeCostCalculation == 1
        ) ? (
          <>
            <FormRow
              fields={[
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].serviceLife.annualLifeCost`,
                  label: t(`formLabels.details.serviceLife.annualLifeCost`),
                  placeholder: t(
                    `formPlaceholders.details.serviceLife.annualLifeCost`
                  ),
                  type: "number",
                  width: 100,
                },
              ]}
            />
          </>
        ) : (
          <Container
            title={
              t(`formLabels.details.serviceLife.annualLifeCost`) +
              t(`titles.calculatedSection`)
            }
            bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
            direction={"column"}
            spacing={"1rem"}
          >
            <FormRow
              fields={[
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].serviceLife.annualLifeCost`,
                  label: t(`formLabels.details.serviceLife.annualLifeCost`),
                  placeholder: t(
                    `formPlaceholders.details.serviceLife.annualLifeCost`
                  ),
                  type: "number",
                  width: 100,
                  disabled: true,
                  value: calculateServiceOfLifeTotal(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                  ),
                },
              ]}
            />
            {/* Integrated Systems */}
            <FormikCheckbox
              checked={
                !(
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .serviceLife?.annualIntegratedImplantsCostCalculation &&
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .serviceLife?.annualIntegratedImplantsCostCalculation == 1
                )
              }
              defaultChecked={
                !(
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .serviceLife?.annualIntegratedImplantsCostCalculation &&
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .serviceLife?.annualIntegratedImplantsCostCalculation == 1
                )
              }
              onChange={() => {
                if (
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .serviceLife
                ) {
                  if (
                    !props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .serviceLife.annualIntegratedImplantsCostCalculation ||
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .serviceLife.annualIntegratedImplantsCostCalculation == 0
                  )
                    props.formikProps.values.fancades[
                      props.fancadeNumber - 1
                    ].serviceLife.annualIntegratedImplantsCostCalculation = 1;
                  else
                    props.formikProps.values.fancades[
                      props.fancadeNumber - 1
                    ].serviceLife.annualIntegratedImplantsCostCalculation = 0;
                  setUpdate(!update);
                }
              }}
            >
              {t(
                `formLabels.details.serviceLife.annualIntegratedImplantsCostCalculation`
              )}
            </FormikCheckbox>
            {!(
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .serviceLife.annualIntegratedImplantsCostCalculation &&
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .serviceLife.annualIntegratedImplantsCostCalculation == 1
            ) ? (
              <>
                <FormRow
                  fields={[
                    {
                      name: `fancades[${
                        props.fancadeNumber - 1
                      }].serviceLife.annualIntegratedImplantsCost`,
                      label: t(
                        `formLabels.details.serviceLife.annualIntegratedImplantsCost`
                      ),
                      placeholder: t(
                        `formPlaceholders.details.serviceLife.annualIntegratedImplantsCost`
                      ),
                      type: "number",
                      width: 100,
                    },
                  ]}
                />
              </>
            ) : (
              <Container
                title={
                  t(
                    `formLabels.details.serviceLife.annualIntegratedImplantsCost`
                  ) + t(`titles.calculatedSection`)
                }
                bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                direction={"column"}
                spacing={"1rem"}
              >
                <FormRow
                  fields={[
                    {
                      name: `fancades[${
                        props.fancadeNumber - 1
                      }].serviceLife.annualIntegratedImplantsCost`,
                      label: t(
                        `formLabels.details.serviceLife.annualIntegratedImplantsCost`
                      ),
                      placeholder: t(
                        `formPlaceholders.details.serviceLife.annualIntegratedImplantsCost`
                      ),
                      type: "number",
                      width: 100,
                      disabled: true,
                      value: calculateServiceOfLifeIntegratedImplantsTotal(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ]
                      ),
                    },
                  ]}
                />

                {/* Shieldings */}
                <FormikCheckbox
                  marginTop={"1rem"}
                  checked={
                    !(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .serviceLife?.annualShieldingCostsCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .serviceLife?.annualShieldingCostsCalculation === 1
                    )
                  }
                  defaultChecked={
                    !(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .serviceLife?.annualShieldingCostsCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .serviceLife?.annualShieldingCostsCalculation === 1
                    )
                  }
                  onChange={() => {
                    if (
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .serviceLife
                    ) {
                      if (
                        !props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].serviceLife.annualShieldingCostsCalculation ||
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].serviceLife.annualShieldingCostsCalculation == 0
                      )
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].serviceLife.annualShieldingCostsCalculation = 1;
                      else
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].serviceLife.annualShieldingCostsCalculation = 0;
                      setUpdate(!update);
                    }
                  }}
                >
                  {t(
                    `formLabels.details.serviceLife.annualShieldingCostsCalculation`
                  )}
                </FormikCheckbox>
                {!(
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .serviceLife.annualShieldingCostsCalculation &&
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .serviceLife.annualShieldingCostsCalculation == 1
                ) ? (
                  <>
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].serviceLife.annualShieldingsCost`,
                          label: t(
                            `formLabels.details.serviceLife.annualShieldingsCost`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.serviceLife.annualShieldingsCost`
                          ),
                          type: "number",
                          width: 100,
                        },
                      ]}
                    />
                  </>
                ) : (
                  <Container
                    title={
                      t(`formLabels.details.serviceLife.annualShieldingsCost`) +
                      t(`titles.calculatedSection`)
                    }
                    bodyStyle={{
                      backgroundColor: theme.colors.lightOrange,
                    }}
                    direction={"column"}
                    spacing={"1rem"}
                  >
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].serviceLife.annualShieldingsCost`,
                          label: t(
                            `formLabels.details.serviceLife.annualShieldingsCost`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.serviceLife.annualShieldingsCost`
                          ),
                          type: "number",
                          width: 100,
                          disabled: true,
                          value:
                            +props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject.shieldingSystemsNumber *
                            +props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].serviceLife.annualShieldingsConsume *
                            +props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].serviceLife.energeticShieldingVectorUnitPrice,
                        },
                      ]}
                    />

                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].fancadeTechnicalProject.shieldingSystemsNumber`,
                          label: t(
                            `formLabels.details.fancadeProject.shieldingSystemsNumber`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.fancadeProject.shieldingSystemsNumber`
                          ),
                          type: "number",
                          width: 100,
                          disabled: true,
                          value:
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject.shieldingSystemsNumber,
                        },
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].serviceLife.annualShieldingsConsume`,
                          label: t(
                            `formLabels.details.serviceLife.annualShieldingsConsume`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.serviceLife.annualShieldingsConsume`
                          ),
                          type: "number",
                          width: 100,
                        },
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].serviceLife.energeticShieldingVectorUnitPrice`,
                          label: t(
                            `formLabels.details.serviceLife.energeticShieldingVectorUnitPrice`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.serviceLife.energeticShieldingVectorUnitPrice`
                          ),
                          type: "number",
                          width: 100,
                        },
                      ]}
                    />
                  </Container>
                )}

                {/* Ventilation */}
                <FormikCheckbox
                  marginTop={"1rem"}
                  checked={
                    !(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .serviceLife?.annualVentilationCostsCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .serviceLife?.annualVentilationCostsCalculation === 1
                    )
                  }
                  defaultChecked={
                    !(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .serviceLife?.annualVentilationCostsCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .serviceLife?.annualVentilationCostsCalculation === 1
                    )
                  }
                  onChange={() => {
                    if (
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .serviceLife
                    ) {
                      if (
                        !props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].serviceLife.annualVentilationCostsCalculation ||
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].serviceLife.annualVentilationCostsCalculation == 0
                      )
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].serviceLife.annualVentilationCostsCalculation = 1;
                      else
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].serviceLife.annualVentilationCostsCalculation = 0;
                      setUpdate(!update);
                    }
                  }}
                >
                  {t(
                    `formLabels.details.serviceLife.annualVentilationCostsCalculation`
                  )}
                </FormikCheckbox>
                {!(
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .serviceLife.annualVentilationCostsCalculation &&
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .serviceLife.annualVentilationCostsCalculation == 1
                ) ? (
                  <>
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].serviceLife.annualVentilationCost`,
                          label: t(
                            `formLabels.details.serviceLife.annualVentilationCost`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.serviceLife.annualVentilationCost`
                          ),
                          type: "number",
                          width: 100,
                        },
                      ]}
                    />
                  </>
                ) : (
                  <Container
                    title={
                      t(
                        `formLabels.details.serviceLife.annualVentilationCost`
                      ) + t(`titles.calculatedSection`)
                    }
                    bodyStyle={{
                      backgroundColor: theme.colors.lightOrange,
                    }}
                    direction={"column"}
                    spacing={"1rem"}
                  >
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].serviceLife.annualVentilationCost`,
                          label: t(
                            `formLabels.details.serviceLife.annualVentilationCost`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.serviceLife.annualVentilationCost`
                          ),
                          type: "number",
                          width: 100,
                          disabled: true,
                          value:
                            +props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject.ventilationDevicesNumber *
                            +props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].serviceLife.annualVentilationConsume *
                            +props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].serviceLife.energeticVentilationVectorUnitPrice,
                        },
                      ]}
                    />

                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].fancadeTechnicalProject.ventilationDevicesNumber`,
                          label: t(
                            `formLabels.details.fancadeProject.ventilationDevicesNumber`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.fancadeProject.ventilationDevicesNumber`
                          ),
                          type: "number",
                          width: 100,
                          disabled: true,
                          value:
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject.ventilationDevicesNumber,
                        },
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].serviceLife.annualVentilationConsume`,
                          label: t(
                            `formLabels.details.serviceLife.annualVentilationConsume`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.serviceLife.annualVentilationConsume`
                          ),
                          type: "number",
                          width: 100,
                        },
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].serviceLife.energeticVentilationVectorUnitPrice`,
                          label: t(
                            `formLabels.details.serviceLife.energeticVentilationVectorUnitPrice`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.serviceLife.energeticVentilationVectorUnitPrice`
                          ),
                          type: "number",
                          width: 100,
                        },
                      ]}
                    />
                  </Container>
                )}

                {/* Air Conditioning */}
                <FormikCheckbox
                  marginTop={"1rem"}
                  checked={
                    !(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .serviceLife?.annualConditioningCostsCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .serviceLife?.annualConditioningCostsCalculation === 1
                    )
                  }
                  defaultChecked={
                    !(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .serviceLife?.annualConditioningCostsCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .serviceLife?.annualConditioningCostsCalculation === 1
                    )
                  }
                  onChange={() => {
                    if (
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .serviceLife
                    ) {
                      if (
                        !props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].serviceLife.annualConditioningCostsCalculation ||
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].serviceLife.annualConditioningCostsCalculation == 0
                      )
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].serviceLife.annualConditioningCostsCalculation = 1;
                      else
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].serviceLife.annualConditioningCostsCalculation = 0;
                      setUpdate(!update);
                    }
                  }}
                >
                  {t(
                    `formLabels.details.serviceLife.annualConditioningCostsCalculation`
                  )}
                </FormikCheckbox>
                {!(
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .serviceLife.annualConditioningCostsCalculation &&
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .serviceLife.annualConditioningCostsCalculation == 1
                ) ? (
                  <>
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].serviceLife.annualConditioningCost`,
                          label: t(
                            `formLabels.details.serviceLife.annualConditioningCost`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.serviceLife.annualConditioningCost`
                          ),
                          type: "number",
                          width: 100,
                        },
                      ]}
                    />
                  </>
                ) : (
                  <Container
                    title={
                      t(
                        `formLabels.details.serviceLife.annualConditioningCost`
                      ) + t(`titles.calculatedSection`)
                    }
                    bodyStyle={{
                      backgroundColor: theme.colors.lightOrange,
                    }}
                    direction={"column"}
                    spacing={"1rem"}
                  >
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].serviceLife.annualConditioningCost`,
                          label: t(
                            `formLabels.details.serviceLife.annualConditioningCost`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.serviceLife.annualConditioningCost`
                          ),
                          type: "number",
                          width: 100,
                          disabled: true,
                          value:
                            +props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .airConditioningDevicesNumber *
                            +props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].serviceLife.annualConditioningConsume *
                            +props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].serviceLife.energeticConditioningVectorUnitPrice,
                        },
                      ]}
                    />

                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].fancadeTechnicalProject.airConditioningDevicesNumber`,
                          label: t(
                            `formLabels.details.fancadeProject.airConditioningDevicesNumber`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.fancadeProject.airConditioningDevicesNumber`
                          ),
                          type: "number",
                          width: 100,
                          disabled: true,
                          value:
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .airConditioningDevicesNumber,
                        },
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].serviceLife.annualConditioningConsume`,
                          label: t(
                            `formLabels.details.serviceLife.annualConditioningConsume`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.serviceLife.annualConditioningConsume`
                          ),
                          type: "number",
                          width: 100,
                        },
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].serviceLife.energeticConditioningVectorUnitPrice`,
                          label: t(
                            `formLabels.details.serviceLife.energeticConditioningVectorUnitPrice`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.serviceLife.energeticConditioningVectorUnitPrice`
                          ),
                          type: "number",
                          width: 100,
                        },
                      ]}
                    />
                  </Container>
                )}

                {/* Thermic solar */}
                <FormikCheckbox
                  marginTop={"1rem"}
                  checked={
                    !(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .serviceLife?.annualThermicSolarCostsCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .serviceLife?.annualThermicSolarCostsCalculation === 1
                    )
                  }
                  defaultChecked={
                    !(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .serviceLife?.annualThermicSolarCostsCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .serviceLife?.annualThermicSolarCostsCalculation === 1
                    )
                  }
                  onChange={() => {
                    if (
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .serviceLife
                    ) {
                      if (
                        !props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].serviceLife.annualThermicSolarCostsCalculation ||
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].serviceLife.annualThermicSolarCostsCalculation == 0
                      )
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].serviceLife.annualThermicSolarCostsCalculation = 1;
                      else
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].serviceLife.annualThermicSolarCostsCalculation = 0;
                      setUpdate(!update);
                    }
                  }}
                >
                  {t(
                    `formLabels.details.serviceLife.annualThermicSolarCostsCalculation`
                  )}
                </FormikCheckbox>
                {!(
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .serviceLife.annualThermicSolarCostsCalculation &&
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .serviceLife.annualThermicSolarCostsCalculation == 1
                ) ? (
                  <>
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].serviceLife.annualThermicSolarCost`,
                          label: t(
                            `formLabels.details.serviceLife.annualThermicSolarCost`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.serviceLife.annualThermicSolarCost`
                          ),
                          type: "number",
                          width: 100,
                        },
                      ]}
                    />
                  </>
                ) : (
                  <Container
                    title={
                      t(
                        `formLabels.details.serviceLife.annualThermicSolarCost`
                      ) + t(`titles.calculatedSection`)
                    }
                    bodyStyle={{
                      backgroundColor: theme.colors.lightOrange,
                    }}
                    direction={"column"}
                    spacing={"1rem"}
                  >
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].serviceLife.annualThermicSolarCost`,
                          label: t(
                            `formLabels.details.serviceLife.annualThermicSolarCost`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.serviceLife.annualThermicSolarCost`
                          ),
                          type: "number",
                          width: 100,
                          disabled: true,
                          value:
                            +props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject.thermicSolarPanelsNumber *
                            +props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].serviceLife.annualThermicSolarConsume *
                            +props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].serviceLife.energeticThermicSolarVectorUnitPrice,
                        },
                      ]}
                    />

                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].fancadeTechnicalProject.thermicSolarPanelsNumber`,
                          label: t(
                            `formLabels.details.fancadeProject.thermicSolarPanelsNumber`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.fancadeProject.thermicSolarPanelsNumber`
                          ),
                          type: "number",
                          width: 100,
                          disabled: true,
                          value:
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject.thermicSolarPanelsNumber,
                        },
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].serviceLife.annualThermicSolarConsume`,
                          label: t(
                            `formLabels.details.serviceLife.annualThermicSolarConsume`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.serviceLife.annualThermicSolarConsume`
                          ),
                          type: "number",
                          width: 100,
                        },
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].serviceLife.energeticThermicSolarVectorUnitPrice`,
                          label: t(
                            `formLabels.details.serviceLife.energeticThermicSolarVectorUnitPrice`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.serviceLife.energeticThermicSolarVectorUnitPrice`
                          ),
                          type: "number",
                          width: 100,
                        },
                      ]}
                    />
                  </Container>
                )}

                {/* Photovoltaic */}
                {/* <FormikCheckbox
                  checked={
                    false
                    // props.formikProps.values.fancades[props.fancadeNumber - 1]
                    //   .serviceLife.annualPhotovoltaicIsActive &&
                    // props.formikProps.values.fancades[props.fancadeNumber - 1]
                    //   .serviceLife.annualPhotovoltaicIsActive == 1
                  }
                  onChange={(e: any) => {
                    if (!props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                        ].serviceLife.annualPhotovoltaicIsActive ||
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].serviceLife.annualPhotovoltaicIsActive == 0) {
                      props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].serviceLife.annualPhotovoltaicIsActive = 1;
                    } else {
                      props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].serviceLife.annualPhotovoltaicIsActive = 0;
                    }

                    // if (!e.target.checked) {
                    //   props.formikProps.values.fancades[
                    //     props.fancadeNumber - 1
                    //   ].serviceLife.annualPhotovoltaicIsActive = 0;
                    // }
                    // props.formikProps.values.fancades[
                    //   props.fancadeNumber - 1
                    // ].serviceLife.annualPhotovoltaicIsActive =
                    //   !props.formikProps.values.fancades[props.fancadeNumber - 1]
                    //     .serviceLife.annualPhotovoltaicIsActive;
                    // setUpdate(!update);
                  }}
                >
                  {t(
                    "formLabels.details.serviceLife.annualPhotovoltaicIsActive"
                  )}
                </FormikCheckbox> */}
                {
                // props.formikProps.values.fancades[props.fancadeNumber - 1]
                //   .serviceLife.annualPhotovoltaicIsActive &&
                //   props.formikProps.values.fancades[props.fancadeNumber - 1]
                //     .serviceLife.annualPhotovoltaicIsActive == 1 && 
                    (
                    <>
                      {/* Photovoltaic */}
                      <FormikCheckbox
                        marginTop={"1rem"}
                        checked={
                          !(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].serviceLife?.annualPhotovoltaicCostsCalculation &&
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].serviceLife
                              ?.annualPhotovoltaicCostsCalculation === 1
                          )
                        }
                        defaultChecked={
                          !(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].serviceLife?.annualPhotovoltaicCostsCalculation &&
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].serviceLife
                              ?.annualPhotovoltaicCostsCalculation === 1
                          )
                        }
                        onChange={() => {
                          if (
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].serviceLife
                          ) {
                            if (
                              !props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].serviceLife
                                .annualPhotovoltaicCostsCalculation ||
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].serviceLife
                                .annualPhotovoltaicCostsCalculation == 0
                            )
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].serviceLife.annualPhotovoltaicCostsCalculation = 1;
                            else
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].serviceLife.annualPhotovoltaicCostsCalculation = 0;
                            setUpdate(!update);
                          }
                        }}
                      >
                        {t(
                          `formLabels.details.serviceLife.annualPhotovoltaicCostsCalculation`
                        )}
                      </FormikCheckbox>
                      {!(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].serviceLife.annualPhotovoltaicCostsCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].serviceLife.annualPhotovoltaicCostsCalculation == 1
                      ) ? (
                        <>
                          <FormRow
                            fields={[
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].serviceLife.annualPhotovoltaicCost`,
                                label: t(
                                  `formLabels.details.serviceLife.annualPhotovoltaicCost`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.serviceLife.annualPhotovoltaicCost`
                                ),
                                type: "number",
                                width: 100,
                              },
                            ]}
                          />
                        </>
                      ) : (
                        <Container
                          title={
                            t(
                              `formLabels.details.serviceLife.annualPhotovoltaicCost`
                            ) + t(`titles.calculatedSection`)
                          }
                          bodyStyle={{
                            backgroundColor: theme.colors.lightOrange,
                          }}
                          direction={"column"}
                          spacing={"1rem"}
                        >
                          <FormRow
                            fields={[
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].serviceLife.annualPhotovoltaicCost`,
                                label: t(
                                  `formLabels.details.serviceLife.annualPhotovoltaicCost`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.serviceLife.annualPhotovoltaicCost`
                                ),
                                type: "number",
                                width: 100,
                                disabled: true,
                                value:
                                  +props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].fancadeTechnicalProject
                                    .photovoltaicModulesNumber *
                                  +props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].serviceLife.annualPhotovoltaicConsume *
                                  +props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].serviceLife
                                    .energeticPhotovoltaicVectorUnitPrice,
                              },
                            ]}
                          />

                          <FormRow
                            fields={[
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].fancadeTechnicalProject.photovoltaicModulesNumber`,
                                label: t(
                                  `formLabels.details.fancadeProject.photovoltaicModulesNumber`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.fancadeProject.photovoltaicModulesNumber`
                                ),
                                type: "number",
                                width: 100,
                                disabled: true,
                                value:
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].fancadeTechnicalProject
                                    .photovoltaicModulesNumber,
                              },
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].serviceLife.annualPhotovoltaicConsume`,
                                label: t(
                                  `formLabels.details.serviceLife.annualPhotovoltaicConsume`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.serviceLife.annualPhotovoltaicConsume`
                                ),
                                type: "number",
                                width: 100,
                              },
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].serviceLife.energeticPhotovoltaicVectorUnitPrice`,
                                label: t(
                                  `formLabels.details.serviceLife.energeticPhotovoltaicVectorUnitPrice`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.serviceLife.energeticPhotovoltaicVectorUnitPrice`
                                ),
                                type: "number",
                                width: 100,
                              },
                            ]}
                          />
                        </Container>
                      )}
                    </>
                  )}
              </Container>
            )}

            <FormikCheckbox
              checked={
                !(
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .serviceLife?.annualOtherImplantsCostCalculation &&
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .serviceLife?.annualOtherImplantsCostCalculation == 1
                )
              }
              defaultChecked={
                !(
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .serviceLife?.annualOtherImplantsCostCalculation &&
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .serviceLife?.annualOtherImplantsCostCalculation == 1
                )
              }
              onChange={() => {
                if (
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .serviceLife
                ) {
                  if (
                    !props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .serviceLife.annualOtherImplantsCostCalculation ||
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .serviceLife.annualOtherImplantsCostCalculation == 0
                  )
                    props.formikProps.values.fancades[
                      props.fancadeNumber - 1
                    ].serviceLife.annualOtherImplantsCostCalculation = 1;
                  else
                    props.formikProps.values.fancades[
                      props.fancadeNumber - 1
                    ].serviceLife.annualOtherImplantsCostCalculation = 0;
                  setUpdate(!update);
                }
              }}
            >
              {t(
                `formLabels.details.serviceLife.annualOtherImplantsCostCalculation`
              )}
            </FormikCheckbox>
            {!(
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .serviceLife.annualOtherImplantsCostCalculation &&
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .serviceLife.annualOtherImplantsCostCalculation == 1
            ) ? (
              <>
                <FormRow
                  fields={[
                    {
                      name: `fancades[${
                        props.fancadeNumber - 1
                      }].serviceLife.annualOtherImplantsCost`,
                      label: t(
                        `formLabels.details.serviceLife.annualOtherImplantsCost`
                      ),
                      placeholder: t(
                        `formPlaceholders.details.serviceLife.annualOtherImplantsCost`
                      ),
                      type: "number",
                      width: 100,
                    },
                  ]}
                />
              </>
            ) : (
              <Container
                title={
                  t(`formLabels.details.serviceLife.annualOtherImplantsCost`) +
                  t(`titles.calculatedSection`)
                }
                bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                direction={"column"}
                spacing={"1rem"}
              >
                <FormRow
                  fields={[
                    {
                      name: `fancades[${
                        props.fancadeNumber - 1
                      }].serviceLife.annualOtherImplantsCost`,
                      label: t(
                        `formLabels.details.serviceLife.annualOtherImplantsCost`
                      ),
                      placeholder: t(
                        `formPlaceholders.details.serviceLife.annualOtherImplantsCost`
                      ),
                      type: "number",
                      width: 100,
                      disabled: true,
                      value: calculateServiceOfLifeOtherImplantsTotal(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ]
                      ),
                    },
                  ]}
                />

                {currentFancade?.serviceLife?.implants?.map(
                  (implant: Implant, index) => (
                    <>
                      <Divider orientation="horizontal" />
                      <Flex w={"100%"} alignItems={"center"}>
                        <Text fontSize={"2xl"} color={theme.colors.darkGrey2}>
                          {t("formLabels.details.serviceLife.otherImplant")} #
                          {index + 1}
                        </Text>
                        <CloseIcon
                          color={theme.colors.euracOrange}
                          w={"0.7rem"}
                          marginLeft={"0.5rem"}
                          marginTop={"0.5rem"}
                          onClick={() =>
                            deleteImplant(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ]?.serviceLife,
                              index
                            )
                          }
                        />
                      </Flex>
                      <FormikCheckbox
                        paddingTop={"2rem"}
                        checked={
                          !(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].serviceLife.implants[index]
                              .annualImplantCostCalculation &&
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].serviceLife.implants[index]
                              .annualImplantCostCalculation == 1
                          )
                        }
                        defaultChecked={
                          !(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].serviceLife.implants[index]
                              .annualImplantCostCalculation &&
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].serviceLife.implants[index]
                              .annualImplantCostCalculation == 1
                          )
                        }
                        onChange={() => {
                          if (
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].serviceLife
                          ) {
                            if (
                              !props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].serviceLife.implants[index]
                                .annualImplantCostCalculation ||
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].serviceLife.implants[index]
                                .annualImplantCostCalculation == 0
                            )
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].serviceLife.implants[
                                index
                              ].annualImplantCostCalculation = 1;
                            else
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].serviceLife.implants[
                                index
                              ].annualImplantCostCalculation = 0;
                            setUpdate(!update);
                          }
                        }}
                      >
                        {t(
                          `formLabels.details.serviceLife.annualOtherImplantsCostCalculation`
                        ) +
                          " #" +
                          (+index + 1)}
                      </FormikCheckbox>
                      {!(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].serviceLife.implants[index]
                          .annualImplantCostCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].serviceLife.implants[index]
                          .annualImplantCostCalculation == 1
                      ) ? (
                        <>
                          <FormRow
                            fields={[
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].serviceLife.implants[${index}].annualImplantCost`,
                                label: t(
                                  `formLabels.details.serviceLife.annualOtherImplantsCost`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.serviceLife.annualOtherImplantsCost`
                                ),
                                type: "number",
                                width: 100,
                              },
                            ]}
                          />
                        </>
                      ) : (
                        <Container
                          title={
                            t(
                              `formLabels.details.serviceLife.annualOtherImplantsCost`
                            ) + t(`titles.calculatedSection`)
                          }
                          bodyStyle={{
                            backgroundColor: theme.colors.lightOrange,
                          }}
                          direction={"column"}
                          spacing={"1rem"}
                        >
                          <FormRow
                            fields={[
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].serviceLife.implants[${index}].annualImplantCost`,
                                label: t(
                                  `formLabels.details.serviceLife.annualOtherImplantsCost`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.serviceLife.annualOtherImplantsCost`
                                ),
                                type: "number",
                                width: 100,
                                disabled: true,
                                value:
                                  +props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].serviceLife.implants[index]
                                    .annualImplantConsume *
                                  +props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].serviceLife.implants[index]
                                    .energeticImplantVectorUnitPrice,
                              },
                            ]}
                          />

                          <FormRow
                            fields={[
                              {
                                name:
                                  "fancades[" +
                                  (props.fancadeNumber - 1) +
                                  "].serviceLife.implants[" +
                                  index +
                                  "].annualImplantConsume",
                                label: t(
                                  "formLabels.details.serviceLife.annualImplantConsume"
                                ),
                                placeholder: t(
                                  "formPlaceholders.details.serviceLife.annualImplantConsume"
                                ),
                                type: "number",
                                width: 100,
                              },
                              {
                                name:
                                  "fancades[" +
                                  (props.fancadeNumber - 1) +
                                  "].serviceLife.implants[" +
                                  index +
                                  "].energeticImplantVectorUnitPrice",
                                label: t(
                                  "formLabels.details.serviceLife.energeticImplantVectorUnitPrice"
                                ),
                                placeholder: t(
                                  "formPlaceholders.details.serviceLife.energeticImplantVectorUnitPrice"
                                ),
                                type: "number",
                                width: 100,
                              },
                            ]}
                          />
                        </Container>
                      )}
                    </>
                  )
                )}
                <Center paddingTop={"1rem"}>
                  <PrimaryButton
                    width={"50%"}
                    onClick={() => {
                      addImplant(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ]?.serviceLife
                      );
                      setUpdate(!update);
                    }}
                  >
                    {t("formLabels.details.serviceLife.addOtherImplant")}
                  </PrimaryButton>
                </Center>
              </Container>
            )}
          </Container>
        )}
      </Stack>
    );
  else return <Redirect to={"/"} />;
});
