const it = {
  loading: "Caricamento...",
  currency: "€",
  yes: "Sì",
  no: "No",
  pdfName: "report",
  plots: "Grafici",
  attribute: "Attributo",
  fancade: "Facciata",
  selectFancade: "Seleziona la facciata da confrontare - progetto #",
  windows: "Finestre",
  systemsIntegration: "Integrazione Impiantistica",
  customSystemsIntegrations: "Integrazioni impiantistiche personalizzate",
  customSystemsIntegration: "Integrazione impiantistica personalizzata",

  totalPrice: "Prezzo totale (€)",
  units: "Numero unità (u)",
  unitPrice: "Prezzo unitario (€/u)",
  manualInput: "Selezionare per inserire costi per campi",
  scrap: "Sfrido",
  role: {
    user: "Utente",
    admin: "Amministratore",
  },
  selectOptions: {
    transparentWithoutSpandrel:
      "Trasparente - a cellule senza pannello spandrel opaco",
    transparentWithSpandrel:
      "Trasparente - a cellule con pannello spandrel opaco",
    transparentUprights: "Trasparente - montanti e traversi",
    opaqueWoodWithVentilation: "Opaca - legno con facciata ventilata",
    opaqueWoodWithoutVentilation: "Opaca - legno senza facciata ventilata",
    opaqueConcreteWithVentilation:
      "Opaca - calcestruzzo con facciata ventilata",
    opaqueConcreteWithoutVentilation:
      "Opaca - calcestruzzo senza facciata ventilata",
    opaqueWallingWithVentilation: "Opaca - muratura con facciata ventilata",
    opaqueWallingWithoutVentilation:
      "Opaca - muratura senza facciata ventilata",
    opaqueWindows: "Opaca - finestre",
    opaqueModularRetrofit: "Opaca - retrofit modulare",
    windows: "Finestre",

    adminOnlyProjects: "Visualizza solo i tuoi progetti",
    allProjects: "Visualizza i tuoi progetti e quelli degli altri utenti",
    usersOnlyProjects: "Visualizza solo i progetti degli altri utenti",
    copyFancade: "Importa dati dalla facciata...",
    copyFancadeOption: "Importa dati dalla facciata #",
    copySection: "Seleziona da quale sezione importare i dati",
  },
  warnings: {
    cancel: "Annulla",
    confirm: "Continua",
    deleteProjectTitle: "Eliminazione progetto",
    deleteProject:
      "Questa azione non è reversibile, vuoi continuare con l'eliminazione del progetto?",
    deleteProjectsList: "I seguenti progetti verranno eliminati:",
    deleteFancadeTitle: "Eliminazione facciata",
    deleteFancade:
      "Questa azione non è reversibile, vuoi continuare con l'eliminazione della facciata?",

    deleteUserTitle: "Eliminazione utente",
    deleteUser:
      "Questa azione non è reversibile, vuoi continuare con l'eliminazione dell'utente?",

    deleteCategoryTitle: "Eliminazione categoria",
    deleteCategory:
      "Questa azione non è reversibile, vuoi continuare con l'eliminazione della categoria?",

    calculationChange:
      "Cliccando su questo interruttore verrà modificata la modalità di calcolo",
    isBenchmarkChange: "La facciata #1 viene considerata come benchmark",
    chooseFancadeName:
      "Inserisci un nome personalizzato per la facciata selezionata",
    resetFancadeName:
      "Lasciare il campo vuoto per ripristinare il nome della facciata",
    surfaceTooHigh:
      "La superficie inserita è maggiore della superficie della facciata",
    note: "Clicca per visualizzare o modificare la nota relativa a questo campo",
  },
  errors: {
    generic: "Errore",
    noFancades: "I progetti selezionati non hanno facciate",
    emptyTable: "Questa tabella è vuota",
    missingCategory: "Inserire un nome per la categoria",
    updates: {
      project: "C'è stato un problema con il salvataggio del progetto",
    },
    login: {
      missingEmail: "Inserire l'indirizzo e-mail",
      missingPassword: "Inserire la password",
      invalidEmail: "Inserire un indirizzo e-mail valido",
      wrongEmail:
        "L'indirizzo e-mail inserito non appartiene ad un utente registrato",
      failedTitle: "Login fallito",
      failedDescription:
        "Le credenziali inserite sono errate o appartengono ad un utente disabilitato",
    },
    signUp: {
      missingEmail: "Inserire l'indirizzo e-mail",
      missingPassword: "Inserire la password",
      invalidEmail: "Inserire un indirizzo e-mail valido",
      shortPassword: "La password deve essere composta almeno da 8 caratteri",
      alreadyExists:
        "L'indirizzo e-mail inserito corrisponde ad un utente già esistente",
    },
    newProject: {
      missingName: "Inserire il nome",
      missingProjectType: "Inserire il tipo di progetto",
      missingUseDestination: "Inserire la destinazione d'uso",
      missingConstructionYear: "Inserire l'anno di costruzione",
      missingLocation: "Inserire la località",
      missingAuthor: "Inserire l'autore",
      missingDescription: "Inserire una descrizione",
      missingEnergyCertification: "Inserire la certificazione",
      projectCreation: "C'è stato un errore durante la creazione del progetto",
    },
    noValuesPlot:
      "Non sono presenti tutti i valori necessari a calcolare questo grafico",
  },
  successes: {
    updates: {
      projectSuccessful: "Progetto aggiornato",
      categorySuccessful: "Categoria aggiornata",
      userSuccessful: "Utente aggiornato",
      userDisabled: "Utente disabilitato",
      userEnabled: "Utente abilitato",
    },
    creates: {
      projectSuccessful: "Progetto creato",
      userSuccessful: "Utente creato",
      categorySuccessful: "Categoria creata",
    },
    deletes: {
      projectSuccessful: "Progetto eliminato",
      projectsSuccessful: "Progetti eliminati",
      userSuccessful: "Utente eliminato",
      categorySuccessful: "Categoria eliminata",
    },
  },
  buttons: {
    actions: "Azioni",
    login: "Login",
    export: "Esporta",
    pdf: "PDF",
    save: "Salva",
    goBack: "Torna indietro",
    addCategory: "Crea categoria",
    changeCategory: "Sposta progetti selezionati nella categoria...",
    editCategory: "Modifica categoria",
    deleteCategory: "Elimina categoria",
    addWindow: "Aggiungi tipologia di finestra",
    close: "Chiudi",
    cancel: "Annulla",
    createProject: "Crea progetto",
    compare: "Confronta progetti selezionati",
    delete: "Elimina",
    deleteProject: "Elimina progetto",
    deleteProjects: "Elimina i progetti selezionati",
    new: "Nuovo",
    addDetail: "Aggiungi modulo",
    addSystemsIntegration: "Aggiungi integrazione impiantistica",
    addHardware: "Aggiungi elemento ferramenta e accessori",
    addPackaging: "Aggiungi tipo di imballaggio",
    addAdditionalMaterial: "Aggiungi materiale addizionale",
    addTax: "Aggiungi tasse e tributi",
    addAction: "Aggiungi azione",
    addNewField: "Aggiungi campo",
    addNewCustomField: "Aggiungi un campo personalizzato",
    addUser: "Crea utente",
    updateFancadeName: "Aggiorna nome facciata",
    importData: "Importa dati",
    duplicateProject: "Duplica il progetto selezionato",
    saveNote: "Aggiungi nota",
    resetNote: "Annulla modifiche",
  },
  formLabels: {
    email: "Email",
    password: "Password",
    isBenchmark: "Differenza con benchmark",
    category: "Nome categoria",
    board: {
      project: "Ruolo dell'utente",
    },
    details: {
      general: {
        id: "Id progetto",
        name: "Nome progetto",
        location: "Luogo",
        reportAuthor: "Autore dell'analisi",
        description: "Descrizione",
        type: "Tipo di progetto",
        certification: "Prestazione energetica edificio",
        useDestination: "Destinazione d'uso / tipologia edificio",
        constructionYear: "Anno di costruzione",
        isBenchmark: "Tipo di analisi",
        benchmarkOption:
          "Comparativa (le facciate create verranno confrontate con il benchmark creato dall'utente)",
        notBenchmarkOption:
          "Assoluta (tutte le facciate create compongono l'involucro dell'edificio)",
      },
      fancadeProject: {
        type: "Tipo facciata analizzata",
        types: {
          transparent1: "Trasparente - a cellule senza pannello spandrel opaco",
          transparent2: "Trasparente - a cellule con pannello spandrel opaco",
          transparent3: "Trasparente - montanti e traversi",
          opaque1: "Opaca - legno con facciata ventilata",
          opaque2: "Opaca - legno senza facciata ventilata",
          opaque3: "Opaca - calcestruzzo con facciata ventilata",
          opaque4: "Opaca - calcestruzzo senza facciata ventilata",
          opaque5: "Opaca - muratura con facciata ventilata",
          opaque6: "Opaca - muratura senza facciata ventilata",
          windows: "Finestre",
        },
        customField: "Campi personalizzati",
        customFieldModalTitle: "Aggiunta di un nuovo campo",
        fieldName: "Nome del campo",
        fieldValue: "Valore del campo",
        surface: "Superficie di facciata (m²)",
        //Involucro opaco
        transparentTotalPrice: "Prezzo totale finestre (trasparente) (€)",
        windowsTotalPrice: "Prezzo totale finestre (opaco) (€)",
        opaqueShell: "Involucro",
        externalCladdingUnitsNumber: "Numero pannelli di rivestimento esterno", //rivestimento
        externalCladdingUnitsSurface:
          "Superficie unitaria pannelli di rivestimento esterno (m²/u)",
        totalExternalCladdingSurface: "Superficie totale rivestimento esterno", //rivestimento
        insulationSurface: "Superficie isolamento", //isolamento
        supportingElementsQuantity: "Metri lineari di sottostruttura", //elementi portanti
        prefabricatedModulesQuantity: "Quantità moduli prefabbricati",
        modulesSurface: "Superficie totale dei moduli (m²)",
        opaqueAnchoringsNumber: "Numero ancoraggi puntuali", //ancoraggi
        opaqueFixingsNumber: "Numero fissaggi (componenti di facciata)", //fissaggi
        //Involucro trasparente
        glazedCellsNumber: "Numero cellule vetrate",
        glazedCellsSurface: "Superficie unitaria cellula vetrata",
        doubleGlazingQuantity: "Quantità vetrocamera",
        doubleGlazingSurface: "Superficie vetrocamera",
        mainFrameHeightPerUnit: "Altezza main frame per unità vetrata",
        mainFrameWidthPerUnit: "Larghezza main frame per unità vetrata",
        totalMainFrameLength: "Lunghezza totale main frame",
        totalGasketLength: "Lunghezza totale guarnizioni",
        opaqueSpandrelPanelsQuantity: "Quantità pannelli spandrel opachi",
        opaqueSpandrelPanelsSurface: "Superficie pannelli spandrel opachi",
        transparentAnchoringsNumber: "Numero ancoraggi puntuali",
        transparentFixingsNumber: "Numero fissaggi (componenti di facciata)",
        //Altro
        transparentShell: "Involucro trasparente",
        plantIntegration: "Integrazioni impiantistiche in facciata/edificio",
        technicalParameters: "Parametri fisici della facciata",
        windowOpaqueSurfaceRatio:
          "Rapporto superficie finestrata su superficie opaca",
        opaqueUValue: "U-value (parte opaca)",
        transparentUValue: "U-value (parte trasparente)",
        windowGlassGValue: "g-value (vetro finestra)",
        fancadeGlassGValue: "g-value (vetro facciata continua trasparente)",
        prefabricationLevel: "Livello di prefabbricazione",
        geometricDetails: {
          module: "Modulo",
          width: "Larghezza (cm)",
          length: "Lunghezza (cm)",
          depth: "Spessore (cm)",
        },
        systemsIntegration: {
          commonName: "Altre integrazioni impiantistiche",
          name: "Nome",
          units: "Numero unità (u)",
          unitSurface: "Superficie unitaria (m²/u)",
          totalSurface: "Superficie totale (m²)",
          type: "Tipo di integrazione impiantistica",
          surfaceRatio:
            "Rapporto superficie impianto su superficie facciata (%) ",
        },
        systemsSurfacePercentage:
          "Percentuale di superficie occupata dagli impianti sul totale della facciata (totale per tutti gli impianti)",
        windowType: {
          windowType: "Tipologia di finestra",
          windowsNumber: "Quantità finestre",
          windowsUnitSurface: "Superficie unitaria finestre (m²)",
          windowsSurface: "Superficie finestre (m²)",
          doubleGlazingNumber: "Quantità vetrocamera", //vetrocamera
          doubleGlazingSurface: "Superficie vetrocamera", //vetrocamera
          loomHeight: "Altezza telaio", //telaio
          loomWidth: "Larghezza telaio", //telaio
          totalLoomLength: "Lunghezza totale telaio", //telaio
          totalGasketLength: "Lunghezza totale guarnizioni", //guarnizione
        },
        //Integrazione impiantistica
        shieldingSystemsNumber: "Numero di sistemi di schermatura (u)",
        ventilationDevicesNumber: "Numero di apparecchi di ventilazione (u)",
        airConditioningDevicesNumber:
          "Numero di dispositivi per il condizionamento dell´aria (u)",
        thermicSolarPanelsNumber: "Numero di pannelli solari termici (u)",
        thermicSolarPanelsSurface: "Superficie pannelli solari termici (m²)",
        thermicSolarPanelsUnitSurface:
          "Superficie pannelli solari termici (m²/u)",
        photovoltaicModulesNumber: "Numero moduli fotovoltaici (u)",
        photovoltaicModulesSurface: "Superficie moduli fotovoltaici (m²)",
        photovoltaicModulesUnitSurface:
          "Superficie unitaria moduli fotovoltaici (m²/u)",
      },
      fancadePrice: {
        totalCheckbox:
          "Selezionare per inserire costi per campi del prezzo totale",
        totalDesignPrice: "Prezzo progettazione totale",
        technicalProject: "Progetto tecnico",
        structuralSismicProject: "Progetto strutturale e sismico",
        lightingTechnicalProject: "Progetto illuminotecnico",
        ventilationProject: "Progetto ventilazione",
        acusticProject: "Progetto acustico",
        airQualityCheck: "Verifica qualità dell'aria",
        constructionPrinceAnalysis: "Analisi del prezzo di costruzione",
        firePreventionDesign: "Progettazione antincendio",
        buildingSiteManagement: "Gestione del cantiere - progetto",
        buildingSiteSafety: "Sicurezza in cantiere - progetto",
        energeticPerformanceAnalysis:
          "Valutazione e certificazione della performance energetica",
        competitionProject: "Progetto per base gara",
        maintenancePlan: "Piano di manutenzione",
        userManual: "Manuale d'uso",
      },
      fancadeProduction: {
        totalCheckbox:
          "Selezionare per inserire costi per campi del prezzo totale",
        materials: "Materiali",
        totalMaterialsCheckbox:
          "Selezionare per inserire costi per campi del prezzo totale dei materiali",
        opaqueShieldingMaterials: "Involucro opaco",
        transparentShieldingMaterials: "Involucro trasparente",
        plantIntegration: "Integrazione impiantistica",
        scrap: "Sfrido",

        totalProductionPrice: "Prezzo totale produzione (€)",
        totalMaterialsPrice: "Prezzo totale materiali (€)",

        fancadeSurface: "Superficie di facciata (m²)",
        windowsTitle: "Prezzo finestre per tipologia",
        windowByTotal:
          "Selezionare per inserire costi per campi del prezzo totale delle finestre della facciata",
        checkboxTotalWindowPrice:
          "Selezionare per inserire costi per campi del prezzo della tipologia di finestra",
        transparentTotalPrice: "Prezzo totale involuco trasparente",
        windowsTotalPrice: "Prezzo totale finestre (€)",
        windowContainer: "Tipologia di finestra",
        totalWindowPrice: "Prezzo totale tipologia di finestra (€)",
        windowSelect: "Seleziona la modalità di calcolo",
        unityOption: "Calcola per unità",
        componentsOption: "Calcolo per componenti",
        windowsNumber: "Quantità finestre (u)",
        unitPrice: "Prezzo finestre al mq (€/m²)",
        loomByTotal:
          "Selezionare per inserire costi per campi del prezzo del telaio",
        loomComponentsPrice: "Prezzo componenti finestra - telaio (€)",
        loomUnitPrice: "Prezzo componenti finestra - telaio (€)",
        totalLoomLength: "Lunghezza totale telaio (m)",
        doubleGlazingComponentsPrice:
          "Prezzo componenti finestra - vetrocamera (€)",
        doubleGlazingByTotal:
          "Selezionare per inserire costi per campi del prezzo della vetrocamera",
        prefabricatedModulesUnitPrice: "Prezzo moduli al mq (€/m²)",
        prefabricatedModulesQuantity: "Quantità moduli prefabbricati (u)",
        doubleGlazingNumber: "Quantità vetrocamera (u)",
        doubleGlazingUnitPrice: "Prezzo unitario vetrocamera (€/u)",
        gasketByTotal:
          "Selezionare per inserire costi per campi del prezzo delle guarnizioni",
        gasketComponentsPrice: "Prezzo componenti finestra - guarnizioni (€)",
        gasketsUnitPrice: "Prezzo componenti finestra - guarnizioni (€)",
        totalGasketLength: "Lunghezza totale guarnizioni (m)",
        externalCladdingByTotal:
          "Selezionare per inserire costi per campi del prezzo del rivestimento esterno",
        externalCladdingTotalPrice:
          "Prezzo involucro opaco - rivestimento esterno (€)",
        externalCladdingUnitsSurface:
          "Superficie unitaria pannelli di rivestimento esterno (m²/u)",

        totalExternalCladdingSurface:
          "Superficie totale rivestimento esterno (m²)",
        externalCladdingUnitPrice:
          "Prezzo unitario rivestimento esterno (€/m²)",
        insulationByTotal:
          "Selezionare per inserire costi per campi del prezzo dell'isolamento",
        insulationTotalPrice: "Prezzo involucro opaco - isolamento (€)",
        insulationSurface: "Superficie totale isolamento (m²)",
        insulationUnitPrice: "Prezzo unitario isolamento (€/m²)",

        supportingElementsByTotal:
          "Selezionare per inserire costi per campi del prezzo degli elementi portanti",
        supportingElementsTotalPrice:
          "Prezzo involucro opaco - elementi portanti (€)",
        totalSupportingElementsQuantity: "Metri lineari di sottostruttura (m)",
        supportingElementsUnitPrice: "Prezzo unitario elemento portante (€/m)",

        prefabricatedModulesByTotal:
          "Selezionare per inserire costi per campi del prezzo degli moduli prefabbricati",
        prefabricatedModulesTotalPrice:
          "Prezzo involucro opaco - moduli prefabbricati (€)",
        prefabricatedModulesElementsUnitPrice:
          "Prezzo unitario modulo prefabbricato (€/m²)",

        opaqueAnchoringByTotal:
          "Selezionare per inserire costi per campi del prezzo del sistema di ancoraggio",
        opaqueAnchoringTotalPrice:
          "Prezzo involucro opaco - sistema di ancoraggio (€)",
        opaqueAnchoringsNumber: "Numero ancoraggi puntuali (u)",
        opaqueAnchoringUnitPrice: "Prezzo unitario ancoraggi (€/u)",

        opaqueFixingsByTotal:
          "Selezionare per inserire costi per campi del prezzo del sistema di fissaggio",
        opaqueFixingsTotalPrice:
          "Prezzo involucro opaco - sistema di fissaggio (€)",
        opaqueFixingsNumber: "Numero fissaggi (componenti di facciata) (u)",
        opaqueFixingsUnitPrice: "Prezzo unitario fissaggi (€/u)",

        hardware: "Ferramenta e accessori",
        hardwareCheckbox:
          "Selezionare per inserire costi per campi del prezzo di ferramenta e accessori",
        singleHardwareTotalPrice: "Prezzo ferramenta e accessori (€)",
        hardwareTotalPrice:
          "Prezzo involucro opaco - ferramenta e accessori (€)",
        hardwareUnitPrice: "Prezzo unitario ferramenta e accessori (€/u) ",
        hardwareQuantity: "Quantitá ferramenta e accessori (u)",

        glazedCellsByTotal:
          "Selezionare per inserire costi per campi del prezzo della cellula vetrata",
        totalGlazedCellsPrice:
          "Prezzo involucro trasparente - cellula vetrata (€)",
        glazedCellsUnitPrice: "Prezzo unitario cellula vetrata (€/u)",
        glazedCellsNumber: "Involucro trasparente - numero cellule vetrate (u)",

        totalDoubleGlazingPrice:
          "Prezzo involucro trasparente - vetrocamera (€)",
        doubleGlazingQuantity:
          "Involucro trasparente - quantitá vetrocamera (u)",

        totalMainFramePrice: "Prezzo involucro trasparente - main frame (€)",
        mainFrameUnitPrice: "Prezzo unitario main frame (€/u)",
        totalMainFrameLength: "Lunghezza totale main frame (m)",

        totalGasketPrice: "Prezzo involucro trasparente - guarnizioni (€)",
        gasketUnitPrice: "Prezzo unitario guarnizioni (€/m)",

        opaqueSpandrelByTotal:
          "Selezionare per inserire costi per campi del prezzo dei pannelli spandrel opachi",
        totalOpaqueSpandrelModulesPrice:
          "Prezzo involucro trasparente - pannello spandrel opaco (€)",
        opaqueSpandrelModulesUnitPrice:
          "Prezzo unitario pannello spandrel opaco (€/u)",
        opaqueSpandrelPanelsQuantity:
          "Involucro trasparente - quantitá pannelli spandrel opachi (u)",

        transparentAnchoringByTotal:
          "Selezionare per inserire costi per campi del prezzo del sistema di ancoraggio",
        totalTransparentAnchoringsPrice:
          "Prezzo involucro trasparente - sistema di ancoraggio (€)",
        transparentAnchoringsUnitPrice: "Prezzo unitario ancoraggi (€/u)",
        transparentAnchoringsNumber:
          "Involucro trasparente - Numero ancoraggi puntuali (u)",

        fixingsByTotal:
          "Selezionare per inserire costi per campi del prezzo del sistema di fissaggio",
        totalTransparentFixingsNumberPrice:
          "Involucro trasparente - sistema di fissaggio (€)",
        transparentFixingsNumberUnitPrice: "Prezzo unitario fissaggio (€/u)",
        transparentFixingsNumber:
          "Involucro trasparente - Numero fissaggi (componenti di facciata) (u)",

        //Systems Integration production
        systemsIntegrationByTotal:
          "Selezionare per inserire costi per campi del prezzo dell'integrazione impiantistica",
        totalSystemsIntegrationPrice: "Prezzo integrazione impiantistica (€)",
        //For PDF
        totalSystemIntegrationPrice: "Prezzo integrazione impiantistica (€)",

        shieldingByTotal:
          "Selezionare per inserire costi per campi del prezzo del sistema di schermatura",
        shieldingsUnitPrice: "Prezzo unitario sistemi di schermatura (€/u)",
        shieldingSystemsNumber: "Numero di sistemi di schermatura (u)",
        totalShieldingsPrice: "Prezzo sistema di schermatura (€)",

        ventilationByTotal:
          "Selezionare per inserire costi per campi del prezzo del sistema di ventilazione",
        ventilationSystemsUnitPrice:
          "Prezzo unitario apparecchi di ventilazione (€/u)",
        ventilationDevicesNumber: "Numero di apparecchi di ventilazione (u)",
        totalVentilationSystemsPrice: "Prezzo sistema di ventilazione (€)",

        airConditioningByTotal:
          "Selezionare per inserire costi per campi del prezzo del sistema di condizionamento dell'aria",
        airConditioningSystemsUnitPrice:
          "Prezzo unitario sistemi di condizionamento dell´aria (€/u)",
        airConditioningDevicesNumber:
          "Numero di dispositivi per il condizionamento dell´aria (u)",
        totalAirConditioningSystemsPrice:
          "Prezzo sistema condizionamento aria (€)",

        thermicSolarPanelsByTotal:
          "Selezionare per inserire costi per campi del prezzo del sistema solare termico",
        thermicSolarPanelsUnitPrice:
          "Prezzo unitario modulo solare termico (€/u)",
        thermicSolarPanelsNumber: "Numero di pannelli solari termici (u)",
        totalThermicSolarPanelsPrice: "Prezzo sistema solare termico (€)",

        photovoltaicModulesByTotal:
          "Selezionare per inserire costi per campi del prezzo del sistema fotovoltaico integrato",
        photovoltaicModulesUnitPrice:
          "Prezzo unitario modulo fotovoltaico (€/u)",
        photovoltaicModulesNumber: "Numero moduli fotovoltaici (u)",
        totalPhotovoltaicModulesPrice:
          "Prezzo sistema fotovoltaico integrato (€)",

        //Scrap production
        scrapPriceByTotal:
          "Selezionare per inserire costi per campi del prezzo degli sfridi",
        totalScrapPrice: "Prezzo totale sfridi (€) ",

        doubleGlazingScrapByTotal:
          "Selezionare per inserire costi per campi del prezzo dello sfrido della vetrocamera",
        doubleGlazingScrapQuantity: "Quantitá sfrido vetrocamera (u)",
        doubleGlazingScrapUnitPrice: "Prezzo unitario sfrido vetrocamera (€/u)",
        totalDoubleGlazingScrapPrice: "Sfrido (€) - Vetrocamera",

        mainFrameOrLoomByTotal:
          "Selezionare per inserire costi per campi del prezzo dello sfrido del Telaio/main frame ",
        mainFrameOrLoomScrapQuantity: "Quantitá sfrido Telaio/main frame (m)",
        mainFrameOrLoomScrapUnitPrice:
          "Prezzo unitario sfrido Telaio/main frame (€/m)",
        totalMainFrameOrLoomScrapPrice: "Sfrido (€) - Telaio/main frame",

        gasketScrapByTotal:
          "Selezionare per inserire costi per campi del prezzo dello sfrido delle guarnizioni",
        gasketScrapQuantity: "Quantitá sfrido guarnizioni (m)",
        gasketScrapUnitPrice: "Prezzo unitario sfrido guarnizioni (€/m)",
        totalGasketScrapPrice: "Sfrido (€) - Guarnizioni",

        opaqueWindowsScrapByTotal:
          "Selezionare per inserire costi per campi del prezzo dello sfrido delle finestre",
        opaqueWindowsScrapQuantity: "Quantitá sfrido finestre (u)", //Solo opache
        opaqueWindowsScrapUnitPrice: "Prezzo unitario sfrido finestre (€/u)", //Solo opache
        totalOpaqueWindowsScrapPrice: "Sfrido (€) -  Facciata opaca - Finestre", //Solo opache

        opaqueExternalCladdingScrapByTotal:
          "Selezionare per inserire costi per campi del prezzo dello sfrido delle finestre",
        opaqueExternalCladdingScrapQuantity:
          "Quantitá sfrido rivestimento esterno (m²)", //Solo opache
        opaqueExternalCladdingScrapUnitPrice:
          "Prezzo unitario sfrido rivestimento esterno (€/m²)",
        totalOpaqueExternalCladdingScrapPrice:
          "Sfrido (€) -  Facciata opaca - Rivestimento esterno", //Solo opache

        opaqueInsulationScrapByTotal:
          "Selezionare per inserire costi per campi del prezzo dello sfrido dell'isolamento",
        opaqueInsulationScrapQuantity: "Quantitá sfrido isolamento (m²)", //Solo opache
        opaqueInsulationScrapUnitPrice:
          "Prezzo unitario sfrido isolamento (€/m²)",
        totalOpaqueInsulationScrapPrice:
          "Sfrido (€) -  Facciata opaca - Isolamento", //Solo opache

        opaqueSupportingElementsScrapByTotal:
          "Selezionare per inserire costi per campi del prezzo dello sfrido degli elementi portanti",
        opaqueSupportingElementsScrapQuantity:
          "Quantitá sfrido elementi portanti (m3)", //Solo opache
        opaqueSupportingElementsScrapUnitPrice:
          "Prezzo unitario sfrido elementi portanti (€/m3)",
        totalOpaqueSupportingElementsScrapPrice:
          "Sfrido (€) -  Facciata opaca - Elementi portanti", //Solo opache

        transparentGlazedCellScrapByTotal:
          "Selezionare per inserire costi per campi del prezzo dello sfrido della cellula vetrata",
        transparentGlazedCellScrapQuantity:
          "Quantitá sfrido cellula vetrata (u)", //Solo trasparenti
        transparentGlazedCellsScrapUnitPrice:
          "Prezzo unitario sfrido cellula vetrata (€/u)",
        totalTransparentGlazedCellScrapPrice:
          "Sfrido (€) -  Facciata trasparente - Cellula vetrata", //Solo trasparenti

        transparentOpaqueSpandrelModulesScrapByTotal:
          "Selezionare per inserire costi per campi del prezzo dello sfrido dei moduli opachi spandrel",
        transparentOpaqueSpandrelModulesQuantity:
          "Quantitá sfrido moduli opachi spandrel (u)", //Solo trasparenti
        transparentOpaqueSpandrelModulesScrapUnitPrice:
          "Prezzo unitario sfrido moduli opachi spandrel (€/u)",
        totalTransparentOpaqueSpandrelModulesPrice:
          "Sfrido (€) -  Facciata trasparente - Moduli opachi spandrel", //Solo trasparenti

        fixingsScrapByTotal:
          "Selezionare per inserire costi per campi del prezzo dello sfrido del sistema di fissaggio",
        fixingsScrapQuantity: "Quantitá sfrido sistema di fissaggio (u)",
        fixingsScrapUnitPrice:
          "Prezzo unitario sfrido sistema di fissaggio (€/u)",
        totalFixingsScrapPrice: "Sfrido (€) - Sistema di fissaggio",

        anchoringsScrapByTotal:
          "Selezionare per inserire costi per campi del prezzo dello sfrido del sistema di ancoraggio",
        anchoringsScrapQuantity: "Quantitá sfrido sistema di ancoraggio (u)",
        anchoringsScrapUnitPrice:
          "Prezzo unitario sfrido sistema di ancoraggio (€/u)",
        totalAnchoringsScrapPrice: "Sfrido (€) - Sistema di ancoraggio",

        accessoriesScrap: "Sfrido (€) - Accessori",

        //Labor
        labor: "Manodopera",
        totalLaborPriceByTotal:
          "Selezionare per inserire costi per campi del prezzo totale della manodopera",
        totalLaborPrice: "Prezzo totale manodopera (€)",

        baseLaborPriceByTotal:
          "Selezionare per inserire costi per campi del prezzo totale della manodopera di base",
        totalBaseLaborPrice: "Prezzo manodopera base (€)",
        baseLaborHours: "Ore manodopera base (h)",
        baseLaborHourPrice: "Prezzo orario manodopera base (€/h)",

        skilledLaborPriceByTotal:
          "Selezionare per inserire costi per campi del prezzo totale della manodopera specializzata",
        totalSkilledLaborPrice: "Prezzo manodopera specializzata (€)",
        skilledLaborHours: "Ore manodopera specializzata (h)",
        skilledLaborHourPrice: "Prezzo orario manodopera specializzata (€/h)",

        //KPI
        priceMaterialsRatio:
          "Rapporto Prezzo materiali su Prezzo totale della produzione (%)",
        priceLaborRation:
          "Rapporto Prezzo manodopera su Prezzo totale della produzione (%)",
        kpiProduction: "KPI Prezzo di produzione (€/m²)",
        priceLaborRatio:
          "Rapporto Prezzo manodopera su Prezzo totale della produzione (%)",
      },
      transport: {
        totalTransportPrice: "Prezzo totale trasporto (€)",
        totalTransportCheckbox:
          "Selezionare per inserire costi per campi del prezzo totale del trasporto",
        //Packaging
        totalPackagingPrice: "Prezzo totale imballaggio (€)",
        packagingContainer: "Imballaggio", 
        transportContainer: "Trasporto",
        totalPackagingCheckbox:
          "Selezionare per inserire costi per campi del prezzo totale dell'imballaggio",
        totalPrice: "Prezzo totale tipo di imballaggio (€)",

        unitsNumber: "Numero unitá per tipo di imballaggio (u)",
        unitPrice: "Prezzo unitario per tipo di imballaggio (€/u)",
        packageType: "Tipo di imballaggio",

        //Transport
        totalTransportPartPrice: "Prezzo totale trasporto (€)",
        workSiteProductionSiteDistance:
          "Distanza tra cantiere e sito di produzione (Km)",
        transportPartUnitPrice: "Prezzo unitario trasporto al km (€/Km)",

        //KPIs
        kpiTransportTitle:
          "KPIs PREZZO TRASPORTO FACCIATA (un solo tipo di facciata)",
        benchmarkTitle: "Confronto con benchmark",
        kpiTransportTitleAll:
          "KPIs PREZZO TRASPORTO FACCIATA (diversi tipi di facciata)",
        fancadeSurface: "Superficie di facciata (m²)",
        //totalTransportPrice
        //workSiteProductionSiteDistance
        transportPrice1: "Prezzo di trasporto KPI #1 (€/m²)",
        transportPrice2: "Prezzo di trasporto KPI #2 (€/Km)",
        benchmarkDifference: "Differenza con soluzione benchmark",
      },
      buildingSite: {
        installationPrice: "Prezzo installazione facciata (cantiere)",
        installationByTotal:
          "Selezionare per inserire costi per campi del prezzo dell'installazione facciata",
        totalInstallationPrice: "Prezzo totale installazione facciata (€)",

        materialsByTotal:
          "Selezionare per inserire costi per campi del prezzo dei materiali necessari al cantiere",
        totalBuildingSiteMaterialsPrice:
          "Prezzo totale dei materiali necessari al cantiere (€)",

        additionalMaterial: "Materiale addizionale",
        additionalMaterialByTotal:
          "Selezionare per inserire costi per campi del prezzo del materiale addizionale",
        totalAdditionalMaterialPrice:
          "Prezzo totale materiale addizionale (€) ",
        additionalMaterialQuantity: "Quantitá materiale addizionale (m²) ",
        additionalMaterialUnitPrice:
          "Prezzo unitario materiale addizionale (€/m²) ",

        buildingSiteDuration: "Ore totali durata del cantiere ",

        //Labor
        laborByTotal:
          "Selezionare per inserire costi per campi del prezzo della manodopera",
        totalLaborPrice: "Prezzo totale manodopera (€)",

        baseLaborByTotal:
          "Selezionare per inserire costi per campi del prezzo della manodopera base",
        totalBaseLaborPrice: "Prezzo manodopera base (€)",
        baseLaborHours: "Ore manodopera base (h)",
        baseLaborHourPrice: "Prezzo orario manodopera base (€/h)",

        skilledLaborByTotal:
          "Selezionare per inserire costi per campi del prezzo della manodopera specializzata",
        totalSkilledLaborPrice: "Prezzo manodopera specializzata (€)",
        skilledLaborHours: "Ore manodopera specializzata (h)",
        skilledLaborHourPrice: "Prezzo orario manodopera specializzata (€/h)",

        //Tools
        toolsByTotal:
          "Selezionare per inserire costi per campi del prezzo degli strumenti di lavoro",
        totalToolsPrice: "Prezzo totale strumenti di lavoro (€)",

        craneByTotal:
          "Selezionare per inserire costi per campi del prezzo della gru",
        totalTowerCranePrice: "Prezzo totale gru (€)",
        towerCraneHours: "Ore di utilizzo della gru (h)",
        towerCraneHourPrice: "Prezzo orario utilizzo della gru (€/h)",

        elevatorPlatformByTotal:
          "Selezionare per inserire costi per campi del prezzo delle piattaforme elevatrici verticali",
        totalElevatorPlatformPrice:
          "Prezzo totale piattaforme elevatrici verticali (€)",
        elevatorPlatformHours:
          "Ore di utilizzo delle piattaforme levatrici (h)",
        elevatorPlatformHourPrice:
          "Prezzo orario delle piattaforme levatrici (€/h)",

        aerialPlatformByTotal:
          "Selezionare per inserire costi per campi del prezzo delle piattaforme aeree",
        totalAerialPlatformPrice: "Prezzo totale delle piattaforme aeree (€)",
        aerialPlatformHours: "Ore di utilizzo delle piattaforme aeree (h)",
        aerialPlatformHourPrice: "Prezzo orario delle piattaforme aeree (€/h)",

        scaffoldingByTotal:
          "Selezionare per inserire costi per campi del prezzo del ponteggio",
        totalScaffoldingPrice: "Prezzo totale ponteggio (€)",
        scaffoldingSelect: "Calcolo per unità ",
        scaffoldingSurface: "Superficie coperta da ponteggio (m²)",
        scaffoldingSurfaceUnitPrice:
          "Prezzo unitario ponteggio per superficie (€/m²)",
        scaffoldingHours: "Ore di utilizzo del ponteggio (h)",
        scaffoldingHourPrice: "Prezzo orario del ponteggio (€/h)",

        otherByTotal:
          "Selezionare per inserire costi per campi del prezzo di altre infrastrutture",
        totalOtherPrice: "Prezzo totale altre infrastrutture (€)",
        otherHours: "Ore di utilizzo di altre attrezzature (h)",
        otherHourPrice: "Prezzo orario di altre attrezzature (€/h)",

        //-------------Security-------------
        securityPrice: "Prezzo della sicurezza e gestione della sicurezza in cantiere", 
        securityByTotal:
          "Selezionare per inserire costi per campi del prezzo della sicurezza e gestione della sicurezza in cantiere",
        totalSecurityPrice:
          "Prezzo totale sicurezza e gestione della sicurezza in cantiere (€)",

        staffByTotal:
          "Selezionare per inserire costi per campi del prezzo del personale per gestire la sicurezza",
        buildingSiteStaffByTotal:
          "Selezionare per inserire costi per campi del prezzo del personale per gestire il cantiere",
        totalStaffSecurityPrice:
          "Prezzo del personale per gestire la sicurezza(€)",
        staffSecurityHours: "Ore di lavoro per gestione sicurezza (h)",
        staffSecurityHourPrice: "Prezzo orario del personale (€/h)",

        totalStaffBuildingSitePrice:
          "Prezzo del personale per gestire il cantiere (€)",
        staffBuildingSiteHours: "Ore di lavoro per gestione cantiere (h)",
        staffBuildingSiteHourPrice: "Prezzo orario del personale (€/h)",

        taxes: "Tasse e tributi (e.g. occupazione suolo pubblico, altro....)",
        taxCost: "Costo (€)",
        other: "Altre voci ? (feedback dalle aziende)",

        //Kpi
        kpiTitle:
          "KPIs PREZZO DI COSTRUZIONE IN CANTIERE (un solo tipo di facciata)",
        benchmarkTitle: "Confronto con Benchmark",
        kpiAllTitle:
          "KPIs PREZZO DI COSTRUZIONE IN CANTIERE (diversi tipi di facciata)",
        totalInstallationSecurityPrice:
          "Prezzo di costruzione totale facciata + sicurezza e gestione cantiere (€)",

        surface: "Superficie di facciata (m²)", //todo: aggiungere 'Superficie di facciata incl. finestre (m2)'
        installationSecuritySurfaceRatio:
          "Prezzo di costruzione + sicurezza e gestione su superficie facciata (€/m²)",
        installationSurfaceRatio:
          "Prezzo installazione facciata su superficie facciata (€/m²)",
        securitySurfaceRatio:
          "Prezzo sicurezza e gestione della sicurezza in cantiere su superficie facciata (€/m²)",

        installationTotalRatio:
          "Rapporto Prezzo installazione su Prezzo totale costruzione (inc. sicurezza)",
        securityTotalRatio:
          "Rapporto Prezzo sicurezza e gestione sicurezza su Prezzo totale costruzione (inc. sicurezza) ",

        buildingSiteTotal: "Construction site total cost (€)",
        buildingSiteTotalKpi: "Construction site cost KPI (€/m²)",
      },
      serviceLife: {
        annualLifeCost: "Prezzo totale operativo (€/intervento)",
        annualLifeCostCalculation:
          "Selezionare per inserire costi per campi prezzo totale operativo",

        //------------- Annual Integrated Implants Cost -------------
        annualIntegratedImplantsCost:
          "Prezzo del consumo annuo dei sistemi impiantistici integrati (€/intervento)",
        annualIntegratedImplantsCostCalculation:
          "Selezionare per inserire costi per campi prezzo del consumo annuo dei sistemi impiantistici integrati",

        annualShieldingsCost:
          "Prezzo del consumo annuo del sistema di schermatura (€/intervento)",
        annualShieldingCostsCalculation:
          "Selezionare per inserire costi per campi prezzo del consumo annuo del sistema di schermatura",
        shieldingsNumber: "Numero di sistemi di schermatura (u)",
        annualShieldingsConsume:
          "Consumo annuo singolo sistema di schermatura (kwh/u)",
        energeticShieldingVectorUnitPrice:
          "Prezzo unitario del vettore energetico (€/kwh)",

        annualVentilationCost:
          "Prezzo del consumo annuo del sistema di ventilazione (€/intervento)",
        annualVentilationCostsCalculation:
          "Selezionare per inserire costi per campi prezzo del consumo annuo del sistema di ventilazione",
        ventilationsNumber: "Numero di apparecchi di ventilazione (u)",
        annualVentilationConsume:
          "Consumo annuo singolo sistema di ventilazione (kwh/u)",
        energeticVentilationVectorUnitPrice:
          "Prezzo unitario del vettore energetico (€/kwh)",

        annualConditioningCost:
          "Prezzo del consumo annuo sistema condizionamento aria (€/intervento)",
        annualConditioningCostsCalculation:
          "Selezionare per inserire costi per campi prezzo del consumo annuo sistema condizionamento aria",
        conditioningNumber:
          "Numero di dispositivi per il condizionamento dell´aria (u)",
        annualConditioningConsume:
          "Consumo annuo singolo sistema di condizionamento dell´aria (kwh/u)",
        energeticConditioningVectorUnitPrice:
          "Prezzo unitario del vettore energetico (€/kwh)",

        annualThermicSolarCost:
          "Prezzo del consumo annuo sistema solare termico (€/intervento)",
        annualThermicSolarCostsCalculation:
          "Selezionare per inserire costi per campi prezzo del consumo annuo sistema solare termico",
        thermicSolarNumber: "Numero di pannelli solari termici (u)",
        annualThermicSolarConsume:
          "Consumo annuo singolo pannello solare termico (kwh/u)",
        energeticThermicSolarVectorUnitPrice:
          "Prezzo unitario del vettore energetico (€/kwh)",

        annualPhotovoltaicIsActive:
          "Inclusione produzione annua sistema fotovoltaico integrato",
        annualPhotovoltaicCost:
          "Produzione annua sistema fotovoltaico integrato (€/intervento)",
        annualPhotovoltaicCostsCalculation:
          "Selezionare per inserire costi per campi produzione annua sistema fotovoltaico integrato",
        photovoltaicNumber: "Numero moduli fotovoltaici (u)",
        annualPhotovoltaicConsume:
          "Produzione annua singolo modulo fotovoltaico (kWh/u)",
        energeticPhotovoltaicVectorUnitPrice:
          "Prezzo unitario del vettore energetico (€/kwh)",

        //------------- Other implants -------------
        otherImplant: "Altro sistema impiantistico pre-esistente",
        addOtherImplant: "Aggiungi sistema impiantistico pre-esistente",
        annualOtherImplantsCost:
          "Prezzo del consumo annuo di altri sistemi impiantistici pre-esistenti (€/intervento)",
        annualOtherImplantsCostCalculation:
          "Selezionare per inserire costi per campi prezzo del consumo annuo di altri sistemi impiantistici pre-esistenti",

        annualImplantCost: "Prezzo del consumo annuo (€/intervento)",
        annualImplantCostCalculation:
          "Selezionare per inserire costi per campi prezzo del consumo annuo",
        annualImplantConsume: "Consumo annuo singolo (vettore energetico/u)",
        energeticImplantVectorUnitPrice:
          "Prezzo unitario del vettore (€/vettore energetico)",
      },
      maintenance: {
        percentageMessage:
          "Calcolare il prezzo in base alla percentuale del prezzo di costruzione ed inserirlo nel campo sottostante",
        totalMaintenancePercentage:
          "Inserire la percentuale del prezzo di manutenzione totale rispetto rispetto al costo di produzione totale (%)",
        totalExtraordinaryMaintenancePercentage:
          "Inserire la percentuale del prezzo di manutenzione straordinaria rispetto rispetto al costo di produzione totale (%)",
        refLife: "Vita utile di riferimento per il progetto (anni)",
        totalMaintenancePrice:
          "Prezzo totale manutenzione incluso impianti - calcolato (€/anno)",
        maintenanceSelect:
          "Selezionare il tipo di inserimento del prezzo totale manutenzione inclusi gli impianti ",
        manualOption: "Manuale",
        percentageOption: "Percentuale del prezzo di costruzione",
        sumOption: "Calcolo attraverso componenti",
        //---------------Ordinary maintenance---------------
        totalOrdinaryMaintenancePrice:
          "Manutenzione ordinaria - calcolato (€/intervento)",
        totalOrdinaryMaintenancePriceByYear:
          "Manutenzione ordinaria - calcolato (€/anno)",
        ordinaryMaitenance: "Manutenzione ordinaria",
        plantIntegration: "Plant Integration",
        cleaning: "Pulizia",
        extraordinaryMaintenance: "Manutenzione Straordinaria",  
        totalOrdinaryMaintenancePriceSelect:
          "Selezionare il tipo di inserimento del prezzo della manutenzione ordinaria",
        totalOrdinaryMaintenancePercentage:
          "Inserire la percentuale del prezzo di manutenzione ordinaria rispetto rispetto al costo di produzione totale (%)",
        //Opaque
        totalWindowReplacementPrice:
          "Sostituzione di intera finestra (€/intervento)",
        totalWindowReplacementPriceByYear:
          "Sostituzione di intera finestra (€/anno)",
        totalWindowReplacementPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della sostituzione di un'intera finestra",
        windowsNumber: "Quantitá finestre (u)",
        windowUnitPrice: "Prezzo unitario finestre (€/u)",

        totalOpaqueLoomFixingsReplacementPrice:
          "Sostituzione componenti finestra - telaio e fissaggio (€/intervento)",
        totalOpaqueLoomFixingsReplacementPriceByYear:
          "Sostituzione componenti finestra - telaio e fissaggio (€/anno)",
        totalOpaqueLoomFixingsReplacementPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della sostituzione di telaio e fissaggio",
        opaqueLoomFixingsQuantity: "Quantitá telaio e fissaggio (u)",
        opaqueLoomFixingsUnitPrice: "Prezzo unitario telaio e fissaggio (€/u)",

        totalOpaqueDoubleGlazingReplacementPrice:
          "Sostituzione componenti finestra - vetrocamera (€/intervento)",
        totalOpaqueDoubleGlazingReplacementPriceByYear:
          "Sostituzione componenti finestra - vetrocamera (€/anno)",
        totalOpaqueDoubleGlazingReplacementPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della sostituzione della vetrocamera",
        opaqueDoubleGlazingQuantity: "Quantitá vetrocamera (u)",
        opaqueDoubleGlazingUnitPrice: "Prezzo unitario vetrocamera (€/u)",

        totalOpaqueShieldingsReplacementPrice:
          "Sostituzione componenti finestra - sistema di schermatura (€/intervento)",
        totalOpaqueShieldingsReplacementPriceByYear:
          "Sostituzione componenti finestra - sistema di schermatura (€/anno)",
        totalOpaqueShieldingsReplacementPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della sostituzione del sistema di schermatura",
        opaqueShieldingsQuantity: "Quantitá sistema di schermatura (u)",
        opaqueShieldingsUnitPrice:
          "Prezzo unitario sistema di schermatura (€/u)",

        totalExternalCladdingReplacementPrice:
          "Involucro opaco - sostituzione rivestimento esterno (€/intervento)",
        totalExternalCladdingReplacementPriceByYear:
          "Involucro opaco - sostituzione rivestimento esterno (€/anno)",
        totalExternalCladdingReplacementPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della sostituzione del rivestimento esterno",
        externalCladdingQuantity: "Superficie totale rivestimento esterno (m²)",
        externalCladdingUnitPrice:
          "Prezzo unitario rivestimento esterno (€/m²)",
        externalCladdingUnitsSurface:
          "Superficie unitaria pannelli di rivestimento esterno (m²/u)",
        totalInsulationReplacementPrice:
          "Involucro opaco - sostituzione isolamento (€/intervento)",
        totalInsulationReplacementPriceByYear:
          "Involucro opaco - sostituzione isolamento (€/anno)",
        totalInsulationReplacementPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della sostituzione dell'isolamento",
        insulationSurface: "Superficie isolamento (m²)",
        insulationUnitPrice: "Prezzo unitario isolamento (€/m²)",

        totalSupportingElementsReplacementPrice:
          "Involucro opaco - elemento portante (€/intervento)",
        totalSupportingElementsReplacementPriceByYear:
          "Involucro opaco - elemento portante (€/anno)",
        totalSupportingElementsReplacementPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della sostituzione dell'elemento portante",
        supportingElementsQuantity: "Quantitá elemento portante (m3)",
        supportingElementUnitPrice: "Prezzo unitario elemento portante (€/m3)",

        totalPrefabricatedModulesReplacementPrice:
          "Involucro opaco - sostituzione modulo prefabbricato (€/intervento)",
        totalPrefabricatedModulesReplacementPriceByYear:
          "Involucro opaco - sostituzione modulo prefabbricato (€/anno)",
        totalPrefabricatedModulesReplacementPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della sostituzione dei moduli prefabbricati",
        prefabricatedModulesQuantity: "Quantitá moduli prefabbricati (u)",
        prefabricatedModulesUnitPrice: "Prezzo moduli al mq (€/m²)",

        //Transparent
        totalGlazedCellsReplacementPrice:
          "Involucro trasparente - sostituzione cellule vetrate (€/intervento)",
        totalGlazedCellsReplacementPriceByYear:
          "Involucro trasparente - sostituzione cellule vetrate (€/anno)",
        totalGlazedCellsReplacementPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della sostituzione delle cellule vetrate",
        glazedCellsNumber: "Involucro trasparente - numero cellule vetrate (u)",
        glazedCellUnitPrice: "Prezzo unitario cellula vetrata (€/u)",

        totalDoubleGlazingReplacementPrice:
          "Involucro trasparente - sostituzione vetrocamera (€/intervento)",
        totalDoubleGlazingReplacementPriceByYear:
          "Involucro trasparente - sostituzione vetrocamera (€/anno)",
        totalDoubleGlazingReplacementPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della sostituzione della vetrocamera",
        doubleGlazingQuantity:
          "Involucro trasparente - quantitá vetrocamera (u)",
        doubleGlazingUnitPrice: "Prezzo unitario vetrocamera (€/u)",

        totalTransparentLoomFixingsReplacementPrice:
          "Involucro trasparente - sostituzione telaio o fissaggi (€/intervento)",
        totalTransparentLoomFixingsReplacementPriceByYear:
          "Involucro trasparente - sostituzione telaio o fissaggi (€/anno)",
        totalTransparentLoomFixingsReplacementPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della sostituzione del telaio o dei fissaggi",
        transparentLoomFixingsQuantity: "Quantitá telaio o fissaggi (u)",
        transparentLoomFixingsUnitPrice:
          "Prezzo unitario telaio o fissaggi (€/u)",

        totalTransparentShieldingsReplacementPrice:
          "Involucro trasparente - sistema di schermatura (€/intervento)",
        totalTransparentShieldingsReplacementPriceByYear:
          "Involucro trasparente - sistema di schermatura (€/anno)",
        totalTransparentShieldingsReplacementPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della sostituzione del sistema di schermatura",
        transparentShieldingsQuantity: "Quantitá sistema di schermatura (u)",
        transparentShieldingsUnitPrice:
          "Prezzo unitario sistema di schermatura (€/u)",

        //Systems Integrations
        totalPhotovoltaicModulesReplacementPrice:
          "Componenti impiantistici - Moduli fotovoltaici integrati (€/intervento)",
        totalPhotovoltaicModulesReplacementPriceByYear:
          "Componenti impiantistici - Moduli fotovoltaici integrati (€/anno)",
        totalPhotovoltaicModulesReplacementPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della sostituzione dei moduli fotovoltaici integrati",
        photovoltaicModulesNumber: "Numero moduli fotovoltaici (u)",
        photovoltaicModuleUnitPrice:
          "Prezzo unitario modulo fotovoltaico (€/u)",

        totalSolarPanelsReplacementPrice:
          "Componenti impiantistici - pannelli solari termici (€/intervento)",
        totalSolarPanelsReplacementPriceByYear:
          "Componenti impiantistici - pannelli solari termici (€/anno)",
        totalSolarPanelsReplacementPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della sostituzione dei pannelli solari termici",
        solarPanelsNumber: "Numero di pannelli solari termici (u)",
        solarPanelUnitPrice: "Prezzo unitario modulo solare termico (€/u)",

        totalVentilationDevicesReplacementPrice:
          "Componenti impiantistici - apparecchi di ventilazione (€/intervento)",
        totalVentilationDevicesReplacementPriceByYear:
          "Componenti impiantistici - apparecchi di ventilazione (€/anno)",
        totalVentilationDevicesReplacementPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della sostituzione degli apparecchi di ventilazione",
        ventilationDevicesNumber: "Numero di apparecchi di ventilazione (u)",
        ventilationDeviceUnitPrice:
          "Prezzo unitario apparecchi di ventilazione (€/u)",

        totalAirConditioningDevicesReplacementPrice:
          "Componenti impiantistici - condizionamento dell´aria (€/intervento)",
        totalAirConditioningDevicesReplacementPriceByYear:
          "Componenti impiantistici - condizionamento dell´aria (€/anno)",
        totalAirConditioningDevicesReplacementPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della sostituzione del condizionamento dell'aria",
        airConditioningDevicesNumber:
          "Numero di dispositivi per il condizionamento dell´aria (u)",
        airConditioningDeviceUnitPrice:
          "Prezzo unitario sistemi di condizionamento dell´aria (€/u)",

        //Cleaning
        totalCleaningPrice: "Prezzi di pulizia (€/anno)",
        totalCleaningPriceByYear: "Prezzi di pulizia (€/anno)",
        totalCleaningPriceIsTotal:
          "Selezionare per inserire costi per campi dei prezzi di pulizia",
        cleaningsEveryHowManyYears:
          "Periodo degli interventi di pulizia (ogni quanti anni vengono effettuati)",
        cleaningUnitPrice:
          "Prezzo unitario intervento di pulizia - intera facciata (€/u)",

        //---------------Extraordinary maintenance---------------
        totalExtraordinaryMaintenancePrice:
          "Manutenzione straordinaria - calcolato (€/intervento)",
        totalExtraordinaryMaintenancePriceByYear:
          "Manutenzione straordinaria - calcolato (€/anno)",
        totalExtraordinaryMaintenancePriceIsTotal:
          "Selezionare il tipo di inserimento",

        everyHowManyYears:
          "Periodo della manutenzione (ogni quanti anni viene effettuata)",
        timesInAYear:
          "Frequenza della manutenzione (quante volte viene effettuata in un anno)",
        periodByEveryHowManyYears:
          "Inserimento della frequenza in base ad ogni quanti anni viene effettuata",
        periodByTimesInAYear:
          "Inserimento della frequenza in base alla frequenza in un anno",

        actionName: "Nome azione",
        actionValue: "Valore azione (€)",

        action: "Azione (€/intervento)",
        actionByYear: "Azione (€/anno)",
        actions: "Azioni",

        //KPIs
        kpiTitle:
          "KPIs PREZZO DI MANUTENZIONE FACCIATA  (un solo tipo di facciata)",
        benchmarkTitle: "Differenza con soluzione benchmark",
        kpiAllTitle:
          "KPIs PREZZO DI MANUTENZIONE FACCIATA (diversi tipi di facciata)",
        surface: "Superficie di facciata (m²)",

        implantsSurfacePercentage:
          "Percentuale di superficie occupata dagli impianti sul totale della facciata (totale per tutti gli impianti)",
        implantsSurface: "Superficie impianti in facciata (m²)",
        surfaceWithoutImplants: "Superficie facciata escluso impianti (m²)",
        systemsIntegration:
          "Manutenzione - Prezzo per sola integrazione impiantistica (€/intervento)",

        kpi1: "KPI #1 Prezzo di manutenzione (€/m²)",
        kpi2: "KPI #2 Prezzo di manutenzione integrazione impiantistica (€/m²)",
        kpi3: "KPI #3 incidenza degli impianti sulla manutenzione (%)",
      },
      costsActualization: {
        interestRate: "Tasso di interesse (%)",
        priceVectorVariation:
          "Tasso di variazione prezzo vettore energetico (%)",
        priceVectorVariationIsActive:
          "Tasso di variazione prezzo vettore energetico",
      },
      endOfLife: {
        demolitionAndDisassembly: "Demolizione e smontaggio aggregato",
        totalDemolitionAndDisassemblyPrice:
          "Prezzo totale per demolizione e smontaggio (€)",
        totalDemolitionAndDisassemblyPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo di demolizione e smontaggio",

        surfaceDemolitionOrDisassembly:
          "Superficie totale di facciata smontata o demolita  (m²)",

        //---------------Demolition---------------
        demolition: "Demolizione",
        totalDemolitionPrice: "Prezzo totale demolizione (€)",
        totalDemolitionPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della demolizione",

        demolitionQuantity: "Quantitá demolizione (m²)",
        demolitionUnitPrice: "Prezzo unitario demolizione (€/m²)",

        //---------------Disassebly---------------
        disassembly: "Smontaggio",
        totalDisassemblyPrice: "Prezzo smontaggio (€)",
        totalDisassemblyPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo dello smontaggio",

        //Opaque disassembly
        opaqueDisassembly: "Smontaggio di involucro opaco",
        totalWindowsDisassemblyPrice: "Prezzo totale smontaggio finestre (€)",
        totalWindowsDisassemblyPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo dello smontaggio delle finestre",
        windowsQuantity: "Quantitá finestre (u)",
        windowsUnitPrice: "Prezzo unitario smontaggio finestre (€/u)",

        totalExternalCladdingDisassemblyPrice:
          "Prezzo totale smontaggio rivestimento esterno (€)",
        totalExternalCladdingDisassemblyPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo dello smontaggio del rivestimento esterno",
        externalCladdingQuantity: "Quantitá rivestimento esterno (m²)",
        externalCladdingUnitPrice:
          "Prezzo unitario smontaggio rivestimento esterno (€/m²)",

        totalInsulationDisassemblyPrice:
          "Prezzo totale smontaggio isolamento (€)",
        totalInsulationDisassemblyPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo dello smontaggio dell'isolamento",
        insulationQuantity: "Quantitá isolamento (m²)",
        insulationUnitPrice: "Prezzo unitario smontaggio isolamento (€/m²)",

        totalSupportingElementsAndFixingsDisassemblyPrice:
          "Prezzo totale smontaggio pannello portante e fissaggio (€)",
        totalSupportingElementsAndFixingsDisassemblyPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo dello smontaggio del pannello portante e del fissaggio",
        supportingElementsAndFixingsQuantity: "Quantitá panello portante (m²)",
        supportingElementsAndFixingsUnitPrice:
          "Prezzo unitario smontaggio panello portante (€/m²)",

        //Transparent disassembly
        transparentDisassembly: "Smontaggio di involucro trasparente",
        totalGlazedCellsDisassemblyPrice:
          "Prezzo totale smontaggio cellule vetrate  (€)",
        totalGlazedCellsDisassemblyPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo dello smontaggio delle cellule vetrate",
        glazedCellsQuantity: "Quantitá cellule vetrate (u)",
        glazedCellsUnitPrice:
          "Prezzo unitario smontaggio cellule vetrate (€/u)",

        totalDoubleGlazingDisassemblyPrice:
          "Prezzo totale smontaggio vetrocamera  (€)",
        totalDoubleGlazingDisassemblyPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo dello smontaggio della vetrocamera",
        doubleGlazingQuantity: "Quantitá vetrocamera (u)",
        doubleGlazingUnitPrice: "Prezzo unitario smontaggio vetrocamera (€/u)",

        totalUprightsCrosspiecesFixingsDisassemblyPrice:
          "Prezzo totale smontaggio montanti, traversi e fissaggi  (€)",
        totalUprightsCrosspiecesFixingsDisassemblyPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo dello smontaggio di montanti, traversi e fissaggi",
        uprightsCrosspiecesFixingsQuantity: "Quantitá montanti e traversi (m)",
        uprightsCrosspiecesFixingsUnitPrice:
          "Prezzo unitario smontaggio montanti e traversi (€/m)",

        //---------------Transport---------------
        transport: "Trasporto",
        totalTransportPrice: "Prezzo totale trasporto (€)",
        totalTransportPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo del trasporto",

        //Disposal transport
        totalDisposalTransportPrice:
          "Prezzo trasporto al sito di smaltimento/discarica (€)",
        totalDisposalTransporPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo del trasporto al sito di smaltimento/discarica",
        disposalTransportQuantity: "Ore di trasporto (h)",
        disposalTransportUnitPrice: "Prezzo orario trasporto (€/h)",

        //Recycling transport
        totalRecyclingTransportPrice:
          "Prezzo del trasporto al sito di riciclaggio/produzione",
        totalRecyclingTransporPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo del trasporto al sito di riciclaggio/produzione",
        recyclingTransportQuantity: "Ore di trasporto (h)",
        recyclingTransportUnitPrice: "Prezzo orario trasporto (€/h)",

        //---------------Recycling---------------
        recycling: "Riciclaggio",
        totalRecyclingRevenue:
          "Ricavi generati dalla pratica di riciclaggio (€)",
        totalRecyclingRevenueKpi:
          "Ricavi generati dalla pratica di riciclaggio (-€)",
        totalRecyclingRevenueIsTotal:
          "Selezionare per inserire costi per campi dei ricavi generati dal riciclaggio",

        //Windows
        totalWindowsRecyclingRevenue: "Ricavi riciclaggio finestre (€)",
        totalWindowsRecyclingRevenueIsTotal:
          "Selezionare per inserire costi per campi dei ricavi generati dal riciclaggio delle finestre",
        windowsRecyclingQuantity: "Quantitá finestre (u)",
        windowsRecyclingUnitPrice:
          "Ricavo unitario per riciclaggio finestra (€/u)",

        //External cladding
        totalExternalCladdingRecyclingRevenue:
          "Ricavi riciclaggio rivestimento esterno (€)",
        totalExternalCladdingRevenueIsTotal:
          "Selezionare per inserire costi per campi dei ricavi generati dal riciclaggio del rivestimento esterno",
        externalCladdingRecyclingQuantity:
          "Quantitá rivestimento esterno  (m²)",
        externalCladdingRecyclingUnitPrice:
          "Ricavo unitario per riciclaggio rivestimento esterno (€/m²)",

        //Insulation
        totalInsulationRecyclingRevenue: "Ricavi riciclaggio isolamento (€)",
        totalInsulationRevenueIsTotal:
          "Selezionare per inserire costi per campi dei ricavi generati dal riciclaggio dell'isolamento",
        insulationRecyclingQuantity: "Quantitá isolamento (m²)",
        insulationRecyclingUnitPrice:
          "Ricavo unitario per riciclaggio isolamento (€/m²)",

        //Supporting elements and fixings
        totalSupportingElementsAndFixingsRecyclingRevenue:
          "Ricavi riciclaggio pannello portante e fissaggio (€)",
        totalSupportingElementsAndFixingsRecyclingRevenueIsTotal:
          "Selezionare per inserire costi per campi dei ricavi generati dal riciclaggio del pannello portante e del fissaggio",
        supportingElementsAndFixingsRecyclingQuantity:
          "Quantitá pannello portante e fissaggio (m²)",
        supportingElementsAndFixingsRecyclingUnitPrice:
          "Ricavo unitario per riciclaggio pannello portante e fissaggio (€/m²)",

        //Glazed cells
        totalGlazedCellsRecyclingRevenue: "Ricavi riciclaggio cellule vetrate",
        totalGlazedCellsRecyclingRevenueIsTotal:
          "Selezionare per inserire costi per campi dei ricavi generati dal riciclaggio delle cellule vetrate",
        glazedCellsRecyclingQuantity: "Quantitá cellule vetrate (u)",
        glazedCellsUnitRecyclingPrice:
          "Ricavo unitario per riciclaggio cellule vetrate (€/u)",

        //Double glazing
        totalDoubleGlazingRecyclingRevenue: "Ricavi riciclaggio vetrocamera",
        totalDoubleGlazingRecyclingRevenueIsTotal:
          "Selezionare per inserire costi per campi dei ricavi generati dal riciclaggio della vetrocamera",
        doubleGlazingRecyclingQuantity: "Quantitá vetrocamera (u)",
        doubleGlazingRecyclingUnitPrice:
          "Ricavo unitario per riciclaggio vetrocamera (€/u)",

        //Uprights, Crosspieces and Fixings
        totalUprightsCrosspiecesFixingsRecyclingRevenue:
          "Ricavi riciclaggio montanti, traversi e fissaggi",
        totalUprightsCrosspiecesFixingsRecyclingRevenueIsTotal:
          "Selezionare per inserire costi per campi dei ricavi generati dal riciclaggio di montanti e traversi",
        uprightsCrosspiecesFixingsRecyclingQuantity:
          "Quantitá montanti e traversi (m)",
        uprightsCrosspiecesFixingsRecyclingUnitPrice:
          "Ricavo unitario per riciclaggio montanti e traversi (€/m)",

        //---------------Re Use---------------
        ReUse: "Riuso",
        totalReUseRevenue: "Ricavi generati dalla pratica di riuso (€)",
        totalReUseRevenueIsTotal:
          "Selezionare per inserire costi per campi dei ricavi generati dal riuso",

        //Windows
        totalWindowsReUseRevenue: "Ricavi riuso finestre (€)",
        totalWindowsReUseRevenueIsTotal:
          "Selezionare per inserire costi per campi dei ricavi generati dal riuso delle finestre",
        windowsReUseQuantity: "Quantitá finestre (u)",
        windowsReUseUnitPrice: "Ricavo unitario per riuso finestra (€/u)",

        //External cladding
        totalExternalCladdingReUseRevenue:
          "Ricavi riuso rivestimento esterno (€)",
        totalExternalCladdingReUseRevenueIsTotal:
          "Selezionare per inserire costi per campi dei ricavi generati dal riuso del rivestimento esterno",
        externalCladdingReUseQuantity: "Quantitá rivestimento esterno  (m²)",
        externalCladdingReUseUnitPrice:
          "Ricavo unitario per riuso rivestimento esterno (€/m²)",

        //Insulation
        totalInsulationReUseRevenue: "Ricavi riuso isolamento (€)",
        totalInsulationReUseRevenueIsTotal:
          "Selezionare per inserire costi per campi dei ricavi generati dal riuso dell'isolamento",
        insulationReUseQuantity: "Quantitá isolamento (m²)",
        insulationReUseUnitPrice: "Ricavo unitario per riuso isolamento (€/m²)",

        //Supporting elements and fixings
        totalSupportingElementsAndFixingsReUseRevenue:
          "Ricavi riuso pannello portante e fissaggio (€)",
        totalSupportingElementsAndFixingsReUseRevenueIsTotal:
          "Selezionare per inserire costi per campi dei ricavi generati dal riuso del pannello portante e del fissaggio",
        supportingElementsAndFixingsReUseQuantity:
          "Quantitá pannello portante e fissaggio (m²)",
        supportingElementsAndFixingsReUseUnitPrice:
          "Ricavo unitario per riuso pannello portante e fissaggio (€/m²)",

        //Glazed cells
        totalGlazedCellsReUseRevenue: "Ricavi riuso cellule vetrate",
        totalGlazedCellsReUseRevenueIsTotal:
          "Selezionare per inserire costi per campi dei ricavi generati dal riuso delle cellule vetrate",
        glazedCellsReUseQuantity: "Quantitá cellule vetrate (u)",
        glazedCellsUnitReUsePrice:
          "Ricavo unitario per riuso cellule vetrate (€/u)",

        //Double glazing
        totalDoubleGlazingReUseRevenue: "Ricavi riuso vetrocamera",
        totalDoubleGlazingReUseRevenueIsTotal:
          "Selezionare per inserire costi per campi dei ricavi generati dal riuso della vetrocamera",
        doubleGlazingReUseQuantity: "Quantitá vetrocamera (u)",
        doubleGlazingReUseUnitPrice:
          "Ricavo unitario per riuso vetrocamera (€/u)",

        //Uprights, Crosspieces and Fixings
        totalUprightsCrosspiecesFixingsReUseRevenue:
          "Ricavi riuso montanti, traversi e fissaggi",
        totalUprightsCrosspiecesFixingsReUseRevenueIsTotal:
          "Selezionare per inserire costi per campi dei ricavi generati dal riuso di montanti e traversi",
        uprightsCrosspiecesFixingsReUseQuantity:
          "Quantitá montanti e traversi (m)",
        uprightsCrosspiecesFixingsReUseUnitPrice:
          "Ricavo unitario per riuso montanti e traversi (€/m)",
        //---------------KPI---------------
        kpiTitle: "KPIs PREZZO FINE VITA FACCIATA  (un solo tipo di facciata)",
        benchmarkTitle: "Confronto con benchmark",
        kpiAllTitle:
          "KPIs PREZZO FINE VITA FACCIATA  (diversi tipi di facciata)",
        //totalDemolitionAndDisassemblyPrice
        //totalTransportPrice
        //totalRecyclingRevenue
        kpiSurface: "Superficie di facciata (m²)",

        kpi1: "KPI #1 fine vita - Prezzo dismissione su sup. facciata costruita (€/m²)",
        kpi2: "KPI #2 fine vita - Prezzo dismissione su sup. facciata dismessa (€/m²)",
        kpi3: "KPI #3 fine vita - ricavi generati su Prezzo totale produzione (%)",
      },
    },
  },
  formPlaceholders: {
    email: "Email",
    password: "Password",
    nominative: "Nominativo",
    category: "Nome categoria",
    details: {
      general: {
        id: "Id progetto",
        name: "Nome progetto",
        location: "Luogo",
        reportAuthor: "Autore dell'analisi",
        description: "Descrizione",
        type: "Tipo di progetto",
        certification: "Prestazione energetica edificio",
        useDestination: "Destinazione d'uso / tipologia edificio",
        constructionYear: "Anno di costruzione",
      },
      fancadeProject: {
        type: "Tipo facciata analizzata",
        surface: "Superficie di facciata",
        //Involucro opaco
        externalCladdingUnitsSurface:
          "Superficie unitaria pannelli di rivestimento esterno (m²/u)",
        externalCladdingUnitsNumber: "Numero pannelli di rivestimento esterno", //rivestimento
        totalExternalCladdingSurface: "Superficie totale rivestimento esterno", //rivestimento
        insulationSurface: "Superficie isolamento", //isolamento
        supportingElementsQuantity: "Metri lineari di sottostruttura", //elementi portanti
        prefabricatedModulesQuantity: "Quantità moduli prefabbricati",
        modulesSurface: "Superficie totale dei moduli (m²)",
        opaqueAnchoringsNumber: "Numero ancoraggi puntuali", //ancoraggi
        opaqueFixingsNumber: "Numero fissaggi (componenti di facciata)", //fissaggi
        //Involucro trasparente
        glazedCellsNumber: "Numero cellule vetrate",
        glazedCellsSurface: "Superficie unitaria cellula vetrata",
        doubleGlazingQuantity: "Quantità vetrocamera",
        doubleGlazingSurface: "Superficie vetrocamera",
        mainFrameHeightPerUnit: "Altezza main frame per unità vetrata",
        mainFrameWidthPerUnit: "Larghezza main frame per unità vetrata",
        totalMainFrameLength: "Lunghezza totale main frame",
        totalGasketLength: "Lunghezza totale guarnizioni",
        opaqueSpandrelPanelsQuantity: "Quantità pannelli spandrel opachi",
        opaqueSpandrelPanelsSurface: "Superficie pannelli spandrel opachi",
        transparentAnchoringsNumber: "Numero ancoraggi puntuali",
        transparentFixingsNumber: "Numero fissaggi (componenti di facciata)",
        //Altro
        windowOpaqueSurfaceRatio:
          "Rapporto superficie bifinestrata su superficie totale",
        opaqueUValue: "U-value (opaco)",
        transparentUValue: "U-value (trasparente)",
        windowGlassGValue: "G-value (vetro finestra)",
        fancadeGlassGValue: "G-value (vetro facciata continua trasparente)",
        prefabricationLevel: "Livello di prefabbricazione",
        geometricDetails: {
          module: "Modulo",
          width: "Larghezza (cm)",
          length: "Lunghezza (cm)",
          depth: "Spessore (cm)",
        },
        systemsIntegration: {
          commonName: "Altre integrazioni impiantistiche",
          name: "Nome",
          units: "Numero unità (u)",
          unitSurface: "Superficie unitaria (m²/u)",
          totalSurface: "Superficie totale (m²)",
          type: "Tipo di integrazione impiantistica",
          surfaceRatio:
            "Rapporto superficie impianto su superficie facciata (%) ",
        },
        systemsSurfacePercentage:
          "Percentuale di superficie occupata dagli impianti sul totale della facciata (totale per tutti gli impianti)",
        windowType: {
          windowType: "Tipologia di finestra",
          windowsNumber: "Quantità finestre",
          windowsSurface: "Superficie finestre",
          doubleGlazingNumber: "Quantità vetrocamera", //vetrocamera
          doubleGlazingSurface: "Superficie vetrocamera", //vetrocamera
          loomHeight: "Altezza telaio", //telaio
          loomWidth: "Larghezza telaio", //telaio
          totalLoomLength: "Lunghezza totale telaio", //telaio
          totalGasketLength: "Lunghezza totale guarnizioni", //guarnizione
        },
        //Integrazione impiantistica
        shieldingSystemsNumber: "Numero di sistemi di schermatura (u)",
        ventilationDevicesNumber: "Numero di apparecchi di ventilazione (u)",
        airConditioningDevicesNumber:
          "Numero di dispositivi per il condizionamento dell´aria (u)",
        thermicSolarPanelsNumber: "Numero di pannelli solari termici (u)",
        thermicSolarPanelsUnitSurface:
          "Superficie pannelli solari termici (m²/u)",
        photovoltaicModulesNumber: "Numero moduli fotovoltaici (u)",
        photovoltaicModulesSurface: "Superficie moduli fotovoltaici (m²)",
        photovoltaicModulesUnitSurface:
          "Superficie unitaria moduli fotovoltaici (m²/u)",
      },
      fancadePrice: {
        totalDesignPrice: "Prezzo progettazione totale",
        technicalProject: "Progetto tecnico",
        structuralSismicProject: "Progetto strutturale e sismico",
        lightingTechnicalProject: "Progetto illuminotecnico",
        ventilationProject: "Progetto ventilazione",
        acusticProject: "Progetto acustico",
        airQualityCheck: "Verifica qualità dell'aria",
        constructionPrinceAnalysis: "Analisi del prezzo di costruzione",
        firePreventionDesign: "Progettazione antincendio",
        buildingSiteManagement: "Gestione del cantiere - progetto",
        buildingSiteSafety: "Sicurezza in cantiere - progetto",
        energeticPerformanceAnalysis:
          "Valutazione e certificazione della performance energetica",
        competitionProject: "Progetto per base gara",
        maintenancePlan: "Piano di manutenzione",
        userManual: "Manuale d'uso",
      },
      fancadeProduction: {
        opaqueShieldingMaterials: "Involucro opaco",
        transparentShieldingMaterials: "Involucro trasparente",
        plantIntegration: "Integrazione impiantistica",
        scrap: "Sfrido",

        totalProductionPrice: "Prezzo totale produzione (€)",
        totalMaterialsPrice: "Prezzo totale materiali (€)",

        fancadeSurface: "Superficie di facciata (m²)",
        windowsTitle: "Prezzo finestre per tipologia",
        windowByTotal:
          "Selezionare per inserire costi per campi del prezzo totale delle finestre della facciata",
        checkboxTotalWindowPrice:
          "Selezionare per inserire costi per campi del prezzo della tipologia di finestra",
        transparentTotalPrice: "Prezzo totale involuco trasparente",
        windowsTotalPrice: "Prezzo totale finestre (€)",
        windowContainer: "Tipologia di finestra",
        totalWindowPrice: "Prezzo totale tipologia di finestra (€)",
        windowSelect: "Seleziona la modalità di calcolo",
        unityOption: "Calcola per unità",
        componentsOption: "Calcolo per componenti",
        windowsNumber: "Quantità finestre (u)",
        unitPrice: "Prezzo finestre al mq (€/m²)",
        loomByTotal:
          "Selezionare per inserire costi per campi del prezzo del telaio",
        loomComponentsPrice: "Prezzo componenti finestra - telaio (€)",
        loomUnitPrice: "Prezzo componenti finestra - telaio (€)",
        totalLoomLength: "Lunghezza totale telaio (m)",
        doubleGlazingComponentsPrice:
          "Prezzo componenti finestra - vetrocamera (€)",
        doubleGlazingByTotal:
          "Selezionare per inserire costi per campi del prezzo della vetrocamera",

        doubleGlazingNumber: "Quantità guarnizioni (u)",
        doubleGlazingUnitPrice: "Prezzo unitario guarnizioni (€/u)",
        gasketByTotal:
          "Selezionare per inserire costi per campi del prezzo delle guarnizioni",
        gasketComponentsPrice: "Prezzo componenti finestra - guarnizioni (€)",
        gasketsUnitPrice: "Prezzo componenti finestra - guarnizioni (€)",
        totalGasketLength: "Lunghezza totale guarnizioni (m)",
        externalCladdingByTotal:
          "Selezionare per inserire costi per campi del prezzo del rivestimento esterno",
        externalCladdingTotalPrice:
          "Prezzo involucro opaco - rivestimento esterno (€)",
        totalExternalCladdingSurface:
          "Superficie totale rivestimento esterno (m²)",
        externalCladdingUnitPrice:
          "Prezzo unitario rivestimento esterno (€/m²)",
        insulationByTotal:
          "Selezionare per inserire costi per campi del prezzo dell'isolamento",
        insulationTotalPrice: "Prezzo involucro opaco - isolamento (€)",
        insulationSurface: "Superficie totale isolamento (m²)",
        insulationUnitPrice: "Prezzo unitario isolamento (€/m²)",

        supportingElementsByTotal:
          "Selezionare per inserire costi per campi del prezzo degli elementi portanti",
        supportingElementsTotalPrice:
          "Prezzo involucro opaco - elementi portanti (€)",
        totalSupportingElementsQuantity: "Metri lineari di sottostruttura (m)",
        supportingElementsUnitPrice: "Prezzo unitario elemento portante (€/m)",

        prefabricatedModulesByTotal:
          "Selezionare per inserire costi per campi del prezzo degli moduli prefabbricati",
        prefabricatedModulesTotalPrice:
          "Prezzo involucro opaco - moduli prefabbricati (€)",
        totalPrefabricatedModulesQuantity: "Quantità moduli prefabbricati (u)",
        prefabricatedModulesElementsUnitPrice:
          "Prezzo unitario modulo prefabbricato (€/m²)",

        opaqueAnchoringByTotal:
          "Selezionare per inserire costi per campi del prezzo del sistema di ancoraggio",
        opaqueAnchoringTotalPrice:
          "Prezzo involucro opaco - sistema di ancoraggio (€)",
        opaqueAnchoringsNumber: "Numero ancoraggi puntuali (u)",
        opaqueAnchoringUnitPrice: "Prezzo unitario ancoraggi (€/u)",

        opaqueFixingsByTotal:
          "Selezionare per inserire costi per campi del prezzo del sistema di fissaggio",
        opaqueFixingsTotalPrice:
          "Prezzo involucro opaco - sistema di fissaggio (€)",
        opaqueFixingsNumber: "Numero fissaggi (componenti di facciata) (u)",
        opaqueFixingsUnitPrice: "Prezzo unitario fissaggi (€/u)",

        hardware: "Ferramenta e accessori",
        hardwareCheckbox:
          "Selezionare per inserire costi per campi del prezzo di ferramenta e accessori",
        singleHardwareTotalPrice: "Prezzo ferramenta e accessori (€)",
        hardwareTotalPrice:
          "Prezzo involucro opaco - ferramenta e accessori (€)",
        hardwareUnitPrice: "Prezzo unitario ferramenta e accessori (€/u) ",
        hardwareQuantity: "Quantitá ferramenta e accessori (u)",

        glazedCellsByTotal:
          "Selezionare per inserire costi per campi del prezzo della cellula vetrata",
        totalGlazedCellsPrice:
          "Prezzo involucro trasparente - cellula vetrata (€)",
        glazedCellsUnitPrice: "Prezzo unitario cellula vetrata (€/u)",
        glazedCellsNumber: "Involucro trasparente - numero cellule vetrate (u)",

        totalDoubleGlazingPrice:
          "Prezzo involucro trasparente - vetrocamera (€)",
        doubleGlazingQuantity:
          "Involucro trasparente - quantitá vetrocamera (u)",

        totalMainFramePrice: "Prezzo involucro trasparente - main frame (€)",
        mainFrameUnitPrice: "Prezzo unitario main frame (€/u)",
        totalMainFrameLength: "Lunghezza totale main frame (m)",

        totalGasketPrice: "Prezzo involucro trasparente - guarnizioni (€)",
        gasketUnitPrice: "Prezzo unitario guarnizioni (€/m)",

        opaqueSpandrelByTotal:
          "Selezionare per inserire costi per campi del prezzo dei pannelli spandrel opachi",
        totalOpaqueSpandrelModulesPrice:
          "Prezzo involucro trasparente - pannello spandrel opaco (€)",
        opaqueSpandrelModulesUnitPrice:
          "Prezzo unitario pannello spandrel opaco (€/u)",
        opaqueSpandrelPanelsQuantity:
          "Involucro trasparente - quantitá pannelli spandrel opachi (u)",

        transparentAnchoringByTotal:
          "Selezionare per inserire costi per campi del prezzo del sistema di ancoraggio",
        totalTransparentAnchoringsPrice:
          "Prezzo involucro trasparente - sistema di ancoraggio (€)",
        transparentAnchoringsUnitPrice: "Prezzo unitario ancoraggi (€/u)",
        transparentAnchoringsNumber:
          "Involucro trasparente - Numero ancoraggi puntuali (u)",

        fixingsByTotal:
          "Selezionare per inserire costi per campi del prezzo del sistema di fissaggio",
        totalTransparentFixingsNumberPrice:
          "Involucro trasparente - sistema di fissaggio (€)",
        transparentFixingsNumberUnitPrice: "Prezzo unitario fissaggio (€/u)",
        transparentFixingsNumber:
          "Involucro trasparente - Numero fissaggi (componenti di facciata) (u)",

        //Systems Integration production
        systemsIntegrationByTotal:
          "Selezionare per inserire costi per campi del prezzo dell'integrazione impiantistica",
        totalSystemsIntegrationPrice: "Prezzo integrazione impiantistica (€)",

        shieldingByTotal:
          "Selezionare per inserire costi per campi del prezzo del sistema di schermatura",
        shieldingsUnitPrice: "Prezzo unitario sistemi di schermatura (€/u)",
        shieldingSystemsNumber: "Numero di sistemi di schermatura (u)",
        totalShieldingsPrice: "Prezzo sistema di schermatura (€)",

        ventilationByTotal:
          "Selezionare per inserire costi per campi del prezzo del sistema di ventilazione",
        ventilationSystemsUnitPrice:
          "Prezzo unitario apparecchi di ventilazione (€/u)",
        ventilationDevicesNumber: "Numero di apparecchi di ventilazione (u)",
        totalVentilationSystemsPrice: "Prezzo sistema di ventilazione (€)",

        airConditioningByTotal:
          "Selezionare per inserire costi per campi del prezzo del sistema di condizionamento dell'aria",
        airConditioningSystemsUnitPrice:
          "Prezzo unitario sistemi di condizionamento dell´aria (€/u)",
        airConditioningDevicesNumber:
          "Numero di dispositivi per il condizionamento dell´aria (u)",
        totalAirConditioningSystemsPrice:
          "Prezzo sistema condizionamento aria (€)",

        thermicSolarPanelsByTotal:
          "Selezionare per inserire costi per campi del prezzo del sistema solare termico",
        thermicSolarPanelsUnitPrice:
          "Prezzo unitario modulo solare termico (€/u)",
        thermicSolarPanelsNumber: "Numero di pannelli solari termici (u)",
        totalThermicSolarPanelsPrice: "Prezzo sistema solare termico (€)",

        photovoltaicModulesByTotal:
          "Selezionare per inserire costi per campi del prezzo del sistema fotovoltaico integrato",
        photovoltaicModulesUnitPrice:
          "Prezzo unitario modulo fotovoltaico (€/u)",
        photovoltaicModulesNumber: "Numero moduli fotovoltaici (u)",
        totalPhotovoltaicModulesPrice:
          "Prezzo sistema fotovoltaico integrato (€)",

        //Scrap production
        scrapPriceByTotal:
          "Selezionare per inserire costi per campi del prezzo degli sfridi",
        totalScrapPrice: "Prezzo totale sfridi (€) ",

        doubleGlazingScrapByTotal:
          "Selezionare per inserire costi per campi del prezzo dello sfrido della vetrocamera",
        doubleGlazingScrapQuantity: "Quantitá sfrido vetrocamera (u)",
        doubleGlazingScrapUnitPrice: "Prezzo unitario sfrido vetrocamera (€/u)",
        totalDoubleGlazingScrapPrice: "Sfrido (€) - Vetrocamera",

        mainFrameOrLoomByTotal:
          "Selezionare per inserire costi per campi del prezzo dello sfrido del Telaio/main frame ",
        mainFrameOrLoomScrapQuantity: "Quantitá sfrido Telaio/main frame (m)",
        mainFrameOrLoomScrapUnitPrice:
          "Prezzo unitario sfrido Telaio/main frame (€/m)",
        totalMainFrameOrLoomScrapPrice: "Sfrido (€) - Telaio/main frame",

        gasketScrapByTotal:
          "Selezionare per inserire costi per campi del prezzo dello sfrido delle guarnizioni",
        gasketScrapQuantity: "Quantitá sfrido guarnizioni (m)",
        gasketScrapUnitPrice: "Prezzo unitario sfrido guarnizioni (€/m)",
        totalGasketScrapPrice: "Sfrido (€) - Guarnizioni",

        opaqueWindowsScrapByTotal:
          "Selezionare per inserire costi per campi del prezzo dello sfrido delle finestre",
        opaqueWindowsScrapQuantity: "Quantitá sfrido finestre (u)", //Solo opache
        opaqueWindowsScrapUnitPrice: "Prezzo unitario sfrido finestre (€/u)", //Solo opache
        totalOpaqueWindowsScrapPrice: "Sfrido (€) -  Facciata opaca - Finestre", //Solo opache

        opaqueExternalCladdingScrapByTotal:
          "Selezionare per inserire costi per campi del prezzo dello sfrido delle finestre",
        opaqueExternalCladdingScrapQuantity:
          "Quantitá sfrido rivestimento esterno (m²)", //Solo opache
        opaqueExternalCladdingScrapUnitPrice:
          "Prezzo unitario sfrido rivestimento esterno (€/m²)",
        totalOpaqueExternalCladdingScrapPrice:
          "Sfrido (€) -  Facciata opaca - Rivestimento esterno", //Solo opache

        opaqueInsulationScrapByTotal:
          "Selezionare per inserire costi per campi del prezzo dello sfrido dell'isolamento",
        opaqueInsulationScrapQuantity: "Quantitá sfrido isolamento (m²)", //Solo opache
        opaqueInsulationScrapUnitPrice:
          "Prezzo unitario sfrido isolamento (€/m²)",
        totalOpaqueInsulationScrapPrice:
          "Sfrido (€) -  Facciata opaca - Isolamento", //Solo opache

        opaqueSupportingElementsScrapByTotal:
          "Selezionare per inserire costi per campi del prezzo dello sfrido degli elementi portanti",
        opaqueSupportingElementsScrapQuantity:
          "Quantitá sfrido elementi portanti (m3)", //Solo opache
        opaqueSupportingElementsScrapUnitPrice:
          "Prezzo unitario sfrido elementi portanti (€/m3)",
        totalOpaqueSupportingElementsScrapPrice:
          "Sfrido (€) -  Facciata opaca - Elementi portanti", //Solo opache

        transparentGlazedCellScrapByTotal:
          "Selezionare per inserire costi per campi del prezzo dello sfrido della cellula vetrata",
        transparentGlazedCellScrapQuantity:
          "Quantitá sfrido cellula vetrata (u)", //Solo trasparenti
        transparentGlazedCellsScrapUnitPrice:
          "Prezzo unitario sfrido cellula vetrata (€/u)",
        totalTransparentGlazedCellScrapPrice:
          "Sfrido (€) -  Facciata trasparente - Cellula vetrata", //Solo trasparenti

        transparentOpaqueSpandrelModulesScrapByTotal:
          "Selezionare per inserire costi per campi del prezzo dello sfrido dei moduli opachi spandrel",
        transparentOpaqueSpandrelModulesQuantity:
          "Quantitá sfrido moduli opachi spandrel (u)", //Solo trasparenti
        transparentOpaqueSpandrelModulesScrapUnitPrice:
          "Prezzo unitario sfrido moduli opachi spandrel (€/u)",
        totalTransparentOpaqueSpandrelModulesPrice:
          "Sfrido (€) -  Facciata trasparente - Moduli opachi spandrel", //Solo trasparenti

        fixingsScrapByTotal:
          "Selezionare per inserire costi per campi del prezzo dello sfrido del sistema di fissaggio",
        fixingsScrapQuantity: "Quantitá sfrido sistema di fissaggio (u)",
        fixingsScrapUnitPrice:
          "Prezzo unitario sfrido sistema di fissaggio (€/u)",
        totalFixingsScrapPrice: "Sfrido (€) - Sistema di fissaggio",

        anchoringsScrapByTotal:
          "Selezionare per inserire costi per campi del prezzo dello sfrido del sistema di ancoraggio",
        anchoringsScrapQuantity: "Quantitá sfrido sistema di ancoraggio (u)",
        anchoringsScrapUnitPrice:
          "Prezzo unitario sfrido sistema di ancoraggio (€/u)",
        totalAnchoringsScrapPrice: "Sfrido (€) - Sistema di ancoraggio",

        accessoriesScrap: "Sfrido (€) - Accessori",

        //Labor
        totalLaborPriceByTotal:
          "Selezionare per inserire costi per campi del prezzo totale della manodopera",
        totalLaborPrice: "Prezzo totale manodopera (€)",

        baseLaborPriceByTotal:
          "Selezionare per inserire costi per campi del prezzo totale della manodopera di base",
        totalBaseLaborPrice: "Prezzo manodopera base (€)",
        baseLaborHours: "Ore manodopera base (h)",
        baseLaborHourPrice: "Prezzo orario manodopera base (€/h)",

        skilledLaborPriceByTotal:
          "Selezionare per inserire costi per campi del prezzo totale della manodopera specializzata",
        totalSkilledLaborPrice: "Prezzo manodopera specializzata (€)",
        skilledLaborHours: "Ore manodopera specializzata (h)",
        skilledLaborHourPrice: "Prezzo orario manodopera specializzata (€/h)",
      },
      transport: {
        totalTransportPrice: "Prezzo totale trasporto (€)",
        totalTransportCheckbox:
          "Selezionare per inserire costi per campi del prezzo totale del trasporto",
        //Packaging
        totalPackagingPrice: "Prezzo totale imballaggio (€)",
        totalPackagingCheckbox:
          "Selezionare per inserire costi per campi del prezzo totale dell'imballaggio",
        totalPrice: "Prezzo totale tipo di imballaggio (€)",
        unitsNumber: "Numero unitá per tipo di imballaggio (u)",
        unitPrice: "Prezzo unitario per tipo di imballaggio (€/u)",

        //Transport
        totalTransportPartPrice: "Prezzo totale trasporto (€)",
        workSiteProductionSiteDistance:
          "Distanza tra cantiere e sito di produzione (Km)",
        transportPartUnitPrice: "Prezzo unitario trasporto al km (€/Km)",

        //KPIs
        kpiTransportTitle:
          "KPIs PREZZO TRASPORTO FACCIATA (un solo tipo di facciata)",
        fancadeSurface: "Superficie di facciata (m²)",
        //totalTransportPrice
        //workSiteProductionSiteDistance
        transportPrice1: "Prezzo di trasporto KPI #1 (€/m²)",
        transportPrice2: "Prezzo di trasporto KPI #2 (€/Km)",
        benchmarkDifference: "Differenza con soluzione benchmark",
      },
      buildingSite: {
        installationByTotal:
          "Selezionare per inserire costi per campi del prezzo dell'installazione facciata",
        totalInstallationPrice: "Prezzo totale installazione facciata (€)",

        materialsByTotal:
          "Selezionare per inserire costi per campi del prezzo dei materiali necessari al cantiere",
        totalBuildingSiteMaterialsPrice:
          "Prezzo totale dei materiali necessari al cantiere (€)",

        additionalMaterial: "Materiale addizionale",
        additionalMaterialByTotal:
          "Selezionare per inserire costi per campi del prezzo del materiale addizionale",
        totalAdditionalMaterialPrice:
          "Prezzo totale materiale addizionale (€) ",
        additionalMaterialQuantity: "Quantitá materiale addizionale (m²) ",
        additionalMaterialUnitPrice:
          "Prezzo unitario materiale addizionale (€/m²) ",

        buildingSiteDuration: "Ore totali durata del cantiere ",

        //Labor
        laborByTotal:
          "Selezionare per inserire costi per campi del prezzo della manodopera",
        totalLaborPrice: "Prezzo totale manodopera (€)",

        baseLaborByTotal:
          "Selezionare per inserire costi per campi del prezzo della manodopera base",
        totalBaseLaborPrice: "Prezzo manodopera base (€)",
        baseLaborHours: "Ore manodopera base (h)",
        baseLaborHourPrice: "Prezzo orario manodopera base (€/h)",

        skilledLaborByTotal:
          "Selezionare per inserire costi per campi del prezzo della manodopera specializzata",
        totalSkilledLaborPrice: "Prezzo manodopera specializzata (€)",
        skilledLaborHours: "Ore manodopera specializzata (h)",
        skilledLaborHourPrice: "Prezzo orario manodopera specializzata (€/h)",

        //Tools
        toolsByTotal:
          "Selezionare per inserire costi per campi del prezzo degli strumenti di lavoro",
        totalToolsPrice: "Prezzo totale strumenti di lavoro (€)",

        craneByTotal:
          "Selezionare per inserire costi per campi del prezzo della gru",
        totalTowerCranePrice: "Prezzo totale gru (€)",
        towerCraneHours: "Ore di utilizzo della gru (h)",
        towerCraneHourPrice: "Prezzo orario utilizzo della gru (€/h)",

        elevatorPlatformByTotal:
          "Selezionare per inserire costi per campi del prezzo delle piattaforme elevatrici verticali",
        totalElevatorPlatformPrice:
          "Prezzo totale piattaforme elevatrici verticali (€)",
        elevatorPlatformHours:
          "Ore di utilizzo delle piattaforme levatrici (h)",
        elevatorPlatformHourPrice:
          "Prezzo orario delle piattaforme levatrici (€/h)",

        aerialPlatformByTotal:
          "Selezionare per inserire costi per campi del prezzo delle piattaforme aeree",
        totalAerialPlatformPrice: "Prezzo totale delle piattaforme aeree (€)",
        aerialPlatformHours: "Ore di utilizzo delle piattaforme aeree (h)",
        aerialPlatformHourPrice: "Prezzo orario delle piattaforme aeree (€/h)",

        scaffoldingByTotal:
          "Selezionare per inserire costi per campi del prezzo del ponteggio",
        totalScaffoldingPrice: "Prezzo totale ponteggio (€)",
        scaffoldingSelect: "Calcolo per unità ",
        scaffoldingSurface: "Superficie coperta da ponteggio (m²)",
        scaffoldingSurfaceUnitPrice:
          "Prezzo unitario ponteggio per superficie (€/m²)",
        scaffoldingHours: "Ore di utilizzo del ponteggio (h)",
        scaffoldingHourPrice: "Prezzo orario del ponteggio (€/h)",

        otherByTotal:
          "Selezionare per inserire costi per campi del prezzo di altre infrastrutture",
        totalOtherPrice: "Prezzo totale altre infrastrutture (€)",
        otherHours: "Ore di utilizzo di altre attrezzature (h)",
        otherHourPrice: "Prezzo orario di altre attrezzature (€/h)",

        //-------------Security-------------
        securityByTotal:
          "Selezionare per inserire costi per campi del prezzo della sicurezza e gestione della sicurezza in cantiere",
        totalSecurityPrice:
          "Prezzo totale sicurezza e gestione della sicurezza in cantiere (€)",

        staffByTotal:
          "Selezionare per inserire costi per campi del prezzo del personale per gestire la sicurezza",
        buildingSiteStaffByTotal:
          "Selezionare per inserire costi per campi del prezzo del personale per gestire il cantiere",
        totalStaffSecurityPrice:
          "Prezzo del personale per gestire la sicurezza (€)",
        staffSecurityHours: "Ore di lavoro per gestione sicurezza (h)",
        staffSecurityHourPrice: "Prezzo orario del personale (€/h)",

        totalStaffBuildingSitePrice:
          "Prezzo del personale per gestire il cantiere (€)",
        staffBuildingSiteHours: "Ore di lavoro per gestione cantiere (h)",
        staffBuildingSiteHourPrice: "Prezzo orario del personale (€/h)",

        taxes: "Tasse e tributi (e.g. occupazione suolo pubblico, altro....)",
        taxCost: "Costo (€)",
        other: "Altre voci ? (feedback dalle aziende)",

        //Kpi
        kpiTitle:
          "KPIs PREZZO DI COSTRUZIONE IN CANTIERE (un solo tipo di facciata)",
        benchmarkTitle: "Confronto con Benchmark",
        kpiAllTitle:
          "KPIs PREZZO DI COSTRUZIONE IN CANTIERE (diversi tipi di facciata)",
        totalInstallationSecurityPrice:
          "Prezzo di costruzione totale facciata + sicurezza e gestione cantiere (€)",

        surface: "Superficie di facciata (m²)",
        installationSecuritySurfaceRatio:
          "Prezzo di costruzione + sicurezza e gestione su superficie facciata (€/m²)",
        installationSurfaceRatio:
          "Prezzo installazione facciata su superficie facciata (€/m²)",
        securitySurfaceRatio:
          "Prezzo sicurezza e gestione della sicurezza in cantiere su superficie facciata (€/m²)",

        installationTotalRatio:
          "Rapporto Prezzo installazione su Prezzo totale costruzione (inc. sicurezza)",
        securityTotalRatio:
          "Rapporto Prezzo sicurezza e gestione sicurezza su Prezzo totale costruzione (inc. sicurezza) ",

        buildingSiteTotal: "Construction site total cost (€)",
        buildingSiteTotalKpi: "Construction site cost KPI (€/m²)",
      },
      serviceLife: {
        annualLifeCost: "Prezzo totale operativo (€/intervento)",
        annualLifeCostCalculation:
          "Selezionare per inserire costi per campi prezzo totale operativo",

        //------------- Annual Integrated Implants Cost -------------
        annualIntegratedImplantsCost:
          "Prezzo del consumo annuo dei sistemi impiantistici integrati (€/intervento)",
        annualIntegratedImplantsCostCalculation:
          "Selezionare per inserire costi per campi prezzo del consumo annuo dei sistemi impiantistici integrati",

        annualShieldingsCost:
          "Prezzo del consumo annuo del sistema di schermatura (€/intervento)",
        annualShieldingCostsCalculation:
          "Selezionare per inserire costi per campi prezzo del consumo annuo del sistema di schermatura",
        shieldingsNumber: "Numero di sistemi di schermatura (u)",
        annualShieldingsConsume:
          "Consumo annuo singolo sistema di schermatura (kwh/u)",
        energeticShieldingVectorUnitPrice:
          "Prezzo unitario del vettore energetico (€/kwh)",

        annualVentilationCost:
          "Prezzo del consumo annuo del sistema di ventilazione (€/intervento)",
        annualVentilationCostsCalculation:
          "Selezionare per inserire costi per campi prezzo del consumo annuo del sistema di ventilazione",
        ventilationsNumber: "Numero di apparecchi di ventilazione (u)",
        annualVentilationConsume:
          "Consumo annuo singolo sistema di ventilazione (kwh/u)",
        energeticVentilationVectorUnitPrice:
          "Prezzo unitario del vettore energetico (€/kwh)",

        annualConditioningCost:
          "Prezzo del consumo annuo sistema condizionamento aria (€/intervento)",
        annualConditioningCostsCalculation:
          "Selezionare per inserire costi per campi prezzo del consumo annuo sistema condizionamento aria",
        conditioningNumber:
          "Numero di dispositivi per il condizionamento dell´aria (u)",
        annualConditioningConsume:
          "Consumo annuo singolo sistema di condizionamento dell´aria (kwh/u)",
        energeticConditioningVectorUnitPrice:
          "Prezzo unitario del vettore energetico (€/kwh)",

        annualThermicSolarCost:
          "Prezzo del consumo annuo sistema solare termico (€/intervento)",
        annualThermicSolarCostsCalculation:
          "Selezionare per inserire costi per campi prezzo del consumo annuo sistema solare termico",
        thermicSolarNumber: "Numero di pannelli solari termici (u)",
        annualThermicSolarConsume:
          "Consumo annuo singolo pannello solare termico (kwh/u)",
        energeticThermicSolarVectorUnitPrice:
          "Prezzo unitario del vettore energetico (€/kwh)",

        annualPhotovoltaicIsActive:
          "Inclusione produzione annua sistema fotovoltaico integrato",
        annualPhotovoltaicCost:
          "Produzione annua sistema fotovoltaico integrato (€/intervento)",
        annualPhotovoltaicCostsCalculation:
          "Selezionare per inserire costi per campi produzione annua sistema fotovoltaico integrato",
        photovoltaicNumber: "Numero moduli fotovoltaici (u)",
        annualPhotovoltaicConsume:
          "Produzione annua singolo modulo fotovoltaico (kWh/u)",
        energeticPhotovoltaicVectorUnitPrice:
          "Prezzo unitario del vettore energetico (€/kwh)",

        //------------- Other implants -------------
        otherImplant: "Altro sistema impiantistico pre-esistente",
        addOtherImplant: "Aggiungi sistema impiantistico pre-esistente",
        annualOtherImplantsCost:
          "Prezzo del consumo annuo di altri sistemi impiantistici pre-esistenti (€/intervento)",
        annualOtherImplantsCostCalculation:
          "Selezionare per inserire costi per campi prezzo del consumo annuo di altri sistemi impiantistici pre-esistenti",

        annualImplantCost: "Prezzo del consumo annuo (€/intervento)",
        annualImplantCostCalculation:
          "Selezionare per inserire costi per campi prezzo del consumo annuo",
        annualImplantConsume: "Consumo annuo singolo (vettore energetico/u)",
        energeticImplantVectorUnitPrice:
          "Prezzo unitario del vettore (€/vettore energetico)",
      },
      maintenance: {
        refLife: "Vita utile di riferimento per il progetto (anni)",

        totalMaintenancePercentage:
          "Inserire la percentuale del prezzo di manutenzione totale rispetto rispetto al costo di produzione totale (%)",
        totalExtraordinaryMaintenancePercentage:
          "Inserire la percentuale del prezzo di manutenzione straordinaria rispetto rispetto al costo di produzione totale (%)",

        totalMaintenancePrice:
          "Prezzo totale manutenzione incluso impianti - calcolato (€/anno)",
        maintenanceSelect: "Selezionare il tipo di inserimento",

        //---------------Ordinary maintenance---------------
        totalOrdinaryMaintenancePrice:
          "Manutenzione ordinaria - calcolato (€/anni scelti)",
        totalOrdinaryMaintenancePriceIsTotal:
          "Selezionare il tipo di inserimento",

        totalOrdinaryMaintenancePercentage:
          "Inserire la percentuale del prezzo di manutenzione ordinaria rispetto rispetto al costo di produzione totale (%)",
        //Opaque
        totalWindowReplacementPrice:
          "Sostituzione di intera finestra (€/intervento)",
        totalWindowReplacementPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della sostituzione di un'intera finestra",
        windowsNumber: "Quantitá finestre (u)",
        windowUnitPrice: "Prezzo unitario finestre (€/u)",

        totalOpaqueLoomFixingsReplacementPrice:
          "Sostituzione componenti finestra - telaio e fissaggio (€/intervento)",
        totalOpaqueLoomFixingsReplacementPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della sostituzione di telaio e fissaggio",
        opaqueLoomFixingsQuantity: "Quantitá telaio e fissaggio (u)",
        opaqueLoomFixingsUnitPrice: "Prezzo unitario telaio e fissaggio (€/u)",

        totalOpaqueDoubleGlazingReplacementPrice:
          "Sostituzione componenti finestra - vetrocamera (€/intervento)",
        totalOpaqueDoubleGlazingReplacementPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della sostituzione della vetrocamera",
        opaqueDoubleGlazingQuantity: "Quantitá vetrocamera (u)",
        opaqueDoubleGlazingUnitPrice: "Prezzo unitario vetrocamera (€/u)",

        totalOpaqueShieldingsReplacementPrice:
          "Sostituzione componenti finestra - sistema di schermatura (€/intervento)",
        totalOpaqueShieldingsReplacementPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della sostituzione del sistema di schermatura",
        opaqueShieldingsQuantity: "Quantitá sistema di schermatura (u)",
        opaqueShieldingsUnitPrice:
          "Prezzo unitario sistema di schermatura (€/u)",

        totalExternalCladdingReplacementPrice:
          "Involucro opaco - sostituzione rivestimento esterno (€/intervento)",
        totalExternalCladdingReplacementPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della sostituzione del rivestimento esterno",
        externalCladdingQuantity: "Superficie totale rivestimento esterno (m²)",
        externalCladdingUnitPrice:
          "Prezzo unitario rivestimento esterno (€/m²)",

        totalInsulationReplacementPrice:
          "Involucro opaco - sostituzione isolamento (€/intervento)",
        totalInsulationReplacementPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della sostituzione dell'isolamento",
        insulationSurface: "Superficie isolamento (m²)",
        insulationUnitPrice: "Prezzo unitario isolamento (€/m²)",

        totalSupportingElementsReplacementPrice:
          "Involucro opaco - elemento portante (€/intervento)",
        totalSupportingElementsReplacementPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della sostituzione dell'elemento portante",
        supportingElementsQuantity: "Quantitá elemento portante (m3)",
        supportingElementUnitPrice: "Prezzo unitario elemento portante (€/m3)",

        totalPrefabricatedModulesReplacementPrice:
          "Involucro opaco - sostituzione modulo prefabbricato (€/intervento)",
        totalPrefabricatedModulesReplacementPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della sostituzione dei moduli prefabbricati",
        prefabricatedModulesQuantity: "Quantitá moduli prefabbricati (u)",
        prefabricatedModulesUnitPrice: "Prezzo moduli al mq (€/m²)",

        //Transparent
        totalGlazedCellsReplacementPrice:
          "Involucro trasparente - sostituzione cellule vetrate (€/intervento)",
        totalGlazedCellsReplacementPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della sostituzione delle cellule vetrate",
        glazedCellsNumber: "Involucro trasparente - numero cellule vetrate (u)",
        glazedCellUnitPrice: "Prezzo unitario cellula vetrata (€/u)",

        totalDoubleGlazingReplacementPrice:
          "Involucro trasparente - sostituzione vetrocamera (€/intervento)",
        totalDoubleGlazingReplacementPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della sostituzione della vetrocamera",
        doubleGlazingQuantity:
          "Involucro trasparente - quantitá vetrocamera (u)",
        doubleGlazingUnitPrice: "Prezzo unitario vetrocamera (€/u)",

        totalTransparentLoomFixingsReplacementPrice:
          "Involucro trasparente - sostituzione telaio o fissaggi (€/intervento)",
        totalTransparentLoomFixingsReplacementPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della sostituzione del telaio o dei fissaggi",
        transparentLoomFixingsQuantity: "Quantitá telaio o fissaggi (u)",
        transparentLoomFixingsUnitPrice:
          "Prezzo unitario telaio o fissaggi (€/u)",

        totalTransparentShieldingsReplacementPrice:
          "Involucro trasparente - sistema di schermatura (€/intervento)",
        totalTransparentShieldingsReplacementPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della sostituzione del sistema di schermatura",
        transparentShieldingsQuantity: "Quantitá sistema di schermatura (u)",
        transparentShieldingsUnitPrice:
          "Prezzo unitario sistema di schermatura (€/u)",

        //Systems Integrations
        totalPhotovoltaicModulesReplacementPrice:
          "Componenti impiantistici - Moduli fotovoltaici integrati (€/intervento)",
        totalPhotovoltaicModulesReplacementPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della sostituzione dei moduli fotovoltaici integrati",
        photovoltaicModulesNumber: "Numero moduli fotovoltaici (u)",
        photovoltaicModuleUnitPrice:
          "Prezzo unitario modulo fotovoltaico (€/u)",

        totalSolarPanelsReplacementPrice:
          "Componenti impiantistici - pannelli solari termici (€/intervento)",
        totalSolarPanelsReplacementPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della sostituzione dei pannelli solari termici",
        solarPanelsNumber: "Numero di pannelli solari termici (u)",
        solarPanelUnitPrice: "Prezzo unitario modulo solare termico (€/u)",

        totalVentilationDevicesReplacementPrice:
          "Componenti impiantistici - apparecchi di ventilazione (€/intervento)",
        totalVentilationDevicesReplacementPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della sostituzione degli apparecchi di ventilazione",
        ventilationDevicesNumber: "Numero di apparecchi di ventilazione (u)",
        ventilationDeviceUnitPrice:
          "Prezzo unitario apparecchi di ventilazione (€/u)",

        totalAirConditioningDevicesReplacementPrice:
          "Componenti impiantistici - condizionamento dell´aria (€/intervento)",
        totalAirConditioningDevicesReplacementPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della sostituzione del condizionamento dell'aria",
        airConditioningDevicesNumber:
          "Numero di dispositivi per il condizionamento dell´aria (u)",
        airConditioningDeviceUnitPrice:
          "Prezzo unitario sistemi di condizionamento dell´aria (€/u)",

        //Cleaning
        totalCleaningPrice: "Prezzi di pulizia (€/anno)",
        totalCleaningPriceIsTotal:
          "Selezionare per inserire costi per campi dei prezzi di pulizia",
        cleaningsEveryHowManyYears:
          "Periodo degli interventi di pulizia (ogni quanti anni vengono effettuati)",
        cleaningUnitPrice:
          "Prezzo unitario intervento di pulizia - intera facciata (€/u)",

        //---------------Extraordinary maintenance---------------
        totalExtraordinaryMaintenancePrice:
          "Manutenzione straordinaria - calcolato (€/intervento)",
        totalExtraordinaryMaintenancePriceIsTotal:
          "Selezionare il tipo di inserimento",

        everyHowManyYears:
          "Periodo della manutenzione (ogni quanti anni viene effettuata)",
        actionName: "Nome azione",
        actionValue: "Valore azione (€)",
      },
      costsActualization: {
        interestRate: "Tasso di interesse (%)",
        priceVectorVariation:
          "Tasso di variazione prezzo vettore energetico (%)",
      },
      endOfLife: {
        demolitionAndDisassembly: "Demolizione e smontaggio aggregato",
        totalDemolitionAndDisassemblyPrice:
          "Prezzo totale per demolizione e smontaggio (€)",
        totalDemolitionAndDisassemblyPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo di demolizione e smontaggio",

        surfaceDemolitionOrDisassembly:
          "Superficie totale di facciata smontata o demolita  (m²)",

        //---------------Demolition---------------
        demolition: "Demolizione",
        totalDemolitionPrice: "Prezzo totale demolizione (€)",
        totalDemolitionPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo della demolizione",

        demolitionQuantity: "Quantitá demolizione (m²)",
        demolitionUnitPrice: "Prezzo unitario demolizione (€/m²)",

        //---------------Disassebly---------------
        disassembly: "Smontaggio",
        totalDisassemblyPrice: "Prezzo smontaggio (€)",
        totalDisassemblyPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo dello smontaggio",

        //Opaque disassembly
        opaqueDisassembly: "Smontaggio di involucro opaco",
        totalWindowsDisassemblyPrice: "Prezzo totale smontaggio finestre (€)",
        totalWindowsDisassemblyPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo dello smontaggio delle finestre",
        windowsQuantity: "Quantitá finestre (u)",
        windowsUnitPrice: "Prezzo unitario smontaggio finestre (€/u)",

        totalExternalCladdingDisassemblyPrice:
          "Prezzo totale smontaggio rivestimento esterno (€)",
        totalExternalCladdingDisassemblyPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo dello smontaggio del rivestimento esterno",
        externalCladdingQuantity: "Quantitá rivestimento esterno (m²)",
        externalCladdingUnitPrice:
          "Prezzo unitario smontaggio rivestimento esterno (€/m²)",

        totalInsulationDisassemblyPrice:
          "Prezzo totale smontaggio isolamento (€)",
        totalInsulationDisassemblyPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo dello smontaggio dell'isolamento",
        insulationQuantity: "Quantitá isolamento (m²)",
        insulationUnitPrice: "Prezzo unitario smontaggio isolamento (€/m²)",

        totalSupportingElementsAndFixingsDisassemblyPrice:
          "Prezzo totale smontaggio pannello portante e fissaggio (€)",
        totalSupportingElementsAndFixingsDisassemblyPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo dello smontaggio del pannello portante e del fissaggio",
        supportingElementsAndFixingsQuantity: "Quantitá panello portante (m²)",
        supportingElementsAndFixingsUnitPrice:
          "Prezzo unitario smontaggio panello portante (€/m²)",

        //Transparent disassembly
        transparentDisassembly: "Smontaggio di involucro trasparente",

        totalGlazedCellsDisassemblyPrice:
          "Prezzo totale smontaggio cellule vetrate  (€)",
        totalGlazedCellsDisassemblyPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo dello smontaggio delle cellule vetrate",
        glazedCellsQuantity: "Quantitá cellule vetrate (u)",
        glazedCellsUnitPrice:
          "Prezzo unitario smontaggio cellule vetrate (€/u)",

        totalDoubleGlazingDisassemblyPrice:
          "Prezzo totale smontaggio vetrocamera  (€)",
        totalDoubleGlazingDisassemblyPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo dello smontaggio della vetrocamera",
        doubleGlazingQuantity: "Quantitá vetrocamera (u)",
        doubleGlazingUnitPrice: "Prezzo unitario smontaggio vetrocamera (€/u)",

        totalUprightsCrosspiecesFixingsDisassemblyPrice:
          "Prezzo totale smontaggio montanti, traversi e fissaggi  (€)",
        totalUprightsCrosspiecesFixingsDisassemblyPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo dello smontaggio di montanti, traversi e fissaggi",
        uprightsCrosspiecesFixingsQuantity: "Quantitá montanti e traversi (m)",
        uprightsCrosspiecesFixingsUnitPrice:
          "Prezzo unitario smontaggio montanti e traversi (€/m)",

        //---------------Transport---------------
        transport: "Trasporto",
        totalTransportPrice: "Prezzo totale trasporto (€)",
        totalTransportPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo del trasporto",

        //Disposal transport
        totalDisposalTransportPrice:
          "Prezzo trasporto al sito di smaltimento/discarica (€)",
        totalDisposalTransporPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo del trasporto al sito di smaltimento/discarica",
        disposalTransportQuantity: "Ore di trasporto (h)",
        disposalTransportUnitPrice: "Prezzo orario trasporto (€/h)",

        //Recycling transport
        totalRecyclingTransportPrice:
          "Prezzo del trasporto al sito di riciclaggio/produzione",
        totalRecyclingTransporPriceIsTotal:
          "Selezionare per inserire costi per campi del prezzo del trasporto al sito di riciclaggio/produzione",
        recyclingTransportQuantity: "Ore di trasporto (h)",
        recyclingTransportUnitPrice: "Prezzo orario trasporto (€/h)",

        //---------------Recycling---------------
        recycling: "Riciclaggio",
        totalRecyclingRevenue:
          "Ricavi generati dalla pratica di riciclaggio (€)",
        totalRecyclingRevenueKpi:
          "Ricavi generati dalla pratica di riciclaggio (-€)",
        totalRecyclingRevenueIsTotal:
          "Selezionare per inserire costi per campi dei ricavi generati dal riciclaggio",

        //Windows
        totalWindowsRecyclingRevenue: "Ricavi riciclaggio finestre (€)",
        totalWindowsRecyclingRevenueIsTotal:
          "Selezionare per inserire costi per campi dei ricavi generati dal riciclaggio delle finestre",
        windowsRecyclingQuantity: "Quantitá finestre (u)",
        windowsRecyclingUnitPrice:
          "Ricavo unitario per riciclaggio finestra (€/u)",

        //External cladding
        totalExternalCladdingRecyclingRevenue:
          "Ricavi riciclaggio rivestimento esterno (€)",
        totalExternalCladdingRevenueIsTotal:
          "Selezionare per inserire costi per campi dei ricavi generati dal riciclaggio del rivestimento esterno",
        externalCladdingRecyclingQuantity:
          "Quantitá rivestimento esterno  (m²)",
        externalCladdingRecyclingUnitPrice:
          "Ricavo unitario per riciclaggio rivestimento esterno (€/m²)",

        //Insulation
        totalInsulationRecyclingRevenue: "Ricavi riciclaggio isolamento (€)",
        totalInsulationRevenueIsTotal:
          "Selezionare per inserire costi per campi dei ricavi generati dal riciclaggio dell'isolamento",
        insulationRecyclingQuantity: "Quantitá isolamento (m²)",
        insulationRecyclingUnitPrice:
          "Ricavo unitario per riciclaggio isolamento (€/m²)",

        //Supporting elements and fixings
        totalSupportingElementsAndFixingsRecyclingRevenue:
          "Ricavi riciclaggio pannello portante e fissaggio (€)",
        totalSupportingElementsAndFixingsRecyclingRevenueIsTotal:
          "Selezionare per inserire costi per campi dei ricavi generati dal riciclaggio del pannello portante e del fissaggio",
        supportingElementsAndFixingsRecyclingQuantity:
          "Quantitá pannello portante e fissaggio (m²)",
        supportingElementsAndFixingsRecyclingUnitPrice:
          "Ricavo unitario per riciclaggio pannello portante e fissaggio (€/m²)",

        //Glazed cells
        totalGlazedCellsRecyclingRevenue: "Ricavi riciclaggio cellule vetrate",
        totalGlazedCellsRecyclingRevenueIsTotal:
          "Selezionare per inserire costi per campi dei ricavi generati dal riciclaggio delle cellule vetrate",
        glazedCellsRecyclingQuantity: "Quantitá cellule vetrate (u)",
        glazedCellsUnitRecyclingPrice:
          "Ricavo unitario per riciclaggio cellule vetrate (€/u)",

        //Double glazing
        totalDoubleGlazingRecyclingRevenue: "Ricavi riciclaggio vetrocamera",
        totalDoubleGlazingRecyclingRevenueIsTotal:
          "Selezionare per inserire costi per campi dei ricavi generati dal riciclaggio della vetrocamera",
        doubleGlazingRecyclingQuantity: "Quantitá vetrocamera (u)",
        doubleGlazingRecyclingUnitPrice:
          "Ricavo unitario per riciclaggio vetrocamera (€/u)",

        //Uprights, Crosspieces and Fixings
        totalUprightsCrosspiecesFixingsRecyclingRevenue:
          "Ricavi riciclaggio montanti, traversi e fissaggi",
        totalUprightsCrosspiecesFixingsRecyclingRevenueIsTotal:
          "Selezionare per inserire costi per campi dei ricavi generati dal riciclaggio di montanti e traversi",
        uprightsCrosspiecesFixingsRecyclingQuantity:
          "Quantitá montanti e traversi (m)",
        uprightsCrosspiecesFixingsRecyclingUnitPrice:
          "Ricavo unitario per riciclaggio montanti e traversi (€/m)",

        //---------------ReUse---------------
        ReUse: "Riuso",
        totalReUseRevenue: "Ricavi generati dalla pratica di riuso (€)",
        totalReUseRevenueIsTotal:
          "Selezionare per inserire costi per campi dei ricavi generati dal riuso",

        //Windows
        totalWindowsReUseRevenue: "Ricavi riuso finestre (€)",
        totalWindowsReUseRevenueIsTotal:
          "Selezionare per inserire costi per campi dei ricavi generati dal riuso delle finestre",
        windowsReUseQuantity: "Quantitá finestre (u)",
        windowsReUseUnitPrice: "Ricavo unitario per riuso finestra (€/u)",

        //External cladding
        totalExternalCladdingReUseRevenue:
          "Ricavi riuso rivestimento esterno (€)",
        totalExternalCladdingReUseRevenueIsTotal:
          "Selezionare per inserire costi per campi dei ricavi generati dal riuso del rivestimento esterno",
        externalCladdingReUseQuantity: "Quantitá rivestimento esterno  (m²)",
        externalCladdingReUseUnitPrice:
          "Ricavo unitario per riuso rivestimento esterno (€/m²)",

        //Insulation
        totalInsulationReUseRevenue: "Ricavi riuso isolamento (€)",
        totalInsulationReUseRevenueIsTotal:
          "Selezionare per inserire costi per campi dei ricavi generati dal riuso dell'isolamento",
        insulationReUseQuantity: "Quantitá isolamento (m²)",
        insulationReUseUnitPrice: "Ricavo unitario per riuso isolamento (€/m²)",

        //Supporting elements and fixings
        totalSupportingElementsAndFixingsReUseRevenue:
          "Ricavi riuso pannello portante e fissaggio (€)",
        totalSupportingElementsAndFixingsReUseRevenueIsTotal:
          "Selezionare per inserire costi per campi dei ricavi generati dal riuso del pannello portante e del fissaggio",
        supportingElementsAndFixingsReUseQuantity:
          "Quantitá pannello portante e fissaggio (m²)",
        supportingElementsAndFixingsReUseUnitPrice:
          "Ricavo unitario per riuso pannello portante e fissaggio (€/m²)",

        //Glazed cells
        totalGlazedCellsReUseRevenue: "Ricavi riuso cellule vetrate",
        totalGlazedCellsReUseRevenueIsTotal:
          "Selezionare per inserire costi per campi dei ricavi generati dal riuso delle cellule vetrate",
        glazedCellsReUseQuantity: "Quantitá cellule vetrate (u)",
        glazedCellsUnitReUsePrice:
          "Ricavo unitario per riuso cellule vetrate (€/u)",

        //Double glazing
        totalDoubleGlazingReUseRevenue: "Ricavi riuso vetrocamera",
        totalDoubleGlazingReUseRevenueIsTotal:
          "Selezionare per inserire costi per campi dei ricavi generati dal riuso della vetrocamera",
        doubleGlazingReUseQuantity: "Quantitá vetrocamera (u)",
        doubleGlazingReUseUnitPrice:
          "Ricavo unitario per riuso vetrocamera (€/u)",

        //Uprights, Crosspieces and Fixings
        totalUprightsCrosspiecesFixingsReUseRevenue:
          "Ricavi riuso montanti, traversi e fissaggi",
        totalUprightsCrosspiecesFixingsReUseRevenueIsTotal:
          "Selezionare per inserire costi per campi dei ricavi generati dal riuso di montanti e traversi",
        uprightsCrosspiecesFixingsReUseQuantity:
          "Quantitá montanti e traversi (m)",
        uprightsCrosspiecesFixingsReUseUnitPrice:
          "Ricavo unitario per riuso montanti e traversi (€/m)",
      },
    },
  },
  navbar: {
    projects: "Progetti",
    users: "Utenti",
    profile: "Profilo",
    logout: "Logout",
  },
  tablesHeaders: {
    chooseCategory: "Scegli la categoria nella quale vuoi spostare i progetti",
    projectList: {
      id: "ID",
      name: "Nome",
      type: "Tipo progetto",
      useDestination: "Destinazione d'uso",
      constructionYear: "Anno di costruzione",
      location: "Stato / Regione / Città",
    },
    report: {
      costForPhase: "Costo per fase",
      costForPhaseBenchmark: "Differenza con benchmark - costo per fase",
      total: "Totale",
      difference: "Differenza",
      investmentPercentage: "Percentuale su investimento",
      lccPercentage: "Percentuale su investimento LCC",
      kpi: "KPI",
      kpiBenchmark: "KPI - confronto con benchmark",
      phase: "Fase",
      value: "Valore",
    },
    totalActualization: "Costi complessivi attualizzati",
    fancadePrices: "Prezzo progettazione facciata",
    KPIFancadeDesign:
      "KPIs prezzo progettazione facciata (un solo tipo di facciata)",
    generalComparison: "Dati generali",
    deltaAgainstBenchmark: "Confronto con benchmark",
    KPIAllfancades:
      "KPIs prezzo progettazione facciata (diversi tipi di facciata)",
    materials: "Materiali",
    otherMaterials: "Altri materiali",
    labor: "Manodopera",
    completeTable: "Costi per ogni anno",
  },
  tablesVoices: {
    costsActualization: {
      completeTable: "Costi per ogni anno",
      year: "Anno",
      value: "Valore",
      serviceOfLife: "Vita di servizio",
      maintenance: "Manutenzione",
      yearlyMaintenance: "Costo annuale di manutenzione (€)",
      yearlyServiceOfLife: "Costo annuale di vita di servizio (€)",
      refLife: "Vita utile",
      interestsRate: "Tasso di interesse (%)",
      total: "Totale",
      difference: "Differenza con benchmark",
      serviceOfLifeDifference: "Differenza Costo Vita di servizio attualizzato",
      maintenanceDifference: "Differenza costo della manutenzione attualizzato",
      serviceOfLifePrice: "Costo vita di servizio attualizzato (€)",
      maintenancePrice: "Costo della manutenzione attualizzato (€)",
      allFancades: "Diversi tipi di facciata",
    },
    report: {
      allFancadesTab: "Tutte le facciate",
      benchmarkTab: "Confronti con benchmark",
      priceForPhase: {
        project: "Progetto",
        fancadeProduction: "Produzione",
        recycling: "Riciclaggio",
        transportToConstructionSite: "Trasporto in cantiere",
        installation: "Posa in opera",
        serviceLife: "Vita di servizio (attualizzato)",
        maintenance: "Manutenzione (attualizzato)",
        disposal: "Dismissione",
        recyclingRevenues: "Ricavi di riciclaggio",
        totalInvestment: "Investimento totale (anno 0)",
        LCCInvestment: "Investimento totale LCC (anno 0-fine vita)",
      },
      kpi: {
        investment: "Investimento (anno 0)",
        investmentLCC: "Investimento LCC (anno 0-fine vita)",
        project: "Costi progetto",
        production: "Costi produzione",
        materialsProductionRatio: "Rapporto materiali/costo produzione",
        laborProductionRatio: "Rapporto manodopera/costo produzione",

        transport: "Costi trasporto",
        trasportPerKm: "Costi transporto per Km",

        installationOpera: "Costi posa in opera",
        installation: "Costi installazione",
        security: "Costi sicurezza e gestione in cantiere",
        installationConstructionRatio:
          "Rapporto installazione/costo costruzione",
        securityConstructionRatio: "Rapporto sicurezza/costo costruzione",
        materalsInstallationRatio: "Rapporto materiali/installazione",
        laborInstallationRatio: "Rapporto manodopera/installazione",
        toolsInstallationRatio: "Rapporto macchinari/installazione",

        tools: "Costi strumenti di lavoro",
        crane: "Rapporto gru/macchinari",
        elevatorPlatforms: "Rapporto piattaforme elevatrici/macchinari",
        aerialPlatforms: "Rapporto piattaforme aeree/macchinari",
        scaffolding: "Rapporto ponteggio/macchinari",

        buildingSiteDuration: "Durata del cantiere",
        buildingSiteDurationPerM2: "Durata del cantiere per m²",

        maintenance: "Costi manutenzione",
        ordinaryMaintenance: "Rapporto man. ordinaria/manutenzione",
        extraordinaryMaintenance: "Rapporto man. straordinaria/installazione",
        cleaning: "Rapporto pulizia/manutenzione",

        endOfLife: "Costi fine vita",
        recyclingProductionRatio:
          "Rapporto ricavi di riciclaggio/costo produzione",

        materialsTotal: "Materiali (produzione + construzione)",
        materialsCost: "Costi Costo dei materiali",

        laborTotal: "Manodopera (produzione + construzione)",
        laborCost: "Costi della manodopera",

        technicalParameters: "Parametri fisici",
        surface: "Superficie facciata",
        wwr: "Rapporto Parte Vetrata / Facciata (Window-to-Wall Ratio) ",
        uparete: "Trasmittanza termica parete",
        ufinestra: "Trasmittanza termica finestra",
        ufacciata: "Trasmittanza termica media",
      },
      fancadeKPIs: {
        totalDesignPrice: "Prezzo progettazione totale",
        fancadeSurface: "Superficie di facciata",
        kpiDesignPrice: "KPI prezzo di progettazione",
      },
      delta: {
        totalDesignCost: "Design total cost",
        designCostKPI: "Design cost KPI",
      },
      allFancades: {
        totalDesignPrice:
          "Prezzo progettazione totale - tutti i tipi di facciata",
        fancadeSurface: "Superficie di facciata - tutti i tipi di facciata",
        kpiDesignPrice:
          "KPI prezzo di progettazione - tutti i tipi di facciata",
      },
      fancadeProduction: {
        totalProductionPrice: "Prezzo produzione totale",
      },
    },
  },
  titles: {
    newProject: "Creazione nuovo progetto",
    calculatedSection: " - Campi per il calcolo",
    report: {
      projects: "Progetti",
      title: "Risultati",
      projectDetails: "Dettagli progetto",
    },
    details: {
      general: "Dati generali di progetto",
      fancadeTechnicalProject: "Dati di progetto della facciata",
      fancadeProject: "Prezzo progettazione facciata",
      fancadeProduction: "Produzione facciata",
      transport: "Trasporto",
      buildingSite: "Cantiere",
      serviceLife: "Vita di servizio",
      maintenance: "Manutenzione",
      endOfLife: "Fine vita",
      finalReport: "Risultati finali",
      all: "Tutte le sezioni",
    },
  },
  subHeadings: {
    fancadeProduction: {
      fancadeProductionPrice: "Prezzo produzione facciata",
      fancadeProductionKPIsOne:
        "KPIs prezzo produzione facciata (di questa facciata)",
      fancadeProductionKPIsAll:
        "KPIs prezzo produzione facciata (diversi tipi di facciata)",
      benchmarkDifference: "Differenza con benchmark",
    },
  },
  tabs: {
    summary: {
      report: "Risultati",
      generalPlots: "Grafici generali",
      fancadeProductionPlots: "Grafici produzione",
      constructionPlots: "Grafici costruzione",
      maintenancePlots: "Grafici manutenzione",
    },
    details: {
      general: "Dati generali",
      fancadeTechnicalProject: {
        fancadeTechnicalProject: "Dati progetto della facciata",
        firstTab: "Benchmark / Facciata #1",
        benchmark: "Benchmark / ",
        tabsAfterFirst: "Facciata #",
      },
      fancadeProject: "Prezzo progettazione facciata",
      fancadeProduction: "Produzione facciata",
      transport: "Trasporto",
      buildingSite: "Cantiere",
      serviceLife: "Vita di servizio",
      maintenance: "Manutenzione",
      costsActualization: "Attualizzazione dei costi",
      endOfLife: "Fine vita",
    },
  },
  plotTitles: {
    investmentPerPhase: "Costo investimento suddiviso per fase",
    unitInvestmentPerPhase: "Costo investimento unitario per fase",
    lccInvestmentPerPhase: "Costo investimento attualizzato per fasi - LCC",
    lccInvestmentPerPhaseBenchmarkPlotTitle:
      "Costo investimento attualizzato per fasi - LCC - Differenza con benchmark",
    production: "Scomposizione costi produzione facciata",
    buildingSiteScomposition:
      "Scomposizione costi di cantierizzazione della facciata",
    installationScomposition:
      "Scomposizione costi installazione della facciata",
    toolsScomposition: "Scomposizione costi macchinari",
    maintenance: "Scomposizione costi manutenzione della facciata",
    materials: "Materiali",
    labor: "Manodopera",
    installation: "Installazione facciata",
    security: "Gestione della sicurezza in cantiere",
    tools: "Macchinari",
    crane: "Gru",
    elevatorPlatforms: "Piattaforme elevatrici",
    aerialPlatforms: "Piattaforme aeree",
    scaffolding: "Ponteggio",
    extraordinaryMaintenance: "Manutenzione straordinaria",
    cleaning: "Pulizia",
    ordinaryMaintenance: "Manutenzione ordinaria",
  },
  users: {
    id: "ID",
    nominative: "Nominativo",
    email: "Email",
    role: "Ruolo",
    password: "Password",
    manage: "Gestisci",
    delete: "Elimina utente",
    disable: "Disabilita utente",
    enable: "Abilita utente",
    credentialsText:
      "Annotare le seguenti credenziali, non sarà possibile visualizzare nuovamente la password nel futuro.",
  },
  bimp: {
    importBIMP: "Importa da BIM-P",
    exportBIMP: "Esporta in BIM-P",
    exportSuccess: "PDF esportato",
    exportError: "Errore in esportazione PDF"
  }
};

export default it;
