import { Stack } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  calculateMaterialsTotal,
  calculateProductionTotal,
} from "../../../../../../services/utils";
import theme from "../../../../../../theme/theme";
import { Fancade } from "../../../../../../types";
import { Container } from "../../../../../Container";
import { FormikCheckbox } from "../../../../../FormikCheckbox";
import { FormRow } from "../../../../../FormRow";
import { LaborForm } from "./LaborForm";
import { OpaqueWindowsForm } from "./OpaqueWindowsForm";
import { ScrapForm } from "./ScrapForm";
import { SystemsIntegrationForm } from "./SystemsIntegrationForm";
import { TransparentWindowsForm } from "./TransparentWindowsForm";

type Props = {
  currentFancade: Fancade;
  fancadeNumber: number;
  formikProps: any;
};
export const FancadeProductionForm: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const [update, setUpdate] = useState(false);
  return (
    <Stack w={"100%"}>
      <FormikCheckbox
        checked={
          !(
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeProduction?.totalProductionPriceCalculation &&
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeProduction?.totalProductionPriceCalculation == 1
          )
        }
        defaultChecked={
          !(
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeProduction?.totalProductionPriceCalculation &&
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeProduction?.totalProductionPriceCalculation == 1
          )
        }
        onChange={() => {
          if (
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeProduction
          ) {
            if (
              !props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeProduction.totalProductionPriceCalculation ||
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeProduction.totalProductionPriceCalculation == 0
            )
              props.formikProps.values.fancades[
                props.fancadeNumber - 1
              ].fancadeProduction.totalProductionPriceCalculation = 1;
            else
              props.formikProps.values.fancades[
                props.fancadeNumber - 1
              ].fancadeProduction.totalProductionPriceCalculation = 0;
            setUpdate(!update);
          }
        }}
      >
        {t(`formLabels.details.fancadeProduction.totalCheckbox`)}
      </FormikCheckbox>
      {!(
        props.formikProps.values.fancades[props.fancadeNumber - 1]
          .fancadeProduction.totalProductionPriceCalculation &&
        props.formikProps.values.fancades[props.fancadeNumber - 1]
          .fancadeProduction.totalProductionPriceCalculation == 1
      ) ? (
        <>
          <FormRow
            fields={[
              {
                name: `fancades[${
                  props.fancadeNumber - 1
                }].fancadeProduction.totalProductionPrice`,
                label: t(
                  `formLabels.details.fancadeProduction.totalProductionPrice`
                ),
                placeholder: t(
                  `formPlaceholders.details.fancadeProduction.totalProductionPrice`
                ),
                type: "number",
                width: 100,
              },
            ]}
          />
        </>
      ) : (
        <Container
          title={
            t(`formLabels.details.fancadeProduction.totalProductionPrice`) +
            t(`titles.calculatedSection`)
          }
          bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
          direction={"column"}
          spacing={"1rem"}
        >
          <FormRow
            fields={[
              {
                name: `fancades[${
                  props.fancadeNumber - 1
                }].fancadeProduction.totalProductionPrice`,
                label: t(
                  `formLabels.details.fancadeProduction.totalProductionPrice`
                ),
                placeholder: t(
                  `formPlaceholders.details.fancadeProduction.totalProductionPrice`
                ),
                type: "number",
                width: 100,
                value: calculateProductionTotal(
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                ),
                disabled: true,
              },
            ]}
          />

          <Container title={t(`formLabels.details.fancadeProduction.materials`)}>
            <Stack w={"100%"}>
              <FormikCheckbox
                checked={
                  !(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeProduction.totalMaterialsPriceCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeProduction.totalMaterialsPriceCalculation == 1
                  )
                }
                defaultChecked={
                  !(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeProduction.totalMaterialsPriceCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeProduction.totalMaterialsPriceCalculation == 1
                  )
                }
                onChange={() => {
                  if (
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeProduction
                  ) {
                    if (
                      !props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeProduction.totalMaterialsPriceCalculation ||
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .fancadeProduction.totalMaterialsPriceCalculation == 0
                    )
                      props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeProduction.totalMaterialsPriceCalculation = 1;
                    else
                      props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeProduction.totalMaterialsPriceCalculation = 0;
                    setUpdate(!update);
                  }
                }}
              >
                {t(
                  `formLabels.details.fancadeProduction.totalMaterialsCheckbox`
                )}
              </FormikCheckbox>
              {!(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeProduction.totalMaterialsPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeProduction.totalMaterialsPriceCalculation == 1
              ) ? (
                <Stack w={"100%"}>
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeProduction.totalMaterialsPrice`,
                        label: t(
                          `formLabels.details.fancadeProduction.totalMaterialsPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.totalMaterialsPrice`
                        ),
                        type: "number",
                        width: 100,
                      },
                    ]}
                  />
                </Stack>
              ) : props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject?.type &&
                props.formikProps.values.fancades[
                  props.fancadeNumber - 1
                ].fancadeTechnicalProject?.type
                  ?.toLowerCase()
                  ?.indexOf("opaca") != -1 ? (
                <Container
                  title={
                    t(
                      `formLabels.details.fancadeProduction.totalMaterialsPrice`
                    ) + t(`titles.calculatedSection`)
                  }
                  bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                  direction={"column"}
                  spacing={"1rem"}
                >
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeProduction.totalMaterialsPrice`,
                        label: t(
                          `formLabels.details.fancadeProduction.totalMaterialsPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.totalMaterialsPrice`
                        ),
                        type: "number",
                        width: 100,
                        value: calculateMaterialsTotal(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ]
                        ),
                        disabled: true,
                      },
                    ]}
                  />

                  <OpaqueWindowsForm
                    fancadeNumber={props.fancadeNumber}
                    formikProps={props.formikProps}
                  />
                  <SystemsIntegrationForm
                    fancadeNumber={props.fancadeNumber}
                    formikProps={props.formikProps}
                  />
                  <ScrapForm
                    fancadeNumber={props.fancadeNumber}
                    formikProps={props.formikProps}
                  />
                </Container>
              ) : (
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject?.type &&
                props.formikProps.values.fancades[
                  props.fancadeNumber - 1
                ].fancadeTechnicalProject?.type
                  ?.toLowerCase()
                  ?.indexOf("trasparente") != -1 && (
                  <Container
                    title={
                      t(
                        `formLabels.details.fancadeProduction.totalMaterialsPrice`
                      ) + t(`titles.calculatedSection`)
                    }
                    bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                    direction={"column"}
                    spacing={"1rem"}
                  >
                    {/* Trasparente */}
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].fancadeProduction.totalMaterialsPrice`,
                          label: t(
                            `formLabels.details.fancadeProduction.totalMaterialsPrice`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.fancadeProduction.totalMaterialsPrice`
                          ),
                          type: "number",
                          width: 100,
                          value: calculateMaterialsTotal(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ]
                          ),
                          disabled: true,
                        },
                      ]}
                    />
                    <TransparentWindowsForm
                      fancadeNumber={props.fancadeNumber}
                      formikProps={props.formikProps}
                    />
                    <SystemsIntegrationForm
                      fancadeNumber={props.fancadeNumber}
                      formikProps={props.formikProps}
                    />
                    <ScrapForm
                      fancadeNumber={props.fancadeNumber}
                      formikProps={props.formikProps}
                    />
                  </Container>
                )
              )}
            </Stack>
          </Container>
          <Container title={t(`formLabels.details.fancadeProduction.labor`)}>
            <LaborForm
              fancadeNumber={props.fancadeNumber}
              formikProps={props.formikProps}
            />
          </Container>
        </Container>
      )}
    </Stack>
  );
};
