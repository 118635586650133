import { Text } from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/modal";
import {
  Stack,
  FormControl,
  Input,
  FormErrorMessage,
  Select,
} from "@chakra-ui/react";
import {
  Form,
  Field,
  FieldProps,
  useFormik,
  Formik,
  FormikProps,
} from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { getFancadeCopyOptions } from "../../../../services/selectsOptions";
import { Project } from "../../../../types";
import { PrimaryButton } from "../../../Button";
import { FormRow } from "../../../FormRow";

type Props = {
  isVisible: boolean;
  formikProps: FormikProps<Project>;
  onSubmit: (
    formikProps: FormikProps<Project>,
    data:
      | "all"
      | "fancadeTechnicalProject"
      | "fancadeProject"
      | "fancadeProduction"
      | "transport"
      | "buildingSite"
      | "serviceLife"
      | "maintenance"
      | "endOfLife"
  ) => void;
  onDismiss: () => void;
};

export const CopyFancadeModal: React.FC<Props> = ({
  formikProps,
  isVisible,
  onSubmit,
  onDismiss,
}) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isVisible} size={"xl"} onClose={onDismiss}>
      <ModalOverlay />
      <ModalContent maxW={"820px"}>
        <ModalCloseButton />
        <ModalBody style={{ padding: "2rem" }}>
          <Formik
            initialValues={{ section: "all" }}
            onSubmit={(data: any) => {
              onSubmit(formikProps, data.section);
            }}
          >
            {(props) => (
              <Form>
                <Stack spacing={"1rem"}>
                  <Text>{t("selectOptions.copySection")}</Text>
                  <FormRow
                    fields={[
                      {
                        name: "section",
                        type: "select",
                        width: 100,
                        selectOptions: getFancadeCopyOptions(),
                      },
                    ]}
                  />
                  <PrimaryButton
                    type={"submit"}
                    style={{ marginTop: "3em" }}
                    rounded={"500rem"}
                  >
                    {t("buttons.importData")}
                  </PrimaryButton>
                </Stack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const StyledLinkText = styled(Text)`
  cursor: pointer;
  color: var(--chakra-colors-teal-600);
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0.5rem;
`;
