import React from "react";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Select,
  Stack,
  Tab,
  Text,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../hooks/useStores";
import { Redirect, useParams } from "react-router-dom";

import { Field, FieldProps } from "formik";

import styled from "styled-components";
import { transparentize } from "polished";
import theme from "../theme/theme";
import { AiFillFolder } from "react-icons/ai";

interface RouteParams {
  projectId: string;
}

export const GeneralData: React.FC = observer((props) => {
  const { t } = useTranslation();
  const { session, ui, projects } = useStores();
  const { projectId } = useParams<RouteParams>();

  ui.setActiveScreen("projects");

  React.useEffect(() => {
    ui.setProjectModified(false);
  }, [ui]);

  if (session.user)
    return (
      <Stack>
        <Text fontSize={"lg"}>{t("formLabels.details.general.name")}</Text>
        <Field name={"name"}>
          {({ field, form: { setFieldValue }, meta }: FieldProps) => (
            <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
              <Input
                {...field}
                type={"text"}
                name={field.name}
                value={field.value}
                placeholder={t("formPlaceholders.details.general.name")}
                onChange={(event: any) => {
                  ui.setProjectModified(true);
                  setFieldValue(field.name, event.target.value);
                }}
              />
              <FormErrorMessage name={"name"}>{meta.error}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Text fontSize={"lg"}>{t("formLabels.details.general.location")}</Text>
        <Field name={"location"}>
          {({ field, form: { setFieldValue }, meta }: FieldProps) => (
            <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
              <Input
                {...field}
                type={"text"}
                name={field.name}
                value={field.value}
                placeholder={t("formPlaceholders.details.general.location")}
                onChange={(event: any) => {
                  ui.setProjectModified(true);
                  setFieldValue(field.name, event.target.value);
                }}
              />
              <FormErrorMessage name={"location"}>
                {meta.error}
              </FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Text fontSize={"lg"}>
          {t("formLabels.details.general.reportAuthor")}
        </Text>
        <Field name={"reportAuthor"}>
          {({ field, form: { setFieldValue }, meta }: FieldProps) => (
            <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
              <Input
                {...field}
                type={"text"}
                name={field.name}
                value={field.value}
                placeholder={t("formPlaceholders.details.general.reportAuthor")}
                onChange={(event: any) => {
                  ui.setProjectModified(true);
                  setFieldValue(field.name, event.target.value);
                }}
              />
              <FormErrorMessage name={"reportAuthor"}>
                {meta.error}
              </FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Text fontSize={"lg"}>
          {t("formLabels.details.general.description")}
        </Text>
        <Field name={"description"}>
          {({ field, form: { setFieldValue }, meta }: FieldProps) => (
            <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
              <Input
                {...field}
                type={"text"}
                name={field.name}
                value={field.value}
                placeholder={t("formPlaceholders.details.general.description")}
                onChange={(event: any) => {
                  ui.setProjectModified(true);
                  setFieldValue(field.name, event.target.value);
                }}
              />
              <FormErrorMessage name={"description"}>
                {meta.error}
              </FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Text fontSize={"lg"}>{t("formLabels.details.general.type")}</Text>
        <Field name={"type"}>
          {({ field, form: { setFieldValue }, meta }: FieldProps) => (
            <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
              <Input
                {...field}
                type={"text"}
                name={field.name}
                value={field.value}
                placeholder={t("formPlaceholders.details.general.type")}
                onChange={(event: any) => {
                  ui.setProjectModified(true);
                  setFieldValue(field.name, event.target.value);
                }}
              />
              <FormErrorMessage name={"type"}>{meta.error}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Text fontSize={"lg"}>
          {t("formLabels.details.general.certification")}
        </Text>
        <Field name={"certification"}>
          {({ field, form: { setFieldValue }, meta }: FieldProps) => (
            <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
              <Input
                {...field}
                type={"text"}
                name={field.name}
                value={field.value}
                placeholder={t(
                  "formPlaceholders.details.general.certification"
                )}
                onChange={(event: any) => {
                  ui.setProjectModified(true);
                  setFieldValue(field.name, event.target.value);
                }}
              />
              <FormErrorMessage name={"certification"}>
                {meta.error}
              </FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Text fontSize={"lg"}>
          {t("formLabels.details.general.useDestination")}
        </Text>
        <Field name={"useDestination"}>
          {({ field, form: { setFieldValue }, meta }: FieldProps) => (
            <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
              <Input
                {...field}
                type={"text"}
                name={field.name}
                value={field.value}
                placeholder={t(
                  "formPlaceholders.details.general.useDestination"
                )}
                onChange={(event: any) => {
                  ui.setProjectModified(true);
                  setFieldValue(field.name, event.target.value);
                }}
              />
              <FormErrorMessage name={"useDestination"}>
                {meta.error}
              </FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Text fontSize={"lg"}>
          {t("formLabels.details.general.constructionYear")}
        </Text>
        <Field name={"constructionYear"}>
          {({ field, form: { setFieldValue }, meta }: FieldProps) => (
            <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
              <Input
                {...field}
                type={"number"}
                name={field.name}
                value={field.value}
                placeholder={t(
                  "formPlaceholders.details.general.constructionYear"
                )}
                onChange={(event: any) => {
                  ui.setProjectModified(true);
                  setFieldValue(field.name, event.target.value);
                }}
              />
              <FormErrorMessage name={"constructionYear"}>
                {meta.error}
              </FormErrorMessage>
            </FormControl>
          )}
        </Field>

        <Text fontSize={"lg"}>
          {t("formLabels.details.general.isBenchmark")}
        </Text>
        <Field name={"isBenchmark"}>
          {({ field, form: { setFieldValue }, meta }: FieldProps) => (
            <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
              <Select
                {...field}
                name={field.name}
                value={field.value ? 1 : 0}
                onChange={(event: any) => {
                  ui.setProjectModified(true);
                  setFieldValue(
                    field.name,
                    event.target.value == 0 ? false : true
                  );
                }}
              >
                <option value={0}>
                  {t("formLabels.details.general.notBenchmarkOption")}
                </option>
                <option value={1}>
                  {t("formLabels.details.general.benchmarkOption")}
                </option>
              </Select>
              <FormErrorMessage name={"isBenchmark"}>
                {meta.error}
              </FormErrorMessage>
            </FormControl>
          )}
        </Field>
      </Stack>
    );
  else return <Redirect to={"/"} />;
});
