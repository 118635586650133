import { usePDF } from "@react-pdf/renderer";
import { update } from "plotly.js";

type Props = {
  document: any;
  pdfName: string;
  loadingText?: string;
};

export const PDFDownloadLink: React.FC<Props> = (props) => {
  const [instance, updateInstance] = usePDF({ document: props.document });
  if (instance.loading) {
    return <a>{props.loadingText ? props.loadingText : "Loading..."}</a>;
  }
  return (
    <a
      href={instance.url ? instance.url : undefined}
      download={props.pdfName}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {props.children}
    </a>
  );
};
