const en = {
  loading: "Loading...",
  currency: "€",
  yes: "Yes",
  no: "No",
  pdfName: "report",
  plots: "Charts",
  attribute: "Attribute",
  fancade: "Facade",
  selectFancade: "Select the façade to compare - project #",
  windows: "Windows",
  systemsIntegration: "Plant Integration",
  customSystemsIntegrations: "Customised plant integrations",
  customSystemsIntegration: "Customised plant integration",
  totalPrice: "Total price (€)",
  units: "Number of units (u)",
  unitPrice: "Unit price (€/u)",
  manualInput: "Select to enter costs per field",
  scrap: "Scrap",
  role: {
    user: "User",
    admin: "Administrator"
  },
  selectOptions: {
    transparentWithoutSpandrel: "Transparent - cellular without opaque spandrel panel",
    transparentWithSpandrel: "Transparent - cellular with opaque spandrel panel",
    transparentUprights: "Transparent - mullions and transoms",
    opaqueWoodWithVentilation: "Opaque - wood with ventilated façade",
    opaqueWoodWithoutVentilation: "Opaque - wood without ventilated façade",
    opaqueConcreteWithVentilation: "Opaque - concrete with ventilated façade",
    opaqueConcreteWithoutVentilation: "Opaque - concrete without ventilated façade",
    opaqueWallingWithVentilation: "Opaque - masonry with ventilated façade",
    opaqueWallingWithoutVentilation: "Opaque - masonry without ventilated façade",
    opaqueWindows: "Opaque - windows",
    opaqueModularRetrofit: "Opaque - modular retrofit",
    windows: "Windows",
    adminOnlyProjects: "Show only your projects",
    allProjects: "View your projects and those of other users",
    usersOnlyProjects: "View only other users' projects",
    copyFancade: "Import data from the facade...",
    copyFancadeOption: "Import data from facade #",
    copySection: "Select which section to import data from"
  },
  warnings: {
    cancel: "Cancel",
    confirm: "Continued",
    deleteProjectTitle: "Project deletion",
    deleteProject: "This action is not reversible, do you want to continue with the deletion of the project?",
    deleteProjectsList: "The following projects will be eliminated:",
    deleteFancadeTitle: "Facade elimination",
    deleteFancade: "This action is not reversible, do you want to continue with the elimination of the facade?",
    deleteUserTitle: "User deletion",
    deleteUser: "This action is not reversible, do you want to continue with the deletion of the user?",
    deleteCategoryTitle: "Category deletion",
    deleteCategory: "This action is not reversible, do you want to continue with the elimination of the category?",
    calculationChange: "Clicking on this switch will change the calculation mode",
    isBenchmarkChange: "Facade #1 is considered as a benchmark",
    chooseFancadeName: "Enter a customised name for the selected facade",
    resetFancadeName: "Leave the field blank to restore the name of the façade",
    surfaceTooHigh: "The inserted surface area is greater than the surface area of the façade",
    note: "Click to view or edit the note for this field"
  },
  errors: {
    generic: "Error",
    noFancades: "The selected projects do not have fronts",
    emptyTable: "This table is empty",
    missingCategory: "Enter a name for the category",
    updates: {
      project: "There was a problem with saving the project"
    },
    login: {
      missingEmail: "Enter e-mail address",
      missingPassword: "Enter password",
      invalidEmail: "Please enter a valid e-mail address",
      wrongEmail: "The e-mail address entered does not belong to a registered user",
      failedTitle: "Login failed",
      failedDescription: "The credentials entered are incorrect or belong to a disabled user"
    },
    signUp: {
      missingEmail: "Enter e-mail address",
      missingPassword: "Enter password",
      invalidEmail: "Please enter a valid e-mail address",
      shortPassword: "The password must consist of at least 8 characters",
      alreadyExists: "The e-mail address entered corresponds to an existing user"
    },
    newProject: {
      missingName: "Enter name",
      missingProjectType: "Enter the type of project",
      missingUseDestination: "Enter the intended use",
      missingConstructionYear: "Enter year of construction",
      missingLocation: "Enter the location",
      missingAuthor: "Insert author",
      missingDescription: "Enter a description",
      missingEnergyCertification: "Insert certification",
      projectCreation: "There was an error when creating the project"
    },
    noValuesPlot: "Not all the values needed to calculate this graph are present"
  },
  successes: {
    updates: {
      projectSuccessful: "Updated project",
      categorySuccessful: "Updated category",
      userSuccessful: "Updated user",
      userDisabled: "User disabled",
      userEnabled: "User enabled"
    },
    creates: {
      projectSuccessful: "Project created",
      userSuccessful: "User created",
      categorySuccessful: "Category created"
    },
    deletes: {
      projectSuccessful: "Project deleted",
      projectsSuccessful: "Eliminated projects",
      userSuccessful: "User deleted",
      categorySuccessful: "Category eliminated"
    }
  },
  buttons: {
    actions: "Actions",
    login: "Login",
    export: "Export",
    pdf: "PDF",
    save: "Save",
    goBack: "Back to top",
    addCategory: "Create category",
    changeCategory: "Move selected projects into the category...",
    editCategory: "Change category",
    deleteCategory: "Delete category",
    addWindow: "Add window type",
    close: "Close",
    cancel: "Cancel",
    createProject: "Create project",
    compare: "Compare selected projects",
    delete: "Delete",
    deleteProject: "Delete project",
    deleteProjects: "Delete selected projects",
    new: "New",
    addDetail: "Add Module",
    addSystemsIntegration: "Add plant integration",
    addHardware: "Add hardware and accessories",
    addPackaging: "Add packaging type",
    addAdditionalMaterial: "Add additional material",
    addTax: "Add Taxes and Fees",
    addAction: "Add action",
    addNewField: "Add field",
    addNewCustomField: "Add a Custom Field",
    addUser: "Create user",
    updateFancadeName: "Update façade name",
    importData: "Import data",
    duplicateProject: "Duplicate selected project",
    saveNote: "Add note",
    resetNote: "Cancel changes"
  },
  formLabels: {
    email: "Email",
    password: "Password",
    isBenchmark: "Difference with benchmark",
    category: "Category name",
    board: {
      project: "Role of the user"
    },
    details: {
      general: {
        id: "Project Id",
        name: "Project name",
        location: "Place",
        reportAuthor: "Author of the analysis",
        description: "Description",
        type: "Type of project",
        certification: "Building energy performance",
        useDestination: "Intended use / building type",
        constructionYear: "Year of construction",
        isBenchmark: "Type of analysis",
        benchmarkOption: "Comparative (the created façades will be compared with the benchmark created by the user)",
        notBenchmarkOption: "Absolute (all facades created make up the building envelope)"
      },
      fancadeProject: {
        type: "Facade type analysed",
        types: {
          transparent1: "Transparent - cellular without opaque spandrel panel",
          transparent2: "Transparent - cellular with opaque spandrel panel",
          transparent3: "Transparent - mullions and transoms",
          opaque1: "Opaque - wood with ventilated façade",
          opaque2: "Opaque - wood without ventilated façade",
          opaque3: "Opaque - concrete with ventilated façade",
          opaque4: "Opaque - concrete without ventilated façade",
          opaque5: "Opaque - masonry with ventilated façade",
          opaque6: "Opaque - masonry without ventilated façade",
          windows: "Windows"
        },
        customField: "Customised fields",
        customFieldModalTitle: "Adding a new field",
        fieldName: "Field Name",
        fieldValue: "Field Value",
        surface: "Façade surface (m²)",
        transparentTotalPrice: "Total price windows (transparent) (€)",
        windowsTotalPrice: "Total price windows (opaque) (€)",
        opaqueShell: "Enclosure",
        externalCladdingUnitsNumber: "Number of outer cladding panels",
        externalCladdingUnitsSurface: "Unit area of external cladding panels (m²/u)",
        totalExternalCladdingSurface: "Total surface area outer cladding",
        insulationSurface: "Insulation surface",
        supportingElementsQuantity: "Linear metres of substructure",
        prefabricatedModulesQuantity: "Quantity of prefabricated modules",
        modulesSurface: "Total module surface (m²)",
        opaqueAnchoringsNumber: "Number of point anchors",
        opaqueFixingsNumber: "Number of fixings (facade components)",
        glazedCellsNumber: "Number of glazed cells",
        glazedCellsSurface: "Glazed cell unit surface",
        doubleGlazingQuantity: "Quantity of double glazing",
        doubleGlazingSurface: "Insulated glass surface",
        mainFrameHeightPerUnit: "Main frame height per glazed unit",
        mainFrameWidthPerUnit: "Main frame width per glazed unit",
        totalMainFrameLength: "Total length main frame",
        totalGasketLength: "Total length of seals",
        opaqueSpandrelPanelsQuantity: "Quantity of opaque spandrel panels",
        opaqueSpandrelPanelsSurface: "Matt spandrel panel surface",
        transparentAnchoringsNumber: "Number of point anchors",
        transparentFixingsNumber: "Number of fixings (facade components)",
        transparentShell: "Transparent casing",
        plantIntegration: "Facade/building plant integration",
        technicalParameters: "Physical parameters of the façade",
        windowOpaqueSurfaceRatio: "Glazed surface to matt surface ratio",
        opaqueUValue: "U-value (opaque part)",
        transparentUValue: "U-value (transparent part)",
        windowGlassGValue: "g-value (window glass)",
        fancadeGlassGValue: "g-value (transparent curtain wall glass)",
        prefabricationLevel: "Level of prefabrication",
        geometricDetails: {
          module: "Module",
          width: "Width (cm)",
          length: "Length (cm)",
          depth: "Thickness (cm)"
        },
        systemsIntegration: {
          commonName: "Other plant integrations",
          name: "Name",
          units: "Number of units (u)",
          unitSurface: "Unit surface area (m²/u)",
          totalSurface: "Total surface area (m²)",
          type: "Type of plant integration",
          surfaceRatio: "Ratio of plant area to façade area (%)"
        },
        systemsSurfacePercentage: "Percentage of surface area occupied by installations on the total façade (total for all installations)",
        windowType: {
          windowType: "Window type",
          windowsNumber: "Quantity of windows",
          windowsUnitSurface: "Unit window area (m²)",
          windowsSurface: "Window surface (m²)",
          doubleGlazingNumber: "Quantity of double glazing",
          doubleGlazingSurface: "Insulated glass surface",
          loomHeight: "Frame height",
          loomWidth: "Frame width",
          totalLoomLength: "Total frame length",
          totalGasketLength: "Total length of seals"
        },
        shieldingSystemsNumber: "Number of shielding systems (u)",
        ventilationDevicesNumber: "Number of ventilation units (u)",
        airConditioningDevicesNumber: "Number of air conditioning devices (u)",
        thermicSolarPanelsNumber: "Number of solar thermal panels (u)",
        thermicSolarPanelsSurface: "Thermal solar panel surface area (m²)",
        thermicSolarPanelsUnitSurface: "Thermal solar panel surface area (m²/u)",
        photovoltaicModulesNumber: "Number of photovoltaic modules (u)",
        photovoltaicModulesSurface: "Photovoltaic module surface (m²)",
        photovoltaicModulesUnitSurface: "Photovoltaic module unit surface area (m²/u)"
      },
      fancadePrice: {
        totalCheckbox: "Select to enter costs per total price field",
        totalDesignPrice: "Total design price",
        technicalProject: "Technical project",
        structuralSismicProject: "Structural and seismic design",
        lightingTechnicalProject: "Lighting design",
        ventilationProject: "Ventilation project",
        acusticProject: "Acoustic project",
        airQualityCheck: "Air quality testing",
        constructionPrinceAnalysis: "Construction price analysis",
        firePreventionDesign: "Fire design",
        buildingSiteManagement: "Site management - project",
        buildingSiteSafety: "Construction site safety - project",
        energeticPerformanceAnalysis: "Energy performance assessment and certification",
        competitionProject: "Project for tender basis",
        maintenancePlan: "Maintenance Plan",
        userManual: "User's Manual"
      },
      fancadeProduction: {
        totalCheckbox: "Select to enter costs per total price field",
        materials: "Materials",
        totalMaterialsCheckbox: "Select to enter costs for total material price fields",
        opaqueShieldingMaterials: "Opaque casing",
        transparentShieldingMaterials: "Transparent casing",
        plantIntegration: "Plant integration",
        scrap: "Scrap",
        totalProductionPrice: "Total production price (€)",
        totalMaterialsPrice: "Total materials price (€)",
        fancadeSurface: "Façade surface (m²)",
        windowsTitle: "Window price per type",
        windowByTotal: "Select to enter costs per field of the total price of the facade windows",
        checkboxTotalWindowPrice: "Select to enter costs for window type price fields",
        transparentTotalPrice: "Total price transparent casing",
        windowsTotalPrice: "Total price windows (€)",
        windowContainer: "Window type",
        totalWindowPrice: "Total price window type (€)",
        windowSelect: "Select calculation mode",
        unityOption: "Calculate per unit",
        componentsOption: "Calculation by components",
        windowsNumber: "Quantity of windows (u)",
        unitPrice: "Window price per sqm (€/m²)",
        loomByTotal: "Select to enter costs per frame price fields",
        loomComponentsPrice: "Price of window components - frame (€)",
        loomUnitPrice: "Price of window components - frame (€)",
        totalLoomLength: "Total frame length (m)",
        doubleGlazingComponentsPrice: "Price of window components - double glazing (€)",
        doubleGlazingByTotal: "Select to enter costs per pane price field",
        prefabricatedModulesUnitPrice: "Module price per sqm (€/m²)",
        prefabricatedModulesQuantity: "Quantity of prefabricated modules (u)",
        doubleGlazingNumber: "Quantity of double glazing (u)",
        doubleGlazingUnitPrice: "Unit price double glazing (€/u)",
        gasketByTotal: "Select to enter costs for gasket price fields",
        gasketComponentsPrice: "Price of window components - seals (€)",
        gasketsUnitPrice: "Price of window components - seals (€)",
        totalGasketLength: "Total seal length (m)",
        externalCladdingByTotal: "Select to enter costs per price field of the outer cladding",
        externalCladdingTotalPrice: "Price opaque casing - outer cladding (€)",
        externalCladdingUnitsSurface: "Unit area of external cladding panels (m²/u)",
        totalExternalCladdingSurface: "Total surface area exterior cladding (m²)",
        externalCladdingUnitPrice: "Unit price exterior cladding (€/m²)",
        insulationByTotal: "Select to enter costs for insulation price fields",
        insulationTotalPrice: "Price opaque envelope - insulation (€)",
        insulationSurface: "Total insulation surface (m²)",
        insulationUnitPrice: "Unit price insulation (€/m²)",
        supportingElementsByTotal: "Select to enter costs for carrier price fields",
        supportingElementsTotalPrice: "Price opaque casing - load-bearing elements (€)",
        totalSupportingElementsQuantity: "Linear metres of substructure (m)",
        supportingElementsUnitPrice: "Unit price load-bearing element (€/m)",
        prefabricatedModulesByTotal: "Select to enter costs per prefabricated module price fields",
        prefabricatedModulesTotalPrice: "Price opaque casing - prefabricated modules (€)",
        prefabricatedModulesElementsUnitPrice: "Unit price prefabricated module (€/m²)",
        opaqueAnchoringByTotal: "Select to enter costs for anchoring system price fields",
        opaqueAnchoringTotalPrice: "Price opaque casing - anchoring system (€)",
        opaqueAnchoringsNumber: "Number of point anchors (u)",
        opaqueAnchoringUnitPrice: "Anchor unit price (€/u)",
        opaqueFixingsByTotal: "Select to enter costs per fixture price field",
        opaqueFixingsTotalPrice: "Price opaque casing - fixing system (€)",
        opaqueFixingsNumber: "Number of fixings (facade components) (u)",
        opaqueFixingsUnitPrice: "Fixing unit price (€/u)",
        hardware: "Hardware and accessories",
        hardwareCheckbox: "Select to enter costs per price field for hardware and accessories",
        singleHardwareTotalPrice: "Hardware and accessories price (€)",
        hardwareTotalPrice: "Price opaque casing - hardware and accessories (€)",
        hardwareUnitPrice: "Hardware and accessories unit price (€/u)",
        hardwareQuantity: "Quantity of hardware and accessories (u)",
        glazedCellsByTotal: "Select to enter costs for glazed cell price fields",
        totalGlazedCellsPrice: "Transparent enclosure - glazed cell price (€)",
        glazedCellsUnitPrice: "Unit price glazed cell (€/u)",
        glazedCellsNumber: "Transparent casing - number of glazed cells (u)",
        totalDoubleGlazingPrice: "Price transparent casing - double glazing (€)",
        doubleGlazingQuantity: "Transparent casing - quantity of double glazing (u)",
        totalMainFramePrice: "Price transparent main frame (€)",
        mainFrameUnitPrice: "Unit price main frame (€/u)",
        totalMainFrameLength: "Total length main frame (m)",
        totalGasketPrice: "Price transparent casing - seals (€)",
        gasketUnitPrice: "Unit price seals (€/m)",
        opaqueSpandrelByTotal: "Select to enter costs per price field for opaque spandrel panels",
        totalOpaqueSpandrelModulesPrice: "Price transparent casing - matt spandrel panel (€)",
        opaqueSpandrelModulesUnitPrice: "Unit price matt spandrel panel (€/u)",
        opaqueSpandrelPanelsQuantity: "Transparent casing - quantity of opaque spandrel panels (u)",
        transparentAnchoringByTotal: "Select to enter costs for anchoring system price fields",
        totalTransparentAnchoringsPrice: "Price transparent enclosure - anchoring system (€)",
        transparentAnchoringsUnitPrice: "Anchor unit price (€/u)",
        transparentAnchoringsNumber: "Transparent casing - Number of point anchors (u)",
        fixingsByTotal: "Select to enter costs per fixture price field",
        totalTransparentFixingsNumberPrice: "Transparent casing - fastening system (€)",
        transparentFixingsNumberUnitPrice: "Fixing unit price (€/u)",
        transparentFixingsNumber: "Transparent casing - Number of fixings (façade components) (u)",
        systemsIntegrationByTotal: "Select to enter costs per plant integration price field",
        totalSystemsIntegrationPrice: "Plant integration price (€)",
        totalSystemIntegrationPrice: "Plant integration price (€)",
        shieldingByTotal: "Select to enter costs per shielding system price field",
        shieldingsUnitPrice: "Unit price shielding systems (€/u)",
        shieldingSystemsNumber: "Number of shielding systems (u)",
        totalShieldingsPrice: "Shielding system price (€)",
        ventilationByTotal: "Select to enter costs for ventilation system price fields",
        ventilationSystemsUnitPrice: "Unit price ventilation equipment (€/u)",
        ventilationDevicesNumber: "Number of ventilation units (u)",
        totalVentilationSystemsPrice: "Ventilation system price (€)",
        airConditioningByTotal: "Select to enter costs for air conditioning system price fields",
        airConditioningSystemsUnitPrice: "Unit price air conditioning systems (€/u)",
        airConditioningDevicesNumber: "Number of air conditioning devices (u)",
        totalAirConditioningSystemsPrice: "Air conditioning system price (€)",
        thermicSolarPanelsByTotal: "Select to enter costs per solar thermal system price field",
        thermicSolarPanelsUnitPrice: "Unit price solar thermal module (€/u)",
        thermicSolarPanelsNumber: "Number of solar thermal panels (u)",
        totalThermicSolarPanelsPrice: "Solar thermal system price (€)",
        photovoltaicModulesByTotal: "Select to enter costs per integrated photovoltaic system price fields",
        photovoltaicModulesUnitPrice: "Photovoltaic module unit price (€/u)",
        photovoltaicModulesNumber: "Number of photovoltaic modules (u)",
        totalPhotovoltaicModulesPrice: "Integrated photovoltaic system price (€)",
        scrapPriceByTotal: "Select to enter costs for scrap price fields",
        totalScrapPrice: "Total scrap price (€)",
        doubleGlazingScrapByTotal: "Select to enter costs per field of the price of glazing waste",
        doubleGlazingScrapQuantity: "Quantity of glazing waste (u)",
        doubleGlazingScrapUnitPrice: "Unit price for glazing waste (€/u)",
        totalDoubleGlazingScrapPrice: "Waste (€) - Insulated glass",
        mainFrameOrLoomByTotal: "Select to enter costs per frame/main frame price field",
        mainFrameOrLoomScrapQuantity: "Quantity of waste Frame/main frame (m)",
        mainFrameOrLoomScrapUnitPrice: "Unit price frame/main frame (€/m)",
        totalMainFrameOrLoomScrapPrice: "Waste (€) - Frame/main frame",
        gasketScrapByTotal: "Select to enter costs per gasket scrap price field",
        gasketScrapQuantity: "Quantity of trimmed gaskets (m)",
        gasketScrapUnitPrice: "Gasket trim unit price (€/m)",
        totalGasketScrapPrice: "Waste (€) - Seals",
        opaqueWindowsScrapByTotal: "Select to enter costs for window trim price fields",
        opaqueWindowsScrapQuantity: "Quantity of window trim (u)",
        opaqueWindowsScrapUnitPrice: "Unit price window trim (€/u)",
        totalOpaqueWindowsScrapPrice: "Waste (€) - Matt facade - Windows",
        opaqueExternalCladdingScrapByTotal: "Select to enter costs for window trim price fields",
        opaqueExternalCladdingScrapQuantity: "Quantity of loose outer coating (m²)",
        opaqueExternalCladdingScrapUnitPrice: "Unit price for external cladding (€/m²)",
        totalOpaqueExternalCladdingScrapPrice: "Trim (€) - Matt facade - External cladding",
        opaqueInsulationScrapByTotal: "Select to enter costs per field of the insulation scrap price",
        opaqueInsulationScrapQuantity: "Quantity of insulation waste (m²)",
        opaqueInsulationScrapUnitPrice: "Unit price of insulation waste (€/m²)",
        totalOpaqueInsulationScrapPrice: "Waste (€) - Opaque facade - Insulation",
        opaqueSupportingElementsScrapByTotal: "Select to enter costs per field of the scrap price of load-bearing elements",
        opaqueSupportingElementsScrapQuantity: "Quantity of scrap bearing elements (m3)",
        opaqueSupportingElementsScrapUnitPrice: "Unit price of scrap bearing elements (€/m3)",
        totalOpaqueSupportingElementsScrapPrice: "Waste (€) - Opaque façade - Load-bearing elements",
        transparentGlazedCellScrapByTotal: "Select to enter costs per field of the glazing cell scrap price",
        transparentGlazedCellScrapQuantity: "Quantity of glazed cell waste (u)",
        transparentGlazedCellsScrapUnitPrice: "Glazed cell unit price (€/u)",
        totalTransparentGlazedCellScrapPrice: "Waste (€) - Transparent façade - Glazed cell",
        transparentOpaqueSpandrelModulesScrapByTotal: "Select to enter costs per spandrel opaque module scrap price field",
        transparentOpaqueSpandrelModulesQuantity: "Quantity spandrel opaque modules (u)",
        transparentOpaqueSpandrelModulesScrapUnitPrice: "Unit price spandrel opaque modules (€/u)",
        totalTransparentOpaqueSpandrelModulesPrice: "Waste (€) - Transparent façade - Opaque spandrel modules",
        fixingsScrapByTotal: "Select to enter costs per field of the fastener scrap price",
        fixingsScrapQuantity: "Quantity of fastening system waste (u)",
        fixingsScrapUnitPrice: "Fixing system unit price (€/u)",
        totalFixingsScrapPrice: "Scrap (€) - Fixing system",
        anchoringsScrapByTotal: "Select to enter costs per field of the anchor system scrap price",
        anchoringsScrapQuantity: "Quantity of scrap anchoring system (u)",
        anchoringsScrapUnitPrice: "Anchorage system unit price (€/u)",
        totalAnchoringsScrapPrice: "Waste (€) - Anchorage system",
        accessoriesScrap: "Waste (€) - Accessories",
        labor: "Labor",
        totalLaborPriceByTotal: "Select to enter costs per field of the total labour price",
        totalLaborPrice: "Total labour price (€)",
        baseLaborPriceByTotal: "Select to enter costs per field of the total basic labour price",
        totalBaseLaborPrice: "Basic labour price (€)",
        baseLaborHours: "Basic labour hours (h)",
        baseLaborHourPrice: "Price per hour basic labour (€/h)",
        skilledLaborPriceByTotal: "Select to enter costs per field of the total specialised labour price",
        totalSkilledLaborPrice: "Price of specialised labour (€)",
        skilledLaborHours: "Specialised labour hours (h)",
        skilledLaborHourPrice: "Price per hour skilled labour (€/h)",
        priceMaterialsRatio: "Ratio of material price to total production price (%)",
        priceLaborRation: "Ratio of labour price to total production price (%)",
        kpiProduction: "KPI Production price (€/m²)",
        priceLaborRatio: "Ratio of labour price to total production price (%)"
      },
      transport: {
        totalTransportPrice: "Total transport price (€)",
        totalTransportCheckbox: "Select to enter costs per field of the total transport price",
        totalPackagingPrice: "Total packaging price (€)",
        packagingContainer: "Packaging", 
        transportContainer: "Transport",
        totalPackagingCheckbox: "Select to enter costs for total packaging price fields",
        totalPrice: "Total price type of packaging (€)",
        unitsNumber: "Number of units per type of packaging (u)",
        unitPrice: "Unit price per type of packaging (€/u)",
        packageType: "Type of packaging",
        totalTransportPartPrice: "Total transport price (€)",
        workSiteProductionSiteDistance: "Distance between construction site and production site (Km)",
        transportPartUnitPrice: "Unit price transport per km (€/Km)",
        kpiTransportTitle: "KPIs PRICE TRANSPORT FACADE (only one type of facade)",
        benchmarkTitle: "Comparison with benchmarks",
        kpiTransportTitleAll: "KPIs PRICE TRANSPORT FACADE (different types of facade)",
        fancadeSurface: "Façade surface (m²)",
        transportPrice1: "Transport price KPI #1 (€/m²)",
        transportPrice2: "Transport price KPI #2 (€/Km)",
        benchmarkDifference: "Difference with benchmark solution"
      },
      buildingSite: {
        installationPrice: "Facade installation price (building site)",
        installationByTotal: "Select to enter costs for facade installation price fields",
        totalInstallationPrice: "Total price facade installation (€)",
        materialsByTotal: "Select to enter costs per price field for materials needed on site",
        totalBuildingSiteMaterialsPrice: "Total price of materials needed on site (€)",
        additionalMaterial: "Additional material",
        additionalMaterialByTotal: "Select to enter costs for additional material price fields",
        totalAdditionalMaterialPrice: "Total price of additional material (€)",
        additionalMaterialQuantity: "Quantity of additional material (m²)",
        additionalMaterialUnitPrice: "Additional material unit price (€/m²)",
        buildingSiteDuration: "Total hours on site",
        laborByTotal: "Select to enter costs per labour price field",
        totalLaborPrice: "Total labour price (€)",
        baseLaborByTotal: "Select to enter costs for basic labour price fields",
        totalBaseLaborPrice: "Basic labour price (€)",
        baseLaborHours: "Basic labour hours (h)",
        baseLaborHourPrice: "Price per hour basic labour (€/h)",
        skilledLaborByTotal: "Select to enter costs for specialised labour price fields",
        totalSkilledLaborPrice: "Price of specialised labour (€)",
        skilledLaborHours: "Specialised labour hours (h)",
        skilledLaborHourPrice: "Price per hour skilled labour (€/h)",
        toolsByTotal: "Select to enter costs for tool price fields",
        totalToolsPrice: "Total price of working tools (€)",
        craneByTotal: "Select to enter costs per crane price field",
        totalTowerCranePrice: "Total crane price (€)",
        towerCraneHours: "Hours of crane use (h)",
        towerCraneHourPrice: "Hourly price for crane use (€/h)",
        elevatorPlatformByTotal: "Select to enter costs per price field for vertical lift platforms",
        totalElevatorPlatformPrice: "Total price vertical lift platforms (€)",
        elevatorPlatformHours: "Hours of use of lifting platforms (h)",
        elevatorPlatformHourPrice: "Hourly price of levelling platforms (€/h)",
        aerialPlatformByTotal: "Select to enter costs per aerial platform price field",
        totalAerialPlatformPrice: "Total price of aerial platforms (€)",
        aerialPlatformHours: "Hours of use of aerial platforms (h)",
        aerialPlatformHourPrice: "Hourly price of aerial platforms (€/h)",
        scaffoldingByTotal: "Select to enter costs per scaffolding price field",
        totalScaffoldingPrice: "Total scaffolding price (€)",
        scaffoldingSelect: "Calculation per unit",
        scaffoldingSurface: "Area covered by scaffolding (m²)",
        scaffoldingSurfaceUnitPrice: "Scaffolding unit price per surface area (€/m²)",
        scaffoldingHours: "Hours of scaffolding use (h)",
        scaffoldingHourPrice: "Hourly price of scaffolding (€/h)",
        otherByTotal: "Select to enter costs for other infrastructure price fields",
        totalOtherPrice: "Total price other infrastructure (€)",
        otherHours: "Hours of use of other equipment (h)",
        otherHourPrice: "Hourly price of other equipment (€/h)",
        securityPrice: "Price of security and safety management at the construction site", 
        securityByTotal: "Select to enter costs for safety price fields and site safety management",
        totalSecurityPrice: "Total price safety and security management on site (€)",
        staffByTotal: "Select to enter costs for personnel price fields to manage security",
        buildingSiteStaffByTotal: "Select to enter costs for personnel price fields to manage the site",
        totalStaffSecurityPrice: "Price of personnel to manage security(€)",
        staffSecurityHours: "Working hours for safety management (h)",
        staffSecurityHourPrice: "Hourly staff price (€/h)",
        totalStaffBuildingSitePrice: "Price of personnel to manage the site (€)",
        staffBuildingSiteHours: "Working hours for site management (h)",
        staffBuildingSiteHourPrice: "Hourly staff price (€/h)",
        taxes: "Fees and taxes (e.g. occupation of public land, other....)",
        taxCost: "Cost (€)",
        other: "Other items ? (feedback from companies)",
        kpiTitle: "KPIs PRICE OF CONSTRUCTION AT THE BUILDING SITE (only one type of façade)",
        benchmarkTitle: "Comparison with Benchmark",
        kpiAllTitle: "KPIs PRICE OF CONSTRUCTION AT THE BUILDING SITE (different types of façade)",
        totalInstallationSecurityPrice: "Total façade construction price + safety and site management (€)",
        surface: "Façade surface (m²)",
        installationSecuritySurfaceRatio: "Construction price + security and management on façade area (€/m²)",
        installationSurfaceRatio: "Façade installation price on façade area (€/m²)",
        securitySurfaceRatio: "Construction site safety and security price on façade area (€/m²)",
        installationTotalRatio: "Ratio Installation Price to Total Construction Price (inc. safety)",
        securityTotalRatio: "Ratio of safety price and safety management to total construction price (inc. safety)",
        buildingSiteTotal: "Construction site total cost (€)",
        buildingSiteTotalKpi: "Construction site cost KPI (€/m²)"
      },
      serviceLife: {
        annualLifeCost: "Total operating price (€/intervention)",
        annualLifeCostCalculation: "Select to enter costs for operational total price fields",
        annualIntegratedImplantsCost: "Price of annual consumption of integrated plant systems (€/intervention)",
        annualIntegratedImplantsCostCalculation: "Select to enter costs for annual consumption price fields of integrated plant systems",
        annualShieldingsCost: "Price of annual shading system consumption (€/intervention)",
        annualShieldingCostsCalculation: "Select to enter costs for price fields of the annual consumption of the shading system",
        shieldingsNumber: "Number of shielding systems (u)",
        annualShieldingsConsume: "Annual single system consumption (kwh/u)",
        energeticShieldingVectorUnitPrice: "Unit price of energy carrier (€/kwh)",
        annualVentilationCost: "Price of annual ventilation system consumption (€/intervention)",
        annualVentilationCostsCalculation: "Select to enter costs for price fields of the annual consumption of the ventilation system",
        ventilationsNumber: "Number of ventilation units (u)",
        annualVentilationConsume: "Single ventilation system annual consumption (kwh/u)",
        energeticVentilationVectorUnitPrice: "Unit price of energy carrier (€/kwh)",
        annualConditioningCost: "Price of annual air-conditioning system consumption (€/intervention)",
        annualConditioningCostsCalculation: "Select to enter costs for annual air-conditioning system consumption price fields",
        conditioningNumber: "Number of air conditioning devices (u)",
        annualConditioningConsume: "Individual air-conditioning system annual consumption (kwh/u)",
        energeticConditioningVectorUnitPrice: "Unit price of energy carrier (€/kwh)",
        annualThermicSolarCost: "Price of annual solar thermal system consumption (€/intervention)",
        annualThermicSolarCostsCalculation: "Select to enter costs for solar thermal system annual consumption price fields",
        thermicSolarNumber: "Number of solar thermal panels (u)",
        annualThermicSolarConsume: "Single solar thermal panel annual consumption (kwh/u)",
        energeticThermicSolarVectorUnitPrice: "Unit price of energy carrier (€/kwh)",
        annualPhotovoltaicIsActive: "Inclusion of integrated photovoltaic system annual production",
        annualPhotovoltaicCost: "Annual production of integrated photovoltaic system (€/intervention)",
        annualPhotovoltaicCostsCalculation: "Select to enter costs for integrated photovoltaic system annual production fields",
        photovoltaicNumber: "Number of photovoltaic modules (u)",
        annualPhotovoltaicConsume: "Single photovoltaic module annual production (kWh/u)",
        energeticPhotovoltaicVectorUnitPrice: "Unit price of energy carrier (€/kwh)",
        otherImplant: "Other pre-existing plant system",
        addOtherImplant: "Add pre-existing plant system",
        annualOtherImplantsCost: "Price of annual consumption of other pre-existing plant systems (€/intervention)",
        annualOtherImplantsCostCalculation: "Select to enter costs for price fields of the annual consumption of other pre-existing plant systems",
        annualImplantCost: "Price of annual consumption (€/intervention)",
        annualImplantCostCalculation: "Select to enter costs for annual consumption price fields",
        annualImplantConsume: "Single annual consumption (energy carrier/u)",
        energeticImplantVectorUnitPrice: "Unit price of carrier (€/energy carrier)"
      },
      maintenance: {
        percentageMessage: "Calculate the price based on the percentage of the construction price and enter it in the field below",
        totalMaintenancePercentage: "Enter the percentage of the total maintenance price compared to the total production cost (%)",
        totalExtraordinaryMaintenancePercentage: "Enter the percentage of the extraordinary maintenance price compared to the total production cost (%)",
        refLife: "Project Reference Life (years)",
        totalMaintenancePrice: "Total maintenance price including installations - calculated (€/year)",
        maintenanceSelect: "Select the type of input of the total maintenance price including installations",
        manualOption: "Manual",
        percentageOption: "Percentage of the construction price",
        sumOption: "Calculation through components",
        totalOrdinaryMaintenancePrice: "Ordinary maintenance - calculated (€/intervention)",
        totalOrdinaryMaintenancePriceByYear: "Routine maintenance - calculated (€/year)",
        ordinaryMaitenance: "Ordinary maintenance",
        plantIntegration: "Plant Integration",
        cleaning: "Cleaning",  
        extraordinaryMaintenance: "Extraordinary Maintenance",  
        totalOrdinaryMaintenancePriceSelect: "Select the type of price entry for routine maintenance",
        totalOrdinaryMaintenancePercentage: "Enter the percentage of the ordinary maintenance price with respect to the total production cost (%)",
        totalWindowReplacementPrice: "Replacement of entire window (€/intervention)",
        totalWindowReplacementPriceByYear: "Replacement of entire window (€/year)",
        totalWindowReplacementPriceIsTotal: "Select to enter costs per field of the replacement price of an entire window",
        windowsNumber: "Quantity of windows (u)",
        windowUnitPrice: "Unit price windows (€/u)",
        totalOpaqueLoomFixingsReplacementPrice: "Replacement of window components - frame and fixing (€/intervention)",
        totalOpaqueLoomFixingsReplacementPriceByYear: "Replacement of window components - frame and fixing (€/year)",
        totalOpaqueLoomFixingsReplacementPriceIsTotal: "Select to enter costs for frame and fastener replacement price fields",
        opaqueLoomFixingsQuantity: "Frame and fastening quantity (u)",
        opaqueLoomFixingsUnitPrice: "Unit price frame and fixing (€/u)",
        totalOpaqueDoubleGlazingReplacementPrice: "Replacement of window components - double glazing (€/intervention)",
        totalOpaqueDoubleGlazingReplacementPriceByYear: "Replacement of window components - double glazing (€/year)",
        totalOpaqueDoubleGlazingReplacementPriceIsTotal: "Select to enter costs per field of the price of replacement double glazing",
        opaqueDoubleGlazingQuantity: "Quantity of double glazing (u)",
        opaqueDoubleGlazingUnitPrice: "Unit price double glazing (€/u)",
        totalOpaqueShieldingsReplacementPrice: "Replacement of window components - shading system (€/intervention)",
        totalOpaqueShieldingsReplacementPriceByYear: "Replacement of window components - shading system (€/year)",
        totalOpaqueShieldingsReplacementPriceIsTotal: "Select to enter costs per field of the shielding system replacement price",
        opaqueShieldingsQuantity: "Quantity shielding system (u)",
        opaqueShieldingsUnitPrice: "Unit price shielding system (€/u)",
        totalExternalCladdingReplacementPrice: "Opaque casing - replacement of external cladding (€/intervention)",
        totalExternalCladdingReplacementPriceByYear: "Opaque casing - replacement of external cladding (€/year)",
        totalExternalCladdingReplacementPriceIsTotal: "Select to enter costs per price field for replacement of outer cladding",
        externalCladdingQuantity: "Total surface area exterior cladding (m²)",
        externalCladdingUnitPrice: "Unit price exterior cladding (€/m²)",
        externalCladdingUnitsSurface: "Unit area of external cladding panels (m²/u)",
        totalInsulationReplacementPrice: "Opaque envelope - insulation replacement (€/intervention)",
        totalInsulationReplacementPriceByYear: "Opaque envelope - insulation replacement (€/year)",
        totalInsulationReplacementPriceIsTotal: "Select to enter costs per price field for insulation replacement",
        insulationSurface: "Insulation surface (m²)",
        insulationUnitPrice: "Unit price insulation (€/m²)",
        totalSupportingElementsReplacementPrice: "Opaque casing - load-bearing element (€/intervention)",
        totalSupportingElementsReplacementPriceByYear: "Opaque casing - load-bearing element (€/year)",
        totalSupportingElementsReplacementPriceIsTotal: "Select to enter costs per field of the replacement price of the carrier element",
        supportingElementsQuantity: "Quantity of load-bearing element (m3)",
        supportingElementUnitPrice: "Unit price load-bearing element (€/m3)",
        totalPrefabricatedModulesReplacementPrice: "Opaque casing - prefabricated module replacement (€/intervention)",
        totalPrefabricatedModulesReplacementPriceByYear: "Opaque casing - prefabricated module replacement (€/year)",
        totalPrefabricatedModulesReplacementPriceIsTotal: "Select to enter costs per prefabricated module replacement price field",
        prefabricatedModulesQuantity: "Quantity of prefabricated modules (u)",
        prefabricatedModulesUnitPrice: "Module price per sqm (€/m²)",
        totalGlazedCellsReplacementPrice: "Transparent casing - replacement of glazing cells (€/intervention)",
        totalGlazedCellsReplacementPriceByYear: "Transparent casing - replacement of glazing cells (€/year)",
        totalGlazedCellsReplacementPriceIsTotal: "Select to enter costs per price field for glass cell replacement",
        glazedCellsNumber: "Transparent casing - number of glazed cells (u)",
        glazedCellUnitPrice: "Unit price glazed cell (€/u)",
        totalDoubleGlazingReplacementPrice: "Transparent casing - replacement of double glazing (€/intervention)",
        totalDoubleGlazingReplacementPriceByYear: "Transparent casing - double glazing replacement (€/year)",
        totalDoubleGlazingReplacementPriceIsTotal: "Select to enter costs per field of the price of replacement double glazing",
        doubleGlazingQuantity: "Transparent casing - quantity of double glazing (u)",
        doubleGlazingUnitPrice: "Unit price double glazing (€/u)",
        totalTransparentLoomFixingsReplacementPrice: "Transparent casing - frame replacement or fixings (€/intervention)",
        totalTransparentLoomFixingsReplacementPriceByYear: "Transparent casing - frame replacement or fixings (€/year)",
        totalTransparentLoomFixingsReplacementPriceIsTotal: "Select to enter costs for frame or fixture replacement price fields",
        transparentLoomFixingsQuantity: "Quantity frame or fasteners (u)",
        transparentLoomFixingsUnitPrice: "Unit price frame or fixings (€/u)",
        totalTransparentShieldingsReplacementPrice: "Transparent casing - shielding system (€/intervention)",
        totalTransparentShieldingsReplacementPriceByYear: "Transparent casing - shielding system (€/year)",
        totalTransparentShieldingsReplacementPriceIsTotal: "Select to enter costs per field of the shielding system replacement price",
        transparentShieldingsQuantity: "Quantity shielding system (u)",
        transparentShieldingsUnitPrice: "Unit price shielding system (€/u)",
        totalPhotovoltaicModulesReplacementPrice: "Plant Components - Integrated Photovoltaic Modules (€/intervention)",
        totalPhotovoltaicModulesReplacementPriceByYear: "Plant Components - Integrated Photovoltaic Modules (€/year)",
        totalPhotovoltaicModulesReplacementPriceIsTotal: "Select to enter costs per integrated photovoltaic module replacement price fields",
        photovoltaicModulesNumber: "Number of photovoltaic modules (u)",
        photovoltaicModuleUnitPrice: "Photovoltaic module unit price (€/u)",
        totalSolarPanelsReplacementPrice: "System components - thermal solar panels (€/intervention)",
        totalSolarPanelsReplacementPriceByYear: "Plant components - thermal solar panels (€/year)",
        totalSolarPanelsReplacementPriceIsTotal: "Select to enter costs per price field for solar thermal panel replacement",
        solarPanelsNumber: "Number of solar thermal panels (u)",
        solarPanelUnitPrice: "Unit price solar thermal module (€/u)",
        totalVentilationDevicesReplacementPrice: "Plant components - ventilation equipment (€/intervention)",
        totalVentilationDevicesReplacementPriceByYear: "Plant components - ventilation equipment (€/year)",
        totalVentilationDevicesReplacementPriceIsTotal: "Select to enter costs per price field for replacement of ventilation equipment",
        ventilationDevicesNumber: "Number of ventilation units (u)",
        ventilationDeviceUnitPrice: "Unit price ventilation equipment (€/u)",
        totalAirConditioningDevicesReplacementPrice: "Plant components - air conditioning (€/intervention)",
        totalAirConditioningDevicesReplacementPriceByYear: "Plant components - air conditioning (€/year)",
        totalAirConditioningDevicesReplacementPriceIsTotal: "Select to enter costs per air conditioning replacement price field",
        airConditioningDevicesNumber: "Number of air conditioning devices (u)",
        airConditioningDeviceUnitPrice: "Unit price air conditioning systems (€/u)",
        totalCleaningPrice: "Cleaning prices (€/year)",
        totalCleaningPriceByYear: "Cleaning prices (€/year)",
        totalCleaningPriceIsTotal: "Select to enter costs for cleaning price fields",
        cleaningsEveryHowManyYears: "Cleaning period (every how many years they are carried out)",
        cleaningUnitPrice: "Unit price cleaning intervention - entire façade (€/u)",
        totalExtraordinaryMaintenancePrice: "Extraordinary maintenance - calculated (€/intervention)",
        totalExtraordinaryMaintenancePriceByYear: "Extraordinary maintenance - calculated (€/year)",
        totalExtraordinaryMaintenancePriceIsTotal: "Select input type",
        everyHowManyYears: "Maintenance period (every how many years it is carried out)",
        timesInAYear: "Frequency of maintenance (how often it is carried out in a year)",
        periodByEveryHowManyYears: "Frequency entry based on how many years it takes place",
        periodByTimesInAYear: "Frequency entry based on attendance in a year",
        actionName: "Action name",
        actionValue: "Share value (€)",
        action: "Action (€/intervention)",
        actionByYear: "Action (€/year)",
        actions: "Actions",
        kpiTitle: "KPIs FACADE MAINTENANCE PRICE (one type of facade)",
        benchmarkTitle: "Difference with benchmark solution",
        kpiAllTitle: "KPIs PRICE OF FACADE MAINTENANCE (different types of facade)",
        surface: "Façade surface (m²)",
        implantsSurfacePercentage: "Percentage of surface area occupied by installations on the total façade (total for all installations)",
        implantsSurface: "Façade plant surface area (m²)",
        surfaceWithoutImplants: "Façade surface excluding installations (m²)",
        systemsIntegration: "Maintenance - Price for plant integration only (€/intervention)",
        kpi1: "KPI #1 Maintenance price (€/m²)",
        kpi2: "KPI #2 Plant Integration Maintenance Price (€/m²)",
        kpi3: "KPI #3 incidence of facilities on maintenance (%)"
      },
      costsActualization: {
        interestRate: "Interest rate (%)",
        priceVectorVariation: "Rate of change in energy carrier price (%)",
        priceVectorVariationIsActive: "Rate of change in energy carrier price"
      },
      endOfLife: {
        demolitionAndDisassembly: "Aggregate demolition and dismantling",
        totalDemolitionAndDisassemblyPrice: "Total price for demolition and dismantling (€)",
        totalDemolitionAndDisassemblyPriceIsTotal: "Select to enter costs for demolition and dismantling price fields",
        surfaceDemolitionOrDisassembly: "Total surface area of dismantled or demolished façade (m²)",
        demolition: "Demolition",
        totalDemolitionPrice: "Total demolition price (€)",
        totalDemolitionPriceIsTotal: "Select to enter costs for demolition price fields",
        demolitionQuantity: "Demolition quantity (m²)",
        demolitionUnitPrice: "Unit price demolition (€/m²)",
        disassembly: "Dismantling",
        totalDisassemblyPrice: "Disassembly price (€)",
        totalDisassemblyPriceIsTotal: "Select to enter costs for disassembly price fields",
        opaqueDisassembly: "Dismantling of opaque casing",
        totalWindowsDisassemblyPrice: "Total price window dismantling (€)",
        totalWindowsDisassemblyPriceIsTotal: "Select to enter costs per window dismantling price field",
        windowsQuantity: "Quantity of windows (u)",
        windowsUnitPrice: "Unit price window dismantling (€/u)",
        totalExternalCladdingDisassemblyPrice: "Total price for dismantling outer cladding (€)",
        totalExternalCladdingDisassemblyPriceIsTotal: "Select to enter costs per price field for dismantling the outer cladding",
        externalCladdingQuantity: "Exterior cladding quantity (m²)",
        externalCladdingUnitPrice: "Unit price for dismantling external cladding (€/m²)",
        totalInsulationDisassemblyPrice: "Total price dismantling insulation (€)",
        totalInsulationDisassemblyPriceIsTotal: "Select to enter costs per field of the price of dismantling insulation",
        insulationQuantity: "Insulation quantity (m²)",
        insulationUnitPrice: "Unit price dismantling insulation (€/m²)",
        totalSupportingElementsAndFixingsDisassemblyPrice: "Total price bearing panel dismantling and fixing (€)",
        totalSupportingElementsAndFixingsDisassemblyPriceIsTotal: "Select to enter costs per price field for disassembly of carrier panel and fixing",
        supportingElementsAndFixingsQuantity: "Quantity of load-bearing panel (m²)",
        supportingElementsAndFixingsUnitPrice: "Unit price for dismantling load-bearing panel (€/m²)",
        transparentDisassembly: "Dismantling of transparent casing",
        totalGlazedCellsDisassemblyPrice: "Total price for dismantling glazed cells (€)",
        totalGlazedCellsDisassemblyPriceIsTotal: "Select to enter costs per price field for dismantling glass cells",
        glazedCellsQuantity: "Quantity of glazed cells (u)",
        glazedCellsUnitPrice: "Unit price for dismantling glazed cells (€/u)",
        totalDoubleGlazingDisassemblyPrice: "Total price for removal of double glazing (€)",
        totalDoubleGlazingDisassemblyPriceIsTotal: "Select to enter costs per price field for removal of double glazing",
        doubleGlazingQuantity: "Quantity of double glazing (u)",
        doubleGlazingUnitPrice: "Unit price for removal of double glazing (€/u)",
        totalUprightsCrosspiecesFixingsDisassemblyPrice: "Total price dismantling uprights, crossbars and fixings (€)",
        totalUprightsCrosspiecesFixingsDisassemblyPriceIsTotal: "Select to enter costs per price field for dismantling uprights, crossbars and fasteners",
        uprightsCrosspiecesFixingsQuantity: "Quantity of mullions and transoms (m)",
        uprightsCrosspiecesFixingsUnitPrice: "Unit price for dismantling uprights and crossbars (€/m)",
        transport: "Transport",
        totalTransportPrice: "Total transport price (€)",
        totalTransportPriceIsTotal: "Select to enter costs for transport price fields",
        totalDisposalTransportPrice: "Transport price to disposal/dump site (€)",
        totalDisposalTransporPriceIsTotal: "Select to enter costs for transport price fields to disposal/dump site",
        disposalTransportQuantity: "Transport hours (h)",
        disposalTransportUnitPrice: "Hourly transport price (€/h)",
        totalRecyclingTransportPrice: "Price of transport to the recycling/production site",
        totalRecyclingTransporPriceIsTotal: "Select to enter costs for transport price fields at the recycling/production site",
        recyclingTransportQuantity: "Transport hours (h)",
        recyclingTransportUnitPrice: "Hourly transport price (€/h)",
        recycling: "Recycling",
        totalRecyclingRevenue: "Revenues generated by the recycling practice (€)",
        totalRecyclingRevenueKpi: "Revenues generated by the recycling practice (-€)",
        totalRecyclingRevenueIsTotal: "Select to enter costs for revenue fields generated by recycling",
        totalWindowsRecyclingRevenue: "Revenues from window recycling (€)",
        totalWindowsRecyclingRevenueIsTotal: "Select to enter costs for revenue fields generated by window recycling",
        windowsRecyclingQuantity: "Quantity of windows (u)",
        windowsRecyclingUnitPrice: "Unit revenue per window recycling (€/u)",
        totalExternalCladdingRecyclingRevenue: "Revenues from external coating recycling (€)",
        totalExternalCladdingRevenueIsTotal: "Select to enter costs for revenue fields generated by the recycling of the outer coating",
        externalCladdingRecyclingQuantity: "Exterior cladding quantity (m²)",
        externalCladdingRecyclingUnitPrice: "Unit revenue for external coating recycling (€/m²)",
        totalInsulationRecyclingRevenue: "Revenues from recycling insulation (€)",
        totalInsulationRevenueIsTotal: "Select to enter costs for revenue fields generated by the recycling of insulation",
        insulationRecyclingQuantity: "Insulation quantity (m²)",
        insulationRecyclingUnitPrice: "Unit revenue for recycling insulation (€/m²)",
        totalSupportingElementsAndFixingsRecyclingRevenue: "Revenues from recycling bearing panel and fixing (€)",
        totalSupportingElementsAndFixingsRecyclingRevenueIsTotal: "Select to enter costs for revenue fields generated by the recycling of the carrier panel and the fastener",
        supportingElementsAndFixingsRecyclingQuantity: "Quantity of bearing panel and fastening (m²)",
        supportingElementsAndFixingsRecyclingUnitPrice: "Unit revenue for recycling load-bearing panel and fixing (€/m²)",
        totalGlazedCellsRecyclingRevenue: "Revenues from recycling glass cells",
        totalGlazedCellsRecyclingRevenueIsTotal: "Select to enter costs for revenue fields generated by the recycling of glass cells",
        glazedCellsRecyclingQuantity: "Quantity of glazed cells (u)",
        glazedCellsUnitRecyclingPrice: "Unit revenue per glass cell recycling (€/u)",
        totalDoubleGlazingRecyclingRevenue: "Recycling revenues",
        totalDoubleGlazingRecyclingRevenueIsTotal: "Select to enter costs for revenue fields generated by the recycling of double-glazing",
        doubleGlazingRecyclingQuantity: "Quantity of double glazing (u)",
        doubleGlazingRecyclingUnitPrice: "Unit revenue for double-glazing recycling (€/u)",
        totalUprightsCrosspiecesFixingsRecyclingRevenue: "Recycling of uprights, crossbars and fasteners",
        totalUprightsCrosspiecesFixingsRecyclingRevenueIsTotal: "Select to enter costs for revenue fields generated by the recycling of mullions and transoms",
        uprightsCrosspiecesFixingsRecyclingQuantity: "Quantity of mullions and transoms (m)",
        uprightsCrosspiecesFixingsRecyclingUnitPrice: "Unit revenue for recycling uprights and crossbars (€/m)",
        ReUse: "Reuse",
        totalReUseRevenue: "Revenues generated by the reuse practice (€)",
        totalReUseRevenueIsTotal: "Select to enter costs for revenue fields generated by reuse",
        totalWindowsReUseRevenue: "Revenues from reuse of windows (€)",
        totalWindowsReUseRevenueIsTotal: "Select to enter costs for revenue fields generated by the reuse of windows",
        windowsReUseQuantity: "Quantity of windows (u)",
        windowsReUseUnitPrice: "Unit revenue per window reuse (€/u)",
        totalExternalCladdingReUseRevenue: "Revenues from re-use of external cladding (€)",
        totalExternalCladdingReUseRevenueIsTotal: "Select to enter costs for revenue fields generated by the reuse of the outer cladding",
        externalCladdingReUseQuantity: "Exterior cladding quantity (m²)",
        externalCladdingReUseUnitPrice: "Unit revenue for re-use of external cladding (€/m²)",
        totalInsulationReUseRevenue: "Revenues from reused insulation (€)",
        totalInsulationReUseRevenueIsTotal: "Select to enter costs for revenue fields generated by the reuse of insulation",
        insulationReUseQuantity: "Insulation quantity (m²)",
        insulationReUseUnitPrice: "Unit revenue for insulation reuse (€/m²)",
        totalSupportingElementsAndFixingsReUseRevenue: "Revenues from reuse of bearing panel and fixing (€)",
        totalSupportingElementsAndFixingsReUseRevenueIsTotal: "Select to enter costs for revenue fields generated by the reuse of the carrier panel and fastening",
        supportingElementsAndFixingsReUseQuantity: "Quantity of bearing panel and fastening (m²)",
        supportingElementsAndFixingsReUseUnitPrice: "Unit revenue for reuse of bearing panel and fixing (€/m²)",
        totalGlazedCellsReUseRevenue: "Revenues from reuse of glass cells",
        totalGlazedCellsReUseRevenueIsTotal: "Select to enter costs for revenue fields generated by the reuse of glass cells",
        glazedCellsReUseQuantity: "Quantity of glazed cells (u)",
        glazedCellsUnitReUsePrice: "Unit revenue for reuse of glazed cells (€/u)",
        totalDoubleGlazingReUseRevenue: "Revenues from reuse of double glazing",
        totalDoubleGlazingReUseRevenueIsTotal: "Select to enter costs for revenue fields generated by the reuse of double glazing",
        doubleGlazingReUseQuantity: "Quantity of double glazing (u)",
        doubleGlazingReUseUnitPrice: "Unit revenue for reuse of double glazing (€/u)",
        totalUprightsCrosspiecesFixingsReUseRevenue: "Reuse of uprights, crossbars and fasteners",
        totalUprightsCrosspiecesFixingsReUseRevenueIsTotal: "Select to enter costs for revenue fields generated by the reuse of uprights and crossbars",
        uprightsCrosspiecesFixingsReUseQuantity: "Quantity of mullions and transoms (m)",
        uprightsCrosspiecesFixingsReUseUnitPrice: "Unit revenue for reuse of uprights and crossbars (€/m)",
        kpiTitle: "KPIs END-OF-LIFE FAÇADE (only one type of façade)",
        benchmarkTitle: "Comparison with benchmarks",
        kpiAllTitle: "KPIs END OF LIFE FACADE (different types of facade)",
        kpiSurface: "Façade surface (m²)",
        kpi1: "KPI #1 end-of-life - Decommissioning price on constructed façade area (€/m²)",
        kpi2: "KPI #2 end-of-life - decommissioning price on decommissioned façade area (€/m²)",
        kpi3: "KPI #3 end-of-life - revenue generated on total production price (%)"
      }
    }
  },
  formPlaceholders: {
    email: "Email",
    password: "Password",
    nominative: "Name",
    category: "Category name",
    details: {
      general: {
        id: "Project Id",
        name: "Project name",
        location: "Place",
        reportAuthor: "Author of the analysis",
        description: "Description",
        type: "Type of project",
        certification: "Building energy performance",
        useDestination: "Intended use / building type",
        constructionYear: "Year of construction"
      },
      fancadeProject: {
        type: "Facade type analysed",
        surface: "Facade surface",
        externalCladdingUnitsSurface: "Unit area of external cladding panels (m²/u)",
        externalCladdingUnitsNumber: "Number of outer cladding panels",
        totalExternalCladdingSurface: "Total surface area outer cladding",
        insulationSurface: "Insulation surface",
        supportingElementsQuantity: "Linear metres of substructure",
        prefabricatedModulesQuantity: "Quantity of prefabricated modules",
        modulesSurface: "Total module surface (m²)",
        opaqueAnchoringsNumber: "Number of point anchors",
        opaqueFixingsNumber: "Number of fixings (facade components)",
        glazedCellsNumber: "Number of glazed cells",
        glazedCellsSurface: "Glazed cell unit surface",
        doubleGlazingQuantity: "Quantity of double glazing",
        doubleGlazingSurface: "Insulated glass surface",
        mainFrameHeightPerUnit: "Main frame height per glazed unit",
        mainFrameWidthPerUnit: "Main frame width per glazed unit",
        totalMainFrameLength: "Total length main frame",
        totalGasketLength: "Total length of seals",
        opaqueSpandrelPanelsQuantity: "Quantity of opaque spandrel panels",
        opaqueSpandrelPanelsSurface: "Matt spandrel panel surface",
        transparentAnchoringsNumber: "Number of point anchors",
        transparentFixingsNumber: "Number of fixings (facade components)",
        windowOpaqueSurfaceRatio: "Ratio of bifurcated surface area to total surface area",
        opaqueUValue: "U-value (opaque)",
        transparentUValue: "U-value (transparent)",
        windowGlassGValue: "G-value (window glass)",
        fancadeGlassGValue: "G-value (transparent curtain wall glass)",
        prefabricationLevel: "Level of prefabrication",
        geometricDetails: {
          module: "Module",
          width: "Width (cm)",
          length: "Length (cm)",
          depth: "Thickness (cm)"
        },
        systemsIntegration: {
          commonName: "Other plant integrations",
          name: "Name",
          units: "Number of units (u)",
          unitSurface: "Unit surface area (m²/u)",
          totalSurface: "Total surface area (m²)",
          type: "Type of plant integration",
          surfaceRatio: "Ratio of plant area to façade area (%)"
        },
        systemsSurfacePercentage: "Percentage of surface area occupied by installations on the total façade (total for all installations)",
        windowType: {
          windowType: "Window type",
          windowsNumber: "Quantity of windows",
          windowsSurface: "Window surface",
          doubleGlazingNumber: "Quantity of double glazing",
          doubleGlazingSurface: "Insulated glass surface",
          loomHeight: "Frame height",
          loomWidth: "Frame width",
          totalLoomLength: "Total frame length",
          totalGasketLength: "Total length of seals"
        },
        shieldingSystemsNumber: "Number of shielding systems (u)",
        ventilationDevicesNumber: "Number of ventilation units (u)",
        airConditioningDevicesNumber: "Number of air conditioning devices (u)",
        thermicSolarPanelsNumber: "Number of solar thermal panels (u)",
        thermicSolarPanelsUnitSurface: "Thermal solar panel surface area (m²/u)",
        photovoltaicModulesNumber: "Number of photovoltaic modules (u)",
        photovoltaicModulesSurface: "Photovoltaic module surface (m²)",
        photovoltaicModulesUnitSurface: "Photovoltaic module unit surface area (m²/u)"
      },
      fancadePrice: {
        totalDesignPrice: "Total design price",
        technicalProject: "Technical project",
        structuralSismicProject: "Structural and seismic design",
        lightingTechnicalProject: "Lighting design",
        ventilationProject: "Ventilation project",
        acusticProject: "Acoustic project",
        airQualityCheck: "Air quality testing",
        constructionPrinceAnalysis: "Construction price analysis",
        firePreventionDesign: "Fire design",
        buildingSiteManagement: "Site management - project",
        buildingSiteSafety: "Construction site safety - project",
        energeticPerformanceAnalysis: "Energy performance assessment and certification",
        competitionProject: "Project for tender basis",
        maintenancePlan: "Maintenance Plan",
        userManual: "User's Manual"
      },
      fancadeProduction: {
        opaqueShieldingMaterials: "Opaque casing",
        transparentShieldingMaterials: "Transparent casing",
        plantIntegration: "Plant integration",
        scrap: "Scrap",
        totalProductionPrice: "Total production price (€)",
        totalMaterialsPrice: "Total materials price (€)",
        fancadeSurface: "Façade surface (m²)",
        windowsTitle: "Window price per type",
        windowByTotal: "Select to enter costs per field of the total price of the facade windows",
        checkboxTotalWindowPrice: "Select to enter costs for window type price fields",
        transparentTotalPrice: "Total price transparent casing",
        windowsTotalPrice: "Total price windows (€)",
        windowContainer: "Window type",
        totalWindowPrice: "Total price window type (€)",
        windowSelect: "Select calculation mode",
        unityOption: "Calculate per unit",
        componentsOption: "Calculation by components",
        windowsNumber: "Quantity of windows (u)",
        unitPrice: "Window price per sqm (€/m²)",
        loomByTotal: "Select to enter costs per frame price fields",
        loomComponentsPrice: "Price of window components - frame (€)",
        loomUnitPrice: "Price of window components - frame (€)",
        totalLoomLength: "Total frame length (m)",
        doubleGlazingComponentsPrice: "Price of window components - double glazing (€)",
        doubleGlazingByTotal: "Select to enter costs per pane price field",
        doubleGlazingNumber: "Gasket quantity (u)",
        doubleGlazingUnitPrice: "Unit price seals (€/u)",
        gasketByTotal: "Select to enter costs for gasket price fields",
        gasketComponentsPrice: "Price of window components - seals (€)",
        gasketsUnitPrice: "Price of window components - seals (€)",
        totalGasketLength: "Total seal length (m)",
        externalCladdingByTotal: "Select to enter costs per price field of the outer cladding",
        externalCladdingTotalPrice: "Price opaque casing - outer cladding (€)",
        totalExternalCladdingSurface: "Total surface area exterior cladding (m²)",
        externalCladdingUnitPrice: "Unit price exterior cladding (€/m²)",
        insulationByTotal: "Select to enter costs for insulation price fields",
        insulationTotalPrice: "Price opaque envelope - insulation (€)",
        insulationSurface: "Total insulation surface (m²)",
        insulationUnitPrice: "Unit price insulation (€/m²)",
        supportingElementsByTotal: "Select to enter costs for carrier price fields",
        supportingElementsTotalPrice: "Price opaque casing - load-bearing elements (€)",
        totalSupportingElementsQuantity: "Linear metres of substructure (m)",
        supportingElementsUnitPrice: "Unit price load-bearing element (€/m)",
        prefabricatedModulesByTotal: "Select to enter costs per prefabricated module price fields",
        prefabricatedModulesTotalPrice: "Price opaque casing - prefabricated modules (€)",
        totalPrefabricatedModulesQuantity: "Quantity of prefabricated modules (u)",
        prefabricatedModulesElementsUnitPrice: "Unit price prefabricated module (€/m²)",
        opaqueAnchoringByTotal: "Select to enter costs for anchoring system price fields",
        opaqueAnchoringTotalPrice: "Price opaque casing - anchoring system (€)",
        opaqueAnchoringsNumber: "Number of point anchors (u)",
        opaqueAnchoringUnitPrice: "Anchor unit price (€/u)",
        opaqueFixingsByTotal: "Select to enter costs per fixture price field",
        opaqueFixingsTotalPrice: "Price opaque casing - fixing system (€)",
        opaqueFixingsNumber: "Number of fixings (facade components) (u)",
        opaqueFixingsUnitPrice: "Fixing unit price (€/u)",
        hardware: "Hardware and accessories",
        hardwareCheckbox: "Select to enter costs per price field for hardware and accessories",
        singleHardwareTotalPrice: "Hardware and accessories price (€)",
        hardwareTotalPrice: "Price opaque casing - hardware and accessories (€)",
        hardwareUnitPrice: "Hardware and accessories unit price (€/u)",
        hardwareQuantity: "Quantity of hardware and accessories (u)",
        glazedCellsByTotal: "Select to enter costs for glazed cell price fields",
        totalGlazedCellsPrice: "Transparent enclosure - glazed cell price (€)",
        glazedCellsUnitPrice: "Unit price glazed cell (€/u)",
        glazedCellsNumber: "Transparent casing - number of glazed cells (u)",
        totalDoubleGlazingPrice: "Price transparent casing - double glazing (€)",
        doubleGlazingQuantity: "Transparent casing - quantity of double glazing (u)",
        totalMainFramePrice: "Price transparent main frame (€)",
        mainFrameUnitPrice: "Unit price main frame (€/u)",
        totalMainFrameLength: "Total length main frame (m)",
        totalGasketPrice: "Price transparent casing - seals (€)",
        gasketUnitPrice: "Unit price seals (€/m)",
        opaqueSpandrelByTotal: "Select to enter costs per price field for opaque spandrel panels",
        totalOpaqueSpandrelModulesPrice: "Price transparent casing - matt spandrel panel (€)",
        opaqueSpandrelModulesUnitPrice: "Unit price matt spandrel panel (€/u)",
        opaqueSpandrelPanelsQuantity: "Transparent casing - quantity of opaque spandrel panels (u)",
        transparentAnchoringByTotal: "Select to enter costs for anchoring system price fields",
        totalTransparentAnchoringsPrice: "Price transparent enclosure - anchoring system (€)",
        transparentAnchoringsUnitPrice: "Anchor unit price (€/u)",
        transparentAnchoringsNumber: "Transparent casing - Number of point anchors (u)",
        fixingsByTotal: "Select to enter costs per fixture price field",
        totalTransparentFixingsNumberPrice: "Transparent casing - fastening system (€)",
        transparentFixingsNumberUnitPrice: "Fixing unit price (€/u)",
        transparentFixingsNumber: "Transparent casing - Number of fixings (façade components) (u)",
        systemsIntegrationByTotal: "Select to enter costs per plant integration price field",
        totalSystemsIntegrationPrice: "Plant integration price (€)",
        shieldingByTotal: "Select to enter costs per shielding system price field",
        shieldingsUnitPrice: "Unit price shielding systems (€/u)",
        shieldingSystemsNumber: "Number of shielding systems (u)",
        totalShieldingsPrice: "Shielding system price (€)",
        ventilationByTotal: "Select to enter costs for ventilation system price fields",
        ventilationSystemsUnitPrice: "Unit price ventilation equipment (€/u)",
        ventilationDevicesNumber: "Number of ventilation units (u)",
        totalVentilationSystemsPrice: "Ventilation system price (€)",
        airConditioningByTotal: "Select to enter costs for air conditioning system price fields",
        airConditioningSystemsUnitPrice: "Unit price air conditioning systems (€/u)",
        airConditioningDevicesNumber: "Number of air conditioning devices (u)",
        totalAirConditioningSystemsPrice: "Air conditioning system price (€)",
        thermicSolarPanelsByTotal: "Select to enter costs per solar thermal system price field",
        thermicSolarPanelsUnitPrice: "Unit price solar thermal module (€/u)",
        thermicSolarPanelsNumber: "Number of solar thermal panels (u)",
        totalThermicSolarPanelsPrice: "Solar thermal system price (€)",
        photovoltaicModulesByTotal: "Select to enter costs per integrated photovoltaic system price fields",
        photovoltaicModulesUnitPrice: "Photovoltaic module unit price (€/u)",
        photovoltaicModulesNumber: "Number of photovoltaic modules (u)",
        totalPhotovoltaicModulesPrice: "Integrated photovoltaic system price (€)",
        scrapPriceByTotal: "Select to enter costs for scrap price fields",
        totalScrapPrice: "Total scrap price (€)",
        doubleGlazingScrapByTotal: "Select to enter costs per field of the price of glazing waste",
        doubleGlazingScrapQuantity: "Quantity of glazing waste (u)",
        doubleGlazingScrapUnitPrice: "Unit price for glazing waste (€/u)",
        totalDoubleGlazingScrapPrice: "Waste (€) - Insulated glass",
        mainFrameOrLoomByTotal: "Select to enter costs per frame/main frame price field",
        mainFrameOrLoomScrapQuantity: "Quantity of waste Frame/main frame (m)",
        mainFrameOrLoomScrapUnitPrice: "Unit price frame/main frame (€/m)",
        totalMainFrameOrLoomScrapPrice: "Waste (€) - Frame/main frame",
        gasketScrapByTotal: "Select to enter costs per gasket scrap price field",
        gasketScrapQuantity: "Quantity of trimmed gaskets (m)",
        gasketScrapUnitPrice: "Gasket trim unit price (€/m)",
        totalGasketScrapPrice: "Waste (€) - Seals",
        opaqueWindowsScrapByTotal: "Select to enter costs for window trim price fields",
        opaqueWindowsScrapQuantity: "Quantity of window trim (u)",
        opaqueWindowsScrapUnitPrice: "Unit price window trim (€/u)",
        totalOpaqueWindowsScrapPrice: "Waste (€) - Matt facade - Windows",
        opaqueExternalCladdingScrapByTotal: "Select to enter costs for window trim price fields",
        opaqueExternalCladdingScrapQuantity: "Quantity of loose outer coating (m²)",
        opaqueExternalCladdingScrapUnitPrice: "Unit price for external cladding (€/m²)",
        totalOpaqueExternalCladdingScrapPrice: "Trim (€) - Matt facade - External cladding",
        opaqueInsulationScrapByTotal: "Select to enter costs per field of the insulation scrap price",
        opaqueInsulationScrapQuantity: "Quantity of insulation waste (m²)",
        opaqueInsulationScrapUnitPrice: "Unit price of insulation waste (€/m²)",
        totalOpaqueInsulationScrapPrice: "Waste (€) - Opaque facade - Insulation",
        opaqueSupportingElementsScrapByTotal: "Select to enter costs per field of the scrap price of load-bearing elements",
        opaqueSupportingElementsScrapQuantity: "Quantity of scrap bearing elements (m3)",
        opaqueSupportingElementsScrapUnitPrice: "Unit price of scrap bearing elements (€/m3)",
        totalOpaqueSupportingElementsScrapPrice: "Waste (€) - Opaque façade - Load-bearing elements",
        transparentGlazedCellScrapByTotal: "Select to enter costs per field of the glazing cell scrap price",
        transparentGlazedCellScrapQuantity: "Quantity of glazed cell waste (u)",
        transparentGlazedCellsScrapUnitPrice: "Glazed cell unit price (€/u)",
        totalTransparentGlazedCellScrapPrice: "Waste (€) - Transparent façade - Glazed cell",
        transparentOpaqueSpandrelModulesScrapByTotal: "Select to enter costs per spandrel opaque module scrap price field",
        transparentOpaqueSpandrelModulesQuantity: "Quantity spandrel opaque modules (u)",
        transparentOpaqueSpandrelModulesScrapUnitPrice: "Unit price spandrel opaque modules (€/u)",
        totalTransparentOpaqueSpandrelModulesPrice: "Waste (€) - Transparent façade - Opaque spandrel modules",
        fixingsScrapByTotal: "Select to enter costs per field of the fastener scrap price",
        fixingsScrapQuantity: "Quantity of fastening system waste (u)",
        fixingsScrapUnitPrice: "Fixing system unit price (€/u)",
        totalFixingsScrapPrice: "Scrap (€) - Fixing system",
        anchoringsScrapByTotal: "Select to enter costs per field of the anchor system scrap price",
        anchoringsScrapQuantity: "Quantity of scrap anchoring system (u)",
        anchoringsScrapUnitPrice: "Anchorage system unit price (€/u)",
        totalAnchoringsScrapPrice: "Waste (€) - Anchorage system",
        accessoriesScrap: "Waste (€) - Accessories",
        totalLaborPriceByTotal: "Select to enter costs per field of the total labour price",
        totalLaborPrice: "Total labour price (€)",
        baseLaborPriceByTotal: "Select to enter costs per field of the total basic labour price",
        totalBaseLaborPrice: "Basic labour price (€)",
        baseLaborHours: "Basic labour hours (h)",
        baseLaborHourPrice: "Price per hour basic labour (€/h)",
        skilledLaborPriceByTotal: "Select to enter costs per field of the total specialised labour price",
        totalSkilledLaborPrice: "Price of specialised labour (€)",
        skilledLaborHours: "Specialised labour hours (h)",
        skilledLaborHourPrice: "Price per hour skilled labour (€/h)"
      },
      transport: {
        totalTransportPrice: "Total transport price (€)",
        totalTransportCheckbox: "Select to enter costs per field of the total transport price",
        totalPackagingPrice: "Total packaging price (€)",
        totalPackagingCheckbox: "Select to enter costs for total packaging price fields",
        totalPrice: "Total price type of packaging (€)",
        unitsNumber: "Number of units per type of packaging (u)",
        unitPrice: "Unit price per type of packaging (€/u)",
        totalTransportPartPrice: "Total transport price (€)",
        workSiteProductionSiteDistance: "Distance between construction site and production site (Km)",
        transportPartUnitPrice: "Unit price transport per km (€/Km)",
        kpiTransportTitle: "KPIs PRICE TRANSPORT FACADE (only one type of facade)",
        fancadeSurface: "Façade surface (m²)",
        transportPrice1: "Transport price KPI #1 (€/m²)",
        transportPrice2: "Transport price KPI #2 (€/Km)",
        benchmarkDifference: "Difference with benchmark solution"
      },
      buildingSite: {
        installationByTotal: "Select to enter costs for facade installation price fields",
        totalInstallationPrice: "Total price facade installation (€)",
        materialsByTotal: "Select to enter costs per price field for materials needed on site",
        totalBuildingSiteMaterialsPrice: "Total price of materials needed on site (€)",
        additionalMaterial: "Additional material",
        additionalMaterialByTotal: "Select to enter costs for additional material price fields",
        totalAdditionalMaterialPrice: "Total price of additional material (€)",
        additionalMaterialQuantity: "Quantity of additional material (m²)",
        additionalMaterialUnitPrice: "Additional material unit price (€/m²)",
        buildingSiteDuration: "Total hours on site",
        laborByTotal: "Select to enter costs per labour price field",
        totalLaborPrice: "Total labour price (€)",
        baseLaborByTotal: "Select to enter costs for basic labour price fields",
        totalBaseLaborPrice: "Basic labour price (€)",
        baseLaborHours: "Basic labour hours (h)",
        baseLaborHourPrice: "Price per hour basic labour (€/h)",
        skilledLaborByTotal: "Select to enter costs for specialised labour price fields",
        totalSkilledLaborPrice: "Price of specialised labour (€)",
        skilledLaborHours: "Specialised labour hours (h)",
        skilledLaborHourPrice: "Price per hour skilled labour (€/h)",
        toolsByTotal: "Select to enter costs for tool price fields",
        totalToolsPrice: "Total price of working tools (€)",
        craneByTotal: "Select to enter costs per crane price field",
        totalTowerCranePrice: "Total crane price (€)",
        towerCraneHours: "Hours of crane use (h)",
        towerCraneHourPrice: "Hourly price for crane use (€/h)",
        elevatorPlatformByTotal: "Select to enter costs per price field for vertical lift platforms",
        totalElevatorPlatformPrice: "Total price vertical lift platforms (€)",
        elevatorPlatformHours: "Hours of use of lifting platforms (h)",
        elevatorPlatformHourPrice: "Hourly price of levelling platforms (€/h)",
        aerialPlatformByTotal: "Select to enter costs per aerial platform price field",
        totalAerialPlatformPrice: "Total price of aerial platforms (€)",
        aerialPlatformHours: "Hours of use of aerial platforms (h)",
        aerialPlatformHourPrice: "Hourly price of aerial platforms (€/h)",
        scaffoldingByTotal: "Select to enter costs per scaffolding price field",
        totalScaffoldingPrice: "Total scaffolding price (€)",
        scaffoldingSelect: "Calculation per unit",
        scaffoldingSurface: "Area covered by scaffolding (m²)",
        scaffoldingSurfaceUnitPrice: "Scaffolding unit price per surface area (€/m²)",
        scaffoldingHours: "Hours of scaffolding use (h)",
        scaffoldingHourPrice: "Hourly price of scaffolding (€/h)",
        otherByTotal: "Select to enter costs for other infrastructure price fields",
        totalOtherPrice: "Total price other infrastructure (€)",
        otherHours: "Hours of use of other equipment (h)",
        otherHourPrice: "Hourly price of other equipment (€/h)",
        securityByTotal: "Select to enter costs for safety price fields and site safety management",
        totalSecurityPrice: "Total price safety and security management on site (€)",
        staffByTotal: "Select to enter costs for personnel price fields to manage security",
        buildingSiteStaffByTotal: "Select to enter costs for personnel price fields to manage the site",
        totalStaffSecurityPrice: "Price of personnel to manage security (€)",
        staffSecurityHours: "Working hours for safety management (h)",
        staffSecurityHourPrice: "Hourly staff price (€/h)",
        totalStaffBuildingSitePrice: "Price of personnel to manage the site (€)",
        staffBuildingSiteHours: "Working hours for site management (h)",
        staffBuildingSiteHourPrice: "Hourly staff price (€/h)",
        taxes: "Fees and taxes (e.g. occupation of public land, other....)",
        taxCost: "Cost (€)",
        other: "Other items ? (feedback from companies)",
        kpiTitle: "KPIs PRICE OF CONSTRUCTION AT THE BUILDING SITE (only one type of façade)",
        benchmarkTitle: "Comparison with Benchmark",
        kpiAllTitle: "KPIs PRICE OF CONSTRUCTION AT THE BUILDING SITE (different types of façade)",
        totalInstallationSecurityPrice: "Total façade construction price + safety and site management (€)",
        surface: "Façade surface (m²)",
        installationSecuritySurfaceRatio: "Construction price + security and management on façade area (€/m²)",
        installationSurfaceRatio: "Façade installation price on façade area (€/m²)",
        securitySurfaceRatio: "Construction site safety and security price on façade area (€/m²)",
        installationTotalRatio: "Ratio Installation Price to Total Construction Price (inc. safety)",
        securityTotalRatio: "Ratio of safety price and safety management to total construction price (inc. safety)",
        buildingSiteTotal: "Construction site total cost (€)",
        buildingSiteTotalKpi: "Construction site cost KPI (€/m²)"
      },
      serviceLife: {
        annualLifeCost: "Total operating price (€/intervention)",
        annualLifeCostCalculation: "Select to enter costs for operational total price fields",
        annualIntegratedImplantsCost: "Price of annual consumption of integrated plant systems (€/intervention)",
        annualIntegratedImplantsCostCalculation: "Select to enter costs for annual consumption price fields of integrated plant systems",
        annualShieldingsCost: "Price of annual shading system consumption (€/intervention)",
        annualShieldingCostsCalculation: "Select to enter costs for price fields of the annual consumption of the shading system",
        shieldingsNumber: "Number of shielding systems (u)",
        annualShieldingsConsume: "Annual single system consumption (kwh/u)",
        energeticShieldingVectorUnitPrice: "Unit price of energy carrier (€/kwh)",
        annualVentilationCost: "Price of annual ventilation system consumption (€/intervention)",
        annualVentilationCostsCalculation: "Select to enter costs for price fields of the annual consumption of the ventilation system",
        ventilationsNumber: "Number of ventilation units (u)",
        annualVentilationConsume: "Single ventilation system annual consumption (kwh/u)",
        energeticVentilationVectorUnitPrice: "Unit price of energy carrier (€/kwh)",
        annualConditioningCost: "Price of annual air-conditioning system consumption (€/intervention)",
        annualConditioningCostsCalculation: "Select to enter costs for annual air-conditioning system consumption price fields",
        conditioningNumber: "Number of air conditioning devices (u)",
        annualConditioningConsume: "Individual air-conditioning system annual consumption (kwh/u)",
        energeticConditioningVectorUnitPrice: "Unit price of energy carrier (€/kwh)",
        annualThermicSolarCost: "Price of annual solar thermal system consumption (€/intervention)",
        annualThermicSolarCostsCalculation: "Select to enter costs for solar thermal system annual consumption price fields",
        thermicSolarNumber: "Number of solar thermal panels (u)",
        annualThermicSolarConsume: "Single solar thermal panel annual consumption (kwh/u)",
        energeticThermicSolarVectorUnitPrice: "Unit price of energy carrier (€/kwh)",
        annualPhotovoltaicIsActive: "Inclusion of integrated photovoltaic system annual production",
        annualPhotovoltaicCost: "Annual production of integrated photovoltaic system (€/intervention)",
        annualPhotovoltaicCostsCalculation: "Select to enter costs for integrated photovoltaic system annual production fields",
        photovoltaicNumber: "Number of photovoltaic modules (u)",
        annualPhotovoltaicConsume: "Single photovoltaic module annual production (kWh/u)",
        energeticPhotovoltaicVectorUnitPrice: "Unit price of energy carrier (€/kwh)",
        otherImplant: "Other pre-existing plant system",
        addOtherImplant: "Add pre-existing plant system",
        annualOtherImplantsCost: "Price of annual consumption of other pre-existing plant systems (€/intervention)",
        annualOtherImplantsCostCalculation: "Select to enter costs for price fields of the annual consumption of other pre-existing plant systems",
        annualImplantCost: "Price of annual consumption (€/intervention)",
        annualImplantCostCalculation: "Select to enter costs for annual consumption price fields",
        annualImplantConsume: "Single annual consumption (energy carrier/u)",
        energeticImplantVectorUnitPrice: "Unit price of carrier (€/energy carrier)"
      },
      maintenance: {
        refLife: "Project Reference Life (years)",
        totalMaintenancePercentage: "Enter the percentage of the total maintenance price compared to the total production cost (%)",
        totalExtraordinaryMaintenancePercentage: "Enter the percentage of the extraordinary maintenance price compared to the total production cost (%)",
        totalMaintenancePrice: "Total maintenance price including installations - calculated (€/year)",
        maintenanceSelect: "Select input type",
        totalOrdinaryMaintenancePrice: "Routine maintenance - calculated (€/years chosen)",
        totalOrdinaryMaintenancePriceIsTotal: "Select input type",
        totalOrdinaryMaintenancePercentage: "Enter the percentage of the ordinary maintenance price with respect to the total production cost (%)",
        totalWindowReplacementPrice: "Replacement of entire window (€/intervention)",
        totalWindowReplacementPriceIsTotal: "Select to enter costs per field of the replacement price of an entire window",
        windowsNumber: "Quantity of windows (u)",
        windowUnitPrice: "Unit price windows (€/u)",
        totalOpaqueLoomFixingsReplacementPrice: "Replacement of window components - frame and fixing (€/intervention)",
        totalOpaqueLoomFixingsReplacementPriceIsTotal: "Select to enter costs for frame and fastener replacement price fields",
        opaqueLoomFixingsQuantity: "Frame and fastening quantity (u)",
        opaqueLoomFixingsUnitPrice: "Unit price frame and fixing (€/u)",
        totalOpaqueDoubleGlazingReplacementPrice: "Replacement of window components - double glazing (€/intervention)",
        totalOpaqueDoubleGlazingReplacementPriceIsTotal: "Select to enter costs per field of the price of replacement double glazing",
        opaqueDoubleGlazingQuantity: "Quantity of double glazing (u)",
        opaqueDoubleGlazingUnitPrice: "Unit price double glazing (€/u)",
        totalOpaqueShieldingsReplacementPrice: "Replacement of window components - shading system (€/intervention)",
        totalOpaqueShieldingsReplacementPriceIsTotal: "Select to enter costs per field of the shielding system replacement price",
        opaqueShieldingsQuantity: "Quantity shielding system (u)",
        opaqueShieldingsUnitPrice: "Unit price shielding system (€/u)",
        totalExternalCladdingReplacementPrice: "Opaque casing - replacement of external cladding (€/intervention)",
        totalExternalCladdingReplacementPriceIsTotal: "Select to enter costs per price field for replacement of outer cladding",
        externalCladdingQuantity: "Total surface area exterior cladding (m²)",
        externalCladdingUnitPrice: "Unit price exterior cladding (€/m²)",
        totalInsulationReplacementPrice: "Opaque envelope - insulation replacement (€/intervention)",
        totalInsulationReplacementPriceIsTotal: "Select to enter costs per price field for insulation replacement",
        insulationSurface: "Insulation surface (m²)",
        insulationUnitPrice: "Unit price insulation (€/m²)",
        totalSupportingElementsReplacementPrice: "Opaque casing - load-bearing element (€/intervention)",
        totalSupportingElementsReplacementPriceIsTotal: "Select to enter costs per field of the replacement price of the carrier element",
        supportingElementsQuantity: "Quantity of load-bearing element (m3)",
        supportingElementUnitPrice: "Unit price load-bearing element (€/m3)",
        totalPrefabricatedModulesReplacementPrice: "Opaque casing - prefabricated module replacement (€/intervention)",
        totalPrefabricatedModulesReplacementPriceIsTotal: "Select to enter costs per prefabricated module replacement price field",
        prefabricatedModulesQuantity: "Quantity of prefabricated modules (u)",
        prefabricatedModulesUnitPrice: "Module price per sqm (€/m²)",
        totalGlazedCellsReplacementPrice: "Transparent casing - replacement of glazing cells (€/intervention)",
        totalGlazedCellsReplacementPriceIsTotal: "Select to enter costs per price field for glass cell replacement",
        glazedCellsNumber: "Transparent casing - number of glazed cells (u)",
        glazedCellUnitPrice: "Unit price glazed cell (€/u)",
        totalDoubleGlazingReplacementPrice: "Transparent casing - replacement of double glazing (€/intervention)",
        totalDoubleGlazingReplacementPriceIsTotal: "Select to enter costs per field of the price of replacement double glazing",
        doubleGlazingQuantity: "Transparent casing - quantity of double glazing (u)",
        doubleGlazingUnitPrice: "Unit price double glazing (€/u)",
        totalTransparentLoomFixingsReplacementPrice: "Transparent casing - frame replacement or fixings (€/intervention)",
        totalTransparentLoomFixingsReplacementPriceIsTotal: "Select to enter costs for frame or fixture replacement price fields",
        transparentLoomFixingsQuantity: "Quantity frame or fasteners (u)",
        transparentLoomFixingsUnitPrice: "Unit price frame or fixings (€/u)",
        totalTransparentShieldingsReplacementPrice: "Transparent casing - shielding system (€/intervention)",
        totalTransparentShieldingsReplacementPriceIsTotal: "Select to enter costs per field of the shielding system replacement price",
        transparentShieldingsQuantity: "Quantity shielding system (u)",
        transparentShieldingsUnitPrice: "Unit price shielding system (€/u)",
        totalPhotovoltaicModulesReplacementPrice: "Plant Components - Integrated Photovoltaic Modules (€/intervention)",
        totalPhotovoltaicModulesReplacementPriceIsTotal: "Select to enter costs per integrated photovoltaic module replacement price fields",
        photovoltaicModulesNumber: "Number of photovoltaic modules (u)",
        photovoltaicModuleUnitPrice: "Photovoltaic module unit price (€/u)",
        totalSolarPanelsReplacementPrice: "System components - thermal solar panels (€/intervention)",
        totalSolarPanelsReplacementPriceIsTotal: "Select to enter costs per price field for solar thermal panel replacement",
        solarPanelsNumber: "Number of solar thermal panels (u)",
        solarPanelUnitPrice: "Unit price solar thermal module (€/u)",
        totalVentilationDevicesReplacementPrice: "Plant components - ventilation equipment (€/intervention)",
        totalVentilationDevicesReplacementPriceIsTotal: "Select to enter costs per price field for replacement of ventilation equipment",
        ventilationDevicesNumber: "Number of ventilation units (u)",
        ventilationDeviceUnitPrice: "Unit price ventilation equipment (€/u)",
        totalAirConditioningDevicesReplacementPrice: "Plant components - air conditioning (€/intervention)",
        totalAirConditioningDevicesReplacementPriceIsTotal: "Select to enter costs per air conditioning replacement price field",
        airConditioningDevicesNumber: "Number of air conditioning devices (u)",
        airConditioningDeviceUnitPrice: "Unit price air conditioning systems (€/u)",
        totalCleaningPrice: "Cleaning prices (€/year)",
        totalCleaningPriceIsTotal: "Select to enter costs for cleaning price fields",
        cleaningsEveryHowManyYears: "Cleaning period (every how many years they are carried out)",
        cleaningUnitPrice: "Unit price cleaning intervention - entire façade (€/u)",
        totalExtraordinaryMaintenancePrice: "Extraordinary maintenance - calculated (€/intervention)",
        totalExtraordinaryMaintenancePriceIsTotal: "Select input type",
        everyHowManyYears: "Maintenance period (every how many years it is carried out)",
        actionName: "Action name",
        actionValue: "Share value (€)"
      },
      costsActualization: {
        interestRate: "Interest rate (%)",
        priceVectorVariation: "Rate of change in energy carrier price (%)"
      },
      endOfLife: {
        demolitionAndDisassembly: "Aggregate demolition and dismantling",
        totalDemolitionAndDisassemblyPrice: "Total price for demolition and dismantling (€)",
        totalDemolitionAndDisassemblyPriceIsTotal: "Select to enter costs for demolition and dismantling price fields",
        surfaceDemolitionOrDisassembly: "Total surface area of dismantled or demolished façade (m²)",
        demolition: "Demolition",
        totalDemolitionPrice: "Total demolition price (€)",
        totalDemolitionPriceIsTotal: "Select to enter costs for demolition price fields",
        demolitionQuantity: "Demolition quantity (m²)",
        demolitionUnitPrice: "Unit price demolition (€/m²)",
        disassembly: "Dismantling",
        totalDisassemblyPrice: "Disassembly price (€)",
        totalDisassemblyPriceIsTotal: "Select to enter costs for disassembly price fields",
        opaqueDisassembly: "Dismantling of opaque casing",
        totalWindowsDisassemblyPrice: "Total price window dismantling (€)",
        totalWindowsDisassemblyPriceIsTotal: "Select to enter costs per window dismantling price field",
        windowsQuantity: "Quantity of windows (u)",
        windowsUnitPrice: "Unit price window dismantling (€/u)",
        totalExternalCladdingDisassemblyPrice: "Total price for dismantling outer cladding (€)",
        totalExternalCladdingDisassemblyPriceIsTotal: "Select to enter costs per price field for dismantling the outer cladding",
        externalCladdingQuantity: "Exterior cladding quantity (m²)",
        externalCladdingUnitPrice: "Unit price for dismantling external cladding (€/m²)",
        totalInsulationDisassemblyPrice: "Total price dismantling insulation (€)",
        totalInsulationDisassemblyPriceIsTotal: "Select to enter costs per field of the price of dismantling insulation",
        insulationQuantity: "Insulation quantity (m²)",
        insulationUnitPrice: "Unit price dismantling insulation (€/m²)",
        totalSupportingElementsAndFixingsDisassemblyPrice: "Total price bearing panel dismantling and fixing (€)",
        totalSupportingElementsAndFixingsDisassemblyPriceIsTotal: "Select to enter costs per price field for disassembly of carrier panel and fixing",
        supportingElementsAndFixingsQuantity: "Quantity of load-bearing panel (m²)",
        supportingElementsAndFixingsUnitPrice: "Unit price for dismantling load-bearing panel (€/m²)",
        transparentDisassembly: "Dismantling of transparent casing",
        totalGlazedCellsDisassemblyPrice: "Total price for dismantling glazed cells (€)",
        totalGlazedCellsDisassemblyPriceIsTotal: "Select to enter costs per price field for dismantling glass cells",
        glazedCellsQuantity: "Quantity of glazed cells (u)",
        glazedCellsUnitPrice: "Unit price for dismantling glazed cells (€/u)",
        totalDoubleGlazingDisassemblyPrice: "Total price for removal of double glazing (€)",
        totalDoubleGlazingDisassemblyPriceIsTotal: "Select to enter costs per price field for removal of double glazing",
        doubleGlazingQuantity: "Quantity of double glazing (u)",
        doubleGlazingUnitPrice: "Unit price for removal of double glazing (€/u)",
        totalUprightsCrosspiecesFixingsDisassemblyPrice: "Total price dismantling uprights, crossbars and fixings (€)",
        totalUprightsCrosspiecesFixingsDisassemblyPriceIsTotal: "Select to enter costs per price field for dismantling uprights, crossbars and fasteners",
        uprightsCrosspiecesFixingsQuantity: "Quantity of mullions and transoms (m)",
        uprightsCrosspiecesFixingsUnitPrice: "Unit price for dismantling uprights and crossbars (€/m)",
        transport: "Transport",
        totalTransportPrice: "Total transport price (€)",
        totalTransportPriceIsTotal: "Select to enter costs for transport price fields",
        totalDisposalTransportPrice: "Transport price to disposal/dump site (€)",
        totalDisposalTransporPriceIsTotal: "Select to enter costs for transport price fields to disposal/dump site",
        disposalTransportQuantity: "Transport hours (h)",
        disposalTransportUnitPrice: "Hourly transport price (€/h)",
        totalRecyclingTransportPrice: "Price of transport to the recycling/production site",
        totalRecyclingTransporPriceIsTotal: "Select to enter costs for transport price fields at the recycling/production site",
        recyclingTransportQuantity: "Transport hours (h)",
        recyclingTransportUnitPrice: "Hourly transport price (€/h)",
        recycling: "Recycling",
        totalRecyclingRevenue: "Revenues generated by the recycling practice (€)",
        totalRecyclingRevenueKpi: "Revenues generated by the recycling practice (-€)",
        totalRecyclingRevenueIsTotal: "Select to enter costs for revenue fields generated by recycling",
        totalWindowsRecyclingRevenue: "Revenues from window recycling (€)",
        totalWindowsRecyclingRevenueIsTotal: "Select to enter costs for revenue fields generated by window recycling",
        windowsRecyclingQuantity: "Quantity of windows (u)",
        windowsRecyclingUnitPrice: "Unit revenue per window recycling (€/u)",
        totalExternalCladdingRecyclingRevenue: "Revenues from external coating recycling (€)",
        totalExternalCladdingRevenueIsTotal: "Select to enter costs for revenue fields generated by the recycling of the outer coating",
        externalCladdingRecyclingQuantity: "Exterior cladding quantity (m²)",
        externalCladdingRecyclingUnitPrice: "Unit revenue for external coating recycling (€/m²)",
        totalInsulationRecyclingRevenue: "Revenues from recycling insulation (€)",
        totalInsulationRevenueIsTotal: "Select to enter costs for revenue fields generated by the recycling of insulation",
        insulationRecyclingQuantity: "Insulation quantity (m²)",
        insulationRecyclingUnitPrice: "Unit revenue for recycling insulation (€/m²)",
        totalSupportingElementsAndFixingsRecyclingRevenue: "Revenues from recycling bearing panel and fixing (€)",
        totalSupportingElementsAndFixingsRecyclingRevenueIsTotal: "Select to enter costs for revenue fields generated by the recycling of the carrier panel and the fastener",
        supportingElementsAndFixingsRecyclingQuantity: "Quantity of bearing panel and fastening (m²)",
        supportingElementsAndFixingsRecyclingUnitPrice: "Unit revenue for recycling load-bearing panel and fixing (€/m²)",
        totalGlazedCellsRecyclingRevenue: "Revenues from recycling glass cells",
        totalGlazedCellsRecyclingRevenueIsTotal: "Select to enter costs for revenue fields generated by the recycling of glass cells",
        glazedCellsRecyclingQuantity: "Quantity of glazed cells (u)",
        glazedCellsUnitRecyclingPrice: "Unit revenue per glass cell recycling (€/u)",
        totalDoubleGlazingRecyclingRevenue: "Recycling revenues",
        totalDoubleGlazingRecyclingRevenueIsTotal: "Select to enter costs for revenue fields generated by the recycling of double-glazing",
        doubleGlazingRecyclingQuantity: "Quantity of double glazing (u)",
        doubleGlazingRecyclingUnitPrice: "Unit revenue for double-glazing recycling (€/u)",
        totalUprightsCrosspiecesFixingsRecyclingRevenue: "Recycling of uprights, crossbars and fasteners",
        totalUprightsCrosspiecesFixingsRecyclingRevenueIsTotal: "Select to enter costs for revenue fields generated by the recycling of mullions and transoms",
        uprightsCrosspiecesFixingsRecyclingQuantity: "Quantity of mullions and transoms (m)",
        uprightsCrosspiecesFixingsRecyclingUnitPrice: "Unit revenue for recycling uprights and crossbars (€/m)",
        ReUse: "Reuse",
        totalReUseRevenue: "Revenues generated by the reuse practice (€)",
        totalReUseRevenueIsTotal: "Select to enter costs for revenue fields generated by reuse",
        totalWindowsReUseRevenue: "Revenues from reuse of windows (€)",
        totalWindowsReUseRevenueIsTotal: "Select to enter costs for revenue fields generated by the reuse of windows",
        windowsReUseQuantity: "Quantity of windows (u)",
        windowsReUseUnitPrice: "Unit revenue per window reuse (€/u)",
        totalExternalCladdingReUseRevenue: "Revenues from re-use of external cladding (€)",
        totalExternalCladdingReUseRevenueIsTotal: "Select to enter costs for revenue fields generated by the reuse of the outer cladding",
        externalCladdingReUseQuantity: "Exterior cladding quantity (m²)",
        externalCladdingReUseUnitPrice: "Unit revenue for re-use of external cladding (€/m²)",
        totalInsulationReUseRevenue: "Revenues from reused insulation (€)",
        totalInsulationReUseRevenueIsTotal: "Select to enter costs for revenue fields generated by the reuse of insulation",
        insulationReUseQuantity: "Insulation quantity (m²)",
        insulationReUseUnitPrice: "Unit revenue for insulation reuse (€/m²)",
        totalSupportingElementsAndFixingsReUseRevenue: "Revenues from reuse of bearing panel and fixing (€)",
        totalSupportingElementsAndFixingsReUseRevenueIsTotal: "Select to enter costs for revenue fields generated by the reuse of the carrier panel and fastening",
        supportingElementsAndFixingsReUseQuantity: "Quantity of bearing panel and fastening (m²)",
        supportingElementsAndFixingsReUseUnitPrice: "Unit revenue for reuse of bearing panel and fixing (€/m²)",
        totalGlazedCellsReUseRevenue: "Revenues from reuse of glass cells",
        totalGlazedCellsReUseRevenueIsTotal: "Select to enter costs for revenue fields generated by the reuse of glass cells",
        glazedCellsReUseQuantity: "Quantity of glazed cells (u)",
        glazedCellsUnitReUsePrice: "Unit revenue for reuse of glazed cells (€/u)",
        totalDoubleGlazingReUseRevenue: "Revenues from reuse of double glazing",
        totalDoubleGlazingReUseRevenueIsTotal: "Select to enter costs for revenue fields generated by the reuse of double glazing",
        doubleGlazingReUseQuantity: "Quantity of double glazing (u)",
        doubleGlazingReUseUnitPrice: "Unit revenue for reuse of double glazing (€/u)",
        totalUprightsCrosspiecesFixingsReUseRevenue: "Reuse of uprights, crossbars and fasteners",
        totalUprightsCrosspiecesFixingsReUseRevenueIsTotal: "Select to enter costs for revenue fields generated by the reuse of uprights and crossbars",
        uprightsCrosspiecesFixingsReUseQuantity: "Quantity of mullions and transoms (m)",
        uprightsCrosspiecesFixingsReUseUnitPrice: "Unit revenue for reuse of uprights and crossbars (€/m)"
      }
    }
  },
  navbar: {
    projects: "Projects",
    users: "Users",
    profile: "Profile",
    logout: "Logout"
  },
  tablesHeaders: {
    chooseCategory: "Choose the category into which you want to move the projects",
    projectList: {
      id: "ID",
      name: "Name",
      type: "Project type",
      useDestination: "Intended use",
      constructionYear: "Year of construction",
      location: "State / Region / City"
    },
    report: {
      costForPhase: "Cost per phase",
      costForPhaseBenchmark: "Difference with benchmark - cost per stage",
      total: "Total",
      difference: "Difference",
      investmentPercentage: "Percentage on investment",
      lccPercentage: "Percentage on LCC investment",
      kpi: "KPI",
      kpiBenchmark: "KPIs - comparison with benchmarks",
      phase: "Phase",
      value: "Value"
    },
    totalActualization: "Discounted total costs",
    fancadePrices: "Facade design price",
    KPIFancadeDesign: "KPIs facade design price (only one type of facade)",
    generalComparison: "General Data",
    deltaAgainstBenchmark: "Comparison with benchmarks",
    KPIAllfancades: "KPIs facade design price (different types of facade)",
    materials: "Materials",
    otherMaterials: "Other materials",
    labor: "Labour",
    completeTable: "Costs per year"
  },
  tablesVoices: {
    costsActualization: {
      completeTable: "Costs per year",
      year: "Year",
      value: "Value",
      serviceOfLife: "Service life",
      maintenance: "Maintenance",
      yearlyMaintenance: "Annual maintenance cost (€)",
      yearlyServiceOfLife: "Annual service life cost (€)",
      refLife: "Service life",
      interestsRate: "Interest rate (%)",
      total: "Total",
      difference: "Difference with benchmark",
      serviceOfLifeDifference: "Difference Discounted Service Life Cost",
      maintenanceDifference: "Discounted maintenance cost difference",
      serviceOfLifePrice: "Discounted service life cost (€)",
      maintenancePrice: "Discounted maintenance cost (€)",
      allFancades: "Different types of facade"
    },
    report: {
      allFancadesTab: "All facades",
      benchmarkTab: "Benchmark comparisons",
      priceForPhase: {
        project: "Project",
        fancadeProduction: "Production",
        recycling: "Recycling",
        transportToConstructionSite: "Transport on site",
        installation: "Laying",
        serviceLife: "Service life (discounted)",
        maintenance: "Maintenance (up-to-date)",
        disposal: "Decommissioning",
        recyclingRevenues: "Recycling revenues",
        totalInvestment: "Total investment (year 0)",
        LCCInvestment: "Total LCC investment (year 0-end of life)"
      },
      kpi: {
        investment: "Investment (year 0)",
        investmentLCC: "LCC investment (year 0-end of life)",
        project: "Project costs",
        production: "Production costs",
        materialsProductionRatio: "Material-to-production cost ratio",
        laborProductionRatio: "Labour/production cost ratio",
        transport: "Transport costs",
        trasportPerKm: "Transport costs per km",
        installationOpera: "Laying costs",
        installation: "Installation costs",
        security: "Safety costs and site management",
        installationConstructionRatio: "Installation/construction cost ratio",
        securityConstructionRatio: "Safety/cost ratio",
        materalsInstallationRatio: "Material/installation ratio",
        laborInstallationRatio: "Labour/installation ratio",
        toolsInstallationRatio: "Machinery/installation ratio",
        tools: "Tooling costs",
        crane: "Crane/machinery ratio",
        elevatorPlatforms: "Lift table/machinery ratio",
        aerialPlatforms: "Aerial platforms/machinery ratio",
        scaffolding: "Scaffolding/machinery ratio",
        buildingSiteDuration: "Worksite duration",
        buildingSiteDurationPerM2: "Duration of building site per m²",
        maintenance: "Maintenance costs",
        ordinaryMaintenance: "Ordinary maintenance/maintenance report",
        extraordinaryMaintenance: "Extraordinary maintenance/installation report",
        cleaning: "Cleaning/maintenance ratio",
        endOfLife: "End-of-life costs",
        recyclingProductionRatio: "Recycling revenue/production cost ratio",
        materialsTotal: "Materials (production + construction)",
        materialsCost: "Material costs",
        laborTotal: "Labour (production + construction)",
        laborCost: "Labour costs",
        technicalParameters: "Physical parameters",
        surface: "Facade surface",
        wwr: "Window-to-Wall Ratio",
        uparete: "Wall thermal transmittance",
        ufinestra: "Window thermal transmittance",
        ufacciata: "Average thermal transmittance"
      },
      fancadeKPIs: {
        totalDesignPrice: "Total design price",
        fancadeSurface: "Facade surface",
        kpiDesignPrice: "KPI design price"
      },
      delta: {
        totalDesignCost: "Design total cost",
        designCostKPI: "Design cost KPI"
      },
      allFancades: {
        totalDesignPrice: "Total design price - all types of facade",
        fancadeSurface: "Facade surface - all types of facade",
        kpiDesignPrice: "KPI design price - all types of facade"
      },
      fancadeProduction: {
        totalProductionPrice: "Total production price"
      }
    }
  },
  titles: {
    newProject: "New Project Creation",
    calculatedSection: " - Fields for calculation",
    report: {
      projects: "Projects",
      title: "Results",
      projectDetails: "Project details"
    },
    details: {
      general: "General project data",
      fancadeTechnicalProject: "Facade design data",
      fancadeProject: "Facade design price",
      fancadeProduction: "Facade production",
      transport: "Transport",
      buildingSite: "Building Site",
      serviceLife: "Service life",
      maintenance: "Maintenance",
      endOfLife: "End of life",
      finalReport: "Final results",
      all: "All Sections"
    }
  },
  subHeadings: {
    fancadeProduction: {
      fancadeProductionPrice: "Facade production price",
      fancadeProductionKPIsOne: "KPIs facade production price (of this facade)",
      fancadeProductionKPIsAll: "KPIs facade production price (different types of facade)",
      benchmarkDifference: "Difference with benchmark"
    }
  },
  tabs: {
    summary: {
      report: "Results",
      generalPlots: "General Graphs",
      fancadeProductionPlots: "Production Charts",
      constructionPlots: "Construction Charts",
      maintenancePlots: "Maintenance Graphs"
    },
    details: {
      general: "General Data",
      fancadeTechnicalProject: {
        fancadeTechnicalProject: "Facade design data",
        firstTab: "Benchmark / Facade #1",
        benchmark: "Benchmark /",
        tabsAfterFirst: "Facade #"
      },
      fancadeProject: "Facade design price",
      fancadeProduction: "Facade production",
      transport: "Transport",
      buildingSite: "Building Site",
      serviceLife: "Service life",
      maintenance: "Maintenance",
      costsActualization: "Actualisation of costs",
      endOfLife: "End of life"
    }
  },
  plotTitles: {
    investmentPerPhase: "Investment cost per phase",
    unitInvestmentPerPhase: "Unit investment cost per phase",
    lccInvestmentPerPhase: "Discounted investment cost per phase - LCC",
    lccInvestmentPerPhaseBenchmarkPlotTitle: "Discounted investment cost per phase - LCC - Difference with benchmark",
    production: "Breakdown of facade production costs",
    buildingSiteScomposition: "Breakdown of façade construction costs",
    installationScomposition: "Breakdown of façade installation costs",
    toolsScomposition: "Breakdown of machinery costs",
    maintenance: "Breakdown of façade maintenance costs",
    materials: "Materials",
    labor: "Labour",
    installation: "Facade installation",
    security: "Construction site safety management",
    tools: "Machinery",
    crane: "Cranes",
    elevatorPlatforms: "Lifting platforms",
    aerialPlatforms: "Aerial platforms",
    scaffolding: "Scaffolding",
    extraordinaryMaintenance: "Extraordinary maintenance",
    cleaning: "Cleaning",
    ordinaryMaintenance: "Routine maintenance"
  },
  users: {
    id: "ID",
    nominative: "Name",
    email: "Email",
    role: "Role",
    password: "Password",
    manage: "Manage",
    delete: "Delete user",
    disable: "Disable user",
    enable: "Enable user",
    credentialsText: "Make a note of the following credentials, it will not be possible to display the password again in the future."
  },
  bimp: {
    importBIMP: "Import from BIM-P",
    exportBIMP: "Export to BIM-P",
    exportSuccess: "PDF exported",
    exportError: "Error in PDF export"
  }
};

export default en;