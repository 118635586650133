import React from "react";
import {
  Button,
  ButtonGroup,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../hooks/useStores";
import api from "../../../services/api";

import styled from "styled-components";
import { transparentize } from "polished";
import theme from "../../../theme/theme";
import { FaFilter } from "react-icons/fa";
import { GeneralData } from "../../GeneralDataForm";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toProject } from "../../../services/utils";

type Props = {
  onSubmit: (values: any) => any;
};
export const TopBar: React.FC<Props> = observer((props) => {
  const { t } = useTranslation();
  const { ui, projects } = useStores();
  const { isOpen, onOpen, onClose } = useDisclosure();

  ui.setActiveScreen("projects");
  const formValidationSchema = Yup.object().shape({
    name: Yup.string().required(t("errors.newProject.missingName")),
    location: Yup.string(),
    reportAuthor: Yup.string(),
    description: Yup.string().required(
      t("errors.newProject.missingDescription")
    ),
    type: Yup.string(),
    certification: Yup.string(),
    useDestination: Yup.string(),
    isBenchmark: Yup.boolean(),
  });

  const onImportFromBIMP = () => {
    let token = api.getSessionToken();
    var url = 'https://lcc.eurac.edu/BIM-P-integration/auth?token=' + token;

    window.open(url, "_blank", "noreferrer");
  }; 

  return (
    <Flex w={"100%"}>
      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior={"inside"}>
        <Formik
          initialValues={{
            name: "",
            type: "",
            useDestination: "",
            constructionYear: null,
            location: "",
            reportAuthor: "",
            description: "",
            certification: "",
          }}
          validationSchema={formValidationSchema}
          onSubmit={(values, { setSubmitting }) => {
            props.onSubmit(toProject(values));
            onClose();
          }}
        >
          {(props) => (
            <StyledForm>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>{t("titles.newProject")}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <GeneralData />
                </ModalBody>

                <ModalFooter>
                  <StyledButtonGrey mr={3} onClick={onClose}>
                    {t("buttons.close")}
                  </StyledButtonGrey>
                  <StyledButton type={"submit"}>
                    {t("buttons.createProject")}
                  </StyledButton>
                </ModalFooter>
              </ModalContent>
            </StyledForm>
          )}
        </Formik>
      </Modal>
      <StyledFlex w={"80%"}>
        <InputGroup>
          <InputLeftElement pointerEvents={"none"} children={<FaFilter />} />
          <Input
            variant={"filled"}
            type={"text"}
            placeholder={""}
            rounded={"500rem"}
            value={projects.filter}
            onChange={(e) => {
              projects.setFilter(e.target.value);
            }}
          />
        </InputGroup>
      </StyledFlex>
      <StyledFlex w={"20%"} marginLeft={"1%"} bg={""}>
        <ButtonGroup w={"100%"} spacing={"1rem"}>
          <StyledButtonOrange onClick={onImportFromBIMP} marginLeft={"1%"}>
            {t("bimp.importBIMP")}
          </StyledButtonOrange>
          <StyledButtonOrange onClick={onOpen} marginLeft={"1%"}>
            {t("buttons.new")}
          </StyledButtonOrange>
        </ButtonGroup>
      </StyledFlex>
    </Flex>
  );
});

const StyledFlex = styled(Flex)`
  margin-top: 2%;
`;

const StyledButtonOrange = styled(Button)`
  min-width: 5rem;
  border-radius: 500rem;
  color: ${(props) => (!props.disabled ? "white" : "black")};

  background-color: ${(props) =>
    !props.disabled ? theme.colors.euracOrange : transparentize(0.85, "grey")};

  &:hover {
    background-color: ${transparentize(0.5, theme.colors.euracOrange)};
  }
`;
const StyledForm = styled(Form)`
  width: 100%;
`;
const StyledButton = styled(Button)`
  color: white;
  background-color: ${theme.colors.euracOrange};
  border-radius: 500rem;

  &:hover {
    background-color: ${transparentize(0.5, theme.colors.euracOrange)};
  }
`;
const StyledButtonGrey = styled(Button)`
  width: 40%;
  min-width: 4rem;
  border-radius: 500rem;
  color: ${(props) => (!props.disabled ? "white" : "black")};

  background-color: ${(props) =>
    !props.disabled ? "grey" : transparentize(0.85, "grey")};

  &:hover {
    background-color: ${transparentize(0.5, "grey")};
  }
`;
