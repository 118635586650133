import React, { useCallback, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../../../../hooks/useStores";
import { Link, Redirect, useParams } from "react-router-dom";

import { Formik, Form, Field, FieldProps } from "formik";
import * as Yup from "yup";

import { ChevronDownIcon } from "@chakra-ui/icons";
import styled from "styled-components";
import { transparentize } from "polished";
import theme from "../../../../../../theme/theme";
import { AiFillFolder } from "react-icons/ai";
import { FormRow } from "../../../../../FormRow";
import {
  Fancade,
  FancadeTechnicalProjectType,
  Packaging,
  OpaqueWindow,
  SystemsIntegration,
  TransportType,
} from "../../../../../../types";
import { HiRefresh } from "react-icons/hi";
import { useEffect } from "react";
import { values } from "mobx";
import {
  calculatePackagingsTotal,
  calculateTotalLoomLength,
  calculateTotalMainFrameLength,
  calculateTransportTotal,
  calculateWindowOpaqueSurfaceRatio,
} from "../../../../../../services/utils";
import FieldGroup from "../../../../../FieldGroup";
import { SimpleFormGenerator } from "../../../../../SimpleFormGenerator";
import { FormikCheckbox } from "../../../../../FormikCheckbox";
import { Transport } from "./Transport";
import { Container } from "../../../../../Container";

interface RouteParams {
  projectId: string;
}

type props = {
  fancadeNumber: number;
  fancadeId: number;
  fancadeType: string | null;
  formikProps: any;
};

export const TransportForm: React.FC<props> = observer((props) => {
  const { t } = useTranslation();
  const { session, ui, projects } = useStores();
  const { projectId } = useParams<RouteParams>();
  const [update, setUpdate] = useState(false);

  ui.setActiveScreen("projects");

  const currentProject = useMemo(() => {
    return projects.projects.find((element) => {
      return element.id == parseInt(projectId);
    });
  }, [projects, projectId]);

  const currentFancade = currentProject?.fancades?.find((element) => {
    return +element.id == props.fancadeId;
  });

  const addNewPackaging = useCallback(
    (transport: TransportType) => {
      if (!transport.packagings) {
        transport.packagings = [];
      }

      transport.packagings?.push({
        totalPrice: null,
        totalPriceCalculation: null,
        unitsNumber: null,
        unitPrice: null,
      });

      if (currentProject && currentProject.fancades)
        currentProject.fancades[props.fancadeNumber - 1].transport.packagings =
          projects.setPackagingsToFancade(
            currentProject.id,
            props.fancadeId,
            transport.packagings
          );
    },

    [projects, currentProject]
  );

  const deletePackaging = useCallback(
    (transport: TransportType, index: number) => {
      transport.packagings?.splice(index, 1);
      if (currentProject && currentProject.fancades && transport.packagings)
        currentProject.fancades[props.fancadeNumber - 1].transport.packagings =
          projects.setPackagingsToFancade(
            currentProject.id,
            props.fancadeId,
            transport.packagings
          );
    },
    []
  );

  if (session.user)
    return (
      <Stack w={"100%"}>
        <FormikCheckbox
          checked={
            !(
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .transport?.totalTransportPriceCalculation &&
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .transport?.totalTransportPriceCalculation == 1
            )
          }
          defaultChecked={
            !(
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .transport?.totalTransportPriceCalculation &&
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .transport?.totalTransportPriceCalculation == 1
            )
          }
          onChange={() => {
            if (
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .transport
            ) {
              if (
                !props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .transport.totalTransportPriceCalculation ||
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .transport.totalTransportPriceCalculation == 0
              )
                props.formikProps.values.fancades[
                  props.fancadeNumber - 1
                ].transport.totalTransportPriceCalculation = 1;
              else
                props.formikProps.values.fancades[
                  props.fancadeNumber - 1
                ].transport.totalTransportPriceCalculation = 0;
              setUpdate(!update);
            }
          }}
        >
          {t(`formLabels.details.transport.totalTransportCheckbox`)}
        </FormikCheckbox>
        {!(
          props.formikProps.values.fancades[props.fancadeNumber - 1].transport
            .totalTransportPriceCalculation &&
          props.formikProps.values.fancades[props.fancadeNumber - 1].transport
            .totalTransportPriceCalculation == 1
        ) ? (
          <>
            <FormRow
              fields={[
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].transport.totalTransportPrice`,
                  label: t(`formLabels.details.transport.totalTransportPrice`),
                  placeholder: t(
                    `formPlaceholders.details.transport.totalTransportPrice`
                  ),
                  type: "number",
                  width: 100,
                },
              ]}
            />
          </>
        ) : (
          <Container
            title={
              t(`formLabels.details.transport.totalTransportPrice`) +
              t(`titles.calculatedSection`)
            }
            bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
            direction={"column"}
            spacing={"1rem"}
          >
            <FormRow
              fields={[
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].transport.totalTransportPrice`,
                  label: t(`formLabels.details.transport.totalTransportPrice`),
                  placeholder: t(
                    `formPlaceholders.details.transport.totalTransportPrice`
                  ),
                  type: "number",
                  width: 100,
                  disabled: true,
                  value: calculateTransportTotal(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                  ),
                },
              ]}
            />
            {/* Packaging */}
            <Container title={t("formLabels.details.transport.packagingContainer")}>
              <Stack w={"100%"}>
                {" "}
                <FormikCheckbox
                  checked={
                    !(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .transport?.totalPackagingPriceCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .transport?.totalPackagingPriceCalculation == 1
                    )
                  }
                  defaultChecked={
                    !(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .transport?.totalPackagingPriceCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .transport?.totalPackagingPriceCalculation == 1
                    )
                  }
                  onChange={() => {
                    if (
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .transport
                    ) {
                      if (
                        !props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].transport.totalPackagingPriceCalculation ||
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].transport.totalPackagingPriceCalculation == 0
                      )
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].transport.totalPackagingPriceCalculation = 1;
                      else
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].transport.totalPackagingPriceCalculation = 0;
                      setUpdate(!update);
                    }
                  }}
                >
                  {t(`formLabels.details.transport.totalPackagingCheckbox`)}
                </FormikCheckbox>
                {!(
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .transport.totalPackagingPriceCalculation &&
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .transport.totalPackagingPriceCalculation == 1
                ) ? (
                  <>
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].transport.totalPackagingPrice`,
                          label: t(
                            `formLabels.details.transport.totalPackagingPrice`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.transport.totalPackagingPrice`
                          ),
                          type: "number",
                          width: 100,
                        },
                      ]}
                    />
                  </>
                ) : (
                  <Container
                    title={
                      t(`formLabels.details.transport.totalPackagingPrice`) +
                      t(`titles.calculatedSection`)
                    }
                    bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                    direction={"column"}
                    spacing={"1rem"}
                  >
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].transport.totalPackagingPrice`,
                          label: t(
                            `formLabels.details.transport.totalPackagingPrice`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.transport.totalPackagingPrice`
                          ),
                          type: "number",
                          width: 100,
                          disabled: true,
                          value: calculatePackagingsTotal(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ]
                          ),
                        },
                      ]}
                    />

                    {currentFancade?.transport?.packagings?.map(
                      (packaging: Packaging, index) => (
                        <>
                          <Divider orientation="horizontal" />
                          <Flex w={"100%"} alignItems={"center"}>
                            <Text
                              fontSize={"2xl"}
                              color={theme.colors.darkGrey2}
                            >
                              {t("formLabels.details.transport.packageType")} #
                              {index + 1}
                            </Text>
                            <CloseIcon
                              color={theme.colors.euracOrange}
                              w={"0.7rem"}
                              marginLeft={"0.5rem"}
                              marginTop={"0.5rem"}
                              onClick={() =>
                                deletePackaging(
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ]?.transport,
                                  index
                                )
                              }
                            />
                          </Flex>
                          <FormikCheckbox
                            checked={
                              !(
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].transport?.packagings[index]
                                  .totalPriceCalculation &&
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].transport?.packagings[index]
                                  .totalPriceCalculation == 1
                              )
                            }
                            defaultChecked={
                              !(
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].transport?.packagings[index]
                                  .totalPriceCalculation &&
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].transport?.packagings[index]
                                  .totalPriceCalculation == 1
                              )
                            }
                            onChange={() => {
                              if (
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].transport
                              ) {
                                if (
                                  !props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].transport.packagings[index]
                                    .totalPriceCalculation ||
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].transport.packagings[index]
                                    .totalPriceCalculation == 0
                                )
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].transport.packagings[
                                    index
                                  ].totalPriceCalculation = 1;
                                else
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].transport.packagings[
                                    index
                                  ].totalPriceCalculation = 0;
                                setUpdate(!update);
                              }
                            }}
                          >
                            {t(
                              `formLabels.details.transport.totalPackagingCheckbox`
                            ) +
                              " #" +
                              (+index + 1)}
                          </FormikCheckbox>
                          {!(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].transport.packagings[index]
                              .totalPriceCalculation &&
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].transport.packagings[index]
                              .totalPriceCalculation == 1
                          ) ? (
                            <>
                              <FormRow
                                fields={[
                                  {
                                    name: `fancades[${
                                      props.fancadeNumber - 1
                                    }].transport.packagings[${index}].totalPrice`,
                                    label: t(
                                      `formLabels.details.transport.totalPackagingPrice`
                                    ),
                                    placeholder: t(
                                      `formPlaceholders.details.transport.totalPackagingPrice`
                                    ),
                                    type: "number",
                                    width: 100,
                                  },
                                ]}
                              />
                            </>
                          ) : (
                            <Container
                              title={
                                t(
                                  `formLabels.details.transport.totalPackagingPrice`
                                ) + t(`titles.calculatedSection`)
                              }
                              bodyStyle={{
                                backgroundColor: theme.colors.lightOrange,
                              }}
                              direction={"column"}
                              spacing={"1rem"}
                            >
                              <FormRow
                                fields={[
                                  {
                                    name: `fancades[${
                                      props.fancadeNumber - 1
                                    }].transport.packagings[${index}].totalPrice`,
                                    label: t(
                                      `formLabels.details.transport.totalPackagingPrice`
                                    ),
                                    placeholder: t(
                                      `formPlaceholders.details.transport.totalPackagingPrice`
                                    ),
                                    type: "number",
                                    width: 100,
                                    disabled: true,
                                    value:
                                      +props.formikProps.values.fancades[
                                        props.fancadeNumber - 1
                                      ].transport.packagings[index]
                                        .unitsNumber *
                                      +props.formikProps.values.fancades[
                                        props.fancadeNumber - 1
                                      ].transport.packagings[index].unitPrice,
                                  },
                                ]}
                              />

                              <FormRow
                                fields={[
                                  {
                                    name:
                                      "fancades[" +
                                      (props.fancadeNumber - 1) +
                                      "].transport.packagings[" +
                                      index +
                                      "].unitsNumber",
                                    label: t(
                                      "formLabels.details.transport.unitsNumber"
                                    ),
                                    placeholder: t(
                                      "formPlaceholders.details.transport.unitsNumber"
                                    ),
                                    type: "number",
                                    width: 100,
                                  },
                                  {
                                    name:
                                      "fancades[" +
                                      (props.fancadeNumber - 1) +
                                      "].transport.packagings[" +
                                      index +
                                      "].unitPrice",
                                    label: t(
                                      "formLabels.details.transport.unitPrice"
                                    ),
                                    placeholder: t(
                                      "formPlaceholders.details.transport.unitPrice"
                                    ),
                                    type: "number",
                                    width: 100,
                                  },
                                ]}
                              />
                            </Container>
                          )}
                        </>
                      )
                    )}
                    <Center paddingTop={"1rem"}>
                      <StyledButton
                        onClick={() =>
                          addNewPackaging(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ]?.transport
                          )
                        }
                      >
                        {t("buttons.addPackaging")}
                      </StyledButton>
                    </Center>
                  </Container>
                )}
              </Stack>
            </Container>

            {/* Transport part */}
            <Container title={t("formLabels.details.transport.transportContainer")}>
              <Stack w={"100%"}>
                <FormikCheckbox
                  checked={
                    !(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .transport?.totalTransportPartPriceCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .transport?.totalTransportPartPriceCalculation == 1
                    )
                  }
                  defaultChecked={
                    !(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .transport?.totalTransportPartPriceCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .transport?.totalTransportPartPriceCalculation == 1
                    )
                  }
                  onChange={() => {
                    if (
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .transport
                    ) {
                      if (
                        !props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].transport.totalTransportPartPriceCalculation ||
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].transport.totalTransportPartPriceCalculation == 0
                      )
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].transport.totalTransportPartPriceCalculation = 1;
                      else
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].transport.totalTransportPartPriceCalculation = 0;
                      setUpdate(!update);
                    }
                  }}
                >
                  {t(`formLabels.details.transport.totalTransportCheckbox`)}
                </FormikCheckbox>
                {!(
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .transport.totalTransportPartPriceCalculation &&
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .transport.totalTransportPartPriceCalculation == 1
                ) ? (
                  <>
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].transport.totalTransportPartPrice`,
                          label: t(
                            `formLabels.details.transport.totalTransportPartPrice`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.transport.totalTransportPartPrice`
                          ),
                          type: "number",
                          width: 100,
                        },
                      ]}
                    />
                  </>
                ) : (
                  <Container
                    title={
                      t(
                        `formLabels.details.transport.totalTransportPartPrice`
                      ) + t(`titles.calculatedSection`)
                    }
                    bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                    direction={"column"}
                    spacing={"1rem"}
                  >
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].transport.totalTransportPartPrice`,
                          label: t(
                            `formLabels.details.transport.totalTransportPartPrice`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.transport.totalTransportPartPrice`
                          ),
                          type: "number",
                          width: 100,
                          disabled: true,
                          value:
                            +props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].transport.workSiteProductionSiteDistance *
                            +props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].transport.transportPartUnitPrice,
                        },
                      ]}
                    />

                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].transport.workSiteProductionSiteDistance`,
                          label: t(
                            `formLabels.details.transport.workSiteProductionSiteDistance`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.transport.workSiteProductionSiteDistance`
                          ),
                          type: "number",
                          width: 100,
                        },
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].transport.transportPartUnitPrice`,
                          label: t(
                            `formLabels.details.transport.transportPartUnitPrice`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.transport.transportPartUnitPrice`
                          ),
                          type: "number",
                          width: 100,
                        },
                      ]}
                    />
                  </Container>
                )}
              </Stack>
            </Container>
          </Container>
        )}
      </Stack>
    );
  else return <Redirect to={"/"} />;
});

const StyledFlexHeader = styled(Flex)`
  border: 2px solid ${theme.colors.darkGrey};
  background-color: ${theme.colors.lightGrey2};
  color: ${theme.colors.euracOrange};
  padding: 0.8em;
`;

const StyledFlexBody = styled(Flex)`
  border: 2px solid ${theme.colors.darkGrey};
  border-top: 0px;
  padding: 0.8em;
`;

const StyledTab = styled(Tab)`
  color: ${theme.colors.euracOrange};
  border-radius: 500rem;
`;
const StyledButton = styled(Button)`
  color: white;
  background-color: ${theme.colors.euracOrange};
  border-radius: 500rem;

  &:hover {
    background-color: ${transparentize(0.5, theme.colors.euracOrange)};
  }
`;
