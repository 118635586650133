import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  CircularProgress,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Spacer,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import React, { useCallback, useState } from "react";
import { Center, Flex, Text } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../hooks/useStores";

import styled from "styled-components";
import { transparentize } from "polished";
import theme from "../../../theme/theme";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { Category, Project } from "../../../types";
import { SecondaryButton } from "../../Button";
import { UpdateCategoryForm } from "../../UpdateCategoryForm";
import { DeleteAlert } from "../../Alert";

type Props = {
  filter?: string;
};
export const ProjectsTable: React.FC<Props> = observer((props: Props) => {
  const { t } = useTranslation();
  const { session, ui, projects } = useStores();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [update, setUpdate] = useState(false);
  const [isDeleteCategoryOpen, setIsDeleteCategoryOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState<
    Category | undefined
  >();
  const toast = useToast();
  const history = useHistory();

  ui.setActiveScreen("projects");

  const handleLink = useCallback((id: number) => {
    if (session.user) {
      history.push({
        pathname: `/user/${session.user.id}/projects/${id}/summary`,
      });
    }
  }, []);

  const handleSelectedProjectsIds = useCallback((id: number) => {
    projects.handleSelectedProjectsIds(id);
  }, []);

  const isCheckboxChecked = (id: number) => {
    if (projects.selectedProjectsIds.has(id)) return true;
    return false;
  };

  const checkProject = (project: Project) => {
    if (props.filter == "all") return true;
    else if (props.filter == "admin") {
      if (session.user) return project.createdById == +session.user.id;
      else return false;
    } else if (props.filter == "users") {
      if (session.user) return project.createdById != +session.user.id;
      else return false;
    }
  };

  let rows: any = [];
  if (projects.isFetchingProjects) {
    return (
      <Center height={"100%"}>
        <CircularProgress
          size={"2em"}
          color={theme.colors.euracOrange}
          isIndeterminate
        />
      </Center>
    );
  }

  const filterProjects = (projectsToFilter: Project[]): Project[] => {
    let filteredProjects = [...projectsToFilter];
    if (projects.filter) {
      filteredProjects = filteredProjects.filter(
        (project) =>
          project.id
            .toString()
            .toLowerCase()
            .indexOf(projects.filter.toLowerCase()) != -1 ||
          project.name.toLowerCase().indexOf(projects.filter.toLowerCase()) !=
            -1 ||
          project.type.toLowerCase().indexOf(projects.filter.toLowerCase()) !=
            -1 ||
          project.useDestination
            .toLowerCase()
            .indexOf(projects.filter.toLowerCase()) != -1 ||
          project.constructionYear
            .toString()
            .toLowerCase()
            .indexOf(projects.filter.toLowerCase()) != -1
      );
    }

    if (props.filter) {
      filteredProjects = filteredProjects.filter(checkProject);
    }

    return filteredProjects;
  };

  const projectsToRows = (inputProjects: Project[]): any[] => {
    let toReturn: any[] = [];
    inputProjects.map((element) => {
      toReturn.push(
        <Tr
          onClick={() => {
            handleLink(element.id);
          }}
          style={{ cursor: "pointer" }}
        >
          <Td
            onClick={(e: any) => {
              e.stopPropagation();
            }}
            style={{ cursor: "default" }}
          >
            <StyledCheckbox
              colorScheme={"red"}
              isChecked={isCheckboxChecked(element.id)}
              onChange={() => handleSelectedProjectsIds(element.id)}
            />
          </Td>
          <StyledTd>{element.id}</StyledTd>
          <Td>{element.name}</Td>
          <Td>{element.type}</Td>
          <Td>{element.useDestination}</Td>
          <Td>{element.constructionYear}</Td>
        </Tr>
      );
    });
    return toReturn;
  };

  if (!projects.isFetchingProjects) {
    let filteredProjects: Project[] = filterProjects(
      projects.getCategoryProjects()
    );
    rows = projectsToRows(filteredProjects);
  }

  return (
    <Flex w={"99%"} overflowY={"scroll"} flexDirection={"column"}>
      <DeleteAlert
        title={t("warnings.deleteCategoryTitle")}
        body={t("warnings.deleteCategory")}
        isOpen={isDeleteCategoryOpen}
        setIsOpen={setIsDeleteCategoryOpen}
        cancelText={t("warnings.cancel")}
        continueText={t("warnings.confirm")}
        onContinue={async () => {
          if (currentCategory) await projects.deleteCategory(currentCategory);
          setCurrentCategory(undefined);
          toast({
            title: t("successes.deletes.categorySuccessful"),
            status: "success",
            duration: 4000,
            isClosable: true,
          });
          history.push("/");
        }}
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("buttons.addCategory")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <UpdateCategoryForm
              name={currentCategory ? currentCategory.name : ""}
              onSubmit={async (category: string) => {
                if (currentCategory) {
                  await projects.updateCategory({
                    id: currentCategory.id,
                    name: category,
                    projects: currentCategory.projects,
                  });
                  setCurrentCategory(undefined);
                  //setUpdate(!update);
                  onClose();
                  toast({
                    title: t("successes.updates.categorySuccessful"),
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                  });
                }
              }}
            />
          </ModalBody>

          <ModalFooter>
            <SecondaryButton onClick={onClose}>
              {t("buttons.close")}
            </SecondaryButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <StyledTable variant={"striped"} colorScheme={"blackAlpha"}>
        <Thead>
          <Tr>
            <Th></Th>
            <Th fontSize={"md"}>{t("tablesHeaders.projectList.id")}</Th>
            <Th fontSize={"md"}>{t("tablesHeaders.projectList.name")}</Th>
            <Th fontSize={"md"}>{t("tablesHeaders.projectList.type")}</Th>
            <Th fontSize={"md"}>
              {t("tablesHeaders.projectList.useDestination")}
            </Th>
            <Th fontSize={"md"}>
              {t("tablesHeaders.projectList.constructionYear")}
            </Th>
          </Tr>
        </Thead>
        <Tbody>{rows}</Tbody>
      </StyledTable>
      <Accordion defaultIndex={[0]} allowMultiple paddingBottom={"3rem"}>
        {projects.categories.map((category, index) => (
          <StyledTable variant={"striped"} colorScheme={"blackAlpha"}>
            <AccordionItem>
              <AccordionButton>
                <Text
                  textAlign={"left"}
                  fontSize={"2xl"}
                  paddingTop={"1rem"}
                  paddingBottom={"1rem"}
                >
                  {category.name}
                </Text>
                <AiFillEdit
                  style={{ width: "2rem" }}
                  cursor={"pointer"}
                  onClick={(e) => {
                    setCurrentCategory(category);
                    e.stopPropagation();
                    onOpen();
                  }}
                />
                <AiFillDelete
                  style={{ width: "2rem" }}
                  cursor={"pointer"}
                  onClick={async (e) => {
                    e.stopPropagation();
                    setCurrentCategory(category);
                    setIsDeleteCategoryOpen(true);
                  }}
                />
                <Spacer />
                <AccordionIcon marginRight={"1rem"} />
              </AccordionButton>
              <AccordionPanel>
                <StyledTable variant={"striped"} colorScheme={"blackAlpha"}>
                  <Tbody>
                    {projectsToRows(
                      filterProjects(projects.getCategoryProjects(category))
                    )}
                  </Tbody>
                </StyledTable>
              </AccordionPanel>
            </AccordionItem>
          </StyledTable>
        ))}
      </Accordion>
    </Flex>
  );
});

const StyledTable = styled(Table)`
  margin-top: 2%;
`;

const StyledCheckbox = styled(Checkbox)`
  border-color: ${transparentize(0.5, theme.colors.euracOrange)};
`;

const StyledTd = styled(Td)`
  color: ${theme.colors.euracOrange};
  font-weight: 600;
  cursor: pointer;
`;
