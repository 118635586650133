import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";

export type TableRow = {
  name?: string;
  values: string[] | number[];
  widths?: number[];
  variation?: "highlight" | undefined;
};

type Props = {
  columnsTitles: string[];
  rows: TableRow[];
  variation?: "alternate" | undefined;
  style?: any;
};

export const PDFTable: React.FC<Props> = (props) => {
  let rows: any = [];
  let colSpan = 0;

  let propRows: TableRow[] = [...props.rows];
  if (props.columnsTitles.length > 0)
    propRows.unshift({ values: props.columnsTitles });

  if (propRows && propRows.length > 0) {
    let index = 1;

    propRows.map((row, index) => {
      let widths: number[] = [];
      if (row.widths) widths = row.widths;
      else {
        //Calculate the width of every cell of the row
        let sum = 0;
        let divideFor = row.values.length == 0 ? 1 : row.values.length;
        if (row.name) {
          divideFor += 1;
          widths.push(+(100 / divideFor).toFixed());
          sum += widths[widths.length - 1];
        }
        for (let i = 0; i < row.values.length; i++) {
          widths.push(+(100 / divideFor).toFixed());
          sum += widths[widths.length - 1];
        }

        //The sum of the widths should be equal to 100
        if (sum < 100) {
          widths[0] += 100 - +sum;
        }
      }

      //Push the row
      rows.push(
        <View
          style={[
            row.variation === "highlight" ||
            (props.variation == "alternate" && index % 2 == 0)
              ? styles.highlightRow
              : styles.row,
            { borderTopWidth: index == 0 ? 1 : 0 },
          ]}
        >
          {row.name && (
            <Text
              style={{
                width: widths[0] + "%",
                textAlign: "center",
                height: "100%",
                borderRightWidth: 1,
                padding: 2,
              }}
            >
              {row.name}
            </Text>
          )}
          {row.values.map((value, index) => (
            <Text
              style={{
                width: widths[row.name ? index + 1 : index] + "%",
                textAlign: "center",
                height: "100%",
                borderRightWidth: index !== row.values.length - 1 ? 1 : 0,
                padding: 2,
              }}
            >
              {value}
            </Text>
          ))}
        </View>
      );

      index += 1;
    });
  }

  //If the table is empty, specify it
  if (rows.length == 0) {
    rows.push(
      <View style={[styles.row, { borderTopWidth: 1 }]}>
        <View
          style={{
            fontWeight: "semibold",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            width: "100%",
            padding: 2,
          }}
        >
          <Text>{"Empty table"}</Text>
        </View>
      </View>
    );
  }

  if (
    props.columnsTitles &&
    props.columnsTitles.length == 1 &&
    propRows &&
    propRows.length > 0
  )
    colSpan = propRows[0].values.length + 1;

  return <View style={[styles.tableContainer, props.style]}>{rows}</View>;
};

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 1,
    marginLeft: 10,
    marginRight: 10,
  },
  container: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: "black",
    borderBottomWidth: 1,
    alignItems: "center",
    textAlign: "center",
    flexGrow: 1,
    color: "black",
    fontSize: 15,
    margin: 0,
  },
  row: {
    flexDirection: "row",
    borderColor: "black",
    borderWidth: 1,
    alignItems: "center",
    justifyContent: "center",
    fontSize: 13,
    margin: 0,
  },
  highlightRow: {
    flexDirection: "row",
    borderColor: "black",
    backgroundColor: "#f9f5d9",
    borderWidth: 1,
    alignItems: "center",
    justifyContent: "center",
    fontSize: 13,
    margin: 0,
  },
  text: {
    textAlign: "center",
    fontSize: "lg",
  },
});
