import { Text } from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/modal";
import {
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tfoot,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Category } from "../types";
import { SecondaryButton } from "./Button";

type Props = {
  isVisible: boolean;
  categories: Category[];
  onSubmit: (data: any) => void;
  onDismiss: () => void;
};

export const ChangeProjectCategoryModal: React.FC<Props> = ({
  isVisible,
  categories,
  onSubmit,
  onDismiss,
}) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isVisible} size={"xl"} onClose={onDismiss}>
      <ModalOverlay />
      <ModalContent maxW={"820px"}>
        <ModalCloseButton />
        <ModalBody style={{ padding: "2rem" }}>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>{t("tablesHeaders.chooseCategory")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {categories.map((category) => (
                <Tr onClick={() => onSubmit(category.id)} cursor={"pointer"}>
                  <Td>{category.name}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <SecondaryButton colorScheme="blue" mr={3} onClick={onDismiss}>
            {t("buttons.close")}
          </SecondaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const StyledLinkText = styled(Text)`
  cursor: pointer;
  color: var(--chakra-colors-teal-600);
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0.5rem;
`;
