import React, { useCallback, useEffect, useMemo, useRef } from "react";
import {
  Button,
  ButtonGroup,
  Center,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../../hooks/useStores";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";

import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";

import {
  IoArrowBackCircleOutline,
  IoCalendarOutline,
  IoDocumentTextOutline,
  IoHomeOutline,
} from "react-icons/io5";
import styled from "styled-components";
import { transparentize } from "polished";
import theme from "../../../../theme/theme";
import { AiOutlineDollarCircle, AiOutlineUnorderedList } from "react-icons/ai";
import { GeneralData } from "../../../GeneralDataForm";
import { FancadeTechnicalProject } from "./tabs/FancadeTechnicalProject/FancadeTechnicalProject";
import { Project } from "../../../../types";
import { FancadeProject } from "./tabs/FancadeProject/FancadeProject";
import { FancadeProduction } from "./tabs/FancadeProduction/FancadeProduction";
import { Transport } from "./tabs/Transport/Transport";
import { BuildingSite } from "./tabs/BuildingSite/BuildingSite";
import { Maintenance } from "./tabs/Maintenance/Maintenance";
import { EndOfLife } from "./tabs/EndOfLife/EndOfLife";
import { DeleteAlert } from "../../../Alert";
import { CostsActualization } from "./tabs/CostsActualization/CostsActualization";
import { ServiceOfLife } from "./tabs/ServiceOfLife/ServiceOfLife";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { CopyFancadeModal } from "./CopyFancadeModal";
import { copyFancadeData } from "../../../../services/utils";

interface RouteParams {
  projectId: string;
}

export const Details: React.FC = observer((props) => {
  const { t } = useTranslation();
  const { session, ui, projects } = useStores();
  const { projectId } = useParams<RouteParams>();

  const [tabIndex, setTabIndex] = React.useState(0);
  const [sectionIndex, setSectionIndex] = React.useState(0);
  const [update, setUpdate] = React.useState(false);
  const [deleteProjectOpen, setDeleteProjectOpen] = React.useState(false);
  const [modifiedTabs, setModifiedTabs] = React.useState<number[]>([]);
  const [fancadeIndexToCopy, setFancadeIndexToCopy] = React.useState(0);
  const [isCopyFancadeModalOpen, setIsCopyFancadeModalOpen] =
    React.useState(false);

  const formRef = useRef<FormikProps<Project>>(null);
  const toast = useToast();
  const history = useHistory();

  ui.setActiveScreen("projects");
  const formValidationSchema = Yup.object().shape({});

  useEffect(() => {
    if (ui.projectModified) {
      const temp = [...modifiedTabs];
      if (temp.indexOf(sectionIndex) == -1) {
        temp.push(sectionIndex);
        setModifiedTabs(temp);
      }
    }
  }, [ui.projectModified, modifiedTabs, sectionIndex]);

  useEffect(() => {
    projects.getProjectFancades(parseInt(projectId)).then((fancades) => {});
  }, [update]);

  let currentProject = useMemo(() => {
    let currentProject = projects.projects.find((element) => {
      return +element.id == +projectId;
    });
    projects.getProjectFancades(parseInt(projectId)).then((fancades) => {
      if (currentProject) currentProject.fancades = fancades;
    });
    return currentProject;
  }, [projects, update]);

  const handleSubmit = useCallback(() => {
    if (formRef.current) {
      setModifiedTabs([]);
      ui.setProjectModified(false);
      formRef.current.handleSubmit();
    }
  }, [ui, projects, currentProject]);

  const getCopyMenuOptions = useCallback(
    (currentProject: Project): any[] => {
      if (!currentProject.fancades) return [];
      let toReturn: any[] = [];

      currentProject.fancades.map((fancade, index) => {
        if (index != tabIndex) {
          toReturn.push(
            <MenuItem
              onClick={() => {
                setFancadeIndexToCopy(index);
                setIsCopyFancadeModalOpen(true);
              }}
            >
              {t("selectOptions.copyFancadeOption") + (+index + 1)}
            </MenuItem>
          );
        }
      });
      return toReturn;
    },
    [tabIndex, fancadeIndexToCopy]
  );

  const onCopyFancadeDataSubmit = useCallback(
    (
      formikProps: FormikProps<Project>,
      section:
        | "all"
        | "fancadeTechnicalProject"
        | "fancadeProject"
        | "fancadeProduction"
        | "transport"
        | "buildingSite"
        | "serviceLife"
        | "maintenance"
        | "endOfLife"
    ) => {
      if (formikProps.values.fancades) {
        formikProps.values.fancades[tabIndex] = copyFancadeData(
          formikProps.values.fancades[fancadeIndexToCopy],
          formikProps.values.fancades[tabIndex],
          section
        );
      }
      //Change tab to update the form
      const oldSectionIndex = sectionIndex;
      if (sectionIndex === 0) setSectionIndex(1);
      else setSectionIndex(0);
      setSectionIndex(oldSectionIndex);

      setIsCopyFancadeModalOpen(false);
    },
    [isCopyFancadeModalOpen, tabIndex, fancadeIndexToCopy, sectionIndex]
  );

  const redirectToProjectSummary = useCallback(
    (projectId: string) => {
      if (session.user) {
        history.push(`/user/${session.user.id}/projects/${projectId}/summary`);
      }
    },
    [history]
  );

  if (session.user && currentProject)
    return (
      <Stack w={"98%"} h={"100vh"} marginLeft={"1%"}>
        <DeleteAlert
          title={t("warnings.deleteProjectTitle")}
          body={t("warnings.deleteProject")}
          isOpen={deleteProjectOpen}
          setIsOpen={setDeleteProjectOpen}
          cancelText={t("warnings.cancel")}
          continueText={t("warnings.confirm")}
          onContinue={async () => {
            if (currentProject) await projects.deleteProject(currentProject);
            toast({
              title: t("successes.deletes.projectSuccessful"),
              status: "success",
              duration: 4000,
              isClosable: true,
            });
            if (session.user)
              history.push({
                pathname: `/user/${session.user.id}/projects`,
              });
          }}
        />
        <Flex maxH={"12vh"}>
          <Flex alignItems={"center"} paddingTop={"1%"} w={"100%"}>
            <Link to={`/user/${session.user.id}/projects/${projectId}/summary`}>
              <IoArrowBackCircleOutline size={"2.3rem"} color={"#474747"} />
            </Link>
            <Link to={`/user/${session.user.id}/projects`}>
              <Text
                fontSize={"2xl"}
                textColor={theme.colors.euracOrange}
                marginLeft={"1rem"}
              >
                {t("titles.report.projects")}
              </Text>
            </Link>
            <Text
              fontSize={"2xl"}
              textColor={theme.colors.lightGrey}
              marginLeft={"0.5%"}
              cursor={"pointer"}
              onClick={() => redirectToProjectSummary(projectId)}
            >
              {" / " + currentProject?.name + " (#" + projectId + ")"}
            </Text>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                fontSize={"14px"}
                minW={"14rem"}
                marginRight={"1rem"}
                marginLeft={"auto"}
              >
                {t("selectOptions.copyFancade")}
              </MenuButton>
              <MenuList>{getCopyMenuOptions(currentProject)}</MenuList>
            </Menu>
            <ButtonGroup spacing={"1rem"}>
              <Button
                colorScheme={"gray"}
                rounded={"500rem"}
                onClick={() => {
                  setDeleteProjectOpen(true);
                }}
              >
                {t("buttons.deleteProject")}
              </Button>
              <StyledButton onClick={handleSubmit}>
                {t("buttons.save")}
              </StyledButton>
            </ButtonGroup>
          </Flex>
        </Flex>
        <Flex maxH={"88vh"}>
          <Tabs
            index={sectionIndex}
            variant={"unstyled"}
            w={"100%"}
            h={"100vh"}
            isLazy
            onChange={(index) => {
              setSectionIndex(index);
              ui.setProjectModified(false);
            }}
          >
            <Flex w={"100%"} h={"85vh"}>
              <Flex w={"25%"} marginRight={"1rem"}>
                <TabList alignItems={"flex-start"}>
                  <Stack
                    borderRight={"1px solid black"}
                    overflowY={"scroll"}
                    padding={"0.3rem"}
                  >
                    {/* <StyledTab
                      _selected={{
                        color: "white",
                        bg: theme.colors.euracOrange,
                      }}
                    >
                      <Center>
                        <Flex w={"1.7rem"}>
                          <IoDocumentTextOutline size={"1.7rem"} />
                        </Flex>
                        <Text fontSize={"lg"} paddingLeft={"0.2rem"} textAlign={"left"}>
                          {t("tabs.details.general")}
                          {modifiedTabs.indexOf(0) != -1 && "*"}
                        </Text>
                      </Center>
                    </StyledTab> */}
                    <StyledTab
                      _selected={{
                        color: "white",
                        bg: theme.colors.euracOrange,
                      }}
                    >
                      <Center justifyContent={"flex-start"} w={"100%"}>
                        <Flex w={"1.7rem"}>
                          <IoHomeOutline size={"1.7rem"} />
                        </Flex>

                        <Text
                          fontSize={"lg"}
                          paddingLeft={"0.2rem"}
                          textAlign={"left"}
                        >
                          {t(
                            "tabs.details.fancadeTechnicalProject.fancadeTechnicalProject"
                          )}
                          {modifiedTabs.indexOf(0) != -1 && "*"}
                        </Text>
                      </Center>
                    </StyledTab>
                    <StyledTab
                      _selected={{
                        color: "white",
                        bg: theme.colors.euracOrange,
                      }}
                    >
                      <Center justifyContent={"flex-start"} w={"100%"}>
                        <Flex w={"1.7rem"}>
                          <AiOutlineDollarCircle size={"1.7rem"} />
                        </Flex>
                        <Text
                          fontSize={"lg"}
                          paddingLeft={"0.2rem"}
                          textAlign={"left"}
                        >
                          {t("tabs.details.fancadeProject")}
                          {modifiedTabs.indexOf(1) != -1 && "*"}
                        </Text>
                      </Center>
                    </StyledTab>
                    <StyledTab
                      _selected={{
                        color: "white",
                        bg: theme.colors.euracOrange,
                      }}
                    >
                      <Center justifyContent={"flex-start"} w={"100%"}>
                        <Flex w={"1.7rem"}>
                          <AiOutlineDollarCircle size={"1.7rem"} />
                        </Flex>
                        <Text
                          fontSize={"lg"}
                          paddingLeft={"0.2rem"}
                          textAlign={"left"}
                        >
                          {t("tabs.details.fancadeProduction")}
                          {modifiedTabs.indexOf(2) != -1 && "*"}
                        </Text>
                      </Center>
                    </StyledTab>
                    <StyledTab
                      _selected={{
                        color: "white",
                        bg: theme.colors.euracOrange,
                      }}
                    >
                      <Center justifyContent={"flex-start"} w={"100%"}>
                        <Flex w={"1.7rem"}>
                          <AiOutlineDollarCircle size={"1.7rem"} />
                        </Flex>
                        <Text
                          fontSize={"lg"}
                          paddingLeft={"0.2rem"}
                          textAlign={"left"}
                        >
                          {t("tabs.details.transport")}
                          {modifiedTabs.indexOf(3) != -1 && "*"}
                        </Text>
                      </Center>
                    </StyledTab>
                    <StyledTab
                      _selected={{
                        color: "white",
                        bg: theme.colors.euracOrange,
                      }}
                    >
                      <Center justifyContent={"flex-start"} w={"100%"}>
                        <Flex w={"1.7rem"}>
                          <AiOutlineDollarCircle size={"1.7rem"} />
                        </Flex>
                        <Text
                          fontSize={"lg"}
                          paddingLeft={"0.2rem"}
                          textAlign={"left"}
                        >
                          {t("tabs.details.buildingSite")}
                          {modifiedTabs.indexOf(4) != -1 && "*"}
                        </Text>
                      </Center>
                    </StyledTab>
                    <StyledTab
                      _selected={{
                        color: "white",
                        bg: theme.colors.euracOrange,
                      }}
                    >
                      <Center justifyContent={"flex-start"} w={"100%"}>
                        <Flex w={"1.7rem"}>
                          <AiOutlineDollarCircle size={"1.7rem"} />
                        </Flex>
                        <Text
                          fontSize={"lg"}
                          paddingLeft={"0.2rem"}
                          textAlign={"left"}
                        >
                          {t("tabs.details.serviceLife")}
                          {modifiedTabs.indexOf(5) != -1 && "*"}
                        </Text>
                      </Center>
                    </StyledTab>
                    <StyledTab
                      _selected={{
                        color: "white",
                        bg: theme.colors.euracOrange,
                      }}
                    >
                      <Center justifyContent={"flex-start"} w={"100%"}>
                        <Flex w={"1.7rem"}>
                          <AiOutlineDollarCircle size={"1.7rem"} />
                        </Flex>
                        <Text
                          fontSize={"lg"}
                          paddingLeft={"0.2rem"}
                          textAlign={"left"}
                        >
                          {t("tabs.details.maintenance")}
                          {modifiedTabs.indexOf(6) != -1 && "*"}
                        </Text>
                      </Center>
                    </StyledTab>
                    <StyledTab
                      _selected={{
                        color: "white",
                        bg: theme.colors.euracOrange,
                      }}
                    >
                      <Center justifyContent={"flex-start"} w={"100%"}>
                        <Flex w={"1.7rem"}>
                          <IoCalendarOutline size={"1.7rem"} />
                        </Flex>
                        <Text
                          fontSize={"lg"}
                          paddingLeft={"0.2rem"}
                          textAlign={"left"}
                        >
                          {t("tabs.details.costsActualization")}
                          {modifiedTabs.indexOf(7) != -1 && "*"}
                        </Text>
                      </Center>
                    </StyledTab>
                    <StyledTab
                      _selected={{
                        color: "white",
                        bg: theme.colors.euracOrange,
                      }}
                    >
                      <Center justifyContent={"flex-start"} w={"100%"}>
                        <Flex w={"1.7rem"}>
                          <AiOutlineDollarCircle size={"1.7rem"} />
                        </Flex>
                        <Text
                          fontSize={"lg"}
                          paddingLeft={"0.2rem"}
                          textAlign={"left"}
                        >
                          {t("tabs.details.endOfLife")}
                          {modifiedTabs.indexOf(8) != -1 && "*"}
                        </Text>
                      </Center>
                    </StyledTab>
                    <StyledTab
                      _selected={{
                        color: "white",
                        bg: theme.colors.euracOrange,
                      }}
                    >
                      <Center justifyContent={"flex-start"} w={"100%"}>
                        <Flex w={"1.7rem"}>
                          <AiOutlineUnorderedList size={"1.7rem"} />
                        </Flex>
                        <Text
                          fontSize={"lg"}
                          paddingLeft={"0.2rem"}
                          textAlign={"left"}
                        >
                          {t("titles.report.title")}
                        </Text>
                      </Center>
                    </StyledTab>
                  </Stack>
                </TabList>
              </Flex>

              <Flex
                h={"93%"}
                w={"80%"}
                overflowY={"scroll"}
                overflowX={"hidden"}
              >
                <Formik
                  initialValues={currentProject}
                  validationSchema={formValidationSchema}
                  innerRef={formRef}
                  onSubmit={async (values, { setSubmitting, setValues }) => {
                    await projects.updateProject(values);
                    await projects.fetchProjects();
                    if (currentProject) {
                      currentProject.fancades =
                        await projects.getProjectFancades(parseInt(projectId));
                      setValues(currentProject);
                    }

                    setUpdate(!update);
                    toast({
                      title: t("successes.updates.projectSuccessful"),
                      status: "success",
                      duration: 4000,
                      isClosable: true,
                    });
                  }}
                >
                  {(props) => (
                    <StyledForm>
                      <CopyFancadeModal
                        isVisible={isCopyFancadeModalOpen}
                        onDismiss={() => setIsCopyFancadeModalOpen(false)}
                        onSubmit={onCopyFancadeDataSubmit}
                        formikProps={props}
                      />
                      <TabPanels w={"100%"} paddingBottom={"2rem"}>
                        {/* <TabPanel w={"60%"}>
                          <Text
                            fontSize={"3xl"}
                            style={{ marginBottom: "1em" }}
                          >
                            {t("titles.details.general")}
                          </Text>
                          <GeneralData />
                        </TabPanel> */}
                        <TabPanel w={"100%"}>
                          <Text
                            fontSize={"3xl"}
                            style={{ marginBottom: "1em" }}
                          >
                            {t("titles.details.fancadeTechnicalProject")}
                          </Text>
                          <FancadeTechnicalProject
                            formikProps={props}
                            tabIndex={tabIndex}
                            setTabIndex={setTabIndex}
                            isBenchmark={props.values.isBenchmark}
                          />
                        </TabPanel>
                        <TabPanel w={"100%"}>
                          <Text
                            fontSize={"3xl"}
                            style={{ marginBottom: "1em" }}
                          >
                            {t("titles.details.fancadeProject")}
                          </Text>
                          <FancadeProject
                            formikProps={props}
                            tabIndex={tabIndex}
                            setTabIndex={setTabIndex}
                            isBenchmark={props.values.isBenchmark}
                            update={update}
                            setUpdate={setUpdate}
                          />
                        </TabPanel>
                        <TabPanel w={"100%"}>
                          <Text
                            fontSize={"3xl"}
                            style={{ marginBottom: "1em" }}
                          >
                            {t("titles.details.fancadeProduction")}
                          </Text>
                          <FancadeProduction
                            formikProps={props}
                            tabIndex={tabIndex}
                            setTabIndex={setTabIndex}
                            isBenchmark={props.values.isBenchmark}
                          />
                        </TabPanel>
                        <TabPanel w={"100%"}>
                          <Text
                            fontSize={"3xl"}
                            style={{ marginBottom: "1em" }}
                          >
                            {t("titles.details.transport")}
                          </Text>
                          <Transport
                            formikProps={props}
                            tabIndex={tabIndex}
                            setTabIndex={setTabIndex}
                            isBenchmark={props.values.isBenchmark}
                          />
                        </TabPanel>
                        <TabPanel w={"100%"}>
                          <Text
                            fontSize={"3xl"}
                            style={{ marginBottom: "1em" }}
                          >
                            {t("titles.details.buildingSite")}
                          </Text>
                          <BuildingSite
                            formikProps={props}
                            tabIndex={tabIndex}
                            setTabIndex={setTabIndex}
                            isBenchmark={props.values.isBenchmark}
                          />
                        </TabPanel>
                        <TabPanel w={"100%"}>
                          <Text
                            fontSize={"3xl"}
                            style={{ marginBottom: "1em" }}
                          >
                            {t("titles.details.serviceLife")}
                          </Text>
                          <ServiceOfLife
                            formikProps={props}
                            tabIndex={tabIndex}
                            setTabIndex={setTabIndex}
                            isBenchmark={props.values.isBenchmark}
                          />
                        </TabPanel>
                        <TabPanel w={"100%"}>
                          <Text
                            fontSize={"3xl"}
                            style={{ marginBottom: "1em" }}
                          >
                            {t("titles.details.maintenance")}
                            <Maintenance
                              formikProps={props}
                              tabIndex={tabIndex}
                              setTabIndex={setTabIndex}
                              isBenchmark={props.values.isBenchmark}
                            />
                          </Text>
                        </TabPanel>
                        <TabPanel w={"100%"}>
                          <Text
                            fontSize={"3xl"}
                            style={{ marginBottom: "1em" }}
                          >
                            {t("tabs.details.costsActualization")}
                          </Text>
                          <CostsActualization
                            formikProps={props}
                            tabIndex={tabIndex}
                            setTabIndex={setTabIndex}
                            isBenchmark={props.values.isBenchmark}
                          />
                        </TabPanel>
                        <TabPanel w={"100%"}>
                          <Text
                            fontSize={"3xl"}
                            style={{ marginBottom: "1em" }}
                          >
                            {t("titles.details.endOfLife")}
                          </Text>
                          <EndOfLife
                            formikProps={props}
                            tabIndex={tabIndex}
                            setTabIndex={setTabIndex}
                            isBenchmark={props.values.isBenchmark}
                          />
                        </TabPanel>
                        <TabPanel>
                          <Redirect
                            to={
                              session.user
                                ? `/user/${session.user.id}/projects/${projectId}/summary`
                                : "/"
                            }
                          />
                        </TabPanel>
                      </TabPanels>
                    </StyledForm>
                  )}
                </Formik>
              </Flex>
            </Flex>
          </Tabs>
        </Flex>
      </Stack>
    );
  else return <Redirect to={"/"} />;
});

const StyledForm = styled(Form)`
  width: 100%;
`;
const StyledTab = styled(Tab)`
  color: ${theme.colors.euracOrange};
  border-radius: 500rem;
  align-items: center;
`;
const StyledButton = styled(Button)`
  color: white;
  background-color: ${theme.colors.euracOrange};
  border-radius: 500rem;

  &:hover {
    background-color: ${transparentize(0.5, theme.colors.euracOrange)};
  }
`;
