import { makeAutoObservable } from "mobx";
import { Stores, Ui } from "./../types";

export class UiStore {
  stores: Stores;
  projectModified: boolean = false;

  constructor(stores: Stores) {
    this.stores = stores;
    makeAutoObservable(this);
  }

  ui: Ui = {
    activeScreen: "",
  };

  setProjectModified(projectModified: boolean) {
    this.projectModified = projectModified;
  }

  setActiveScreen(newScreen: string) {
    this.ui.activeScreen = newScreen;
  }
}
