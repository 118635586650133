import React, { useCallback, useMemo } from "react";
import {
  Button,
  Flex,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../../../../hooks/useStores";
import { Redirect, useParams } from "react-router-dom";
import { TransportForm } from "./TransportForm";
import { Fancade, Project } from "../../../../../../types";

import theme from "../../../../../../theme/theme";
import { AiFillEdit } from "react-icons/ai";
import { DetailsTable } from "../../DetailsTable";
import {
  calculateBenchmark,
  calculateTransportTotal,
  toFancadeAPI,
} from "../../../../../../services/utils";
import { DeleteAlert } from "../../../../../Alert";
import { ChangeFancadeNameModal } from "../../../../../ChangeFancadeNameModal";

interface RouteParams {
  projectId: string;
}

type Props = {
  formikProps: any;
  tabIndex: any;
  setTabIndex: any;
  isBenchmark: boolean;
};
export const Transport: React.FC<Props> = observer((props) => {
  const { t } = useTranslation();
  const { session, ui, projects } = useStores();
  const { projectId } = useParams<RouteParams>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [deleteFancadeOpen, setDeleteFancadeOpen] = React.useState(false);
  const [fancadeToUpdate, setFancadeToUpdate] = React.useState<number>(0);
  const [update, setUpdate] = React.useState(false);

  ui.setActiveScreen("projects");

  const handleTabsChange = (index: number) => {
    props.setTabIndex(index);
  };

  const currentProject: Project = props.formikProps.values;

  const addFancade = useCallback(() => {
    if (!currentProject.fancades) currentProject.fancades = [];
    currentProject.fancades.push(projects.getEmptyFancade(+projectId));
    if (currentProject && currentProject.fancades) {
      props.setTabIndex(currentProject.fancades.length - 1);
    }
    setUpdate(!update);
  }, [props.tabIndex, projects, currentProject, props.formikProps, update]);

  const deleteFancade = useCallback(
    (fancade: Fancade, index: number) => {
      if (fancade.id != -1) projects.deleteFancade(toFancadeAPI(fancade));
      props.formikProps.values.fancades.splice(index, 1);
      if (props.tabIndex == index && props.tabIndex > 0) {
        props.setTabIndex(props.tabIndex - 1);
      }
    },
    [
      props.tabIndex,
      projects,
      currentProject,
      props.formikProps.values.fancades,
    ]
  );

  const getOneFancadeKPIsRows = (fancade: Fancade) => {
    if (fancade && fancade.transport) {
      let kpi1 = 0;
      let kpi2 = 0;

      if (
        +calculateTransportTotal(fancade) &&
        fancade.fancadeTechnicalProject.surface
      )
        kpi1 =
          +calculateTransportTotal(fancade) /
          +fancade.fancadeTechnicalProject.surface;

      if (
        +calculateTransportTotal(fancade) && //@ts-ignore
        +fancade?.transport?.workSiteProductionSiteDistance &&
        fancade.transport.totalTransportPartPriceCalculation &&
        fancade.transport.totalTransportPartPriceCalculation == 1
      )
        kpi2 =
          +calculateTransportTotal(fancade) / //@ts-ignore
          +fancade.transport.workSiteProductionSiteDistance;
      let rows = [
        {
          name: t("formLabels.details.transport.totalTransportPrice"),
          values: [
            calculateTransportTotal(fancade).toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " €",
          ],
        },
        {
          name: t("formLabels.details.transport.fancadeSurface"),
          values: [
            (fancade.fancadeTechnicalProject.surface
              ? +fancade.fancadeTechnicalProject.surface
              : 0
            ).toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " m²",
          ],
        },
        {
          name: t(
            "formLabels.details.transport.workSiteProductionSiteDistance"
          ),

          values: [
            (fancade.transport.totalTransportPartPriceCalculation &&
            fancade.transport.totalTransportPartPriceCalculation == 1 &&
            fancade.transport.workSiteProductionSiteDistance != null
              ? fancade.transport.workSiteProductionSiteDistance
              : 0
            ).toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " Km",
          ],
        },
        {
          name: t("formLabels.details.transport.transportPrice1"),
          values: [
            kpi1.toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " €/m²",
          ],
        },
        {
          name: t("formLabels.details.transport.transportPrice2"),
          values: [
            kpi2.toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " €/m²",
            ,
          ],
        },
      ];

      return rows;
    }
    return [];
  };

  const getBenchmarkKPIRows = (
    project: Project,
    currentFancadeIndex: number
  ) => {
    let rows: any[] = [];

    let benchmark;
    let current;
    //@ts-ignore
    if (project && project.fancades && project?.fancades[0])
      benchmark = project?.fancades[0];
    //@ts-ignore
    if (project && project.fancades && project?.fancades[currentFancadeIndex])
      current = project?.fancades[currentFancadeIndex];
    if (current && current.transport && benchmark && benchmark.transport) {
      let totalTransportPrice = 0;
      let kpi1 = 0;
      let kpi2 = 0;

      //Total price
      if (benchmark && +calculateTransportTotal(benchmark))
        totalTransportPrice = +calculateTransportTotal(benchmark);
      if (current && +calculateTransportTotal(current))
        totalTransportPrice = calculateBenchmark(
          totalTransportPrice,
          +calculateTransportTotal(current)
        );
      //KPI1
      if (
        benchmark &&
        +calculateTransportTotal(benchmark) &&
        benchmark.fancadeTechnicalProject.surface
      )
        kpi1 =
          +calculateTransportTotal(benchmark) /
          +benchmark.fancadeTechnicalProject.surface;
      if (
        current &&
        +calculateTransportTotal(current) &&
        current.fancadeTechnicalProject.surface
      )
        kpi1 = calculateBenchmark(
          kpi1,
          +calculateTransportTotal(current) /
            +current.fancadeTechnicalProject.surface
        );

      //KPI2
      if (
        benchmark &&
        +calculateTransportTotal(benchmark) && //@ts-ignore
        +benchmark?.transport?.workSiteProductionSiteDistance &&
        benchmark?.transport.totalTransportPartPriceCalculation &&
        benchmark?.transport.totalTransportPartPriceCalculation == 1
      )
        kpi2 =
          +calculateTransportTotal(benchmark) / //@ts-ignore
          +benchmark.transport.workSiteProductionSiteDistance;
      if (
        current &&
        +calculateTransportTotal(current) && //@ts-ignore
        +current?.transport?.workSiteProductionSiteDistance &&
        current?.transport.totalTransportPartPriceCalculation &&
        current?.transport.totalTransportPartPriceCalculation == 1
      )
        kpi2 = calculateBenchmark(
          kpi2,
          +calculateTransportTotal(current) / //@ts-ignore
            +current.transport.workSiteProductionSiteDistance
        );

      rows = [
        {
          name: t("formLabels.details.transport.totalTransportPrice"),
          values: [
            totalTransportPrice.toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " €",
          ],
        },
        {
          name: t("formLabels.details.transport.transportPrice1"),
          values: [
            kpi1.toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " €/m²",
          ],
        },
        {
          name: t("formLabels.details.transport.transportPrice2"),
          values: [
            kpi2.toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " €/m²",
          ],
        },
      ];
    }
    return rows;
  };

  const getAllFancadeKPIsRows = (project: Project) => {
    let transport = 0;
    let surface = 0;
    let distance = 0;

    let kpi1 = 0;
    let kpi2 = 0;

    project?.fancades?.map((fancade, index) => {
      if (fancade.transport.totalTransportPrice)
        transport += +fancade.transport.totalTransportPrice;

      if (fancade.fancadeTechnicalProject.surface)
        surface += +fancade.fancadeTechnicalProject.surface;

      if (
        fancade.transport.totalTransportPartPriceCalculation &&
        fancade.transport.totalTransportPartPriceCalculation == 1 &&
        fancade.transport.workSiteProductionSiteDistance
      )
        distance += +fancade.transport.workSiteProductionSiteDistance;
    });
    if (surface != 0) kpi1 = transport / surface;
    if (distance != 0) kpi2 = transport / distance;

    project.kpiFancadeTransport = {
      buildingSiteProductionDistance: distance,
      transportKPI1: kpi1,
      transportKPI2: kpi2,
      totalTransportPrice: transport,
    };

    return [
      {
        name: t("formLabels.details.transport.totalTransportPrice"),
        values: [
          transport.toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " €",
        ],
      },
      {
        name: t("formLabels.details.transport.fancadeSurface"),
        values: [
          (+surface).toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " m²",
        ],
      },
      {
        name: t("formLabels.details.transport.workSiteProductionSiteDistance"),
        values: [
          distance.toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " Km",
        ],
      },
      {
        name: t("formLabels.details.transport.transportPrice1"),
        values: [
          kpi1.toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " €/m²",
        ],
      },
      {
        name: t("formLabels.details.transport.transportPrice2"),
        values: [
          kpi2.toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " €/Km",
        ],
      },
    ];
  };

  if (session.user)
    return (
      <Flex w={"100%"}>
        <ChangeFancadeNameModal
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={(values: any) => {
            if (currentProject && currentProject.fancades)
              currentProject.fancades[fancadeToUpdate].customName =
                values.customName;
            onClose();
          }}
        />
        <Tabs
          index={props.tabIndex}
          onChange={handleTabsChange}
          variant={"enclosed"}
          w={"100%"}
        >
          <TabList>
            {currentProject?.fancades?.map((fancade: Fancade, index) => (
              <Tab alignItems={"center"}>
                <DeleteAlert
                  title={t("warnings.deleteFancadeTitle")}
                  body={t("warnings.deleteFancade")}
                  isOpen={deleteFancadeOpen}
                  setIsOpen={setDeleteFancadeOpen}
                  cancelText={t("warnings.cancel")}
                  continueText={t("warnings.confirm")}
                  onContinue={async () => {
                    deleteFancade(fancade, index);
                  }}
                />
                <Text color={theme.colors.darkGrey2}>
                  {fancade.customName
                    ? currentProject.isBenchmark && index == 0
                      ? t("tabs.details.fancadeTechnicalProject.benchmark") +
                        fancade.customName
                      : fancade.customName
                    : currentProject.isBenchmark && index == 0
                    ? t("tabs.details.fancadeTechnicalProject.firstTab")
                    : t("tabs.details.fancadeTechnicalProject.tabsAfterFirst") +
                      (index + 1)}
                </Text>
                <AiFillEdit
                  style={{ marginLeft: "0.3rem" }}
                  onClick={() => {
                    setFancadeToUpdate(index);
                    onOpen();
                  }}
                />
                <Text
                  onClick={() => setDeleteFancadeOpen(true)}
                  color={theme.colors.euracOrange}
                  paddingLeft={"0.5rem"}
                >
                  <strong>x</strong>
                </Text>
              </Tab>
            ))}
            <Button variant={"ghost"} onClick={addFancade}>
              <Text color={theme.colors.euracOrange}>+</Text>
            </Button>
          </TabList>
          <TabPanels>
            {currentProject?.fancades?.map((fancade, index) => (
              <TabPanel>
                <Stack w={"100%"} spacing={"2rem"}>
                  <TransportForm
                    fancadeNumber={index + 1}
                    fancadeId={fancade.id}
                    fancadeType={fancade?.fancadeTechnicalProject?.type}
                    formikProps={props.formikProps}
                  />
                  <DetailsTable
                    columnsTitles={[
                      t("formLabels.details.transport.kpiTransportTitle"),
                    ]}
                    /* @ts-ignore */
                    rows={getOneFancadeKPIsRows(fancade)}
                  />
                  {index > 0 && props.isBenchmark ? (
                    <DetailsTable
                      columnsTitles={[
                        t("formLabels.details.transport.benchmarkTitle"),
                      ]}
                      rows={getBenchmarkKPIRows(currentProject, index)}
                    />
                  ) : (
                    <></>
                  )}
                  {currentProject.fancades &&
                  currentProject.fancades.length > 1 ? (
                    <DetailsTable
                      columnsTitles={[
                        t("formLabels.details.transport.kpiTransportTitleAll"),
                      ]} /* @ts-ignore */
                      rows={getAllFancadeKPIsRows(currentProject)}
                    />
                  ) : (
                    <></>
                  )}
                </Stack>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Flex>
    );
  else return <Redirect to={"/"} />;
});
