import React, { useCallback, useMemo, useState } from "react";
import {
  Box,
  Button,
  Center,
  CircularProgress,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../hooks/useStores";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";

import { ChevronDownIcon } from "@chakra-ui/icons";
import styled from "styled-components";
import theme from "../../../theme/theme";
import { AiFillFolder, AiOutlineUnorderedList } from "react-icons/ai";
import { VscGraph } from "react-icons/vsc";
import { Report } from "./report/Report";
import { useEffect } from "react";
import { Fancade, Project } from "../../../types";
import { DeleteAlert } from "../../Alert";
import { ProjectPDF } from "../../ProjectPDF";
import { IoDocumentTextOutline } from "react-icons/io5";
import { GeneralData } from "../../GeneralDataForm";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toProject } from "../../../services/utils";
import { PrimaryButton } from "../../Button";

interface RouteParams {
  projectId: string;
}

export const Summary: React.FC = observer(() => {
  const { t } = useTranslation();
  const { session, ui, projects } = useStores();
  const { projectId } = useParams<RouteParams>();
  const [toRedirect, setToRedirect] = useState(false);
  const [deleteProjectOpen, setDeleteProjectOpen] = useState(false);

  const formValidationSchema = Yup.object().shape({
    name: Yup.string().required(t("errors.newProject.missingName")),
    location: Yup.string(),
    reportAuthor: Yup.string(),
    description: Yup.string()
      .required(t("errors.newProject.missingDescription"))
      .nullable(),
    type: Yup.string(),
    certification: Yup.string(),
    useDestination: Yup.string(),
    isBenchmark: Yup.boolean(),
  });

  const toast = useToast();
  let currentProject = useMemo(() => {
    return projects.projects.find((element) => {
      return +element.id == +projectId;
    });
  }, [projects.projects]);

  const isBenchmark = useMemo(() => {
    return currentProject ? currentProject.isBenchmark : false;
  }, [currentProject]);

  const history = useHistory();

  ui.setActiveScreen("projects");

  useEffect(() => {
    projects
      .getProjectFancades(parseInt(projectId))
      .then((fancades: Fancade[]) => {
        if (currentProject) currentProject.fancades = fancades;
      });
  }, [projects, currentProject]);

  const redirectToProjectDetails = useCallback(() => {
    if (session.user)
      history.push({
        pathname: `/user/${session.user.id}/projects/${projectId}/details`,
      });
  }, [history]);

  const redirectToPlotsSummary = useCallback(
    (projectId: string) => {
      if (session.user)
        history.push({
          pathname: `/user/${session.user.id}/projects/${projectId}/plots-summary`,
        });
    },
    [history]
  );

  const onSaveProject = useCallback(
    async (project: Project) => {
      try {
        await projects.updateProject(project);
        toast({
          title: t("successes.updates.projectSuccessful"),
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      } catch (e) {
        toast({
          title: t("errors.updates.project"),
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      }
    },
    [projects]
  );

  if (!session.user) {
    return <Redirect to={"/"} />;
  }

  if (session.user) {
    if (toRedirect)
      return <Redirect to={"/user/" + session.user?.id + "/projects"} />;

    if (!currentProject)
      return (
        <Center height={"100%"}>
          <CircularProgress
            size={"2em"}
            color={theme.colors.euracOrange}
            isIndeterminate
          />
        </Center>
      );
    return (
      <Stack w={"99%"} minW={"43rem"}>
        <Flex
          alignItems={"center"}
          paddingLeft={"1rem"}
          paddingTop={"0.5rem"}
          paddingRight={"5%"}
          h={"6vh"}
          minH={"3rem"}
        >
          <Link to={`/user/${session.user.id}/projects`}>
            <Text fontSize={"2xl"} textColor={theme.colors.euracOrange}>
              {t("titles.report.projects")}{" "}
            </Text>
          </Link>
          <Text
            fontSize={"2xl"}
            textColor={theme.colors.lightGrey}
            marginLeft={"0.5%"}
          >
            {" / " + currentProject?.name + " (#" + projectId + ")"}
          </Text>

          <Button
            colorScheme={"gray"}
            marginRight={"1rem"}
            marginLeft={"auto"}
            onClick={() => {
              setDeleteProjectOpen(true);
            }}
            rounded={"500rem"}
          >
            {t("buttons.deleteProject")}
          </Button>
          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton isActive={isOpen} as={Box}>
                  <Text fontSize={"xl"}>
                    {t("buttons.export")}
                    <ChevronDownIcon />
                  </Text>
                </MenuButton>
                <MenuList>
                  {currentProject && currentProject.fancades && (
                    <Link
                      to={{
                        pathname: "/download",
                        state: {
                          pdf: <ProjectPDF project={currentProject} />,
                          redirectUrl:
                            "/user/" +
                            session.user?.id +
                            "/projects/" +
                            currentProject.id +
                            "/summary",
                        },
                      }}
                    >
                      <MenuItem>{t("buttons.pdf")}</MenuItem>
                    </Link>
                  )}
                  {currentProject && currentProject.fancades && currentProject.importedFromBIMP && (
                    <Link
                      to={{
                        pathname: "/export",
                        state: {
                          pdf: <ProjectPDF project={currentProject} />,
                          projectId: currentProject.id,
                          redirectUrl:
                            "/user/" +
                            session.user?.id +
                            "/projects/" +
                            currentProject.id +
                            "/summary",
                        },
                      }}
                    >
                      <MenuItem>{t("bimp.exportBIMP")}</MenuItem>
                    </Link>
                  )}
                </MenuList>
              </>
            )}
          </Menu>
        </Flex>

        <Tabs variant={"unstyled"} flexGrow={1}>
          <Flex>
            <TabList
              minW={"14rem"}
              maxW={"14rem"}
              flexDirection={"column"}
              h={"88vh"}
              overflowY={"scroll"}
              alignItems={"flex-start"}
              padding={"0.3rem"}
            >
              <StyledTab
                _selected={{
                  color: "white",
                  bg: theme.colors.euracOrange,
                }}
              >
                <Center>
                  <Flex w={"1.7rem"}>
                    <IoDocumentTextOutline size={"1.7rem"} />
                  </Flex>
                  <Text
                    fontSize={"lg"}
                    paddingLeft={"0.2rem"}
                    textAlign={"left"}
                  >
                    {t("tabs.details.general")}
                  </Text>
                </Center>
              </StyledTab>
              <StyledTab
                w={"100%"}
                justifyContent={"left"}
                onClick={() => redirectToProjectDetails()}
              >
                <Flex w={"1.7rem"}>
                  <AiFillFolder size={"1.7rem"} />
                </Flex>
                <Text fontSize={"lg"} textAlign={"left"}>
                  {t("titles.report.projectDetails")}
                </Text>
              </StyledTab>

              <StyledTab
                _selected={{
                  color: "white",
                  bg: theme.colors.euracOrange,
                }}
                onClick={() => redirectToPlotsSummary(projectId)}
              >
                <Flex w={"1.7rem"} padding={0}>
                  <VscGraph size={"1.7rem"} />
                </Flex>
                <Text fontSize={"lg"} textAlign={"left"}>
                  {t("plots")}
                </Text>
              </StyledTab>
              <StyledTab
                _selected={{
                  color: "white",
                  bg: theme.colors.euracOrange,
                }}
              >
                <Flex w={"100%"}>
                  <Flex w={"1.7rem"}>
                    <AiOutlineUnorderedList size={"1.7rem"} />
                  </Flex>
                  <Text
                    fontSize={"lg"}
                    paddingLeft={"0.2rem"}
                    textAlign={"left"}
                  >
                    {t("tabs.summary.report")}
                  </Text>
                </Flex>
              </StyledTab>
            </TabList>
            <TabPanels w={"100%"}>
              <TabPanel
                w={"100%"}
                flexDirection={"column"}
                h={"88vh"}
                overflowY={"scroll"}
              >
                <Formik
                  initialValues={currentProject ? currentProject : {}}
                  validationSchema={formValidationSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    onSaveProject(toProject(values));
                  }}
                >
                  {(props) => (
                    <Form>
                      <Flex w={"100%"} justifyContent={"flex-end"}>
                        <PrimaryButton
                          marginRight={"1rem"}
                          marginBottom={"1rem"}
                          type={"submit"}
                          minW={"8rem"}
                        >
                          {t("buttons.save")}
                        </PrimaryButton>
                      </Flex>
                      <GeneralData />
                    </Form>
                  )}
                </Formik>
              </TabPanel>
              <TabPanel w={"100%"} h={"100%"}>
                <Center h={"100%"} flexDirection={"column"}>
                  <CircularProgress
                    size={"5rem"}
                    color={"#df1b12"}
                    isIndeterminate
                  />
                  <Text>{t("loading")}</Text>
                </Center>
              </TabPanel>
              <TabPanel w={"100%"} h={"100%"}>
                <Center h={"100%"} flexDirection={"column"}>
                  <CircularProgress
                    size={"5rem"}
                    color={"#df1b12"}
                    isIndeterminate
                  />
                  <Text>{t("loading")}</Text>
                </Center>
              </TabPanel>
              <TabPanel w={"100%"} h={"auto"}>
                <Report isBenchmark={isBenchmark} />
              </TabPanel>
            </TabPanels>
          </Flex>
        </Tabs>
        <DeleteAlert
          title={t("warnings.deleteProjectTitle")}
          body={t("warnings.deleteProject")}
          isOpen={deleteProjectOpen}
          setIsOpen={setDeleteProjectOpen}
          cancelText={t("warnings.cancel")}
          continueText={t("warnings.confirm")}
          onContinue={async () => {
            if (currentProject) await projects.deleteProject(currentProject);
            toast({
              title: t("successes.deletes.projectSuccessful"),
              status: "success",
              duration: 4000,
              isClosable: true,
            });
            setToRedirect(true);
          }}
        />
      </Stack>
    );
  } else return <Redirect to={"/"} />;
});

const StyledFlex = styled(Flex)`
  cursor: pointer;
  color: ${theme.colors.euracOrange};
`;
const StyledTab = styled(Tab)`
  color: ${theme.colors.euracOrange};
  border-radius: 500rem;
`;
const StyledDivider = styled(Divider)`
  border-color: black;
`;
