import { CloseIcon } from "@chakra-ui/icons";
import { Button, Flex, Select, Stack, Text } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useStores } from "../../../../../../hooks/useStores";
import {
  calculateHardwareTotalPrice,
  calculateTotalMainFrameLength,
  calculateTransparentWindowsTotal,
} from "../../../../../../services/utils";
import theme from "../../../../../../theme/theme";
import { transparentize } from "polished";
import {
  FancadeProductionType,
  Hardware,
  Project,
} from "../../../../../../types";
import { Container } from "../../../../../Container";
import { FormRow } from "../../../../../FormRow";
import { FormikCheckbox } from "../../../../../FormikCheckbox";

type Props = {
  fancadeNumber: number;
  formikProps: any;
};
export const TransparentWindowsForm: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [update, setUpdate] = useState(false);
  const { projects } = useStores();

  const currentProject: Project = props.formikProps.values;

  const addHardware = useCallback(
    (fancadeProduction: FancadeProductionType) => {
      if (!fancadeProduction.hardware) {
        fancadeProduction.hardware = [];
      }

      fancadeProduction.hardware?.push({
        quantity: null,
        totalPrice: null,
        unitPrice: null,
        totalPriceCalculation: 0,
      });

      if (currentProject && currentProject.fancades)
        currentProject.fancades[
          props.fancadeNumber - 1
        ].fancadeProduction.hardware = projects.setHardwareToFancade(
          currentProject.id,
          currentProject.fancades[props.fancadeNumber - 1].id,
          fancadeProduction.hardware
        );
      setUpdate(!update);
    },

    [projects, currentProject, props.formikProps, update]
  );
  const deleteHardware = useCallback(
    (fancadeProduction: FancadeProductionType, index: number) => {
      fancadeProduction.hardware?.splice(index, 1);
      if (
        currentProject &&
        currentProject.fancades &&
        fancadeProduction.hardware
      )
        currentProject.fancades[
          props.fancadeNumber - 1
        ].fancadeProduction.hardware = projects.setHardwareToFancade(
          currentProject.id,
          currentProject.fancades[props.fancadeNumber - 1].id,
          fancadeProduction.hardware
        );

      setUpdate(!update);
    },
    [update]
  );

  let hardwareContainers: any[] = [];
  props.formikProps.values.fancades[
    props.fancadeNumber - 1
  ].fancadeProduction?.hardware?.map((hardware: Hardware, index: number) => {
    let hardwareFields: any[] = [];
    hardwareFields.push(
      <>
        <FormikCheckbox
          paddingTop={"2rem"}
          checked={
            !(
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeProduction?.hardware[index]?.totalPriceCalculation &&
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeProduction?.hardware[index]?.totalPriceCalculation == 1
            )
          }
          defaultChecked={
            !(
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeProduction?.hardware[index]?.totalPriceCalculation &&
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeProduction?.hardware[index]?.totalPriceCalculation == 1
            )
          }
          onChange={() => {
            if (
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                ?.fancadeProduction?.hardware
            ) {
              if (
                !props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeProduction?.hardware[index]?.totalPriceCalculation ||
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeProduction?.hardware[index]?.totalPriceCalculation ==
                  0
              )
                props.formikProps.values.fancades[
                  props.fancadeNumber - 1
                ].fancadeProduction.hardware[index].totalPriceCalculation = 1;
              else
                props.formikProps.values.fancades[
                  props.fancadeNumber - 1
                ].fancadeProduction.hardware[index].totalPriceCalculation = 0;
              setUpdate(!update);
            }
          }}
        >
          {t(`formLabels.details.fancadeProduction.hardwareCheckbox`)}
        </FormikCheckbox>
        {!(
          props.formikProps.values.fancades[props.fancadeNumber - 1]
            .fancadeProduction?.hardware[index]?.totalPriceCalculation &&
          props.formikProps.values.fancades[props.fancadeNumber - 1]
            .fancadeProduction?.hardware[index]?.totalPriceCalculation == 1
        ) ? (
          <>
            <FormRow
              fields={[
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].fancadeProduction.hardware[${index}].totalPrice`,
                  label: t(
                    `formLabels.details.fancadeProduction.singleHardwareTotalPrice`
                  ),
                  placeholder: t(
                    `formPlaceholders.details.fancadeProduction.singleHardwareTotalPrice`
                  ),
                  type: "number",
                  width: 100,
                },
              ]}
            />
          </>
        ) : (
          <Container
            title={
              t(
                `formLabels.details.fancadeProduction.singleHardwareTotalPrice`
              ) + t(`titles.calculatedSection`)
            }
            bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
            direction={"column"}
            spacing={"1rem"}
          >
            <FormRow
              fields={[
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].fancadeProduction.hardware.totalPrice`,
                  label: t(
                    `formLabels.details.fancadeProduction.singleHardwareTotalPrice`
                  ),
                  placeholder: t(
                    `formPlaceholders.details.fancadeProduction.singleHardwareTotalPrice`
                  ),
                  type: "number",
                  width: 100,
                  disabled: true,
                  value:
                    +props.formikProps.values.fancades[props.fancadeNumber - 1]
                      ?.fancadeProduction?.hardware[index].quantity *
                    +props.formikProps.values.fancades[props.fancadeNumber - 1]
                      ?.fancadeProduction?.hardware[index].unitPrice,
                },
              ]}
            />
            <FormRow
              fields={[
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].fancadeProduction.hardware[${index}].quantity`,
                  label: t(
                    `formLabels.details.fancadeProduction.hardwareQuantity`
                  ),
                  placeholder: t(
                    `formPlaceholders.details.fancadeProduction.hardwareQuantity`
                  ),
                  type: "number",
                  width: 100,
                },
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].fancadeProduction.hardware[${index}].unitPrice`,
                  label: t(
                    `formLabels.details.fancadeProduction.hardwareUnitPrice`
                  ),
                  placeholder: t(
                    `formPlaceholders.details.fancadeProduction.hardwareUnitPrice`
                  ),
                  type: "number",
                  width: 100,
                },
              ]}
            />
          </Container>
        )}
      </>
    );
    hardwareContainers.push(
      <Container
        title={
          <Flex alignItems={"center"} w={"100%"}>
            <Text fontSize={"2xl"} color={theme.colors.darkGrey2}>
              {t("formLabels.details.fancadeProduction.hardware") +
                " #" +
                (index + 1)}
            </Text>
            <CloseIcon
              color={theme.colors.euracOrange}
              w={"0.7rem"}
              marginLeft={"0.5rem"}
              marginTop={"0.5rem"}
              onClick={() =>
                deleteHardware(
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeProduction,
                  index
                )
              }
            />
          </Flex>
        }
      >
        <Stack w={"100%"}>{hardwareFields}</Stack>
      </Container>
    );
  });

  return (
    <Stack w={"100%"}>
      <Container title={t("windows")} direction={"column"}>
        <FormikCheckbox
          paddingTop={"2rem"}
          checked={
            !props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject.transparentTotalPriceCalculation ||
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject.transparentTotalPriceCalculation == 0
          }
          defaultChecked={
            !props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject.transparentTotalPriceCalculation ||
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject.transparentTotalPriceCalculation == 0
          }
          onChange={() => {
            if (
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeTechnicalProject
            ) {
              if (
                !props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject.transparentTotalPriceCalculation ||
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject.transparentTotalPriceCalculation == 0
              )
                props.formikProps.values.fancades[
                  props.fancadeNumber - 1
                ].fancadeTechnicalProject.transparentTotalPriceCalculation = 1;
              else
                props.formikProps.values.fancades[
                  props.fancadeNumber - 1
                ].fancadeTechnicalProject.transparentTotalPriceCalculation = 0;
              setUpdate(!update);
            }
          }}
        >
          {t(`formLabels.details.fancadeProduction.windowByTotal`)}
        </FormikCheckbox>
        {!props.formikProps.values.fancades[props.fancadeNumber - 1]
          .fancadeTechnicalProject.transparentTotalPriceCalculation ||
        props.formikProps.values.fancades[props.fancadeNumber - 1]
          .fancadeTechnicalProject.transparentTotalPriceCalculation == 0 ? (
          <FormRow
            fields={[
              {
                name: `fancades[${
                  props.fancadeNumber - 1
                }].fancadeTechnicalProject.transparentTotalPrice`,
                label: t(
                  `formLabels.details.fancadeProduction.transparentTotalPrice`
                ),
                placeholder: t(
                  `formPlaceholders.details.fancadeProduction.transparentTotalPrice`
                ),
                type: "number",
                width: 100,
              },
            ]}
          />
        ) : (
          <Container
            title={
              t(`formLabels.details.fancadeProduction.transparentTotalPrice`) +
              t(`titles.calculatedSection`)
            }
            bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
            direction={"column"}
            spacing={"1rem"}
          >
            <FormRow
              fields={[
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].fancadeTechnicalProject.transparentTotalPrice`,
                  label: t(
                    `formLabels.details.fancadeProduction.transparentTotalPrice`
                  ),
                  placeholder: t(
                    `formPlaceholders.details.fancadeProduction.transparentTotalPrice`
                  ),
                  type: "number",
                  width: 100,
                  disabled: true,
                  value: calculateTransparentWindowsTotal(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                  ),
                },
              ]}
            />
            {/* Glazed cells */}
            <FormikCheckbox
              paddingTop={"2rem"}
              checked={
                !props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject.totalGlazedCellsPriceCalculation ||
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject.totalGlazedCellsPriceCalculation == 0
              }
              defaultChecked={
                !props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject.totalGlazedCellsPriceCalculation ||
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject.totalGlazedCellsPriceCalculation == 0
              }
              onChange={() => {
                if (
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject
                ) {
                  if (
                    !props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .totalGlazedCellsPriceCalculation ||
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .totalGlazedCellsPriceCalculation == 0
                  )
                    props.formikProps.values.fancades[
                      props.fancadeNumber - 1
                    ].fancadeTechnicalProject.totalGlazedCellsPriceCalculation = 1;
                  else
                    props.formikProps.values.fancades[
                      props.fancadeNumber - 1
                    ].fancadeTechnicalProject.totalGlazedCellsPriceCalculation = 0;
                  setUpdate(!update);
                }
              }}
            >
              {t(`formLabels.details.fancadeProduction.glazedCellsByTotal`)}
            </FormikCheckbox>
            {!props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject.totalGlazedCellsPriceCalculation ||
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject.totalGlazedCellsPriceCalculation == 0 ? (
              <>
                <FormRow
                  fields={[
                    {
                      name: `fancades[${
                        props.fancadeNumber - 1
                      }].fancadeTechnicalProject.totalGlazedCellsPrice`,
                      label: t(
                        `formLabels.details.fancadeProduction.totalGlazedCellsPrice`
                      ),
                      placeholder: t(
                        `formPlaceholders.details.fancadeProduction.totalGlazedCellsPrice`
                      ),
                      type: "number",
                      width: 100,
                    },
                  ]}
                />
              </>
            ) : (
              <Container
                title={
                  t(
                    `formLabels.details.fancadeProduction.totalGlazedCellsPrice`
                  ) + t(`titles.calculatedSection`)
                }
                bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                direction={"column"}
                spacing={"1rem"}
              >
                <Text>
                  {t(`formLabels.details.fancadeProduction.windowSelect`)}
                </Text>
                <Select
                  value={
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject.totalGlazedCellsPriceCalculation
                  }
                  onChange={(e) => {
                    props.formikProps.values.fancades[
                      props.fancadeNumber - 1
                    ].fancadeTechnicalProject.totalGlazedCellsPriceCalculation =
                      +e.target.options.selectedIndex + 1;
                    setUpdate(!update);
                  }}
                >
                  <option value={1}>
                    {t(`formLabels.details.fancadeProduction.unityOption`)}
                  </option>
                  <option value={2}>
                    {t(`formLabels.details.fancadeProduction.componentsOption`)}
                  </option>
                </Select>
                {props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject.totalGlazedCellsPriceCalculation ==
                1 ? (
                  <>
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].fancadeTechnicalProject.totalGlazedCellsPrice`,
                          label: t(
                            `formLabels.details.fancadeProduction.totalGlazedCellsPrice`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.fancadeProduction.totalGlazedCellsPrice`
                          ),
                          type: "number",
                          width: 100,
                          disabled: true,
                          value: +calculateTransparentWindowsTotal(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ]
                          ),
                        },
                      ]}
                    />
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].fancadeTechnicalProject.glazedCellsNumber`,
                          label: t(
                            `formLabels.details.fancadeProduction.glazedCellsNumber`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.fancadeProduction.glazedCellsNumber`
                          ),
                          type: "number",
                          width: 100,
                          disabled: true,
                        },
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].fancadeTechnicalProject.glazedCellsUnitPrice`,
                          label: t(
                            `formLabels.details.fancadeProduction.glazedCellsUnitPrice`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.fancadeProduction.glazedCellsUnitPrice`
                          ),
                          type: "number",
                          width: 100,
                        },
                      ]}
                    />
                  </>
                ) : (
                  <>
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].fancadeTechnicalProject.totalGlazedCellsPrice`,
                          label: t(
                            `formLabels.details.fancadeProduction.totalGlazedCellsPrice`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.fancadeProduction.totalGlazedCellsPrice`
                          ),
                          type: "number",
                          width: 100,
                          disabled: true,
                          value: +calculateTransparentWindowsTotal(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ]
                          ),
                        },
                      ]}
                    />

                    {/* Main frame */}
                    <FormikCheckbox
                      paddingTop={"2rem"}
                      checked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                            .totalMainFramePriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                            .totalMainFramePriceCalculation == 1
                        )
                      }
                      defaultChecked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                            .totalMainFramePriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                            .totalMainFramePriceCalculation == 1
                        )
                      }
                      onChange={() => {
                        if (
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                        ) {
                          if (
                            !props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .totalMainFramePriceCalculation ||
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .totalMainFramePriceCalculation == 0
                          )
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject.totalMainFramePriceCalculation = 1;
                          else
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject.totalMainFramePriceCalculation = 0;
                          setUpdate(!update);
                        }
                      }}
                    >
                      {t(`formLabels.details.fancadeProduction.windowByTotal`)}
                    </FormikCheckbox>
                    {!(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .fancadeTechnicalProject
                        .totalMainFramePriceCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .fancadeTechnicalProject
                        .totalMainFramePriceCalculation == 1
                    ) ? (
                      <>
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].fancadeTechnicalProject.totalMainFramePrice`,
                              label: t(
                                `formLabels.details.fancadeProduction.totalMainFramePrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.fancadeProduction.totalMainFramePrice`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </>
                    ) : (
                      <Container
                        title={
                          t(
                            `formLabels.details.fancadeProduction.totalMainFramePrice`
                          ) + t(`titles.calculatedSection`)
                        }
                        bodyStyle={{
                          backgroundColor: theme.colors.lightOrange,
                        }}
                        direction={"column"}
                        spacing={"1rem"}
                      >
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].fancadeTechnicalProject.totalMainFramePrice`,
                              label: t(
                                `formLabels.details.fancadeProduction.totalMainFramePrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.fancadeProduction.totalMainFramePrice`
                              ),
                              type: "number",
                              width: 100,
                              disabled: true,
                              value:
                                +calculateTotalMainFrameLength(
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].fancadeTechnicalProject
                                    .mainFrameHeightPerUnit,
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].fancadeTechnicalProject
                                    .mainFrameWidthPerUnit,
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].fancadeTechnicalProject
                                    .doubleGlazingQuantity
                                ) *
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].fancadeTechnicalProject.mainFrameUnitPrice,
                            },
                          ]}
                        />
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].fancadeTechnicalProject.totalMainFrameLength`,
                              label: t(
                                `formLabels.details.fancadeProduction.totalMainFrameLength`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.fancadeProduction.totalMainFrameLength`
                              ),
                              type: "number",
                              width: 100,
                              disabled: true,
                              value: calculateTotalMainFrameLength(
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].fancadeTechnicalProject
                                  .mainFrameHeightPerUnit,
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].fancadeTechnicalProject.mainFrameWidthPerUnit,
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].fancadeTechnicalProject.doubleGlazingQuantity
                              ),
                            },
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].fancadeTechnicalProject.mainFrameUnitPrice`,
                              label: t(
                                `formLabels.details.fancadeProduction.mainFrameUnitPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.fancadeProduction.mainFrameUnitPrice`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </Container>
                    )}
                    {/* Double Glazing */}
                    <FormikCheckbox
                      paddingTop={"2rem"}
                      checked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                            .totalDoubleGlazingPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                            .totalDoubleGlazingPriceCalculation == 1
                        )
                      }
                      defaultChecked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                            .totalDoubleGlazingPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                            .totalDoubleGlazingPriceCalculation == 1
                        )
                      }
                      onChange={() => {
                        if (
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                        ) {
                          if (
                            !props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .totalDoubleGlazingPriceCalculation ||
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .totalDoubleGlazingPriceCalculation == 0
                          )
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject.totalDoubleGlazingPriceCalculation = 1;
                          else
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject.totalDoubleGlazingPriceCalculation = 0;
                          setUpdate(!update);
                        }
                      }}
                    >
                      {t(
                        `formLabels.details.fancadeProduction.doubleGlazingByTotal`
                      )}
                    </FormikCheckbox>
                    {!(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .fancadeTechnicalProject
                        .totalDoubleGlazingPriceCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .fancadeTechnicalProject
                        .totalDoubleGlazingPriceCalculation == 1
                    ) ? (
                      <>
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].fancadeTechnicalProject.totalDoubleGlazingPrice`,
                              label: t(
                                `formLabels.details.fancadeProduction.totalDoubleGlazingPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.fancadeProduction.totalDoubleGlazingPrice`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </>
                    ) : (
                      <Container
                        title={
                          t(
                            `formLabels.details.fancadeProduction.totalDoubleGlazingPrice`
                          ) + t(`titles.calculatedSection`)
                        }
                        bodyStyle={{
                          backgroundColor: theme.colors.lightOrange,
                        }}
                        direction={"column"}
                        spacing={"1rem"}
                      >
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].fancadeTechnicalProject.totalDoubleGlazingPrice`,
                              label: t(
                                `formLabels.details.fancadeProduction.totalDoubleGlazingPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.fancadeProduction.totalDoubleGlazingPrice`
                              ),
                              type: "number",
                              width: 100,
                              disabled: true,
                              value:
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].fancadeTechnicalProject
                                  .doubleGlazingQuantity *
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].fancadeTechnicalProject
                                  .doubleGlazingUnitPrice,
                            },
                          ]}
                        />
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].fancadeTechnicalProject.doubleGlazingQuantity`,
                              label: t(
                                `formLabels.details.fancadeProduction.doubleGlazingQuantity`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.fancadeProduction.doubleGlazingQuantity`
                              ),
                              type: "number",
                              width: 100,
                              disabled: true,
                              value:
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].fancadeTechnicalProject.doubleGlazingQuantity,
                            },
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].fancadeTechnicalProject.doubleGlazingUnitPrice`,
                              label: t(
                                `formLabels.details.fancadeProduction.doubleGlazingUnitPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.fancadeProduction.doubleGlazingUnitPrice`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </Container>
                    )}

                    {/* Gasket */}
                    <FormikCheckbox
                      paddingTop={"2rem"}
                      checked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                            .totalGasketPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                            .totalGasketPriceCalculation == 1
                        )
                      }
                      defaultChecked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                            .totalGasketPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                            .totalGasketPriceCalculation == 1
                        )
                      }
                      onChange={() => {
                        if (
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                        ) {
                          if (
                            !props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .totalGasketPriceCalculation ||
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .totalGasketPriceCalculation == 0
                          )
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject.totalGasketPriceCalculation = 1;
                          else
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject.totalGasketPriceCalculation = 0;
                          setUpdate(!update);
                        }
                      }}
                    >
                      {t(`formLabels.details.fancadeProduction.gasketByTotal`)}
                    </FormikCheckbox>
                    {!(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .fancadeTechnicalProject.totalGasketPriceCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .fancadeTechnicalProject.totalGasketPriceCalculation ==
                        1
                    ) ? (
                      <>
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].fancadeTechnicalProject.totalGasketPrice`,
                              label: t(
                                `formLabels.details.fancadeProduction.totalGasketPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.fancadeProduction.totalGasketPrice`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </>
                    ) : (
                      <Container
                        title={
                          t(
                            `formLabels.details.fancadeProduction.totalGasketPrice`
                          ) + t(`titles.calculatedSection`)
                        }
                        bodyStyle={{
                          backgroundColor: theme.colors.lightOrange,
                        }}
                        direction={"column"}
                        spacing={"1rem"}
                      >
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].fancadeTechnicalProject.totalGasketPrice`,
                              label: t(
                                `formLabels.details.fancadeProduction.totalGasketPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.fancadeProduction.totalGasketPrice`
                              ),
                              type: "number",
                              width: 100,
                              disabled: true,
                              value:
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].fancadeTechnicalProject.totalGasketLength *
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].fancadeTechnicalProject.gasketUnitPrice,
                            },
                          ]}
                        />
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].fancadeTechnicalProject.totalGasketLength`,
                              label: t(
                                `formLabels.details.fancadeProduction.totalGasketLength`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.fancadeProduction.totalGasketLength`
                              ),
                              type: "number",
                              width: 100,
                              disabled: true,
                            },
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].fancadeTechnicalProject.gasketUnitPrice`,
                              label: t(
                                `formLabels.details.fancadeProduction.gasketUnitPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.fancadeProduction.gasketUnitPrice`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </Container>
                    )}

                    {props.formikProps.values.fancades[
                      props.fancadeNumber - 1
                    ] &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .fancadeTechnicalProject &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .fancadeTechnicalProject.type &&
                      props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.type.toLowerCase() !=
                        "trasparente - a cellule senza pannello spandrel opaco" && (
                        <>
                          {/* Opaque Spandrel Modules */}
                          <FormikCheckbox
                            paddingTop={"2rem"}
                            checked={
                              !(
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].fancadeTechnicalProject
                                  .totalOpaqueSpandrelModulesPriceCalculation &&
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].fancadeTechnicalProject
                                  .totalOpaqueSpandrelModulesPriceCalculation ==
                                  1
                              )
                            }
                            defaultChecked={
                              !(
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].fancadeTechnicalProject
                                  .totalOpaqueSpandrelModulesPriceCalculation &&
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].fancadeTechnicalProject
                                  .totalOpaqueSpandrelModulesPriceCalculation ==
                                  1
                              )
                            }
                            onChange={() => {
                              if (
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].fancadeTechnicalProject
                              ) {
                                if (
                                  !props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].fancadeTechnicalProject
                                    .totalOpaqueSpandrelModulesPriceCalculation ||
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].fancadeTechnicalProject
                                    .totalOpaqueSpandrelModulesPriceCalculation ==
                                    0
                                )
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].fancadeTechnicalProject.totalOpaqueSpandrelModulesPriceCalculation = 1;
                                else
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].fancadeTechnicalProject.totalOpaqueSpandrelModulesPriceCalculation = 0;
                                setUpdate(!update);
                              }
                            }}
                          >
                            {t(
                              `formLabels.details.fancadeProduction.opaqueSpandrelByTotal`
                            )}
                          </FormikCheckbox>
                          {!(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .totalOpaqueSpandrelModulesPriceCalculation &&
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .totalOpaqueSpandrelModulesPriceCalculation == 1
                          ) ? (
                            <>
                              <FormRow
                                fields={[
                                  {
                                    name: `fancades[${
                                      props.fancadeNumber - 1
                                    }].fancadeTechnicalProject.totalOpaqueSpandrelModulesPrice`,
                                    label: t(
                                      `formLabels.details.fancadeProduction.totalOpaqueSpandrelModulesPrice`
                                    ),
                                    placeholder: t(
                                      `formPlaceholders.details.fancadeProduction.totalOpaqueSpandrelModulesPrice`
                                    ),
                                    type: "number",
                                    width: 100,
                                  },
                                ]}
                              />
                            </>
                          ) : (
                            <Container
                              title={
                                t(
                                  `formLabels.details.fancadeProduction.totalOpaqueSpandrelModulesPrice`
                                ) + t(`titles.calculatedSection`)
                              }
                              bodyStyle={{
                                backgroundColor: theme.colors.lightOrange,
                              }}
                              direction={"column"}
                              spacing={"1rem"}
                            >
                              <FormRow
                                fields={[
                                  {
                                    name: `fancades[${
                                      props.fancadeNumber - 1
                                    }].fancadeTechnicalProject.totalOpaqueSpandrelModulesPrice`,
                                    label: t(
                                      `formLabels.details.fancadeProduction.totalOpaqueSpandrelModulesPrice`
                                    ),
                                    placeholder: t(
                                      `formPlaceholders.details.fancadeProduction.totalOpaqueSpandrelModulesPrice`
                                    ),
                                    type: "number",
                                    width: 100,
                                    disabled: true,
                                    value:
                                      +props.formikProps.values.fancades[
                                        props.fancadeNumber - 1
                                      ].fancadeTechnicalProject
                                        .opaqueSpandrelPanelsQuantity *
                                      +props.formikProps.values.fancades[
                                        props.fancadeNumber - 1
                                      ].fancadeTechnicalProject
                                        .opaqueSpandrelModulesUnitPrice,
                                  },
                                ]}
                              />
                              <FormRow
                                fields={[
                                  {
                                    name: `fancades[${
                                      props.fancadeNumber - 1
                                    }].fancadeTechnicalProject.opaqueSpandrelPanelsQuantity`,
                                    label: t(
                                      `formLabels.details.fancadeProduction.opaqueSpandrelPanelsQuantity`
                                    ),
                                    placeholder: t(
                                      `formPlaceholders.details.fancadeProduction.opaqueSpandrelPanelsQuantity`
                                    ),
                                    type: "number",
                                    width: 100,
                                    disabled: true,
                                  },
                                  {
                                    name: `fancades[${
                                      props.fancadeNumber - 1
                                    }].fancadeTechnicalProject.opaqueSpandrelModulesUnitPrice`,
                                    label: t(
                                      `formLabels.details.fancadeProduction.opaqueSpandrelModulesUnitPrice`
                                    ),
                                    placeholder: t(
                                      `formPlaceholders.details.fancadeProduction.opaqueSpandrelModulesUnitPrice`
                                    ),
                                    type: "number",
                                    width: 100,
                                  },
                                ]}
                              />
                            </Container>
                          )}
                        </>
                      )}

                    {/* Fixings */}
                    <FormikCheckbox
                      paddingTop={"2rem"}
                      checked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                            .totalTransparentFixingsNumberPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                            .totalTransparentFixingsNumberPriceCalculation == 1
                        )
                      }
                      defaultChecked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                            .totalTransparentFixingsNumberPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                            .totalTransparentFixingsNumberPriceCalculation == 1
                        )
                      }
                      onChange={() => {
                        if (
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                        ) {
                          if (
                            !props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .totalTransparentFixingsNumberPriceCalculation ||
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .totalTransparentFixingsNumberPriceCalculation ==
                              0
                          )
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject.totalTransparentFixingsNumberPriceCalculation = 1;
                          else
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject.totalTransparentFixingsNumberPriceCalculation = 0;
                          setUpdate(!update);
                        }
                      }}
                    >
                      {t(
                        `formLabels.details.fancadeProduction.opaqueFixingsByTotal`
                      )}
                    </FormikCheckbox>
                    {!(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .fancadeTechnicalProject
                        .totalTransparentFixingsNumberPriceCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .fancadeTechnicalProject
                        .totalTransparentFixingsNumberPriceCalculation == 1
                    ) ? (
                      <>
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].fancadeTechnicalProject.totalTransparentFixingsNumberPrice`,
                              label: t(
                                `formLabels.details.fancadeProduction.totalTransparentFixingsNumberPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.fancadeProduction.totalTransparentFixingsNumberPrice`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </>
                    ) : (
                      <Container
                        title={
                          t(
                            `formLabels.details.fancadeProduction.totalTransparentFixingsNumberPrice`
                          ) + t(`titles.calculatedSection`)
                        }
                        bodyStyle={{
                          backgroundColor: theme.colors.lightOrange,
                        }}
                        direction={"column"}
                        spacing={"1rem"}
                      >
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].fancadeTechnicalProject.totalTransparentFixingsNumberPrice`,
                              label: t(
                                `formLabels.details.fancadeProduction.totalTransparentFixingsNumberPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.fancadeProduction.totalTransparentFixingsNumberPrice`
                              ),
                              type: "number",
                              width: 100,
                              disabled: true,
                              value:
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].fancadeTechnicalProject
                                  .transparentFixingsNumber *
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].fancadeTechnicalProject
                                  .transparentFixingsNumberUnitPrice,
                            },
                          ]}
                        />
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].fancadeTechnicalProject.transparentFixingsNumber`,
                              label: t(
                                `formLabels.details.fancadeProduction.transparentFixingsNumber`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.fancadeProduction.transparentFixingsNumber`
                              ),
                              type: "number",
                              width: 100,
                              disabled: true,
                            },
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].fancadeTechnicalProject.transparentFixingsNumberUnitPrice`,
                              label: t(
                                `formLabels.details.fancadeProduction.transparentFixingsNumberUnitPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.fancadeProduction.transparentFixingsNumberUnitPrice`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </Container>
                    )}
                  </>
                )}
              </Container>
            )}
            {/* Anchorings */}
            <FormikCheckbox
              paddingTop={"2rem"}
              checked={
                !(
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject
                    .totalTransparentAnchoringsPriceCalculation &&
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject
                    .totalTransparentAnchoringsPriceCalculation == 1
                )
              }
              defaultChecked={
                !(
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject
                    .totalTransparentAnchoringsPriceCalculation &&
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject
                    .totalTransparentAnchoringsPriceCalculation == 1
                )
              }
              onChange={() => {
                if (
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject
                ) {
                  if (
                    !props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .totalTransparentAnchoringsPriceCalculation ||
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .totalTransparentAnchoringsPriceCalculation == 0
                  )
                    props.formikProps.values.fancades[
                      props.fancadeNumber - 1
                    ].fancadeTechnicalProject.totalTransparentAnchoringsPriceCalculation = 1;
                  else
                    props.formikProps.values.fancades[
                      props.fancadeNumber - 1
                    ].fancadeTechnicalProject.totalTransparentAnchoringsPriceCalculation = 0;
                  setUpdate(!update);
                }
              }}
            >
              {t(
                `formLabels.details.fancadeProduction.transparentAnchoringByTotal`
              )}
            </FormikCheckbox>
            {!(
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeTechnicalProject
                .totalTransparentAnchoringsPriceCalculation &&
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeTechnicalProject
                .totalTransparentAnchoringsPriceCalculation == 1
            ) ? (
              <>
                <FormRow
                  fields={[
                    {
                      name: `fancades[${
                        props.fancadeNumber - 1
                      }].fancadeTechnicalProject.totalTransparentAnchoringsPrice`,
                      label: t(
                        `formLabels.details.fancadeProduction.totalTransparentAnchoringsPrice`
                      ),
                      placeholder: t(
                        `formPlaceholders.details.fancadeProduction.totalTransparentAnchoringsPrice`
                      ),
                      type: "number",
                      width: 100,
                    },
                  ]}
                />
              </>
            ) : (
              <Container
                title={
                  t(
                    `formLabels.details.fancadeProduction.totalTransparentAnchoringsPrice`
                  ) + t(`titles.calculatedSection`)
                }
                bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                direction={"column"}
                spacing={"1rem"}
              >
                <FormRow
                  fields={[
                    {
                      name: `fancades[${
                        props.fancadeNumber - 1
                      }].fancadeTechnicalProject.totalTransparentAnchoringsPrice`,
                      label: t(
                        `formLabels.details.fancadeProduction.totalTransparentAnchoringsPrice`
                      ),
                      placeholder: t(
                        `formPlaceholders.details.fancadeProduction.totalTransparentAnchoringsPrice`
                      ),
                      type: "number",
                      width: 100,
                      disabled: true,
                      value:
                        +props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject.transparentAnchoringsNumber *
                        +props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .transparentAnchoringsUnitPrice,
                    },
                  ]}
                />
                <FormRow
                  fields={[
                    {
                      name: `fancades[${
                        props.fancadeNumber - 1
                      }].fancadeTechnicalProject.transparentAnchoringsNumber`,
                      label: t(
                        `formLabels.details.fancadeProduction.transparentAnchoringsNumber`
                      ),
                      placeholder: t(
                        `formPlaceholders.details.fancadeProduction.transparentAnchoringsNumber`
                      ),
                      type: "number",
                      width: 100,
                      disabled: true,
                    },
                    {
                      name: `fancades[${
                        props.fancadeNumber - 1
                      }].fancadeTechnicalProject.transparentAnchoringsUnitPrice`,
                      label: t(
                        `formLabels.details.fancadeProduction.transparentAnchoringsUnitPrice`
                      ),
                      placeholder: t(
                        `formPlaceholders.details.fancadeProduction.transparentAnchoringsUnitPrice`
                      ),
                      type: "number",
                      width: 100,
                    },
                  ]}
                />
              </Container>
            )}
          </Container>
        )}
      </Container>
      {/* Hardware */}
      <Container
        direction={"column"}
        title={t(`formLabels.details.fancadeProduction.hardware`)}
      >
        <FormikCheckbox
          checked={
            !(
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeProduction?.hardwareTotalPriceCalculation &&
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeProduction?.hardwareTotalPriceCalculation == 1
            )
          }
          defaultChecked={
            !(
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeProduction?.hardwareTotalPriceCalculation &&
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeProduction?.hardwareTotalPriceCalculation == 1
            )
          }
          onChange={() => {
            if (
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                ?.fancadeProduction
            ) {
              if (
                !props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeProduction?.hardwareTotalPriceCalculation ||
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeProduction?.hardwareTotalPriceCalculation == 0
              )
                props.formikProps.values.fancades[
                  props.fancadeNumber - 1
                ].fancadeProduction.hardwareTotalPriceCalculation = 1;
              else
                props.formikProps.values.fancades[
                  props.fancadeNumber - 1
                ].fancadeProduction.hardwareTotalPriceCalculation = 0;
              setUpdate(!update);
            }
          }}
        >
          {t(`formLabels.details.fancadeProduction.hardwareCheckbox`)}
        </FormikCheckbox>
        {!(
          props.formikProps.values.fancades[props.fancadeNumber - 1]
            .fancadeProduction?.hardwareTotalPriceCalculation &&
          props.formikProps.values.fancades[props.fancadeNumber - 1]
            .fancadeProduction?.hardwareTotalPriceCalculation == 1
        ) ? (
          <>
            <FormRow
              fields={[
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].fancadeProduction.hardwareTotalPrice`,
                  label: t(
                    `formLabels.details.fancadeProduction.hardwareTotalPrice`
                  ),
                  placeholder: t(
                    `formPlaceholders.details.fancadeProduction.hardwareTotalPrice`
                  ),
                  type: "number",
                  width: 100,
                },
              ]}
            />
          </>
        ) : (
          <Container
            title={
              t(
                `formLabels.details.fancadeProduction.singleHardwareTotalPrice`
              ) + t(`titles.calculatedSection`)
            }
            bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
            direction={"column"}
            spacing={"1rem"}
          >
            <FormRow
              fields={[
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].fancadeProduction.hardware.totalPrice`,
                  label: t(
                    `formLabels.details.fancadeProduction.singleHardwareTotalPrice`
                  ),
                  placeholder: t(
                    `formPlaceholders.details.fancadeProduction.singleHardwareTotalPrice`
                  ),
                  type: "number",
                  width: 100,
                  disabled: true,
                  value: calculateHardwareTotalPrice(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                  ),
                },
              ]}
            />

            <>
              <Stack w={"100%"}>{hardwareContainers}</Stack>
              <Stack
                width={"100%"}
                paddingBottom={"2rem"}
                paddingTop={"1.5rem"}
                alignItems={"center"}
              >
                <StyledButton
                  onClick={() =>
                    addHardware(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .fancadeProduction
                    )
                  }
                >
                  {t("buttons.addHardware")}
                </StyledButton>
              </Stack>
            </>
          </Container>
        )}
      </Container>
    </Stack>
  );
};

const StyledButton = styled(Button)`
  color: white;
  background-color: ${theme.colors.euracOrange};
  border-radius: 500rem;

  &:hover {
    background-color: ${transparentize(0.5, theme.colors.euracOrange)};
  }
`;
