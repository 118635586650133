import {
  CircularProgress,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
} from "@chakra-ui/react";

import React from "react";
import { Center, Stack, Text } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import theme from "../../theme/theme";
import { useStores } from "../../hooks/useStores";
import { Project } from "../../types";
import { DetailsTable } from "./../projects/summary/details/DetailsTable";
import { getProductionPlotValues } from "../../services/utils";
import Plot from "react-plotly.js";

type Props = {
  projectId: number;
  isBenchmark: boolean;
};

export const ProductionPlots: React.FC<Props> = observer((props) => {
  const { t } = useTranslation();
  const { ui, projects } = useStores();
  ui.setActiveScreen("projects");
  const productionPlotTitle = t("plotTitles.production");
  const productionPlotLabels = [
    t("plotTitles.materials"),
    t("plotTitles.labor"),
  ];
  const productionPlotColors = [
    theme.colors.materialsMarker,
    theme.colors.laborMarker,
  ];

  const [tabIndex, setTabIndex] = React.useState(0);

  if (projects.isFetchingProjects) {
    return (
      <Center height={"100%"}>
        <CircularProgress
          size={"2em"}
          color={theme.colors.euracOrange}
          isIndeterminate
        />
      </Center>
    );
  }

  //Find the current project
  const currentProject: Project | undefined = projects.projects.find(
    (project) => project.id == props.projectId
  );

  if (currentProject == undefined)
    return <Center>{t("errors.generic")}</Center>;
  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };
  return (
    <Stack w={"99%"} h={"85vh"} overflowY={"scroll"}>
      <Tabs
        index={tabIndex}
        onChange={handleTabsChange}
        variant={"enclosed"}
        w={"100%"}
        paddingTop={"0.2rem"}
        paddingLeft={"0.1rem"}
      >
        <TabList>
          {currentProject?.fancades?.map((fancade, index) => (
            <Tab alignItems={"center"}>
              <Text color={theme.colors.darkGrey2}>
                {fancade.customName
                  ? currentProject.isBenchmark && index == 0
                    ? t("tabs.details.fancadeTechnicalProject.benchmark") +
                      fancade.customName
                    : fancade.customName
                  : currentProject.isBenchmark && index == 0
                  ? t("tabs.details.fancadeTechnicalProject.firstTab")
                  : t("tabs.details.fancadeTechnicalProject.tabsAfterFirst") +
                    (index + 1)}
              </Text>
            </Tab>
          ))}
          {!currentProject.isBenchmark &&
            currentProject.fancades &&
            currentProject.fancades.length > 0 && (
              <Tab alignItems={"center"}>
                <Text color={theme.colors.darkGrey2}>
                  {t("tablesVoices.report.allFancadesTab")}
                </Text>
              </Tab>
            )}
        </TabList>
        <TabPanels>
          {currentProject?.fancades?.map((fancade, index) => (
            <TabPanel w={"100%"}>
              {getProductionPlotValues(currentProject, index).length > 0 ? (
                <Plot
                  data={[
                    {
                      y: [getProductionPlotValues(currentProject, index)[0]],
                      type: "bar",
                      name: productionPlotLabels[0],
                      marker: { color: productionPlotColors[0] },
                    },
                    {
                      y: [getProductionPlotValues(currentProject, index)[1]],
                      type: "bar",
                      name: productionPlotLabels[1],
                      marker: { color: productionPlotColors[1] },
                    },
                  ]}
                  layout={{
                    title: productionPlotTitle,
                    barmode: "stack",
                    yaxis: {
                      range: [0, 1],
                    },
                    xaxis: {
                      showticklabels: false,
                      range: [-1, 1],
                    },
                  }}
                />
              ) : (
                <Text textAlign={"center"} fontSize={"2xl"}>
                  {t("errors.noValuesPlot")}
                </Text>
              )}
            </TabPanel>
          ))}
          {currentProject.fancades && currentProject.fancades.length > 0 && (
            <TabPanel w={"100%"} overflowX={"scroll"}>
              {getProductionPlotValues(currentProject, -1, props.isBenchmark)
                .length > 0 ? (
                <Plot
                  data={[
                    {
                      y: [
                        getProductionPlotValues(
                          currentProject,
                          -1,
                          props.isBenchmark
                        )[0],
                      ],
                      type: "bar",
                      name: productionPlotLabels[0],
                      marker: { color: productionPlotColors[0] },
                    },
                    {
                      y: [
                        getProductionPlotValues(
                          currentProject,
                          -1,
                          props.isBenchmark
                        )[1],
                      ],
                      type: "bar",
                      name: productionPlotLabels[1],
                      marker: { color: productionPlotColors[1] },
                    },
                  ]}
                  layout={{
                    title: productionPlotTitle,
                    barmode: "stack",
                    yaxis: {
                      range: [0, 1],
                    },
                    xaxis: {
                      showticklabels: false,
                      range: [-1, 1],
                    },
                  }}
                />
              ) : (
                <Text textAlign={"center"} fontSize={"2xl"}>
                  {t("errors.noValuesPlot")}
                </Text>
              )}
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
      {!currentProject.fancades ||
        (currentProject?.fancades?.length == 0 && (
          <DetailsTable columnsTitles={[]} rows={[]} />
        ))}
    </Stack>
  );
});
