import React, { useCallback } from "react";
import ReactDOM from "react-dom";
import {
  Button,
  Center,
  Flex,
  Stack,
  Text,
  Image,
  Spacer,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../hooks/useStores";
import { useHistory } from "react-router";

import styled from "styled-components";
import theme from "../../theme/theme";
import { NavItem } from "./styled";
import { AiFillFolder } from "react-icons/ai";
import { HiUsers } from "react-icons/hi";
import { FaCircle } from "react-icons/fa";
import { Redirect } from "react-router-dom";

import euracLogo from "./../../assets/eurac_new_logo_special.png";
import euLogo from "./../../assets/eu_logo.jpeg";
import { getUserInitials } from "../../services/utils";
import i18n from "../../i18n";

export const Sidebar: React.FC = observer(() => {
  const { t } = useTranslation();
  const { session, ui } = useStores();
  const history = useHistory();

  const handleLink = useCallback((route: string) => {
    if (session.user)
      history.push({
        pathname: `/user/${session.user.id}/${route}`,
      });
  }, []);

  const onClickLanguageChange = (e: any) => {
    const language = e.target.value;
    i18n.changeLanguage(language); //change the language
  };

  if (session.user)
    return (
      <Stack marginTop={"10%"} w={"100%"}>
        <Center
          marginBottom={"4em"}
          cursor={"pointer"}
          onClick={() => history.push("/")}
        >
          <Image width={"85%"} src={euracLogo} />
        </Center>
        <Stack w={"100%"} paddingLeft={"10%"} h={"100%"}>
          <Stack width={"90%"}>
            <NavItem
              isActive={ui.ui.activeScreen == "projects"}
              onClick={() => handleLink("projects")}
            >
              <Flex w={"3rem"}>
                <StyledAiFillFolder size={"2.5rem"} />
              </Flex>
              <Text marginLeft={"0.5rem"} fontSize={"lg"}>
                {t("navbar.projects")}
              </Text>
            </NavItem>
            {session.user?.role == "admin" && (
              <NavItem
                isActive={ui.ui.activeScreen == "users"}
                onClick={() => handleLink("users")}
              >
                <Flex w={"2.5rem"}>
                  <StyledHiUsers size={"2.5rem"} />
                </Flex>
                <Text marginLeft={"0.5em"} fontSize={"lg"}>
                  {t("navbar.users")}
                </Text>
              </NavItem>
            )}
          </Stack>
          <Spacer />
          <Flex paddingBottom={"1rem"} justifyContent={'space-between'}>
            <Flex>
            <Menu>
              <MenuButton as={Text}>
                <Flex alignItems={"center"}>
                  <FaCircle color={theme.colors.euracOrange} size={"3rem"} />
                  <Text
                    marginLeft={"-3rem"}
                    textAlign={"center"}
                    fontSize={"xl"}
                    width={"3rem"}
                    color={"white"}
                  >
                    {getUserInitials(session.user)}
                  </Text>
                </Flex>
              </MenuButton>
              <MenuList>
                {/* <MenuItem onClick={() => alert("Profilo")}>
                  {t("navbar.profile")}
                </MenuItem> */}
                <MenuItem onClick={() => session.resetSession()}>
                  {t("navbar.logout")}
                </MenuItem>
              </MenuList>
            </Menu>
            </Flex>
            <Flex alignItems={"center"} paddingRight={'1rem'}>
            <select className="custom-select" onChange={onClickLanguageChange}>
                <option value="it" >It</option>
                <option value="en" >En</option>
            </select>  
            </Flex>
          </Flex>
        </Stack>
        <Flex bg={"white"} paddingTop={"1rem"} paddingBottom={"1rem"}>
          <Image width={"100%"} src={euLogo} />
        </Flex>
      </Stack>
    );
  else return <Redirect to={"/"} />;
});

const StyledAiFillFolder = styled(AiFillFolder)`
  margin-left: 1em;
`;

const StyledHiUsers = styled(HiUsers)`
  margin-left: 1em;
`;
