import React, { useCallback } from "react";
import {
  Button,
  Divider,
  Flex,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../../../../hooks/useStores";
import { Redirect, useParams } from "react-router-dom";
import { Fancade, Project } from "../../../../../../types";

import styled from "styled-components";
import { transparentize } from "polished";
import theme from "../../../../../../theme/theme";
import { AiFillEdit, AiFillFolder } from "react-icons/ai";
import { DetailsTable } from "../../DetailsTable";
import {
  calculateMaintenanceTotal,
  calculateServiceOfLifeTotal,
  getActualizationBenchmarkRows,
  getActualizationLifeServiceRows,
  getActualizationMaintenanceRows,
  getActualizazionAllRows,
  getTotalActualizationRows,
  toFancadeAPI,
} from "../../../../../../services/utils";
import { DeleteAlert } from "../../../../../Alert";
import { FormRow } from "../../../../../FormRow";
import { IoMdArrowDropup } from "react-icons/io";
import { FormikCheckbox } from "../../../../../FormikCheckbox";
import { ChangeFancadeNameModal } from "../../../../../ChangeFancadeNameModal";

interface RouteParams {
  projectId: string;
}

type Props = {
  formikProps: any;
  tabIndex: any;
  setTabIndex: any;
  isBenchmark: boolean;
};
export const CostsActualization: React.FC<Props> = observer((props) => {
  const { t } = useTranslation();
  const { session, ui, projects } = useStores();
  const { projectId } = useParams<RouteParams>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [deleteFancadeOpen, setDeleteFancadeOpen] = React.useState(false);
  const [fancadeToUpdate, setFancadeToUpdate] = React.useState<number>(0);
  const [showAllTable, setShowAllTable] = React.useState(false);
  const [update, setUpdate] = React.useState(false);

  ui.setActiveScreen("projects");

  const handleTabsChange = (index: number) => {
    props.setTabIndex(index);
  };

  let currentProject: Project = props.formikProps.values;
  const addFancade = useCallback(() => {
    if (!currentProject.fancades) currentProject.fancades = [];
    currentProject.fancades.push(projects.getEmptyFancade(+projectId));
    if (currentProject && currentProject.fancades) {
      props.setTabIndex(currentProject.fancades.length - 1);
    }
    setUpdate(!update);
  }, [props.tabIndex, projects, currentProject, props.formikProps]);

  const deleteFancade = useCallback(
    (fancade: Fancade, index: number) => {
      if (fancade.id != -1) projects.deleteFancade(toFancadeAPI(fancade));
      props.formikProps.values.fancades.splice(index, 1);
      if (props.tabIndex === index && props.tabIndex > 0) {
        props.setTabIndex(props.tabIndex - 1);
      }
    },
    [
      props.tabIndex,
      projects,
      currentProject,
      props.formikProps.values.fancades,
    ]
  );

  if (session.user)
    return (
      <Flex w={"100%"}>
        <ChangeFancadeNameModal
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={(values: any) => {
            if (currentProject && currentProject.fancades)
              currentProject.fancades[fancadeToUpdate].customName =
                values.customName;
            onClose();
          }}
        />
        <Tabs
          index={props.tabIndex}
          onChange={handleTabsChange}
          variant={"enclosed"}
          w={"100%"}
        >
          <TabList>
            {currentProject?.fancades?.map((fancade, index) => (
              <Tab alignItems={"center"}>
                <DeleteAlert
                  title={t("warnings.deleteFancadeTitle")}
                  body={t("warnings.deleteFancade")}
                  isOpen={deleteFancadeOpen}
                  setIsOpen={setDeleteFancadeOpen}
                  cancelText={t("warnings.cancel")}
                  continueText={t("warnings.confirm")}
                  onContinue={async () => {
                    deleteFancade(fancade, index);
                  }}
                />
                <Text color={theme.colors.darkGrey2}>
                  {fancade.customName
                    ? currentProject.isBenchmark && index == 0
                      ? t("tabs.details.fancadeTechnicalProject.benchmark") +
                        fancade.customName
                      : fancade.customName
                    : currentProject.isBenchmark && index == 0
                    ? t("tabs.details.fancadeTechnicalProject.firstTab")
                    : t("tabs.details.fancadeTechnicalProject.tabsAfterFirst") +
                      (index + 1)}
                </Text>
                <AiFillEdit
                  style={{ marginLeft: "0.3rem" }}
                  onClick={() => {
                    setFancadeToUpdate(index);
                    onOpen();
                  }}
                />
                <Text
                  onClick={() => setDeleteFancadeOpen(true)}
                  color={theme.colors.euracOrange}
                  paddingLeft={"0.5rem"}
                >
                  <strong>x</strong>
                </Text>
              </Tab>
            ))}
            <Button variant={"ghost"} onClick={addFancade}>
              <Text color={theme.colors.euracOrange}>+</Text>
            </Button>
          </TabList>
          <TabPanels>
            {currentProject?.fancades?.map((fancade, index) => (
              <TabPanel>
                <Stack w={"100%"} spacing={"1rem"}>
                  <Text fontSize={"2xl"}>
                    {t("tablesVoices.costsActualization.serviceOfLife")}
                  </Text>
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${index}].serviceOfLife.yearlyServiceOfLife`,
                        label: t(
                          `tablesVoices.costsActualization.yearlyServiceOfLife`
                        ),
                        placeholder: t(
                          `tablesVoices.costsActualization.yearlyServiceOfLife`
                        ),
                        type: "number",
                        width: 100,
                        disabled: true,
                        value: calculateServiceOfLifeTotal(fancade),
                      },
                      {
                        name: `fancades[${index}].maintenance.refLife`,
                        label: t(`formLabels.details.maintenance.refLife`),
                        placeholder: t(
                          `formLabels.details.maintenance.refLife`
                        ),
                        type: "number",
                        width: 100,
                        disabled: true,
                      },
                      {
                        name: `fancades[${index}].costsActualization.interestRate`,
                        label: t(
                          `formLabels.details.costsActualization.interestRate`
                        ),
                        placeholder: t(
                          `formLabels.details.costsActualization.interestRate`
                        ),
                        type: "number",
                        width: 100,
                        value:
                          props.formikProps.values.fancades[index]
                            .costsActualization.interestRate,
                        keepField: true,
                      },
                    ]}
                  />
                  <FormikCheckbox
                    name={`fancades[${index}].costsActualization.priceVectorVariationIsActive`}
                    onChange={(e: any) => {
                      if (e.target) {
                        if (!e.target.checked) {
                          props.formikProps.values.fancades[
                            index
                          ].costsActualization.priceVectorVariation = 0;
                        }
                        props.formikProps.values.fancades[
                          index
                        ].costsActualization.priceVectorVariationIsActive =
                          !props.formikProps.values.fancades[index]
                            .costsActualization.priceVectorVariationIsActive;
                        setUpdate(!update);
                      }
                    }}
                    checked={
                      !props.formikProps.values.fancades[index]
                        .costsActualization.priceVectorVariationIsActive
                    }
                    customLabel={t(
                      `formLabels.details.costsActualization.priceVectorVariationIsActive`
                    )}
                  >
                    {t(
                      `formLabels.details.costsActualization.priceVectorVariationIsActive`
                    )}
                  </FormikCheckbox>
                  {props.formikProps.values.fancades[index].costsActualization
                    .priceVectorVariationIsActive && (
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${index}].costsActualization.priceVectorVariation`,
                          label: t(
                            `formLabels.details.costsActualization.priceVectorVariation`
                          ),
                          placeholder: t(
                            `formLabels.details.costsActualization.priceVectorVariation`
                          ),
                          type: "number",
                          width: 100,
                          value:
                            props.formikProps.values.fancades[index]
                              .costsActualization.priceVectorVariation,
                          keepField: true,
                        },
                      ]}
                    />
                  )}

                  <DetailsTable
                    columnsTitles={[
                      t("tablesVoices.costsActualization.year"),
                      t("tablesVoices.costsActualization.value"),
                      t("tablesVoices.costsActualization.total"),
                    ]}
                    rows={getActualizationLifeServiceRows(fancade, index)}
                  />
                  <Divider />
                  <Text fontSize={"2xl"}>
                    {t("tablesVoices.costsActualization.maintenance")}
                  </Text>
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${index}].maintenance.totalMaintenancePrice`,
                        label: t(
                          `tablesVoices.costsActualization.yearlyMaintenance`
                        ),
                        placeholder: t(
                          `tablesVoices.costsActualization.yealyMaintenance`
                        ),
                        type: "number",
                        width: 100,
                        disabled: true,
                        value: calculateMaintenanceTotal(fancade),
                      },
                      {
                        name: `fancades[${index}].maintenance.refLife`,
                        label: t(`formLabels.details.maintenance.refLife`),
                        placeholder: t(
                          `formLabels.details.maintenance.refLife`
                        ),
                        type: "number",
                        width: 100,
                        disabled: true,
                      },
                      {
                        name: `fancades[${index}].costsActualization.interestRate`,
                        label: t(
                          `formLabels.details.costsActualization.interestRate`
                        ),
                        placeholder: t(
                          `formLabels.details.costsActualization.interestRate`
                        ),
                        type: "number",
                        width: 100,
                        value:
                          props.formikProps.values.fancades[index]
                            .costsActualization.interestRate,
                        keepField: true,
                      },
                    ]}
                  />

                  <FormikCheckbox
                    name={`fancades[${index}].costsActualization.priceVectorVariationIsActive`}
                    onChange={(e: any) => {
                      if (e.target) {
                        if (!e.target.checked) {
                          props.formikProps.values.fancades[
                            index
                          ].costsActualization.priceVectorVariation = 0;
                        }
                        props.formikProps.values.fancades[
                          index
                        ].costsActualization.priceVectorVariationIsActive =
                          !props.formikProps.values.fancades[index]
                            .costsActualization.priceVectorVariationIsActive;
                        setUpdate(!update);
                      }
                    }}
                    checked={
                      !props.formikProps.values.fancades[index]
                        .costsActualization.priceVectorVariationIsActive
                    }
                    customLabel={t(
                      `formLabels.details.costsActualization.priceVectorVariationIsActive`
                    )}
                  >
                    {t(
                      `formLabels.details.costsActualization.priceVectorVariationIsActive`
                    )}
                  </FormikCheckbox>
                  {props.formikProps.values.fancades[index].costsActualization
                    .priceVectorVariationIsActive && (
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${index}].costsActualization.priceVectorVariation`,
                          label: t(
                            `formLabels.details.costsActualization.priceVectorVariation`
                          ),
                          placeholder: t(
                            `formLabels.details.costsActualization.priceVectorVariation`
                          ),
                          type: "number",
                          width: 100,
                          value:
                            props.formikProps.values.fancades[index]
                              .costsActualization.priceVectorVariation,
                          keepField: true,
                        },
                      ]}
                    />
                  )}
                  <DetailsTable
                    columnsTitles={[
                      t("tablesVoices.costsActualization.year"),
                      t("tablesVoices.costsActualization.value"),
                      t("tablesVoices.costsActualization.total"),
                    ]}
                    rows={getActualizationMaintenanceRows(fancade, index)}
                  />
                  <Divider />
                  <Text fontSize={"2xl"}>
                    {t("tablesHeaders.totalActualization")}
                  </Text>
                  <DetailsTable
                    columnsTitles={[
                      t("tablesVoices.costsActualization.year"),
                      t("tablesVoices.costsActualization.value"),
                      t("tablesVoices.costsActualization.total"),
                    ]}
                    rows={getTotalActualizationRows(fancade)}
                  />
                  {index > 0 && currentProject.isBenchmark && (
                    <>
                      <Divider />
                      <Text fontSize={"2xl"}>
                        {t("tablesVoices.costsActualization.difference")}
                      </Text>
                      <DetailsTable
                        columnsTitles={[
                          t("tablesVoices.costsActualization.year"),
                          t(
                            "tablesVoices.costsActualization.serviceOfLifePrice"
                          ),
                          t("tablesVoices.costsActualization.maintenancePrice"),
                        ]}
                        rows={getActualizationBenchmarkRows(
                          currentProject,
                          fancade,
                          index
                        )}
                      />
                    </>
                  )}
                  <Divider />
                  <Stack
                    w={"100%"}
                    paddingBottom={showAllTable ? "0rem" : "10rem"}
                  >
                    <Text fontSize={"2xl"} paddingTop={"1rem"}>
                      {t("tablesVoices.costsActualization.allFancades")}
                    </Text>
                    <DetailsTable
                      columnsTitles={[
                        t("tablesVoices.costsActualization.year"),
                        t("tablesVoices.costsActualization.serviceOfLifePrice"),
                        t("tablesVoices.costsActualization.maintenancePrice"),
                      ]}
                      rows={[
                        getActualizazionAllRows(currentProject, index)[
                          getActualizazionAllRows(currentProject, index)
                            .length - 1
                        ],
                      ]}
                    />
                    <Flex
                      w={"100%"}
                      bg={theme.colors.darkGrey}
                      alignItems={"center"}
                      cursor={"pointer"}
                      onClick={() => setShowAllTable(!showAllTable)}
                    >
                      <Text
                        fontSize={"2xl"}
                        paddingLeft={"2rem"}
                        paddingTop={"1rem"}
                        paddingBottom={"1rem"}
                      >
                        {t("tablesVoices.costsActualization.completeTable")}
                      </Text>
                      <Spacer />
                      <IoMdArrowDropup
                        style={{
                          marginRight: "1rem",
                          transform: showAllTable
                            ? "rotate(0deg)"
                            : "rotate(180deg)",
                        }}
                        size={"3rem"}
                      />
                    </Flex>
                  </Stack>
                  <DetailsTable
                    columnsTitles={[
                      t("tablesVoices.costsActualization.year"),
                      t("tablesVoices.costsActualization.serviceOfLifePrice"),
                      t("tablesVoices.costsActualization.maintenancePrice"),
                    ]}
                    rows={getActualizazionAllRows(currentProject, index)}
                    style={{
                      display: showAllTable ? "inline" : "none",
                    }}
                  />
                </Stack>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Flex>
    );
  else return <Redirect to={"/"} />;
});

const StyledAiFillFolder = styled(AiFillFolder)`
  margin-right: 0.5em;
`;

const StyledFlex = styled(Flex)`
  cursor: pointer;
`;
const StyledTab = styled(Tab)`
  color: ${theme.colors.euracOrange};
  border-radius: 500rem;
`;
const StyledButton = styled(Button)`
  color: white;
  border-radius: 500rem;
  background-color: ${theme.colors.euracOrange};

  &:hover {
    background-color: ${transparentize(0.5, theme.colors.euracOrange)};
  }
`;
