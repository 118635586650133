import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Checkbox,
  CircularProgress,
} from "@chakra-ui/react";

import React, { useCallback, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  Button,
  ButtonGroup,
  Center,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../../hooks/useStores";

import styled from "styled-components";
import { transparentize } from "polished";
import theme from "../../../../theme/theme";
import { FaFilter } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { Project } from "../../../../types";

export const GeneralInfoTable: React.FC = observer((props) => {
  const { t } = useTranslation();
  const { session, ui, projects } = useStores();
  const history = useHistory();

  ui.setActiveScreen("projects");

  const handleLink = useCallback((id: string) => {
    if (session.user) {
      history.push({
        pathname: `/user/${session.user.id}/projects/${id}/report`,
      });
    }
  }, []);

  const isProjectSelected = (element: Project, index: any, array: any) => {
    return projects.selectedProjectsIds.has(element.id);
  };

  const selectedProjects = projects.projects.filter(isProjectSelected);

  let head: any = [];
  selectedProjects.map((project) => {
    head.push(<Th fontSize={"md"}>{project.id}</Th>);
  });

  let body: any = [];
  const attributes = [
    "name",
    "type",
    "useDestination",
    "constructionYear",
    "location",
  ];

  attributes.map((attribute: string) => {
    body.push(
      <Tr>
        <StyledTd>{t("tablesHeaders.projectList." + attribute)}</StyledTd>
        <Td>{selectedProjects[0][attribute as keyof Project]}</Td>
        <Td>{selectedProjects[1][attribute as keyof Project]}</Td>
      </Tr>
    );
  });

  return (
    <Flex w={"99%"}>
      <StyledTable variant={"striped"} colorScheme={"blackAlpha"}>
        <Thead>
          <Tr>
            <Th fontSize={"md"}>{t("tablesHeaders.generalComparison")}</Th>
            {head}
          </Tr>
        </Thead>
        <Tbody>{body}</Tbody>
      </StyledTable>
    </Flex>
  );
});

const StyledTable = styled(Table)`
  margin-top: 2%;
`;

const StyledCheckbox = styled(Checkbox)`
  border-color: ${transparentize(0.5, theme.colors.euracOrange)};
`;

const StyledTd = styled(Td)`
  color: ${theme.colors.euracOrange};
  font-weight: 600;
`;
