import React, { useMemo } from "react";
import { Button, Input, Stack } from "@chakra-ui/react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/form-control";
import { useTranslation } from "react-i18next";

import { Form, Field, FieldProps, useFormik, Formik } from "formik";
import * as Yup from "yup";

import styled from "styled-components";
import { User } from "../../types";
import { Select } from "../Select";
import { ROLES } from "../../config/rolesAcls";
import { transparentize } from "polished";
import theme from "../../theme/theme";

type Props = {
  user: User | null;
  onSubmit: (data: any) => any;
  isLoading: boolean;
};

export const UserForm: React.FC<Props> = ({ user, onSubmit, isLoading }) => {
  const { t } = useTranslation();

  const roles = useMemo(() => {
    return ROLES.map((role) => ({
      label: t(`role.${role}`),
      value: role,
    }));
  }, [t]);

  const getSelectedRole = (roleKey: string | null) => {
    if (!roleKey) return null;

    return {
      value: roleKey,
      label: t(`role.${roleKey}`),
    };
  };

  const validationSchema = Yup.object().shape({
    nominative: Yup.string(),
    email: Yup.string()
      .required(t("errors.login.missingEmail"))
      .email(t("errors.login.invalidEmail")),
    role: Yup.string().required(),
  });

  return (
    <Formik
      initialValues={{
        id: user?.id || null,
        nominative: user?.nominative || "",
        email: user?.email || "",
        role: user?.role || null,
      }}
      onSubmit={(data: any) => {
        onSubmit(data);
      }}
      validationSchema={validationSchema}
    >
      {(formikProps) => (
        <Form>
          <Stack spacing={"1rem"}>
            <Field name={"nominative"}>
              {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                  <Input
                    {...field}
                    name={field.name}
                    type={"text"}
                    value={field.value}
                    placeholder={t("formPlaceholders.nominative")}
                    onChange={(event: any) =>
                      setFieldValue(field.name, event.target.value)
                    }
                  />
                  <FormErrorMessage name={"nominative"}>
                    {meta.error}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name={"email"}>
              {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                  <Input
                    {...field}
                    type={"email"}
                    name={field.name}
                    value={field.value}
                    placeholder={t("formPlaceholders.email")}
                    onChange={(event: any) =>
                      setFieldValue(field.name, event.target.value)
                    }
                  />
                  <FormErrorMessage name={"email"}>
                    {meta.error}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name={"role"}>
              {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                  <StyledFormLabel htmlFor="projectId">
                    {t("formLabels.board.project")}
                  </StyledFormLabel>
                  <Select
                    options={roles}
                    value={getSelectedRole(field.value)}
                    onChange={(option) =>
                      setFieldValue(field.name, option && option.value)
                    }
                    onBlur={field.onBlur}
                  />

                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <StyledButton
              disabled={!formikProps.dirty || !formikProps.isValid}
              type={"submit"}
              style={{ marginTop: "3em" }}
              rounded={"500rem"}
            >
              {t("buttons.addUser")}
            </StyledButton>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

const StyledFormLabel = styled(FormLabel)`
  font-size: 0.85rem !important;
  font-weight: 400 !important;
  line-height: 1rem;
  color: ${(props) => props.theme.secondaryText1};
`;

const StyledButton = styled(Button)`
  color: white;
  background-color: ${theme.colors.euracOrange};
  border-radius: 500rem;
  &:hover {
    background-color: ${transparentize(0.5, theme.colors.euracOrange)};
  }
`;
