import React from "react";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Field, FieldProps } from "formik";
import { FieldType } from "../types";
import { FormikInput } from "./FormikInput";
import { observer } from "mobx-react";
import { useStores } from "../hooks/useStores";

import { NoteIcon } from "./NoteIcon";

type props = {
  fields: FieldType[];
  paddingBottom?: string;
  width?: string;
  preventNoteRendering?: boolean;
  onChange?: (value: any) => any;
};

export const FormRow: React.FC<props> = observer((props) => {
  const { ui } = useStores();
  let labels: any[] = [];
  let inputs: any[] = [];
  let totalWidth = 0;

  props.fields.map((propField) => {
    totalWidth += propField.width;
    if (propField) {
      let disabled = false;
      if (propField.disabled) {
        disabled = true;
      }

      //Get the note field name
      let splittedName = propField.name.split(".");
      splittedName.splice(1, 0, "notes");

      const noteFieldName = splittedName.join(".");

      labels.push(
        <Flex width={propField.width + "%"} alignItems={"center"}>
          <Text
            marginRight={"0.2rem"}
            fontSize={"lg"}
            textAlign={
              propField.value != null && !propField.keepField
                ? "center"
                : "left"
            }
          >
            {propField.label}
          </Text>
          {!props.preventNoteRendering && (
            <Field name={noteFieldName}>
              {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                <NoteIcon
                  onBlur={(content: string) => {
                    setFieldValue(field.name, content);
                  }}
                  noteContent={field.value}
                />
              )}
            </Field>
          )}
        </Flex>
      );

      if (propField.type != "select") {
        if (propField.value == null) {
          inputs.push(
            <Stack width={propField.width + "%"} marginRight={"1rem"}>
              <Field name={propField.name}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormikInput
                      field={field}
                      type={propField.type}
                      name={field.name}
                      value={field.value}
                      placeholder={
                        propField.placeholder
                          ? propField.placeholder
                          : undefined
                      }
                      disabled={disabled}
                      setFieldValue={setFieldValue}
                      onChange={(event: any) => {
                        ui.setProjectModified(true);
                        if (propField.onChange) {
                          propField.onChange(event);
                        }
                      }}
                      onBlur={(event: any) => {
                        if (propField.onBlur) {
                          propField.onBlur(event);
                        }
                      }}
                    />
                    <FormErrorMessage name={propField.name}>
                      {meta.error}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Stack>
          );
        } else {
          if (propField.onChange) propField.onChange(propField.value);
          if (propField.keepField) {
            inputs.push(
              <Stack width={propField.width + "%"} marginRight={"1rem"}>
                <Field name={propField.name}>
                  {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                    <FormControl
                      isInvalid={Boolean(meta.error) && meta.touched}
                    >
                      <FormikInput
                        field={field}
                        type={propField.type}
                        name={field.name}
                        value={propField.value}
                        placeholder={
                          propField.placeholder
                            ? propField.placeholder
                            : undefined
                        }
                        setFieldValue={setFieldValue}
                        onChange={(event: any) => {
                          ui.setProjectModified(true);
                          if (propField.onChange) {
                            propField.onChange(event);
                          }
                        }}
                        disabled={disabled}
                      />
                      <FormErrorMessage name={propField.name}>
                        {meta.error}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Stack>
            );
          } else {
            inputs.push(
              <Stack width={propField.width + "%"} marginRight={"1rem"}>
                <Text textAlign={"center"} fontWeight={600}>
                  {propField.value.toLocaleString("cs-CZ", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </Text>
                {/* <Field name={propField.name}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <Input
                      {...field}
                      type={propField.type}
                      name={field.name}
                      value={propField.value}
                      placeholder={
                        propField.placeholder
                          ? propField.placeholder
                          : undefined
                      }
                      onChange={(event: any) => {
                        ui.setProjectModified(true);
                        if (propField.onChange) {
                          propField.onChange(event);
                        }
                      }}
                      disabled={disabled}
                    />
                    <FormErrorMessage name={propField.name}>
                      {meta.error}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field> */}
              </Stack>
            );
          }
        }
      } else {
        //Get options
        let options: any[] = [];

        //If the select has a placeholder, add it to the options
        if (propField.placeholder) {
          options.push(
            <option value="" disabled selected hidden>
              {propField.placeholder}
            </option>
          );
        }
        propField.selectOptions?.map((option) => {
          options.push(<option value={option.value}>{option.text}</option>);
        });
        inputs.push(
          <Stack width={propField.width + "%"} marginRight={"1rem"}>
            <Field name={propField.name}>
              {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                  <Select
                    {...field}
                    name={field.name}
                    value={field.value}
                    //placeholder={propField.placeholder}
                    disabled={disabled}
                    onChange={(event: any) => {
                      ui.setProjectModified(true);
                      setFieldValue(field.name, event.target.value);
                      if (props.onChange) {
                        props.onChange(event.target.value);
                      }
                    }}
                  >
                    {options}
                  </Select>
                  <FormErrorMessage name={propField.name}>
                    {meta.error}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Stack>
        );
      }
    }
  });

  return (
    <Stack
      w={props.width ? props.width : "100%"}
      paddingBottom={props.paddingBottom}
    >
      <Flex justifyContent={"center"} alignItems={"center"}>
        {labels}
      </Flex>
      <Flex justifyContent={"center"} alignItems={"center"}>
        {inputs}
      </Flex>
    </Stack>
  );
});
