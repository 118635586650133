import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Center,
  Divider,
  Flex,
  Stack,
  Tab,
  Text,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../../../../hooks/useStores";
import { Redirect, useParams } from "react-router-dom";

import styled from "styled-components";
import { transparentize } from "polished";
import theme from "../../../../../../theme/theme";
import { FormRow } from "../../../../../FormRow";
import {
  BuildingSiteMaterial,
  BuildingSiteType,
  Project,
} from "../../../../../../types";
import {
  calculateBuildingSiteLaborTotal,
  calculateBuildingSiteMaterialsTotal,
  calculateBuildingSiteToolsTotal,
  calculateInstallationTotal,
  calculateScaffoldingTotal,
  calculateSecurityTotal,
} from "../../../../../../services/utils";
import { FormikCheckbox } from "../../../../../FormikCheckbox";
import { Container } from "../../../../../Container";

interface RouteParams {
  projectId: string;
}

type props = {
  fancadeNumber: number;
  fancadeId: number;
  fancadeType: string | null;
  formikProps: any;
};

export const BuildingSiteForm: React.FC<props> = observer((props) => {
  const { t } = useTranslation();
  const { session, ui, projects } = useStores();
  const { projectId } = useParams<RouteParams>();
  const [update, setUpdate] = useState(false);

  ui.setActiveScreen("projects");

  const currentProject: Project = props.formikProps.values;

  const currentFancade = currentProject?.fancades?.find((element) => {
    return +element.id == props.fancadeId;
  });

  const addNewBuildingSiteMaterial = useCallback(
    (buildingSite: BuildingSiteType) => {
      if (!buildingSite.buildingSiteMaterials) {
        buildingSite.buildingSiteMaterials = [];
      }

      buildingSite.buildingSiteMaterials?.push({
        totalAdditionalMaterialPrice: null,
        totalAdditionalMaterialPriceCalculation: null,

        additionalMaterialQuantity: null,
        additionalMaterialUnitPrice: null,
      });

      if (currentProject && currentProject.fancades)
        currentProject.fancades[
          props.fancadeNumber - 1
        ].buildingSite.buildingSiteMaterials = projects.setBuildingSiteMaterialsToFancade(
          currentProject.id,
          props.fancadeId,
          buildingSite.buildingSiteMaterials
        );
      setUpdate(!update);
    },

    [projects, currentProject, update]
  );

  const deleteBuildingSiteMaterial = useCallback(
    (buildingSite: BuildingSiteType, index: number) => {
      buildingSite.buildingSiteMaterials?.splice(index, 1);
      if (
        currentProject &&
        currentProject.fancades &&
        buildingSite.buildingSiteMaterials
      )
        currentProject.fancades[
          props.fancadeNumber - 1
        ].buildingSite.buildingSiteMaterials = projects.setBuildingSiteMaterialsToFancade(
          currentProject.id,
          props.fancadeId,
          buildingSite.buildingSiteMaterials
        );
    },
    []
  );

  if (session.user)
    return (
      <Stack w={"100%"}>
        <Container title={t('formLabels.details.buildingSite.installationPrice')}>
          <Stack w={"100%"}>
            <FormikCheckbox
              checked={
                !props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .buildingSite.totalInstallationPriceCalculation ||
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .buildingSite.totalInstallationPriceCalculation == 0
              }
              defaultChecked={
                !props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .buildingSite.totalInstallationPriceCalculation ||
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .buildingSite.totalInstallationPriceCalculation == 0
              }
              onChange={() => {
                if (
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .buildingSite
                ) {
                  if (
                    !props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .buildingSite.totalInstallationPriceCalculation ||
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .buildingSite.totalInstallationPriceCalculation == 0
                  )
                    props.formikProps.values.fancades[
                      props.fancadeNumber - 1
                    ].buildingSite.totalInstallationPriceCalculation = 1;
                  else
                    props.formikProps.values.fancades[
                      props.fancadeNumber - 1
                    ].buildingSite.totalInstallationPriceCalculation = 0;
                  setUpdate(!update);
                }
              }}
            >
              {t(`formLabels.details.buildingSite.installationByTotal`)}
            </FormikCheckbox>
            {!props.formikProps.values.fancades[props.fancadeNumber - 1]
              .buildingSite.totalInstallationPriceCalculation ||
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .buildingSite.totalInstallationPriceCalculation == 0 ? (
              <>
                <FormRow
                  fields={[
                    {
                      name: `fancades[${
                        props.fancadeNumber - 1
                      }].buildingSite.totalInstallationPrice`,
                      label: t(
                        `formLabels.details.buildingSite.totalInstallationPrice`
                      ),
                      placeholder: t(
                        `formPlaceholders.details.buildingSite.totalInstallationPrice`
                      ),
                      type: "number",
                      width: 100,
                    },
                  ]}
                />
              </>
            ) : (
              <Container
                title={
                  t(`formLabels.details.buildingSite.totalInstallationPrice`) +
                  t(`titles.calculatedSection`)
                }
                bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                direction={"column"}
                spacing={"1rem"}
              >
                <FormRow
                  fields={[
                    {
                      name: `fancades[${
                        props.fancadeNumber - 1
                      }].buildingSite.totalInstallationPrice`,
                      label: t(
                        `formLabels.details.buildingSite.totalInstallationPrice`
                      ),
                      placeholder: t(
                        `formPlaceholders.details.buildingSite.totalInstallationPrice`
                      ),
                      type: "number",
                      width: 100,
                      disabled: true,
                      value: calculateInstallationTotal(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ]
                      ),
                    },
                  ]}
                />

                <FormikCheckbox
                  marginTop={"2rem"}
                  checked={
                    !(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite
                        ?.totalBuildingSiteMaterialsPriceCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite
                        ?.totalBuildingSiteMaterialsPriceCalculation == 1
                    )
                  }
                  defaultChecked={
                    !(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite
                        ?.totalBuildingSiteMaterialsPriceCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite
                        ?.totalBuildingSiteMaterialsPriceCalculation == 1
                    )
                  }
                  onChange={() => {
                    if (
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite
                    ) {
                      if (
                        !props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].buildingSite
                          .totalBuildingSiteMaterialsPriceCalculation ||
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].buildingSite
                          .totalBuildingSiteMaterialsPriceCalculation == 0
                      )
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].buildingSite.totalBuildingSiteMaterialsPriceCalculation = 1;
                      else
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].buildingSite.totalBuildingSiteMaterialsPriceCalculation = 0;
                      setUpdate(!update);
                    }
                  }}
                >
                  {t(`formLabels.details.buildingSite.materialsByTotal`)}
                </FormikCheckbox>
                {!(
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .buildingSite.totalBuildingSiteMaterialsPriceCalculation &&
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .buildingSite.totalBuildingSiteMaterialsPriceCalculation ==
                    1
                ) ? (
                  <>
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].buildingSite.totalBuildingSiteMaterialsPrice`,
                          label: t(
                            `formLabels.details.buildingSite.totalBuildingSiteMaterialsPrice`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.buildingSite.totalBuildingSiteMaterialsPrice`
                          ),
                          type: "number",
                          width: 100,
                        },
                      ]}
                    />
                  </>
                ) : (
                  <Container
                    title={
                      t(
                        `formLabels.details.buildingSite.totalBuildingSiteMaterialsPrice`
                      ) + t(`titles.calculatedSection`)
                    }
                    bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                    direction={"column"}
                    spacing={"1rem"}
                  >
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].buildingSite.totalBuildingSiteMaterialsPrice`,
                          label: t(
                            `formLabels.details.buildingSite.totalBuildingSiteMaterialsPrice`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.buildingSite.totalBuildingSiteMaterialsPrice`
                          ),
                          type: "number",
                          width: 100,
                          disabled: true,
                          value: calculateBuildingSiteMaterialsTotal(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ]
                          ),
                        },
                      ]}
                    />
                    {currentFancade?.buildingSite?.buildingSiteMaterials?.map(
                      (buildingSiteMaterial: BuildingSiteMaterial, index) => (
                        <>
                          <Divider orientation="horizontal" />
                          <Flex w={"100%"} alignItems={"center"}>
                            <Text
                              fontSize={"2xl"}
                              color={theme.colors.darkGrey2}
                            >
                              {t(
                                "formLabels.details.buildingSite.additionalMaterial"
                              )}{" "}
                              #{index + 1}
                            </Text>
                            <CloseIcon
                              color={theme.colors.euracOrange}
                              w={"0.7rem"}
                              marginLeft={"0.5rem"}
                              marginTop={"0.5rem"}
                              onClick={() =>
                                deleteBuildingSiteMaterial(
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ]?.buildingSite,
                                  index
                                )
                              }
                            />
                          </Flex>
                          <FormikCheckbox
                            paddingTop={"2rem"}
                            checked={
                              !(
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].buildingSite?.buildingSiteMaterials[index]
                                  .totalAdditionalMaterialPriceCalculation &&
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].buildingSite?.buildingSiteMaterials[index]
                                  .totalAdditionalMaterialPriceCalculation == 1
                              )
                            }
                            defaultChecked={
                              !(
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].buildingSite?.buildingSiteMaterials[index]
                                  .totalAdditionalMaterialPriceCalculation &&
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].buildingSite?.buildingSiteMaterials[index]
                                  .totalAdditionalMaterialPriceCalculation == 1
                              )
                            }
                            onChange={() => {
                              if (
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].buildingSite
                              ) {
                                if (
                                  !props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].buildingSite.buildingSiteMaterials[index]
                                    .totalAdditionalMaterialPriceCalculation ||
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].buildingSite.buildingSiteMaterials[index]
                                    .totalAdditionalMaterialPriceCalculation ==
                                    0
                                )
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].buildingSite.buildingSiteMaterials[
                                    index
                                  ].totalAdditionalMaterialPriceCalculation = 1;
                                else
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].buildingSite.buildingSiteMaterials[
                                    index
                                  ].totalAdditionalMaterialPriceCalculation = 0;
                                setUpdate(!update);
                              }
                            }}
                          >
                            {t(
                              `formLabels.details.buildingSite.additionalMaterialByTotal`
                            ) +
                              " #" +
                              (+index + 1)}
                          </FormikCheckbox>
                          {!(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite.buildingSiteMaterials[index]
                              .totalAdditionalMaterialPriceCalculation &&
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite.buildingSiteMaterials[index]
                              .totalAdditionalMaterialPriceCalculation == 1
                          ) ? (
                            <>
                              <FormRow
                                fields={[
                                  {
                                    name: `fancades[${
                                      props.fancadeNumber - 1
                                    }].buildingSite.buildingSiteMaterials[${index}].totalAdditionalMaterialPrice`,
                                    label: t(
                                      `formLabels.details.buildingSite.totalAdditionalMaterialPrice`
                                    ),
                                    placeholder: t(
                                      `formPlaceholders.details.buildingSite.totalAdditionalMaterialPrice`
                                    ),
                                    type: "number",
                                    width: 100,
                                  },
                                ]}
                              />
                            </>
                          ) : (
                            <Container
                              title={
                                t(
                                  `formLabels.details.buildingSite.totalAdditionalMaterialPrice`
                                ) + t(`titles.calculatedSection`)
                              }
                              bodyStyle={{
                                backgroundColor: theme.colors.lightOrange,
                              }}
                              direction={"column"}
                              spacing={"1rem"}
                            >
                              <FormRow
                                fields={[
                                  {
                                    name: `fancades[${
                                      props.fancadeNumber - 1
                                    }].buildingSite.buildingSiteMaterials[${index}].totalAdditionalMaterialPrice`,
                                    label: t(
                                      `formLabels.details.buildingSite.totalAdditionalMaterialPrice`
                                    ),
                                    placeholder: t(
                                      `formPlaceholders.details.buildingSite.totalAdditionalMaterialPrice`
                                    ),
                                    type: "number",
                                    width: 100,
                                    disabled: true,
                                    value:
                                      +props.formikProps.values.fancades[
                                        props.fancadeNumber - 1
                                      ].buildingSite.buildingSiteMaterials[
                                        index
                                      ].additionalMaterialQuantity *
                                      +props.formikProps.values.fancades[
                                        props.fancadeNumber - 1
                                      ].buildingSite.buildingSiteMaterials[
                                        index
                                      ].additionalMaterialUnitPrice,
                                  },
                                ]}
                              />

                              <FormRow
                                fields={[
                                  {
                                    name:
                                      "fancades[" +
                                      (props.fancadeNumber - 1) +
                                      "].buildingSite.buildingSiteMaterials[" +
                                      index +
                                      "].additionalMaterialQuantity",
                                    label: t(
                                      "formLabels.details.buildingSite.additionalMaterialQuantity"
                                    ),
                                    placeholder: t(
                                      "formPlaceholders.details.buildingSite.additionalMaterialQuantity"
                                    ),
                                    type: "number",
                                    width: 100,
                                  },
                                  {
                                    name:
                                      "fancades[" +
                                      (props.fancadeNumber - 1) +
                                      "].buildingSite.buildingSiteMaterials[" +
                                      index +
                                      "].additionalMaterialUnitPrice",
                                    label: t(
                                      "formLabels.details.buildingSite.additionalMaterialUnitPrice"
                                    ),
                                    placeholder: t(
                                      "formPlaceholders.details.buildingSite.additionalMaterialUnitPrice"
                                    ),
                                    type: "number",
                                    width: 100,
                                  },
                                ]}
                              />
                            </Container>
                          )}
                        </>
                      )
                    )}
                    <Center paddingTop={"1rem"}>
                      <StyledButton
                        onClick={() =>
                          addNewBuildingSiteMaterial(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ]?.buildingSite
                          )
                        }
                      >
                        {t("buttons.addAdditionalMaterial")}
                      </StyledButton>
                    </Center>
                  </Container>
                )}

                {/* Building site Duration */}
                <FormRow
                  fields={[
                    {
                      name: `fancades[${
                        props.fancadeNumber - 1
                      }].buildingSite.buildingSiteDuration`,
                      label: t(
                        `formLabels.details.buildingSite.buildingSiteDuration`
                      ),
                      placeholder: t(
                        `formPlaceholders.details.buildingSite.buildingSiteDuration`
                      ),
                      type: "number",
                      width: 100,
                    },
                  ]}
                />
                {/* Labor */}
                <FormikCheckbox
                  marginTop={"2rem"}
                  checked={
                    !(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite?.totalLaborPriceCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite?.totalLaborPriceCalculation == 1
                    )
                  }
                  defaultChecked={
                    !(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite?.totalLaborPriceCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite?.totalLaborPriceCalculation == 1
                    )
                  }
                  onChange={() => {
                    if (
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite
                    ) {
                      if (
                        !props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].buildingSite.totalLaborPriceCalculation ||
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].buildingSite.totalLaborPriceCalculation == 0
                      )
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].buildingSite.totalLaborPriceCalculation = 1;
                      else
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].buildingSite.totalLaborPriceCalculation = 0;
                      setUpdate(!update);
                    }
                  }}
                >
                  {t(`formLabels.details.buildingSite.laborByTotal`)}
                </FormikCheckbox>
                {!(
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .buildingSite.totalLaborPriceCalculation &&
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .buildingSite.totalLaborPriceCalculation == 1
                ) ? (
                  <>
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].buildingSite.totalLaborPrice`,
                          label: t(
                            `formLabels.details.buildingSite.totalLaborPrice`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.buildingSite.totalLaborPrice`
                          ),
                          type: "number",
                          width: 100,
                        },
                      ]}
                    />
                  </>
                ) : (
                  <Container
                    title={
                      t(`formLabels.details.buildingSite.totalLaborPrice`) +
                      t(`titles.calculatedSection`)
                    }
                    bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                    direction={"column"}
                    spacing={"1rem"}
                  >
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].buildingSite.totalLaborPrice`,
                          label: t(
                            `formLabels.details.buildingSite.totalLaborPrice`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.buildingSite.totalLaborPrice`
                          ),
                          type: "number",
                          width: 100,
                          disabled: true,
                          value: calculateBuildingSiteLaborTotal(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ]
                          ),
                        },
                      ]}
                    />

                    {/* Base Labor */}
                    <FormikCheckbox
                      paddingTop={"2rem"}
                      checked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite?.totalBaseLaborPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite?.totalBaseLaborPriceCalculation == 1
                        )
                      }
                      defaultChecked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite?.totalBaseLaborPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite?.totalBaseLaborPriceCalculation == 1
                        )
                      }
                      onChange={() => {
                        if (
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite
                        ) {
                          if (
                            !props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite.totalBaseLaborPriceCalculation ||
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite.totalBaseLaborPriceCalculation == 0
                          )
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite.totalBaseLaborPriceCalculation = 1;
                          else
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite.totalBaseLaborPriceCalculation = 0;
                          setUpdate(!update);
                        }
                      }}
                    >
                      {t(`formLabels.details.buildingSite.baseLaborByTotal`)}
                    </FormikCheckbox>
                    {!(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite.totalBaseLaborPriceCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite.totalBaseLaborPriceCalculation == 1
                    ) ? (
                      <>
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].buildingSite.totalBaseLaborPrice`,
                              label: t(
                                `formLabels.details.buildingSite.totalBaseLaborPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.buildingSite.totalBaseLaborPrice`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </>
                    ) : (
                      <Container
                        title={
                          t(
                            `formLabels.details.buildingSite.totalBaseLaborPrice`
                          ) + t(`titles.calculatedSection`)
                        }
                        bodyStyle={{
                          backgroundColor: theme.colors.lightOrange,
                        }}
                        direction={"column"}
                        spacing={"1rem"}
                      >
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].buildingSite.totalBaseLaborPrice`,
                              label: t(
                                `formLabels.details.buildingSite.totalBaseLaborPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.buildingSite.totalBaseLaborPrice`
                              ),
                              type: "number",
                              width: 100,
                              disabled: true,
                              value:
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].buildingSite.baseLaborHours *
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].buildingSite.baseLaborHourPrice,
                            },
                          ]}
                        />

                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].buildingSite.baseLaborHours`,
                              label: t(
                                `formLabels.details.buildingSite.baseLaborHours`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.buildingSite.baseLaborHours`
                              ),
                              type: "number",
                              width: 100,
                            },
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].buildingSite.baseLaborHourPrice`,
                              label: t(
                                `formLabels.details.buildingSite.baseLaborHourPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.buildingSite.baseLaborHourPrice`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </Container>
                    )}

                    {/* Skilled Labor */}
                    <FormikCheckbox
                      paddingTop={"2rem"}
                      checked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite?.totalSkilledLaborPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite?.totalSkilledLaborPriceCalculation ===
                            1
                        )
                      }
                      defaultChecked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite?.totalSkilledLaborPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite?.totalSkilledLaborPriceCalculation ===
                            1
                        )
                      }
                      onChange={() => {
                        if (
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite
                        ) {
                          if (
                            !props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite.totalSkilledLaborPriceCalculation ||
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite.totalSkilledLaborPriceCalculation ==
                              0
                          )
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite.totalSkilledLaborPriceCalculation = 1;
                          else
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite.totalSkilledLaborPriceCalculation = 0;
                          setUpdate(!update);
                        }
                      }}
                    >
                      {t(`formLabels.details.buildingSite.skilledLaborByTotal`)}
                    </FormikCheckbox>
                    {!(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite.totalSkilledLaborPriceCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite.totalSkilledLaborPriceCalculation == 1
                    ) ? (
                      <>
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].buildingSite.totalSkilledLaborPrice`,
                              label: t(
                                `formLabels.details.buildingSite.totalSkilledLaborPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.buildingSite.totalSkilledLaborPrice`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </>
                    ) : (
                      <Container
                        title={
                          t(
                            `formLabels.details.buildingSite.totalSkilledLaborPrice`
                          ) + t(`titles.calculatedSection`)
                        }
                        bodyStyle={{
                          backgroundColor: theme.colors.lightOrange,
                        }}
                        direction={"column"}
                        spacing={"1rem"}
                      >
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].buildingSite.totalSkilledLaborPrice`,
                              label: t(
                                `formLabels.details.buildingSite.totalSkilledLaborPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.buildingSite.totalSkilledLaborPrice`
                              ),
                              type: "number",
                              width: 100,
                              disabled: true,
                              value:
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].buildingSite.skilledLaborHours *
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].buildingSite.skilledLaborHourPrice,
                            },
                          ]}
                        />

                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].buildingSite.skilledLaborHours`,
                              label: t(
                                `formLabels.details.buildingSite.skilledLaborHours`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.buildingSite.skilledLaborHours`
                              ),
                              type: "number",
                              width: 100,
                            },
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].buildingSite.skilledLaborHourPrice`,
                              label: t(
                                `formLabels.details.buildingSite.skilledLaborHourPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.buildingSite.skilledLaborHourPrice`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </Container>
                    )}
                  </Container>
                )}

                {/* Tools */}
                <FormikCheckbox
                  marginTop={"2rem"}
                  checked={
                    !(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite?.totalToolsPriceCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite?.totalToolsPriceCalculation == 1
                    )
                  }
                  defaultChecked={
                    !(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite?.totalToolsPriceCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite?.totalToolsPriceCalculation == 1
                    )
                  }
                  onChange={() => {
                    if (
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite
                    ) {
                      if (
                        !props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].buildingSite.totalToolsPriceCalculation ||
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].buildingSite.totalToolsPriceCalculation == 0
                      )
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].buildingSite.totalToolsPriceCalculation = 1;
                      else
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].buildingSite.totalToolsPriceCalculation = 0;
                      setUpdate(!update);
                    }
                  }}
                >
                  {t(`formLabels.details.buildingSite.toolsByTotal`)}
                </FormikCheckbox>
                {!(
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .buildingSite.totalToolsPriceCalculation &&
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .buildingSite.totalToolsPriceCalculation == 1
                ) ? (
                  <>
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].buildingSite.totalToolsPrice`,
                          label: t(
                            `formLabels.details.buildingSite.totalToolsPrice`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.buildingSite.totalToolsPrice`
                          ),
                          type: "number",
                          width: 100,
                        },
                      ]}
                    />
                  </>
                ) : (
                  <Container
                    title={
                      t(`formLabels.details.buildingSite.totalToolsPrice`) +
                      t(`titles.calculatedSection`)
                    }
                    bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                    direction={"column"}
                    spacing={"1rem"}
                  >
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].buildingSite.totalToolsPrice`,
                          label: t(
                            `formLabels.details.buildingSite.totalToolsPrice`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.buildingSite.totalToolsPrice`
                          ),
                          type: "number",
                          width: 100,
                          disabled: true,
                          value: calculateBuildingSiteToolsTotal(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ]
                          ),
                        },
                      ]}
                    />

                    {/* Crane */}
                    <FormikCheckbox
                      paddingTop={"2rem"}
                      checked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite?.totalTowerCranePriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite?.totalTowerCranePriceCalculation == 1
                        )
                      }
                      defaultChecked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite?.totalTowerCranePriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite?.totalTowerCranePriceCalculation == 1
                        )
                      }
                      onChange={() => {
                        if (
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite
                        ) {
                          if (
                            !props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite.totalTowerCranePriceCalculation ||
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite.totalTowerCranePriceCalculation == 0
                          )
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite.totalTowerCranePriceCalculation = 1;
                          else
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite.totalTowerCranePriceCalculation = 0;
                          setUpdate(!update);
                        }
                      }}
                    >
                      {t(`formLabels.details.buildingSite.craneByTotal`)}
                    </FormikCheckbox>
                    {!(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite.totalTowerCranePriceCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite.totalTowerCranePriceCalculation == 1
                    ) ? (
                      <>
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].buildingSite.totalTowerCranePrice`,
                              label: t(
                                `formLabels.details.buildingSite.totalTowerCranePrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.buildingSite.totalTowerCranePrice`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </>
                    ) : (
                      <Container
                        title={
                          t(
                            `formLabels.details.buildingSite.totalTowerCranePrice`
                          ) + t(`titles.calculatedSection`)
                        }
                        bodyStyle={{
                          backgroundColor: theme.colors.lightOrange,
                        }}
                        direction={"column"}
                        spacing={"1rem"}
                      >
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].buildingSite.totalTowerCranePrice`,
                              label: t(
                                `formLabels.details.buildingSite.totalTowerCranePrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.buildingSite.totalTowerCranePrice`
                              ),
                              type: "number",
                              width: 100,
                              disabled: true,
                              value:
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].buildingSite.towerCraneHours *
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].buildingSite.towerCraneHourPrice,
                            },
                          ]}
                        />

                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].buildingSite.towerCraneHours`,
                              label: t(
                                `formLabels.details.buildingSite.towerCraneHours`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.buildingSite.towerCraneHours`
                              ),
                              type: "number",
                              width: 100,
                            },
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].buildingSite.towerCraneHourPrice`,
                              label: t(
                                `formLabels.details.buildingSite.towerCraneHourPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.buildingSite.towerCraneHourPrice`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </Container>
                    )}

                    {/* Elevator Platform */}
                    <FormikCheckbox
                      paddingTop={"2rem"}
                      checked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite
                            ?.totalElevatorPlatformPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite
                            ?.totalElevatorPlatformPriceCalculation == 1
                        )
                      }
                      defaultChecked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite
                            ?.totalElevatorPlatformPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite
                            ?.totalElevatorPlatformPriceCalculation == 1
                        )
                      }
                      onChange={() => {
                        if (
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite
                        ) {
                          if (
                            !props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite
                              .totalElevatorPlatformPriceCalculation ||
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite
                              .totalElevatorPlatformPriceCalculation == 0
                          )
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite.totalElevatorPlatformPriceCalculation = 1;
                          else
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite.totalElevatorPlatformPriceCalculation = 0;
                          setUpdate(!update);
                        }
                      }}
                    >
                      {t(
                        `formLabels.details.buildingSite.elevatorPlatformByTotal`
                      )}
                    </FormikCheckbox>
                    {!(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite.totalElevatorPlatformPriceCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite.totalElevatorPlatformPriceCalculation ===
                        1
                    ) ? (
                      <>
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].buildingSite.totalElevatorPlatformPrice`,
                              label: t(
                                `formLabels.details.buildingSite.totalElevatorPlatformPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.buildingSite.totalElevatorPlatformPrice`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </>
                    ) : (
                      <Container
                        title={
                          t(
                            `formLabels.details.buildingSite.totalElevatorPlatformPrice`
                          ) + t(`titles.calculatedSection`)
                        }
                        bodyStyle={{
                          backgroundColor: theme.colors.lightOrange,
                        }}
                        direction={"column"}
                        spacing={"1rem"}
                      >
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].buildingSite.totalElevatorPlatformPrice`,
                              label: t(
                                `formLabels.details.buildingSite.totalElevatorPlatformPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.buildingSite.totalElevatorPlatformPrice`
                              ),
                              type: "number",
                              width: 100,
                              disabled: true,
                              value:
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].buildingSite.elevatorPlatformHours *
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].buildingSite.elevatorPlatformHourPrice,
                            },
                          ]}
                        />

                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].buildingSite.elevatorPlatformHours`,
                              label: t(
                                `formLabels.details.buildingSite.elevatorPlatformHours`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.buildingSite.elevatorPlatformHours`
                              ),
                              type: "number",
                              width: 100,
                            },
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].buildingSite.elevatorPlatformHourPrice`,
                              label: t(
                                `formLabels.details.buildingSite.elevatorPlatformHourPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.buildingSite.elevatorPlatformHourPrice`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </Container>
                    )}

                    {/* Aerial Platform */}
                    <FormikCheckbox
                      paddingTop={"2rem"}
                      checked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite?.totalAerialPlatformPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite?.totalAerialPlatformPriceCalculation ==
                            1
                        )
                      }
                      defaultChecked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite?.totalAerialPlatformPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite?.totalAerialPlatformPriceCalculation ==
                            1
                        )
                      }
                      onChange={() => {
                        if (
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite
                        ) {
                          if (
                            !props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite
                              .totalAerialPlatformPriceCalculation ||
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite
                              .totalAerialPlatformPriceCalculation == 0
                          )
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite.totalAerialPlatformPriceCalculation = 1;
                          else
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite.totalAerialPlatformPriceCalculation = 0;
                          setUpdate(!update);
                        }
                      }}
                    >
                      {t(
                        `formLabels.details.buildingSite.aerialPlatformByTotal`
                      )}
                    </FormikCheckbox>
                    {!(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite.totalAerialPlatformPriceCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite.totalAerialPlatformPriceCalculation == 1
                    ) ? (
                      <>
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].buildingSite.totalAerialPlatformPrice`,
                              label: t(
                                `formLabels.details.buildingSite.totalAerialPlatformPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.buildingSite.totalAerialPlatformPrice`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </>
                    ) : (
                      <Container
                        title={
                          t(
                            `formLabels.details.buildingSite.totalAerialPlatformPrice`
                          ) + t(`titles.calculatedSection`)
                        }
                        bodyStyle={{
                          backgroundColor: theme.colors.lightOrange,
                        }}
                        direction={"column"}
                        spacing={"1rem"}
                      >
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].buildingSite.totalAerialPlatformPrice`,
                              label: t(
                                `formLabels.details.buildingSite.totalAerialPlatformPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.buildingSite.totalAerialPlatformPrice`
                              ),
                              type: "number",
                              width: 100,
                              disabled: true,
                              value:
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].buildingSite.aerialPlatformHours *
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].buildingSite.aerialPlatformHourPrice,
                            },
                          ]}
                        />

                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].buildingSite.aerialPlatformHours`,
                              label: t(
                                `formLabels.details.buildingSite.aerialPlatformHours`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.buildingSite.aerialPlatformHours`
                              ),
                              type: "number",
                              width: 100,
                            },
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].buildingSite.aerialPlatformHourPrice`,
                              label: t(
                                `formLabels.details.buildingSite.aerialPlatformHourPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.buildingSite.aerialPlatformHourPrice`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </Container>
                    )}

                    {/* Scaffolding */}
                    <FormikCheckbox
                      paddingTop={"2rem"}
                      checked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite?.totalScaffoldingPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite?.totalScaffoldingPriceCalculation == 1
                        )
                      }
                      defaultChecked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite?.totalScaffoldingPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite?.totalScaffoldingPriceCalculation == 1
                        )
                      }
                      onChange={() => {
                        if (
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite
                        ) {
                          if (
                            !props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite.totalScaffoldingPriceCalculation ||
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite.totalScaffoldingPriceCalculation ===
                              0
                          )
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite.totalScaffoldingPriceCalculation = 1;
                          else
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite.totalScaffoldingPriceCalculation = 0;
                          setUpdate(!update);
                        }
                      }}
                    >
                      {t(`formLabels.details.buildingSite.scaffoldingByTotal`)}
                    </FormikCheckbox>
                    {!(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite.totalScaffoldingPriceCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite.totalScaffoldingPriceCalculation == 1
                    ) ? (
                      <>
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].buildingSite.totalScaffoldingPrice`,
                              label: t(
                                `formLabels.details.buildingSite.totalScaffoldingPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.buildingSite.totalScaffoldingPrice`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </>
                    ) : (
                      <Container
                        title={
                          t(
                            `formLabels.details.buildingSite.totalScaffoldingPrice`
                          ) + t(`titles.calculatedSection`)
                        }
                        bodyStyle={{
                          backgroundColor: theme.colors.lightOrange,
                        }}
                        direction={"column"}
                        spacing={"1rem"}
                      >
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].buildingSite.totalScaffoldingPrice`,
                              label: t(
                                `formLabels.details.buildingSite.totalScaffoldingPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.buildingSite.totalScaffoldingPrice`
                              ),
                              type: "number",
                              width: 100,
                              disabled: true,
                              value: calculateScaffoldingTotal(
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ]
                              ),
                            },
                          ]}
                        />
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].buildingSite.scaffoldingSurface`,
                              label: t(
                                `formLabels.details.buildingSite.scaffoldingSurface`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.buildingSite.scaffoldingSurface`
                              ),
                              type: "number",
                              width: 100,
                            },
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].buildingSite.scaffoldingSurfaceUnitPrice`,
                              label: t(
                                `formLabels.details.buildingSite.scaffoldingSurfaceUnitPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.buildingSite.scaffoldingSurfaceUnitPrice`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].buildingSite.scaffoldingHours`,
                              label: t(
                                `formLabels.details.buildingSite.scaffoldingHours`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.buildingSite.scaffoldingHours`
                              ),
                              type: "number",
                              width: 100,
                            },
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].buildingSite.scaffoldingHourPrice`,
                              label: t(
                                `formLabels.details.buildingSite.scaffoldingHourPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.buildingSite.scaffoldingHourPrice`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </Container>
                    )}

                    {/* Other */}
                    <FormikCheckbox
                      paddingTop={"2rem"}
                      checked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite.totalOtherPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite.totalOtherPriceCalculation == 1
                        )
                      }
                      defaultChecked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite.totalOtherPriceCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite.totalOtherPriceCalculation == 1
                        )
                      }
                      onChange={() => {
                        if (
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite
                        ) {
                          if (
                            !props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite.totalOtherPriceCalculation ||
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite.totalOtherPriceCalculation == 0
                          )
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite.totalOtherPriceCalculation = 1;
                          else
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite.totalOtherPriceCalculation = 0;
                          setUpdate(!update);
                        }
                      }}
                    >
                      {t(`formLabels.details.buildingSite.otherByTotal`)}
                    </FormikCheckbox>
                    {!(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite.totalOtherPriceCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite.totalOtherPriceCalculation == 1
                    ) ? (
                      <>
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].buildingSite.totalOtherPrice`,
                              label: t(
                                `formLabels.details.buildingSite.totalOtherPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.buildingSite.totalOtherPrice`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </>
                    ) : (
                      <Container
                        title={
                          t(`formLabels.details.buildingSite.totalOtherPrice`) +
                          t(`titles.calculatedSection`)
                        }
                        bodyStyle={{
                          backgroundColor: theme.colors.lightOrange,
                        }}
                        direction={"column"}
                        spacing={"1rem"}
                      >
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].buildingSite.totalOtherPrice`,
                              label: t(
                                `formLabels.details.buildingSite.totalOtherPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.buildingSite.totalOtherPrice`
                              ),
                              type: "number",
                              width: 100,
                              disabled: true,
                              value:
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].buildingSite.otherHours *
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].buildingSite.otherHourPrice,
                            },
                          ]}
                        />

                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].buildingSite.otherHours`,
                              label: t(
                                `formLabels.details.buildingSite.otherHours`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.buildingSite.otherHours`
                              ),
                              type: "number",
                              width: 100,
                            },
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].buildingSite.otherHourPrice`,
                              label: t(
                                `formLabels.details.buildingSite.otherHourPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.buildingSite.otherHourPrice`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </Container>
                    )}
                  </Container>
                )}
              </Container>
            )}
          </Stack>
        </Container>
        <Container
          title={
            t('formLabels.details.buildingSite.securityPrice')
          }
        >
          <Stack w={"100%"}>
            {/* Security */}
            <FormikCheckbox
              checked={
                !(
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .buildingSite?.totalSecurityPriceCalculation &&
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .buildingSite?.totalSecurityPriceCalculation == 1
                )
              }
              defaultChecked={
                !(
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .buildingSite?.totalSecurityPriceCalculation &&
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .buildingSite?.totalSecurityPriceCalculation == 1
                )
              }
              onChange={() => {
                if (
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .buildingSite
                ) {
                  if (
                    !props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .buildingSite.totalSecurityPriceCalculation ||
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .buildingSite.totalSecurityPriceCalculation == 0
                  )
                    props.formikProps.values.fancades[
                      props.fancadeNumber - 1
                    ].buildingSite.totalSecurityPriceCalculation = 1;
                  else
                    props.formikProps.values.fancades[
                      props.fancadeNumber - 1
                    ].buildingSite.totalSecurityPriceCalculation = 0;
                  setUpdate(!update);
                }
              }}
            >
              {t(`formLabels.details.buildingSite.securityByTotal`)}
            </FormikCheckbox>
            {!(
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .buildingSite.totalSecurityPriceCalculation &&
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .buildingSite.totalSecurityPriceCalculation == 1
            ) ? (
              <>
                <FormRow
                  fields={[
                    {
                      name: `fancades[${
                        props.fancadeNumber - 1
                      }].buildingSite.totalSecurityPrice`,
                      label: t(
                        `formLabels.details.buildingSite.totalSecurityPrice`
                      ),
                      placeholder: t(
                        `formPlaceholders.details.buildingSite.totalSecurityPrice`
                      ),
                      type: "number",
                      width: 100,
                    },
                  ]}
                />
              </>
            ) : (
              <Container
                title={
                  t(`formLabels.details.buildingSite.totalSecurityPrice`) +
                  t(`titles.calculatedSection`)
                }
                bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                direction={"column"}
                spacing={"1rem"}
              >
                <FormRow
                  fields={[
                    {
                      name: `fancades[${
                        props.fancadeNumber - 1
                      }].buildingSite.totalSecurityPrice`,
                      label: t(
                        `formLabels.details.buildingSite.totalSecurityPrice`
                      ),
                      placeholder: t(
                        `formPlaceholders.details.buildingSite.totalSecurityPrice`
                      ),
                      type: "number",
                      width: 100,
                      disabled: true,
                      value: calculateSecurityTotal(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ]
                      ),
                    },
                  ]}
                />

                {/* Security staff */}
                <FormikCheckbox
                  marginTop={"2rem"}
                  checked={
                    !(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite?.totalStaffSecurityPriceCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite?.totalStaffSecurityPriceCalculation == 1
                    )
                  }
                  defaultChecked={
                    !(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite?.totalStaffSecurityPriceCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite?.totalStaffSecurityPriceCalculation == 1
                    )
                  }
                  onChange={() => {
                    if (
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite
                    ) {
                      if (
                        !props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].buildingSite.totalStaffSecurityPriceCalculation ||
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].buildingSite.totalStaffSecurityPriceCalculation == 0
                      )
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].buildingSite.totalStaffSecurityPriceCalculation = 1;
                      else
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].buildingSite.totalStaffSecurityPriceCalculation = 0;
                      setUpdate(!update);
                    }
                  }}
                >
                  {t(`formLabels.details.buildingSite.staffByTotal`)}
                </FormikCheckbox>
                {!(
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .buildingSite.totalStaffSecurityPriceCalculation &&
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .buildingSite.totalStaffSecurityPriceCalculation == 1
                ) ? (
                  <>
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].buildingSite.totalStaffSecurityPrice`,
                          label: t(
                            `formLabels.details.buildingSite.totalStaffSecurityPrice`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.buildingSite.totalStaffSecurityPrice`
                          ),
                          type: "number",
                          width: 100,
                        },
                      ]}
                    />
                  </>
                ) : (
                  <Container
                    title={
                      t(
                        `formLabels.details.buildingSite.totalStaffSecurityPrice`
                      ) + t(`titles.calculatedSection`)
                    }
                    bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                    direction={"column"}
                    spacing={"1rem"}
                  >
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].buildingSite.totalStaffSecurityPrice`,
                          label: t(
                            `formLabels.details.buildingSite.totalStaffSecurityPrice`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.buildingSite.totalStaffSecurityPrice`
                          ),
                          type: "number",
                          width: 100,
                          disabled: true,
                          value:
                            +props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite.staffSecurityHours *
                            +props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite.staffSecurityHourPrice,
                        },
                      ]}
                    />

                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].buildingSite.staffSecurityHours`,
                          label: t(
                            `formLabels.details.buildingSite.staffSecurityHours`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.buildingSite.staffSecurityHours`
                          ),
                          type: "number",
                          width: 100,
                        },
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].buildingSite.staffSecurityHourPrice`,
                          label: t(
                            `formLabels.details.buildingSite.staffSecurityHourPrice`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.buildingSite.staffSecurityHourPrice`
                          ),
                          type: "number",
                          width: 100,
                        },
                      ]}
                    />
                  </Container>
                )}

                {/* Staff*/}
                <FormikCheckbox
                  marginTop={"2rem"}
                  checked={
                    !(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite?.totalStaffBuildingSitePriceCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite?.totalStaffBuildingSitePriceCalculation ==
                        1
                    )
                  }
                  defaultChecked={
                    !(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite?.totalStaffBuildingSitePriceCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite?.totalStaffBuildingSitePriceCalculation ==
                        1
                    )
                  }
                  onChange={() => {
                    if (
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .buildingSite
                    ) {
                      if (
                        !props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].buildingSite.totalStaffBuildingSitePriceCalculation ||
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].buildingSite.totalStaffBuildingSitePriceCalculation ==
                          0
                      )
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].buildingSite.totalStaffBuildingSitePriceCalculation = 1;
                      else
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].buildingSite.totalStaffBuildingSitePriceCalculation = 0;
                      setUpdate(!update);
                    }
                  }}
                >
                  {t(
                    `formLabels.details.buildingSite.buildingSiteStaffByTotal`
                  )}
                </FormikCheckbox>
                {!(
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .buildingSite.totalStaffBuildingSitePriceCalculation &&
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .buildingSite.totalStaffBuildingSitePriceCalculation == 1
                ) ? (
                  <>
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].buildingSite.totalStaffBuildingSitePrice`,
                          label: t(
                            `formLabels.details.buildingSite.totalStaffBuildingSitePrice`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.buildingSite.totalStaffBuildingSitePrice`
                          ),
                          type: "number",
                          width: 100,
                        },
                      ]}
                    />
                  </>
                ) : (
                  <Container
                    title={
                      t(
                        `formLabels.details.buildingSite.totalStaffBuildingSitePrice`
                      ) + t(`titles.calculatedSection`)
                    }
                    bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                    direction={"column"}
                    spacing={"1rem"}
                  >
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].buildingSite.totalStaffBuildingSitePrice`,
                          label: t(
                            `formLabels.details.buildingSite.totalStaffBuildingSitePrice`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.buildingSite.totalStaffBuildingSitePrice`
                          ),
                          type: "number",
                          width: 100,
                          disabled: true,
                          value:
                            +props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite.staffBuildingSiteHours *
                            +props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].buildingSite.staffBuildingSiteHourPrice,
                        },
                      ]}
                    />

                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].buildingSite.staffBuildingSiteHours`,
                          label: t(
                            `formLabels.details.buildingSite.staffBuildingSiteHours`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.buildingSite.staffBuildingSiteHours`
                          ),
                          type: "number",
                          width: 100,
                        },
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].buildingSite.staffBuildingSiteHourPrice`,
                          label: t(
                            `formLabels.details.buildingSite.staffBuildingSiteHourPrice`
                          ),
                          placeholder: t(
                            `formPlaceholders.details.buildingSite.staffBuildingSiteHourPrice`
                          ),
                          type: "number",
                          width: 100,
                        },
                      ]}
                    />
                  </Container>
                )}

                {/* Taxes */}
                <Text fontSize={"2xl"} color={theme.colors.darkGrey2}>
                  {t(`formLabels.details.buildingSite.taxes`)}
                </Text>
                {props.formikProps.values.fancades[
                  props.fancadeNumber - 1
                ].buildingSite.taxes?.map((tax: number, index: number) => (
                  <>
                    <Divider orientation="horizontal" />
                    <Flex w={"100%"} alignItems={"center"}>
                      <Text fontSize={"xl"} color={theme.colors.darkGrey2}>
                        {t(`formLabels.details.buildingSite.taxes`) +
                          " #" +
                          (index + 1)}
                      </Text>
                      <CloseIcon
                        color={theme.colors.euracOrange}
                        w={"0.7rem"}
                        marginLeft={"0.5rem"}
                        marginTop={"0.5rem"}
                        onClick={() => {
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].buildingSite.taxes.splice(index, 1);
                          setUpdate(!update);
                        }}
                      />
                    </Flex>
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].buildingSite.taxes[${index}]`,
                          label: t(`formLabels.details.buildingSite.taxCost`),
                          placeholder: t(
                            `formPlaceholders.details.buildingSite.taxCost`
                          ),
                          type: "number",
                          width: 100,
                        },
                      ]}
                    />
                  </>
                ))}
                <Center paddingTop={"1rem"}>
                  <StyledButton
                    onClick={() => {
                      if (
                        !props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].buildingSite.taxes
                      )
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].buildingSite.taxes = [];
                      props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].buildingSite.taxes.push(null);
                      setUpdate(!update);
                    }}
                  >
                    {t("buttons.addTax")}
                  </StyledButton>
                </Center>
              </Container>
            )}
          </Stack>
        </Container>
      </Stack>
    );
  else return <Redirect to={"/"} />;
});

const StyledFlexHeader = styled(Flex)`
  border: 2px solid ${theme.colors.darkGrey};
  background-color: ${theme.colors.lightGrey2};
  color: ${theme.colors.euracOrange};
  padding: 0.8em;
`;

const StyledFlexBody = styled(Flex)`
  border: 2px solid ${theme.colors.darkGrey};
  border-top: 0px;
  padding: 0.8em;
`;

const StyledTab = styled(Tab)`
  color: ${theme.colors.euracOrange};
  border-radius: 500rem;
`;
const StyledButton = styled(Button)`
  color: white;
  background-color: ${theme.colors.euracOrange};
  border-radius: 500rem;

  &:hover {
    background-color: ${transparentize(0.5, theme.colors.euracOrange)};
  }
`;
