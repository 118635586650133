import React, { useCallback, useMemo, useState } from "react";
import {
  Box,
  Button,
  Center,
  CircularProgress,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../hooks/useStores";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";

import { ChevronDownIcon } from "@chakra-ui/icons";
import styled from "styled-components";
import theme from "../../../theme/theme";
import { VscGraph } from "react-icons/vsc";
import { useEffect } from "react";
import { Fancade, Project } from "../../../types";
import { DeleteAlert } from "../../Alert";
import { GeneralPlots } from "../../Plots/GeneralPlots";
import { ProductionPlots } from "../../Plots/ProductionPlots";
import { ConstructionPlots } from "../../Plots/ConstructionPlots";
import { MaintenancePlots } from "../../Plots/MaintenancePlots";
import { ProjectPDF } from "../../ProjectPDF";
import { IoArrowBackCircleOutline } from "react-icons/io5";

interface RouteParams {
  projectId: string;
}

export const PlotsSummary: React.FC = observer(() => {
  const { t } = useTranslation();
  const { session, ui, projects } = useStores();
  const { projectId } = useParams<RouteParams>();
  const [toRedirect, setToRedirect] = useState(false);
  const [deleteProjectOpen, setDeleteProjectOpen] = useState(false);

  const toast = useToast();
  let currentProject = useMemo(() => {
    return projects.projects.find((element) => {
      return +element.id == +projectId;
    });
  }, [projects.projects]);

  const isBenchmark = useMemo(() => {
    return currentProject ? currentProject.isBenchmark : false;
  }, [currentProject]);

  const history = useHistory();

  ui.setActiveScreen("projects");

  useEffect(() => {
    projects
      .getProjectFancades(parseInt(projectId))
      .then((fancades: Fancade[]) => {
        if (currentProject) currentProject.fancades = fancades;
      });
  }, [projects, currentProject]);

  const redirectToProjectSummary = useCallback(
    (projectId: string) => {
      if (session.user) {
        history.push(`/user/${session.user.id}/projects/${projectId}/summary`);
      }
    },
    [history]
  );

  if (!session.user) {
    return <Redirect to={"/"} />;
  }

  if (session.user) {
    if (toRedirect)
      return <Redirect to={"/user/" + session.user?.id + "/projects"} />;

    if (!currentProject)
      return (
        <Center height={"100%"}>
          <CircularProgress
            size={"2em"}
            color={theme.colors.euracOrange}
            isIndeterminate
          />
        </Center>
      );
    return (
      <Stack w={"99%"} minW={"43rem"}>
        <Flex
          alignItems={"center"}
          paddingLeft={"1rem"}
          paddingTop={"0.5rem"}
          paddingRight={"5%"}
          h={"6vh"}
          minH={"3rem"}
        >
          <Link to={`/user/${session.user.id}/projects/${projectId}/summary`}>
            <IoArrowBackCircleOutline size={"2.3rem"} color={"#474747"} />
          </Link>
          <Link to={`/user/${session.user.id}/projects`}>
            <Text fontSize={"2xl"} textColor={theme.colors.euracOrange}>
              {t("titles.report.projects")}
            </Text>
          </Link>
          <Text
            fontSize={"2xl"}
            textColor={theme.colors.lightGrey}
            marginLeft={"0.5%"}
            cursor={"pointer"}
            onClick={() => redirectToProjectSummary(projectId)}
          >
            {" / " + currentProject?.name + " (#" + projectId + ")"}
          </Text>

          <Button
            colorScheme={"gray"}
            marginRight={"1rem"}
            marginLeft={"auto"}
            onClick={() => {
              setDeleteProjectOpen(true);
            }}
            rounded={"500rem"}
          >
            {t("buttons.deleteProject")}
          </Button>
        </Flex>

        <Tabs variant={"unstyled"} flexGrow={1}>
          <Flex>
            <TabList
              minW={"14rem"}
              maxW={"14rem"}
              flexDirection={"column"}
              h={"88vh"}
              overflowY={"scroll"}
              alignItems={"flex-start"}
              padding={"0.3rem"}
            >
              <StyledTab
                _selected={{
                  color: "white",
                  bg: theme.colors.euracOrange,
                }}
              >
                <Center>
                  <Flex w={"1.7rem"}>
                    <VscGraph size={"1.7rem"} />
                  </Flex>
                  <Text
                    fontSize={"lg"}
                    paddingLeft={"0.2rem"}
                    textAlign={"left"}
                  >
                    {t("tabs.summary.generalPlots")}
                  </Text>
                </Center>
              </StyledTab>
              <StyledTab
                _selected={{
                  color: "white",
                  bg: theme.colors.euracOrange,
                }}
              >
                <Center>
                  <Flex w={"1.7rem"}>
                    <VscGraph size={"1.7rem"} />
                  </Flex>
                  <Text
                    fontSize={"lg"}
                    paddingLeft={"0.2rem"}
                    textAlign={"left"}
                  >
                    {t("tabs.summary.fancadeProductionPlots")}
                  </Text>
                </Center>
              </StyledTab>
              <StyledTab
                _selected={{
                  color: "white",
                  bg: theme.colors.euracOrange,
                }}
              >
                <Center>
                  <Flex w={"1.7rem"}>
                    <VscGraph size={"1.7rem"} />
                  </Flex>
                  <Text
                    fontSize={"lg"}
                    paddingLeft={"0.2rem"}
                    textAlign={"left"}
                  >
                    {t("tabs.summary.constructionPlots")}
                  </Text>
                </Center>
              </StyledTab>
              <StyledTab
                _selected={{
                  color: "white",
                  bg: theme.colors.euracOrange,
                }}
              >
                <Center>
                  <Flex w={"1.7rem"}>
                    <VscGraph size={"1.7rem"} />
                  </Flex>
                  <Text
                    fontSize={"lg"}
                    paddingLeft={"0.2rem"}
                    textAlign={"left"}
                  >
                    {t("tabs.summary.maintenancePlots")}
                  </Text>
                </Center>
              </StyledTab>
            </TabList>
            <TabPanels w={"100%"}>
              <TabPanel w={"100%"}>
                <Stack w={"100%"}>
                  {currentProject && (
                    <GeneralPlots
                      projectId={currentProject.id}
                      isBenchmark={isBenchmark}
                    />
                  )}
                </Stack>
              </TabPanel>
              <TabPanel w={"100%"}>
                {currentProject && (
                  <ProductionPlots
                    projectId={currentProject.id}
                    isBenchmark={isBenchmark}
                  />
                )}
              </TabPanel>
              <TabPanel w={"100%"}>
                {currentProject && (
                  <ConstructionPlots
                    projectId={currentProject.id}
                    isBenchmark={isBenchmark}
                  />
                )}
              </TabPanel>
              <TabPanel w={"100%"}>
                {currentProject && (
                  <MaintenancePlots
                    projectId={currentProject.id}
                    isBenchmark={isBenchmark}
                  />
                )}
              </TabPanel>
            </TabPanels>
          </Flex>
        </Tabs>
        <DeleteAlert
          title={t("warnings.deleteProjectTitle")}
          body={t("warnings.deleteProject")}
          isOpen={deleteProjectOpen}
          setIsOpen={setDeleteProjectOpen}
          cancelText={t("warnings.cancel")}
          continueText={t("warnings.confirm")}
          onContinue={async () => {
            if (currentProject) await projects.deleteProject(currentProject);
            toast({
              title: t("successes.deletes.projectSuccessful"),
              status: "success",
              duration: 4000,
              isClosable: true,
            });
            setToRedirect(true);
          }}
        />
      </Stack>
    );
  } else return <Redirect to={"/"} />;
});

const StyledFlex = styled(Flex)`
  cursor: pointer;
  color: ${theme.colors.euracOrange};
`;
const StyledTab = styled(Tab)`
  color: ${theme.colors.euracOrange};
  border-radius: 500rem;
`;
const StyledDivider = styled(Divider)`
  border-color: black;
`;
