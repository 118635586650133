import React, { useEffect, useState } from "react";
import { HashRouter as Router, Route } from "react-router-dom";
import {
  Center,
  ChakraProvider,
  CircularProgress,
  Text,
} from "@chakra-ui/react";

import { Stores } from "../../types";
import { StoresContext } from "../../contexts";
import { SessionStore } from "../../stores/Session";
import { UiStore } from "../../stores/Ui";
import { ProjectsStore } from "../../stores/Projects";

import "../../i18n";

import { Login } from "./../Login/index";
import { AppLayout } from "./../AppLayout";
import { PrivateRoute } from "../../components/PrivateRoute";
import { RedirectToUserBoard } from "./RedirectToUserBoard";
import { observer } from "mobx-react";
import { UsersStore } from "../../stores/Users";
import theme from "./../../theme/fonts";
import Fonts from "../../components/Fonts";
import PDFDownloadPage from "../PDFDownloadPage";
import PDFExportPage from "../ExportBIM-P/PDFExportPage";
import i18n from "../../i18n";

const Root: React.FC = observer((Props) => {
  const [stores] = useState<Stores>(() => {
    const root: Stores = {} as Stores;

    root.session = new SessionStore(root);
    root.ui = new UiStore(root);
    root.projects = new ProjectsStore(root);
    root.users = new UsersStore(root);

    return root;
  });

  useEffect(() => {
    if (!stores.session.isInitialized) {
      stores.session.initialize();
    }
  }, [stores.session.isInitialized]);

  if (!stores.session.isInitialized) {
    return (
      <Center height={"100vh"} flexDirection={"column"}>
        <CircularProgress size={"5rem"} color={"#df1b12"} isIndeterminate />
        <Text>{i18n.t("loading")}</Text>
      </Center>
    );
  }

  return (
    <StoresContext.Provider value={stores}>
      <ChakraProvider theme={theme}>
        <Fonts />
        <Router>
          <Route exact path={`/`} component={RedirectToUserBoard} />
          <Route path={"/login"} component={Login} />
          <Route path={"/download"} component={PDFDownloadPage} />
          <Route path={"/export"} component={PDFExportPage} /> 
          <PrivateRoute
            isAuthenticated={stores.session.isLogged}
            path={"/user/:uid"}
            component={AppLayout}
          />
        </Router>
      </ChakraProvider>
    </StoresContext.Provider>
  );
});

export default Root;
