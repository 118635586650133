import { ChevronDownIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  useDisclosure,
  useToast,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { transparentize } from "polished";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { PrimaryButton } from "../../components/Button";
import { CredentialsModal } from "../../components/CredentialsModal";
import { UserModal } from "../../components/UserModal";
import { useStores } from "../../hooks/useStores";
import { User } from "../../types";
import { Redirect } from "react-router-dom";

export const UserList: React.FC = observer(() => {
  const { users, session } = useStores();
  const { t } = useTranslation();
  const toast = useToast();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentCredentialsUser, setCurrentCredentialsUser] = useState<
    User | undefined
  >();
  const [userToDelete, setUserToDelete] = useState<User | undefined>();

  useEffect(() => {
    users.fetchUsers();
  }, []);

  if (session.user?.role != "admin") {
    return <Redirect to={"/"} />;
  }

  return (
    <Stack
      marginRight={"2rem"}
      marginTop={"1.5rem"}
      width={"100%"}
      overflowY={"scroll"}
    >
      <Modal
        onClose={() => {
          setUserToDelete(undefined);
          onClose();
        }}
        isOpen={isOpen}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("warnings.deleteUserTitle")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{t("warnings.deleteUser")}</Text>
          </ModalBody>
          <ModalFooter>
            <PrimaryButton
              marginRight={"1rem"}
              onClick={async () => {
                if (userToDelete) await users.deleteUser(userToDelete);
                toast({
                  title: t("successes.deletes.userSuccessful"),
                  status: "success",
                  duration: 9000,
                  isClosable: true,
                });
                onClose();
              }}
            >
              {t("warnings.confirm")}
            </PrimaryButton>
            <StyledButtonGrey
              onClick={() => {
                setUserToDelete(undefined);
                onClose();
              }}
            >
              {t("warnings.cancel")}
            </StyledButtonGrey>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {session.user?.role == "admin" && (
        <HStack justifyContent={"flex-end"}>
          <PrimaryButton onClick={() => setIsModalVisible(true)}>
            {t("buttons.new")}
          </PrimaryButton>
        </HStack>
      )}

      <Table>
        <Thead>
          <Tr>
            <Th fontSize={"md"}>{t("users.id")}</Th>
            <Th fontSize={"md"}>{t("users.nominative")}</Th>
            <Th fontSize={"md"}>{t("users.email")}</Th>
            <Th fontSize={"md"}>{t("users.role")}</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {users.users.map((user) => (
            <Tr>
              <Td>{user.id}</Td>
              <Td>{user.nominative}</Td>
              <Td>{user.email}</Td>
              <Td>{t("role." + user.role)}</Td>
              <Td>
                <Menu>
                  <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                    {t("users.manage")}
                  </MenuButton>
                  <MenuList>
                    {user.disabled ? (
                      <MenuItem
                        onClick={async () => {
                          const temp = { ...user };
                          temp.disabled = false;
                          await users.updateUser(temp);
                          toast({
                            title: t("successes.updates.userEnabled"),
                            status: "success",
                            duration: 9000,
                            isClosable: true,
                          });
                        }}
                      >
                        {t("users.enable")}
                      </MenuItem>
                    ) : (
                      <MenuItem
                        onClick={async () => {
                          const temp = { ...user };
                          temp.disabled = true;
                          await users.updateUser(temp);
                          toast({
                            title: t("successes.updates.userDisabled"),
                            status: "success",
                            duration: 9000,
                            isClosable: true,
                          });
                        }}
                      >
                        {t("users.disable")}
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={() => {
                        setUserToDelete(user);
                        onOpen();
                      }}
                    >
                      {t("users.delete")}
                    </MenuItem>
                  </MenuList>
                </Menu>
                {/* <CloseIcon
                  color={"red"}
                  cursor={"pointer"}
                  onClick={() => {
                    setUserToDelete(user);
                    onOpen();
                  }}
                /> */}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <UserModal
        isVisible={isModalVisible}
        onSubmit={async (data) => {
          const user = await users.createUser(data);
          if (user == undefined) {
            toast({
              title: "Errore",
              description: t("errors.signUp.alreadyExists"),
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          } else {
            toast({
              title: t("successes.creates.userSuccessful"),
              status: "success",
              duration: 9000,
              isClosable: true,
            });
            setIsModalVisible(false);
            setCurrentCredentialsUser(user);
          }
        }}
        onDismiss={() => setIsModalVisible(false)}
      />
      <CredentialsModal
        isVisible={currentCredentialsUser != undefined}
        onDismiss={() => setCurrentCredentialsUser(undefined)}
        user={currentCredentialsUser}
      />
    </Stack>
  );
});

const StyledButtonGrey = styled(Button)`
  border-radius: 500rem;
  color: ${(props) => (!props.disabled ? "white" : "black")};

  background-color: ${(props) =>
    !props.disabled ? "grey" : transparentize(0.85, "grey")};

  &:hover {
    background-color: ${transparentize(0.5, "grey")};
  }
`;
