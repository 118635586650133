import {
  toCategory,
  toFancadeAPI,
  toFancadeFromAPI,
  toProject,
  toUser,
} from "./utils";
import { User, Project, FancadeAPI, Fancade, Category } from "./../types";
import keys from "../config/keys";

let sessionToken: string | undefined;

const getSessionToken = () => {
  return sessionToken;
};
const setSession = (token: string) => {
  sessionToken = token;
};

const clearSession = () => {
  sessionToken = undefined;
};

const parseErrorMessage = async (response: Response) => {
  try {
    const data = await response.json();
    if (!data.ErrorMessage) {
      throw new Error();
    }
    return data.ErrorMessage;
  } catch (err) {
    return "";
  }
};

const makeApiRequest = async (
  method: "POST" | "GET" | "PATCH" | "DELETE",
  path: string,
  body?: any
): Promise<any> => {
  const headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
  });
  if (sessionToken) {
    headers.append("Authorization", `Bearer ${sessionToken}`);
  }

  try {
    const response = await fetch(`${keys.API_BASE_URL}${path}`, {
      method: method,
      body: body ? JSON.stringify(body) : undefined,
      headers: headers,
    });

    if (!response.ok) {
      const message = await parseErrorMessage(response);
      throw new Error(`${response.status}: ${response.statusText}. ${message}`);
    }
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    console.warn(`Failed request ${method} ${path}`, err);
    throw err;
  }
};

const login = async (email: string, password: string) => {
  // delete old token
  localStorage.removeItem("session");
  const body = {
    email,
    password,
  };
  const response = await makeApiRequest("POST", "auth/basic", body);

  localStorage.setItem("session", response.token);
  setSession(response.token);

  return toUser(response.user);
};

const getCurrentUser = async (): Promise<User | null> => {
  const sessionData = localStorage.getItem("session");
  if (!sessionData) return null;
  setSession(JSON.parse(sessionData).token);
  const result = await makeApiRequest("GET", `auth/me`);

  return toUser(result);
};

const fetchUsers = async (): Promise<User[]> => {
  const response = await makeApiRequest("GET", "users");
  return response.map((item: any) => toUser(item));
};

const createUser = async (data: any): Promise<User> => {
  const payload = {
    email: data.email,
    nominative: data.nominative,
    role: data.role,
  };
  let response = await makeApiRequest("POST", "users", payload);
  return toUser(response);
};

const updateUser = async (user: User) => {
  const response = await makeApiRequest("PATCH", "users/" + user.id, user);
  return toUser(response);
};

const deleteUser = async (user: User) => {
  const response = await makeApiRequest("DELETE", "users/" + user.id);
  return toUser(response);
};

//------------------------------PROJECTS------------------------------
const getCurrentUserProjects = async () => {
  const response = await makeApiRequest("GET", "projects");
  let toReturn: Project[] = [];
  response.map(async (item: any) => {
    toReturn.push(toProject(item));
  });
  return toReturn;
};

const createProject = async (project: Project) => {
  const payload = {
    name: project.name,
    location: project.location,
    reportAuthor: project.reportAuthor,
    description: project.description,
    type: project.type,
    certification: project.certification,
    useDestination: project.useDestination,
    constructionYear: +project.constructionYear,
    isBenchmark: project.isBenchmark,
    categoryId: +project.categoryId,
  };
  const response = await makeApiRequest("POST", "projects", payload);
  return toProject(response);
};

const updateProject = async (project: Project) => {
  let temp: FancadeAPI[] = [];

  if (project.fancades) {
    await Promise.all(
      project.fancades.map( async (fancade: Fancade) => {
        if (fancade) {
          var ftemp = [{
            id: fancade.id,
            customName: fancade.customName,
            projectId: project.id,
            fancadeTechnicalProject: JSON.stringify(
              fancade.fancadeTechnicalProject
            ),
            fancadeProject: JSON.stringify(fancade.fancadeProject),
            fancadeProduction: JSON.stringify(fancade.fancadeProduction),
            transport: JSON.stringify(fancade.transport),
            buildingSite: JSON.stringify(fancade.buildingSite),
            serviceLife: JSON.stringify(fancade.serviceLife),
            maintenance: JSON.stringify(fancade.maintenance),
            costsActualization: JSON.stringify(fancade.costsActualization),
            endOfLife: JSON.stringify(fancade.endOfLife),
            notes: JSON.stringify(fancade.notes),
          }];  

          var payload = {
            fancades: ftemp
          };
          const fresponse = await makeApiRequest(
            "PATCH",
            "projects/" + project.id,
            payload
          );
        }
      })
    )
  }

  const payload = {
    name: project.name,
    location: project.location,
    reportAuthor: project.reportAuthor,
    description: project.description,
    type: project.type,
    certification: project.certification,
    useDestination: project.useDestination,
    constructionYear: +project.constructionYear,
    isBenchmark: project.isBenchmark,
    // fancades: temp,
    categoryId: +project.categoryId,
  };

  const response = await makeApiRequest(
    "PATCH",
    "projects/" + project.id,
    payload
  );
  return toProject(response);
};

const deleteProject = async (project: Project) => {
  const response = await makeApiRequest("DELETE", "projects/" + project.id);
  return toProject(response);
};

//------------------------------FANCADES------------------------------
const getProjectFancades = async (projectId: number) => {
  const response = await makeApiRequest(
    "GET",
    "projects/" + projectId + "/fancades"
  );
  let toReturn = response.map((item: any) => toFancadeFromAPI(item));
  return toReturn;
};

const createFancade = async (fancade: FancadeAPI) => {
  const payload = {
    fancadeTechnicalProject: fancade.fancadeTechnicalProject,
  };
  const response = await makeApiRequest(
    "POST",
    "projects/" + fancade.projectId + "/fancades",
    payload
  );
  return toFancadeAPI(response);
};

const updateFancade = async (fancade: FancadeAPI) => {
  const payload = {
    fancadeTechnicalProject: fancade.fancadeTechnicalProject,
  };
  const response = await makeApiRequest(
    "PATCH",
    "projects/" + fancade.projectId + "/fancades/" + fancade.id,
    payload
  );
  return toFancadeAPI(response);
};

const deleteFancade = async (fancade: FancadeAPI) => {
  const response = await makeApiRequest(
    "DELETE",
    "projects/" + fancade.projectId + "/fancades/" + fancade.id
  );
  return toFancadeAPI(response);
};

//------------------------------CATEGORIES------------------------------
const getCategories = async () => {
  const response = await makeApiRequest("GET", "categories");
  let toReturn = response.map((item: any) => toCategory(item));
  return toReturn;
};

const createCategory = async (category: Category) => {
  const response = await makeApiRequest("POST", "categories", {
    name: category.name,
  });
  return toCategory(response);
};

const updateCategory = async (category: Category) => {
  const response = await makeApiRequest(
    "PATCH",
    "categories/" + category.id,
    category
  );
  return toCategory(response);
};

const deleteCategory = async (category: Category) => {
  const response = await makeApiRequest("DELETE", "categories/" + category.id);
  return toCategory(response);
};

const api = {
  getSessionToken,
  setSession,
  clearSession,
  login,
  getCurrentUser,
  fetchUsers,
  createUser,
  updateUser,
  deleteUser,
  getCurrentUserProjects,
  createProject,
  updateProject,
  deleteProject,
  getProjectFancades,
  deleteFancade,
  getCategories,
  createCategory,
  updateCategory,
  deleteCategory,
  parseErrorMessage,
};

export default api;
