import React, { useCallback } from "react";
import {
  Button,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../../../../hooks/useStores";
import { Redirect, useParams } from "react-router-dom";
import {
  Fancade,
  FancadeProjectType,
  Project,
  TableRow,
} from "../../../../../../types";

import theme from "../../../../../../theme/theme";
import { DetailsTable } from "../../DetailsTable";
import { FancadeProjectForm } from "./FancadeProjectForm";
import {
  calculateBenchmark,
  calculateFancadeDesignTotalCost,
  toFancadeAPI,
} from "../../../../../../services/utils";
import { DeleteAlert } from "../../../../../Alert";
import { AiFillEdit } from "react-icons/ai";
import { ChangeFancadeNameModal } from "../../../../../ChangeFancadeNameModal";

interface RouteParams {
  projectId: string;
}

type Props = {
  formikProps: any;
  tabIndex: any;
  setTabIndex: any;
  isBenchmark: boolean;
  update: boolean;
  setUpdate: any;
};

export const FancadeProject: React.FC<Props> = observer((props) => {
  const { t } = useTranslation();
  const { session, ui, projects } = useStores();
  const { projectId } = useParams<RouteParams>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [deleteFancadeOpen, setDeleteFancadeOpen] = React.useState(false);
  const [updateKpi, setUpdateKpi] = React.useState(false);
  const [fancadeToUpdate, setFancadeToUpdate] = React.useState<number>(0);
  const [update, setUpdate] = React.useState(false);

  ui.setActiveScreen("projects");

  let tabs: any[] = [];
  let tabPanels: any[] = [];

  const handleTabsChange = (index: number) => {
    props.setTabIndex(index);
  };

  const currentProject: Project = props.formikProps.values;

  const getPriceKPIsRows = (fancadeNumber: number) => {
    let kpiRows: TableRow[] = [];

    const currentFancade: Fancade =
      props.formikProps.values?.fancades[fancadeNumber - 1];
    //Calculate total design price if not manual
    let totalPrice = 0;
    const temp: FancadeProjectType =
      props.formikProps.values?.fancades[fancadeNumber - 1]?.fancadeProject;
    totalPrice = calculateFancadeDesignTotalCost(temp);
    if (
      currentFancade &&
      currentFancade.fancadeKpiFancadeProject &&
      currentFancade.fancadeKpiFancadeProject.totalDesignPrice
    )
      currentFancade.fancadeKpiFancadeProject.totalDesignPrice = totalPrice;
    //Add total design price to table
    kpiRows.push({
      name: t("tablesVoices.report.fancadeKPIs.totalDesignPrice"),
      values: [
        (+totalPrice).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " €",
      ],
    });
    let surface = 0;
    if (currentFancade?.fancadeTechnicalProject?.surface)
      surface = currentFancade.fancadeTechnicalProject.surface;
    kpiRows.push({
      name: t("tablesVoices.report.fancadeKPIs.fancadeSurface"),
      values: [
        (+surface).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " m²",
      ],
    });

    //KPI
    let kpi = 0;
    if (surface != 0 && totalPrice != 0) kpi = totalPrice / surface;
    if (currentFancade?.fancadeKpiFancadeProject)
      currentFancade.fancadeKpiFancadeProject.totalDesignPriceKPI = kpi;
    kpiRows.push({
      name: t("tablesVoices.report.fancadeKPIs.kpiDesignPrice"),
      values: [
        (+kpi).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " €/m²",
      ],
    });

    return kpiRows;
  };

  const getDeltaRows = (fancadeNumber: number) => {
    let deltaRows: TableRow[] = [];
    let deltaTotal = 0;
    let deltaKPI = 0;

    const currentFancade: Fancade =
      props.formikProps.values.fancades[fancadeNumber - 1];
    const benchmarkFancade: Fancade = props.formikProps.values.fancades[0];

    //Get benchmark values
    const totalPriceBenchmark = calculateFancadeDesignTotalCost(
      benchmarkFancade?.fancadeProject
    );
    const benchmarkKPI =
      benchmarkFancade?.fancadeKpiFancadeProject?.totalDesignPriceKPI;

    //Get current fancade values
    const totalPriceCurrentFancade = calculateFancadeDesignTotalCost(
      currentFancade?.fancadeProject
    );
    const currentFancadeKPI =
      currentFancade?.fancadeKpiFancadeProject?.totalDesignPriceKPI;

    if (totalPriceBenchmark && totalPriceCurrentFancade)
      deltaTotal = calculateBenchmark(
        +totalPriceBenchmark,
        +totalPriceCurrentFancade
      );
    if (benchmarkKPI && currentFancadeKPI)
      deltaKPI = calculateBenchmark(+benchmarkKPI, +currentFancadeKPI);

    deltaRows.push({
      name: t("tablesVoices.report.delta.totalDesignCost"),
      values: [
        deltaTotal.toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " €",
      ],
    });

    deltaRows.push({
      name: t("tablesVoices.report.delta.designCostKPI"),
      values: [
        deltaKPI.toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " €/m²",
      ],
    });

    return deltaRows;
  };

  const getProjectKPIsRows = () => {
    let totalDesignPrice = 0;
    let totalSurface = 0;
    let totalDesignKPI = 0;
    if (props.formikProps.values.fancades) {
      props.formikProps.values?.fancades?.map((fancade: Fancade) => {
        totalDesignPrice += +calculateFancadeDesignTotalCost(
          fancade?.fancadeProject
        );

        if (fancade?.fancadeTechnicalProject?.surface)
          totalSurface += +fancade.fancadeTechnicalProject.surface;
      });
    }

    totalDesignKPI = +totalDesignPrice / +totalSurface;

    //Assign values to Formik
    props.formikProps.values.kpiFancadeProject = {
      totalDesignPrice,
      totalFancadeSurface: totalSurface,
      totalDesignPriceKPI: totalDesignKPI,
    };

    return [
      {
        name: t("tablesVoices.report.allFancades.totalDesignPrice"),
        values: [
          totalDesignPrice.toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " €",
        ],
      },
      {
        name: t("tablesVoices.report.allFancades.fancadeSurface"),
        values: [
          totalSurface.toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " m²",
        ],
      },
      {
        name: t("tablesVoices.report.allFancades.kpiDesignPrice"),
        values: [
          totalDesignKPI.toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " €/m²",
        ],
      },
    ];
  };

  currentProject?.fancades?.map((fancade, index) => {
    if (tabs.length == 0)
      tabs.push(
        <Tab>
          <DeleteAlert
            title={t("warnings.deleteFancadeTitle")}
            body={t("warnings.deleteFancade")}
            isOpen={deleteFancadeOpen}
            setIsOpen={setDeleteFancadeOpen}
            cancelText={t("warnings.cancel")}
            continueText={t("warnings.confirm")}
            onContinue={async () => {
              deleteFancade(fancade, index);
            }}
          />
          <Text color={theme.colors.darkGrey2}>
            {fancade.customName
              ? currentProject.isBenchmark && index == 0
                ? t("tabs.details.fancadeTechnicalProject.benchmark") +
                  fancade.customName
                : fancade.customName
              : currentProject.isBenchmark
              ? t("tabs.details.fancadeTechnicalProject.firstTab")
              : t("tabs.details.fancadeTechnicalProject.tabsAfterFirst") +
                (index + 1)}
          </Text>
          <AiFillEdit
            style={{ marginLeft: "0.3rem" }}
            onClick={() => {
              setFancadeToUpdate(index);
              onOpen();
            }}
          />
          <Text
            onClick={() => setDeleteFancadeOpen(true)}
            color={theme.colors.euracOrange}
            paddingLeft={"0.5rem"}
          >
            <strong>x</strong>
          </Text>
        </Tab>
      );
    else
      tabs.push(
        <Tab alignItems={"center"}>
          <DeleteAlert
            title={t("warnings.deleteFancadeTitle")}
            body={t("warnings.deleteFancade")}
            isOpen={deleteFancadeOpen}
            setIsOpen={setDeleteFancadeOpen}
            cancelText={t("warnings.cancel")}
            continueText={t("warnings.confirm")}
            onContinue={async () => {
              deleteFancade(fancade, index);
            }}
          />
          <Text color={theme.colors.darkGrey2}>
            {fancade.customName
              ? currentProject.isBenchmark && index == 0
                ? t("tabs.details.fancadeTechnicalProject.benchmark") +
                  fancade.customName
                : fancade.customName
              : t("tabs.details.fancadeTechnicalProject.tabsAfterFirst") +
                (tabs.length + 1)}
          </Text>
          <AiFillEdit
            style={{ marginLeft: "0.3rem" }}
            onClick={() => {
              setFancadeToUpdate(index);
              onOpen();
            }}
          />
          <Text
            onClick={() => setDeleteFancadeOpen(true)}
            color={theme.colors.euracOrange}
            paddingLeft={"0.5rem"}
          >
            <strong>x</strong>
          </Text>
        </Tab>
      );

    tabPanels.push(
      <TabPanel>
        <FancadeProjectForm
          formikProps={props.formikProps}
          currentFancade={fancade}
          fancadeNumber={tabs.length}
          setUpdateKpi={setUpdateKpi}
          updateKpi={updateKpi}
        />
        <DetailsTable
          rows={getPriceKPIsRows(tabs.length)}
          columnsTitles={[t("tablesHeaders.KPIFancadeDesign")]}
          style={{ marginTop: "2rem" }}
        />
        {index > 0 && props.isBenchmark && (
          <DetailsTable
            rows={getDeltaRows(tabs.length)}
            columnsTitles={[t("tablesHeaders.deltaAgainstBenchmark")]}
            style={{ marginTop: "2rem" }}
          />
        )}
      </TabPanel>
    );
  });

  const addFancade = useCallback(() => {
    if (!currentProject.fancades) currentProject.fancades = [];
    currentProject.fancades.push(projects.getEmptyFancade(+projectId));
    if (currentProject && currentProject.fancades) {
      props.setTabIndex(currentProject.fancades.length - 1);
    }
    setUpdate(!update);
  }, [props.tabIndex, projects, currentProject, props.formikProps, update]);

  const deleteFancade = useCallback(
    (fancade: Fancade, index: number) => {
      if (fancade.id != -1) projects.deleteFancade(toFancadeAPI(fancade));
      props.formikProps.values.fancades.splice(index, 1);
      if (props.tabIndex == index && props.tabIndex > 0) {
        props.setTabIndex(props.tabIndex - 1);
      }
    },
    [
      props.tabIndex,
      projects,
      currentProject,
      props.formikProps.values.fancades,
    ]
  );

  if (session.user)
    return (
      <Stack w={"100%"}>
        <ChangeFancadeNameModal
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={(values: any) => {
            if (currentProject && currentProject.fancades)
              currentProject.fancades[fancadeToUpdate].customName =
                values.customName;
            onClose();
          }}
        />
        <Tabs
          index={props.tabIndex}
          onChange={handleTabsChange}
          variant={"enclosed"}
          w={"100%"}
        >
          <TabList>
            {tabs}
            <Button variant={"ghost"} onClick={addFancade}>
              <Text color={theme.colors.euracOrange}>+</Text>
            </Button>
          </TabList>
          <TabPanels>{tabPanels}</TabPanels>
        </Tabs>
        {tabs.length > 1 && (
          <DetailsTable
            rows={getProjectKPIsRows()}
            columnsTitles={[t("tablesHeaders.KPIAllfancades")]}
          />
        )}
      </Stack>
    );
  else return <Redirect to={"/"} />;
});
