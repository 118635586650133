import React, { useState } from "react";
import {
  Button,
  Flex,
  Stack,
  Text,
  Input,
  Image,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { FormControl, FormErrorMessage } from "@chakra-ui/form-control";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import { Formik, Form, Field, FieldProps } from "formik";
import * as Yup from "yup";

import styled from "styled-components";
import { transparentize } from "polished";
import theme from "./../theme/theme";

import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

type Props = {
  onSubmit: (email: string, password: string) => any;
  isLoading: boolean;
};

export const LoginForm: React.FC<Props> = observer((props) => {
  const { t } = useTranslation();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const loginValidationSchema = Yup.object().shape({
    email: Yup.string()
      .required(t("errors.login.missingEmail"))
      .email(t("errors.login.invalidEmail")),
    password: Yup.string().required(t("errors.login.missingPassword")),
  });

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      validationSchema={loginValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values.email, values.password);
      }}
    >
      {(formikProps) => (
        <StyledForm>
          <StyledFlex justifyContent={"center"}>
            <Image
              width={"70%"}
              src={
                "https://upload.wikimedia.org/wikipedia/commons/9/9b/Eurac_Research_-_logo.png"
              }
              alt="Eurac Research"
            />
          </StyledFlex>
          <Text fontSize={"4xl"} style={{ marginBottom: "1em" }}>
            Login
          </Text>
          <Stack spacing={"1rem"} style={{ marginBottom: "2em" }}>
            <Text fontSize={"ms"}>{t("formLabels.email")}</Text>
            <Field name={"email"}>
              {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                  <Input
                    {...field}
                    type={"email"}
                    name={field.name}
                    value={field.value}
                    placeholder={t("formPlaceholders.email")}
                    onChange={(event: any) =>
                      setFieldValue(field.name, event.target.value)
                    }
                  />
                  <FormErrorMessage name={"email"}>
                    {meta.error}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Text fontSize={"ms"}>{t("formLabels.password")}</Text>
            <Field name={"password"}>
              {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                  <InputGroup size="lg">
                    <Input
                      {...field}
                      type={isPasswordVisible ? "text" : "password"}
                      name={field.name}
                      value={field.value}
                      placeholder={t("formPlaceholders.password")}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                    />
                    <InputRightElement width="4.5rem">
                      {isPasswordVisible ? (
                        <AiFillEyeInvisible
                          onClick={() =>
                            setIsPasswordVisible(!isPasswordVisible)
                          }
                        />
                      ) : (
                        <AiFillEye
                          onClick={() =>
                            setIsPasswordVisible(!isPasswordVisible)
                          }
                        />
                      )}
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage name={"password"}>
                    {meta.error}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <StyledButton
              disabled={!formikProps.dirty || !formikProps.isValid}
              type={"submit"}
              isLoading={props.isLoading}
              style={{ marginTop: "3em" }}
              rounded={"500rem"}
            >
              {t("buttons.login")}
            </StyledButton>
          </Stack>
        </StyledForm>
      )}
    </Formik>
  );
});

const StyledForm = styled(Form)`
  width: 80%;
`;

const StyledFlex = styled(Flex)`
  margin-top: 1.5em;
  margin-bottom: 1.5em;
`;

const StyledButton = styled(Button)`
  color: white;
  background-color: ${theme.colors.euracOrange};
  border-radius: 500rem;
  &:hover {
    background-color: ${transparentize(0.5, theme.colors.euracOrange)};
  }
`;
