import React, { useCallback } from "react";
import {
  Button,
  Flex,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../../../../hooks/useStores";
import { Redirect, useParams } from "react-router-dom";
import { MaintenanceForm } from "./MaintenanceForm";
import { Fancade, Project } from "../../../../../../types";

import theme from "../../../../../../theme/theme";
import { AiFillEdit } from "react-icons/ai";
import {
  calculateBenchmark,
  calculateMaintenanceTotal,
  calculateSystemsIntegrationsMaintenance,
  toFancadeAPI,
} from "../../../../../../services/utils";
import { DetailsTable } from "../../DetailsTable";
import { DeleteAlert } from "../../../../../Alert";
import { ChangeFancadeNameModal } from "../../../../../ChangeFancadeNameModal";

interface RouteParams {
  projectId: string;
}

type Props = {
  formikProps: any;
  tabIndex: any;
  setTabIndex: any;
  isBenchmark: boolean;
};
export const Maintenance: React.FC<Props> = observer((props) => {
  const { t } = useTranslation();
  const { session, ui, projects } = useStores();
  const { projectId } = useParams<RouteParams>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [deleteFancadeOpen, setDeleteFancadeOpen] = React.useState(false);
  const [fancadeToUpdate, setFancadeToUpdate] = React.useState<number>(0);
  const [update, setUpdate] = React.useState(false);

  ui.setActiveScreen("projects");

  const handleTabsChange = (index: number) => {
    props.setTabIndex(index);
  };

  const currentProject: Project = props.formikProps.values;

  const addFancade = useCallback(() => {
    if (!currentProject.fancades) currentProject.fancades = [];
    currentProject.fancades.push(projects.getEmptyFancade(+projectId));
    if (currentProject && currentProject.fancades) {
      props.setTabIndex(currentProject.fancades.length - 1);
    }
    setUpdate(!update);
  }, [props.tabIndex, projects, currentProject, props.formikProps, update]);

  const deleteFancade = useCallback(
    (fancade: Fancade, index: number) => {
      if (fancade.id != -1) projects.deleteFancade(toFancadeAPI(fancade));
      props.formikProps.values.fancades.splice(index, 1);
      if (props.tabIndex == index && props.tabIndex > 0) {
        props.setTabIndex(props.tabIndex - 1);
      }
    },
    [
      props.tabIndex,
      projects,
      currentProject,
      props.formikProps.values.fancades,
    ]
  );

  const getOneFancadeKPIsRows = (fancade: Fancade) => {
    if (fancade && fancade.maintenance) {
      let rows: any[] = [];

      let maintenance = calculateMaintenanceTotal(fancade);
      let surface = 0;

      let implantsSurfacePercentage = 0;
      let implantsSurface = 0;
      let surfaceWithoutImplants = 0;
      let systemsIntegration = 0;

      let kpi1 = 0;
      let kpi2 = 0;
      let kpi3 = 0;

      if (fancade.fancadeTechnicalProject.surface)
        surface = fancade.fancadeTechnicalProject.surface;

      if (fancade.fancadeTechnicalProject.systemsSurfacePercentage)
        implantsSurfacePercentage =
          fancade.fancadeTechnicalProject.systemsSurfacePercentage;

      implantsSurface = +implantsSurfacePercentage * +surface;

      surfaceWithoutImplants = calculateBenchmark(+surface, +implantsSurface);
      systemsIntegration = calculateSystemsIntegrationsMaintenance(fancade);

      if (surface != 0) kpi1 = +maintenance / +surface;
      if (implantsSurface != 0) kpi2 = +systemsIntegration / +implantsSurface;
      if (maintenance != 0) kpi3 = +systemsIntegration / +maintenance;

      rows = [
        {
          name: t("formLabels.details.maintenance.totalMaintenancePrice"),
          values: [
            maintenance.toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " €",
          ],
        },
        {
          name: t("formLabels.details.maintenance.surface"),
          values: [
            (+surface).toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " m²",
          ],
        },
        {
          name: t("formLabels.details.maintenance.implantsSurfacePercentage"),
          values: [
            implantsSurfacePercentage.toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " %",
          ],
        },
        {
          name: t("formLabels.details.maintenance.implantsSurface"),
          values: [
            implantsSurface.toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " m²",
          ],
        },
        {
          name: t("formLabels.details.maintenance.surfaceWithoutImplants"),
          values: [
            surfaceWithoutImplants.toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " m²",
          ],
        },
        {
          name: t("formLabels.details.maintenance.systemsIntegration"),
          values: [
            systemsIntegration.toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " €",
          ],
        },
        {
          name: t("formLabels.details.maintenance.kpi1"),
          values: [
            kpi1.toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " €/m²",
          ],
        },
        {
          name: t("formLabels.details.maintenance.kpi2"),
          values: [
            kpi2.toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " €/m²",
          ],
        },
        {
          name: t("formLabels.details.maintenance.kpi3"),
          values: [
            (kpi3 * 100).toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " %",
          ],
        },
      ];

      return rows;
    }
    return [];
  };

  const getBenchmarkKPIRows = (
    project: Project,
    currentFancadeIndex: number
  ) => {
    let rows: any[] = [];
    let fancade = null;
    if (project.fancades && project.fancades[currentFancadeIndex])
      fancade = project.fancades[currentFancadeIndex];
    let benchmark = null;
    if (project.fancades && project.fancades[0])
      benchmark = project.fancades[0];
    let maintenance = 0;
    let benchmarkMaintenance = 0;
    let surface = 0;
    let benchmarkSurface = 0;

    let implantsSurfacePercentage = 0;
    let benchmarkImplantsSurfacePercentage = 0;
    let implantsSurface = 0;
    let benchmarkImplantsSurface = 0;
    let surfaceWithoutImplants = 0;
    let benchmarkSurfaceWithoutImplants = 0;
    let systemsIntegration = 0;
    let benchmarkSystemsIntegration = 0;

    let kpi1 = 0;
    let benchmarkKpi1 = 0;
    let kpi2 = 0;
    let benchmarkKpi2 = 0;
    let kpi3 = 0;
    let benchmarkKpi3 = 0;

    if (fancade) maintenance = calculateMaintenanceTotal(fancade);
    if (benchmark) benchmarkMaintenance = calculateMaintenanceTotal(benchmark);

    if (fancade && fancade.fancadeTechnicalProject.surface)
      surface = fancade.fancadeTechnicalProject.surface;
    if (benchmark && benchmark.fancadeTechnicalProject.surface)
      benchmarkSurface = benchmark.fancadeTechnicalProject.surface;

    if (fancade && fancade.fancadeTechnicalProject.systemsSurfacePercentage)
      implantsSurfacePercentage =
        fancade.fancadeTechnicalProject.systemsSurfacePercentage;
    if (benchmark && benchmark.fancadeTechnicalProject.systemsSurfacePercentage)
      benchmarkImplantsSurfacePercentage =
        benchmark.fancadeTechnicalProject.systemsSurfacePercentage;

    implantsSurface = +implantsSurfacePercentage * +surface;
    benchmarkImplantsSurface =
      +benchmarkImplantsSurfacePercentage * +benchmarkSurface;

    surfaceWithoutImplants = calculateBenchmark(surface, implantsSurface);
    benchmarkSurfaceWithoutImplants = calculateBenchmark(
      +benchmarkSurface,
      +benchmarkImplantsSurface
    );

    if (fancade)
      systemsIntegration = calculateSystemsIntegrationsMaintenance(fancade);
    if (benchmark)
      benchmarkSystemsIntegration =
        calculateSystemsIntegrationsMaintenance(benchmark);

    if (surface != 0) kpi1 = +maintenance / +surface;
    if (benchmarkSurface != 0)
      benchmarkKpi1 = +benchmarkMaintenance / +benchmarkSurface;

    if (implantsSurface != 0) kpi2 = +systemsIntegration / +implantsSurface;
    if (benchmarkImplantsSurface != 0)
      benchmarkKpi2 = +benchmarkSystemsIntegration / +benchmarkImplantsSurface;

    if (maintenance != 0) kpi3 = +systemsIntegration / +maintenance;
    if (benchmarkMaintenance != 0)
      benchmarkKpi3 = +benchmarkSystemsIntegration / +benchmarkMaintenance;

    rows = [
      {
        name: t("formLabels.details.maintenance.totalMaintenancePrice"),
        values: [
          calculateBenchmark(
            +benchmarkMaintenance,
            +maintenance
          ).toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " €",
        ],
      },
      {
        name: t("formLabels.details.maintenance.systemsIntegration"),
        values: [
          calculateBenchmark(
            +benchmarkSystemsIntegration,
            +systemsIntegration
          ).toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " €",
        ],
      },
      {
        name: t("formLabels.details.maintenance.kpi1"),
        values: [
          calculateBenchmark(+benchmarkKpi1, +kpi1).toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " €/m²",
        ],
      },
      {
        name: t("formLabels.details.maintenance.kpi2"),
        values: [
          calculateBenchmark(+benchmarkKpi2, +kpi2).toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " €/m²",
        ],
      },
      {
        name: t("formLabels.details.maintenance.kpi3"),
        values: [
          (calculateBenchmark(+benchmarkKpi3, +kpi3) * 100).toLocaleString(
            "cs-CZ",
            {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }
          ) + " %",
        ],
      },
    ];

    return rows;
  };

  const getAllFancadesKPIRows = (project: Project) => {
    let rows: any[] = [];

    let maintenance = 0;
    let surface = 0;

    let implantsSurfacePercentage = 0;
    let implantsSurface = 0;
    let surfaceWithoutImplants = 0;
    let systemsIntegration = 0;

    let kpi1 = 0;
    let kpi2 = 0;
    let kpi3 = 0;

    project.fancades?.map((fancade, index) => {
      maintenance += +calculateMaintenanceTotal(fancade);
      if (fancade.fancadeTechnicalProject?.surface)
        surface += +fancade.fancadeTechnicalProject.surface;

      if (fancade.fancadeTechnicalProject?.systemsSurfacePercentage)
        implantsSurfacePercentage +=
          +fancade.fancadeTechnicalProject.systemsSurfacePercentage;
      implantsSurface += +implantsSurfacePercentage * +surface;
      surfaceWithoutImplants += calculateBenchmark(+surface, +implantsSurface);
      systemsIntegration += +calculateSystemsIntegrationsMaintenance(fancade);
    });

    if (surface != 0) kpi1 = +maintenance / +surface;
    if (implantsSurface != 0) kpi2 = +systemsIntegration / +implantsSurface;
    if (maintenance != 0) kpi3 = +systemsIntegration / +maintenance;

    //Store project KPIs
    project.kpiMaintenance = {
      totalMaintenancePrice: maintenance,
      //totalFancadeSurface
      implantsOccupiedSurfacePercentage: implantsSurfacePercentage,
      implantsSurface: implantsSurface,
      fancadeSurfacesWithoutImplants: surfaceWithoutImplants,
      systemsIntegrationPrice: systemsIntegration,
      maintenancePriceKPI: kpi1,
      systemsIntegrationMaintenanceKPI: kpi2,
      implantsMaintenanceIncidence: kpi3,
    };
    rows = [
      {
        name: t("formLabels.details.maintenance.totalMaintenancePrice"),
        values: [
          maintenance.toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " €",
        ],
      },
      {
        name: t("formLabels.details.maintenance.surface"),
        values: [
          (+surface).toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " m²",
        ],
      },
      {
        name: t("formLabels.details.maintenance.implantsSurfacePercentage"),
        values: [
          implantsSurfacePercentage.toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " %",
        ],
      },
      {
        name: t("formLabels.details.maintenance.implantsSurface"),
        values: [
          implantsSurface.toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " m²",
        ],
      },
      {
        name: t("formLabels.details.maintenance.surfaceWithoutImplants"),
        values: [
          surfaceWithoutImplants.toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " m²",
        ],
      },
      {
        name: t("formLabels.details.maintenance.systemsIntegration"),
        values: [
          systemsIntegration.toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " €",
        ],
      },
      {
        name: t("formLabels.details.maintenance.kpi1"),
        values: [
          kpi1.toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " €/m²",
        ],
      },
      {
        name: t("formLabels.details.maintenance.kpi2"),
        values: [
          kpi2.toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " €/m²",
        ],
      },
      {
        name: t("formLabels.details.maintenance.kpi3"),
        values: [
          (+kpi3 * 100).toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " %",
        ],
      },
    ];

    return rows;
  };

  if (session.user)
    return (
      <Flex w={"100%"}>
        <ChangeFancadeNameModal
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={(values: any) => {
            if (currentProject && currentProject.fancades)
              currentProject.fancades[fancadeToUpdate].customName =
                values.customName;
            onClose();
          }}
        />
        <Tabs
          index={props.tabIndex}
          onChange={handleTabsChange}
          variant={"enclosed"}
          w={"100%"}
        >
          <TabList>
            {currentProject?.fancades?.map((fancade, index) => (
              <Tab alignItems={"center"}>
                <DeleteAlert
                  title={t("warnings.deleteFancadeTitle")}
                  body={t("warnings.deleteFancade")}
                  isOpen={deleteFancadeOpen}
                  setIsOpen={setDeleteFancadeOpen}
                  cancelText={t("warnings.cancel")}
                  continueText={t("warnings.confirm")}
                  onContinue={async () => {
                    deleteFancade(fancade, index);
                  }}
                />
                <Text color={theme.colors.darkGrey2}>
                  {fancade.customName
                    ? currentProject.isBenchmark && index == 0
                      ? t("tabs.details.fancadeTechnicalProject.benchmark") +
                        fancade.customName
                      : fancade.customName
                    : currentProject.isBenchmark && index == 0
                    ? t("tabs.details.fancadeTechnicalProject.firstTab")
                    : t("tabs.details.fancadeTechnicalProject.tabsAfterFirst") +
                      (index + 1)}
                </Text>
                <AiFillEdit
                  style={{ marginLeft: "0.3rem" }}
                  onClick={() => {
                    setFancadeToUpdate(index);
                    onOpen();
                  }}
                />
                <Text
                  onClick={() => setDeleteFancadeOpen(true)}
                  color={theme.colors.euracOrange}
                  paddingLeft={"0.5rem"}
                >
                  <strong>x</strong>
                </Text>
              </Tab>
            ))}
            <Button variant={"ghost"} onClick={addFancade}>
              <Text color={theme.colors.euracOrange}>+</Text>
            </Button>
          </TabList>
          <TabPanels>
            {currentProject?.fancades?.map((fancade, index) => (
              <TabPanel>
                <MaintenanceForm
                  fancadeNumber={index + 1}
                  fancadeId={fancade.id}
                  fancadeType={fancade?.fancadeTechnicalProject?.type}
                  formikProps={props.formikProps}
                />
                <Stack w={"100%"} fontSize={"md"} spacing={"2rem"}>
                  <DetailsTable
                    columnsTitles={[
                      t("formLabels.details.maintenance.kpiTitle"),
                    ]}
                    rows={getOneFancadeKPIsRows(fancade)}
                  />
                  {index > 0 && props.isBenchmark ? (
                    <DetailsTable
                      columnsTitles={[
                        t("formLabels.details.maintenance.benchmarkTitle"),
                      ]}
                      rows={getBenchmarkKPIRows(currentProject, index)}
                    />
                  ) : (
                    <></>
                  )}
                  {currentProject.fancades &&
                  currentProject.fancades.length > 1 ? (
                    <DetailsTable
                      columnsTitles={[
                        t("formLabels.details.buildingSite.kpiAllTitle"),
                      ]}
                      rows={getAllFancadesKPIRows(currentProject)}
                    />
                  ) : (
                    <></>
                  )}
                </Stack>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Flex>
    );
  else return <Redirect to={"/"} />;
});
