import { Page, StyleSheet } from "@react-pdf/renderer";

type Props = {
  style?: any;
};

export const PDFPage: React.FC<Props> = (props) => {
  return (
    <Page size="A4" style={props.style ? props.style : styles.page}>
      {props.children}
    </Page>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
  },
});
