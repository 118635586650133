import React from "react";
import RSelect, { Props } from "react-select";

export type Option = {
  label: string;
  value: string;
};

export const Select: React.FC<Props> = (props) => {
  const selectStyles = {
    control: (styles: any) => ({
      ...styles,
      minHeight: "var(--chakra-sizes-10)",
      borderRadius: "var(--chakra-radii-md)",
      borderColor: "inherit",
      width: "100%",
    }),
    indicatorSeparator: () => ({ display: "none" }),
  };

  return <RSelect styles={selectStyles} {...props} />;
};
