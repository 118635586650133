import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Text,
} from "@chakra-ui/react";
import React from "react";
import styled from "styled-components";
import theme from "../theme/theme";
import { transparentize } from "polished";
import { useTranslation } from "react-i18next";

type ProjectElement = {
  id: number;
  name: string;
};

type Props = {
  isOpen: any;
  setIsOpen: any;
  title?: string;
  body?: string;
  cancelText: string;
  continueText: string;
  onContinue: any;
  projects?: ProjectElement[];
};

export const DeleteAlert: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const onClose = () => props.setIsOpen(false);
  const onContinue = async () => {
    props.onContinue();
    props.setIsOpen(false);
  };
  const cancelRef = React.useRef(null);

  return (
    <>
      <AlertDialog
        isOpen={props.isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered={true}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {props.title}
            </AlertDialogHeader>

            <AlertDialogBody>
              {props.body}

              {props.projects && (
                <>
                  <Text marginTop={"1rem"}>
                    {t("warnings.deleteProjectsList")}
                  </Text>
                  {props.projects.map((project, index) => (
                    <Text key={index}>
                      {"- " + project.name + " (#" + project.id + ")"}
                    </Text>
                  ))}
                </>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <StyledButtonGrey ref={cancelRef} onClick={onClose}>
                {props.cancelText}
              </StyledButtonGrey>
              <StyledButtonOrange colorScheme="red" onClick={onContinue} ml={3}>
                {props.continueText}
              </StyledButtonOrange>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

const StyledButtonOrange = styled(Button)`
  border-radius: 500rem;
  color: ${(props) => (!props.disabled ? "white" : "black")};

  background-color: ${(props) =>
    !props.disabled ? theme.colors.euracOrange : transparentize(0.85, "grey")};

  &:hover {
    background-color: ${transparentize(0.5, theme.colors.euracOrange)};
  }
`;
const StyledButtonGrey = styled(Button)`
  border-radius: 500rem;
  color: ${(props) => (!props.disabled ? "white" : "black")};

  background-color: ${(props) =>
    !props.disabled ? "grey" : transparentize(0.85, "grey")};

  &:hover {
    background-color: ${transparentize(0.5, "grey")};
  }
`;
