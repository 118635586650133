import { Text } from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Stack } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { User } from "../types";

type Props = {
  user: User | undefined;
  isVisible: boolean;
  onSubmit?: (data: any) => void;
  onDismiss: () => void;
};

export const CredentialsModal: React.FC<Props> = ({
  user,
  isVisible,
  onSubmit,
  onDismiss,
}) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isVisible} size={"xl"} onClose={onDismiss}>
      <ModalOverlay />
      <ModalContent maxW={"820px"}>
        <ModalCloseButton />
        <ModalBody style={{ padding: "2rem" }}>
          <Stack w={"100%"}>
            <Text
              fontSize={"xl"}
              paddingTop={"1rem"}
              paddingBottom={"2rem"}
              fontWeight={600}
            >
              {t("users.credentialsText")}
            </Text>
            {user && (
              <>
                <Text fontSize={"lg"}>
                  <strong>{t("users.id")}: </strong>
                  {user.id}
                </Text>
                <Text fontSize={"lg"}>
                  <strong>{t("users.nominative")}: </strong>
                  {user.nominative}
                </Text>
                <Text fontSize={"lg"}>
                  <strong>{t("users.email")}: </strong>
                  {user.email}
                </Text>
                <Text fontSize={"lg"}>
                  <strong>{t("users.password")}: </strong>
                  {user.password}
                </Text>
                <Text fontSize={"lg"}>
                  <strong>{t("users.role")}: </strong>
                  {user.role}
                </Text>
              </>
            )}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const StyledLinkText = styled(Text)`
  cursor: pointer;
  color: var(--chakra-colors-teal-600);
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0.5rem;
`;
