import React, { useCallback } from "react";
import ReactDOM from "react-dom";
import {
  Button,
  Center,
  Flex,
  Stack,
  Text,
  Input,
  Image,
} from "@chakra-ui/react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/form-control";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "./../hooks/useStores";

import { Formik, Form, Field, FieldProps } from "formik";
import * as Yup from "yup";

import styled from "styled-components";
import { transparentize } from "polished";
import theme from "./../theme/theme";

type Props = {
  onSubmit: (category: string) => any;
};

export const CategoryForm: React.FC<Props> = observer((props) => {
  const { t } = useTranslation();

  const categoryValidationSchema = Yup.object().shape({
    category: Yup.string().required(t("errors.missingCategory")),
  });

  return (
    <Formik
      initialValues={{ category: "" }}
      validationSchema={categoryValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values.category);
      }}
    >
      {(formikProps) => (
        <Form>
          <Stack
            spacing={"1rem"}
            w={"100%"}
            style={{ marginBottom: "2em" }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Text fontSize={"ms"}>{t("formLabels.category")}</Text>
            <Field name={"category"}>
              {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                  <Input
                    {...field}
                    type={"text"}
                    name={field.name}
                    value={field.value}
                    placeholder={t("formPlaceholders.category")}
                    onChange={(event: any) =>
                      setFieldValue(field.name, event.target.value)
                    }
                  />
                  <FormErrorMessage name={"category"}>
                    {meta.error}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <StyledButton
              disabled={!formikProps.dirty || !formikProps.isValid}
              type={"submit"}
              style={{ marginTop: "3em" }}
              rounded={"500rem"}
            >
              {t("buttons.addCategory")}
            </StyledButton>
          </Stack>
        </Form>
      )}
    </Formik>
  );
});

const StyledButton = styled(Button)`
  color: white;
  background-color: ${theme.colors.euracOrange};
  border-radius: 500rem;
  &:hover {
    background-color: ${transparentize(0.5, theme.colors.euracOrange)};
  }
`;
