import React from "react";
import { Button, Center, Flex, Select, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { Project } from "../../../../types";
import { CostPerPhaseTable } from "./tables/CostPerPhaseTable";
import { KPITable } from "./tables/KPITable";

type Props = {
  project: Project;
};

export const BenchmarkTab: React.FC<Props> = ({ project }) => {
  const { t } = useTranslation();
  const [currentFancadeIndex, setCurrentFancadeIndex] = React.useState(1);
  return (
    <Stack
      w={"99%"}
      justifyContent={"center"}
      alignItems={"center"}
      spacing={"2rem"}
    >
      <Text marginTop={"1rem"} fontSize={"2xl"}>
        {t("selectFancade") + project.id}
      </Text>
      <Select
        w={"80%"}
        value={currentFancadeIndex}
        onChange={(event) => {
          setCurrentFancadeIndex(+event.target.value);
        }}
      >
        {project.fancades?.map((fancade, index) => (
          <>
            {index > 0 && (
              <option value={index}>
                {t("fancade") + " #" + (+index + 1)}
              </option>
            )}
          </>
        ))}
      </Select>
      <CostPerPhaseTable
        project={project}
        fancadeIndex={currentFancadeIndex}
        benchmark={true}
      />
      <KPITable
        project={project}
        fancadeIndex={currentFancadeIndex}
        benchmark={true}
      />
    </Stack>
  );
};
