import { Flex, Stack, Text } from "@chakra-ui/react";
import { transparentize } from "polished";
import { useTranslation } from "react-i18next";
import theme from "../theme/theme";
import { FormRow } from "./FormRow";

type Props = {
  calculation: number | null;
  setCalculation: any;
  name: string;
  everyHowManyYears: number;
  setUpdate: any;
  update: boolean;
};
export const PeriodFields: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <Stack
      bg={transparentize(0.95, theme.colors.euracOrange)}
      rounded={5}
      padding={"1rem"}
    >
      <Flex>
        <Flex w={"50%"} alignItems={"center"}>
          <input
            type="radio"
            value="0"
            checked={props.calculation == null || props.calculation == 0}
            onChange={(e) => {
              if (e.target.checked) {
                props.setCalculation(0);
              } else {
                props.setCalculation(1);
              }
              props.setUpdate(!props.update);
            }}
            defaultChecked
          />
          <Text fontSize={"md"} display={"inline"} paddingLeft={"0.5rem"}>
            {t("formLabels.details.maintenance.periodByEveryHowManyYears")}
          </Text>
        </Flex>
        <Flex w={"50%"} alignItems={"center"}>
          <input
            type="radio"
            value="1"
            checked={props.calculation == 1}
            onChange={(e) => {
              if (e.target.checked) {
                props.setCalculation(1);
              } else {
                props.setCalculation(0);
              }
              props.setUpdate(!props.update);
            }}
          />
          <Text fontSize={"md"} display={"inline"} paddingLeft={"0.5rem"}>
            {t("formLabels.details.maintenance.periodByTimesInAYear")}
          </Text>
        </Flex>
      </Flex>

      {/*  <RadioGroup
        onChange={(nextValue) => {
          if (!props.calculation || props.calculation === 0) {
            console.log(1);
            props.setCalculation(1);
          } else {
            props.setCalculation(0);
            console.log(0);
          }
          props.setUpdate(!props.update);
        }}
        value={props.calculation || 0}
      >
        <Radio value={0}>
          {t("formLabels.details.maintenance.periodByEveryHowManyYears")}
        </Radio>
        <Radio value={1}>
          {t("formLabels.details.maintenance.periodByTimesInAYear")}
        </Radio>
      </RadioGroup> */}
      {/* <FormikCheckbox
        checked={props.calculation === 1}
        leftText={t("formLabels.details.maintenance.periodByEveryHowManyYears")}
        onChange={(event: any) => {
          if (!props.calculation || props.calculation === 0) {
            props.setCalculation(1);
          } else {
            props.setCalculation(0);
          }
          props.setUpdate(!props.update);
        }}
      >
        {t("formLabels.details.maintenance.periodByTimesInAYear")}
      </FormikCheckbox> */}

      <FormRow
        fields={[
          {
            name: props.name,
            label: t(`formLabels.details.maintenance.everyHowManyYears`),
            placeholder: t(
              `formPlaceholders.details.maintenance.everyHowManyYears`
            ),
            type: "number",
            width: 100,
            keepField: true,
            value:
              props.calculation === 1
                ? props.everyHowManyYears != 0
                  ? 1 / +props.everyHowManyYears
                  : 0
                : props.everyHowManyYears,
            disabled: props.calculation === 1,
          },
          {
            name: props.name,
            label: t(`formLabels.details.maintenance.timesInAYear`),
            placeholder: t(`formLabels.details.maintenance.timesInAYear`),
            type: "number",
            width: 100,
            keepField: true,
            value:
              props.calculation === 1
                ? +props.everyHowManyYears
                : props.everyHowManyYears != 0
                ? 1 / +props.everyHowManyYears
                : 0,
            disabled: !(props.calculation === 1),
          },
        ]}
      />
    </Stack>
  );
};

//const StyledInput = styled;
