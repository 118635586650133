import React, { useState } from "react";
import { Button, Stack } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../../../../hooks/useStores";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import { transparentize } from "polished";
import theme from "../../../../../../theme/theme";
import { FormRow } from "../../../../../FormRow";
import {
  calculateDemolitionAndDisassemblyTotal,
  calculateDisassemblyTotal,
  calculateEndOfLifeTransportTotal,
  calculateRecyclingTotalRevenue,
  calculateReUseTotalRevenue,
} from "../../../../../../services/utils";
import { FormikCheckbox } from "../../../../../FormikCheckbox";
import { Container } from "../../../../../Container";

interface RouteParams {
  projectId: string;
}

type props = {
  fancadeNumber: number;
  fancadeId: number;
  fancadeType: string | null;
  formikProps: any;
};

export const EndOfLifeForm: React.FC<props> = observer((props) => {
  const { t } = useTranslation();
  const { session, ui } = useStores();
  const [update, setUpdate] = useState(false);

  ui.setActiveScreen("projects");

  if (session.user)
    return (
      <Stack w={"100%"}>
        <Container
          title={t(`formLabels.details.endOfLife.demolitionAndDisassembly`)}
        >
          <Stack w={"100%"} spacing={"1rem"}>
            <FormRow
              fields={[
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].endOfLife.surfaceDemolitionOrDisassembly`,
                  label: t(
                    `formLabels.details.endOfLife.surfaceDemolitionOrDisassembly`
                  ),
                  placeholder: t(
                    `formPlaceholders.details.endOfLife.surfaceDemolitionOrDisassembly`
                  ),
                  type: "number",
                  width: 100,
                },
              ]}
              paddingBottom={"1rem"}
            />
            <FormikCheckbox
              checked={
                !props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .endOfLife.totalDemolitionAndDisassemblyPriceCalculation ||
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .endOfLife.totalDemolitionAndDisassemblyPriceCalculation == 0
              }
              defaultChecked={
                !props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .endOfLife.totalDemolitionAndDisassemblyPriceCalculation ||
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .endOfLife.totalDemolitionAndDisassemblyPriceCalculation == 0
              }
              onChange={() => {
                if (
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .endOfLife
                ) {
                  if (
                    !props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .endOfLife
                      .totalDemolitionAndDisassemblyPriceCalculation ||
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .endOfLife
                      .totalDemolitionAndDisassemblyPriceCalculation == 0
                  )
                    props.formikProps.values.fancades[
                      props.fancadeNumber - 1
                    ].endOfLife.totalDemolitionAndDisassemblyPriceCalculation = 1;
                  else
                    props.formikProps.values.fancades[
                      props.fancadeNumber - 1
                    ].endOfLife.totalDemolitionAndDisassemblyPriceCalculation = 0;
                  setUpdate(!update);
                }
              }}
            >
              {t(
                `formLabels.details.endOfLife.totalDemolitionAndDisassemblyPriceIsTotal`
              )}
            </FormikCheckbox>
            {!props.formikProps.values.fancades[props.fancadeNumber - 1]
              .endOfLife.totalDemolitionAndDisassemblyPriceCalculation ||
            props.formikProps.values.fancades[props.fancadeNumber - 1].endOfLife
              .totalDemolitionAndDisassemblyPriceCalculation == 0 ? (
              <>
                <FormRow
                  fields={[
                    {
                      name: `fancades[${
                        props.fancadeNumber - 1
                      }].endOfLife.totalDemolitionAndDisassemblyPrice`,
                      label: t(
                        `formLabels.details.endOfLife.totalDemolitionAndDisassemblyPrice`
                      ),
                      placeholder: t(
                        `formPlaceholders.details.endOfLife.totalDemolitionAndDisassemblyPrice`
                      ),
                      type: "number",
                      width: 100,
                    },
                  ]}
                />
              </>
            ) : (
              <Container
                title={
                  t(
                    `formLabels.details.endOfLife.totalDemolitionAndDisassemblyPrice`
                  ) + t(`titles.calculatedSection`)
                }
                bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                direction={"column"}
                spacing={"1rem"}
              >
                <FormRow
                  fields={[
                    {
                      name: `fancades[${
                        props.fancadeNumber - 1
                      }].endOfLife.totalDemolitionAndDisassemblyPrice`,
                      label: t(
                        `formLabels.details.endOfLife.totalDemolitionAndDisassemblyPrice`
                      ),
                      placeholder: t(
                        `formPlaceholders.details.endOfLife.totalDemolitionAndDisassemblyPrice`
                      ),
                      type: "number",
                      width: 100,
                      disabled: true,
                      value: calculateDemolitionAndDisassemblyTotal(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ]
                      ),
                    },
                  ]}
                />
                <Container
                  title={t(`formLabels.details.endOfLife.demolition`)}
                  direction={"column"}
                  spacing={"1rem"}
                >
                  {/* Demolition */}
                  <FormikCheckbox
                    checked={
                      !(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife?.totalDemolitionPriceCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife?.totalDemolitionPriceCalculation == 1
                      )
                    }
                    defaultChecked={
                      !(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife?.totalDemolitionPriceCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife?.totalDemolitionPriceCalculation == 1
                      )
                    }
                    onChange={() => {
                      if (
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife
                      ) {
                        if (
                          !props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalDemolitionPriceCalculation ||
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalDemolitionPriceCalculation == 0
                        )
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalDemolitionPriceCalculation = 1;
                        else
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalDemolitionPriceCalculation = 0;
                        setUpdate(!update);
                      }
                    }}
                  >
                    {t(
                      `formLabels.details.endOfLife.totalDemolitionPriceIsTotal`
                    )}
                  </FormikCheckbox>
                  {!(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .endOfLife.totalDemolitionPriceCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .endOfLife.totalDemolitionPriceCalculation == 1
                  ) ? (
                    <>
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.totalDemolitionPrice`,
                            label: t(
                              `formLabels.details.endOfLife.totalDemolitionPrice`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.totalDemolitionPrice`
                            ),
                            type: "number",
                            width: 100,
                          },
                        ]}
                      />
                    </>
                  ) : (
                    <Container
                      title={
                        t(
                          `formLabels.details.endOfLife.totalDemolitionAndDisassemblyPrice`
                        ) + t(`titles.calculatedSection`)
                      }
                      bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                      direction={"column"}
                      spacing={"1rem"}
                    >
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.totalDemolitionPrice`,
                            label: t(
                              `formLabels.details.endOfLife.totalDemolitionPrice`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.totalDemolitionPrice`
                            ),
                            type: "number",
                            width: 100,
                            disabled: true,
                            value:
                              +props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].endOfLife.demolitionQuantity *
                              +props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].endOfLife.demolitionUnitPrice,
                          },
                        ]}
                      />

                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.demolitionQuantity`,
                            label: t(
                              `formLabels.details.endOfLife.demolitionQuantity`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.demolitionQuantity`
                            ),
                            type: "number",
                            width: 100,
                          },
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.demolitionUnitPrice`,
                            label: t(
                              `formLabels.details.endOfLife.demolitionUnitPrice`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.demolitionUnitPrice`
                            ),
                            type: "number",
                            width: 100,
                          },
                        ]}
                      />
                    </Container>
                  )}
                </Container>
                <Container
                  title={t(`formLabels.details.endOfLife.disassembly`)}
                  direction={"column"}
                  spacing={"1rem"}
                >
                  {/* Disassembly */}
                  <FormikCheckbox
                    checked={
                      !(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife?.totalDisassemblyPriceCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife?.totalDisassemblyPriceCalculation == 1
                      )
                    }
                    defaultChecked={
                      !(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife?.totalDisassemblyPriceCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife?.totalDisassemblyPriceCalculation == 1
                      )
                    }
                    onChange={() => {
                      if (
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife
                      ) {
                        if (
                          !props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalDisassemblyPriceCalculation ||
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalDisassemblyPriceCalculation == 0
                        )
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalDisassemblyPriceCalculation = 1;
                        else
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalDisassemblyPriceCalculation = 0;
                        setUpdate(!update);
                      }
                    }}
                  >
                    {t(
                      `formLabels.details.endOfLife.totalDisassemblyPriceIsTotal`
                    )}
                  </FormikCheckbox>
                  {!(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .endOfLife.totalDisassemblyPriceCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .endOfLife.totalDisassemblyPriceCalculation == 1
                  ) ? (
                    <>
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.totalDisassemblyPrice`,
                            label: t(
                              `formLabels.details.endOfLife.totalDisassemblyPrice`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.totalDisassemblyPrice`
                            ),
                            type: "number",
                            width: 100,
                          },
                        ]}
                      />
                    </>
                  ) : (
                    <Container
                      title={
                        t(
                          `formLabels.details.endOfLife.totalDisassemblyPrice`
                        ) + t(`titles.calculatedSection`)
                      }
                      bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                      direction={"column"}
                      spacing={"1rem"}
                    >
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.totalDisassemblyPrice`,
                            label: t(
                              `formLabels.details.endOfLife.totalDisassemblyPrice`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.totalDisassemblyPrice`
                            ),
                            type: "number",
                            width: 100,
                            disabled: true,
                            value: +calculateDisassemblyTotal(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ]
                            ),
                          },
                        ]}
                      />

                      {props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.type
                        ?.toLowerCase()
                        .indexOf("opaca") != -1 ? (
                        <>
                          {/* Windows */}
                          <FormikCheckbox
                            marginTop={"1rem"}
                            checked={
                              !(
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].endOfLife
                                  ?.totalWindowsDisassemblyPriceCalculation &&
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].endOfLife
                                  ?.totalWindowsDisassemblyPriceCalculation ===
                                  1
                              )
                            }
                            defaultChecked={
                              !(
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].endOfLife
                                  ?.totalWindowsDisassemblyPriceCalculation &&
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].endOfLife
                                  ?.totalWindowsDisassemblyPriceCalculation ===
                                  1
                              )
                            }
                            onChange={() => {
                              if (
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].endOfLife
                              ) {
                                if (
                                  !props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].endOfLife
                                    .totalWindowsDisassemblyPriceCalculation ||
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].endOfLife
                                    .totalWindowsDisassemblyPriceCalculation ==
                                    0
                                )
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].endOfLife.totalWindowsDisassemblyPriceCalculation = 1;
                                else
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].endOfLife.totalWindowsDisassemblyPriceCalculation = 0;
                                setUpdate(!update);
                              }
                            }}
                          >
                            {t(
                              `formLabels.details.endOfLife.totalWindowsDisassemblyPriceIsTotal`
                            )}
                          </FormikCheckbox>
                          {!(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].endOfLife
                              .totalWindowsDisassemblyPriceCalculation &&
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].endOfLife
                              .totalWindowsDisassemblyPriceCalculation == 1
                          ) ? (
                            <>
                              <FormRow
                                fields={[
                                  {
                                    name: `fancades[${
                                      props.fancadeNumber - 1
                                    }].endOfLife.totalWindowsDisassemblyPrice`,
                                    label: t(
                                      `formLabels.details.endOfLife.totalWindowsDisassemblyPrice`
                                    ),
                                    placeholder: t(
                                      `formPlaceholders.details.endOfLife.totalWindowsDisassemblyPrice`
                                    ),
                                    type: "number",
                                    width: 100,
                                  },
                                ]}
                              />
                            </>
                          ) : (
                            <Container
                              title={
                                t(
                                  `formLabels.details.endOfLife.totalWindowsDisassemblyPrice`
                                ) + t(`titles.calculatedSection`)
                              }
                              bodyStyle={{
                                backgroundColor: theme.colors.lightOrange,
                              }}
                              direction={"column"}
                              spacing={"1rem"}
                            >
                              <FormRow
                                fields={[
                                  {
                                    name: `fancades[${
                                      props.fancadeNumber - 1
                                    }].endOfLife.totalWindowsDisassemblyPrice`,
                                    label: t(
                                      `formLabels.details.endOfLife.totalWindowsDisassemblyPrice`
                                    ),
                                    placeholder: t(
                                      `formPlaceholders.details.endOfLife.totalWindowsDisassemblyPrice`
                                    ),
                                    type: "number",
                                    width: 100,
                                    disabled: true,
                                    value:
                                      +props.formikProps.values.fancades[
                                        props.fancadeNumber - 1
                                      ].endOfLife.windowsQuantity *
                                      +props.formikProps.values.fancades[
                                        props.fancadeNumber - 1
                                      ].endOfLife.windowsUnitPrice,
                                  },
                                ]}
                              />

                              <FormRow
                                fields={[
                                  {
                                    name: `fancades[${
                                      props.fancadeNumber - 1
                                    }].endOfLife.windowsQuantity`,
                                    label: t(
                                      `formLabels.details.endOfLife.windowsQuantity`
                                    ),
                                    placeholder: t(
                                      `formPlaceholders.details.endOfLife.windowsQuantity`
                                    ),
                                    type: "number",
                                    width: 100,
                                  },
                                  {
                                    name: `fancades[${
                                      props.fancadeNumber - 1
                                    }].endOfLife.windowsUnitPrice`,
                                    label: t(
                                      `formLabels.details.endOfLife.windowsUnitPrice`
                                    ),
                                    placeholder: t(
                                      `formPlaceholders.details.endOfLife.windowsUnitPrice`
                                    ),
                                    type: "number",
                                    width: 100,
                                  },
                                ]}
                              />
                            </Container>
                          )}

                          {/* External Cladding */}
                          <FormikCheckbox
                            marginTop={"1rem"}
                            checked={
                              !(
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].endOfLife
                                  ?.totalExternalCladdingDisassemblyPriceCalculation &&
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].endOfLife
                                  ?.totalExternalCladdingDisassemblyPriceCalculation ==
                                  1
                              )
                            }
                            defaultChecked={
                              !(
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].endOfLife
                                  ?.totalExternalCladdingDisassemblyPriceCalculation &&
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].endOfLife
                                  ?.totalExternalCladdingDisassemblyPriceCalculation ==
                                  1
                              )
                            }
                            onChange={() => {
                              if (
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].endOfLife
                              ) {
                                if (
                                  !props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].endOfLife
                                    .totalExternalCladdingDisassemblyPriceCalculation ||
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].endOfLife
                                    .totalExternalCladdingDisassemblyPriceCalculation ==
                                    0
                                )
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].endOfLife.totalExternalCladdingDisassemblyPriceCalculation = 1;
                                else
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].endOfLife.totalExternalCladdingDisassemblyPriceCalculation = 0;
                                setUpdate(!update);
                              }
                            }}
                          >
                            {t(
                              `formLabels.details.endOfLife.totalExternalCladdingDisassemblyPriceIsTotal`
                            )}
                          </FormikCheckbox>
                          {!(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].endOfLife
                              .totalExternalCladdingDisassemblyPriceCalculation &&
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].endOfLife
                              .totalExternalCladdingDisassemblyPriceCalculation ==
                              1
                          ) ? (
                            <>
                              <FormRow
                                fields={[
                                  {
                                    name: `fancades[${
                                      props.fancadeNumber - 1
                                    }].endOfLife.totalExternalCladdingDisassemblyPrice`,
                                    label: t(
                                      `formLabels.details.endOfLife.totalExternalCladdingDisassemblyPrice`
                                    ),
                                    placeholder: t(
                                      `formPlaceholders.details.endOfLife.totalExternalCladdingDisassemblyPrice`
                                    ),
                                    type: "number",
                                    width: 100,
                                  },
                                ]}
                              />
                            </>
                          ) : (
                            <Container
                              title={
                                t(
                                  `formLabels.details.endOfLife.totalExternalCladdingDisassemblyPrice`
                                ) + t(`titles.calculatedSection`)
                              }
                              bodyStyle={{
                                backgroundColor: theme.colors.lightOrange,
                              }}
                              direction={"column"}
                              spacing={"1rem"}
                            >
                              <FormRow
                                fields={[
                                  {
                                    name: `fancades[${
                                      props.fancadeNumber - 1
                                    }].endOfLife.totalExternalCladdingDisassemblyPrice`,
                                    label: t(
                                      `formLabels.details.endOfLife.totalExternalCladdingDisassemblyPrice`
                                    ),
                                    placeholder: t(
                                      `formPlaceholders.details.endOfLife.totalExternalCladdingDisassemblyPrice`
                                    ),
                                    type: "number",
                                    width: 100,
                                    disabled: true,
                                    value:
                                      +props.formikProps.values.fancades[
                                        props.fancadeNumber - 1
                                      ].endOfLife.externalCladdingQuantity *
                                      +props.formikProps.values.fancades[
                                        props.fancadeNumber - 1
                                      ].endOfLife.externalCladdingUnitPrice,
                                  },
                                ]}
                              />

                              <FormRow
                                fields={[
                                  {
                                    name: `fancades[${
                                      props.fancadeNumber - 1
                                    }].endOfLife.externalCladdingQuantity`,
                                    label: t(
                                      `formLabels.details.endOfLife.externalCladdingQuantity`
                                    ),
                                    placeholder: t(
                                      `formPlaceholders.details.endOfLife.externalCladdingQuantity`
                                    ),
                                    type: "number",
                                    width: 100,
                                  },
                                  {
                                    name: `fancades[${
                                      props.fancadeNumber - 1
                                    }].endOfLife.externalCladdingUnitPrice`,
                                    label: t(
                                      `formLabels.details.endOfLife.externalCladdingUnitPrice`
                                    ),
                                    placeholder: t(
                                      `formPlaceholders.details.endOfLife.externalCladdingUnitPrice`
                                    ),
                                    type: "number",
                                    width: 100,
                                  },
                                ]}
                              />
                            </Container>
                          )}

                          {/* Insulation */}
                          <FormikCheckbox
                            marginTop={"1rem"}
                            checked={
                              !(
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].endOfLife
                                  ?.totalInsulationDisassemblyPriceCalculation &&
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].endOfLife
                                  ?.totalInsulationDisassemblyPriceCalculation ==
                                  1
                              )
                            }
                            defaultChecked={
                              !(
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].endOfLife
                                  ?.totalInsulationDisassemblyPriceCalculation &&
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].endOfLife
                                  ?.totalInsulationDisassemblyPriceCalculation ==
                                  1
                              )
                            }
                            onChange={() => {
                              if (
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].endOfLife
                              ) {
                                if (
                                  !props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].endOfLife
                                    .totalInsulationDisassemblyPriceCalculation ||
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].endOfLife
                                    .totalInsulationDisassemblyPriceCalculation ==
                                    0
                                )
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].endOfLife.totalInsulationDisassemblyPriceCalculation = 1;
                                else
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].endOfLife.totalInsulationDisassemblyPriceCalculation = 0;
                                setUpdate(!update);
                              }
                            }}
                          >
                            {t(
                              `formLabels.details.endOfLife.totalInsulationDisassemblyPriceIsTotal`
                            )}
                          </FormikCheckbox>
                          {!(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].endOfLife
                              .totalInsulationDisassemblyPriceCalculation &&
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].endOfLife
                              .totalInsulationDisassemblyPriceCalculation == 1
                          ) ? (
                            <>
                              <FormRow
                                fields={[
                                  {
                                    name: `fancades[${
                                      props.fancadeNumber - 1
                                    }].endOfLife.totalInsulationDisassemblyPrice`,
                                    label: t(
                                      `formLabels.details.endOfLife.totalInsulationDisassemblyPrice`
                                    ),
                                    placeholder: t(
                                      `formPlaceholders.details.endOfLife.totalInsulationDisassemblyPrice`
                                    ),
                                    type: "number",
                                    width: 100,
                                  },
                                ]}
                              />
                            </>
                          ) : (
                            <Container
                              title={
                                t(
                                  `formLabels.details.endOfLife.totalInsulationDisassemblyPrice`
                                ) + t(`titles.calculatedSection`)
                              }
                              bodyStyle={{
                                backgroundColor: theme.colors.lightOrange,
                              }}
                              direction={"column"}
                              spacing={"1rem"}
                            >
                              <FormRow
                                fields={[
                                  {
                                    name: `fancades[${
                                      props.fancadeNumber - 1
                                    }].endOfLife.totalInsulationDisassemblyPrice`,
                                    label: t(
                                      `formLabels.details.endOfLife.totalInsulationDisassemblyPrice`
                                    ),
                                    placeholder: t(
                                      `formPlaceholders.details.endOfLife.totalInsulationDisassemblyPrice`
                                    ),
                                    type: "number",
                                    width: 100,
                                    disabled: true,
                                    value:
                                      +props.formikProps.values.fancades[
                                        props.fancadeNumber - 1
                                      ].endOfLife.insulationQuantity *
                                      +props.formikProps.values.fancades[
                                        props.fancadeNumber - 1
                                      ].endOfLife.insulationUnitPrice,
                                  },
                                ]}
                              />

                              <FormRow
                                fields={[
                                  {
                                    name: `fancades[${
                                      props.fancadeNumber - 1
                                    }].endOfLife.insulationQuantity`,
                                    label: t(
                                      `formLabels.details.endOfLife.insulationQuantity`
                                    ),
                                    placeholder: t(
                                      `formPlaceholders.details.endOfLife.insulationQuantity`
                                    ),
                                    type: "number",
                                    width: 100,
                                  },
                                  {
                                    name: `fancades[${
                                      props.fancadeNumber - 1
                                    }].endOfLife.insulationUnitPrice`,
                                    label: t(
                                      `formLabels.details.endOfLife.insulationUnitPrice`
                                    ),
                                    placeholder: t(
                                      `formPlaceholders.details.endOfLife.insulationUnitPrice`
                                    ),
                                    type: "number",
                                    width: 100,
                                  },
                                ]}
                              />
                            </Container>
                          )}

                          {/* Supporting Elements and Fixings */}
                          <FormikCheckbox
                            marginTop={"1rem"}
                            checked={
                              !(
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].endOfLife
                                  ?.totalSupportingElementsAndFixingsDisassemblyPriceCalculation &&
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].endOfLife
                                  ?.totalSupportingElementsAndFixingsDisassemblyPriceCalculation ==
                                  1
                              )
                            }
                            defaultChecked={
                              !(
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].endOfLife
                                  ?.totalSupportingElementsAndFixingsDisassemblyPriceCalculation &&
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].endOfLife
                                  ?.totalSupportingElementsAndFixingsDisassemblyPriceCalculation ==
                                  1
                              )
                            }
                            onChange={() => {
                              if (
                                props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].endOfLife
                              ) {
                                if (
                                  !props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].endOfLife
                                    .totalSupportingElementsAndFixingsDisassemblyPriceCalculation ||
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].endOfLife
                                    .totalSupportingElementsAndFixingsDisassemblyPriceCalculation ==
                                    0
                                )
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].endOfLife.totalSupportingElementsAndFixingsDisassemblyPriceCalculation = 1;
                                else
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].endOfLife.totalSupportingElementsAndFixingsDisassemblyPriceCalculation = 0;
                                setUpdate(!update);
                              }
                            }}
                          >
                            {t(
                              `formLabels.details.endOfLife.totalSupportingElementsAndFixingsDisassemblyPriceIsTotal`
                            )}
                          </FormikCheckbox>
                          {!(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].endOfLife
                              .totalSupportingElementsAndFixingsDisassemblyPriceCalculation &&
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].endOfLife
                              .totalSupportingElementsAndFixingsDisassemblyPriceCalculation ==
                              1
                          ) ? (
                            <>
                              <FormRow
                                fields={[
                                  {
                                    name: `fancades[${
                                      props.fancadeNumber - 1
                                    }].endOfLife.totalSupportingElementsAndFixingsDisassemblyPrice`,
                                    label: t(
                                      `formLabels.details.endOfLife.totalSupportingElementsAndFixingsDisassemblyPrice`
                                    ),
                                    placeholder: t(
                                      `formPlaceholders.details.endOfLife.totalSupportingElementsAndFixingsDisassemblyPrice`
                                    ),
                                    type: "number",
                                    width: 100,
                                  },
                                ]}
                              />
                            </>
                          ) : (
                            <Container
                              title={
                                t(
                                  `formLabels.details.endOfLife.totalSupportingElementsAndFixingsDisassemblyPrice`
                                ) + t(`titles.calculatedSection`)
                              }
                              bodyStyle={{
                                backgroundColor: theme.colors.lightOrange,
                              }}
                              direction={"column"}
                              spacing={"1rem"}
                            >
                              <FormRow
                                fields={[
                                  {
                                    name: `fancades[${
                                      props.fancadeNumber - 1
                                    }].endOfLife.totalSupportingElementsAndFixingsDisassemblyPrice`,
                                    label: t(
                                      `formLabels.details.endOfLife.totalSupportingElementsAndFixingsDisassemblyPrice`
                                    ),
                                    placeholder: t(
                                      `formPlaceholders.details.endOfLife.totalSupportingElementsAndFixingsDisassemblyPrice`
                                    ),
                                    type: "number",
                                    width: 100,
                                    disabled: true,
                                    value:
                                      +props.formikProps.values.fancades[
                                        props.fancadeNumber - 1
                                      ].endOfLife
                                        .supportingElementsAndFixingsQuantity *
                                      +props.formikProps.values.fancades[
                                        props.fancadeNumber - 1
                                      ].endOfLife
                                        .supportingElementsAndFixingsUnitPrice,
                                  },
                                ]}
                              />

                              <FormRow
                                fields={[
                                  {
                                    name: `fancades[${
                                      props.fancadeNumber - 1
                                    }].endOfLife.supportingElementsAndFixingsQuantity`,
                                    label: t(
                                      `formLabels.details.endOfLife.supportingElementsAndFixingsQuantity`
                                    ),
                                    placeholder: t(
                                      `formPlaceholders.details.endOfLife.supportingElementsAndFixingsQuantity`
                                    ),
                                    type: "number",
                                    width: 100,
                                  },
                                  {
                                    name: `fancades[${
                                      props.fancadeNumber - 1
                                    }].endOfLife.supportingElementsAndFixingsUnitPrice`,
                                    label: t(
                                      `formLabels.details.endOfLife.supportingElementsAndFixingsUnitPrice`
                                    ),
                                    placeholder: t(
                                      `formPlaceholders.details.endOfLife.supportingElementsAndFixingsUnitPrice`
                                    ),
                                    type: "number",
                                    width: 100,
                                  },
                                ]}
                              />
                            </Container>
                          )}
                        </>
                      ) : (
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject.type
                          ?.toLowerCase()
                          .indexOf("trasparente") != -1 && (
                          <>
                            {/* Glazed Cells */}
                            <FormikCheckbox
                              paddingTop={"1rem"}
                              checked={
                                !(
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].endOfLife
                                    ?.totalGlazedCellsDisassemblyPriceCalculation &&
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].endOfLife
                                    ?.totalGlazedCellsDisassemblyPriceCalculation ==
                                    1
                                )
                              }
                              defaultChecked={
                                !(
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].endOfLife
                                    ?.totalGlazedCellsDisassemblyPriceCalculation &&
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].endOfLife
                                    ?.totalGlazedCellsDisassemblyPriceCalculation ==
                                    1
                                )
                              }
                              onChange={() => {
                                if (
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].endOfLife
                                ) {
                                  if (
                                    !props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].endOfLife
                                      .totalGlazedCellsDisassemblyPriceCalculation ||
                                    props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].endOfLife
                                      .totalGlazedCellsDisassemblyPriceCalculation ==
                                      0
                                  )
                                    props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].endOfLife.totalGlazedCellsDisassemblyPriceCalculation = 1;
                                  else
                                    props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].endOfLife.totalGlazedCellsDisassemblyPriceCalculation = 0;
                                  setUpdate(!update);
                                }
                              }}
                            >
                              {t(
                                `formLabels.details.endOfLife.totalGlazedCellsDisassemblyPriceIsTotal`
                              )}
                            </FormikCheckbox>
                            {!(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].endOfLife
                                .totalGlazedCellsDisassemblyPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].endOfLife
                                .totalGlazedCellsDisassemblyPriceCalculation ==
                                1
                            ) ? (
                              <>
                                <FormRow
                                  fields={[
                                    {
                                      name: `fancades[${
                                        props.fancadeNumber - 1
                                      }].endOfLife.totalGlazedCellsDisassemblyPrice`,
                                      label: t(
                                        `formLabels.details.endOfLife.totalGlazedCellsDisassemblyPrice`
                                      ),
                                      placeholder: t(
                                        `formPlaceholders.details.endOfLife.totalGlazedCellsDisassemblyPrice`
                                      ),
                                      type: "number",
                                      width: 100,
                                    },
                                  ]}
                                />
                              </>
                            ) : (
                              <Container
                                title={
                                  t(
                                    `formLabels.details.endOfLife.totalGlazedCellsDisassemblyPrice`
                                  ) + t(`titles.calculatedSection`)
                                }
                                bodyStyle={{
                                  backgroundColor: theme.colors.lightOrange,
                                }}
                                direction={"column"}
                                spacing={"1rem"}
                              >
                                <FormRow
                                  fields={[
                                    {
                                      name: `fancades[${
                                        props.fancadeNumber - 1
                                      }].endOfLife.totalGlazedCellsDisassemblyPrice`,
                                      label: t(
                                        `formLabels.details.endOfLife.totalGlazedCellsDisassemblyPrice`
                                      ),
                                      placeholder: t(
                                        `formPlaceholders.details.endOfLife.totalGlazedCellsDisassemblyPrice`
                                      ),
                                      type: "number",
                                      width: 100,
                                      disabled: true,
                                      value:
                                        +props.formikProps.values.fancades[
                                          props.fancadeNumber - 1
                                        ].endOfLife.glazedCellsQuantity *
                                        +props.formikProps.values.fancades[
                                          props.fancadeNumber - 1
                                        ].endOfLife.glazedCellsUnitPrice,
                                    },
                                  ]}
                                />

                                <FormRow
                                  fields={[
                                    {
                                      name: `fancades[${
                                        props.fancadeNumber - 1
                                      }].endOfLife.glazedCellsQuantity`,
                                      label: t(
                                        `formLabels.details.endOfLife.glazedCellsQuantity`
                                      ),
                                      placeholder: t(
                                        `formPlaceholders.details.endOfLife.glazedCellsQuantity`
                                      ),
                                      type: "number",
                                      width: 100,
                                    },
                                    {
                                      name: `fancades[${
                                        props.fancadeNumber - 1
                                      }].endOfLife.glazedCellsUnitPrice`,
                                      label: t(
                                        `formLabels.details.endOfLife.glazedCellsUnitPrice`
                                      ),
                                      placeholder: t(
                                        `formPlaceholders.details.endOfLife.glazedCellsUnitPrice`
                                      ),
                                      type: "number",
                                      width: 100,
                                    },
                                  ]}
                                />
                              </Container>
                            )}

                            {/* Double Glazing */}
                            <FormikCheckbox
                              paddingTop={"1rem"}
                              checked={
                                !(
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].endOfLife
                                    ?.totalDoubleGlazingDisassemblyPriceCalculation &&
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].endOfLife
                                    ?.totalDoubleGlazingDisassemblyPriceCalculation ==
                                    1
                                )
                              }
                              defaultChecked={
                                !(
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].endOfLife
                                    ?.totalDoubleGlazingDisassemblyPriceCalculation &&
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].endOfLife
                                    ?.totalDoubleGlazingDisassemblyPriceCalculation ==
                                    1
                                )
                              }
                              onChange={() => {
                                if (
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].endOfLife
                                ) {
                                  if (
                                    !props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].endOfLife
                                      .totalDoubleGlazingDisassemblyPriceCalculation ||
                                    props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].endOfLife
                                      .totalDoubleGlazingDisassemblyPriceCalculation ==
                                      0
                                  )
                                    props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].endOfLife.totalDoubleGlazingDisassemblyPriceCalculation = 1;
                                  else
                                    props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].endOfLife.totalDoubleGlazingDisassemblyPriceCalculation = 0;
                                  setUpdate(!update);
                                }
                              }}
                            >
                              {t(
                                `formLabels.details.endOfLife.totalDoubleGlazingDisassemblyPriceIsTotal`
                              )}
                            </FormikCheckbox>
                            {!(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].endOfLife
                                .totalDoubleGlazingDisassemblyPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].endOfLife
                                .totalDoubleGlazingDisassemblyPriceCalculation ==
                                1
                            ) ? (
                              <>
                                <FormRow
                                  fields={[
                                    {
                                      name: `fancades[${
                                        props.fancadeNumber - 1
                                      }].endOfLife.totalDoubleGlazingDisassemblyPrice`,
                                      label: t(
                                        `formLabels.details.endOfLife.totalDoubleGlazingDisassemblyPrice`
                                      ),
                                      placeholder: t(
                                        `formPlaceholders.details.endOfLife.totalDoubleGlazingDisassemblyPrice`
                                      ),
                                      type: "number",
                                      width: 100,
                                    },
                                  ]}
                                />
                              </>
                            ) : (
                              <Container
                                title={
                                  t(
                                    `formLabels.details.endOfLife.totalDoubleGlazingDisassemblyPrice`
                                  ) + t(`titles.calculatedSection`)
                                }
                                bodyStyle={{
                                  backgroundColor: theme.colors.lightOrange,
                                }}
                                direction={"column"}
                                spacing={"1rem"}
                              >
                                <FormRow
                                  fields={[
                                    {
                                      name: `fancades[${
                                        props.fancadeNumber - 1
                                      }].endOfLife.totalDoubleGlazingDisassemblyPrice`,
                                      label: t(
                                        `formLabels.details.endOfLife.totalDoubleGlazingDisassemblyPrice`
                                      ),
                                      placeholder: t(
                                        `formPlaceholders.details.endOfLife.totalDoubleGlazingDisassemblyPrice`
                                      ),
                                      type: "number",
                                      width: 100,
                                      disabled: true,
                                      value:
                                        +props.formikProps.values.fancades[
                                          props.fancadeNumber - 1
                                        ].endOfLife.doubleGlazingQuantity *
                                        +props.formikProps.values.fancades[
                                          props.fancadeNumber - 1
                                        ].endOfLife.doubleGlazingUnitPrice,
                                    },
                                  ]}
                                />

                                <FormRow
                                  fields={[
                                    {
                                      name: `fancades[${
                                        props.fancadeNumber - 1
                                      }].endOfLife.doubleGlazingQuantity`,
                                      label: t(
                                        `formLabels.details.endOfLife.doubleGlazingQuantity`
                                      ),
                                      placeholder: t(
                                        `formPlaceholders.details.endOfLife.doubleGlazingQuantity`
                                      ),
                                      type: "number",
                                      width: 100,
                                    },
                                    {
                                      name: `fancades[${
                                        props.fancadeNumber - 1
                                      }].endOfLife.doubleGlazingUnitPrice`,
                                      label: t(
                                        `formLabels.details.endOfLife.doubleGlazingUnitPrice`
                                      ),
                                      placeholder: t(
                                        `formPlaceholders.details.endOfLife.doubleGlazingUnitPrice`
                                      ),
                                      type: "number",
                                      width: 100,
                                    },
                                  ]}
                                />
                              </Container>
                            )}

                            {/* Uprights, Crosspieces and Fixings */}
                            <FormikCheckbox
                              paddingTop={"1rem"}
                              checked={
                                !(
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].endOfLife
                                    ?.totalUprightsCrosspiecesFixingsDisassemblyPriceCalculation &&
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].endOfLife
                                    ?.totalUprightsCrosspiecesFixingsDisassemblyPriceCalculation ==
                                    1
                                )
                              }
                              defaultChecked={
                                !(
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].endOfLife
                                    ?.totalUprightsCrosspiecesFixingsDisassemblyPriceCalculation &&
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].endOfLife
                                    ?.totalUprightsCrosspiecesFixingsDisassemblyPriceCalculation ==
                                    1
                                )
                              }
                              onChange={() => {
                                if (
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].endOfLife
                                ) {
                                  if (
                                    !props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].endOfLife
                                      .totalUprightsCrosspiecesFixingsDisassemblyPriceCalculation ||
                                    props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].endOfLife
                                      .totalUprightsCrosspiecesFixingsDisassemblyPriceCalculation ==
                                      0
                                  )
                                    props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].endOfLife.totalUprightsCrosspiecesFixingsDisassemblyPriceCalculation = 1;
                                  else
                                    props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].endOfLife.totalUprightsCrosspiecesFixingsDisassemblyPriceCalculation = 0;
                                  setUpdate(!update);
                                }
                              }}
                            >
                              {t(
                                `formLabels.details.endOfLife.totalUprightsCrosspiecesFixingsDisassemblyPriceIsTotal`
                              )}
                            </FormikCheckbox>
                            {!(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].endOfLife
                                .totalUprightsCrosspiecesFixingsDisassemblyPriceCalculation &&
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].endOfLife
                                .totalUprightsCrosspiecesFixingsDisassemblyPriceCalculation ==
                                1
                            ) ? (
                              <>
                                <FormRow
                                  fields={[
                                    {
                                      name: `fancades[${
                                        props.fancadeNumber - 1
                                      }].endOfLife.totalUprightsCrosspiecesFixingsDisassemblyPrice`,
                                      label: t(
                                        `formLabels.details.endOfLife.totalUprightsCrosspiecesFixingsDisassemblyPrice`
                                      ),
                                      placeholder: t(
                                        `formPlaceholders.details.endOfLife.totalUprightsCrosspiecesFixingsDisassemblyPrice`
                                      ),
                                      type: "number",
                                      width: 100,
                                    },
                                  ]}
                                />
                              </>
                            ) : (
                              <Container
                                title={
                                  t(
                                    `formLabels.details.endOfLife.totalUprightsCrosspiecesFixingsDisassemblyPrice`
                                  ) + t(`titles.calculatedSection`)
                                }
                                bodyStyle={{
                                  backgroundColor: theme.colors.lightOrange,
                                }}
                                direction={"column"}
                                spacing={"1rem"}
                              >
                                <FormRow
                                  fields={[
                                    {
                                      name: `fancades[${
                                        props.fancadeNumber - 1
                                      }].endOfLife.totalUprightsCrosspiecesFixingsDisassemblyPrice`,
                                      label: t(
                                        `formLabels.details.endOfLife.totalUprightsCrosspiecesFixingsDisassemblyPrice`
                                      ),
                                      placeholder: t(
                                        `formPlaceholders.details.endOfLife.totalUprightsCrosspiecesFixingsDisassemblyPrice`
                                      ),
                                      type: "number",
                                      width: 100,
                                      disabled: true,
                                      value:
                                        +props.formikProps.values.fancades[
                                          props.fancadeNumber - 1
                                        ].endOfLife
                                          .uprightsCrosspiecesFixingsQuantity *
                                        +props.formikProps.values.fancades[
                                          props.fancadeNumber - 1
                                        ].endOfLife
                                          .uprightsCrosspiecesFixingsUnitPrice,
                                    },
                                  ]}
                                />

                                <FormRow
                                  fields={[
                                    {
                                      name: `fancades[${
                                        props.fancadeNumber - 1
                                      }].endOfLife.uprightsCrosspiecesFixingsQuantity`,
                                      label: t(
                                        `formLabels.details.endOfLife.uprightsCrosspiecesFixingsQuantity`
                                      ),
                                      placeholder: t(
                                        `formPlaceholders.details.endOfLife.uprightsCrosspiecesFixingsQuantity`
                                      ),
                                      type: "number",
                                      width: 100,
                                    },
                                    {
                                      name: `fancades[${
                                        props.fancadeNumber - 1
                                      }].endOfLife.uprightsCrosspiecesFixingsUnitPrice`,
                                      label: t(
                                        `formLabels.details.endOfLife.uprightsCrosspiecesFixingsUnitPrice`
                                      ),
                                      placeholder: t(
                                        `formPlaceholders.details.endOfLife.uprightsCrosspiecesFixingsUnitPrice`
                                      ),
                                      type: "number",
                                      width: 100,
                                    },
                                  ]}
                                />
                              </Container>
                            )}
                          </>
                        )
                      )}
                    </Container>
                  )}
                </Container>
              </Container>
            )}
          </Stack>
        </Container>

        <Container
          title={t(`formLabels.details.endOfLife.transport`)}
          direction={"column"}
          spacing={"1rem"}
        >
          <FormikCheckbox
            checked={
              !(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .endOfLife?.totalTransportPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .endOfLife?.totalTransportPriceCalculation == 1
              )
            }
            defaultChecked={
              !(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .endOfLife?.totalTransportPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .endOfLife?.totalTransportPriceCalculation == 1
              )
            }
            onChange={() => {
              if (
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .endOfLife
              ) {
                if (
                  !props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .endOfLife.totalTransportPriceCalculation ||
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .endOfLife.totalTransportPriceCalculation == 0
                )
                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].endOfLife.totalTransportPriceCalculation = 1;
                else
                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].endOfLife.totalTransportPriceCalculation = 0;
                setUpdate(!update);
              }
            }}
          >
            {t(`formLabels.details.endOfLife.totalTransportPriceIsTotal`)}
          </FormikCheckbox>
          {!(
            props.formikProps.values.fancades[props.fancadeNumber - 1].endOfLife
              .totalTransportPriceCalculation &&
            props.formikProps.values.fancades[props.fancadeNumber - 1].endOfLife
              .totalTransportPriceCalculation == 1
          ) ? (
            <>
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].endOfLife.totalTransportPrice`,
                    label: t(
                      `formLabels.details.endOfLife.totalTransportPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.endOfLife.totalTransportPrice`
                    ),
                    type: "number",
                    width: 100,
                  },
                ]}
              />
            </>
          ) : (
            <Container
              title={
                t(`formLabels.details.endOfLife.totalTransportPrice`) +
                t(`titles.calculatedSection`)
              }
              bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
              direction={"column"}
              spacing={"1rem"}
            >
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].endOfLife.totalTransportPrice`,
                    label: t(
                      `formLabels.details.endOfLife.totalTransportPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.endOfLife.totalTransportPrice`
                    ),
                    type: "number",
                    width: 100,
                    disabled: true,
                    value: +calculateEndOfLifeTransportTotal(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                    ),
                  },
                ]}
              />

              {/* Disposal Transport */}
              <FormikCheckbox
                paddingTop={"1rem"}
                checked={
                  !(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .endOfLife?.totalDisposalTransportPriceCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .endOfLife?.totalDisposalTransportPriceCalculation == 1
                  )
                }
                defaultChecked={
                  !(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .endOfLife?.totalDisposalTransportPriceCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .endOfLife?.totalDisposalTransportPriceCalculation == 1
                  )
                }
                onChange={() => {
                  if (
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .endOfLife
                  ) {
                    if (
                      !props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].endOfLife.totalDisposalTransportPriceCalculation ||
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .endOfLife.totalDisposalTransportPriceCalculation == 0
                    )
                      props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].endOfLife.totalDisposalTransportPriceCalculation = 1;
                    else
                      props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].endOfLife.totalDisposalTransportPriceCalculation = 0;
                    setUpdate(!update);
                  }
                }}
              >
                {t(
                  `formLabels.details.endOfLife.totalDisposalTransporPriceIsTotal`
                )}
              </FormikCheckbox>
              {!(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .endOfLife.totalDisposalTransportPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .endOfLife.totalDisposalTransportPriceCalculation == 1
              ) ? (
                <>
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].endOfLife.totalDisposalTransportPrice`,
                        label: t(
                          `formLabels.details.endOfLife.totalDisposalTransportPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.endOfLife.totalDisposalTransportPrice`
                        ),
                        type: "number",
                        width: 100,
                      },
                    ]}
                  />
                </>
              ) : (
                <Container
                  title={
                    t(
                      `formLabels.details.endOfLife.totalDisposalTransportPrice`
                    ) + t(`titles.calculatedSection`)
                  }
                  bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                  direction={"column"}
                  spacing={"1rem"}
                >
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].endOfLife.totalDisposalTransportPrice`,
                        label: t(
                          `formLabels.details.endOfLife.totalDisposalTransportPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.endOfLife.totalDisposalTransportPrice`
                        ),
                        type: "number",
                        width: 100,
                        disabled: true,
                        value:
                          +props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.disposalTransportQuantity *
                          +props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.disposalTransportUnitPrice,
                      },
                    ]}
                  />

                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].endOfLife.disposalTransportQuantity`,
                        label: t(
                          `formLabels.details.endOfLife.disposalTransportQuantity`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.endOfLife.disposalTransportQuantity`
                        ),
                        type: "number",
                        width: 100,
                      },
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].endOfLife.disposalTransportUnitPrice`,
                        label: t(
                          `formLabels.details.endOfLife.disposalTransportUnitPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.endOfLife.disposalTransportUnitPrice`
                        ),
                        type: "number",
                        width: 100,
                      },
                    ]}
                  />
                </Container>
              )}

              {/* Recycling Transport */}
              <FormikCheckbox
                paddingTop={"1rem"}
                checked={
                  !(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .endOfLife?.totalRecyclingTransportPriceCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .endOfLife?.totalRecyclingTransportPriceCalculation == 1
                  )
                }
                defaultChecked={
                  !(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .endOfLife?.totalRecyclingTransportPriceCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .endOfLife?.totalRecyclingTransportPriceCalculation == 1
                  )
                }
                onChange={() => {
                  if (
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .endOfLife
                  ) {
                    if (
                      !props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].endOfLife.totalRecyclingTransportPriceCalculation ||
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .endOfLife.totalRecyclingTransportPriceCalculation == 0
                    )
                      props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].endOfLife.totalRecyclingTransportPriceCalculation = 1;
                    else
                      props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].endOfLife.totalRecyclingTransportPriceCalculation = 0;
                    setUpdate(!update);
                  }
                }}
              >
                {t(
                  `formLabels.details.endOfLife.totalRecyclingTransporPriceIsTotal`
                )}
              </FormikCheckbox>
              {!(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .endOfLife.totalRecyclingTransportPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .endOfLife.totalRecyclingTransportPriceCalculation == 1
              ) ? (
                <>
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].endOfLife.totalRecyclingTransportPrice`,
                        label: t(
                          `formLabels.details.endOfLife.totalRecyclingTransportPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.endOfLife.totalRecyclingTransportPrice`
                        ),
                        type: "number",
                        width: 100,
                      },
                    ]}
                  />
                </>
              ) : (
                <Container
                  title={
                    t(
                      `formLabels.details.endOfLife.totalRecyclingTransportPrice`
                    ) + t(`titles.calculatedSection`)
                  }
                  bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                  direction={"column"}
                  spacing={"1rem"}
                >
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].endOfLife.totalRecyclingTransportPrice`,
                        label: t(
                          `formLabels.details.endOfLife.totalRecyclingTransportPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.endOfLife.totalRecyclingTransportPrice`
                        ),
                        type: "number",
                        width: 100,
                        disabled: true,
                        value:
                          +props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.recyclingTransportQuantity *
                          +props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.recyclingTransportUnitPrice,
                      },
                    ]}
                  />

                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].endOfLife.recyclingTransportQuantity`,
                        label: t(
                          `formLabels.details.endOfLife.recyclingTransportQuantity`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.endOfLife.recyclingTransportQuantity`
                        ),
                        type: "number",
                        width: 100,
                      },
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].endOfLife.recyclingTransportUnitPrice`,
                        label: t(
                          `formLabels.details.endOfLife.recyclingTransportUnitPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.endOfLife.recyclingTransportUnitPrice`
                        ),
                        type: "number",
                        width: 100,
                      },
                    ]}
                  />
                </Container>
              )}
            </Container>
          )}
        </Container>

        <Container
          title={t(`formLabels.details.endOfLife.recycling`)}
          direction={"column"}
          spacing={"1rem"}
        >
          <FormikCheckbox
            checked={
              !(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .endOfLife?.totalRecyclingRevenueCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .endOfLife?.totalRecyclingRevenueCalculation == 1
              )
            }
            defaultChecked={
              !(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .endOfLife?.totalRecyclingRevenueCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .endOfLife?.totalRecyclingRevenueCalculation == 1
              )
            }
            onChange={() => {
              if (
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .endOfLife
              ) {
                if (
                  !props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .endOfLife.totalRecyclingRevenueCalculation ||
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .endOfLife.totalRecyclingRevenueCalculation == 0
                )
                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].endOfLife.totalRecyclingRevenueCalculation = 1;
                else
                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].endOfLife.totalRecyclingRevenueCalculation = 0;
                setUpdate(!update);
              }
            }}
          >
            {t(`formLabels.details.endOfLife.totalRecyclingRevenueIsTotal`)}
          </FormikCheckbox>
          {!(
            props.formikProps.values.fancades[props.fancadeNumber - 1].endOfLife
              .totalRecyclingRevenueCalculation &&
            props.formikProps.values.fancades[props.fancadeNumber - 1].endOfLife
              .totalRecyclingRevenueCalculation == 1
          ) ? (
            <>
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].endOfLife.totalRecyclingRevenue`,
                    label: t(
                      `formLabels.details.endOfLife.totalRecyclingRevenue`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.endOfLife.totalRecyclingRevenue`
                    ),
                    type: "number",
                    width: 100,
                  },
                ]}
              />
            </>
          ) : (
            <Container
              title={
                t(`formLabels.details.endOfLife.totalRecyclingRevenue`) +
                t(`titles.calculatedSection`)
              }
              bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
              direction={"column"}
              spacing={"1rem"}
            >
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].endOfLife.totalRecyclingRevenue`,
                    label: t(
                      `formLabels.details.endOfLife.totalRecyclingRevenue`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.endOfLife.totalRecyclingRevenue`
                    ),
                    type: "number",
                    width: 100,
                    disabled: true,
                    value: +calculateRecyclingTotalRevenue(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                    ),
                  },
                ]}
              />
              {props.formikProps.values.fancades[
                props.fancadeNumber - 1
              ].fancadeTechnicalProject.type
                ?.toLowerCase()
                .indexOf("opaca") != -1 ? (
                <>
                  {/* Windows Recycling */}
                  <FormikCheckbox
                    paddingTop={"1rem"}
                    checked={
                      !(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife?.totalWindowsRecyclingRevenueCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife?.totalWindowsRecyclingRevenueCalculation ==
                          1
                      )
                    }
                    defaultChecked={
                      !(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife?.totalWindowsRecyclingRevenueCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife?.totalWindowsRecyclingRevenueCalculation ==
                          1
                      )
                    }
                    onChange={() => {
                      if (
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife
                      ) {
                        if (
                          !props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalWindowsRecyclingRevenueCalculation ||
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalWindowsRecyclingRevenueCalculation ==
                            0
                        )
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalWindowsRecyclingRevenueCalculation = 1;
                        else
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalWindowsRecyclingRevenueCalculation = 0;
                        setUpdate(!update);
                      }
                    }}
                  >
                    {t(
                      `formLabels.details.endOfLife.totalWindowsRecyclingRevenueIsTotal`
                    )}
                  </FormikCheckbox>
                  {!(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .endOfLife.totalWindowsRecyclingRevenueCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .endOfLife.totalWindowsRecyclingRevenueCalculation == 1
                  ) ? (
                    <>
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.totalWindowsRecyclingRevenue`,
                            label: t(
                              `formLabels.details.endOfLife.totalWindowsRecyclingRevenue`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.totalWindowsRecyclingRevenue`
                            ),
                            type: "number",
                            width: 100,
                          },
                        ]}
                      />
                    </>
                  ) : (
                    <Container
                      title={
                        t(
                          `formLabels.details.endOfLife.totalWindowsRecyclingRevenue`
                        ) + t(`titles.calculatedSection`)
                      }
                      bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                      direction={"column"}
                      spacing={"1rem"}
                    >
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.totalWindowsRecyclingRevenue`,
                            label: t(
                              `formLabels.details.endOfLife.totalWindowsRecyclingRevenue`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.totalWindowsRecyclingRevenue`
                            ),
                            type: "number",
                            width: 100,
                            disabled: true,
                            value:
                              +props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].endOfLife.windowsRecyclingQuantity *
                              +props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].endOfLife.windowsRecyclingUnitPrice,
                          },
                        ]}
                      />

                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.windowsRecyclingQuantity`,
                            label: t(
                              `formLabels.details.endOfLife.windowsRecyclingQuantity`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.windowsRecyclingQuantity`
                            ),
                            type: "number",
                            width: 100,
                          },
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.windowsRecyclingUnitPrice`,
                            label: t(
                              `formLabels.details.endOfLife.windowsRecyclingUnitPrice`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.windowsRecyclingUnitPrice`
                            ),
                            type: "number",
                            width: 100,
                          },
                        ]}
                      />
                    </Container>
                  )}

                  {/* External Cladding Recycling */}
                  <FormikCheckbox
                    paddingTop={"1rem"}
                    checked={
                      !(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife?.totalExternalCladdingRevenueCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife?.totalExternalCladdingRevenueCalculation ==
                          1
                      )
                    }
                    defaultChecked={
                      !(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife?.totalExternalCladdingRevenueCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife?.totalExternalCladdingRevenueCalculation ==
                          1
                      )
                    }
                    onChange={() => {
                      if (
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife
                      ) {
                        if (
                          !props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalExternalCladdingRevenueCalculation ||
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalExternalCladdingRevenueCalculation ==
                            0
                        )
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalExternalCladdingRevenueCalculation = 1;
                        else
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalExternalCladdingRevenueCalculation = 0;
                        setUpdate(!update);
                      }
                    }}
                  >
                    {t(
                      `formLabels.details.endOfLife.totalExternalCladdingRevenueIsTotal`
                    )}
                  </FormikCheckbox>
                  {!(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .endOfLife.totalExternalCladdingRevenueCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .endOfLife.totalExternalCladdingRevenueCalculation == 1
                  ) ? (
                    <>
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.totalExternalCladdingRecyclingRevenue`,
                            label: t(
                              `formLabels.details.endOfLife.totalExternalCladdingRecyclingRevenue`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.totalExternalCladdingRecyclingRevenue`
                            ),
                            type: "number",
                            width: 100,
                          },
                        ]}
                      />
                    </>
                  ) : (
                    <Container
                      title={
                        t(
                          `formLabels.details.endOfLife.totalExternalCladdingRecyclingRevenue`
                        ) + t(`titles.calculatedSection`)
                      }
                      bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                      direction={"column"}
                      spacing={"1rem"}
                    >
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.totalExternalCladdingRecyclingRevenue`,
                            label: t(
                              `formLabels.details.endOfLife.totalExternalCladdingRecyclingRevenue`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.totalExternalCladdingRecyclingRevenue`
                            ),
                            type: "number",
                            width: 100,
                            disabled: true,
                            value:
                              +props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].endOfLife.externalCladdingRecyclingQuantity *
                              +props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].endOfLife.externalCladdingRecyclingUnitPrice,
                          },
                        ]}
                      />

                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.externalCladdingRecyclingQuantity`,
                            label: t(
                              `formLabels.details.endOfLife.externalCladdingRecyclingQuantity`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.externalCladdingRecyclingQuantity`
                            ),
                            type: "number",
                            width: 100,
                          },
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.externalCladdingRecyclingUnitPrice`,
                            label: t(
                              `formLabels.details.endOfLife.externalCladdingRecyclingUnitPrice`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.externalCladdingRecyclingUnitPrice`
                            ),
                            type: "number",
                            width: 100,
                          },
                        ]}
                      />
                    </Container>
                  )}

                  {/* Insulation Recycling */}
                  <FormikCheckbox
                    paddingTop={"1rem"}
                    checked={
                      !(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife?.totalInsulationRevenueCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife?.totalInsulationRevenueCalculation == 1
                      )
                    }
                    defaultChecked={
                      !(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife?.totalInsulationRevenueCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife?.totalInsulationRevenueCalculation == 1
                      )
                    }
                    onChange={() => {
                      if (
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife
                      ) {
                        if (
                          !props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalInsulationRevenueCalculation ||
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalInsulationRevenueCalculation == 0
                        )
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalInsulationRevenueCalculation = 1;
                        else
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalInsulationRevenueCalculation = 0;
                        setUpdate(!update);
                      }
                    }}
                  >
                    {t(
                      `formLabels.details.endOfLife.totalInsulationRevenueIsTotal`
                    )}
                  </FormikCheckbox>
                  {!(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .endOfLife.totalInsulationRevenueCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .endOfLife.totalInsulationRevenueCalculation == 1
                  ) ? (
                    <>
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.totalInsulationRecyclingRevenue`,
                            label: t(
                              `formLabels.details.endOfLife.totalInsulationRecyclingRevenue`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.totalInsulationRecyclingRevenue`
                            ),
                            type: "number",
                            width: 100,
                          },
                        ]}
                      />
                    </>
                  ) : (
                    <Container
                      title={
                        t(
                          `formLabels.details.endOfLife.totalInsulationRecyclingRevenue`
                        ) + t(`titles.calculatedSection`)
                      }
                      bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                      direction={"column"}
                      spacing={"1rem"}
                    >
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.totalInsulationRecyclingRevenue`,
                            label: t(
                              `formLabels.details.endOfLife.totalInsulationRecyclingRevenue`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.totalInsulationRecyclingRevenue`
                            ),
                            type: "number",
                            width: 100,
                            disabled: true,
                            value:
                              +props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].endOfLife.insulationRecyclingQuantity *
                              +props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].endOfLife.insulationRecyclingUnitPrice,
                          },
                        ]}
                      />

                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.insulationRecyclingQuantity`,
                            label: t(
                              `formLabels.details.endOfLife.insulationRecyclingQuantity`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.insulationRecyclingQuantity`
                            ),
                            type: "number",
                            width: 100,
                          },
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.insulationRecyclingUnitPrice`,
                            label: t(
                              `formLabels.details.endOfLife.insulationRecyclingUnitPrice`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.insulationRecyclingUnitPrice`
                            ),
                            type: "number",
                            width: 100,
                          },
                        ]}
                      />
                    </Container>
                  )}

                  {/* Supporting Elements and Fixings Recycling */}
                  <FormikCheckbox
                    paddingTop={"1rem"}
                    checked={
                      !(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife
                          ?.totalSupportingElementsAndFixingsRecyclingRevenueCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife
                          ?.totalSupportingElementsAndFixingsRecyclingRevenueCalculation ==
                          1
                      )
                    }
                    defaultChecked={
                      !(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife
                          ?.totalSupportingElementsAndFixingsRecyclingRevenueCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife
                          ?.totalSupportingElementsAndFixingsRecyclingRevenueCalculation ==
                          1
                      )
                    }
                    onChange={() => {
                      if (
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife
                      ) {
                        if (
                          !props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                            .totalSupportingElementsAndFixingsRecyclingRevenueCalculation ||
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                            .totalSupportingElementsAndFixingsRecyclingRevenueCalculation ==
                            0
                        )
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalSupportingElementsAndFixingsRecyclingRevenueCalculation = 1;
                        else
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalSupportingElementsAndFixingsRecyclingRevenueCalculation = 0;
                        setUpdate(!update);
                      }
                    }}
                  >
                    {t(
                      `formLabels.details.endOfLife.totalSupportingElementsAndFixingsRecyclingRevenueIsTotal`
                    )}
                  </FormikCheckbox>
                  {!(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .endOfLife
                      .totalSupportingElementsAndFixingsRecyclingRevenueCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .endOfLife
                      .totalSupportingElementsAndFixingsRecyclingRevenueCalculation ==
                      1
                  ) ? (
                    <>
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.totalSupportingElementsAndFixingsRecyclingRevenue`,
                            label: t(
                              `formLabels.details.endOfLife.totalSupportingElementsAndFixingsRecyclingRevenue`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.totalSupportingElementsAndFixingsRecyclingRevenue`
                            ),
                            type: "number",
                            width: 100,
                          },
                        ]}
                      />
                    </>
                  ) : (
                    <Container
                      title={
                        t(
                          `formLabels.details.endOfLife.totalSupportingElementsAndFixingsRecyclingRevenue`
                        ) + t(`titles.calculatedSection`)
                      }
                      bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                      direction={"column"}
                      spacing={"1rem"}
                    >
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.totalSupportingElementsAndFixingsRecyclingRevenue`,
                            label: t(
                              `formLabels.details.endOfLife.totalSupportingElementsAndFixingsRecyclingRevenue`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.totalSupportingElementsAndFixingsRecyclingRevenue`
                            ),
                            type: "number",
                            width: 100,
                            disabled: true,
                            value:
                              +props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].endOfLife
                                .supportingElementsAndFixingsRecyclingQuantity *
                              +props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].endOfLife
                                .supportingElementsAndFixingsRecyclingUnitPrice,
                          },
                        ]}
                      />

                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.supportingElementsAndFixingsRecyclingQuantity`,
                            label: t(
                              `formLabels.details.endOfLife.supportingElementsAndFixingsRecyclingQuantity`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.supportingElementsAndFixingsRecyclingQuantity`
                            ),
                            type: "number",
                            width: 100,
                          },
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.supportingElementsAndFixingsRecyclingUnitPrice`,
                            label: t(
                              `formLabels.details.endOfLife.supportingElementsAndFixingsRecyclingUnitPrice`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.supportingElementsAndFixingsRecyclingUnitPrice`
                            ),
                            type: "number",
                            width: 100,
                          },
                        ]}
                      />
                    </Container>
                  )}
                </>
              ) : (
                props.formikProps.values.fancades[
                  props.fancadeNumber - 1
                ].fancadeTechnicalProject.type
                  ?.toLowerCase()
                  .indexOf("trasparente") != -1 && (
                  <>
                    {/* Glazed Cells Recycling */}
                    <FormikCheckbox
                      paddingTop={"1rem"}
                      checked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                            ?.totalGlazedCellsRecyclingRevenueCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                            ?.totalGlazedCellsRecyclingRevenueCalculation == 1
                        )
                      }
                      defaultChecked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                            ?.totalGlazedCellsRecyclingRevenueCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                            ?.totalGlazedCellsRecyclingRevenueCalculation == 1
                        )
                      }
                      onChange={() => {
                        if (
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                        ) {
                          if (
                            !props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].endOfLife
                              .totalGlazedCellsRecyclingRevenueCalculation ||
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].endOfLife
                              .totalGlazedCellsRecyclingRevenueCalculation == 0
                          )
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].endOfLife.totalGlazedCellsRecyclingRevenueCalculation = 1;
                          else
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].endOfLife.totalGlazedCellsRecyclingRevenueCalculation = 0;
                          setUpdate(!update);
                        }
                      }}
                    >
                      {t(
                        `formLabels.details.endOfLife.totalGlazedCellsRecyclingRevenueIsTotal`
                      )}
                    </FormikCheckbox>
                    {!(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .endOfLife
                        .totalGlazedCellsRecyclingRevenueCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .endOfLife
                        .totalGlazedCellsRecyclingRevenueCalculation == 1
                    ) ? (
                      <>
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].endOfLife.totalGlazedCellsRecyclingRevenue`,
                              label: t(
                                `formLabels.details.endOfLife.totalGlazedCellsRecyclingRevenue`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.endOfLife.totalGlazedCellsRecyclingRevenue`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </>
                    ) : (
                      <Container
                        title={
                          t(
                            `formLabels.details.endOfLife.totalGlazedCellsRecyclingRevenue`
                          ) + t(`titles.calculatedSection`)
                        }
                        bodyStyle={{
                          backgroundColor: theme.colors.lightOrange,
                        }}
                        direction={"column"}
                        spacing={"1rem"}
                      >
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].endOfLife.totalGlazedCellsRecyclingRevenue`,
                              label: t(
                                `formLabels.details.endOfLife.totalGlazedCellsRecyclingRevenue`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.endOfLife.totalGlazedCellsRecyclingRevenue`
                              ),
                              type: "number",
                              width: 100,
                              disabled: true,
                              value:
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].endOfLife.glazedCellsRecyclingQuantity *
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].endOfLife.glazedCellsUnitRecyclingPrice,
                            },
                          ]}
                        />

                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].endOfLife.glazedCellsRecyclingQuantity`,
                              label: t(
                                `formLabels.details.endOfLife.glazedCellsRecyclingQuantity`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.endOfLife.glazedCellsRecyclingQuantity`
                              ),
                              type: "number",
                              width: 100,
                            },
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].endOfLife.glazedCellsUnitRecyclingPrice`,
                              label: t(
                                `formLabels.details.endOfLife.glazedCellsUnitRecyclingPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.endOfLife.glazedCellsUnitRecyclingPrice`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </Container>
                    )}

                    {/* Double Glazing Recycling */}
                    <FormikCheckbox
                      paddingTop={"1rem"}
                      checked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                            ?.totalDoubleGlazingRecyclingRevenueCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                            ?.totalDoubleGlazingRecyclingRevenueCalculation ===
                            1
                        )
                      }
                      defaultChecked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                            ?.totalDoubleGlazingRecyclingRevenueCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                            ?.totalDoubleGlazingRecyclingRevenueCalculation ===
                            1
                        )
                      }
                      onChange={() => {
                        if (
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                        ) {
                          if (
                            !props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].endOfLife
                              .totalDoubleGlazingRecyclingRevenueCalculation ||
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].endOfLife
                              .totalDoubleGlazingRecyclingRevenueCalculation ==
                              0
                          )
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].endOfLife.totalDoubleGlazingRecyclingRevenueCalculation = 1;
                          else
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].endOfLife.totalDoubleGlazingRecyclingRevenueCalculation = 0;
                          setUpdate(!update);
                        }
                      }}
                    >
                      {t(
                        `formLabels.details.endOfLife.totalDoubleGlazingRecyclingRevenueIsTotal`
                      )}
                    </FormikCheckbox>
                    {!(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .endOfLife
                        .totalDoubleGlazingRecyclingRevenueCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .endOfLife
                        .totalDoubleGlazingRecyclingRevenueCalculation == 1
                    ) ? (
                      <>
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].endOfLife.totalDoubleGlazingRecyclingRevenue`,
                              label: t(
                                `formLabels.details.endOfLife.totalDoubleGlazingRecyclingRevenue`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.endOfLife.totalDoubleGlazingRecyclingRevenue`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </>
                    ) : (
                      <Container
                        title={
                          t(
                            `formLabels.details.endOfLife.totalDoubleGlazingRecyclingRevenue`
                          ) + t(`titles.calculatedSection`)
                        }
                        bodyStyle={{
                          backgroundColor: theme.colors.lightOrange,
                        }}
                        direction={"column"}
                        spacing={"1rem"}
                      >
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].endOfLife.totalDoubleGlazingRecyclingRevenue`,
                              label: t(
                                `formLabels.details.endOfLife.totalDoubleGlazingRecyclingRevenue`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.endOfLife.totalDoubleGlazingRecyclingRevenue`
                              ),
                              type: "number",
                              width: 100,
                              disabled: true,
                              value:
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].endOfLife.doubleGlazingRecyclingQuantity *
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].endOfLife.doubleGlazingRecyclingUnitPrice,
                            },
                          ]}
                        />

                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].endOfLife.doubleGlazingRecyclingQuantity`,
                              label: t(
                                `formLabels.details.endOfLife.doubleGlazingRecyclingQuantity`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.endOfLife.doubleGlazingRecyclingQuantity`
                              ),
                              type: "number",
                              width: 100,
                            },
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].endOfLife.doubleGlazingRecyclingUnitPrice`,
                              label: t(
                                `formLabels.details.endOfLife.doubleGlazingRecyclingUnitPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.endOfLife.doubleGlazingRecyclingUnitPrice`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </Container>
                    )}

                    {/* Uprights, Crosspieces and Fixings Recycling */}
                    <FormikCheckbox
                      paddingTop={"1rem"}
                      checked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                            ?.totalUprightsCrosspiecesFixingsRecyclingRevenueCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                            ?.totalUprightsCrosspiecesFixingsRecyclingRevenueCalculation ==
                            1
                        )
                      }
                      defaultChecked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                            ?.totalUprightsCrosspiecesFixingsRecyclingRevenueCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                            ?.totalUprightsCrosspiecesFixingsRecyclingRevenueCalculation ==
                            1
                        )
                      }
                      onChange={() => {
                        if (
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                        ) {
                          if (
                            !props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].endOfLife
                              .totalUprightsCrosspiecesFixingsRecyclingRevenueCalculation ||
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].endOfLife
                              .totalUprightsCrosspiecesFixingsRecyclingRevenueCalculation ==
                              0
                          )
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].endOfLife.totalUprightsCrosspiecesFixingsRecyclingRevenueCalculation = 1;
                          else
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].endOfLife.totalUprightsCrosspiecesFixingsRecyclingRevenueCalculation = 0;
                          setUpdate(!update);
                        }
                      }}
                    >
                      {t(
                        `formLabels.details.endOfLife.totalUprightsCrosspiecesFixingsRecyclingRevenueIsTotal`
                      )}
                    </FormikCheckbox>
                    {!(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .endOfLife
                        .totalUprightsCrosspiecesFixingsRecyclingRevenueCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .endOfLife
                        .totalUprightsCrosspiecesFixingsRecyclingRevenueCalculation ==
                        1
                    ) ? (
                      <>
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].endOfLife.totalUprightsCrosspiecesFixingsRecyclingRevenue`,
                              label: t(
                                `formLabels.details.endOfLife.totalUprightsCrosspiecesFixingsRecyclingRevenue`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.endOfLife.totalUprightsCrosspiecesFixingsRecyclingRevenue`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </>
                    ) : (
                      <Container
                        title={
                          t(
                            `formLabels.details.endOfLife.totalUprightsCrosspiecesFixingsRecyclingRevenue`
                          ) + t(`titles.calculatedSection`)
                        }
                        bodyStyle={{
                          backgroundColor: theme.colors.lightOrange,
                        }}
                        direction={"column"}
                        spacing={"1rem"}
                      >
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].endOfLife.totalUprightsCrosspiecesFixingsRecyclingRevenue`,
                              label: t(
                                `formLabels.details.endOfLife.totalUprightsCrosspiecesFixingsRecyclingRevenue`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.endOfLife.totalUprightsCrosspiecesFixingsRecyclingRevenue`
                              ),
                              type: "number",
                              width: 100,
                              disabled: true,
                              value:
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].endOfLife
                                  .uprightsCrosspiecesFixingsRecyclingQuantity *
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].endOfLife
                                  .uprightsCrosspiecesFixingsRecyclingUnitPrice,
                            },
                          ]}
                        />

                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].endOfLife.uprightsCrosspiecesFixingsRecyclingQuantity`,
                              label: t(
                                `formLabels.details.endOfLife.uprightsCrosspiecesFixingsRecyclingQuantity`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.endOfLife.uprightsCrosspiecesFixingsRecyclingQuantity`
                              ),
                              type: "number",
                              width: 100,
                            },
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].endOfLife.uprightsCrosspiecesFixingsRecyclingUnitPrice`,
                              label: t(
                                `formLabels.details.endOfLife.uprightsCrosspiecesFixingsRecyclingUnitPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.endOfLife.uprightsCrosspiecesFixingsRecyclingUnitPrice`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </Container>
                    )}
                  </>
                )
              )}
            </Container>
          )}
        </Container>
        <Container
          title={t(`formLabels.details.endOfLife.ReUse`)}
          direction={"column"}
          spacing={"1rem"}
        >
          <FormikCheckbox
            checked={
              !(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .endOfLife?.totalReUseRevenueCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .endOfLife?.totalReUseRevenueCalculation == 1
              )
            }
            defaultChecked={
              !(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .endOfLife?.totalReUseRevenueCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .endOfLife?.totalReUseRevenueCalculation == 1
              )
            }
            onChange={() => {
              if (
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .endOfLife
              ) {
                if (
                  !props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .endOfLife.totalReUseRevenueCalculation ||
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .endOfLife.totalReUseRevenueCalculation == 0
                )
                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].endOfLife.totalReUseRevenueCalculation = 1;
                else
                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].endOfLife.totalReUseRevenueCalculation = 0;
                setUpdate(!update);
              }
            }}
          >
            {t(`formLabels.details.endOfLife.totalReUseRevenueIsTotal`)}
          </FormikCheckbox>
          {!(
            props.formikProps.values.fancades[props.fancadeNumber - 1].endOfLife
              .totalReUseRevenueCalculation &&
            props.formikProps.values.fancades[props.fancadeNumber - 1].endOfLife
              .totalReUseRevenueCalculation == 1
          ) ? (
            <>
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].endOfLife.totalReUseRevenue`,
                    label: t(`formLabels.details.endOfLife.totalReUseRevenue`),
                    placeholder: t(
                      `formPlaceholders.details.endOfLife.totalReUseRevenue`
                    ),
                    type: "number",
                    width: 100,
                  },
                ]}
              />
            </>
          ) : (
            <Container
              title={
                t(`formLabels.details.endOfLife.totalReUseRevenue`) +
                t(`titles.calculatedSection`)
              }
              bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
              direction={"column"}
              spacing={"1rem"}
            >
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].endOfLife.totalReUseRevenue`,
                    label: t(`formLabels.details.endOfLife.totalReUseRevenue`),
                    placeholder: t(
                      `formPlaceholders.details.endOfLife.totalReUseRevenue`
                    ),
                    type: "number",
                    width: 100,
                    disabled: true,
                    value: +calculateReUseTotalRevenue(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                    ),
                  },
                ]}
              />
              {props.formikProps.values.fancades[
                props.fancadeNumber - 1
              ].fancadeTechnicalProject.type
                ?.toLowerCase()
                .indexOf("opaca") != -1 ? (
                <>
                  {/* Windows ReUse */}
                  <FormikCheckbox
                    paddingTop={"1rem"}
                    checked={
                      !(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife?.totalWindowsReUseRevenueCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife?.totalWindowsReUseRevenueCalculation == 1
                      )
                    }
                    defaultChecked={
                      !(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife?.totalWindowsReUseRevenueCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife?.totalWindowsReUseRevenueCalculation == 1
                      )
                    }
                    onChange={() => {
                      if (
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife
                      ) {
                        if (
                          !props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalWindowsReUseRevenueCalculation ||
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalWindowsReUseRevenueCalculation == 0
                        )
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalWindowsReUseRevenueCalculation = 1;
                        else
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalWindowsReUseRevenueCalculation = 0;
                        setUpdate(!update);
                      }
                    }}
                  >
                    {t(
                      `formLabels.details.endOfLife.totalWindowsReUseRevenueIsTotal`
                    )}
                  </FormikCheckbox>
                  {!(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .endOfLife.totalWindowsReUseRevenueCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .endOfLife.totalWindowsReUseRevenueCalculation == 1
                  ) ? (
                    <>
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.totalWindowsReUseRevenue`,
                            label: t(
                              `formLabels.details.endOfLife.totalWindowsReUseRevenue`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.totalWindowsReUseRevenue`
                            ),
                            type: "number",
                            width: 100,
                          },
                        ]}
                      />
                    </>
                  ) : (
                    <Container
                      title={
                        t(
                          `formLabels.details.endOfLife.totalWindowsReUseRevenue`
                        ) + t(`titles.calculatedSection`)
                      }
                      bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                      direction={"column"}
                      spacing={"1rem"}
                    >
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.totalWindowsReUseRevenue`,
                            label: t(
                              `formLabels.details.endOfLife.totalWindowsReUseRevenue`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.totalWindowsReUseRevenue`
                            ),
                            type: "number",
                            width: 100,
                            disabled: true,
                            value:
                              +props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].endOfLife.windowsReUseQuantity *
                              +props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].endOfLife.windowsReUseUnitPrice,
                          },
                        ]}
                      />

                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.windowsReUseQuantity`,
                            label: t(
                              `formLabels.details.endOfLife.windowsReUseQuantity`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.windowsReUseQuantity`
                            ),
                            type: "number",
                            width: 100,
                          },
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.windowsReUseUnitPrice`,
                            label: t(
                              `formLabels.details.endOfLife.windowsReUseUnitPrice`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.windowsReUseUnitPrice`
                            ),
                            type: "number",
                            width: 100,
                          },
                        ]}
                      />
                    </Container>
                  )}

                  {/* External Cladding ReUse */}
                  <FormikCheckbox
                    paddingTop={"1rem"}
                    checked={
                      !(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife
                          ?.totalExternalCladdingReUseRevenueCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife
                          ?.totalExternalCladdingReUseRevenueCalculation == 1
                      )
                    }
                    defaultChecked={
                      !(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife
                          ?.totalExternalCladdingReUseRevenueCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife
                          ?.totalExternalCladdingReUseRevenueCalculation == 1
                      )
                    }
                    onChange={() => {
                      if (
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife
                      ) {
                        if (
                          !props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                            .totalExternalCladdingReUseRevenueCalculation ||
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                            .totalExternalCladdingReUseRevenueCalculation == 0
                        )
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalExternalCladdingReUseRevenueCalculation = 1;
                        else
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalExternalCladdingReUseRevenueCalculation = 0;
                        setUpdate(!update);
                      }
                    }}
                  >
                    {t(
                      `formLabels.details.endOfLife.totalExternalCladdingReUseRevenueIsTotal`
                    )}
                  </FormikCheckbox>
                  {!(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .endOfLife.totalExternalCladdingReUseRevenueCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .endOfLife.totalExternalCladdingReUseRevenueCalculation ==
                      1
                  ) ? (
                    <>
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.totalExternalCladdingReUseRevenue`,
                            label: t(
                              `formLabels.details.endOfLife.totalExternalCladdingReUseRevenue`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.totalExternalCladdingReUseRevenue`
                            ),
                            type: "number",
                            width: 100,
                          },
                        ]}
                      />
                    </>
                  ) : (
                    <Container
                      title={
                        t(
                          `formLabels.details.endOfLife.totalExternalCladdingReUseRevenue`
                        ) + t(`titles.calculatedSection`)
                      }
                      bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                      direction={"column"}
                      spacing={"1rem"}
                    >
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.totalExternalCladdingReUseRevenue`,
                            label: t(
                              `formLabels.details.endOfLife.totalExternalCladdingReUseRevenue`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.totalExternalCladdingReUseRevenue`
                            ),
                            type: "number",
                            width: 100,
                            disabled: true,
                            value:
                              +props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].endOfLife.externalCladdingReUseQuantity *
                              +props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].endOfLife.externalCladdingReUseUnitPrice,
                          },
                        ]}
                      />

                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.externalCladdingReUseQuantity`,
                            label: t(
                              `formLabels.details.endOfLife.externalCladdingReUseQuantity`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.externalCladdingReUseQuantity`
                            ),
                            type: "number",
                            width: 100,
                          },
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.externalCladdingReUseUnitPrice`,
                            label: t(
                              `formLabels.details.endOfLife.externalCladdingReUseUnitPrice`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.externalCladdingReUseUnitPrice`
                            ),
                            type: "number",
                            width: 100,
                          },
                        ]}
                      />
                    </Container>
                  )}

                  {/* Insulation ReUse */}
                  <FormikCheckbox
                    paddingTop={"1rem"}
                    checked={
                      !(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife?.totalInsulationReUseRevenueCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife?.totalInsulationReUseRevenueCalculation == 1
                      )
                    }
                    defaultChecked={
                      !(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife?.totalInsulationReUseRevenueCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife?.totalInsulationReUseRevenueCalculation == 1
                      )
                    }
                    onChange={() => {
                      if (
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife
                      ) {
                        if (
                          !props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalInsulationReUseRevenueCalculation ||
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalInsulationReUseRevenueCalculation ==
                            0
                        )
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalInsulationReUseRevenueCalculation = 1;
                        else
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalInsulationReUseRevenueCalculation = 0;
                        setUpdate(!update);
                      }
                    }}
                  >
                    {t(
                      `formLabels.details.endOfLife.totalInsulationReUseRevenueIsTotal`
                    )}
                  </FormikCheckbox>
                  {!(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .endOfLife.totalInsulationReUseRevenueCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .endOfLife.totalInsulationReUseRevenueCalculation == 1
                  ) ? (
                    <>
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.totalInsulationReUseRevenue`,
                            label: t(
                              `formLabels.details.endOfLife.totalInsulationReUseRevenue`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.totalInsulationReUseRevenue`
                            ),
                            type: "number",
                            width: 100,
                          },
                        ]}
                      />
                    </>
                  ) : (
                    <Container
                      title={
                        t(
                          `formLabels.details.endOfLife.totalInsulationReUseRevenue`
                        ) + t(`titles.calculatedSection`)
                      }
                      bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                      direction={"column"}
                      spacing={"1rem"}
                    >
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.totalInsulationReUseRevenue`,
                            label: t(
                              `formLabels.details.endOfLife.totalInsulationReUseRevenue`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.totalInsulationReUseRevenue`
                            ),
                            type: "number",
                            width: 100,
                            disabled: true,
                            value:
                              +props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].endOfLife.insulationReUseQuantity *
                              +props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].endOfLife.insulationReUseUnitPrice,
                          },
                        ]}
                      />

                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.insulationReUseQuantity`,
                            label: t(
                              `formLabels.details.endOfLife.insulationReUseQuantity`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.insulationReUseQuantity`
                            ),
                            type: "number",
                            width: 100,
                          },
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.insulationReUseUnitPrice`,
                            label: t(
                              `formLabels.details.endOfLife.insulationReUseUnitPrice`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.insulationReUseUnitPrice`
                            ),
                            type: "number",
                            width: 100,
                          },
                        ]}
                      />
                    </Container>
                  )}

                  {/* Supporting Elements and Fixings ReUse */}
                  <FormikCheckbox
                    paddingTop={"1rem"}
                    checked={
                      !(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife
                          ?.totalSupportingElementsAndFixingsReUseRevenueCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife
                          ?.totalSupportingElementsAndFixingsReUseRevenueCalculation ==
                          1
                      )
                    }
                    defaultChecked={
                      !(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife
                          ?.totalSupportingElementsAndFixingsReUseRevenueCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife
                          ?.totalSupportingElementsAndFixingsReUseRevenueCalculation ==
                          1
                      )
                    }
                    onChange={() => {
                      if (
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].endOfLife
                      ) {
                        if (
                          !props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                            .totalSupportingElementsAndFixingsReUseRevenueCalculation ||
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                            .totalSupportingElementsAndFixingsReUseRevenueCalculation ==
                            0
                        )
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalSupportingElementsAndFixingsReUseRevenueCalculation = 1;
                        else
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife.totalSupportingElementsAndFixingsReUseRevenueCalculation = 0;
                        setUpdate(!update);
                      }
                    }}
                  >
                    {t(
                      `formLabels.details.endOfLife.totalSupportingElementsAndFixingsReUseRevenueIsTotal`
                    )}
                  </FormikCheckbox>
                  {!(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .endOfLife
                      .totalSupportingElementsAndFixingsReUseRevenueCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .endOfLife
                      .totalSupportingElementsAndFixingsReUseRevenueCalculation ==
                      1
                  ) ? (
                    <>
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.totalSupportingElementsAndFixingsReUseRevenue`,
                            label: t(
                              `formLabels.details.endOfLife.totalSupportingElementsAndFixingsReUseRevenue`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.totalSupportingElementsAndFixingsReUseRevenue`
                            ),
                            type: "number",
                            width: 100,
                          },
                        ]}
                      />
                    </>
                  ) : (
                    <Container
                      title={
                        t(
                          `formLabels.details.endOfLife.totalSupportingElementsAndFixingsReUseRevenue`
                        ) + t(`titles.calculatedSection`)
                      }
                      bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                      direction={"column"}
                      spacing={"1rem"}
                    >
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.totalSupportingElementsAndFixingsReUseRevenue`,
                            label: t(
                              `formLabels.details.endOfLife.totalSupportingElementsAndFixingsReUseRevenue`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.totalSupportingElementsAndFixingsReUseRevenue`
                            ),
                            type: "number",
                            width: 100,
                            disabled: true,
                            value:
                              +props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].endOfLife
                                .supportingElementsAndFixingsReUseQuantity *
                              +props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].endOfLife
                                .supportingElementsAndFixingsReUseUnitPrice,
                          },
                        ]}
                      />

                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.supportingElementsAndFixingsReUseQuantity`,
                            label: t(
                              `formLabels.details.endOfLife.supportingElementsAndFixingsReUseQuantity`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.supportingElementsAndFixingsReUseQuantity`
                            ),
                            type: "number",
                            width: 100,
                          },
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].endOfLife.supportingElementsAndFixingsReUseUnitPrice`,
                            label: t(
                              `formLabels.details.endOfLife.supportingElementsAndFixingsReUseUnitPrice`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.endOfLife.supportingElementsAndFixingsReUseUnitPrice`
                            ),
                            type: "number",
                            width: 100,
                          },
                        ]}
                      />
                    </Container>
                  )}
                </>
              ) : (
                props.formikProps.values.fancades[
                  props.fancadeNumber - 1
                ].fancadeTechnicalProject.type
                  ?.toLowerCase()
                  .indexOf("trasparente") != -1 && (
                  <>
                    {/* Glazed Cells ReUse */}
                    <FormikCheckbox
                      paddingTop={"1rem"}
                      checked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                            ?.totalGlazedCellsReUseRevenueCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                            ?.totalGlazedCellsReUseRevenueCalculation == 1
                        )
                      }
                      defaultChecked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                            ?.totalGlazedCellsReUseRevenueCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                            ?.totalGlazedCellsReUseRevenueCalculation == 1
                        )
                      }
                      onChange={() => {
                        if (
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                        ) {
                          if (
                            !props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].endOfLife
                              .totalGlazedCellsReUseRevenueCalculation ||
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].endOfLife
                              .totalGlazedCellsReUseRevenueCalculation == 0
                          )
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].endOfLife.totalGlazedCellsReUseRevenueCalculation = 1;
                          else
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].endOfLife.totalGlazedCellsReUseRevenueCalculation = 0;
                          setUpdate(!update);
                        }
                      }}
                    >
                      {t(
                        `formLabels.details.endOfLife.totalGlazedCellsReUseRevenueIsTotal`
                      )}
                    </FormikCheckbox>
                    {!(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .endOfLife.totalGlazedCellsReUseRevenueCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .endOfLife.totalGlazedCellsReUseRevenueCalculation == 1
                    ) ? (
                      <>
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].endOfLife.totalGlazedCellsReUseRevenue`,
                              label: t(
                                `formLabels.details.endOfLife.totalGlazedCellsReUseRevenue`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.endOfLife.totalGlazedCellsReUseRevenue`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </>
                    ) : (
                      <Container
                        title={
                          t(
                            `formLabels.details.endOfLife.totalGlazedCellsReUseRevenue`
                          ) + t(`titles.calculatedSection`)
                        }
                        bodyStyle={{
                          backgroundColor: theme.colors.lightOrange,
                        }}
                        direction={"column"}
                        spacing={"1rem"}
                      >
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].endOfLife.totalGlazedCellsReUseRevenue`,
                              label: t(
                                `formLabels.details.endOfLife.totalGlazedCellsReUseRevenue`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.endOfLife.totalGlazedCellsReUseRevenue`
                              ),
                              type: "number",
                              width: 100,
                              disabled: true,
                              value:
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].endOfLife.glazedCellsReUseQuantity *
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].endOfLife.glazedCellsUnitReUsePrice,
                            },
                          ]}
                        />

                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].endOfLife.glazedCellsReUseQuantity`,
                              label: t(
                                `formLabels.details.endOfLife.glazedCellsReUseQuantity`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.endOfLife.glazedCellsReUseQuantity`
                              ),
                              type: "number",
                              width: 100,
                            },
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].endOfLife.glazedCellsUnitReUsePrice`,
                              label: t(
                                `formLabels.details.endOfLife.glazedCellsUnitReUsePrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.endOfLife.glazedCellsUnitReUsePrice`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </Container>
                    )}

                    {/* Double Glazing ReUse */}
                    <FormikCheckbox
                      paddingTop={"1rem"}
                      checked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                            ?.totalDoubleGlazingReUseRevenueCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                            ?.totalDoubleGlazingReUseRevenueCalculation === 1
                        )
                      }
                      defaultChecked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                            ?.totalDoubleGlazingReUseRevenueCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                            ?.totalDoubleGlazingReUseRevenueCalculation === 1
                        )
                      }
                      onChange={() => {
                        if (
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                        ) {
                          if (
                            !props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].endOfLife
                              .totalDoubleGlazingReUseRevenueCalculation ||
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].endOfLife
                              .totalDoubleGlazingReUseRevenueCalculation == 0
                          )
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].endOfLife.totalDoubleGlazingReUseRevenueCalculation = 1;
                          else
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].endOfLife.totalDoubleGlazingReUseRevenueCalculation = 0;
                          setUpdate(!update);
                        }
                      }}
                    >
                      {t(
                        `formLabels.details.endOfLife.totalDoubleGlazingReUseRevenueIsTotal`
                      )}
                    </FormikCheckbox>
                    {!(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .endOfLife.totalDoubleGlazingReUseRevenueCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .endOfLife.totalDoubleGlazingReUseRevenueCalculation ==
                        1
                    ) ? (
                      <>
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].endOfLife.totalDoubleGlazingReUseRevenue`,
                              label: t(
                                `formLabels.details.endOfLife.totalDoubleGlazingReUseRevenue`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.endOfLife.totalDoubleGlazingReUseRevenue`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </>
                    ) : (
                      <Container
                        title={
                          t(
                            `formLabels.details.endOfLife.totalDoubleGlazingReUseRevenue`
                          ) + t(`titles.calculatedSection`)
                        }
                        bodyStyle={{
                          backgroundColor: theme.colors.lightOrange,
                        }}
                        direction={"column"}
                        spacing={"1rem"}
                      >
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].endOfLife.totalDoubleGlazingReUseRevenue`,
                              label: t(
                                `formLabels.details.endOfLife.totalDoubleGlazingReUseRevenue`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.endOfLife.totalDoubleGlazingReUseRevenue`
                              ),
                              type: "number",
                              width: 100,
                              disabled: true,
                              value:
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].endOfLife.doubleGlazingReUseQuantity *
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].endOfLife.doubleGlazingReUseUnitPrice,
                            },
                          ]}
                        />

                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].endOfLife.doubleGlazingReUseQuantity`,
                              label: t(
                                `formLabels.details.endOfLife.doubleGlazingReUseQuantity`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.endOfLife.doubleGlazingReUseQuantity`
                              ),
                              type: "number",
                              width: 100,
                            },
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].endOfLife.doubleGlazingReUseUnitPrice`,
                              label: t(
                                `formLabels.details.endOfLife.doubleGlazingReUseUnitPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.endOfLife.doubleGlazingReUseUnitPrice`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </Container>
                    )}

                    {/* Uprights, Crosspieces and Fixings ReUse */}
                    <FormikCheckbox
                      paddingTop={"1rem"}
                      checked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                            ?.totalUprightsCrosspiecesFixingsReUseRevenueCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                            ?.totalUprightsCrosspiecesFixingsReUseRevenueCalculation ==
                            1
                        )
                      }
                      defaultChecked={
                        !(
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                            ?.totalUprightsCrosspiecesFixingsReUseRevenueCalculation &&
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                            ?.totalUprightsCrosspiecesFixingsReUseRevenueCalculation ==
                            1
                        )
                      }
                      onChange={() => {
                        if (
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].endOfLife
                        ) {
                          if (
                            !props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].endOfLife
                              .totalUprightsCrosspiecesFixingsReUseRevenueCalculation ||
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].endOfLife
                              .totalUprightsCrosspiecesFixingsReUseRevenueCalculation ==
                              0
                          )
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].endOfLife.totalUprightsCrosspiecesFixingsReUseRevenueCalculation = 1;
                          else
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].endOfLife.totalUprightsCrosspiecesFixingsReUseRevenueCalculation = 0;
                          setUpdate(!update);
                        }
                      }}
                    >
                      {t(
                        `formLabels.details.endOfLife.totalUprightsCrosspiecesFixingsReUseRevenueIsTotal`
                      )}
                    </FormikCheckbox>
                    {!(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .endOfLife
                        .totalUprightsCrosspiecesFixingsReUseRevenueCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .endOfLife
                        .totalUprightsCrosspiecesFixingsReUseRevenueCalculation ==
                        1
                    ) ? (
                      <>
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].endOfLife.totalUprightsCrosspiecesFixingsReUseRevenue`,
                              label: t(
                                `formLabels.details.endOfLife.totalUprightsCrosspiecesFixingsReUseRevenue`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.endOfLife.totalUprightsCrosspiecesFixingsReUseRevenue`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </>
                    ) : (
                      <Container
                        title={
                          t(
                            `formLabels.details.endOfLife.totalUprightsCrosspiecesFixingsReUseRevenue`
                          ) + t(`titles.calculatedSection`)
                        }
                        bodyStyle={{
                          backgroundColor: theme.colors.lightOrange,
                        }}
                        direction={"column"}
                        spacing={"1rem"}
                      >
                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].endOfLife.totalUprightsCrosspiecesFixingsReUseRevenue`,
                              label: t(
                                `formLabels.details.endOfLife.totalUprightsCrosspiecesFixingsReUseRevenue`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.endOfLife.totalUprightsCrosspiecesFixingsReUseRevenue`
                              ),
                              type: "number",
                              width: 100,
                              disabled: true,
                              value:
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].endOfLife
                                  .uprightsCrosspiecesFixingsReUseQuantity *
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].endOfLife
                                  .uprightsCrosspiecesFixingsReUseUnitPrice,
                            },
                          ]}
                        />

                        <FormRow
                          fields={[
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].endOfLife.uprightsCrosspiecesFixingsReUseQuantity`,
                              label: t(
                                `formLabels.details.endOfLife.uprightsCrosspiecesFixingsReUseQuantity`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.endOfLife.uprightsCrosspiecesFixingsReUseQuantity`
                              ),
                              type: "number",
                              width: 100,
                            },
                            {
                              name: `fancades[${
                                props.fancadeNumber - 1
                              }].endOfLife.uprightsCrosspiecesFixingsReUseUnitPrice`,
                              label: t(
                                `formLabels.details.endOfLife.uprightsCrosspiecesFixingsReUseUnitPrice`
                              ),
                              placeholder: t(
                                `formPlaceholders.details.endOfLife.uprightsCrosspiecesFixingsReUseUnitPrice`
                              ),
                              type: "number",
                              width: 100,
                            },
                          ]}
                        />
                      </Container>
                    )}
                  </>
                )
              )}
            </Container>
          )}
        </Container>
      </Stack>
    );
  else return <Redirect to={"/"} />;
});

const StyledButton = styled(Button)`
  color: white;
  background-color: ${theme.colors.euracOrange};
  border-radius: 500rem;
  &:hover {
    background-color: ${transparentize(0.5, theme.colors.euracOrange)};
  }
`;
