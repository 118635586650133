import { CloseIcon } from "@chakra-ui/icons";
import { Button, Flex, Select, Stack, Text } from "@chakra-ui/react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useStores } from "../../../../../../hooks/useStores";
import {
  calculateHardwareTotalPrice,
  calculateOpaqueWindowsTotal,
  calculateOpaqueWindowTotal,
  calculateSystemsIntegrationTotal,
  calculateTotalLoomLength,
  calculateTotalMainFrameLength,
  calculateTransparentWindowsTotal,
} from "../../../../../../services/utils";
import theme from "../../../../../../theme/theme";
import { transparentize } from "polished";
import {
  Fancade,
  FancadeProductionType,
  Hardware,
  OpaqueWindow,
  SystemsIntegration,
} from "../../../../../../types";
import { Container } from "../../../../../Container";
import { FormRow } from "../../../../../FormRow";
import { FormikCheckbox } from "../../../../../FormikCheckbox";

type Props = {
  fancadeNumber: number;
  formikProps: any;
};
export const SystemsIntegrationForm: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [update, setUpdate] = useState(false);
  const { projects } = useStores();

  return (
    <Container title={t("systemsIntegration")} direction={"column"}>
      {/* Total System Integration */}
      <FormikCheckbox
        checked={
          !(
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject
              .totalSystemsIntegrationPriceCalculation &&
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject
              .totalSystemsIntegrationPriceCalculation == 1
          )
        }
        defaultChecked={
          !(
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject
              .totalSystemsIntegrationPriceCalculation &&
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject
              .totalSystemsIntegrationPriceCalculation == 1
          )
        }
        onChange={() => {
          if (
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject
          ) {
            if (
              !props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeTechnicalProject
                .totalSystemsIntegrationPriceCalculation ||
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeTechnicalProject
                .totalSystemsIntegrationPriceCalculation == 0
            )
              props.formikProps.values.fancades[
                props.fancadeNumber - 1
              ].fancadeTechnicalProject.totalSystemsIntegrationPriceCalculation = 1;
            else
              props.formikProps.values.fancades[
                props.fancadeNumber - 1
              ].fancadeTechnicalProject.totalSystemsIntegrationPriceCalculation = 0;
            setUpdate(!update);
          }
        }}
      >
        {t(`formLabels.details.fancadeProduction.systemsIntegrationByTotal`)}
      </FormikCheckbox>
      {!(
        props.formikProps.values.fancades[props.fancadeNumber - 1]
          .fancadeTechnicalProject.totalSystemsIntegrationPriceCalculation &&
        props.formikProps.values.fancades[props.fancadeNumber - 1]
          .fancadeTechnicalProject.totalSystemsIntegrationPriceCalculation == 1
      ) ? (
        <>
          <FormRow
            fields={[
              {
                name: `fancades[${
                  props.fancadeNumber - 1
                }].fancadeTechnicalProject.totalSystemsIntegrationPrice`,
                label: t(
                  `formLabels.details.fancadeProduction.totalSystemsIntegrationPrice`
                ),
                placeholder: t(
                  `formPlaceholders.details.fancadeProduction.totalSystemsIntegrationPrice`
                ),
                type: "number",
                width: 100,
              },
            ]}
          />
        </>
      ) : (
        <Container
          title={
            t(
              `formLabels.details.fancadeProduction.totalSystemsIntegrationPrice`
            ) + t(`titles.calculatedSection`)
          }
          bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
          direction={"column"}
          spacing={"1rem"}
        >
          <FormRow
            fields={[
              {
                name: `fancades[${
                  props.fancadeNumber - 1
                }].fancadeTechnicalProject.totalSystemsIntegrationPrice`,
                label: t(
                  `formLabels.details.fancadeProduction.totalSystemsIntegrationPrice`
                ),
                placeholder: t(
                  `formPlaceholders.details.fancadeProduction.totalSystemsIntegrationPrice`
                ),
                type: "number",
                width: 100,
                disabled: true,
                value: calculateSystemsIntegrationTotal(
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                ),
              },
            ]}
          />
          {/* Shieldings */}
          <FormikCheckbox
            paddingTop={"2rem"}
            checked={
              !(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject.totalShieldingsPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject.totalShieldingsPriceCalculation == 1
              )
            }
            defaultChecked={
              !(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject.totalShieldingsPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject.totalShieldingsPriceCalculation == 1
              )
            }
            onChange={() => {
              if (
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
              ) {
                if (
                  !props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject.totalShieldingsPriceCalculation ||
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject.totalShieldingsPriceCalculation ==
                    0
                )
                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].fancadeTechnicalProject.totalShieldingsPriceCalculation = 1;
                else
                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].fancadeTechnicalProject.totalShieldingsPriceCalculation = 0;
                setUpdate(!update);
              }
            }}
          >
            {t(`formLabels.details.fancadeProduction.shieldingByTotal`)}
          </FormikCheckbox>
          {!(
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject.totalShieldingsPriceCalculation &&
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject.totalShieldingsPriceCalculation == 1
          ) ? (
            <>
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.totalShieldingsPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.totalShieldingsPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.totalShieldingsPrice`
                    ),
                    type: "number",
                    width: 100,
                  },
                ]}
              />
            </>
          ) : (
            <Container
              title={
                t(`formLabels.details.fancadeProduction.totalShieldingsPrice`) +
                t(`titles.calculatedSection`)
              }
              bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
              direction={"column"}
              spacing={"1rem"}
            >
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.totalShieldingsPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.totalShieldingsPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.totalShieldingsPrice`
                    ),
                    type: "number",
                    width: 100,
                    disabled: true,
                    value:
                      +props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.shieldingSystemsNumber *
                      +props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.shieldingsUnitPrice,
                  },
                ]}
              />
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.shieldingSystemsNumber`,
                    label: t(
                      `formLabels.details.fancadeProduction.shieldingSystemsNumber`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.shieldingSystemsNumber`
                    ),
                    type: "number",
                    width: 100,
                    disabled: true,
                  },
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.shieldingsUnitPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.shieldingsUnitPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.shieldingsUnitPrice`
                    ),
                    type: "number",
                    width: 100,
                  },
                ]}
              />
            </Container>
          )}

          {/* Ventilation */}
          <FormikCheckbox
            paddingTop={"2rem"}
            checked={
              !(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalVentilationSystemsPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalVentilationSystemsPriceCalculation == 1
              )
            }
            defaultChecked={
              !(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalVentilationSystemsPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalVentilationSystemsPriceCalculation == 1
              )
            }
            onChange={() => {
              if (
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
              ) {
                if (
                  !props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject
                    .totalVentilationSystemsPriceCalculation ||
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject
                    .totalVentilationSystemsPriceCalculation == 0
                )
                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].fancadeTechnicalProject.totalVentilationSystemsPriceCalculation = 1;
                else
                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].fancadeTechnicalProject.totalVentilationSystemsPriceCalculation = 0;
                setUpdate(!update);
              }
            }}
          >
            {t(`formLabels.details.fancadeProduction.ventilationByTotal`)}
          </FormikCheckbox>
          {!(
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject
              .totalVentilationSystemsPriceCalculation &&
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject
              .totalVentilationSystemsPriceCalculation == 1
          ) ? (
            <>
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.totalVentilationSystemsPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.totalVentilationSystemsPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.totalVentilationSystemsPrice`
                    ),
                    type: "number",
                    width: 100,
                  },
                ]}
              />
            </>
          ) : (
            <Container
              title={
                t(
                  `formLabels.details.fancadeProduction.totalVentilationSystemsPrice`
                ) + t(`titles.calculatedSection`)
              }
              bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
              direction={"column"}
              spacing={"1rem"}
            >
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.totalVentilationSystemsPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.totalVentilationSystemsPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.totalVentilationSystemsPrice`
                    ),
                    type: "number",
                    width: 100,
                    disabled: true,
                    value:
                      +props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.ventilationDevicesNumber *
                      +props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.ventilationSystemsUnitPrice,
                  },
                ]}
              />
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.ventilationDevicesNumber`,
                    label: t(
                      `formLabels.details.fancadeProduction.ventilationDevicesNumber`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.ventilationDevicesNumber`
                    ),
                    type: "number",
                    width: 100,
                    disabled: true,
                  },
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.ventilationSystemsUnitPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.ventilationSystemsUnitPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.ventilationSystemsUnitPrice`
                    ),
                    type: "number",
                    width: 100,
                  },
                ]}
              />
            </Container>
          )}

          {/* Air Conditioning */}
          <FormikCheckbox
            paddingTop={"2rem"}
            checked={
              !(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalAirConditioningSystemsPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalAirConditioningSystemsPriceCalculation == 1
              )
            }
            defaultChecked={
              !(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalAirConditioningSystemsPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalAirConditioningSystemsPriceCalculation == 1
              )
            }
            onChange={() => {
              if (
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
              ) {
                if (
                  !props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject
                    .totalAirConditioningSystemsPriceCalculation ||
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject
                    .totalAirConditioningSystemsPriceCalculation == 0
                )
                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].fancadeTechnicalProject.totalAirConditioningSystemsPriceCalculation = 1;
                else
                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].fancadeTechnicalProject.totalAirConditioningSystemsPriceCalculation = 0;
                setUpdate(!update);
              }
            }}
          >
            {t(`formLabels.details.fancadeProduction.airConditioningByTotal`)}
          </FormikCheckbox>
          {!(
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject
              .totalAirConditioningSystemsPriceCalculation &&
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject
              .totalAirConditioningSystemsPriceCalculation == 1
          ) ? (
            <>
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.totalAirConditioningSystemsPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.totalAirConditioningSystemsPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.totalAirConditioningSystemsPrice`
                    ),
                    type: "number",
                    width: 100,
                  },
                ]}
              />
            </>
          ) : (
            <Container
              title={
                t(
                  `formLabels.details.fancadeProduction.totalAirConditioningSystemsPrice`
                ) + t(`titles.calculatedSection`)
              }
              bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
              direction={"column"}
              spacing={"1rem"}
            >
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.totalAirConditioningSystemsPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.totalAirConditioningSystemsPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.totalAirConditioningSystemsPrice`
                    ),
                    type: "number",
                    width: 100,
                    disabled: true,
                    value:
                      +props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.airConditioningDevicesNumber *
                      +props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.airConditioningSystemsUnitPrice,
                  },
                ]}
              />
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.airConditioningDevicesNumber`,
                    label: t(
                      `formLabels.details.fancadeProduction.airConditioningDevicesNumber`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.airConditioningDevicesNumber`
                    ),
                    type: "number",
                    width: 100,
                    disabled: true,
                  },
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.airConditioningSystemsUnitPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.airConditioningSystemsUnitPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.airConditioningSystemsUnitPrice`
                    ),
                    type: "number",
                    width: 100,
                  },
                ]}
              />
            </Container>
          )}

          {/* Thermic Solar Panels */}
          <FormikCheckbox
            paddingTop={"2rem"}
            checked={
              !(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalThermicSolarPanelsPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalThermicSolarPanelsPriceCalculation == 1
              )
            }
            defaultChecked={
              !(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalThermicSolarPanelsPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalThermicSolarPanelsPriceCalculation == 1
              )
            }
            onChange={() => {
              if (
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
              ) {
                if (
                  !props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject
                    .totalThermicSolarPanelsPriceCalculation ||
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject
                    .totalThermicSolarPanelsPriceCalculation == 0
                )
                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].fancadeTechnicalProject.totalThermicSolarPanelsPriceCalculation = 1;
                else
                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].fancadeTechnicalProject.totalThermicSolarPanelsPriceCalculation = 0;
                setUpdate(!update);
              }
            }}
          >
            {t(
              `formLabels.details.fancadeProduction.thermicSolarPanelsByTotal`
            )}
          </FormikCheckbox>
          {!(
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject
              .totalThermicSolarPanelsPriceCalculation &&
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject
              .totalThermicSolarPanelsPriceCalculation == 1
          ) ? (
            <>
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.totalThermicSolarPanelsPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.totalThermicSolarPanelsPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.totalThermicSolarPanelsPrice`
                    ),
                    type: "number",
                    width: 100,
                  },
                ]}
              />
            </>
          ) : (
            <Container
              title={
                t(
                  `formLabels.details.fancadeProduction.totalThermicSolarPanelsPrice`
                ) + t(`titles.calculatedSection`)
              }
              bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
              direction={"column"}
              spacing={"1rem"}
            >
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.totalThermicSolarPanelsPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.totalThermicSolarPanelsPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.totalThermicSolarPanelsPrice`
                    ),
                    type: "number",
                    width: 100,
                    disabled: true,
                    value:
                      +props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.thermicSolarPanelsNumber *
                      +props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.thermicSolarPanelsUnitPrice,
                  },
                ]}
              />
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.thermicSolarPanelsNumber`,
                    label: t(
                      `formLabels.details.fancadeProduction.thermicSolarPanelsNumber`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.thermicSolarPanelsNumber`
                    ),
                    type: "number",
                    width: 100,
                    disabled: true,
                  },
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.thermicSolarPanelsUnitPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.thermicSolarPanelsUnitPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.thermicSolarPanelsUnitPrice`
                    ),
                    type: "number",
                    width: 100,
                  },
                ]}
              />
            </Container>
          )}

          {/* Photovoltaic Modules */}
          <FormikCheckbox
            paddingTop={"2rem"}
            checked={
              !(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalPhotovoltaicModulesPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalPhotovoltaicModulesPriceCalculation == 1
              )
            }
            defaultChecked={
              !(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalPhotovoltaicModulesPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
                  .totalPhotovoltaicModulesPriceCalculation == 1
              )
            }
            onChange={() => {
              if (
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject
              ) {
                if (
                  !props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject
                    .totalPhotovoltaicModulesPriceCalculation ||
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject
                    .totalPhotovoltaicModulesPriceCalculation == 0
                )
                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].fancadeTechnicalProject.totalPhotovoltaicModulesPriceCalculation = 1;
                else
                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].fancadeTechnicalProject.totalPhotovoltaicModulesPriceCalculation = 0;
                setUpdate(!update);
              }
            }}
          >
            {t(
              `formLabels.details.fancadeProduction.photovoltaicModulesByTotal`
            )}
          </FormikCheckbox>
          {!(
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject
              .totalPhotovoltaicModulesPriceCalculation &&
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject
              .totalPhotovoltaicModulesPriceCalculation == 1
          ) ? (
            <>
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.totalPhotovoltaicModulesPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.totalPhotovoltaicModulesPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.totalPhotovoltaicModulesPrice`
                    ),
                    type: "number",
                    width: 100,
                  },
                ]}
              />
            </>
          ) : (
            <Container
              title={
                t(
                  `formLabels.details.fancadeProduction.totalPhotovoltaicModulesPrice`
                ) + t(`titles.calculatedSection`)
              }
              bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
              direction={"column"}
              spacing={"1rem"}
            >
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.totalPhotovoltaicModulesPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.totalPhotovoltaicModulesPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.totalPhotovoltaicModulesPrice`
                    ),
                    type: "number",
                    width: 100,
                    disabled: true,
                    value:
                      +props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.photovoltaicModulesNumber *
                      +props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.photovoltaicModulesUnitPrice,
                  },
                ]}
              />
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.photovoltaicModulesNumber`,
                    label: t(
                      `formLabels.details.fancadeProduction.photovoltaicModulesNumber`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.photovoltaicModulesNumber`
                    ),
                    type: "number",
                    width: 100,
                    disabled: true,
                  },
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.photovoltaicModulesUnitPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.photovoltaicModulesUnitPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.photovoltaicModulesUnitPrice`
                    ),
                    type: "number",
                    width: 100,
                  },
                ]}
              />
            </Container>
          )}
          <Text fontSize={"2xl"}>{t("customSystemsIntegrations")}</Text>
          {props.formikProps.values.fancades[
            props.fancadeNumber - 1
          ].fancadeTechnicalProject.systemsIntegrations.map(
            (systemIntegration: SystemsIntegration, index: number) => (
              <>
                {" "}
                <FormikCheckbox
                  checked={
                    !(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .fancadeTechnicalProject.systemsIntegrations[index]
                        .totalPriceCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .fancadeTechnicalProject.systemsIntegrations[index]
                        .totalPriceCalculation == 1
                    )
                  }
                  defaultChecked={
                    !(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .fancadeTechnicalProject.systemsIntegrations[index]
                        .totalPriceCalculation &&
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .fancadeTechnicalProject.systemsIntegrations[index]
                        .totalPriceCalculation == 1
                    )
                  }
                  onChange={() => {
                    if (
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .fancadeTechnicalProject
                    ) {
                      if (
                        !props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject.systemsIntegrations[index]
                          .totalPriceCalculation ||
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject.systemsIntegrations[index]
                          .totalPriceCalculation == 0
                      )
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject.systemsIntegrations[
                          index
                        ].totalPriceCalculation = 1;
                      else
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject.systemsIntegrations[
                          index
                        ].totalPriceCalculation = 0;
                      setUpdate(!update);
                    }
                  }}
                >
                  {t(`manualInput`) +
                    " - " +
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject.systemsIntegrations[index].name}
                </FormikCheckbox>
                {!(
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject.systemsIntegrations[index]
                    .totalPriceCalculation &&
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject.systemsIntegrations[index]
                    .totalPriceCalculation == 1
                ) ? (
                  <>
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].fancadeTechnicalProject.systemsIntegrations[${index}].totalPrice`,
                          label:
                            t(`totalPrice`) +
                            " - " +
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject.systemsIntegrations[index]
                              .name,
                          placeholder:
                            t(`totalPrice`) +
                            " - " +
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject.systemsIntegrations[index]
                              .name,
                          type: "number",
                          width: 100,
                        },
                      ]}
                    />
                  </>
                ) : (
                  <Container
                    title={
                      t(`totalPrice`) +
                      " - " +
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .fancadeTechnicalProject.systemsIntegrations[index]
                        .name +
                      t(`titles.calculatedSection`)
                    }
                    bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                    direction={"column"}
                    spacing={"1rem"}
                  >
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].fancadeTechnicalProject.systemsIntegrations[${index}].totalPrice`,
                          label:
                            t(`totalPrice`) +
                            " - " +
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject.systemsIntegrations[index]
                              .name,
                          placeholder:
                            t(`totalPrice`) +
                            " - " +
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject.systemsIntegrations[index]
                              .name,
                          type: "number",
                          width: 100,
                          disabled: true,
                          value:
                            +props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject.systemsIntegrations[index]
                              .units *
                            +props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject.systemsIntegrations[index]
                              .unitPrice,
                        },
                      ]}
                    />
                    <FormRow
                      fields={[
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].fancadeTechnicalProject.systemsIntegrations[${index}].units`,
                          label:
                            t(`units`) +
                            " - " +
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject.systemsIntegrations[index]
                              .name,
                          placeholder:
                            t(`units`) +
                            " - " +
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject.systemsIntegrations[index]
                              .name,
                          type: "number",
                          width: 100,
                          disabled: true,
                        },
                        {
                          name: `fancades[${
                            props.fancadeNumber - 1
                          }].fancadeTechnicalProject.systemsIntegrations[${index}].unitPrice`,
                          label:
                            t(`unitPrice`) +
                            " - " +
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject.systemsIntegrations[index]
                              .name,
                          placeholder:
                            t(`unitPrice`) +
                            " - " +
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject.systemsIntegrations[index]
                              .name,
                          type: "number",
                          width: 100,
                        },
                      ]}
                    />
                  </Container>
                )}
              </>
            )
          )}
        </Container>
      )}
    </Container>
  );
};

const StyledButton = styled(Button)`
  color: white;
  background-color: ${theme.colors.euracOrange};
  border-radius: 500rem;

  &:hover {
    background-color: ${transparentize(0.5, theme.colors.euracOrange)};
  }
`;
