import React from "react";
import { Select, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { Project } from "../../../../types";
import Plot from "react-plotly.js";
import { getLCCInvestmentPerPhasePlotValues } from "../../../../services/utils";
import theme from "../../../../theme/theme";

type Props = {
  project: Project;
};

export const BenchmarkPlotsTab: React.FC<Props> = ({ project }) => {
  const { t } = useTranslation();
  const [currentFancadeIndex, setCurrentFancadeIndex] = React.useState(1);

  const lccInvestmentPerPhasePlotLabels = [
    t("tablesVoices.report.priceForPhase.project"),
    t("tablesVoices.report.priceForPhase.fancadeProduction"),
    t("tablesVoices.report.priceForPhase.transportToConstructionSite"),
    t("tablesVoices.report.priceForPhase.installation"),

    t("tablesVoices.report.priceForPhase.serviceLife"),
    t("tablesVoices.report.priceForPhase.maintenance"),
    t("tablesVoices.report.priceForPhase.disposal"),
    t("tablesVoices.report.priceForPhase.recycling"),
  ];
  const lccInvestmentPerPhasePlotColors = [
    theme.colors.projectMarker,
    theme.colors.productionMarker,
    theme.colors.transportMarker,
    theme.colors.installationMarker,
    theme.colors.serviceLifeMarker,
    theme.colors.maintenanceMarker,
    theme.colors.disposalMarker,
    theme.colors.recyclingMarker,
  ];

  const lccInvestmentPerPhaseBenchmarkPlotTitle = t(
    "plotTitles.lccInvestmentPerPhaseBenchmarkPlotTitle"
  );

  const currentFancade = project.fancades
    ? project.fancades[currentFancadeIndex]
    : null;
  const benchmarkFancade = project.fancades ? project.fancades[0] : null;
  return (
    <Stack
      w={"99%"}
      justifyContent={"center"}
      alignItems={"center"}
      spacing={"2rem"}
    >
      <Text marginTop={"1rem"} fontSize={"2xl"}>
        {t("selectFancade") + project.id}
      </Text>
      <Select
        w={"80%"}
        value={currentFancadeIndex}
        onChange={(event) => {
          setCurrentFancadeIndex(+event.target.value);
        }}
      >
        {project.fancades?.map((fancade, index) => (
          <>
            {index > 0 && (
              <option value={index}>
                {t("fancade") + " #" + (+index + 1)}
              </option>
            )}
          </>
        ))}
      </Select>
      {getLCCInvestmentPerPhasePlotValues(project, currentFancadeIndex).length >
        0 && (
        <Plot
          style={{ paddingBottom: "3rem" }}
          data={getLCCInvestmentPerPhasePlotValues(
            project,
            currentFancadeIndex
          ).map((value, valueIndex) => {
            return {
              y: [
                getLCCInvestmentPerPhasePlotValues(
                  project,
                  currentFancadeIndex
                )[valueIndex],
                getLCCInvestmentPerPhasePlotValues(project, 0)[valueIndex],
              ],
              x: [
                currentFancade && currentFancade.customName
                  ? currentFancade.customName
                  : t("fancade") + " #" + (+currentFancadeIndex + 1),
                benchmarkFancade && benchmarkFancade.customName
                  ? "Benchmark / " + benchmarkFancade.customName
                  : "Benchmark",
              ],
              type: "bar",
              name: lccInvestmentPerPhasePlotLabels[valueIndex],
              marker: {
                color: lccInvestmentPerPhasePlotColors[valueIndex],
              },
            };
          })}
          layout={{
            title: lccInvestmentPerPhaseBenchmarkPlotTitle,
            barmode: "stack",
            xaxis: { automargin: true },
            height: 500,
          }}
        />
      )}
    </Stack>
  );
};
