import { FieldType } from "../types";
import { FormRow } from "./FormRow";

type Props = {
  fields: FieldType[];
  formStructure: number[];
  spacing?: string;
  onChange?: any;
};

export const SimpleFormGenerator: React.FC<Props> = (props) => {
  let rows: any[] = [];
  let currentField = 0;
  for (let i = 0; i != props.formStructure.length; i++) {
    let fields: FieldType[] = [];
    for (let j = 0; j != props.formStructure[i]; j++) {
      if (currentField <= props.fields.length)
        fields.push(props.fields[currentField]);
      currentField++;
    }
    rows.push(
      <FormRow
        onChange={(e) => {
          if (props.onChange) props.onChange(e);
        }}
        paddingBottom={props.spacing}
        fields={fields}
      ></FormRow>
    );
  }
  return <>{rows}</>;
};
