import {
  buildingSiteToTable,
  costPerPhaseToTable,
  endOfLifeToTable,
  fancadeProjectToTable,
  fancadeTechnicalProjectToTable,
  generalDataToTable,
  kpiToTable,
  maintenanceToTable,
  productionToTable,
  serviceLifeToTable,
  transportToTable,
} from "../services/tabToTable";
import { Project } from "../types";
import { PDFPage } from "./PDFPage";
import { PDFTable } from "./PDFTable";
import { Text } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";

import {
  getActualizationBenchmarkRows,
  getActualizationLifeServiceRows,
  getActualizationMaintenanceRows,
} from "../services/utils";

type Props = {
  project: Project;
};
export const ProjectPDF: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  let titles = [t("attribute")];

  //Add fancades' names to titles
  props.project.fancades?.map((fancade, index) => {
    let title = "";
    if (fancade.customName) {
      title += fancade.customName;
    } else {
      title += t("fancade") + " #" + (index + 1);
    }
    if (index === 0) {
      titles.push("Benchmark / " + title);
    } else {
      titles.push(title);
    }
  });

  return (
    <>
      <PDFPage>
        <Text
          style={{
            textAlign: "center",
            padding: 5,
            fontSize: 25,
            fontWeight: "bold",
          }}
        >
          {t("titles.details.general")}
        </Text>
        <PDFTable
          columnsTitles={[]}
          rows={generalDataToTable(props.project)}
        ></PDFTable>
      </PDFPage>
      <PDFPage>
        <Text
          style={{
            textAlign: "center",
            padding: 5,
            fontSize: 25,
            fontWeight: "bold",
          }}
        >
          {t("titles.details.fancadeTechnicalProject")}
        </Text>
        <PDFTable
          columnsTitles={titles}
          rows={fancadeTechnicalProjectToTable(props.project)}
        ></PDFTable>
      </PDFPage>
      <PDFPage>
        <Text
          style={{
            textAlign: "center",
            padding: 5,
            fontSize: 25,
            fontWeight: "bold",
          }}
        >
          {t("titles.details.fancadeProject")}
        </Text>
        <PDFTable
          columnsTitles={titles}
          rows={fancadeProjectToTable(props.project)}
        ></PDFTable>
      </PDFPage>
      <PDFPage>
        <Text
          style={{
            textAlign: "center",
            padding: 5,
            fontSize: 25,
            fontWeight: "bold",
          }}
        >
          {t("titles.details.fancadeProduction")}
        </Text>
        <PDFTable
          columnsTitles={titles}
          rows={productionToTable(props.project)}
        ></PDFTable>
      </PDFPage>
      <PDFPage>
        <Text
          style={{
            textAlign: "center",
            padding: 5,
            fontSize: 25,
            fontWeight: "bold",
          }}
        >
          {t("titles.details.transport")}
        </Text>
        <PDFTable
          columnsTitles={titles}
          rows={transportToTable(props.project)}
        ></PDFTable>
      </PDFPage>
      <PDFPage>
        <Text
          style={{
            textAlign: "center",
            padding: 5,
            fontSize: 25,
            fontWeight: "bold",
          }}
        >
          {t("titles.details.buildingSite")}
        </Text>
        <PDFTable
          columnsTitles={titles}
          rows={buildingSiteToTable(props.project)}
        ></PDFTable>
      </PDFPage>
      <PDFPage>
        <Text
          style={{
            textAlign: "center",
            padding: 5,
            fontSize: 25,
            fontWeight: "bold",
          }}
        >
          {t("titles.details.serviceLife")}
        </Text>
        <PDFTable
          columnsTitles={titles}
          rows={serviceLifeToTable(props.project)}
        ></PDFTable>
      </PDFPage>
      <PDFPage>
        <Text
          style={{
            textAlign: "center",
            padding: 5,
            fontSize: 25,
            fontWeight: "bold",
          }}
        >
          {t("titles.details.maintenance")}
        </Text>
        <PDFTable
          columnsTitles={titles}
          rows={maintenanceToTable(props.project)}
        ></PDFTable>
      </PDFPage>
      <PDFPage>
        {props.project.fancades &&
          props.project.fancades?.map((fancade, index) => (
            <>
              <Text
                style={{
                  textAlign: "center",
                  padding: 5,
                  fontSize: 15,
                  fontWeight: "bold",
                }}
              >
                {t("tablesVoices.costsActualization.serviceOfLife") +
                  " " +
                  t("fancade") +
                  " #" +
                  (+index + 1)}
              </Text>
              <PDFTable
                style={{ marginBottom: 20 }}
                columnsTitles={[
                  t("tablesVoices.costsActualization.year"),
                  t("tablesVoices.costsActualization.value"),
                  t("tablesVoices.costsActualization.total"),
                ]}
                rows={getActualizationLifeServiceRows(fancade, index)}
              ></PDFTable>
              <Text
                style={{
                  textAlign: "center",
                  padding: 5,
                  fontSize: 15,
                  fontWeight: "bold",
                }}
              >
                {t("tablesVoices.costsActualization.maintenance") +
                  " " +
                  t("fancade") +
                  " #" +
                  (+index + 1)}
              </Text>
              <PDFTable
                style={{ marginBottom: 20 }}
                columnsTitles={[
                  t("tablesVoices.costsActualization.year"),
                  t("tablesVoices.costsActualization.value"),
                  t("tablesVoices.costsActualization.total"),
                ]}
                rows={getActualizationMaintenanceRows(fancade, index)}
              ></PDFTable>
              {index > 0 && (
                <>
                  <Text
                    style={{
                      textAlign: "center",
                      padding: 5,
                      fontSize: 15,
                      fontWeight: "bold",
                    }}
                  >
                    {"Differenza facciata #" + (+index + 1) + " con benchmark"}
                  </Text>
                  <PDFTable
                    style={{ marginBottom: 20 }}
                    columnsTitles={[
                      t("tablesVoices.costsActualization.year"),
                      t("tablesVoices.costsActualization.serviceOfLifePrice"),
                      t("tablesVoices.costsActualization.maintenancePrice"),
                    ]}
                    rows={getActualizationBenchmarkRows(
                      props.project,
                      fancade,
                      index
                    )}
                  ></PDFTable>
                </>
              )}
            </>
          ))}
      </PDFPage>
      <PDFPage>
        <Text
          style={{
            textAlign: "center",
            padding: 5,
            fontSize: 25,
            fontWeight: "bold",
          }}
        >
          {t("titles.details.endOfLife")}
        </Text>
        <PDFTable
          columnsTitles={titles}
          rows={endOfLifeToTable(props.project)}
        ></PDFTable>
      </PDFPage>
      <PDFPage>
        <Text
          style={{
            textAlign: "center",
            padding: 5,
            fontSize: 25,
            fontWeight: "bold",
          }}
        >
          {t("titles.details.finalReport")}
        </Text>
        {props.project.fancades?.map((fancade, index) => (
          <>
            <Text
              style={{
                textAlign: "center",
                padding: 5,
                fontSize: 15,
                fontWeight: "bold",
              }}
            >
              {t("fancade") + " #" + (+index + 1)}
            </Text>
            <PDFTable
              style={{ marginBottom: 20 }}
              columnsTitles={[
                t("tablesHeaders.report.costForPhase"),
                t("tablesHeaders.report.total"),
                t("tablesHeaders.report.investmentPercentage"),
                t("tablesHeaders.report.lccPercentage"),
              ]}
              rows={costPerPhaseToTable(props.project, index)}
            ></PDFTable>
            <PDFTable
              style={{ marginBottom: 20 }}
              columnsTitles={[
                t("tablesHeaders.report.kpi"),
                t("tablesHeaders.report.value"),
              ]}
              rows={kpiToTable(props.project, index)}
            ></PDFTable>
            {index > 0 && (
              <>
                <Text
                  style={{
                    textAlign: "center",
                    padding: 5,
                    fontSize: 15,
                    fontWeight: "bold",
                  }}
                >
                  {"Differenza facciata #" + (+index + 1) + " con benchmark"}
                </Text>
                <PDFTable
                  style={{ marginBottom: 20 }}
                  columnsTitles={[
                    t("tablesHeaders.report.costForPhase"),
                    t("tablesHeaders.report.total"),
                    t("tablesHeaders.report.investmentPercentage"),
                    t("tablesHeaders.report.lccPercentage"),
                  ]}
                  rows={costPerPhaseToTable(props.project, index, true)}
                ></PDFTable>
                <PDFTable
                  style={{ marginBottom: 20 }}
                  columnsTitles={[
                    t("tablesHeaders.report.kpi"),
                    t("tablesHeaders.report.value"),
                  ]}
                  rows={kpiToTable(props.project, index, true)}
                ></PDFTable>
              </>
            )}
          </>
        ))}
        {!props.project.isBenchmark && (
          <>
            <Text
              style={{
                textAlign: "center",
                padding: 5,
                fontSize: 15,
                fontWeight: "bold",
              }}
            >
              {t("tablesVoices.report.allFancadesTab")}
            </Text>
            <PDFTable
              style={{ marginBottom: 20 }}
              columnsTitles={[
                t("tablesHeaders.report.costForPhase"),
                t("tablesHeaders.report.total"),
                t("tablesHeaders.report.investmentPercentage"),
                t("tablesHeaders.report.lccPercentage"),
              ]}
              rows={costPerPhaseToTable(props.project, -1)}
            ></PDFTable>
            <PDFTable
              style={{ marginBottom: 20 }}
              columnsTitles={[
                t("tablesHeaders.report.kpi"),
                t("tablesHeaders.report.value"),
              ]}
              rows={kpiToTable(props.project, -1)}
            ></PDFTable>
          </>
        )}
      </PDFPage>
    </>
  );
};
