import { CloseIcon } from "@chakra-ui/icons";
import { Button, Flex, Select, Stack, Text } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useStores } from "../../../../../../hooks/useStores";
import {
  calculateHardwareTotalPrice,
  calculateOpaqueWindowsTotal,
  calculateOpaqueWindowTotal,
  calculateTotalLoomLength,
} from "../../../../../../services/utils";
import theme from "../../../../../../theme/theme";
import { transparentize } from "polished";
import {
  FancadeProductionType,
  Hardware,
  OpaqueWindow,
  Project,
} from "../../../../../../types";
import { Container } from "../../../../../Container";
import { FormRow } from "../../../../../FormRow";
import { FormikCheckbox } from "../../../../../FormikCheckbox";

type Props = {
  fancadeNumber: number;
  formikProps: any;
};
export const OpaqueWindowsForm: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [update, setUpdate] = useState(false);
  const { projects } = useStores();
  let windowContainers: any[] = [];

  props.formikProps.values.fancades[
    props.fancadeNumber - 1
  ].fancadeTechnicalProject?.windows?.map(
    (window: OpaqueWindow, index: number) => {
      let fields: any[] = [];
      //Window total price
      fields.push(
        <FormikCheckbox
          checked={
            !props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject.windows[index].totalPriceCalculation ||
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject.windows[index].totalPriceCalculation ===
              0
          }
          defaultChecked={
            !props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject.windows[index].totalPriceCalculation ||
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeTechnicalProject.windows[index].totalPriceCalculation ===
              0
          }
          onChange={() => {
            if (
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                ?.fancadeTechnicalProject
            ) {
              if (
                !props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject.windows[index]
                  .totalPriceCalculation ||
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject.windows[index]
                  .totalPriceCalculation == 0
              )
                props.formikProps.values.fancades[
                  props.fancadeNumber - 1
                ].fancadeTechnicalProject.windows[
                  index
                ].totalPriceCalculation = 1;
              else
                props.formikProps.values.fancades[
                  props.fancadeNumber - 1
                ].fancadeTechnicalProject.windows[
                  index
                ].totalPriceCalculation = 0;
              setUpdate(!update);
            }
          }}
        >
          {t(`formLabels.details.fancadeProduction.checkboxTotalWindowPrice`) +
            " #" +
            (+index + 1)}
        </FormikCheckbox>
      );
      if (
        !props.formikProps.values.fancades[props.fancadeNumber - 1]
          .fancadeTechnicalProject.windows[index].totalPriceCalculation ||
        props.formikProps.values.fancades[props.fancadeNumber - 1]
          .fancadeTechnicalProject.windows[index].totalPriceCalculation == 0
      ) {
        fields.push(
          <>
            <FormRow
              fields={[
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].fancadeTechnicalProject.windows[${index}].totalPrice`,
                  label:
                    t(`formLabels.details.fancadeProduction.totalWindowPrice`) +
                    " #" +
                    (+index + 1),
                  placeholder: t(
                    `formPlaceholders.details.fancadeProduction.totalWindowPrice`
                  ),
                  type: "number",
                  width: 100,
                },
              ]}
            />
          </>
        );
      } else {
        fields.push(
          <Stack w={"100%"}>
            <FormRow
              fields={[
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].fancadeTechnicalProject.windows[${index}].totalPrice`,
                  label:
                    t(`formLabels.details.fancadeProduction.totalWindowPrice`) +
                    " #" +
                    (+index + 1),
                  placeholder: t(
                    `formPlaceholders.details.fancadeProduction.totalWindowPrice`
                  ),
                  type: "number",
                  width: 100,
                  disabled: true,
                  value: calculateOpaqueWindowTotal(
                    props.formikProps.values.fancades[props.fancadeNumber - 1],
                    index
                  ),
                },
              ]}
            />
            <Text>
              {t(`formLabels.details.fancadeProduction.windowSelect`)}
            </Text>
            <Select
              value={
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject.windows[index].totalPriceCalculation
              }
              onChange={(e) => {
                props.formikProps.values.fancades[
                  props.fancadeNumber - 1
                ].fancadeTechnicalProject.windows[index].totalPriceCalculation =
                  +e.target.options.selectedIndex + 1;
                setUpdate(!update);
              }}
            >
              <option value={1}>
                {t(`formLabels.details.fancadeProduction.unityOption`)}
              </option>
              <option value={2}>
                {t(`formLabels.details.fancadeProduction.componentsOption`)}
              </option>
            </Select>
          </Stack>
        );

        if (
          props.formikProps.values.fancades[props.fancadeNumber - 1]
            ?.fancadeTechnicalProject?.windows[index]?.totalPriceCalculation &&
          props.formikProps.values.fancades[props.fancadeNumber - 1]
            ?.fancadeTechnicalProject?.windows[index]?.totalPriceCalculation ==
            1
        ) {
          //Window by unit
          fields.push(
            <Stack w={"100%"}>
              {/* <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.windows[${index}].windowsNumber`,
                    label:
                      t(`formLabels.details.fancadeProduction.windowsNumber`) +
                      " #" +
                      (+index + 1),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.windowsNumber`
                    ),
                    type: "number",
                    width: 100,
                    disabled: true,
                  },
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.windows[${index}].windowsUnitSurface`,
                    label:
                      t(
                        `formLabels.details.fancadeProject.windowType.windowsUnitSurface`
                      ) +
                      " #" +
                      (+index + 1),
                    placeholder: t(
                      `formLabels.details.fancadeProject.windowType.windowsUnitSurface`
                    ),
                    type: "number",
                    width: 100,
                    disabled: true,
                  },
                ]}
              /> */}
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.windows[${index}].windowsSurface`,
                    label:
                      t(
                        `formLabels.details.fancadeProject.windowType.windowsSurface`
                      ) +
                      " #" +
                      (+index + 1),
                    placeholder: t(
                      `formLabels.details.fancadeProject.windowType.windowsSurface`
                    ),
                    type: "number",
                    width: 100,
                    disabled: true,
                  },
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.windows[${index}].unitPrice`,
                    label:
                      t(`formLabels.details.fancadeProduction.unitPrice`) +
                      " #" +
                      (+index + 1),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.unitPrice`
                    ),
                    type: "number",
                    width: 100,
                  },
                ]}
              />
            </Stack>
          );
        } else if (
          props.formikProps.values.fancades[props.fancadeNumber - 1]
            ?.fancadeTechnicalProject?.windows[index]?.totalPriceCalculation &&
          props.formikProps.values.fancades[props.fancadeNumber - 1]
            ?.fancadeTechnicalProject?.windows[index]?.totalPriceCalculation ==
            2
        ) {
          //Window by components
          fields.push(
            <Stack w={"100%"}>
              {/* Loom */}
              <FormikCheckbox
                paddingTop={"3rem"}
                checked={
                  !(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject.windows[index]
                      .loomComponentsPriceCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject.windows[index]
                      .loomComponentsPriceCalculation == 1
                  )
                }
                defaultChecked={
                  !(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject.windows[index]
                      .loomComponentsPriceCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject.windows[index]
                      .loomComponentsPriceCalculation == 1
                  )
                }
                onChange={() => {
                  if (
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                  ) {
                    if (
                      !props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.windows[index]
                        .loomComponentsPriceCalculation ||
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .fancadeTechnicalProject.windows[index]
                        .loomComponentsPriceCalculation == 0
                    )
                      props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.windows[
                        index
                      ].loomComponentsPriceCalculation = 1;
                    else
                      props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.windows[
                        index
                      ].loomComponentsPriceCalculation = 0;
                    setUpdate(!update);
                  }
                }}
              >
                {t(`formLabels.details.fancadeProduction.loomByTotal`)}
              </FormikCheckbox>
              {!(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject.windows[index]
                  .loomComponentsPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject.windows[index]
                  .loomComponentsPriceCalculation == 1
              ) ? (
                <Stack w={"100%"}>
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.windows[${index}].loomComponentsPrice`,
                        label: t(
                          `formLabels.details.fancadeProduction.loomComponentsPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.loomComponentsPrice`
                        ),
                        type: "number",
                        width: 100,
                      },
                    ]}
                  />
                </Stack>
              ) : (
                <Container
                  title={
                    t(
                      `formLabels.details.fancadeProduction.loomComponentsPrice`
                    ) + t(`titles.calculatedSection`)
                  }
                  bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                  direction={"column"}
                  spacing={"1rem"}
                >
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.windows[${index}].loomComponentsPrice`,
                        label: t(
                          `formLabels.details.fancadeProduction.loomComponentsPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.loomComponentsPrice`
                        ),
                        type: "number",
                        width: 100,
                        disabled: true,
                        value:
                          +calculateTotalLoomLength(
                            props.formikProps.values?.fancades[
                              props.fancadeNumber - 1
                            ]?.fancadeTechnicalProject?.windows[index]
                              ?.loomHeight,
                            props.formikProps.values?.fancades[
                              props.fancadeNumber - 1
                            ]?.fancadeTechnicalProject?.windows[index]
                              ?.loomWidth,
                            props.formikProps.values?.fancades[
                              props.fancadeNumber - 1
                            ]?.fancadeTechnicalProject?.windows[index]
                              ?.doubleGlazingNumber
                          ) *
                          +props.formikProps.values?.fancades[
                            props.fancadeNumber - 1
                          ]?.fancadeTechnicalProject?.windows[index]
                            ?.loomUnitPrice,
                      },
                    ]}
                  />
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.windows[${index}].totalLoomLength`,
                        label: t(
                          `formLabels.details.fancadeProduction.totalLoomLength`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.totalLoomLength`
                        ),
                        type: "number",
                        width: 100,
                        disabled: true,
                        value: calculateTotalLoomLength(
                          props.formikProps.values?.fancades[
                            props.fancadeNumber - 1
                          ]?.fancadeTechnicalProject?.windows[index]
                            ?.loomHeight,
                          props.formikProps.values?.fancades[
                            props.fancadeNumber - 1
                          ]?.fancadeTechnicalProject?.windows[index]?.loomWidth,
                          props.formikProps.values?.fancades[
                            props.fancadeNumber - 1
                          ]?.fancadeTechnicalProject?.windows[index]
                            ?.doubleGlazingNumber
                        ),
                      },
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.windows[${index}].loomUnitPrice`,
                        label: t(
                          `formLabels.details.fancadeProduction.loomUnitPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.loomUnitPrice`
                        ),
                        type: "number",
                        width: 100,
                      },
                    ]}
                  />
                </Container>
              )}

              {/* Double Glazing */}
              <FormikCheckbox
                paddingTop={"3rem"}
                checked={
                  !(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject.windows[index]
                      .doubleGlazingComponentsPriceCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject.windows[index]
                      .doubleGlazingComponentsPriceCalculation == 1
                  )
                }
                defaultChecked={
                  !(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject.windows[index]
                      .doubleGlazingComponentsPriceCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject.windows[index]
                      .doubleGlazingComponentsPriceCalculation == 1
                  )
                }
                onChange={() => {
                  if (
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                  ) {
                    if (
                      !props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.windows[index]
                        .doubleGlazingComponentsPriceCalculation ||
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .fancadeTechnicalProject.windows[index]
                        .doubleGlazingComponentsPriceCalculation == 0
                    )
                      props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.windows[
                        index
                      ].doubleGlazingComponentsPriceCalculation = 1;
                    else
                      props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.windows[
                        index
                      ].doubleGlazingComponentsPriceCalculation = 0;
                    setUpdate(!update);
                  }
                }}
              >
                {t(`formLabels.details.fancadeProduction.doubleGlazingByTotal`)}
              </FormikCheckbox>
              {!(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject.windows[index]
                  .doubleGlazingComponentsPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject.windows[index]
                  .doubleGlazingComponentsPriceCalculation == 1
              ) ? (
                <Stack w={"100%"}>
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.windows[${index}].doubleGlazingComponentsPrice`,
                        label: t(
                          `formLabels.details.fancadeProduction.doubleGlazingComponentsPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.doubleGlazingComponentsPrice`
                        ),
                        type: "number",
                        width: 100,
                      },
                    ]}
                  />
                </Stack>
              ) : (
                <Container
                  title={
                    t(
                      `formLabels.details.fancadeProduction.doubleGlazingComponentsPrice`
                    ) + t(`titles.calculatedSection`)
                  }
                  bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                  direction={"column"}
                  spacing={"1rem"}
                >
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.windows[${index}].doubleGlazingComponentsPrice`,
                        label: t(
                          `formLabels.details.fancadeProduction.doubleGlazingComponentsPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.doubleGlazingComponentsPrice`
                        ),
                        type: "number",
                        width: 100,
                        disabled: true,
                        value:
                          +props.formikProps.values?.fancades[
                            props.fancadeNumber - 1
                          ]?.fancadeTechnicalProject?.windows[index]
                            ?.doubleGlazingNumber *
                          +props.formikProps.values?.fancades[
                            props.fancadeNumber - 1
                          ]?.fancadeTechnicalProject?.windows[index]
                            ?.doubleGlazingUnitPrice,
                      },
                    ]}
                  />
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.windows[${index}].doubleGlazingNumber`,
                        label: t(
                          `formLabels.details.fancadeProduction.doubleGlazingNumber`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.doubleGlazingNumber`
                        ),
                        type: "number",
                        width: 100,
                        disabled: true,
                        value:
                          props.formikProps.values?.fancades[
                            props.fancadeNumber - 1
                          ]?.fancadeTechnicalProject?.windows[index]
                            ?.doubleGlazingNumber,
                      },
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.windows[${index}].doubleGlazingUnitPrice`,
                        label: t(
                          `formLabels.details.fancadeProduction.doubleGlazingUnitPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.doubleGlazingUnitPrice`
                        ),
                        type: "number",
                        width: 100,
                      },
                    ]}
                  />
                </Container>
              )}

              {/* Gasket */}
              <FormikCheckbox
                paddingTop={"3rem"}
                checked={
                  !(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject.windows[index]
                      .gasketsComponentsPriceCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject.windows[index]
                      .gasketsComponentsPriceCalculation == 1
                  )
                }
                defaultChecked={
                  !(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject.windows[index]
                      .gasketsComponentsPriceCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject.windows[index]
                      .gasketsComponentsPriceCalculation == 1
                  )
                }
                onChange={() => {
                  if (
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                  ) {
                    if (
                      !props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.windows[index]
                        .gasketsComponentsPriceCalculation ||
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .fancadeTechnicalProject.windows[index]
                        .gasketsComponentsPriceCalculation == 0
                    )
                      props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.windows[
                        index
                      ].gasketsComponentsPriceCalculation = 1;
                    else
                      props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.windows[
                        index
                      ].gasketsComponentsPriceCalculation = 0;
                    setUpdate(!update);
                  }
                }}
              >
                {t(`formLabels.details.fancadeProduction.gasketByTotal`)}
              </FormikCheckbox>
              {!(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject.windows[index]
                  .gasketsComponentsPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject.windows[index]
                  .gasketsComponentsPriceCalculation == 1
              ) ? (
                <Stack w={"100%"}>
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.windows[${index}].gasketsComponentsPrice`,
                        label: t(
                          `formLabels.details.fancadeProduction.gasketComponentsPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.gasketComponentsPrice`
                        ),
                        type: "number",
                        width: 100,
                      },
                    ]}
                  />
                </Stack>
              ) : (
                <Container
                  title={
                    t(
                      `formLabels.details.fancadeProduction.gasketComponentsPrice`
                    ) + t(`titles.calculatedSection`)
                  }
                  bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
                  direction={"column"}
                  spacing={"1rem"}
                >
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.windows[${index}].gasketsComponentsPrice`,
                        label: t(
                          `formLabels.details.fancadeProduction.gasketComponentsPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.gasketComponentsPrice`
                        ),
                        type: "number",
                        width: 100,
                        disabled: true,
                        value:
                          +props.formikProps.values?.fancades[
                            props.fancadeNumber - 1
                          ]?.fancadeTechnicalProject?.windows[index]
                            ?.totalGasketLength *
                          +props.formikProps.values?.fancades[
                            props.fancadeNumber - 1
                          ]?.fancadeTechnicalProject?.windows[index]
                            ?.gasketsUnitPrice,
                      },
                    ]}
                  />
                  <FormRow
                    fields={[
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.windows[${index}].totalGasketLength`,
                        label: t(
                          `formLabels.details.fancadeProduction.totalGasketLength`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.totalGasketLength`
                        ),
                        type: "number",
                        width: 100,
                        disabled: true,
                        value:
                          props.formikProps.values?.fancades[
                            props.fancadeNumber - 1
                          ]?.fancadeTechnicalProject?.windows[index]
                            ?.totalGasketLength,
                      },
                      {
                        name: `fancades[${
                          props.fancadeNumber - 1
                        }].fancadeTechnicalProject.windows[${index}].gasketsUnitPrice`,
                        label: t(
                          `formLabels.details.fancadeProduction.gasketsUnitPrice`
                        ),
                        placeholder: t(
                          `formPlaceholders.details.fancadeProduction.gasketsUnitPrice`
                        ),
                        type: "number",
                        width: 100,
                      },
                    ]}
                  />
                </Container>
              )}
            </Stack>
          );
        }
      }

      windowContainers.push(
        <Container
          title={
            t(`formLabels.details.fancadeProduction.windowContainer`) +
            " #" +
            (+index + 1)
          }
        >
          <Stack w={"100%"}>{fields}</Stack>
        </Container>
      );
    }
  );
  const currentProject: Project = props.formikProps.values;

  const addHardware = useCallback(
    (fancadeProduction: FancadeProductionType) => {
      if (!fancadeProduction.hardware) {
        fancadeProduction.hardware = [];
      }

      fancadeProduction.hardware?.push({
        quantity: null,
        totalPrice: null,
        unitPrice: null,
        totalPriceCalculation: 0,
      });

      if (currentProject && currentProject.fancades)
        currentProject.fancades[
          props.fancadeNumber - 1
        ].fancadeProduction.hardware = projects.setHardwareToFancade(
          currentProject.id,
          currentProject.fancades[props.fancadeNumber - 1].id,
          fancadeProduction.hardware
        );
      setUpdate(!update);
    },

    [projects, currentProject, props.formikProps, update]
  );
  const deleteHardware = useCallback(
    (fancadeProduction: FancadeProductionType, index: number) => {
      fancadeProduction.hardware?.splice(index, 1);
      if (
        currentProject &&
        currentProject.fancades &&
        fancadeProduction.hardware
      )
        currentProject.fancades[
          props.fancadeNumber - 1
        ].fancadeProduction.hardware = projects.setHardwareToFancade(
          currentProject.id,
          currentProject.fancades[props.fancadeNumber - 1].id,
          fancadeProduction.hardware
        );

      setUpdate(!update);
    },
    [update]
  );

  const addNewWindowType = useCallback(
    (fancadeId: number) => {
      if (
        currentProject &&
        currentProject.fancades &&
        currentProject.fancades[props.fancadeNumber - 1] &&
        currentProject.fancades[props.fancadeNumber - 1].fancadeTechnicalProject
      ) {
        if (
          !currentProject.fancades[props.fancadeNumber - 1]
            .fancadeTechnicalProject.windows
        )
          currentProject.fancades[
            props.fancadeNumber - 1
          ].fancadeTechnicalProject.windows = [];

        currentProject.fancades[
          props.fancadeNumber - 1
        ].fancadeTechnicalProject.windows = projects.addWindowTypeToFancade(
          currentProject.id,
          fancadeId,
          projects.getEmptyWindowType(
            currentProject.fancades[props.fancadeNumber - 1]
          )
        );
      }
      setUpdate(!update);
    },
    [projects, update]
  );

  let hardwareContainers: any[] = [];
  props.formikProps.values.fancades[
    props.fancadeNumber - 1
  ].fancadeProduction?.hardware?.map((hardware: Hardware, index: number) => {
    let hardwareFields: any[] = [];
    hardwareFields.push(
      <>
        <FormikCheckbox
          paddingTop={"2rem"}
          checked={
            !(
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeProduction?.hardware[index]?.totalPriceCalculation &&
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeProduction?.hardware[index]?.totalPriceCalculation == 1
            )
          }
          defaultChecked={
            !(
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeProduction?.hardware[index]?.totalPriceCalculation &&
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeProduction?.hardware[index]?.totalPriceCalculation == 1
            )
          }
          onChange={() => {
            if (
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                ?.fancadeProduction?.hardware
            ) {
              if (
                !props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeProduction?.hardware[index]?.totalPriceCalculation ||
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeProduction?.hardware[index]?.totalPriceCalculation ==
                  0
              )
                props.formikProps.values.fancades[
                  props.fancadeNumber - 1
                ].fancadeProduction.hardware[index].totalPriceCalculation = 1;
              else
                props.formikProps.values.fancades[
                  props.fancadeNumber - 1
                ].fancadeProduction.hardware[index].totalPriceCalculation = 0;
              setUpdate(!update);
            }
          }}
        >
          {t(`formLabels.details.fancadeProduction.hardwareCheckbox`)}
        </FormikCheckbox>
        {!(
          props.formikProps.values.fancades[props.fancadeNumber - 1]
            .fancadeProduction?.hardware[index]?.totalPriceCalculation &&
          props.formikProps.values.fancades[props.fancadeNumber - 1]
            .fancadeProduction?.hardware[index]?.totalPriceCalculation == 1
        ) ? (
          <>
            <FormRow
              fields={[
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].fancadeProduction.hardware[${index}].totalPrice`,
                  label: t(
                    `formLabels.details.fancadeProduction.singleHardwareTotalPrice`
                  ),
                  placeholder: t(
                    `formPlaceholders.details.fancadeProduction.singleHardwareTotalPrice`
                  ),
                  type: "number",
                  width: 100,
                },
              ]}
            />
          </>
        ) : (
          <Container
            title={
              t(
                `formLabels.details.fancadeProduction.singleHardwareTotalPrice`
              ) + t(`titles.calculatedSection`)
            }
            bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
            direction={"column"}
            spacing={"1rem"}
          >
            <FormRow
              fields={[
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].fancadeProduction.hardware.totalPrice`,
                  label: t(
                    `formLabels.details.fancadeProduction.singleHardwareTotalPrice`
                  ),
                  placeholder: t(
                    `formPlaceholders.details.fancadeProduction.singleHardwareTotalPrice`
                  ),
                  type: "number",
                  width: 100,
                  disabled: true,
                  value:
                    +props.formikProps.values.fancades[props.fancadeNumber - 1]
                      ?.fancadeProduction?.hardware[index].quantity *
                    +props.formikProps.values.fancades[props.fancadeNumber - 1]
                      ?.fancadeProduction?.hardware[index].unitPrice,
                },
              ]}
            />
            <FormRow
              fields={[
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].fancadeProduction.hardware[${index}].quantity`,
                  label: t(
                    `formLabels.details.fancadeProduction.hardwareQuantity`
                  ),
                  placeholder: t(
                    `formPlaceholders.details.fancadeProduction.hardwareQuantity`
                  ),
                  type: "number",
                  width: 100,
                },
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].fancadeProduction.hardware[${index}].unitPrice`,
                  label: t(
                    `formLabels.details.fancadeProduction.hardwareUnitPrice`
                  ),
                  placeholder: t(
                    `formPlaceholders.details.fancadeProduction.hardwareUnitPrice`
                  ),
                  type: "number",
                  width: 100,
                },
              ]}
            />
          </Container>
        )}
      </>
    );
    hardwareContainers.push(
      <Container
        title={
          <Flex alignItems={"center"}>
            <Text fontSize={"2xl"} color={theme.colors.darkGrey2}>
              {t("formLabels.details.fancadeProduction.hardware") +
                " #" +
                (index + 1)}
            </Text>
            <CloseIcon
              color={theme.colors.euracOrange}
              w={"0.7rem"}
              marginLeft={"0.5rem"}
              marginTop={"0.5rem"}
              onClick={() =>
                deleteHardware(
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeProduction,
                  index
                )
              }
            />
          </Flex>
        }
      >
        <Stack>{hardwareFields}</Stack>
      </Container>
    );
  });

  return (
    <Stack w={"100%"}>
      <Container title={t("windows")} direction={"column"}>
        <FormikCheckbox
          paddingTop={"2rem"}
          checked={
            !(
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeTechnicalProject.windowsTotalPriceCalculation &&
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeTechnicalProject.windowsTotalPriceCalculation == 1
            )
          }
          defaultChecked={
            !(
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeTechnicalProject.windowsTotalPriceCalculation &&
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeTechnicalProject.windowsTotalPriceCalculation == 1
            )
          }
          onChange={() => {
            if (
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeTechnicalProject
            ) {
              if (
                !props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject.windowsTotalPriceCalculation ||
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeTechnicalProject.windowsTotalPriceCalculation == 0
              )
                props.formikProps.values.fancades[
                  props.fancadeNumber - 1
                ].fancadeTechnicalProject.windowsTotalPriceCalculation = 1;
              else
                props.formikProps.values.fancades[
                  props.fancadeNumber - 1
                ].fancadeTechnicalProject.windowsTotalPriceCalculation = 0;
              setUpdate(!update);
            }
          }}
        >
          {t(`formLabels.details.fancadeProduction.windowByTotal`)}
        </FormikCheckbox>
        {!(
          props.formikProps.values.fancades[props.fancadeNumber - 1]
            .fancadeTechnicalProject.windowsTotalPriceCalculation &&
          props.formikProps.values.fancades[props.fancadeNumber - 1]
            .fancadeTechnicalProject.windowsTotalPriceCalculation == 1
        ) ? (
          <>
            <FormRow
              fields={[
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].fancadeTechnicalProject.windowsTotalPrice`,
                  label: t(
                    `formLabels.details.fancadeProduction.windowsTotalPrice`
                  ),
                  placeholder: t(
                    `formPlaceholders.details.fancadeProduction.windowsTotalPrice`
                  ),
                  type: "number",
                  width: 100,
                },
              ]}
            />
          </>
        ) : (
          <Container
            title={
              t(`formLabels.details.fancadeProduction.windowsTotalPrice`) +
              t(`titles.calculatedSection`)
            }
            bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
            direction={"column"}
            spacing={"1rem"}
          >
            <FormRow
              fields={[
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].fancadeTechnicalProject.windowsTotalPrice`,
                  label: t(
                    `formLabels.details.fancadeProduction.windowsTotalPrice`
                  ),
                  placeholder: t(
                    `formPlaceholders.details.fancadeProduction.windowsTotalPrice`
                  ),
                  type: "number",
                  width: 100,
                  disabled: true,
                  value: calculateOpaqueWindowsTotal(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                  ),
                },
              ]}
            />
            {props.formikProps.values.fancades[props.fancadeNumber - 1] &&
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeTechnicalProject &&
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeTechnicalProject.type &&
              props.formikProps.values.fancades[
                props.fancadeNumber - 1
              ].fancadeTechnicalProject.type
                .toLowerCase()
                .indexOf("finestre") == -1 && (
                <>
                  <Text fontSize={"2xl"}>
                    {t(`formLabels.details.fancadeProduction.windowsTitle`)}
                  </Text>
                  {windowContainers}
                  <Stack
                    width={"100%"}
                    paddingBottom={"2rem"}
                    paddingTop={"1.5rem"}
                    alignItems={"center"}
                  >
                    <StyledButton
                      onClick={() => {
                        if (currentProject && currentProject.fancades)
                          addNewWindowType(
                            currentProject.fancades[props.fancadeNumber - 1].id
                          );
                      }}
                    >
                      {t("buttons.addWindow")}
                    </StyledButton>
                  </Stack>
                </>
              )}
          </Container>
        )}
      </Container>
      <Container direction={"column"} title={t("tablesHeaders.otherMaterials")}>
        {props.formikProps.values.fancades[props.fancadeNumber - 1] &&
          props.formikProps.values.fancades[props.fancadeNumber - 1]
            .fancadeTechnicalProject &&
          props.formikProps.values.fancades[props.fancadeNumber - 1]
            .fancadeTechnicalProject.type &&
          props.formikProps.values.fancades[
            props.fancadeNumber - 1
          ].fancadeTechnicalProject.type.toLowerCase() != "finestre" && (
            <>
              {props.formikProps.values.fancades[props.fancadeNumber - 1] &&
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeTechnicalProject &&
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeTechnicalProject.type &&
              props.formikProps.values.fancades[
                props.fancadeNumber - 1
              ].fancadeTechnicalProject.type.toLowerCase() ==
                "opaca - retrofit modulare" ? (
                <>
                  {/* Prefabricated modules */}
                  <FormikCheckbox
                    paddingTop={"2rem"}
                    checked={
                      !(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .prefabricatedModulesTotalPriceCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .prefabricatedModulesTotalPriceCalculation == 1
                      )
                    }
                    defaultChecked={
                      !(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .prefabricatedModulesTotalPriceCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .prefabricatedModulesTotalPriceCalculation == 1
                      )
                    }
                    onChange={() => {
                      if (
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                      ) {
                        if (
                          !props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                            .prefabricatedModulesTotalPriceCalculation ||
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                            .prefabricatedModulesTotalPriceCalculation == 0
                        )
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject.prefabricatedModulesTotalPriceCalculation = 1;
                        else
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject.prefabricatedModulesTotalPriceCalculation = 0;
                        setUpdate(!update);
                      }
                    }}
                  >
                    {t(
                      `formLabels.details.fancadeProduction.prefabricatedModulesByTotal`
                    )}
                  </FormikCheckbox>
                  {!(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .prefabricatedModulesTotalPriceCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .prefabricatedModulesTotalPriceCalculation == 1
                  ) ? (
                    <>
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].fancadeTechnicalProject.prefabricatedModulesTotalPrice`,
                            label: t(
                              `formLabels.details.fancadeProduction.prefabricatedModulesTotalPrice`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.fancadeProduction.prefabricatedModulesTotalPrice`
                            ),
                            type: "number",
                            width: 100,
                          },
                        ]}
                      />
                    </>
                  ) : (
                    <Container
                      title={
                        t(
                          `formLabels.details.fancadeProduction.prefabricatedModulesTotalPrice`
                        ) + t(`titles.calculatedSection`)
                      }
                      bodyStyle={{
                        backgroundColor: theme.colors.lightOrange,
                      }}
                      direction={"column"}
                      spacing={"1rem"}
                    >
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].fancadeTechnicalProject.prefabricatedModulesTotalPrice`,
                            label: t(
                              `formLabels.details.fancadeProduction.prefabricatedModulesTotalPrice`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.fancadeProduction.prefabricatedModulesTotalPrice`
                            ),
                            type: "number",
                            width: 100,
                            disabled: true,
                            value:
                              +props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject.modulesSurface *
                              +props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject
                                .prefabricatedModulesUnitPrice,
                          },
                        ]}
                      />
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].fancadeTechnicalProject.modulesSurface`,
                            label: t(
                              `formLabels.details.fancadeProject.modulesSurface`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.fancadeProject.modulesSurface`
                            ),
                            type: "number",
                            width: 100,
                            disabled: true,
                          },
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].fancadeTechnicalProject.prefabricatedModulesUnitPrice`,
                            label: t(
                              `formLabels.details.fancadeProduction.prefabricatedModulesUnitPrice`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.fancadeProduction.prefabricatedModulesUnitPrice`
                            ),
                            type: "number",
                            width: 100,
                          },
                        ]}
                      />
                    </Container>
                  )}

                  {/* Anchorings */}
                  <FormikCheckbox
                    paddingTop={"2rem"}
                    checked={
                      !(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .opaqueAnchoringTotalPriceCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .opaqueAnchoringTotalPriceCalculation == 1
                      )
                    }
                    defaultChecked={
                      !(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .opaqueAnchoringTotalPriceCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .opaqueAnchoringTotalPriceCalculation == 1
                      )
                    }
                    onChange={() => {
                      if (
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                      ) {
                        if (
                          !props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                            .opaqueAnchoringTotalPriceCalculation ||
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                            .opaqueAnchoringTotalPriceCalculation == 0
                        )
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject.opaqueAnchoringTotalPriceCalculation = 1;
                        else
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject.opaqueAnchoringTotalPriceCalculation = 0;
                        setUpdate(!update);
                      }
                    }}
                  >
                    {t(
                      `formLabels.details.fancadeProduction.opaqueAnchoringByTotal`
                    )}
                  </FormikCheckbox>
                  {!(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .opaqueAnchoringTotalPriceCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .opaqueAnchoringTotalPriceCalculation === 1
                  ) ? (
                    <>
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].fancadeTechnicalProject.opaqueAnchoringTotalPrice`,
                            label: t(
                              `formLabels.details.fancadeProduction.opaqueAnchoringTotalPrice`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.fancadeProduction.opaqueAnchoringTotalPrice`
                            ),
                            type: "number",
                            width: 100,
                          },
                        ]}
                      />
                    </>
                  ) : (
                    <Container
                      title={
                        t(
                          `formLabels.details.fancadeProduction.opaqueAnchoringTotalPrice`
                        ) + t(`titles.calculatedSection`)
                      }
                      bodyStyle={{
                        backgroundColor: theme.colors.lightOrange,
                      }}
                      direction={"column"}
                      spacing={"1rem"}
                    >
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].fancadeTechnicalProject.opaqueAnchoringTotalPrice`,
                            label: t(
                              `formLabels.details.fancadeProduction.opaqueAnchoringTotalPrice`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.fancadeProduction.opaqueAnchoringTotalPrice`
                            ),
                            type: "number",
                            width: 100,
                            disabled: true,
                            value:
                              +props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject.opaqueAnchoringsNumber *
                              +props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject
                                .opaqueAnchoringUnitPrice,
                          },
                        ]}
                      />
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].fancadeTechnicalProject.opaqueAnchoringsNumber`,
                            label: t(
                              `formLabels.details.fancadeProduction.opaqueAnchoringsNumber`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.fancadeProduction.opaqueAnchoringsNumber`
                            ),
                            type: "number",
                            width: 100,
                            disabled: true,
                          },
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].fancadeTechnicalProject.opaqueAnchoringUnitPrice`,
                            label: t(
                              `formLabels.details.fancadeProduction.opaqueAnchoringUnitPrice`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.fancadeProduction.opaqueAnchoringUnitPrice`
                            ),
                            type: "number",
                            width: 100,
                          },
                        ]}
                      />
                    </Container>
                  )}
                  {/* Fixings */}
                  <FormikCheckbox
                    paddingTop={"2rem"}
                    checked={
                      !(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .opaqueFixingsTotalPriceCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .opaqueFixingsTotalPriceCalculation == 1
                      )
                    }
                    defaultChecked={
                      !(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .opaqueFixingsTotalPriceCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .opaqueFixingsTotalPriceCalculation == 1
                      )
                    }
                    onChange={() => {
                      if (
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                      ) {
                        if (
                          !props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                            .opaqueFixingsTotalPriceCalculation ||
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                            .opaqueFixingsTotalPriceCalculation == 0
                        )
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject.opaqueFixingsTotalPriceCalculation = 1;
                        else
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject.opaqueFixingsTotalPriceCalculation = 0;
                        setUpdate(!update);
                      }
                    }}
                  >
                    {t(
                      `formLabels.details.fancadeProduction.opaqueFixingsByTotal`
                    )}
                  </FormikCheckbox>
                  {!(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .opaqueFixingsTotalPriceCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .opaqueFixingsTotalPriceCalculation == 1
                  ) ? (
                    <>
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].fancadeTechnicalProject.opaqueFixingsTotalPrice`,
                            label: t(
                              `formLabels.details.fancadeProduction.opaqueFixingsTotalPrice`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.fancadeProduction.opaqueFixingsTotalPrice`
                            ),
                            type: "number",
                            width: 100,
                          },
                        ]}
                      />
                    </>
                  ) : (
                    <Container
                      title={
                        t(
                          `formLabels.details.fancadeProduction.opaqueFixingsTotalPrice`
                        ) + t(`titles.calculatedSection`)
                      }
                      bodyStyle={{
                        backgroundColor: theme.colors.lightOrange,
                      }}
                      direction={"column"}
                      spacing={"1rem"}
                    >
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].fancadeTechnicalProject.opaqueFixingsTotalPrice`,
                            label: t(
                              `formLabels.details.fancadeProduction.opaqueFixingsTotalPrice`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.fancadeProduction.opaqueFixingsTotalPrice`
                            ),
                            type: "number",
                            width: 100,
                            disabled: true,
                            value:
                              +props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject.opaqueFixingsNumber *
                              +props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject.opaqueFixingsUnitPrice,
                          },
                        ]}
                      />
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].fancadeTechnicalProject.opaqueFixingsNumber`,
                            label: t(
                              `formLabels.details.fancadeProduction.opaqueFixingsNumber`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.fancadeProduction.opaqueFixingsNumber`
                            ),
                            type: "number",
                            width: 100,
                            disabled: true,
                          },
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].fancadeTechnicalProject.opaqueFixingsUnitPrice`,
                            label: t(
                              `formLabels.details.fancadeProduction.opaqueFixingsUnitPrice`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.fancadeProduction.opaqueFixingsUnitPrice`
                            ),
                            type: "number",
                            width: 100,
                          },
                        ]}
                      />
                    </Container>
                  )}
                </>
              ) : (
                <>
                  {/* External cladding */}
                  <FormikCheckbox
                    paddingTop={"2rem"}
                    checked={
                      !(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .externalCladdingTotalPriceCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .externalCladdingTotalPriceCalculation == 1
                      )
                    }
                    defaultChecked={
                      !(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .externalCladdingTotalPriceCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .externalCladdingTotalPriceCalculation == 1
                      )
                    }
                    onChange={() => {
                      if (
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                      ) {
                        if (
                          !props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                            .externalCladdingTotalPriceCalculation ||
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                            .externalCladdingTotalPriceCalculation == 0
                        )
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject.externalCladdingTotalPriceCalculation = 1;
                        else
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject.externalCladdingTotalPriceCalculation = 0;
                        setUpdate(!update);
                      }
                    }}
                  >
                    {t(
                      `formLabels.details.fancadeProduction.externalCladdingByTotal`
                    )}
                  </FormikCheckbox>
                  {!(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .externalCladdingTotalPriceCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .externalCladdingTotalPriceCalculation == 1
                  ) ? (
                    <>
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].fancadeTechnicalProject.externalCladdingTotalPrice`,
                            label: t(
                              `formLabels.details.fancadeProduction.externalCladdingTotalPrice`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.fancadeProduction.externalCladdingTotalPrice`
                            ),
                            type: "number",
                            width: 100,
                          },
                        ]}
                      />
                    </>
                  ) : (
                    <Container
                      title={
                        t(
                          `formLabels.details.fancadeProduction.externalCladdingTotalPrice`
                        ) + t(`titles.calculatedSection`)
                      }
                      bodyStyle={{
                        backgroundColor: theme.colors.lightOrange,
                      }}
                      direction={"column"}
                      spacing={"1rem"}
                    >
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].fancadeTechnicalProject.externalCladdingTotalPrice`,
                            label: t(
                              `formLabels.details.fancadeProduction.externalCladdingTotalPrice`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.fancadeProduction.externalCladdingTotalPrice`
                            ),
                            type: "number",
                            width: 100,
                            disabled: true,
                            value:
                              +props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject
                                .externalCladdingUnitsNumber *
                              +props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject
                                .externalCladdingUnitsSurface *
                              +props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject
                                .externalCladdingUnitPrice,
                          },
                        ]}
                      />
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].fancadeTechnicalProject.totalExternalCladdingSurface`,
                            label: t(
                              `formLabels.details.fancadeProduction.totalExternalCladdingSurface`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.fancadeProduction.totalExternalCladdingSurface`
                            ),
                            type: "number",
                            width: 100,
                            disabled: true,
                            value:
                              +props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject
                                .externalCladdingUnitsNumber *
                              +props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject
                                .externalCladdingUnitsSurface,
                          },
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].fancadeTechnicalProject.externalCladdingUnitPrice`,
                            label: t(
                              `formLabels.details.fancadeProduction.externalCladdingUnitPrice`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.fancadeProduction.externalCladdingUnitPrice`
                            ),
                            type: "number",
                            width: 100,
                          },
                        ]}
                      />
                    </Container>
                  )}
                  {/* Insulation */}
                  <FormikCheckbox
                    paddingTop={"2rem"}
                    checked={
                      !(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .insulationTotalPriceCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .insulationTotalPriceCalculation == 1
                      )
                    }
                    defaultChecked={
                      !(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .insulationTotalPriceCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .insulationTotalPriceCalculation == 1
                      )
                    }
                    onChange={() => {
                      if (
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                      ) {
                        if (
                          !props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                            .insulationTotalPriceCalculation ||
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject
                            .insulationTotalPriceCalculation == 0
                        )
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject.insulationTotalPriceCalculation = 1;
                        else
                          props.formikProps.values.fancades[
                            props.fancadeNumber - 1
                          ].fancadeTechnicalProject.insulationTotalPriceCalculation = 0;
                        setUpdate(!update);
                      }
                    }}
                  >
                    {t(
                      `formLabels.details.fancadeProduction.insulationByTotal`
                    )}
                  </FormikCheckbox>
                  {!(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .insulationTotalPriceCalculation &&
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject
                      .insulationTotalPriceCalculation == 1
                  ) ? (
                    <>
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].fancadeTechnicalProject.insulationTotalPrice`,
                            label: t(
                              `formLabels.details.fancadeProduction.insulationTotalPrice`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.fancadeProduction.insulationTotalPrice`
                            ),
                            type: "number",
                            width: 100,
                          },
                        ]}
                      />
                    </>
                  ) : (
                    <Container
                      title={
                        t(
                          `formLabels.details.fancadeProduction.insulationTotalPrice`
                        ) + t(`titles.calculatedSection`)
                      }
                      bodyStyle={{
                        backgroundColor: theme.colors.lightOrange,
                      }}
                      direction={"column"}
                      spacing={"1rem"}
                    >
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].fancadeTechnicalProject.insulationTotalPrice`,
                            label: t(
                              `formLabels.details.fancadeProduction.insulationTotalPrice`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.fancadeProduction.insulationTotalPrice`
                            ),
                            type: "number",
                            width: 100,
                            disabled: true,
                            value:
                              +props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject.insulationSurface *
                              +props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject.insulationUnitPrice,
                          },
                        ]}
                      />
                      <FormRow
                        fields={[
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].fancadeTechnicalProject.insulationSurface`,
                            label: t(
                              `formLabels.details.fancadeProduction.insulationSurface`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.fancadeProduction.insulationSurface`
                            ),
                            type: "number",
                            width: 100,
                            disabled: true,
                          },
                          {
                            name: `fancades[${
                              props.fancadeNumber - 1
                            }].fancadeTechnicalProject.insulationUnitPrice`,
                            label: t(
                              `formLabels.details.fancadeProduction.insulationUnitPrice`
                            ),
                            placeholder: t(
                              `formPlaceholders.details.fancadeProduction.insulationUnitPrice`
                            ),
                            type: "number",
                            width: 100,
                          },
                        ]}
                      />
                    </Container>
                  )}

                  {props.formikProps.values.fancades[props.fancadeNumber - 1] &&
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject &&
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject.type &&
                  ( props.formikProps.values.fancades[
                      props.fancadeNumber - 1
                    ].fancadeTechnicalProject.type.toLowerCase() ==
                      "opaca - finestre") ? (
                    <>
                      {/* Supporting elements */}
                      <FormikCheckbox
                        paddingTop={"2rem"}
                        checked={
                          !(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .supportingElementsTotalPriceCalculation &&
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .supportingElementsTotalPriceCalculation == 1
                          )
                        }
                        defaultChecked={
                          !(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .supportingElementsTotalPriceCalculation &&
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .supportingElementsTotalPriceCalculation == 1
                          )
                        }
                        onChange={() => {
                          if (
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                          ) {
                            if (
                              !props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject
                                .supportingElementsTotalPriceCalculation ||
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject
                                .supportingElementsTotalPriceCalculation == 0
                            )
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject.supportingElementsTotalPriceCalculation = 1;
                            else
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject.supportingElementsTotalPriceCalculation = 0;
                            setUpdate(!update);
                          }
                        }}
                      >
                        {t(
                          `formLabels.details.fancadeProduction.supportingElementsByTotal`
                        )}
                      </FormikCheckbox>
                      {!(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .supportingElementsTotalPriceCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .supportingElementsTotalPriceCalculation == 1
                      ) ? (
                        <>
                          <FormRow
                            fields={[
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].fancadeTechnicalProject.supportingElementsTotalPrice`,
                                label: t(
                                  `formLabels.details.fancadeProduction.supportingElementsTotalPrice`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.fancadeProduction.supportingElementsTotalPrice`
                                ),
                                type: "number",
                                width: 100,
                              },
                            ]}
                          />
                        </>
                      ) : (
                        <Container
                          title={
                            t(
                              `formLabels.details.fancadeProduction.supportingElementsTotalPrice`
                            ) + t(`titles.calculatedSection`)
                          }
                          bodyStyle={{
                            backgroundColor: theme.colors.lightOrange,
                          }}
                          direction={"column"}
                          spacing={"1rem"}
                        >
                          <FormRow
                            fields={[
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].fancadeTechnicalProject.supportingElementsTotalPrice`,
                                label: t(
                                  `formLabels.details.fancadeProduction.supportingElementsTotalPrice`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.fancadeProduction.supportingElementsTotalPrice`
                                ),
                                type: "number",
                                width: 100,
                                disabled: true,
                                value:
                                  +props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].fancadeTechnicalProject
                                    .supportingElementsQuantity *
                                  +props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].fancadeTechnicalProject
                                    .supportingElementsUnitPrice,
                              },
                            ]}
                          />
                          <FormRow
                            fields={[
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].fancadeTechnicalProject.supportingElementsQuantity`,
                                label: t(
                                  `formLabels.details.fancadeProduction.totalSupportingElementsQuantity`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.fancadeProduction.totalSupportingElementsQuantity`
                                ),
                                type: "number",
                                width: 100,
                                disabled: true,
                              },
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].fancadeTechnicalProject.supportingElementsUnitPrice`,
                                label: t(
                                  `formLabels.details.fancadeProduction.supportingElementsUnitPrice`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.fancadeProduction.supportingElementsUnitPrice`
                                ),
                                type: "number",
                                width: 100,
                              },
                            ]}
                          />
                        </Container>
                      )}
                      {/* Anchorings */}
                      <FormikCheckbox
                        paddingTop={"2rem"}
                        checked={
                          !(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .opaqueAnchoringTotalPriceCalculation &&
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .opaqueAnchoringTotalPriceCalculation == 1
                          )
                        }
                        defaultChecked={
                          !(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .opaqueAnchoringTotalPriceCalculation &&
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .opaqueAnchoringTotalPriceCalculation == 1
                          )
                        }
                        onChange={() => {
                          if (
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                          ) {
                            if (
                              !props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject
                                .opaqueAnchoringTotalPriceCalculation ||
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject
                                .opaqueAnchoringTotalPriceCalculation == 0
                            )
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject.opaqueAnchoringTotalPriceCalculation = 1;
                            else
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject.opaqueAnchoringTotalPriceCalculation = 0;
                            setUpdate(!update);
                          }
                        }}
                      >
                        {t(
                          `formLabels.details.fancadeProduction.opaqueAnchoringByTotal`
                        )}
                      </FormikCheckbox>
                      {!(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .opaqueAnchoringTotalPriceCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .opaqueAnchoringTotalPriceCalculation === 1
                      ) ? (
                        <>
                          <FormRow
                            fields={[
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].fancadeTechnicalProject.opaqueAnchoringTotalPrice`,
                                label: t(
                                  `formLabels.details.fancadeProduction.opaqueAnchoringTotalPrice`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.fancadeProduction.opaqueAnchoringTotalPrice`
                                ),
                                type: "number",
                                width: 100,
                              },
                            ]}
                          />
                        </>
                      ) : (
                        <Container
                          title={
                            t(
                              `formLabels.details.fancadeProduction.opaqueAnchoringTotalPrice`
                            ) + t(`titles.calculatedSection`)
                          }
                          bodyStyle={{
                            backgroundColor: theme.colors.lightOrange,
                          }}
                          direction={"column"}
                          spacing={"1rem"}
                        >
                          <FormRow
                            fields={[
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].fancadeTechnicalProject.opaqueAnchoringTotalPrice`,
                                label: t(
                                  `formLabels.details.fancadeProduction.opaqueAnchoringTotalPrice`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.fancadeProduction.opaqueAnchoringTotalPrice`
                                ),
                                type: "number",
                                width: 100,
                                disabled: true,
                                value:
                                  +props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].fancadeTechnicalProject
                                    .opaqueAnchoringsNumber *
                                  +props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].fancadeTechnicalProject
                                    .opaqueAnchoringUnitPrice,
                              },
                            ]}
                          />
                          <FormRow
                            fields={[
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].fancadeTechnicalProject.opaqueAnchoringsNumber`,
                                label: t(
                                  `formLabels.details.fancadeProduction.opaqueAnchoringsNumber`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.fancadeProduction.opaqueAnchoringsNumber`
                                ),
                                type: "number",
                                width: 100,
                                disabled: true,
                              },
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].fancadeTechnicalProject.opaqueAnchoringUnitPrice`,
                                label: t(
                                  `formLabels.details.fancadeProduction.opaqueAnchoringUnitPrice`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.fancadeProduction.opaqueAnchoringUnitPrice`
                                ),
                                type: "number",
                                width: 100,
                              },
                            ]}
                          />
                        </Container>
                      )}

                      {/* Fixings */}
                      <FormikCheckbox
                        paddingTop={"2rem"}
                        checked={
                          !(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .opaqueFixingsTotalPriceCalculation &&
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .opaqueFixingsTotalPriceCalculation == 1
                          )
                        }
                        defaultChecked={
                          !(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .opaqueFixingsTotalPriceCalculation &&
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .opaqueFixingsTotalPriceCalculation == 1
                          )
                        }
                        onChange={() => {
                          if (
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                          ) {
                            if (
                              !props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject
                                .opaqueFixingsTotalPriceCalculation ||
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject
                                .opaqueFixingsTotalPriceCalculation == 0
                            )
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject.opaqueFixingsTotalPriceCalculation = 1;
                            else
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject.opaqueFixingsTotalPriceCalculation = 0;
                            setUpdate(!update);
                          }
                        }}
                      >
                        {t(
                          `formLabels.details.fancadeProduction.opaqueFixingsByTotal`
                        )}
                      </FormikCheckbox>
                      {!(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .opaqueFixingsTotalPriceCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .opaqueFixingsTotalPriceCalculation == 1
                      ) ? (
                        <>
                          <FormRow
                            fields={[
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].fancadeTechnicalProject.opaqueFixingsTotalPrice`,
                                label: t(
                                  `formLabels.details.fancadeProduction.opaqueFixingsTotalPrice`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.fancadeProduction.opaqueFixingsTotalPrice`
                                ),
                                type: "number",
                                width: 100,
                              },
                            ]}
                          />
                        </>
                      ) : (
                        <Container
                          title={
                            t(
                              `formLabels.details.fancadeProduction.opaqueFixingsTotalPrice`
                            ) + t(`titles.calculatedSection`)
                          }
                          bodyStyle={{
                            backgroundColor: theme.colors.lightOrange,
                          }}
                          direction={"column"}
                          spacing={"1rem"}
                        >
                          <FormRow
                            fields={[
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].fancadeTechnicalProject.opaqueFixingsTotalPrice`,
                                label: t(
                                  `formLabels.details.fancadeProduction.opaqueFixingsTotalPrice`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.fancadeProduction.opaqueFixingsTotalPrice`
                                ),
                                type: "number",
                                width: 100,
                                disabled: true,
                                value:
                                  +props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].fancadeTechnicalProject
                                    .opaqueFixingsNumber *
                                  +props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].fancadeTechnicalProject
                                    .opaqueFixingsUnitPrice,
                              },
                            ]}
                          />
                          <FormRow
                            fields={[
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].fancadeTechnicalProject.opaqueFixingsNumber`,
                                label: t(
                                  `formLabels.details.fancadeProduction.opaqueFixingsNumber`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.fancadeProduction.opaqueFixingsNumber`
                                ),
                                type: "number",
                                width: 100,
                                disabled: true,
                              },
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].fancadeTechnicalProject.opaqueFixingsUnitPrice`,
                                label: t(
                                  `formLabels.details.fancadeProduction.opaqueFixingsUnitPrice`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.fancadeProduction.opaqueFixingsUnitPrice`
                                ),
                                type: "number",
                                width: 100,
                              },
                            ]}
                          />
                        </Container>
                      )}
                    </>
                  ) : (
                    <>
                      {/* Supporting elements */}
                      <FormikCheckbox
                        paddingTop={"2rem"}
                        checked={
                          !(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .supportingElementsTotalPriceCalculation &&
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .supportingElementsTotalPriceCalculation == 1
                          )
                        }
                        defaultChecked={
                          !(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .supportingElementsTotalPriceCalculation &&
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .supportingElementsTotalPriceCalculation == 1
                          )
                        }
                        onChange={() => {
                          if (
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                          ) {
                            if (
                              !props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject
                                .supportingElementsTotalPriceCalculation ||
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject
                                .supportingElementsTotalPriceCalculation == 0
                            )
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject.supportingElementsTotalPriceCalculation = 1;
                            else
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject.supportingElementsTotalPriceCalculation = 0;
                            setUpdate(!update);
                          }
                        }}
                      >
                        {t(
                          `formLabels.details.fancadeProduction.supportingElementsByTotal`
                        )}
                      </FormikCheckbox>
                      {!(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .supportingElementsTotalPriceCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .supportingElementsTotalPriceCalculation == 1
                      ) ? (
                        <>
                          <FormRow
                            fields={[
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].fancadeTechnicalProject.supportingElementsTotalPrice`,
                                label: t(
                                  `formLabels.details.fancadeProduction.supportingElementsTotalPrice`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.fancadeProduction.supportingElementsTotalPrice`
                                ),
                                type: "number",
                                width: 100,
                              },
                            ]}
                          />
                        </>
                      ) : (
                        <Container
                          title={
                            t(
                              `formLabels.details.fancadeProduction.supportingElementsTotalPrice`
                            ) + t(`titles.calculatedSection`)
                          }
                          bodyStyle={{
                            backgroundColor: theme.colors.lightOrange,
                          }}
                          direction={"column"}
                          spacing={"1rem"}
                        >
                          <FormRow
                            fields={[
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].fancadeTechnicalProject.supportingElementsTotalPrice`,
                                label: t(
                                  `formLabels.details.fancadeProduction.supportingElementsTotalPrice`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.fancadeProduction.supportingElementsTotalPrice`
                                ),
                                type: "number",
                                width: 100,
                                disabled: true,
                                value:
                                  +props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].fancadeTechnicalProject
                                    .supportingElementsQuantity *
                                  +props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].fancadeTechnicalProject
                                    .supportingElementsUnitPrice,
                              },
                            ]}
                          />
                          <FormRow
                            fields={[
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].fancadeTechnicalProject.supportingElementsQuantity`,
                                label: t(
                                  `formLabels.details.fancadeProduction.totalSupportingElementsQuantity`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.fancadeProduction.totalSupportingElementsQuantity`
                                ),
                                type: "number",
                                width: 100,
                                disabled: true,
                              },
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].fancadeTechnicalProject.supportingElementsUnitPrice`,
                                label: t(
                                  `formLabels.details.fancadeProduction.supportingElementsUnitPrice`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.fancadeProduction.supportingElementsUnitPrice`
                                ),
                                type: "number",
                                width: 100,
                              },
                            ]}
                          />
                        </Container>
                      )}

                      {/* Prefabricated modules */}
                      <FormikCheckbox
                        paddingTop={"2rem"}
                        checked={
                          !(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .prefabricatedModulesTotalPriceCalculation &&
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .prefabricatedModulesTotalPriceCalculation == 1
                          )
                        }
                        defaultChecked={
                          !(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .prefabricatedModulesTotalPriceCalculation &&
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .prefabricatedModulesTotalPriceCalculation == 1
                          )
                        }
                        onChange={() => {
                          if (
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                          ) {
                            if (
                              !props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject
                                .prefabricatedModulesTotalPriceCalculation ||
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject
                                .prefabricatedModulesTotalPriceCalculation == 0
                            )
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject.prefabricatedModulesTotalPriceCalculation = 1;
                            else
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject.prefabricatedModulesTotalPriceCalculation = 0;
                            setUpdate(!update);
                          }
                        }}
                      >
                        {t(
                          `formLabels.details.fancadeProduction.prefabricatedModulesByTotal`
                        )}
                      </FormikCheckbox>
                      {!(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .prefabricatedModulesTotalPriceCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .prefabricatedModulesTotalPriceCalculation == 1
                      ) ? (
                        <>
                          <FormRow
                            fields={[
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].fancadeTechnicalProject.prefabricatedModulesTotalPrice`,
                                label: t(
                                  `formLabels.details.fancadeProduction.prefabricatedModulesTotalPrice`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.fancadeProduction.prefabricatedModulesTotalPrice`
                                ),
                                type: "number",
                                width: 100,
                              },
                            ]}
                          />
                        </>
                      ) : (
                        <Container
                          title={
                            t(
                              `formLabels.details.fancadeProduction.prefabricatedModulesTotalPrice`
                            ) + t(`titles.calculatedSection`)
                          }
                          bodyStyle={{
                            backgroundColor: theme.colors.lightOrange,
                          }}
                          direction={"column"}
                          spacing={"1rem"}
                        >
                          <FormRow
                            fields={[
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].fancadeTechnicalProject.prefabricatedModulesTotalPrice`,
                                label: t(
                                  `formLabels.details.fancadeProduction.prefabricatedModulesTotalPrice`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.fancadeProduction.prefabricatedModulesTotalPrice`
                                ),
                                type: "number",
                                width: 100,
                                disabled: true,
                                value:
                                  +props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].fancadeTechnicalProject.modulesSurface *
                                  +props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].fancadeTechnicalProject
                                    .prefabricatedModulesUnitPrice,
                              },
                            ]}
                          />
                          <FormRow
                            fields={[
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].fancadeTechnicalProject.modulesSurface`,
                                label: t(
                                  `formLabels.details.fancadeProject.modulesSurface`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.fancadeProject.modulesSurface`
                                ),
                                type: "number",
                                width: 100,
                                disabled: true,
                              },
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].fancadeTechnicalProject.prefabricatedModulesUnitPrice`,
                                label: t(
                                  `formLabels.details.fancadeProduction.prefabricatedModulesUnitPrice`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.fancadeProduction.prefabricatedModulesUnitPrice`
                                ),
                                type: "number",
                                width: 100,
                              },
                            ]}
                          />
                        </Container>
                      )}
                      {/* Anchorings */}
                      <FormikCheckbox
                        paddingTop={"2rem"}
                        checked={
                          !(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .opaqueAnchoringTotalPriceCalculation &&
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .opaqueAnchoringTotalPriceCalculation == 1
                          )
                        }
                        defaultChecked={
                          !(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .opaqueAnchoringTotalPriceCalculation &&
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .opaqueAnchoringTotalPriceCalculation == 1
                          )
                        }
                        onChange={() => {
                          if (
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                          ) {
                            if (
                              !props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject
                                .opaqueAnchoringTotalPriceCalculation ||
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject
                                .opaqueAnchoringTotalPriceCalculation == 0
                            )
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject.opaqueAnchoringTotalPriceCalculation = 1;
                            else
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject.opaqueAnchoringTotalPriceCalculation = 0;
                            setUpdate(!update);
                          }
                        }}
                      >
                        {t(
                          `formLabels.details.fancadeProduction.opaqueAnchoringByTotal`
                        )}
                      </FormikCheckbox>
                      {!(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .opaqueAnchoringTotalPriceCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .opaqueAnchoringTotalPriceCalculation === 1
                      ) ? (
                        <>
                          <FormRow
                            fields={[
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].fancadeTechnicalProject.opaqueAnchoringTotalPrice`,
                                label: t(
                                  `formLabels.details.fancadeProduction.opaqueAnchoringTotalPrice`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.fancadeProduction.opaqueAnchoringTotalPrice`
                                ),
                                type: "number",
                                width: 100,
                              },
                            ]}
                          />
                        </>
                      ) : (
                        <Container
                          title={
                            t(
                              `formLabels.details.fancadeProduction.opaqueAnchoringTotalPrice`
                            ) + t(`titles.calculatedSection`)
                          }
                          bodyStyle={{
                            backgroundColor: theme.colors.lightOrange,
                          }}
                          direction={"column"}
                          spacing={"1rem"}
                        >
                          <FormRow
                            fields={[
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].fancadeTechnicalProject.opaqueAnchoringTotalPrice`,
                                label: t(
                                  `formLabels.details.fancadeProduction.opaqueAnchoringTotalPrice`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.fancadeProduction.opaqueAnchoringTotalPrice`
                                ),
                                type: "number",
                                width: 100,
                                disabled: true,
                                value:
                                  +props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].fancadeTechnicalProject
                                    .opaqueAnchoringsNumber *
                                  +props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].fancadeTechnicalProject
                                    .opaqueAnchoringUnitPrice,
                              },
                            ]}
                          />
                          <FormRow
                            fields={[
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].fancadeTechnicalProject.opaqueAnchoringsNumber`,
                                label: t(
                                  `formLabels.details.fancadeProduction.opaqueAnchoringsNumber`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.fancadeProduction.opaqueAnchoringsNumber`
                                ),
                                type: "number",
                                width: 100,
                                disabled: true,
                              },
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].fancadeTechnicalProject.opaqueAnchoringUnitPrice`,
                                label: t(
                                  `formLabels.details.fancadeProduction.opaqueAnchoringUnitPrice`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.fancadeProduction.opaqueAnchoringUnitPrice`
                                ),
                                type: "number",
                                width: 100,
                              },
                            ]}
                          />
                        </Container>
                      )}

                      {/* Fixings */}
                      <FormikCheckbox
                        paddingTop={"2rem"}
                        checked={
                          !(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .opaqueFixingsTotalPriceCalculation &&
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .opaqueFixingsTotalPriceCalculation == 1
                          )
                        }
                        defaultChecked={
                          !(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .opaqueFixingsTotalPriceCalculation &&
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                              .opaqueFixingsTotalPriceCalculation == 1
                          )
                        }
                        onChange={() => {
                          if (
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ].fancadeTechnicalProject
                          ) {
                            if (
                              !props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject
                                .opaqueFixingsTotalPriceCalculation ||
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject
                                .opaqueFixingsTotalPriceCalculation == 0
                            )
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject.opaqueFixingsTotalPriceCalculation = 1;
                            else
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject.opaqueFixingsTotalPriceCalculation = 0;
                            setUpdate(!update);
                          }
                        }}
                      >
                        {t(
                          `formLabels.details.fancadeProduction.opaqueFixingsByTotal`
                        )}
                      </FormikCheckbox>
                      {!(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .opaqueFixingsTotalPriceCalculation &&
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject
                          .opaqueFixingsTotalPriceCalculation == 1
                      ) ? (
                        <>
                          <FormRow
                            fields={[
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].fancadeTechnicalProject.opaqueFixingsTotalPrice`,
                                label: t(
                                  `formLabels.details.fancadeProduction.opaqueFixingsTotalPrice`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.fancadeProduction.opaqueFixingsTotalPrice`
                                ),
                                type: "number",
                                width: 100,
                              },
                            ]}
                          />
                        </>
                      ) : (
                        <Container
                          title={
                            t(
                              `formLabels.details.fancadeProduction.opaqueFixingsTotalPrice`
                            ) + t(`titles.calculatedSection`)
                          }
                          bodyStyle={{
                            backgroundColor: theme.colors.lightOrange,
                          }}
                          direction={"column"}
                          spacing={"1rem"}
                        >
                          <FormRow
                            fields={[
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].fancadeTechnicalProject.opaqueFixingsTotalPrice`,
                                label: t(
                                  `formLabels.details.fancadeProduction.opaqueFixingsTotalPrice`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.fancadeProduction.opaqueFixingsTotalPrice`
                                ),
                                type: "number",
                                width: 100,
                                disabled: true,
                                value:
                                  +props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].fancadeTechnicalProject
                                    .opaqueFixingsNumber *
                                  +props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].fancadeTechnicalProject
                                    .opaqueFixingsUnitPrice,
                              },
                            ]}
                          />
                          <FormRow
                            fields={[
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].fancadeTechnicalProject.opaqueFixingsNumber`,
                                label: t(
                                  `formLabels.details.fancadeProduction.opaqueFixingsNumber`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.fancadeProduction.opaqueFixingsNumber`
                                ),
                                type: "number",
                                width: 100,
                                disabled: true,
                              },
                              {
                                name: `fancades[${
                                  props.fancadeNumber - 1
                                }].fancadeTechnicalProject.opaqueFixingsUnitPrice`,
                                label: t(
                                  `formLabels.details.fancadeProduction.opaqueFixingsUnitPrice`
                                ),
                                placeholder: t(
                                  `formPlaceholders.details.fancadeProduction.opaqueFixingsUnitPrice`
                                ),
                                type: "number",
                                width: 100,
                              },
                            ]}
                          />
                        </Container>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        {/* Hardware */}
        <Text fontSize={"2xl"} paddingTop={"2rem"}>
          {t(`formLabels.details.fancadeProduction.hardware`)}
        </Text>
        <FormikCheckbox
          checked={
            !(
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeProduction?.hardwareTotalPriceCalculation &&
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeProduction?.hardwareTotalPriceCalculation == 1
            )
          }
          defaultChecked={
            !(
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeProduction?.hardwareTotalPriceCalculation &&
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeProduction?.hardwareTotalPriceCalculation == 1
            )
          }
          onChange={() => {
            if (
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                ?.fancadeProduction
            ) {
              if (
                !props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeProduction?.hardwareTotalPriceCalculation ||
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeProduction?.hardwareTotalPriceCalculation == 0
              )
                props.formikProps.values.fancades[
                  props.fancadeNumber - 1
                ].fancadeProduction.hardwareTotalPriceCalculation = 1;
              else
                props.formikProps.values.fancades[
                  props.fancadeNumber - 1
                ].fancadeProduction.hardwareTotalPriceCalculation = 0;
              setUpdate(!update);
            }
          }}
        >
          {t(`formLabels.details.fancadeProduction.hardwareCheckbox`)}
        </FormikCheckbox>
        {!(
          props.formikProps.values.fancades[props.fancadeNumber - 1]
            .fancadeProduction?.hardwareTotalPriceCalculation &&
          props.formikProps.values.fancades[props.fancadeNumber - 1]
            .fancadeProduction?.hardwareTotalPriceCalculation == 1
        ) ? (
          <>
            <FormRow
              fields={[
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].fancadeProduction.hardwareTotalPrice`,
                  label: t(
                    `formLabels.details.fancadeProduction.hardwareTotalPrice`
                  ),
                  placeholder: t(
                    `formPlaceholders.details.fancadeProduction.hardwareTotalPrice`
                  ),
                  type: "number",
                  width: 100,
                },
              ]}
            />
          </>
        ) : (
          <Container
            title={
              t(
                `formLabels.details.fancadeProduction.singleHardwareTotalPrice`
              ) + t(`titles.calculatedSection`)
            }
            bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
            direction={"column"}
            spacing={"1rem"}
          >
            <FormRow
              fields={[
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].fancadeProduction.hardware.totalPrice`,
                  label: t(
                    `formLabels.details.fancadeProduction.singleHardwareTotalPrice`
                  ),
                  placeholder: t(
                    `formPlaceholders.details.fancadeProduction.singleHardwareTotalPrice`
                  ),
                  type: "number",
                  width: 100,
                  disabled: true,
                  value: calculateHardwareTotalPrice(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                  ),
                },
              ]}
            />

            <>
              <Stack>{hardwareContainers}</Stack>
              <Stack
                width={"100%"}
                paddingBottom={"2rem"}
                paddingTop={"1.5rem"}
                alignItems={"center"}
              >
                <StyledButton
                  onClick={() =>
                    addHardware(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        .fancadeProduction
                    )
                  }
                >
                  {t("buttons.addHardware")}
                </StyledButton>
              </Stack>
            </>
          </Container>
        )}
      </Container>
    </Stack>
  );
};

const StyledButton = styled(Button)`
  color: white;
  background-color: ${theme.colors.euracOrange};
  border-radius: 500rem;

  &:hover {
    background-color: ${transparentize(0.5, theme.colors.euracOrange)};
  }
`;
