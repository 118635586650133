import { CloseIcon } from "@chakra-ui/icons";
import { Button, Flex, Select, Stack, Text } from "@chakra-ui/react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useStores } from "../../../../../../hooks/useStores";
import {
  calculateHardwareTotalPrice,
  calculateLaborTotal,
  calculateOpaqueWindowsTotal,
  calculateOpaqueWindowTotal,
  calculateTotalLoomLength,
  calculateTotalMainFrameLength,
  calculateTransparentWindowsTotal,
} from "../../../../../../services/utils";
import theme from "../../../../../../theme/theme";
import { transparentize } from "polished";
import {
  Fancade,
  FancadeProductionType,
  Hardware,
  OpaqueWindow,
} from "../../../../../../types";
import { Container } from "../../../../../Container";
import { FormRow } from "../../../../../FormRow";
import { FormikCheckbox } from "../../../../../FormikCheckbox";

type Props = {
  fancadeNumber: number;
  formikProps: any;
};
export const LaborForm: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [update, setUpdate] = useState(false);

  return (
    <Stack w={"100%"}>
      {/* Total Labor */}
      <FormikCheckbox
        paddingTop={"2rem"}
        checked={
          !(
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeProduction.totalLaborPriceCalculation &&
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeProduction.totalLaborPriceCalculation == 1
          )
        }
        defaultChecked={
          !(
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeProduction.totalLaborPriceCalculation &&
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeProduction.totalLaborPriceCalculation == 1
          )
        }
        onChange={() => {
          if (
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeProduction
          ) {
            if (
              !props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeProduction.totalLaborPriceCalculation ||
              props.formikProps.values.fancades[props.fancadeNumber - 1]
                .fancadeProduction.totalLaborPriceCalculation == 0
            )
              props.formikProps.values.fancades[
                props.fancadeNumber - 1
              ].fancadeProduction.totalLaborPriceCalculation = 1;
            else
              props.formikProps.values.fancades[
                props.fancadeNumber - 1
              ].fancadeProduction.totalLaborPriceCalculation = 0;
            setUpdate(!update);
          }
        }}
      >
        {t(`formLabels.details.fancadeProduction.totalLaborPriceByTotal`)}
      </FormikCheckbox>
      {!(
        props.formikProps.values.fancades[props.fancadeNumber - 1]
          .fancadeProduction.totalLaborPriceCalculation &&
        props.formikProps.values.fancades[props.fancadeNumber - 1]
          .fancadeProduction.totalLaborPriceCalculation == 1
      ) ? (
        <>
          <FormRow
            fields={[
              {
                name: `fancades[${
                  props.fancadeNumber - 1
                }].fancadeProduction.totalLaborPrice`,
                label: t(
                  `formLabels.details.fancadeProduction.totalLaborPrice`
                ),
                placeholder: t(
                  `formPlaceholders.details.fancadeProduction.totalLaborPrice`
                ),
                type: "number",
                width: 100,
              },
            ]}
          />
        </>
      ) : (
        <Container
          title={
            t(`formLabels.details.fancadeProduction.totalLaborPrice`) +
            t(`titles.calculatedSection`)
          }
          bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
          direction={"column"}
          spacing={"1rem"}
        >
          <FormRow
            fields={[
              {
                name: `fancades[${
                  props.fancadeNumber - 1
                }].fancadeProduction.totalLaborPrice`,
                label: t(
                  `formLabels.details.fancadeProduction.totalLaborPrice`
                ),
                placeholder: t(
                  `formPlaceholders.details.fancadeProduction.totalLaborPrice`
                ),
                type: "number",
                width: 100,
                disabled: true,
                value: +calculateLaborTotal(
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                ),
              },
            ]}
          />

          {/* Base Labor */}
          <FormikCheckbox
            paddingTop={"2rem"}
            checked={
              !(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeProduction.totalBaseLaborPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeProduction.totalBaseLaborPriceCalculation == 1
              )
            }
            defaultChecked={
              !(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeProduction.totalBaseLaborPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeProduction.totalBaseLaborPriceCalculation == 1
              )
            }
            onChange={() => {
              if (
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeProduction
              ) {
                if (
                  !props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeProduction.totalBaseLaborPriceCalculation ||
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeProduction.totalBaseLaborPriceCalculation == 0
                )
                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].fancadeProduction.totalBaseLaborPriceCalculation = 1;
                else
                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].fancadeProduction.totalBaseLaborPriceCalculation = 0;
                setUpdate(!update);
              }
            }}
          >
            {t(`formLabels.details.fancadeProduction.baseLaborPriceByTotal`)}
          </FormikCheckbox>
          {!(
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeProduction.totalBaseLaborPriceCalculation &&
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeProduction.totalBaseLaborPriceCalculation == 1
          ) ? (
            <>
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeProduction.totalBaseLaborPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.totalBaseLaborPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.totalBaseLaborPrice`
                    ),
                    type: "number",
                    width: 100,
                  },
                ]}
              />
            </>
          ) : (
            <Container
              title={
                t(`formLabels.details.fancadeProduction.totalBaseLaborPrice`) +
                t(`titles.calculatedSection`)
              }
              bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
              direction={"column"}
              spacing={"1rem"}
            >
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeProduction.totalBaseLaborPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.totalBaseLaborPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.totalBaseLaborPrice`
                    ),
                    type: "number",
                    width: 100,
                    disabled: true,
                    value:
                      +props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeProduction.baseLaborHours *
                      +props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeProduction.baseLaborHourPrice,
                  },
                ]}
              />
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeProduction.baseLaborHours`,
                    label: t(
                      `formLabels.details.fancadeProduction.baseLaborHours`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.baseLaborHours`
                    ),
                    type: "number",
                    width: 100,
                  },
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeProduction.baseLaborHourPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.baseLaborHourPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.baseLaborHourPrice`
                    ),
                    type: "number",
                    width: 100,
                  },
                ]}
              />
            </Container>
          )}

          {/* Skilled Labor */}
          <FormikCheckbox
            paddingTop={"2rem"}
            checked={
              !(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeProduction.totalSkilledLaborPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeProduction.totalSkilledLaborPriceCalculation == 1
              )
            }
            defaultChecked={
              !(
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeProduction.totalSkilledLaborPriceCalculation &&
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeProduction.totalSkilledLaborPriceCalculation == 1
              )
            }
            onChange={() => {
              if (
                props.formikProps.values.fancades[props.fancadeNumber - 1]
                  .fancadeProduction
              ) {
                if (
                  !props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeProduction.totalSkilledLaborPriceCalculation ||
                  props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeProduction.totalSkilledLaborPriceCalculation == 0
                )
                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].fancadeProduction.totalSkilledLaborPriceCalculation = 1;
                else
                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].fancadeProduction.totalSkilledLaborPriceCalculation = 0;
                setUpdate(!update);
              }
            }}
          >
            {t(`formLabels.details.fancadeProduction.skilledLaborPriceByTotal`)}
          </FormikCheckbox>
          {!(
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeProduction.totalSkilledLaborPriceCalculation &&
            props.formikProps.values.fancades[props.fancadeNumber - 1]
              .fancadeProduction.totalSkilledLaborPriceCalculation == 1
          ) ? (
            <>
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeProduction.totalSkilledLaborPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.totalSkilledLaborPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.totalSkilledLaborPrice`
                    ),
                    type: "number",
                    width: 100,
                  },
                ]}
              />
            </>
          ) : (
            <Container
              title={
                t(
                  `formLabels.details.fancadeProduction.totalSkilledLaborPrice`
                ) + t(`titles.calculatedSection`)
              }
              bodyStyle={{ backgroundColor: theme.colors.lightOrange }}
              direction={"column"}
              spacing={"1rem"}
            >
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeProduction.totalSkilledLaborPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.totalSkilledLaborPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.totalSkilledLaborPrice`
                    ),
                    type: "number",
                    width: 100,
                    disabled: true,
                    value:
                      +props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeProduction.skilledLaborHours *
                      +props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeProduction.skilledLaborHourPrice,
                  },
                ]}
              />
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeProduction.skilledLaborHours`,
                    label: t(
                      `formLabels.details.fancadeProduction.skilledLaborHours`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.skilledLaborHours`
                    ),
                    type: "number",
                    width: 100,
                  },
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeProduction.skilledLaborHourPrice`,
                    label: t(
                      `formLabels.details.fancadeProduction.skilledLaborHourPrice`
                    ),
                    placeholder: t(
                      `formPlaceholders.details.fancadeProduction.skilledLaborHourPrice`
                    ),
                    type: "number",
                    width: 100,
                  },
                ]}
              />
            </Container>
          )}
        </Container>
      )}
    </Stack>
  );
};

const StyledButton = styled(Button)`
  color: white;
  background-color: ${theme.colors.euracOrange};
  border-radius: 500rem;

  &:hover {
    background-color: ${transparentize(0.5, theme.colors.euracOrange)};
  }
`;
