import { makeAutoObservable } from "mobx";
import api from "../services/api";
import { Stores, User } from "./../types";

export class UsersStore {
  stores: Stores;
  isFetching = false;

  users: User[] = [];

  constructor(stores: Stores) {
    this.stores = stores;
    makeAutoObservable(this);
  }

  fetchUsers = async () => {
    this.isFetching = true;
    try {
      this.users = await api.fetchUsers();
    } catch (err) {
      throw err;
    } finally {
      this.isFetching = false;
    }
  };

  createUser = async (data: any) => {
    this.isFetching = true;
    let user: any;
    try {
      user = await api.createUser(data);
      this.users.push(user);
    } catch (err) {
      throw err;
    } finally {
      this.isFetching = false;
      return user;
    }
  };

  updateUser = async (user: User) => {
    const temp = await api.updateUser(user);
    const toFind = this.users.find((element) => {
      return element.id == user.id;
    });
    if (toFind) this.users[this.users.indexOf(toFind)] = temp;
    return temp;
  };

  deleteUser = async (user: User) => {
    const temp = await api.deleteUser(user);
    const toFind = this.users.find((element) => {
      return element.id == user.id;
    });
    if (toFind) this.users.splice(this.users.indexOf(toFind), 1);
    return temp;
  };
}
