import { Stack, Flex, Text, Spacer } from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import { IoMdArrowDropup } from "react-icons/io";
import styled from "styled-components";
import theme from "../theme/theme";

type Props = {
  title?: any;
  direction?: "row" | "column";
  spacing?: string;
  bodyStyle?: any;
};

export const Container: React.FC<Props> = (props) => {
  const [showBody, setShowBody] = useState(false);
  return (
    <Stack spacing={0}>
      {typeof props.title == "string" &&
        props.title.toLowerCase().indexOf("campi per il calcolo") != -1 &&
        props.children && (
          <Flex marginBottom={"1rem"}>
            {React.Children.toArray(props.children)[0]}
          </Flex>
        )}
      <StyledFlexHeader
        onClick={() => {
          setShowBody(!showBody);
        }}
        alignItems={"center"}
      >
        {typeof props.title == "string" ? (
          <>
            <Text fontSize={"xl"} fontWeight={"medium"}>
              {props.title}
            </Text>
          </>
        ) : (
          <>{props.title}</>
        )}
        <Spacer />
        <IoMdArrowDropup
          style={{
            marginRight: "1rem",
            transform: showBody ? "rotate(0deg)" : "rotate(180deg)",
          }}
          size={"2rem"}
        />
      </StyledFlexHeader>
      <StyledStackBody
        direction={props.direction ? props.direction : "row"}
        spacing={props.spacing ? props.spacing : 0}
        display={showBody ? "flex" : "none"}
        style={props.bodyStyle ? props.bodyStyle : { backgroundColor: "white" }}
      >
        {typeof props.title == "string" &&
        props.title.toLowerCase().indexOf("campi per il calcolo") != -1
          ? React.Children.map(
              props.children,
              (child, index) => index > 0 && child
            )
          : props.children}
      </StyledStackBody>
    </Stack>
  );
};

const StyledFlexHeader = styled(Flex)`
  border: 2px solid ${theme.colors.darkGrey};
  background-color: ${theme.colors.lightGrey2};
  color: ${theme.colors.euracOrange};
  padding: 0.8em;
  cursor: pointer;
`;

const StyledStackBody = styled(Stack)`
  border: 2px solid ${theme.colors.darkGrey};
  border-top: 0px;
  padding: 0.8em;
`;
