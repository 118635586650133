import styled from "styled-components";
import { Button as CIButton } from "@chakra-ui/react";
import theme from "../../theme/theme";
import { transparentize } from "polished";

export const PrimaryButton = styled(CIButton)`
color: white;
background-color: ${theme.colors.euracOrange};
border-radius: 500rem;

&:hover {
  background-color: ${transparentize(0.5, theme.colors.euracOrange)};
}
  }
`;

export const SecondaryButton = styled(CIButton)`
  min-width: 4rem;
  border-radius: 500rem;
  color: ${(props) => (!props.disabled ? "white" : "black")};

  background-color: ${(props) =>
    !props.disabled ? "grey" : transparentize(0.85, "grey")};

  &:hover {
    background-color: ${transparentize(0.5, "grey")};
  }
`;
