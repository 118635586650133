import { TableRow } from "../components/PDFTable";
import i18n from "../i18n";
import {
  Fancade,
  FancadeProductionType,
  FancadeTechnicalProjectType,
  Project,
} from "../types";
import {
  calculateAerialPlatformsToolsKPI,
  calculateBenchmark,
  calculateBuildingSiteDurationKPI,
  calculateBuildingSiteDurationPerM2KPI,
  calculateBuildingSiteInstallationConstructionKPI,
  calculateBuildingSiteInstallationKPI,
  calculateBuildingSiteKPI,
  calculateBuildingSiteLaborInstallationKPI,
  calculateBuildingSiteMaterialsInstallationKPI,
  calculateBuildingSiteSecurityConstructionKPI,
  calculateBuildingSiteSecurityKPI,
  calculateBuildingSiteToolsInstallationKPI,
  calculateCleaningMaintenanceKPI,
  calculateCraneToolsKPI,
  calculateElevatorPlatformsToolsKPI,
  calculateEndOfLifeKPI,
  calculateExtraordinaryMaintenanceMaintenanceKPI,
  calculateFancadeKPI,
  calculateInvestmentKPI,
  calculateLaborCostKPI,
  calculateLaborCostProductionKPI,
  calculateLaborProductionAndConstructionKPI,
  calculateLCCInvestmentKPI,
  calculateMaintenanceKPI,
  calculateMaterialsCostKPI,
  calculateMaterialsCostProductionKPI,
  calculateMaterialsProductionAndConstructionKPI,
  calculateOrdinaryMaintenanceMaintenanceKPI,
  calculateProjectKPI,
  calculateRecyclingProductionKPI,
  calculateScaffoldingsToolsKPI,
  calculateToolsKPI,
  calculateTransportKPI,
  calculateTransportPerKmKPI,
  calculateUFancadeAverageKPI,
  calculateUwallKPI,
  calculateUwindowKPI,
  calculateWWRKPI,
  getAllFancadesCostsPerPhaseRows,
  getBuildingSite,
  getCostsPerPhaseBenchmarkRows,
  getCostsPerPhaseRows,
  getEndOfLife,
  getFancadeProduction,
  getFancadeProject,
  getFancadeTechnicalProject,
  getMaintenance,
  getServiceLife,
  getTransport,
} from "./utils";

const containsNoCase = (string: string, array: string[]): boolean => {
  let toReturn = false;
  array.map((element) => {
    if (element.toLowerCase() === string.toLowerCase()) {
      toReturn = true;
      return;
    }
  });

  return toReturn;
};

export const generalDataToTable = (project: Project): TableRow[] => {
  let toReturn: TableRow[] = [];
  const attributes = [
    "id",
    "name",
    "type",
    "useDestination",
    "constructionYear",
    "location",
    "reportAuthor",
    "description",
    "certification",
  ];
  if (project.fancades && project.fancades.length > 0) {
    let k: keyof typeof project;
    let index = 0;
    for (k in project) {
      if (containsNoCase(k.toString(), attributes)) {
        index += 1;
        toReturn.push({
          name: i18n.t("formLabels.details.general." + k.toString()), //@ts-ignore
          values: [project[k]],
          variation: index % 2 === 0 ? "highlight" : undefined,
        });
      }
    }
  }
  return toReturn;
};

export const fancadeTechnicalProjectToTable = (
  project: Project
): TableRow[] => {
  let toReturn: TableRow[] = [];

  if (project.fancades && project.fancades.length > 0) {
    //@ts-ignore
    let attributes = [
      "type",
      "surface",
      "windowsTotalPrice",

      "externalCladdingUnitsNumber", //rivestimento
      "totalExternalCladdingSurface", //rivestimento
      "insulationSurface", //isolamento
      "supportingElementsQuantity", //elementi portanti
      "prefabricatedModulesQuantity",
      "modulesSurface",
      "opaqueAnchoringsNumber", //ancoraggi
      "opaqueFixingsNumber", //fissaggi
      "transparentTotalPrice",
      "glazedCellsNumber",
      "glazedCellsSurface",
      "doubleGlazingQuantity",
      "doubleGlazingSurface",
      "mainFrameHeightPerUnit",
      "mainFrameWidthPerUnit",
      "totalMainFrameLength",
      "totalGasketLength",
      "opaqueSpandrelPanelsQuantity",
      "opaqueSpandrelPanelsSurface",
      "transparentAnchoringsNumber", //ancoraggi
      "transparentFixingsNumber", //fissaggi
      "shieldingSystemsNumber",
      "ventilationDevicesNumber",
      "airConditioningDevicesNumber",
      "thermicSolarPanelsNumber",
      "windowOpaqueSurfaceRatio",
      "thermicSolarPanelsSurface",
      "photovoltaicModulesSurface",
      "photovoltaicModulesNumber",
      "opaqueUValue",
      "transparentUValue",
      "windowGlassGValue",
      "fancadeGlassGValue",
      "prefabricationLevel",
    ];

    //Check, Add windows

    const tempFancade = project.fancades[0];
    let k: keyof typeof tempFancade.fancadeTechnicalProject;
    let index = 0;
    for (k in tempFancade.fancadeTechnicalProject) {
      if (containsNoCase(k.toString(), attributes)) {
        index += 1;
        toReturn.push({
          name: i18n.t("formLabels.details.fancadeProject." + k.toString()), //@ts-ignore
          values: [],
          variation: index % 2 === 0 ? "highlight" : undefined,
        });
        project.fancades?.map((fancade: Fancade, fancadeIndex) => {
          if (getFancadeTechnicalProject(project, fancadeIndex)) {
            toReturn[toReturn.length - 1].values.push(
              //@ts-ignore
              getFancadeTechnicalProject(project, fancadeIndex) &&
                //@ts-ignore
                getFancadeTechnicalProject(project, fancadeIndex)[k]
                ? //@ts-ignore
                  getFancadeTechnicalProject(project, fancadeIndex)[k]
                : " "
            );
          } else {
            //@ts-ignore
            toReturn[toReturn.length - 1].values.push(" ");
          }
        });
      }
    }
  }
  return toReturn;
};

export const fancadeProjectToTable = (project: Project): TableRow[] => {
  let toReturn: TableRow[] = [];

  if (project.fancades && project.fancades.length > 0) {
    const tempFancade = project.fancades[0];
    let k: keyof typeof tempFancade.fancadeProject;
    let index = 0;
    for (k in tempFancade.fancadeProject) {
      if (k.toString().toLocaleLowerCase().indexOf("calculation") === -1) {
        index += 1;
        toReturn.push({
          name: i18n.t("formLabels.details.fancadePrice." + k.toString()), //@ts-ignore
          values: [],
          variation: index % 2 === 0 ? "highlight" : undefined,
        });
        project.fancades?.map((fancade: Fancade, fancadeIndex) => {
          if (getFancadeTechnicalProject(project, fancadeIndex)) {
            toReturn[toReturn.length - 1].values.push(
              //@ts-ignore
              getFancadeProject(project, fancadeIndex) &&
                //@ts-ignore
                getFancadeProject(project, fancadeIndex)[k]
                ? //@ts-ignore
                  getFancadeProject(project, fancadeIndex)[k]
                : " "
            );
          } else {
            //@ts-ignore
            toReturn[toReturn.length - 1].values.push(" ");
          }
        });
      }
    }
  }

  return toReturn;
};

export const productionToTable = (project: Project): TableRow[] => {
  let toReturn: TableRow[] = [];

  const fancadeProductionAttributes = [
    "totalProductionPrice",

    "totalMaterialsPrice",
    "totalSystemIntegrationPrice",

    //Labor
    "totalLaborPrice",

    "totalBaseLaborPrice",
    "baseLaborHours",
    "baseLaborHourPrice",

    "totalSkilledLaborPrice",
    "skilledLaborHours",
    "skilledLaborHourPrice",
    "hardwareTotalPrice",
  ];
  const fancadeTechnicalProjectAttributes = [
    //Involucro opaco production
    "externalCladdingTotalPrice",
    "externalCladdingUnitPrice",
    "insulationTotalPrice",
    "insulationUnitPrice",
    "supportingElementsTotalPrice",
    "supportingElementsUnitPrice",

    "prefabricatedModulesTotalPrice",
    "prefabricatedModulesUnitPrice",

    "opaqueAnchoringTotalPrice",
    "opaqueAnchoringUnitPrice",

    "opaqueFixingsTotalPrice",
    "opaqueFixingsUnitPrice",
    //Involucro trasparente production
    "glazedCellsUnitPrice",
    "totalGlazedCellsPrice",
    "totalMainFramePrice",
    "mainFrameUnitPrice",
    "totalDoubleGlazingPrice",
    "doubleGlazingUnitPrice",
    "gasketUnitPrice",
    "totalGasketPrice",
    "opaqueSpandrelModulesUnitPrice",
    "totalOpaqueSpandrelModulesPrice",
    "transparentAnchoringsUnitPrice",
    "totalTransparentAnchoringsPrice",
    "totalTransparentFixingsNumberPrice",
    "transparentFixingsNumberUnitPrice",

    //Systems Integration production

    "shieldingsUnitPrice",
    "totalShieldingsPrice",
    "totalShieldingsPrice",

    "ventilationSystemsUnitPrice",
    "totalVentilationSystemsPrice",

    "airConditioningSystemsUnitPrice",
    "totalAirConditioningSystemsPrice",

    "thermicSolarPanelsUnitPrice",
    "totalThermicSolarPanelsPrice",

    "photovoltaicModulesUnitPrice",
    "totalPhotovoltaicModulesPrice",

    "totalScrapPrice",
  ];

  if (project.fancades && project.fancades.length > 0) {
    const tempProduction: FancadeProductionType =
      project.fancades[0].fancadeProduction;
    const tempTechnicalProject: FancadeTechnicalProjectType =
      project.fancades[0].fancadeTechnicalProject;
    let k: keyof typeof tempProduction;
    let j: keyof typeof tempTechnicalProject;
    let index = 0;
    for (k in tempProduction) {
      if (containsNoCase(k.toString(), fancadeProductionAttributes)) {
        index += 1;
        toReturn.push({
          name: i18n.t("formLabels.details.fancadeProduction." + k.toString()), //@ts-ignore
          values: [],
          variation: index % 2 === 0 ? "highlight" : undefined,
        });
        project.fancades.map((fancade, fancadeIndex) => {
          if (getFancadeProduction(project, fancadeIndex)) {
            toReturn[toReturn.length - 1].values.push(
              //@ts-ignore
              getFancadeProduction(project, fancadeIndex) &&
                //@ts-ignore
                getFancadeProduction(project, fancadeIndex)[k]
                ? //@ts-ignore
                  getFancadeProduction(project, fancadeIndex)[k]
                : " "
            );
          } else {
            //@ts-ignore
            toReturn[toReturn.length - 1].values.push(" ");
          }
        });
      }
    }

    for (j in tempTechnicalProject) {
      if (containsNoCase(j.toString(), fancadeTechnicalProjectAttributes)) {
        index += 1;
        toReturn.push({
          name: i18n.t("formLabels.details.fancadeProduction." + j.toString()), //@ts-ignore
          values: [],
          variation: index % 2 === 0 ? "highlight" : undefined,
        });
        project.fancades.map((fancade, fancadeIndex) => {
          if (getFancadeTechnicalProject(project, fancadeIndex)) {
            toReturn[toReturn.length - 1].values.push(
              //@ts-ignore
              getFancadeTechnicalProject(project, fancadeIndex) &&
                //@ts-ignore
                getFancadeTechnicalProject(project, fancadeIndex)[j]
                ? //@ts-ignore
                  getFancadeTechnicalProject(project, fancadeIndex)[j]
                : " "
            );
          } else {
            //@ts-ignore
            toReturn[toReturn.length - 1].values.push(" ");
          }
        });
      }
    }
  }
  return toReturn;
};

export const transportToTable = (project: Project): TableRow[] => {
  let toReturn: TableRow[] = [];

  if (project.fancades && project.fancades.length > 0) {
    const tempFancade = project.fancades[0];
    let k: keyof typeof tempFancade.transport;
    let index = 0;
    for (k in tempFancade.transport) {
      if (
        k.toString().toLowerCase().indexOf("calculation") === -1 &&
        k.toString().toLowerCase().indexOf("packagings") === -1
      ) {
        index += 1;
        toReturn.push({
          name: i18n.t("formLabels.details.transport." + k.toString()), //@ts-ignore
          values: [],
          variation: index % 2 === 0 ? "highlight" : undefined,
        });

        project.fancades?.map((fancade: Fancade, fancadeIndex) => {
          if (getTransport(project, fancadeIndex)) {
            toReturn[toReturn.length - 1].values.push(
              //@ts-ignore
              getTransport(project, fancadeIndex) &&
                //@ts-ignore
                getTransport(project, fancadeIndex)[k]
                ? //@ts-ignore
                  getTransport(project, fancadeIndex)[k]
                : " "
            );
          } else {
            //@ts-ignore
            toReturn[toReturn.length - 1].values.push(" ");
          }
        });
      }
    }
  }

  return toReturn;
};

export const buildingSiteToTable = (project: Project): TableRow[] => {
  let toReturn: TableRow[] = [];

  if (project.fancades && project.fancades.length > 0) {
    const tempFancade = project.fancades[0];
    let k: keyof typeof tempFancade.buildingSite;
    let index = 0;
    for (k in tempFancade.buildingSite) {
      if (
        k.toString().toLowerCase().indexOf("calculation") === -1 &&
        k.toString().toLowerCase().indexOf("buildingSiteMaterials") === -1
      ) {
        index += 1;
        toReturn.push({
          name: i18n.t("formLabels.details.buildingSite." + k.toString()), //@ts-ignore
          values: [],
          variation: index % 2 === 0 ? "highlight" : undefined,
        });

        project.fancades?.map((fancade: Fancade, fancadeIndex) => {
          if (getBuildingSite(project, fancadeIndex)) {
            toReturn[toReturn.length - 1].values.push(
              //@ts-ignore
              getBuildingSite(project, fancadeIndex) &&
                //@ts-ignore
                getBuildingSite(project, fancadeIndex)[k]
                ? //@ts-ignore
                  getBuildingSite(project, fancadeIndex)[k]
                : " "
            );
          } else {
            //@ts-ignore
            toReturn[toReturn.length - 1].values.push(" ");
          }
        });
      }
    }
  }

  return toReturn;
};

export const serviceLifeToTable = (project: Project): TableRow[] => {
  let toReturn: TableRow[] = [];

  if (project.fancades && project.fancades.length > 0) {
    const tempFancade = project.fancades[0];
    let k: keyof typeof tempFancade.serviceLife;
    let index = 0;
    for (k in tempFancade.serviceLife) {
      if (
        k.toString().toLowerCase().indexOf("calculation") === -1 &&
        k.toString().toLowerCase().indexOf("implants") === -1
      ) {
        index += 1;
        toReturn.push({
          name: i18n.t("formLabels.details.serviceLife." + k.toString()), //@ts-ignore
          values: [],
          variation: index % 2 === 0 ? "highlight" : undefined,
        });

        project.fancades?.map((fancade: Fancade, fancadeIndex) => {
          if (getServiceLife(project, fancadeIndex)) {
            toReturn[toReturn.length - 1].values.push(
              //@ts-ignore
              getServiceLife(project, fancadeIndex) &&
                //@ts-ignore
                getServiceLife(project, fancadeIndex)[k]
                ? //@ts-ignore
                  getServiceLife(project, fancadeIndex)[k]
                : " "
            );
          } else {
            //@ts-ignore
            toReturn[toReturn.length - 1].values.push(" ");
          }
        });
      }
    }
  }

  return toReturn;
};

export const maintenanceToTable = (project: Project): TableRow[] => {
  let toReturn: TableRow[] = [];

  if (project.fancades && project.fancades.length > 0) {
    const tempFancade = project.fancades[0];
    let k: keyof typeof tempFancade.maintenance;
    let index = 0;
    for (k in tempFancade.maintenance) {
      if (
        k.toString().toLowerCase().indexOf("calculation") === -1 &&
        k.toString().toLowerCase().indexOf("istotal") === -1
      ) {
        index += 1;
        toReturn.push({
          name: i18n.t("formLabels.details.maintenance." + k.toString()), //@ts-ignore
          values: [],
          variation: index % 2 === 0 ? "highlight" : undefined,
        });

        project.fancades?.map((fancade: Fancade, fancadeIndex) => {
          if (getMaintenance(project, fancadeIndex)) {
            toReturn[toReturn.length - 1].values.push(
              //@ts-ignore
              getMaintenance(project, fancadeIndex) &&
                //@ts-ignore
                getMaintenance(project, fancadeIndex)[k]
                ? //@ts-ignore
                  getMaintenance(project, fancadeIndex)[k]
                : " "
            );
          } else {
            //@ts-ignore
            toReturn[toReturn.length - 1].values.push(" ");
          }
        });
      }
    }
  }

  return toReturn;
};

export const actualizationToTable = (project: Project): TableRow[] => {
  let toReturn: TableRow[] = [];

  if (project.fancades && project.fancades.length > 0) {
  }
  return toReturn;
};

export const endOfLifeToTable = (project: Project): TableRow[] => {
  let toReturn: TableRow[] = [];

  if (project.fancades && project.fancades.length > 0) {
    const tempFancade = project.fancades[0];
    let k: keyof typeof tempFancade.endOfLife;
    let index = 0;
    for (k in tempFancade.endOfLife) {
      if (k.toString().toLowerCase().indexOf("calculation") === -1) {
        index += 1;
        toReturn.push({
          name: i18n.t("formLabels.details.endOfLife." + k.toString()), //@ts-ignore
          values: [],
          variation: index % 2 === 0 ? "highlight" : undefined,
        });

        project.fancades?.map((fancade: Fancade, fancadeIndex) => {
          if (getEndOfLife(project, fancadeIndex)) {
            toReturn[toReturn.length - 1].values.push(
              //@ts-ignore
              getEndOfLife(project, fancadeIndex) &&
                //@ts-ignore
                getEndOfLife(project, fancadeIndex)[k]
                ? //@ts-ignore
                  getEndOfLife(project, fancadeIndex)[k]
                : " "
            );
          } else {
            //@ts-ignore
            toReturn[toReturn.length - 1].values.push(" ");
          }
        });
      }
    }
  }

  return toReturn;
};

export const costPerPhaseToTable = (
  project: Project,
  fancadeIndex: number,
  benchmark?: boolean
): TableRow[] => {
  let rows: TableRow[] = [];
  if (fancadeIndex == -1) {
    rows = getAllFancadesCostsPerPhaseRows(project, benchmark ? true : false);
  } else if (benchmark) {
    rows = getCostsPerPhaseBenchmarkRows(project, fancadeIndex);
  } else {
    rows = getCostsPerPhaseRows(project, fancadeIndex);
  }

  if (rows[0])
    rows[0].name = i18n.t("tablesVoices.report.priceForPhase.project");
  if (rows[1])
    rows[1].name = i18n.t(
      "tablesVoices.report.priceForPhase.fancadeProduction"
    );
  if (rows[2])
    rows[2].name = i18n.t(
      "tablesVoices.report.priceForPhase.transportToConstructionSite"
    );
  if (rows[3])
    rows[3].name = i18n.t("tablesVoices.report.priceForPhase.installation");
  if (rows[4])
    rows[4].name = i18n.t("tablesVoices.report.priceForPhase.serviceLife");
  if (rows[5])
    rows[5].name = i18n.t("tablesVoices.report.priceForPhase.maintenance");
  if (rows[6])
    rows[6].name = i18n.t("tablesVoices.report.priceForPhase.disposal");
  if (rows[7])
    rows[7].name = i18n.t(
      "tablesVoices.report.priceForPhase.recyclingRevenues"
    );
  if (rows[8])
    rows[8].name = i18n.t("tablesVoices.report.priceForPhase.totalInvestment");
  if (rows[9])
    rows[9].name = i18n.t("tablesVoices.report.priceForPhase.LCCInvestment");

  rows.map((row, index) => {
    if (index % 2 === 0) {
      rows[index].variation = "highlight";
    }
  });
  return rows;
};

export const kpiToTable = (
  propProject: Project,
  fancadeIndex: number,
  isBenchmark?: boolean
): TableRow[] => {
  let rows: TableRow[] = [];
  let fancade: Fancade | undefined = undefined;
  let benchmark: Fancade | undefined = undefined;
  let project: Project | undefined = undefined;

  if (fancadeIndex != -1) {
    if (
      propProject &&
      propProject.fancades &&
      propProject.fancades.length > 0
    ) {
      fancade = propProject.fancades[fancadeIndex];
      if (isBenchmark) benchmark = propProject.fancades[0];
    }
  } else {
    project = propProject;
  }

  if (true) {
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.investment"),
      values: [
        (benchmark
          ? calculateBenchmark(
              +calculateInvestmentKPI(benchmark, project),
              +calculateInvestmentKPI(fancade, project)
            )
          : calculateInvestmentKPI(fancade, project)
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " €/m²",
      ],
    });
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.investmentLCC"),
      values: [
        (benchmark
          ? calculateBenchmark(
              +calculateLCCInvestmentKPI(benchmark, project),
              +calculateLCCInvestmentKPI(fancade, project)
            )
          : calculateInvestmentKPI(fancade, project)
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " €/m²",
      ],
    });
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.project"),
      values: [
        (benchmark
          ? calculateBenchmark(
              +calculateProjectKPI(benchmark, project),
              +calculateProjectKPI(fancade, project)
            )
          : calculateProjectKPI(fancade, project)
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " €/m²",
      ],
    });

    rows.push({
      name: i18n.t("tablesVoices.report.kpi.materialsProductionRatio"),
      values: [
        (benchmark
          ? +calculateBenchmark(
              +calculateMaterialsCostProductionKPI(benchmark, project),
              +calculateMaterialsCostProductionKPI(fancade, project)
            ) * 100
          : +calculateMaterialsCostProductionKPI(fancade, project) * 100
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " %",
      ],
    });

    rows.push({
      name: i18n.t("tablesVoices.report.kpi.laborProductionRatio"),
      values: [
        (benchmark
          ? +calculateBenchmark(
              +calculateLaborCostProductionKPI(benchmark, project),
              +calculateLaborCostProductionKPI(fancade, project)
            ) * 100
          : +calculateLaborCostProductionKPI(fancade, project) * 100
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " %",
      ],
    });

    rows.push({
      name: i18n.t("tablesVoices.report.kpi.transport"),
      values: [
        (benchmark
          ? calculateBenchmark(
              +calculateTransportKPI(benchmark, project),
              +calculateTransportKPI(fancade, project)
            )
          : calculateTransportKPI(fancade, project)
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " €/m²",
      ],
    });

    rows.push({
      name: i18n.t("tablesVoices.report.kpi.trasportPerKm"),
      values: [
        (benchmark
          ? calculateBenchmark(
              +calculateTransportPerKmKPI(benchmark, project),
              +calculateTransportPerKmKPI(fancade, project)
            )
          : calculateTransportPerKmKPI(fancade, project)
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " €/Km",
      ],
    });
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.installationOpera"),
      values: [
        (benchmark
          ? calculateBenchmark(
              +calculateBuildingSiteKPI(benchmark, project),
              +calculateBuildingSiteKPI(fancade, project)
            )
          : calculateBuildingSiteKPI(fancade, project)
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " €/m²",
      ],
    });
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.installation"),
      values: [
        (benchmark
          ? calculateBenchmark(
              +calculateBuildingSiteInstallationKPI(benchmark, project),
              +calculateBuildingSiteInstallationKPI(fancade, project)
            )
          : calculateBuildingSiteInstallationKPI(fancade, project)
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " €/m²",
      ],
    });
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.security"),
      values: [
        (benchmark
          ? calculateBenchmark(
              +calculateBuildingSiteSecurityKPI(benchmark, project),
              +calculateBuildingSiteSecurityKPI(fancade, project)
            )
          : calculateBuildingSiteSecurityKPI(fancade, project)
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " €/m²",
      ],
    });
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.installationConstructionRatio"),
      values: [
        (benchmark
          ? +calculateBenchmark(
              +calculateBuildingSiteInstallationConstructionKPI(
                benchmark,
                project
              ),
              +calculateBuildingSiteInstallationConstructionKPI(
                fancade,
                project
              )
            ) * 100
          : +calculateBuildingSiteInstallationConstructionKPI(
              fancade,
              project
            ) * 100
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " %",
      ],
    });
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.securityConstructionRatio"),
      values: [
        (benchmark
          ? +calculateBenchmark(
              +calculateBuildingSiteSecurityConstructionKPI(benchmark, project),
              +calculateBuildingSiteSecurityConstructionKPI(fancade, project)
            ) * 100
          : +calculateBuildingSiteSecurityConstructionKPI(fancade, project) *
            100
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " %",
      ],
    });
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.materalsInstallationRatio"),
      values: [
        (benchmark
          ? +calculateBenchmark(
              +calculateBuildingSiteMaterialsInstallationKPI(
                benchmark,
                project
              ),
              +calculateBuildingSiteMaterialsInstallationKPI(fancade, project)
            ) * 100
          : +calculateBuildingSiteMaterialsInstallationKPI(fancade, project) *
            100
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " %",
      ],
    });
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.laborInstallationRatio"),
      values: [
        (
          +calculateBuildingSiteLaborInstallationKPI(fancade, project) * 100
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " %",
      ],
    });
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.toolsInstallationRatio"),
      values: [
        (benchmark
          ? +calculateBenchmark(
              +calculateBuildingSiteToolsInstallationKPI(benchmark, project),
              +calculateBuildingSiteToolsInstallationKPI(fancade, project)
            ) * 100
          : +calculateBuildingSiteToolsInstallationKPI(fancade, project) * 100
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " %",
      ],
    });
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.tools"),
      values: [
        (benchmark
          ? calculateBenchmark(
              +calculateToolsKPI(benchmark, project),
              +calculateToolsKPI(fancade, project)
            )
          : calculateToolsKPI(fancade, project)
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " €/m²",
      ],
    });
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.crane"),
      values: [
        (benchmark
          ? +calculateBenchmark(
              +calculateCraneToolsKPI(benchmark, project),
              +calculateCraneToolsKPI(fancade, project)
            ) * 100
          : +calculateCraneToolsKPI(fancade, project) * 100
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " %",
      ],
    });
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.elevatorPlatforms"),
      values: [
        (benchmark
          ? +calculateBenchmark(
              +calculateElevatorPlatformsToolsKPI(benchmark, project),
              +calculateElevatorPlatformsToolsKPI(fancade, project)
            ) * 100
          : +calculateElevatorPlatformsToolsKPI(fancade, project) * 100
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " %",
      ],
    });
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.aerialPlatforms"),
      values: [
        (benchmark
          ? +calculateBenchmark(
              +calculateAerialPlatformsToolsKPI(benchmark, project),
              +calculateAerialPlatformsToolsKPI(fancade, project)
            ) * 100
          : +calculateAerialPlatformsToolsKPI(fancade, project) * 100
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " %",
      ],
    });
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.scaffolding"),
      values: [
        (benchmark
          ? +calculateBenchmark(
              +calculateScaffoldingsToolsKPI(benchmark, project),
              +calculateScaffoldingsToolsKPI(fancade, project)
            ) * 100
          : +calculateScaffoldingsToolsKPI(fancade, project) * 100
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " %",
      ],
    });
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.buildingSiteDuration"),
      values: [
        (benchmark
          ? calculateBenchmark(
              +calculateBuildingSiteDurationKPI(benchmark, project),
              +calculateBuildingSiteDurationKPI(fancade, project)
            )
          : calculateBuildingSiteDurationKPI(fancade, project)
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " giorni",
      ],
    });
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.buildingSiteDurationPerM2"),
      values: [
        (benchmark
          ? calculateBenchmark(
              +calculateBuildingSiteDurationPerM2KPI(benchmark, project),
              +calculateBuildingSiteDurationPerM2KPI(fancade, project)
            )
          : calculateBuildingSiteDurationPerM2KPI(fancade, project)
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " Gg/m²",
      ],
    });
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.maintenance"),
      values: [
        (benchmark
          ? calculateBenchmark(
              +calculateMaintenanceKPI(benchmark, project),
              +calculateMaintenanceKPI(fancade, project)
            )
          : calculateMaintenanceKPI(fancade, project)
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " €/m²",
      ],
    });
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.ordinaryMaintenance"),
      values: [
        (benchmark
          ? +calculateBenchmark(
              +calculateOrdinaryMaintenanceMaintenanceKPI(benchmark, project),
              +calculateOrdinaryMaintenanceMaintenanceKPI(fancade, project)
            ) * 100
          : +calculateOrdinaryMaintenanceMaintenanceKPI(fancade, project) * 100
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " %",
      ],
    });
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.extraordinaryMaintenance"),
      values: [
        (benchmark
          ? +calculateBenchmark(
              +calculateExtraordinaryMaintenanceMaintenanceKPI(
                benchmark,
                project
              ),
              +calculateExtraordinaryMaintenanceMaintenanceKPI(fancade, project)
            ) * 100
          : +calculateExtraordinaryMaintenanceMaintenanceKPI(fancade, project) *
            100
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " %",
      ],
    });
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.cleaning"),
      values: [
        (benchmark
          ? +calculateBenchmark(
              +calculateCleaningMaintenanceKPI(benchmark, project),
              +calculateCleaningMaintenanceKPI(fancade, project)
            ) * 100
          : +calculateCleaningMaintenanceKPI(fancade, project) * 100
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " %",
      ],
    });
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.endOfLife"),
      values: [
        (benchmark
          ? calculateBenchmark(
              +calculateEndOfLifeKPI(benchmark, project),
              +calculateEndOfLifeKPI(fancade, project)
            )
          : calculateEndOfLifeKPI(fancade, project)
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " €/m²",
      ],
    });
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.recyclingProductionRatio"),
      values: [
        (benchmark
          ? +calculateBenchmark(
              +calculateRecyclingProductionKPI(benchmark, project),
              +calculateRecyclingProductionKPI(fancade, project)
            ) * 100
          : +calculateRecyclingProductionKPI(fancade, project) * 100
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " %",
      ],
    });
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.materialsTotal"),
      values: [
        (benchmark
          ? calculateBenchmark(
              +calculateMaterialsProductionAndConstructionKPI(
                benchmark,
                project
              ),
              +calculateMaterialsProductionAndConstructionKPI(fancade, project)
            )
          : calculateMaterialsProductionAndConstructionKPI(fancade, project)
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " €",
      ],
    });
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.materialsCost"),
      values: [
        (benchmark
          ? calculateBenchmark(
              +calculateMaterialsCostKPI(benchmark, project),
              +calculateMaterialsCostKPI(fancade, project)
            )
          : calculateMaterialsCostKPI(fancade, project)
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " €/m²",
      ],
    });
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.laborTotal"),
      values: [
        (benchmark
          ? calculateBenchmark(
              +calculateLaborProductionAndConstructionKPI(benchmark, project),
              +calculateLaborProductionAndConstructionKPI(fancade, project)
            )
          : calculateLaborProductionAndConstructionKPI(fancade, project)
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " €",
      ],
    });
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.laborCost"),
      values: [
        (benchmark
          ? calculateBenchmark(
              +calculateLaborCostKPI(benchmark, project),
              +calculateLaborCostKPI(fancade, project)
            )
          : calculateLaborCostKPI(fancade, project)
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " €/m²",
      ],
    });
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.technicalParameters"),
      values: [""],
    });
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.surface"),
      values: [
        (benchmark
          ? calculateBenchmark(
              +calculateFancadeKPI(benchmark, project),
              +calculateFancadeKPI(fancade, project)
            )
          : calculateFancadeKPI(fancade, project)
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " m²",
      ],
    });
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.wwr"),
      values: [
        (benchmark
          ? +calculateBenchmark(
              +calculateWWRKPI(benchmark, project),
              +calculateWWRKPI(fancade, project)
            ) * 100
          : +calculateWWRKPI(fancade, project) * 100
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " %",
      ],
    });
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.uparete"),
      values: [
        (benchmark
          ? calculateBenchmark(
              +calculateUwallKPI(benchmark, project),
              +calculateUwallKPI(fancade, project)
            )
          : calculateUwallKPI(fancade, project)
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " W/m²K",
      ],
    });
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.ufinestra"),
      values: [
        (benchmark
          ? calculateBenchmark(
              +calculateUwindowKPI(benchmark, project),
              +calculateUwindowKPI(fancade, project)
            )
          : calculateUwindowKPI(fancade, project)
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " W/m²K",
      ],
    });
    rows.push({
      name: i18n.t("tablesVoices.report.kpi.ufacciata"),
      values: [
        (benchmark
          ? calculateBenchmark(
              +calculateUFancadeAverageKPI(benchmark, project),
              +calculateUFancadeAverageKPI(fancade, project)
            )
          : calculateUFancadeAverageKPI(fancade, project)
        ).toLocaleString("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + " W/m²K",
      ],
    });
  }

  rows.map((row, index) => {
    if (index % 2 === 0) {
      rows[index].variation = "highlight";
    }
  });

  return rows;
};

export const getComparisonRows = (
  rows1: any[],
  rows2: any[],
  fancadeIndex1: number,
  fancadeIndex2: number
): TableRow[] => {
  let toReturn: any[] = [];

  if (rows1.length > 0) {
    rows1.map((row, index) => toReturn.push({ ...row }));
  } else if (rows2.length > 0) {
    rows2.map((row, index) => toReturn.push({ ...row }));
  }
  toReturn.map((row, index) => {
    toReturn[index].values = [];
    if (
      rows1.length > index &&
      rows1[index].values &&
      rows1[index].values.length > fancadeIndex1
    ) {
      //@ts-ignore
      toReturn[index].values.push(rows1[index].values[fancadeIndex1]);
    } else {
      //@ts-ignore
      toReturn[index].values.push(" ");
    }
    if (
      rows2.length > index &&
      rows2[index].values &&
      rows2[index].values.length > fancadeIndex2
    ) {
      //@ts-ignore
      toReturn[index].values.push(rows2[index].values[fancadeIndex2]);
    } else {
      //@ts-ignore
      toReturn[index].values.push(" ");
    }
  });

  return toReturn;
};

export const getKpiRows = (
  propProject: Project,
  propFancadeIndex: number,
  prpBenchmark?: boolean
): TableRow[] => {
  let rows: TableRow[] = [];
  let fancade: Fancade | undefined = undefined;
  let benchmark: Fancade | undefined = undefined;
  let project: Project | undefined = undefined;

  if (propFancadeIndex != -1) {
    if (
      propProject &&
      propProject.fancades &&
      propProject.fancades.length > 0
    ) {
      fancade = propProject.fancades[propFancadeIndex];
      if (benchmark) benchmark = propProject.fancades[0];
    }
  } else {
    project = propProject;
  }

  rows.push({
    name: i18n.t("tablesVoices.report.kpi.investment"),
    values: [
      (benchmark
        ? calculateBenchmark(
            +calculateInvestmentKPI(benchmark, project),
            +calculateInvestmentKPI(fancade, project)
          )
        : calculateInvestmentKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " €/m²",
    ],
  });
  rows.push({
    name: i18n.t("tablesVoices.report.kpi.investmentLCC"),
    values: [
      (benchmark
        ? calculateBenchmark(
            +calculateLCCInvestmentKPI(benchmark, project),
            +calculateLCCInvestmentKPI(fancade, project)
          )
        : calculateInvestmentKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " €/m²",
    ],
  });
  rows.push({
    name: i18n.t("tablesVoices.report.kpi.project"),
    values: [
      (benchmark
        ? calculateBenchmark(
            +calculateProjectKPI(benchmark, project),
            +calculateProjectKPI(fancade, project)
          )
        : calculateProjectKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " €/m²",
    ],
  });

  rows.push({
    name: i18n.t("tablesVoices.report.kpi.materialsProductionRatio"),
    values: [
      (benchmark
        ? +calculateBenchmark(
            +calculateMaterialsCostProductionKPI(benchmark, project),
            +calculateMaterialsCostProductionKPI(fancade, project)
          ) * 100
        : +calculateMaterialsCostProductionKPI(fancade, project) * 100
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " %",
    ],
  });

  rows.push({
    name: i18n.t("tablesVoices.report.kpi.laborProductionRatio"),
    values: [
      (benchmark
        ? +calculateBenchmark(
            +calculateLaborCostProductionKPI(benchmark, project),
            +calculateLaborCostProductionKPI(fancade, project)
          ) * 100
        : +calculateLaborCostProductionKPI(fancade, project) * 100
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " %",
    ],
  });

  rows.push({
    name: i18n.t("tablesVoices.report.kpi.transport"),
    values: [
      (benchmark
        ? calculateBenchmark(
            +calculateTransportKPI(benchmark, project),
            +calculateTransportKPI(fancade, project)
          )
        : calculateTransportKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " €/m²",
    ],
  });

  rows.push({
    name: i18n.t("tablesVoices.report.kpi.trasportPerKm"),
    values: [
      (benchmark
        ? calculateBenchmark(
            +calculateTransportPerKmKPI(benchmark, project),
            +calculateTransportPerKmKPI(fancade, project)
          )
        : calculateTransportPerKmKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " €/Km",
    ],
  });
  rows.push({
    name: i18n.t("tablesVoices.report.kpi.installationOpera"),
    values: [
      (benchmark
        ? calculateBenchmark(
            +calculateBuildingSiteKPI(benchmark, project),
            +calculateBuildingSiteKPI(fancade, project)
          )
        : calculateBuildingSiteKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " €/m²",
    ],
  });
  rows.push({
    name: i18n.t("tablesVoices.report.kpi.installation"),
    values: [
      (benchmark
        ? calculateBenchmark(
            +calculateBuildingSiteInstallationKPI(benchmark, project),
            +calculateBuildingSiteInstallationKPI(fancade, project)
          )
        : calculateBuildingSiteInstallationKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " €/m²",
    ],
  });
  rows.push({
    name: i18n.t("tablesVoices.report.kpi.security"),
    values: [
      (benchmark
        ? calculateBenchmark(
            +calculateBuildingSiteSecurityKPI(benchmark, project),
            +calculateBuildingSiteSecurityKPI(fancade, project)
          )
        : calculateBuildingSiteSecurityKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " €/m²",
    ],
  });
  rows.push({
    name: i18n.t("tablesVoices.report.kpi.installationConstructionRatio"),
    values: [
      (benchmark
        ? +calculateBenchmark(
            +calculateBuildingSiteInstallationConstructionKPI(
              benchmark,
              project
            ),
            +calculateBuildingSiteInstallationConstructionKPI(fancade, project)
          ) * 100
        : +calculateBuildingSiteInstallationConstructionKPI(fancade, project) *
          100
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " %",
    ],
  });
  rows.push({
    name: i18n.t("tablesVoices.report.kpi.securityConstructionRatio"),
    values: [
      (benchmark
        ? +calculateBenchmark(
            +calculateBuildingSiteSecurityConstructionKPI(benchmark, project),
            +calculateBuildingSiteSecurityConstructionKPI(fancade, project)
          ) * 100
        : +calculateBuildingSiteSecurityConstructionKPI(fancade, project) * 100
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " %",
    ],
  });
  rows.push({
    name: i18n.t("tablesVoices.report.kpi.materalsInstallationRatio"),
    values: [
      (benchmark
        ? +calculateBenchmark(
            +calculateBuildingSiteMaterialsInstallationKPI(benchmark, project),
            +calculateBuildingSiteMaterialsInstallationKPI(fancade, project)
          ) * 100
        : +calculateBuildingSiteMaterialsInstallationKPI(fancade, project) * 100
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " %",
    ],
  });
  rows.push({
    name: i18n.t("tablesVoices.report.kpi.laborInstallationRatio"),
    values: [
      (
        +calculateBuildingSiteLaborInstallationKPI(fancade, project) * 100
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " %",
    ],
  });
  rows.push({
    name: i18n.t("tablesVoices.report.kpi.toolsInstallationRatio"),
    values: [
      (benchmark
        ? +calculateBenchmark(
            +calculateBuildingSiteToolsInstallationKPI(benchmark, project),
            +calculateBuildingSiteToolsInstallationKPI(fancade, project)
          ) * 100
        : +calculateBuildingSiteToolsInstallationKPI(fancade, project) * 100
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " %",
    ],
  });
  rows.push({
    name: i18n.t("tablesVoices.report.kpi.tools"),
    values: [
      (benchmark
        ? calculateBenchmark(
            +calculateToolsKPI(benchmark, project),
            +calculateToolsKPI(fancade, project)
          )
        : calculateToolsKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " €/m²",
    ],
  });
  rows.push({
    name: i18n.t("tablesVoices.report.kpi.crane"),
    values: [
      (benchmark
        ? +calculateBenchmark(
            +calculateCraneToolsKPI(benchmark, project),
            +calculateCraneToolsKPI(fancade, project)
          ) * 100
        : +calculateCraneToolsKPI(fancade, project) * 100
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " %",
    ],
  });
  rows.push({
    name: i18n.t("tablesVoices.report.kpi.elevatorPlatforms"),
    values: [
      (benchmark
        ? +calculateBenchmark(
            +calculateElevatorPlatformsToolsKPI(benchmark, project),
            +calculateElevatorPlatformsToolsKPI(fancade, project)
          ) * 100
        : +calculateElevatorPlatformsToolsKPI(fancade, project) * 100
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " %",
    ],
  });
  rows.push({
    name: i18n.t("tablesVoices.report.kpi.aerialPlatforms"),
    values: [
      (benchmark
        ? +calculateBenchmark(
            +calculateAerialPlatformsToolsKPI(benchmark, project),
            +calculateAerialPlatformsToolsKPI(fancade, project)
          ) * 100
        : +calculateAerialPlatformsToolsKPI(fancade, project) * 100
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " %",
    ],
  });
  rows.push({
    name: i18n.t("tablesVoices.report.kpi.scaffolding"),
    values: [
      (benchmark
        ? +calculateBenchmark(
            +calculateScaffoldingsToolsKPI(benchmark, project),
            +calculateScaffoldingsToolsKPI(fancade, project)
          ) * 100
        : +calculateScaffoldingsToolsKPI(fancade, project) * 100
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " %",
    ],
  });
  rows.push({
    name: i18n.t("tablesVoices.report.kpi.buildingSiteDuration"),
    values: [
      (benchmark
        ? calculateBenchmark(
            +calculateBuildingSiteDurationKPI(benchmark, project),
            +calculateBuildingSiteDurationKPI(fancade, project)
          )
        : calculateBuildingSiteDurationKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " giorni",
    ],
  });
  rows.push({
    name: i18n.t("tablesVoices.report.kpi.buildingSiteDurationPerM2"),
    values: [
      (benchmark
        ? calculateBenchmark(
            +calculateBuildingSiteDurationPerM2KPI(benchmark, project),
            +calculateBuildingSiteDurationPerM2KPI(fancade, project)
          )
        : calculateBuildingSiteDurationPerM2KPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " Gg/m²",
    ],
  });
  rows.push({
    name: i18n.t("tablesVoices.report.kpi.maintenance"),
    values: [
      (benchmark
        ? calculateBenchmark(
            +calculateMaintenanceKPI(benchmark, project),
            +calculateMaintenanceKPI(fancade, project)
          )
        : calculateMaintenanceKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " €/m²",
    ],
  });
  rows.push({
    name: i18n.t("tablesVoices.report.kpi.ordinaryMaintenance"),
    values: [
      (benchmark
        ? +calculateBenchmark(
            +calculateOrdinaryMaintenanceMaintenanceKPI(benchmark, project),
            +calculateOrdinaryMaintenanceMaintenanceKPI(fancade, project)
          ) * 100
        : +calculateOrdinaryMaintenanceMaintenanceKPI(fancade, project) * 100
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " %",
    ],
  });
  rows.push({
    name: i18n.t("tablesVoices.report.kpi.extraordinaryMaintenance"),
    values: [
      (benchmark
        ? +calculateBenchmark(
            +calculateExtraordinaryMaintenanceMaintenanceKPI(
              benchmark,
              project
            ),
            +calculateExtraordinaryMaintenanceMaintenanceKPI(fancade, project)
          ) * 100
        : +calculateExtraordinaryMaintenanceMaintenanceKPI(fancade, project) *
          100
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " %",
    ],
  });
  rows.push({
    name: i18n.t("tablesVoices.report.kpi.cleaning"),
    values: [
      (benchmark
        ? +calculateBenchmark(
            +calculateCleaningMaintenanceKPI(benchmark, project),
            +calculateCleaningMaintenanceKPI(fancade, project)
          ) * 100
        : +calculateCleaningMaintenanceKPI(fancade, project) * 100
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " %",
    ],
  });
  rows.push({
    name: i18n.t("tablesVoices.report.kpi.endOfLife"),
    values: [
      (benchmark
        ? calculateBenchmark(
            +calculateEndOfLifeKPI(benchmark, project),
            +calculateEndOfLifeKPI(fancade, project)
          )
        : calculateEndOfLifeKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " €/m²",
    ],
  });
  rows.push({
    name: i18n.t("tablesVoices.report.kpi.recyclingProductionRatio"),
    values: [
      (benchmark
        ? +calculateBenchmark(
            +calculateRecyclingProductionKPI(benchmark, project),
            +calculateRecyclingProductionKPI(fancade, project)
          ) * 100
        : +calculateRecyclingProductionKPI(fancade, project) * 100
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " %",
    ],
  });
  rows.push({
    name: i18n.t("tablesVoices.report.kpi.materialsTotal"),
    values: [
      (benchmark
        ? calculateBenchmark(
            +calculateMaterialsProductionAndConstructionKPI(benchmark, project),
            +calculateMaterialsProductionAndConstructionKPI(fancade, project)
          )
        : calculateMaterialsProductionAndConstructionKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " €",
    ],
  });
  rows.push({
    name: i18n.t("tablesVoices.report.kpi.materialsCost"),
    values: [
      (benchmark
        ? calculateBenchmark(
            +calculateMaterialsCostKPI(benchmark, project),
            +calculateMaterialsCostKPI(fancade, project)
          )
        : calculateMaterialsCostKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " €/m²",
    ],
  });
  rows.push({
    name: i18n.t("tablesVoices.report.kpi.laborTotal"),
    values: [
      (benchmark
        ? calculateBenchmark(
            +calculateLaborProductionAndConstructionKPI(benchmark, project),
            +calculateLaborProductionAndConstructionKPI(fancade, project)
          )
        : calculateLaborProductionAndConstructionKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " €",
    ],
  });
  rows.push({
    name: i18n.t("tablesVoices.report.kpi.laborCost"),
    values: [
      (benchmark
        ? calculateBenchmark(
            +calculateLaborCostKPI(benchmark, project),
            +calculateLaborCostKPI(fancade, project)
          )
        : calculateLaborCostKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " €/m²",
    ],
  });
  rows.push({
    name: i18n.t("tablesVoices.report.kpi.technicalParameters"),
    values: [""],
  });
  rows.push({
    name: i18n.t("tablesVoices.report.kpi.surface"),
    values: [
      (benchmark
        ? calculateBenchmark(
            +calculateFancadeKPI(benchmark, project),
            +calculateFancadeKPI(fancade, project)
          )
        : calculateFancadeKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " m²",
    ],
  });
  rows.push({
    name: i18n.t("tablesVoices.report.kpi.wwr"),
    values: [
      (benchmark
        ? +calculateBenchmark(
            +calculateWWRKPI(benchmark, project),
            +calculateWWRKPI(fancade, project)
          ) * 100
        : +calculateWWRKPI(fancade, project) * 100
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " %",
    ],
  });
  rows.push({
    name: i18n.t("tablesVoices.report.kpi.uparete"),
    values: [
      (benchmark
        ? calculateBenchmark(
            +calculateUwallKPI(benchmark, project),
            +calculateUwallKPI(fancade, project)
          )
        : calculateUwallKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " W/m²K",
    ],
  });
  rows.push({
    name: i18n.t("tablesVoices.report.kpi.ufinestra"),
    values: [
      (benchmark
        ? calculateBenchmark(
            +calculateUwindowKPI(benchmark, project),
            +calculateUwindowKPI(fancade, project)
          )
        : calculateUwindowKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " W/m²K",
    ],
  });
  rows.push({
    name: i18n.t("tablesVoices.report.kpi.ufacciata"),
    values: [
      (benchmark
        ? calculateBenchmark(
            +calculateUFancadeAverageKPI(benchmark, project),
            +calculateUFancadeAverageKPI(fancade, project)
          )
        : calculateUFancadeAverageKPI(fancade, project)
      ).toLocaleString("cs-CZ", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " W/m²K",
    ],
  });

  return rows;
};

export const getKpiComparisonRows = (
  rows1: any[],
  rows2: any[]
): TableRow[] => {
  let toReturn: any[] = [];

  rows1.map((row, index) => toReturn.push({ ...row }));

  toReturn.map((row, index) => {
    toReturn[index].values = [];
    if (
      rows1.length > index &&
      rows1[index].values &&
      rows1[index].values.length > 0
    ) {
      //@ts-ignore
      toReturn[index].values.push(rows1[index].values[0]);
    } else {
      //@ts-ignore
      toReturn[index].values.push(" ");
    }
    if (
      rows2.length > index &&
      rows2[index].values &&
      rows2[index].values.length > 0
    ) {
      //@ts-ignore
      toReturn[index].values.push(rows2[index].values[0]);
    } else {
      //@ts-ignore
      toReturn[index].values.push(" ");
    }
  });
  return toReturn;
};

export const getActualizationComparisonRows = (
  rows1: any[],
  rows2: any[]
): TableRow[] => {
  let toReturn: any[] = [];

  if (rows1.length > 0) {
    rows1.map((row, index) => toReturn.push({ ...row }));
  } else {
    rows2.map((row, index) => toReturn.push({ ...row }));
  }
  toReturn.map((row, index) => {
    toReturn[index].values = [];
    //Row 1 values
    if (
      rows1.length > index &&
      rows1[index].values &&
      rows1[index].values.length > 0
    ) {
      toReturn[index].values.push(rows1[index].values[0]);
    } else {
      toReturn[index].values.push(" ");
    }
    if (
      rows1.length > index &&
      rows1[index].values &&
      rows1[index].values.length > 1
    ) {
      toReturn[index].values.push(rows1[index].values[1]);
    } else {
      toReturn[index].values.push(" ");
    }
    //Row 2 values
    if (
      rows2.length > index &&
      rows2[index].values &&
      rows2[index].values.length > 0
    ) {
      toReturn[index].values.push(rows2[index].values[0]);
    } else {
      toReturn[index].values.push(" ");
    }
    if (
      rows2.length > index &&
      rows2[index].values &&
      rows2[index].values.length > 1
    ) {
      toReturn[index].values.push(rows2[index].values[1]);
    } else {
      toReturn[index].values.push(" ");
    }
  });
  return toReturn;
};
