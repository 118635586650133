import React, { useCallback } from "react";
import {
  Button,
  Flex,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../../../../hooks/useStores";
import { Redirect, useParams } from "react-router-dom";
import { EndOfLifeForm } from "./EndOfLifeForm";
import { Fancade, Project } from "../../../../../../types";

import styled from "styled-components";
import { transparentize } from "polished";
import theme from "../../../../../../theme/theme";
import { AiFillEdit, AiFillFolder } from "react-icons/ai";
import {
  calculateBenchmark,
  calculateDemolitionAndDisassemblyTotal,
  calculateEndOfLifeTransportTotal,
  calculateInstallationTotal,
  calculateProductionTotal,
  calculateRecyclingTotalRevenue,
  calculateSecurityTotal,
  calculateTransportTotal,
  toFancadeAPI,
} from "../../../../../../services/utils";
import { DetailsTable } from "../../DetailsTable";
import { DeleteAlert } from "../../../../../Alert";
import { ChangeFancadeNameModal } from "../../../../../ChangeFancadeNameModal";

interface RouteParams {
  projectId: string;
}

type Props = {
  formikProps: any;
  tabIndex: any;
  setTabIndex: any;
  isBenchmark: boolean;
};
export const EndOfLife: React.FC<Props> = observer((props) => {
  const { t } = useTranslation();
  const { session, ui, projects } = useStores();
  const { projectId } = useParams<RouteParams>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [deleteFancadeOpen, setDeleteFancadeOpen] = React.useState(false);
  const [fancadeToUpdate, setFancadeToUpdate] = React.useState<number>(0);
  const [update, setUpdate] = React.useState(false);
  ui.setActiveScreen("projects");

  const handleTabsChange = (index: number) => {
    props.setTabIndex(index);
  };

  const currentProject: Project = props.formikProps.values;

  const addFancade = useCallback(() => {
    if (!currentProject.fancades) currentProject.fancades = [];
    currentProject.fancades.push(projects.getEmptyFancade(+projectId));
    if (currentProject && currentProject.fancades) {
      props.setTabIndex(currentProject.fancades.length - 1);
    }
    setUpdate(!update);
  }, [props.tabIndex, projects, currentProject, props.formikProps, update]);

  const deleteFancade = useCallback(
    (fancade: Fancade, index: number) => {
      if (fancade.id != -1) projects.deleteFancade(toFancadeAPI(fancade));
      props.formikProps.values.fancades.splice(index, 1);
      if (props.tabIndex == index && props.tabIndex > 0) {
        props.setTabIndex(props.tabIndex - 1);
      }
    },
    [
      props.tabIndex,
      projects,
      currentProject,
      props.formikProps.values.fancades,
    ]
  );

  const getOneFancadeKPIsRows = (fancade: Fancade) => {
    if (fancade && fancade.maintenance) {
      let rows: any[] = [];

      let demolitionAndDisassembly =
        calculateDemolitionAndDisassemblyTotal(fancade);
      let transport = calculateEndOfLifeTransportTotal(fancade);
      let recycling = calculateRecyclingTotalRevenue(fancade);
      let surface = 0;

      let kpi1 = 0;
      let kpi2 = 0;
      let kpi3 = 0;

      if (fancade.fancadeTechnicalProject.surface)
        surface = fancade.fancadeTechnicalProject.surface;
      if (surface != 0)
        kpi1 =
          +(+demolitionAndDisassembly + +transport + +recycling) / +surface;
      if (
        fancade.endOfLife.surfaceDemolitionOrDisassembly &&
        fancade.endOfLife.surfaceDemolitionOrDisassembly != 0
      )
        kpi2 =
          +(+demolitionAndDisassembly + +transport + +recycling) /
          +fancade.endOfLife.surfaceDemolitionOrDisassembly;

      const installationSecurity =
        +calculateInstallationTotal(fancade) + +calculateSecurityTotal(fancade);
      if (
        +calculateProductionTotal(fancade) +
          +calculateTransportTotal(fancade) +
          +installationSecurity !=
        0
      )
        kpi3 =
          +recycling /
          +(
            +calculateProductionTotal(fancade) +
            +calculateTransportTotal(fancade) +
            +installationSecurity
          );

      rows = [
        {
          name: t(
            "formLabels.details.endOfLife.totalDemolitionAndDisassemblyPrice"
          ),
          values: [
            demolitionAndDisassembly.toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " €",
          ],
        },
        {
          name: t("formLabels.details.endOfLife.totalTransportPrice"),
          values: [
            transport.toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " €",
          ],
        },
        {
          name: t("formLabels.details.endOfLife.totalRecyclingRevenueKpi"),
          values: [
            (-1 * +recycling).toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " €",
          ],
        },
        {
          name: t("formLabels.details.endOfLife.kpiSurface"),
          values: [
            (+surface).toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " m²",
          ],
        },
        {
          name: t("formLabels.details.endOfLife.kpi1"),
          values: [
            kpi1.toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " €/m²",
          ],
        },
        {
          name: t("formLabels.details.endOfLife.kpi2"),
          values: [
            kpi2.toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " €/m²",
          ],
        },
        {
          name: t("formLabels.details.endOfLife.kpi3"),
          values: [
            (kpi3 * 100).toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " %",
          ],
        },
      ];

      return rows;
    }
    return [];
  };

  const getBenchmarkKPIRows = (
    project: Project,
    currentFancadeIndex: number
  ) => {
    let rows: any[] = [];

    let fancade;
    let benchmark;

    if (project && project.fancades && project?.fancades[currentFancadeIndex])
      fancade = project?.fancades[currentFancadeIndex];

    if (project && project.fancades && project?.fancades[0])
      benchmark = project?.fancades[0];

    let demolitionAndDisassembly = 0;
    let transport = 0;
    let recycling = 0;
    let surface = 0;

    let kpi1 = 0;
    let kpi2 = 0;
    let kpi3 = 0;

    if (fancade) {
      transport = calculateEndOfLifeTransportTotal(fancade);
      recycling = calculateRecyclingTotalRevenue(fancade);
      if (fancade.fancadeTechnicalProject.surface)
        surface = fancade.fancadeTechnicalProject.surface;

      if (surface != 0)
        kpi1 =
          +(+demolitionAndDisassembly + +transport + +recycling) / +surface;
      if (
        fancade.endOfLife.surfaceDemolitionOrDisassembly &&
        fancade.endOfLife.surfaceDemolitionOrDisassembly != 0
      )
        kpi2 =
          +(+demolitionAndDisassembly + +transport + +recycling) /
          +fancade.endOfLife.surfaceDemolitionOrDisassembly;

      const installationSecurity =
        +calculateInstallationTotal(fancade) + +calculateSecurityTotal(fancade);
      if (
        +calculateProductionTotal(fancade) +
          +calculateTransportTotal(fancade) +
          +installationSecurity !=
        0
      )
        kpi3 =
          +recycling /
          +(
            +calculateProductionTotal(fancade) +
            +calculateTransportTotal(fancade) +
            +installationSecurity
          );
    }

    let demolitionAndDisassemblyBenchmark = 0;
    let transportBenchmark = 0;
    let recyclingBenchmark = 0;
    let surfaceBenchmark = 0;

    let kpi1Benchmark = 0;
    let kpi2Benchmark = 0;
    let kpi3Benchmark = 0;

    if (benchmark) {
      transportBenchmark = calculateEndOfLifeTransportTotal(benchmark);
      recyclingBenchmark = calculateRecyclingTotalRevenue(benchmark);
      if (benchmark.fancadeTechnicalProject.surface)
        surfaceBenchmark = benchmark.fancadeTechnicalProject.surface;

      if (surface != 0)
        kpi1Benchmark =
          +(
            +demolitionAndDisassemblyBenchmark +
            +transportBenchmark +
            +recyclingBenchmark
          ) / +surfaceBenchmark;
      if (
        benchmark.endOfLife.surfaceDemolitionOrDisassembly &&
        benchmark.endOfLife.surfaceDemolitionOrDisassembly != 0
      )
        kpi2 =
          +(
            +demolitionAndDisassemblyBenchmark +
            +transportBenchmark +
            +recyclingBenchmark
          ) / +benchmark.endOfLife.surfaceDemolitionOrDisassembly;

      const installationSecurityBenchmark =
        +calculateInstallationTotal(benchmark) +
        +calculateSecurityTotal(benchmark);
      if (
        +calculateProductionTotal(benchmark) +
          +calculateTransportTotal(benchmark) +
          +installationSecurityBenchmark !=
        0
      )
        kpi3Benchmark =
          +recycling /
          (+calculateProductionTotal(benchmark) +
            +calculateTransportTotal(benchmark) +
            +installationSecurityBenchmark);
    }

    rows = [
      {
        name: t("formLabels.details.endOfLife.kpi1"),
        values: [
          calculateBenchmark(+kpi1Benchmark, +kpi1).toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " €/m²",
        ],
      },
      {
        name: t("formLabels.details.endOfLife.kpi2"),
        values: [
          calculateBenchmark(+kpi2Benchmark, +kpi2).toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " €/m²",
        ],
      },
      {
        name: t("formLabels.details.endOfLife.kpi3"),
        values: [
          calculateBenchmark(+kpi3Benchmark, +kpi3).toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " %",
        ],
      },
    ];

    return rows;
  };

  const getAllFancadesKPIRows = (project: Project) => {
    let rows: any[] = [];

    let demolitionAndDisassembly = 0;
    let transport = 0;
    let recycling = 0;
    let surface = 0;

    let production = 0;
    let transportTotal = 0;
    let installationSecurity = 0;
    let demolitionSurface = 0;

    let kpi1 = 0;
    let kpi2 = 0;
    let kpi3 = 0;

    project.fancades?.map((fancade, index) => {
      if (fancade) {
        transport += +calculateEndOfLifeTransportTotal(fancade);
        recycling += +calculateRecyclingTotalRevenue(fancade);
        if (fancade.fancadeTechnicalProject.surface)
          surface += +fancade.fancadeTechnicalProject.surface;

        production += +calculateProductionTotal(fancade);
        transportTotal += calculateTransportTotal(fancade);
        installationSecurity +=
          +calculateInstallationTotal(fancade) +
          +calculateSecurityTotal(fancade);

        if (fancade.endOfLife.surfaceDemolitionOrDisassembly)
          demolitionSurface +=
            +fancade.endOfLife.surfaceDemolitionOrDisassembly;
      }
    });

    if (surface != 0)
      kpi1 = (+demolitionAndDisassembly + +transport + +recycling) / +surface;
    if (demolitionSurface != 0)
      kpi2 =
        +(+demolitionAndDisassembly + +transport + +recycling) /
        +demolitionSurface;
    if (+(+production + +transportTotal + +installationSecurity) != 0)
      kpi3 =
        +recycling / +(+production + +transportTotal + +installationSecurity);

    //Store project KPIs
    project.kpiEndOfLife = {
      totalDisassemblyPrice: demolitionAndDisassembly,
      totalTransportPrice: transport,
      recyclingIncomings: recycling,
      //totalFancadeSurface
      disassemblyOnBuiltSurfaceKPI: kpi1,
      disassemblyOnDisusedtSurfaceKPI: kpi2,
      incomingsOnTotalPriceKPI: kpi3,
    };
    rows = [
      {
        name: t(
          "formLabels.details.endOfLife.totalDemolitionAndDisassemblyPrice"
        ),
        values: [
          demolitionAndDisassembly.toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " €",
        ],
      },
      {
        name: t("formLabels.details.endOfLife.totalTransportPrice"),
        values: [
          transport.toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " €",
        ],
      },
      {
        name: t("formLabels.details.endOfLife.totalRecyclingRevenueKpi"),
        values: [
          -1 *
            +recycling.toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) +
            " €",
        ],
      },
      {
        name: t("formLabels.details.endOfLife.kpiSurface"),
        values: [
          (+surface).toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " m²",
        ],
      },
      {
        name: t("formLabels.details.endOfLife.kpi1"),
        values: [
          kpi1.toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " €/m²",
        ],
      },
      {
        name: t("formLabels.details.endOfLife.kpi2"),
        values: [
          kpi2.toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " €/m²",
        ],
      },
      {
        name: t("formLabels.details.endOfLife.kpi3"),
        values: [
          (kpi3 * 100).toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " %",
        ],
      },
    ];

    return rows;
  };

  if (session.user)
    return (
      <Flex w={"100%"}>
        <ChangeFancadeNameModal
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={(values: any) => {
            if (currentProject && currentProject.fancades)
              currentProject.fancades[fancadeToUpdate].customName =
                values.customName;
            onClose();
          }}
        />
        <Tabs
          index={props.tabIndex}
          onChange={handleTabsChange}
          variant={"enclosed"}
          w={"100%"}
        >
          <TabList>
            {currentProject?.fancades?.map((fancade, index) => (
              <Tab alignItems={"center"}>
                <DeleteAlert
                  title={t("warnings.deleteFancadeTitle")}
                  body={t("warnings.deleteFancade")}
                  isOpen={deleteFancadeOpen}
                  setIsOpen={setDeleteFancadeOpen}
                  cancelText={t("warnings.cancel")}
                  continueText={t("warnings.confirm")}
                  onContinue={async () => {
                    deleteFancade(fancade, index);
                  }}
                />
                <Text color={theme.colors.darkGrey2}>
                  {fancade.customName
                    ? currentProject.isBenchmark && index == 0
                      ? t("tabs.details.fancadeTechnicalProject.benchmark") +
                        fancade.customName
                      : fancade.customName
                    : currentProject.isBenchmark && index == 0
                    ? t("tabs.details.fancadeTechnicalProject.firstTab")
                    : t("tabs.details.fancadeTechnicalProject.tabsAfterFirst") +
                      (index + 1)}
                </Text>
                <AiFillEdit
                  style={{ marginLeft: "0.3rem" }}
                  onClick={() => {
                    setFancadeToUpdate(index);
                    onOpen();
                  }}
                />
                <Text
                  onClick={() => setDeleteFancadeOpen(true)}
                  color={theme.colors.euracOrange}
                  paddingLeft={"0.5rem"}
                >
                  <strong>x</strong>
                </Text>
              </Tab>
            ))}
            <Button variant={"ghost"} onClick={addFancade}>
              <Text color={theme.colors.euracOrange}>+</Text>
            </Button>
          </TabList>
          <TabPanels>
            {currentProject?.fancades?.map((fancade, index) => (
              <TabPanel>
                <EndOfLifeForm
                  fancadeNumber={index + 1}
                  fancadeId={fancade.id}
                  fancadeType={fancade?.fancadeTechnicalProject?.type}
                  formikProps={props.formikProps}
                />
                <Stack
                  w={"100%"}
                  fontSize={"md"}
                  spacing={"2rem"}
                  paddingTop={"2rem"}
                >
                  <DetailsTable
                    columnsTitles={[t("formLabels.details.endOfLife.kpiTitle")]}
                    rows={getOneFancadeKPIsRows(fancade)}
                  />
                  {index > 0 && props.isBenchmark && (
                    <DetailsTable
                      columnsTitles={[
                        t("formLabels.details.endOfLife.benchmarkTitle"),
                      ]}
                      rows={getBenchmarkKPIRows(currentProject, index)}
                    />
                  )}

                  {currentProject.fancades &&
                    currentProject.fancades.length > 1 && (
                      <DetailsTable
                        columnsTitles={[
                          t("formLabels.details.endOfLife.kpiAllTitle"),
                        ]}
                        rows={getAllFancadesKPIRows(currentProject)}
                      />
                    )}
                </Stack>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Flex>
    );
  else return <Redirect to={"/"} />;
});
