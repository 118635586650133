import { makeAutoObservable } from "mobx";
import { isJSDocNullableType } from "typescript";
import api from "../services/api";
import {
  Stores,
  Project,
  Fancade,
  OpaqueWindow,
  GeometricDetail,
  SystemsIntegration,
  Hardware,
  Packaging,
  BuildingSiteMaterial,
  FancadeAPI,
  Pair,
  Implant,
  Category,
} from "./../types";

export class ProjectsStore {
  stores: Stores;
  isFetchingProjects = false;
  selectedProjectsIds = new Set<number>();
  filter = "";

  constructor(stores: Stores) {
    this.stores = stores;
    makeAutoObservable(this);
  }

  projects: Project[] = [];
  categories: Category[] = [];

  setFilter = (filter: string) => {
    this.filter = filter;
  };

  fetchProjects = async () => {
    this.isFetchingProjects = true;
    try {
      const retrievedProjects: Project[] = await api.getCurrentUserProjects();
      this.projects = retrievedProjects;
    } catch (err) {
      throw err;
    } finally {
      this.isFetchingProjects = false;
    }
  };

  handleSelectedProjectsIds = (id: number) => {
    if (this.selectedProjectsIds.has(id)) {
      this.selectedProjectsIds.delete(id);
    } else {
      this.selectedProjectsIds.add(id);
    }
  };

  getSelectedProjectListElements = (): any[] => {
    let selectedProjectsIdsArray = Array.from(this.selectedProjectsIds);
    let toReturn: any[] = [];
    selectedProjectsIdsArray.map((id, index) => {
      const toFind = this.projects.find((element) => {
        return element.id == id;
      });

      if (toFind) {
        toReturn.push({ id: toFind.id, name: toFind.name });
      }
    });

    return toReturn;
  };

  getEmptyProject = (createdById: number) => {
    return {
      id: null,
      name: "",
      type: "",
      useDestination: "",
      constructionYear: "",
      location: "",
      author: "",
      description: "",
      certification: "",
      isBenchmark: false,
      createdById: createdById,
      categoryId: null,
    };
  };

  addProject = async (project: Project) => {
    const temp = await api.createProject(project);
    this.projects.push(temp);
    return temp;
  };

  updateProject = async (project: Project) => {
    const temp = await api.updateProject(project);
    const toFind = this.projects.find((element) => {
      return element.id == project.id;
    });
    if (toFind) this.projects[this.projects.indexOf(toFind)] = temp;
    return temp;
  };

  duplicateProject = async (project: Project) => {
    //Create duplicated project
    let temp = await api.createProject(project);

    //Update project by adding the source project fancades'
    if (project.fancades) {
      project.fancades.map((fancade, index) => {
        if (!temp.fancades) temp.fancades = [];
        temp.fancades.push(JSON.parse(JSON.stringify(fancade)));
        temp.fancades[index].id = -1;
      });
      temp = await api.updateProject(temp);
    }
    this.projects.push(temp);
    return temp;
  };

  updateProjectsCategory = (projectIds: number[], categoryId: number) => {
    projectIds.map(async (id, index) => {
      const toFind = this.projects.find((element) => {
        return element.id == id;
      });
      if (
        toFind &&
        this.stores.session.user &&
        toFind.createdById == parseInt(this.stores.session.user.id)
      ) {
        let temp = { ...toFind };
        temp.categoryId = categoryId;
        temp = await api.updateProject(temp);
        this.projects[this.projects.indexOf(toFind)] = temp;
      }
    });
  };

  getProjectFancades = async (projectId: number) => {
    let fancades = [];
    try {
      fancades = await api.getProjectFancades(projectId);
      let tempProject = this.projects.find(
        (project) => project.id == projectId
      );
      if (tempProject) {
        tempProject.fancades = fancades;
      }
    } catch (err) {
      throw err;
    } finally {
      this.isFetchingProjects = false;
    }

    return fancades;
  };

  deleteProject = async (project: Project) => {
    const temp = await api.deleteProject(project);
    const toFind = this.projects.find((element) => {
      return element.id == project.id;
    });
    if (toFind) this.projects.splice(this.projects.indexOf(toFind), 1);
    return temp;
  };

  deleteSelectedProjects = async () => {
    let selectedProjectsIdsArray = Array.from(this.selectedProjectsIds);
    selectedProjectsIdsArray.map(async (id, index) => {
      const toFind = this.projects.find((element) => {
        return element.id == id;
      });

      if (toFind) {
        const temp = await api.deleteProject(toFind);
        this.projects.splice(this.projects.indexOf(toFind), 1);
      }
    });
  };

  getEmptyFancade = (projectId: number) => {
    const fancade: Fancade = {
      id: -1,
      fancadeTechnicalProject: {
        type: null,
        surface: null,
        //Involucro opaco
        windows: [],
        windowsTotalPrice: null,
        windowsTotalPriceCalculation: null,
        externalCladdingUnitsNumber: null, //rivestimento
        externalCladdingUnitsSurface: null, //rivestimento
        totalExternalCladdingSurface: null, //rivestimento
        insulationSurface: null, //isolamento
        supportingElementsQuantity: null, //elementi portanti
        prefabricatedModulesQuantity: null,
        modulesSurface: null,
        opaqueAnchoringsNumber: null, //ancoraggi
        opaqueFixingsNumber: null, //fissaggi
        //Involucro opaco production
        externalCladdingTotalPrice: null,
        externalCladdingTotalPriceCalculation: null,
        externalCladdingUnitPrice: null,

        insulationTotalPrice: null,
        insulationTotalPriceCalculation: null,
        insulationUnitPrice: null,
        supportingElementsTotalPrice: null,
        supportingElementsTotalPriceCalculation: null,
        supportingElementsUnitPrice: null,

        prefabricatedModulesTotalPrice: null,
        prefabricatedModulesTotalPriceCalculation: null,
        prefabricatedModulesUnitPrice: null,

        opaqueAnchoringTotalPrice: null,
        opaqueAnchoringTotalPriceCalculation: null,
        opaqueAnchoringUnitPrice: null,

        opaqueFixingsTotalPrice: null,
        opaqueFixingsTotalPriceCalculation: null,
        opaqueFixingsUnitPrice: null,

        //Involucro trasparente
        transparentTotalPrice: null,
        transparentTotalPriceCalculation: null,
        glazedCellsNumber: null,
        glazedCellsSurface: null,
        doubleGlazingQuantity: null,
        doubleGlazingSurface: null,
        mainFrameHeightPerUnit: null,
        mainFrameWidthPerUnit: null,
        totalMainFrameLength: null,
        totalGasketLength: null,
        opaqueSpandrelPanelsQuantity: null,
        opaqueSpandrelPanelsSurface: null,
        transparentAnchoringsNumber: null, //ancoraggi
        transparentFixingsNumber: null, //fissaggi

        //Involucro trasparente production
        glazedCellsUnitPrice: null,
        totalGlazedCellsPrice: null,
        totalGlazedCellsPriceCalculation: null,
        totalMainFramePrice: null,
        totalMainFramePriceCalculation: null,
        mainFrameUnitPrice: null,
        totalDoubleGlazingPrice: null,
        totalDoubleGlazingPriceCalculation: null,
        doubleGlazingUnitPrice: null,
        gasketUnitPrice: null,
        totalGasketPrice: null,
        totalGasketPriceCalculation: null,
        opaqueSpandrelModulesUnitPrice: null,
        totalOpaqueSpandrelModulesPrice: null,
        totalOpaqueSpandrelModulesPriceCalculation: null,
        transparentAnchoringsUnitPrice: null,
        totalTransparentAnchoringsPrice: null,
        totalTransparentAnchoringsPriceCalculation: null,
        totalTransparentFixingsNumberPrice: null,
        totalTransparentFixingsNumberPriceCalculation: null,
        transparentFixingsNumberUnitPrice: null,

        //Systems Integration production
        totalSystemsIntegrationPrice: null,
        totalSystemsIntegrationPriceCalculation: null,

        shieldingsUnitPrice: null,
        totalShieldingsPrice: null,
        totalShieldingsPriceCalculation: null,

        ventilationSystemsUnitPrice: null,
        totalVentilationSystemsPrice: null,
        totalVentilationSystemsPriceCalculation: null,

        airConditioningSystemsUnitPrice: null,
        totalAirConditioningSystemsPrice: null,
        totalAirConditioningSystemsPriceCalculation: null,

        thermicSolarPanelsUnitPrice: null,
        totalThermicSolarPanelsPrice: null,
        totalThermicSolarPanelsPriceCalculation: null,

        photovoltaicModulesUnitPrice: null,
        totalPhotovoltaicModulesPrice: null,
        totalPhotovoltaicModulesPriceCalculation: null,

        //Scrap production use getScrapUnitPrice(varName, string)
        totalScrapPrice: null,
        totalScrapPriceCalculation: null,

        doubleGlazingScrapQuantity: null,
        totalDoubleGlazingScrapPrice: null,
        totalDoubleGlazingScrapPriceCalculation: null,

        mainFrameOrLoomScrapQuantity: null,
        totalMainFrameOrLoomScrapPrice: null,
        totalMainFrameOrLoomScrapPriceCalculation: null,

        gasketScrapQuantity: null,
        totalGasketScrapPrice: null,
        totalGasketScrapPriceCalculation: null,

        opaqueWindowsScrapQuantity: null, //Solo opache
        totalOpaqueWindowsScrapPrice: null, //Solo opache
        totalOpaqueWindowsScrapPriceCalculation: null, //Solo opache

        opaqueExternalCladdingScrapQuantity: null, //Solo opache
        totalOpaqueExternalCladdingScrapPrice: null, //Solo opache
        totalOpaqueExternalCladdingScrapPriceCalculation: null, //Solo opache

        opaqueInsulationScrapQuantity: null, //Solo opache
        totalOpaqueInsulationScrapPrice: null, //Solo opache
        totalOpaqueInsulationScrapPriceCalculation: null, //Solo opache

        opaqueSupportingElementsScrapQuantity: null, //Solo opache
        totalOpaqueSupportingElementsScrapPrice: null, //Solo opache
        totalOpaqueSupportingElementsScrapPriceCalculation: null, //Solo opache

        transparentGlazedCellScrapQuantity: null, //Solo trasparenti
        totalTransparentGlazedCellScrapPrice: null, //Solo trasparenti
        totalTransparentGlazedCellScrapPriceCalculation: null, //Solo trasparenti

        transparentOpaqueSpandrelModulesQuantity: null, //Solo trasparenti
        totalTransparentOpaqueSpandrelModulesPrice: null, //Solo trasparenti
        totalTransparentOpaqueSpandrelModulesPriceCalculation: null, //Solo trasparenti

        fixingsScrapQuantity: null,
        totalFixingsScrapPrice: null,
        totalFixingsScrapPriceCalculation: null,

        anchoringsScrapQuantity: null,
        totalAnchoringsScrapPrice: null,
        totalAnchoringsScrapPriceCalculation: null,

        accessoriesScrap: null,

        //Altro
        shieldingSystemsNumber: null,
        ventilationDevicesNumber: null,
        airConditioningDevicesNumber: null,
        thermicSolarPanelsNumber: null,
        windowOpaqueSurfaceRatio: null,
        thermicSolarPanelsSurface: null,
        thermicSolarPanelsUnitSurface: null,
        photovoltaicModulesSurface: null,
        photovoltaicModulesUnitSurface: null,
        photovoltaicModulesNumber: null,
        opaqueUValue: null,
        transparentUValue: null,
        windowGlassGValue: null,
        fancadeGlassGValue: null,
        prefabricationLevel: null,
        geometricDetails: null,
        systemsIntegration: 1 | 0, //Integrazione impiantistica
        systemsSurfacePercentage: null,
        systemsIntegrations: [],
        customFields: [],
      },
      fancadeProject: {
        totalDesignPrice: null,
        totalDesignPriceCalculation: null,
        technicalProject: null,
        structuralSismicProject: null,
        lightingTechnicalProject: null,
        ventilationProject: null,
        acusticProject: null,
        airQualityCheck: null,
        constructionPrinceAnalysis: null,
        firePreventionDesign: null,
        buildingSiteManagement: null,
        buildingSiteSafety: null,
        energeticPerformanceAnalysis: null,
        competitionProject: null,
        maintenancePlan: null,
        userManual: null,
      },
      fancadeKpiFancadeProject: {
        totalDesignPrice: null,
        totalFancadeSurface: null,
        totalDesignPriceKPI: null,
      },
      fancadeProduction: {
        totalProductionPrice: null,
        totalProductionPriceCalculation: null,
        windowsTotalPrice: null,
        windowsTotalPriceCalculation: null,
        totalMaterialsPrice: null,
        totalMaterialsPriceCalculation: null,
        totalScrapPrice: null,
        totalScrapPriceCalculation: null,
        totalSystemIntegrationPrice: null,
        totalSystemIntegrationPriceCalculation: null,

        //Labor
        totalLaborPrice: null,
        totalLaborPriceCalculation: null,

        totalBaseLaborPrice: null,
        totalBaseLaborPriceCalculation: null,
        baseLaborHours: null,
        baseLaborHourPrice: null,

        totalSkilledLaborPrice: null,
        totalSkilledLaborPriceCalculation: null,
        skilledLaborHours: null,
        skilledLaborHourPrice: null,

        hardware: null,
        hardwareTotalPrice: null,
        hardwareTotalPriceCalculation: null,
      },
      transport: {
        totalTransportPrice: null,
        totalTransportPriceCalculation: null,

        //Packaging
        totalPackagingPrice: null,
        totalPackagingPriceCalculation: null,
        packagings: [],

        //Transport
        totalTransportPartPrice: null,
        totalTransportPartPriceCalculation: null,
        workSiteProductionSiteDistance: null,
        transportPartUnitPrice: null,
      },
      buildingSite: {
        //-------------Fancade Installation-------------
        totalInstallationPrice: null,
        totalInstallationPriceCalculation: null,

        totalBuildingSiteMaterialsPrice: null,
        totalBuildingSiteMaterialsPriceCalculation: null,
        buildingSiteMaterials: [],

        buildingSiteDuration: null,

        //Labor
        totalLaborPrice: null,
        totalLaborPriceCalculation: null,

        totalBaseLaborPrice: null,
        totalBaseLaborPriceCalculation: null,
        baseLaborHours: null,
        baseLaborHourPrice: null,

        totalSkilledLaborPrice: null,
        totalSkilledLaborPriceCalculation: null,
        skilledLaborHours: null,
        skilledLaborHourPrice: null,

        //Tools
        totalToolsPrice: null,
        totalToolsPriceCalculation: null,

        totalTowerCranePrice: null,
        totalTowerCranePriceCalculation: null,
        towerCraneHours: null,
        towerCraneHourPrice: null,

        totalElevatorPlatformPrice: null,
        totalElevatorPlatformPriceCalculation: null,
        elevatorPlatformHours: null,
        elevatorPlatformHourPrice: null,

        totalAerialPlatformPrice: null,
        totalAerialPlatformPriceCalculation: null,
        aerialPlatformHours: null,
        aerialPlatformHourPrice: null,

        totalScaffoldingPrice: null,
        totalScaffoldingPriceCalculation: null,
        scaffoldingSurface: null,
        scaffoldingSurfaceUnitPrice: null,
        scaffoldingHours: null,
        scaffoldingHourPrice: null,

        totalOtherPrice: null,
        totalOtherPriceCalculation: null,
        otherHours: null,
        otherHourPrice: null,

        //-------------Security-------------
        totalSecurityPrice: null,
        totalSecurityPriceCalculation: null,

        totalStaffSecurityPrice: null,
        totalStaffSecurityPriceCalculation: null,
        staffSecurityHours: null,
        staffSecurityHourPrice: null,

        totalStaffBuildingSitePrice: null,
        totalStaffBuildingSitePriceCalculation: null,
        staffBuildingSiteHours: null,
        staffBuildingSiteHourPrice: null,

        taxes: null,
        other: null,

        //Kpi
        totalInstallationSecurityPrice: null,

        installationSecuritySurfaceRatio: null,
        installationSurfaceRatio: null,
        securitySurfaceRatio: null,

        installationTotalRatio: null,
        securityTotalRatio: null,
      },
      serviceLife: {
        annualLifeCost: null,
        annualLifeCostCalculation: null,

        //------------- Annual Integrated Implants Cost -------------
        annualIntegratedImplantsCost: null,
        annualIntegratedImplantsCostCalculation: null,

        annualShieldingsCost: null,
        annualShieldingCostsCalculation: null,
        //shieldingsNumber: null,
        annualShieldingsConsume: null,
        energeticShieldingVectorUnitPrice: null,

        annualVentilationCost: null,
        annualVentilationCostsCalculation: null,
        //ventilationsNumber: null,
        annualVentilationConsume: null,
        energeticVentilationVectorUnitPrice: null,

        annualConditioningCost: null,
        annualConditioningCostsCalculation: null,
        //ConditioningNumber: null,
        annualConditioningConsume: null,
        energeticConditioningVectorUnitPrice: null,

        annualThermicSolarCost: null,
        annualThermicSolarCostsCalculation: null,
        //ThermicSolarNumber: null,
        annualThermicSolarConsume: null,
        energeticThermicSolarVectorUnitPrice: null,

        annualPhotovoltaicIsActive: false,
        annualPhotovoltaicCost: null,
        annualPhotovoltaicCostsCalculation: null,
        //PhotovoltaicNumber: null,
        annualPhotovoltaicConsume: null,
        energeticPhotovoltaicVectorUnitPrice: null,

        //------------- Other implants -------------
        annualOtherImplantsCost: null,
        annualOtherImplantsCostCalculation: null,

        implants: [],
      },
      maintenance: {
        refLife: 25,
        totalMaintenancePrice: null,
        totalMaintenancePriceCalculation: null,
        totalMaintenancePercentage: null,

        //---------------Ordinary maintenance---------------
        totalOrdinaryMaintenancePrice: null,
        totalOrdinaryMaintenancePriceCalculation: null,
        totalOrdinaryMaintenancePercentage: null,
        totalOrdinaryMaintenanceEveryHowManyYears: 1,
        totalOrdinaryMaintenancePeriodCalculation: null,

        //Opaque
        totalWindowReplacementPrice: null,
        totalWindowReplacementPriceCalculation: null,
        windowsNumber: null,
        windowUnitPrice: null,
        windowEveryHowManyYears: 1,
        windowPeriodCalculation: null,

        totalOpaqueLoomFixingsReplacementPrice: null,
        totalOpaqueLoomFixingsReplacementPriceCalculation: null,
        opaqueLoomFixingsQuantity: null,
        opaqueLoomFixingsUnitPrice: null,
        opaqueLoomFixingsEveryHowManyYears: 1,
        opaqueLoomFixingsPeriodCalculation: null,

        totalOpaqueDoubleGlazingReplacementPrice: null,
        totalOpaqueDoubleGlazingReplacementPriceCalculation: null,
        opaqueDoubleGlazingQuantity: null,
        opaqueDoubleGlazingUnitPrice: null,
        opaqueDoubleGlazingEveryHowManyYears: 1,
        opaqueDoubleGlazingPeriodCalculation: null,

        totalOpaqueShieldingsReplacementPrice: null,
        totalOpaqueShieldingsReplacementPriceCalculation: null,
        opaqueShieldingsQuantity: null,
        opaqueShieldingsUnitPrice: null,
        opaqueShieldingEveryHowManyYears: 1,
        opaqueShieldingPeriodCalculation: null,

        totalExternalCladdingReplacementPrice: null,
        totalExternalCladdingReplacementPriceCalculation: null,
        externalCladdingQuantity: null,
        externalCladdingUnitPrice: null,
        externalCladdingEveryHowManyYears: 1,
        externalCladdingPeriodCalculation: null,

        totalInsulationReplacementPrice: null,
        totalInsulationReplacementPriceCalculation: null,
        insulationSurface: null,
        insulationUnitPrice: null,
        insulationEveryHowManyYears: 1,
        insulationPeriodCalculation: null,

        totalSupportingElementsReplacementPrice: null,
        totalSupportingElementsReplacementPriceCalculation: null,
        supportingElementsQuantity: null,
        supportingElementUnitPrice: null,
        supportingElementsEveryHowManyYears: 1,
        supportingElementsPeriodCalculation: null,

        totalPrefabricatedModulesReplacementPrice: null,
        totalPrefabricatedModulesReplacementPriceCalculation: null,
        prefabricatedModulesQuantity: null,
        prefabricatedModulesUnitPrice: null,
        prefabricatedModulesEveryHowManyYears: 1,
        prefabricatedModulesPeriodCalculation: null,

        //Transparent
        totalGlazedCellsReplacementPrice: null,
        totalGlazedCellsReplacementPriceCalculation: null,
        glazedCellsNumber: null,
        glazedCellUnitPrice: null,
        glazedCellsEveryHowManyYears: 1,
        glazedCellsPeriodCalculation: null,

        totalDoubleGlazingReplacementPrice: null,
        totalDoubleGlazingReplacementPriceCalculation: null,
        doubleGlazingQuantity: null,
        doubleGlazingUnitPrice: null,
        doubleGlazingEveryHowManyYears: 1,
        doubleGlazingPeriodCalculation: null,

        totalTransparentLoomFixingsReplacementPrice: null,
        totalTransparentLoomFixingsReplacementPriceCalculation: null,
        transparentLoomFixingsQuantity: null,
        transparentLoomFixingsUnitPrice: null,
        transparentLoomFixingsEveryHowManyYears: 1,
        transparentLoomPeriodCalculation: null,

        totalTransparentShieldingsReplacementPrice: null,
        totalTransparentShieldingsReplacementPriceCalculation: null,
        transparentShieldingsQuantity: null,
        transparentShieldingsUnitPrice: null,
        transparentShieldingsEveryHowManyYears: 1,
        transparentShieldingsPeriodCalculation: null,

        //Systems Integrations
        totalPhotovoltaicModulesReplacementPrice: null,
        totalPhotovoltaicModulesReplacementPriceCalculation: null,
        photovoltaicModulesNumber: null,
        photovoltaicModuleUnitPrice: null,
        photovoltaicModulesEveryHowManyYears: 1,
        photovoltaicModulesPeriodCalculation: null,

        totalSolarPanelsReplacementPrice: null,
        totalSolarPanelsReplacementPriceCalculation: null,
        solarPanelsNumber: null,
        solarPanelUnitPrice: null,
        solarPanelsEveryHowManyYears: 1,
        solarPanelsPeriodCalculation: null,

        totalVentilationDevicesReplacementPrice: null,
        totalVentilationDevicesReplacementPriceCalculation: null,
        ventilationDevicesNumber: null,
        ventilationDeviceUnitPrice: null,
        ventilationDevicesEveryHowManyYears: 1,
        ventilationDevicesPeriodCalculation: null,

        totalAirConditioningDevicesReplacementPrice: null,
        totalAirConditioningDevicesReplacementPriceCalculation: null,
        airConditioningDevicesNumber: null,
        airConditioningDeviceUnitPrice: null,
        airConditioningDevicesEveryHowManyYears: 1,
        airConditioningPeriodCalculation: null,

        //Cleaning
        totalCleaningPrice: null,
        totalCleaningPriceCalculation: null,
        cleaningsEveryHowManyYears: 1,
        cleaningUnitPrice: null,
        cleaningsPeriodCalculation: null,

        //---------------Extraordinary maintenance---------------
        totalExtraordinaryMaintenancePrice: null,
        totalExtraordinaryMaintenancePriceCalculation: null,
        totalExtraordinaryMaintenancePercentage: null,
        totalExtraordinaryMaintenanceEveryHowManyYears: 1,
        totalExtraordinaryMaintenancePeriodCalculation: null,
        actions: [],
      },
      endOfLife: {
        totalDemolitionAndDisassemblyPrice: null,
        totalDemolitionAndDisassemblyPriceCalculation: null,

        surfaceDemolitionOrDisassembly: null,

        //---------------Demolition---------------
        totalDemolitionPrice: null,
        totalDemolitionPriceCalculation: null,

        demolitionQuantity: null,
        demolitionUnitPrice: null,

        //---------------Disassebly---------------
        totalDisassemblyPrice: null,
        totalDisassemblyPriceCalculation: null,

        //Opaque disassembly
        totalWindowsDisassemblyPrice: null,
        totalWindowsDisassemblyPriceCalculation: null,
        windowsQuantity: null,
        windowsUnitPrice: null,

        totalExternalCladdingDisassemblyPrice: null,
        totalExternalCladdingDisassemblyPriceCalculation: null,
        externalCladdingQuantity: null,
        externalCladdingUnitPrice: null,

        totalInsulationDisassemblyPrice: null,
        totalInsulationDisassemblyPriceCalculation: null,
        insulationQuantity: null,
        insulationUnitPrice: null,

        totalSupportingElementsAndFixingsDisassemblyPrice: null,
        totalSupportingElementsAndFixingsDisassemblyPriceCalculation: null,
        supportingElementsAndFixingsQuantity: null,
        supportingElementsAndFixingsUnitPrice: null,

        //Transparent disassembly
        totalGlazedCellsDisassemblyPrice: null,
        totalGlazedCellsDisassemblyPriceCalculation: null,
        glazedCellsQuantity: null,
        glazedCellsUnitPrice: null,

        totalDoubleGlazingDisassemblyPrice: null,
        totalDoubleGlazingDisassemblyPriceCalculation: null,
        doubleGlazingQuantity: null,
        doubleGlazingUnitPrice: null,

        totalUprightsCrosspiecesFixingsDisassemblyPrice: null,
        totalUprightsCrosspiecesFixingsDisassemblyPriceCalculation: null,
        uprightsCrosspiecesFixingsQuantity: null,
        uprightsCrosspiecesFixingsUnitPrice: null,

        //---------------Transport---------------
        totalTransportPrice: null,
        totalTransportPriceCalculation: null,

        //Disposal transport
        totalDisposalTransportPrice: null,
        totalDisposalTransportPriceCalculation: null,
        disposalTransportQuantity: null,
        disposalTransportUnitPrice: null,

        //Recycling transport
        totalRecyclingTransportPrice: null,
        totalRecyclingTransportPriceCalculation: null,
        recyclingTransportQuantity: null,
        recyclingTransportUnitPrice: null,

        //---------------Recycling---------------
        totalRecyclingRevenue: null,
        totalRecyclingRevenueCalculation: null,

        //Windows
        totalWindowsRecyclingRevenue: null,
        totalWindowsRecyclingRevenueCalculation: null,
        windowsRecyclingQuantity: null,
        windowsRecyclingUnitPrice: null,

        //External cladding
        totalExternalCladdingRecyclingRevenue: null,
        totalExternalCladdingRevenueCalculation: null,
        externalCladdingRecyclingQuantity: null,
        externalCladdingRecyclingUnitPrice: null,

        //Insulation
        totalInsulationRecyclingRevenue: null,
        totalInsulationRevenueCalculation: null,
        insulationRecyclingQuantity: null,
        insulationRecyclingUnitPrice: null,

        //Supporting elements and fixings
        totalSupportingElementsAndFixingsRecyclingRevenue: null,
        totalSupportingElementsAndFixingsRecyclingRevenueCalculation: null,
        supportingElementsAndFixingsRecyclingQuantity: null,
        supportingElementsAndFixingsRecyclingUnitPrice: null,

        //Glazed cells
        totalGlazedCellsRecyclingRevenue: null,
        totalGlazedCellsRecyclingRevenueCalculation: null,
        glazedCellsRecyclingQuantity: null,
        glazedCellsUnitRecyclingPrice: null,

        //Double glazing
        totalDoubleGlazingRecyclingRevenue: null,
        totalDoubleGlazingRecyclingRevenueCalculation: null,
        doubleGlazingRecyclingQuantity: null,
        doubleGlazingRecyclingUnitPrice: null,

        //Uprights, Crosspieces and Fixings
        totalUprightsCrosspiecesFixingsRecyclingRevenue: null,
        totalUprightsCrosspiecesFixingsRecyclingRevenueCalculation: null,
        uprightsCrosspiecesFixingsRecyclingQuantity: null,
        uprightsCrosspiecesFixingsRecyclingUnitPrice: null,

        //---------------ReUse---------------
        totalReUseRevenue: null,
        totalReUseRevenueCalculation: null,

        //Windows
        totalWindowsReUseRevenue: null,
        totalWindowsReUseRevenueCalculation: null,
        windowsReUseQuantity: null,
        windowsReUseUnitPrice: null,

        //External cladding
        totalExternalCladdingReUseRevenue: null,
        totalExternalCladdingReUseRevenueCalculation: null,
        externalCladdingReUseQuantity: null,
        externalCladdingReUseUnitPrice: null,

        //Insulation
        totalInsulationReUseRevenue: null,
        totalInsulationReUseRevenueCalculation: null,
        insulationReUseQuantity: null,
        insulationReUseUnitPrice: null,

        //Supporting elements and fixings
        totalSupportingElementsAndFixingsReUseRevenue: null,
        totalSupportingElementsAndFixingsReUseRevenueCalculation: null,
        supportingElementsAndFixingsReUseQuantity: null,
        supportingElementsAndFixingsReUseUnitPrice: null,

        //Glazed cells
        totalGlazedCellsReUseRevenue: null,
        totalGlazedCellsReUseRevenueCalculation: null,
        glazedCellsReUseQuantity: null,
        glazedCellsUnitReUsePrice: null,

        //Double glazing
        totalDoubleGlazingReUseRevenue: null,
        totalDoubleGlazingReUseRevenueCalculation: null,
        doubleGlazingReUseQuantity: null,
        doubleGlazingReUseUnitPrice: null,

        //Uprights, Crosspieces and Fixings
        totalUprightsCrosspiecesFixingsReUseRevenue: null,
        totalUprightsCrosspiecesFixingsReUseRevenueCalculation: null,
        uprightsCrosspiecesFixingsReUseQuantity: null,
        uprightsCrosspiecesFixingsReUseUnitPrice: null,
      },
      costsActualization: {
        interestRate: null,
        priceVectorVariation: null,
        costOfLifeActualization: [],
        maintenanceCostActualization: [],
      },
      notes: {
        fancadeTechnicalProject: {
          type: null,
          surface: null,
          //Involucro opaco
          windows: [],
          windowsTotalPrice: null,
          windowsTotalPriceCalculation: null,
          externalCladdingUnitsNumber: null, //rivestimento
          externalCladdingUnitsSurface: null, //rivestimento
          totalExternalCladdingSurface: null, //rivestimento
          insulationSurface: null, //isolamento
          supportingElementsQuantity: null, //elementi portanti
          prefabricatedModulesQuantity: null,
          modulesSurface: null,
          opaqueAnchoringsNumber: null, //ancoraggi
          opaqueFixingsNumber: null, //fissaggi
          //Involucro opaco production
          externalCladdingTotalPrice: null,
          externalCladdingTotalPriceCalculation: null,
          externalCladdingUnitPrice: null,

          insulationTotalPrice: null,
          insulationTotalPriceCalculation: null,
          insulationUnitPrice: null,
          supportingElementsTotalPrice: null,
          supportingElementsTotalPriceCalculation: null,
          supportingElementsUnitPrice: null,

          prefabricatedModulesTotalPrice: null,
          prefabricatedModulesTotalPriceCalculation: null,
          prefabricatedModulesUnitPrice: null,

          opaqueAnchoringTotalPrice: null,
          opaqueAnchoringTotalPriceCalculation: null,
          opaqueAnchoringUnitPrice: null,

          opaqueFixingsTotalPrice: null,
          opaqueFixingsTotalPriceCalculation: null,
          opaqueFixingsUnitPrice: null,

          //Involucro trasparente
          transparentTotalPrice: null,
          transparentTotalPriceCalculation: null,
          glazedCellsNumber: null,
          glazedCellsSurface: null,
          doubleGlazingQuantity: null,
          doubleGlazingSurface: null,
          mainFrameHeightPerUnit: null,
          mainFrameWidthPerUnit: null,
          totalMainFrameLength: null,
          totalGasketLength: null,
          opaqueSpandrelPanelsQuantity: null,
          opaqueSpandrelPanelsSurface: null,
          transparentAnchoringsNumber: null, //ancoraggi
          transparentFixingsNumber: null, //fissaggi

          //Involucro trasparente production
          glazedCellsUnitPrice: null,
          totalGlazedCellsPrice: null,
          totalGlazedCellsPriceCalculation: null,
          totalMainFramePrice: null,
          totalMainFramePriceCalculation: null,
          mainFrameUnitPrice: null,
          totalDoubleGlazingPrice: null,
          totalDoubleGlazingPriceCalculation: null,
          doubleGlazingUnitPrice: null,
          gasketUnitPrice: null,
          totalGasketPrice: null,
          totalGasketPriceCalculation: null,
          opaqueSpandrelModulesUnitPrice: null,
          totalOpaqueSpandrelModulesPrice: null,
          totalOpaqueSpandrelModulesPriceCalculation: null,
          transparentAnchoringsUnitPrice: null,
          totalTransparentAnchoringsPrice: null,
          totalTransparentAnchoringsPriceCalculation: null,
          totalTransparentFixingsNumberPrice: null,
          totalTransparentFixingsNumberPriceCalculation: null,
          transparentFixingsNumberUnitPrice: null,

          //Systems Integration production
          totalSystemsIntegrationPrice: null,
          totalSystemsIntegrationPriceCalculation: null,

          shieldingsUnitPrice: null,
          totalShieldingsPrice: null,
          totalShieldingsPriceCalculation: null,

          ventilationSystemsUnitPrice: null,
          totalVentilationSystemsPrice: null,
          totalVentilationSystemsPriceCalculation: null,

          airConditioningSystemsUnitPrice: null,
          totalAirConditioningSystemsPrice: null,
          totalAirConditioningSystemsPriceCalculation: null,

          thermicSolarPanelsUnitPrice: null,
          totalThermicSolarPanelsPrice: null,
          totalThermicSolarPanelsPriceCalculation: null,

          photovoltaicModulesUnitPrice: null,
          totalPhotovoltaicModulesPrice: null,
          totalPhotovoltaicModulesPriceCalculation: null,

          //Scrap production use getScrapUnitPrice(varName, string)
          totalScrapPrice: null,
          totalScrapPriceCalculation: null,

          doubleGlazingScrapQuantity: null,
          totalDoubleGlazingScrapPrice: null,
          totalDoubleGlazingScrapPriceCalculation: null,

          mainFrameOrLoomScrapQuantity: null,
          totalMainFrameOrLoomScrapPrice: null,
          totalMainFrameOrLoomScrapPriceCalculation: null,

          gasketScrapQuantity: null,
          totalGasketScrapPrice: null,
          totalGasketScrapPriceCalculation: null,

          opaqueWindowsScrapQuantity: null, //Solo opache
          totalOpaqueWindowsScrapPrice: null, //Solo opache
          totalOpaqueWindowsScrapPriceCalculation: null, //Solo opache

          opaqueExternalCladdingScrapQuantity: null, //Solo opache
          totalOpaqueExternalCladdingScrapPrice: null, //Solo opache
          totalOpaqueExternalCladdingScrapPriceCalculation: null, //Solo opache

          opaqueInsulationScrapQuantity: null, //Solo opache
          totalOpaqueInsulationScrapPrice: null, //Solo opache
          totalOpaqueInsulationScrapPriceCalculation: null, //Solo opache

          opaqueSupportingElementsScrapQuantity: null, //Solo opache
          totalOpaqueSupportingElementsScrapPrice: null, //Solo opache
          totalOpaqueSupportingElementsScrapPriceCalculation: null, //Solo opache

          transparentGlazedCellScrapQuantity: null, //Solo trasparenti
          totalTransparentGlazedCellScrapPrice: null, //Solo trasparenti
          totalTransparentGlazedCellScrapPriceCalculation: null, //Solo trasparenti

          transparentOpaqueSpandrelModulesQuantity: null, //Solo trasparenti
          totalTransparentOpaqueSpandrelModulesPrice: null, //Solo trasparenti
          totalTransparentOpaqueSpandrelModulesPriceCalculation: null, //Solo trasparenti

          fixingsScrapQuantity: null,
          totalFixingsScrapPrice: null,
          totalFixingsScrapPriceCalculation: null,

          anchoringsScrapQuantity: null,
          totalAnchoringsScrapPrice: null,
          totalAnchoringsScrapPriceCalculation: null,

          accessoriesScrap: null,

          //Altro
          shieldingSystemsNumber: null,
          ventilationDevicesNumber: null,
          airConditioningDevicesNumber: null,
          thermicSolarPanelsNumber: null,
          windowOpaqueSurfaceRatio: null,
          thermicSolarPanelsSurface: null,
          thermicSolarPanelsUnitSurface: null,
          photovoltaicModulesSurface: null,
          photovoltaicModulesUnitSurface: null,
          photovoltaicModulesNumber: null,
          opaqueUValue: null,
          transparentUValue: null,
          windowGlassGValue: null,
          fancadeGlassGValue: null,
          prefabricationLevel: null,
          geometricDetails: null,
          systemsIntegration: 1 | 0, //Integrazione impiantistica
          systemsSurfacePercentage: null,
          systemsIntegrations: [],
          customFields: [],
        },
        fancadeProject: {
          totalDesignPrice: null,
          totalDesignPriceCalculation: null,
          technicalProject: null,
          structuralSismicProject: null,
          lightingTechnicalProject: null,
          ventilationProject: null,
          acusticProject: null,
          airQualityCheck: null,
          constructionPrinceAnalysis: null,
          firePreventionDesign: null,
          buildingSiteManagement: null,
          buildingSiteSafety: null,
          energeticPerformanceAnalysis: null,
          competitionProject: null,
          maintenancePlan: null,
          userManual: null,
        },
        fancadeKpiFancadeProject: {
          totalDesignPrice: null,
          totalFancadeSurface: null,
          totalDesignPriceKPI: null,
        },
        fancadeProduction: {
          totalProductionPrice: null,
          totalProductionPriceCalculation: null,
          windowsTotalPrice: null,
          windowsTotalPriceCalculation: null,
          totalMaterialsPrice: null,
          totalMaterialsPriceCalculation: null,
          totalScrapPrice: null,
          totalScrapPriceCalculation: null,
          totalSystemIntegrationPrice: null,
          totalSystemIntegrationPriceCalculation: null,

          //Labor
          totalLaborPrice: null,
          totalLaborPriceCalculation: null,

          totalBaseLaborPrice: null,
          totalBaseLaborPriceCalculation: null,
          baseLaborHours: null,
          baseLaborHourPrice: null,

          totalSkilledLaborPrice: null,
          totalSkilledLaborPriceCalculation: null,
          skilledLaborHours: null,
          skilledLaborHourPrice: null,

          hardware: null,
          hardwareTotalPrice: null,
          hardwareTotalPriceCalculation: null,
        },
        transport: {
          totalTransportPrice: null,
          totalTransportPriceCalculation: null,

          //Packaging
          totalPackagingPrice: null,
          totalPackagingPriceCalculation: null,
          packagings: [],

          //Transport
          totalTransportPartPrice: null,
          totalTransportPartPriceCalculation: null,
          workSiteProductionSiteDistance: null,
          transportPartUnitPrice: null,
        },
        buildingSite: {
          //-------------Fancade Installation-------------
          totalInstallationPrice: null,
          totalInstallationPriceCalculation: null,

          totalBuildingSiteMaterialsPrice: null,
          totalBuildingSiteMaterialsPriceCalculation: null,
          buildingSiteMaterials: [],

          buildingSiteDuration: null,

          //Labor
          totalLaborPrice: null,
          totalLaborPriceCalculation: null,

          totalBaseLaborPrice: null,
          totalBaseLaborPriceCalculation: null,
          baseLaborHours: null,
          baseLaborHourPrice: null,

          totalSkilledLaborPrice: null,
          totalSkilledLaborPriceCalculation: null,
          skilledLaborHours: null,
          skilledLaborHourPrice: null,

          //Tools
          totalToolsPrice: null,
          totalToolsPriceCalculation: null,

          totalTowerCranePrice: null,
          totalTowerCranePriceCalculation: null,
          towerCraneHours: null,
          towerCraneHourPrice: null,

          totalElevatorPlatformPrice: null,
          totalElevatorPlatformPriceCalculation: null,
          elevatorPlatformHours: null,
          elevatorPlatformHourPrice: null,

          totalAerialPlatformPrice: null,
          totalAerialPlatformPriceCalculation: null,
          aerialPlatformHours: null,
          aerialPlatformHourPrice: null,

          totalScaffoldingPrice: null,
          totalScaffoldingPriceCalculation: null,
          scaffoldingSurface: null,
          scaffoldingSurfaceUnitPrice: null,
          scaffoldingHours: null,
          scaffoldingHourPrice: null,

          totalOtherPrice: null,
          totalOtherPriceCalculation: null,
          otherHours: null,
          otherHourPrice: null,

          //-------------Security-------------
          totalSecurityPrice: null,
          totalSecurityPriceCalculation: null,

          totalStaffSecurityPrice: null,
          totalStaffSecurityPriceCalculation: null,
          staffSecurityHours: null,
          staffSecurityHourPrice: null,

          totalStaffBuildingSitePrice: null,
          totalStaffBuildingSitePriceCalculation: null,
          staffBuildingSiteHours: null,
          staffBuildingSiteHourPrice: null,

          taxes: null,
          other: null,

          //Kpi
          totalInstallationSecurityPrice: null,

          installationSecuritySurfaceRatio: null,
          installationSurfaceRatio: null,
          securitySurfaceRatio: null,

          installationTotalRatio: null,
          securityTotalRatio: null,
        },
        serviceLife: {
          annualLifeCost: null,
          annualLifeCostCalculation: null,

          //------------- Annual Integrated Implants Cost -------------
          annualIntegratedImplantsCost: null,
          annualIntegratedImplantsCostCalculation: null,

          annualShieldingsCost: null,
          annualShieldingCostsCalculation: null,
          //shieldingsNumber: null,
          annualShieldingsConsume: null,
          energeticShieldingVectorUnitPrice: null,

          annualVentilationCost: null,
          annualVentilationCostsCalculation: null,
          //ventilationsNumber: null,
          annualVentilationConsume: null,
          energeticVentilationVectorUnitPrice: null,

          annualConditioningCost: null,
          annualConditioningCostsCalculation: null,
          //ConditioningNumber: null,
          annualConditioningConsume: null,
          energeticConditioningVectorUnitPrice: null,

          annualThermicSolarCost: null,
          annualThermicSolarCostsCalculation: null,
          //ThermicSolarNumber: null,
          annualThermicSolarConsume: null,
          energeticThermicSolarVectorUnitPrice: null,

          annualPhotovoltaicIsActive: false,
          annualPhotovoltaicCost: null,
          annualPhotovoltaicCostsCalculation: null,
          //PhotovoltaicNumber: null,
          annualPhotovoltaicConsume: null,
          energeticPhotovoltaicVectorUnitPrice: null,

          //------------- Other implants -------------
          annualOtherImplantsCost: null,
          annualOtherImplantsCostCalculation: null,

          implants: [],
        },
        maintenance: {
          refLife: 25,
          totalMaintenancePrice: null,
          totalMaintenancePriceCalculation: null,
          totalMaintenancePercentage: null,

          //---------------Ordinary maintenance---------------
          totalOrdinaryMaintenancePrice: null,
          totalOrdinaryMaintenancePriceCalculation: null,

          totalOrdinaryMaintenancePercentage: null,
          totalOrdinaryMaintenanceEveryHowManyYears: null,
          totalOrdinaryMaintenancePeriodCalculation: null,

          //Opaque
          totalWindowReplacementPrice: null,
          totalWindowReplacementPriceCalculation: null,
          windowsNumber: null,
          windowUnitPrice: null,
          windowEveryHowManyYears: null,
          windowPeriodCalculation: null,

          totalOpaqueLoomFixingsReplacementPrice: null,
          totalOpaqueLoomFixingsReplacementPriceCalculation: null,
          opaqueLoomFixingsQuantity: null,
          opaqueLoomFixingsUnitPrice: null,
          opaqueLoomFixingsEveryHowManyYears: null,
          opaqueLoomFixingsPeriodCalculation: null,

          totalOpaqueDoubleGlazingReplacementPrice: null,
          totalOpaqueDoubleGlazingReplacementPriceCalculation: null,
          opaqueDoubleGlazingQuantity: null,
          opaqueDoubleGlazingUnitPrice: null,
          opaqueDoubleGlazingEveryHowManyYears: null,
          opaqueDoubleGlazingPeriodCalculation: null,

          totalOpaqueShieldingsReplacementPrice: null,
          totalOpaqueShieldingsReplacementPriceCalculation: null,
          opaqueShieldingsQuantity: null,
          opaqueShieldingsUnitPrice: null,
          opaqueShieldingEveryHowManyYears: null,
          opaqueShieldingPeriodCalculation: null,

          totalExternalCladdingReplacementPrice: null,
          totalExternalCladdingReplacementPriceCalculation: null,
          externalCladdingQuantity: null,
          externalCladdingUnitPrice: null,
          externalCladdingEveryHowManyYears: null,
          externalCladdingPeriodCalculation: null,

          totalInsulationReplacementPrice: null,
          totalInsulationReplacementPriceCalculation: null,
          insulationSurface: null,
          insulationUnitPrice: null,
          insulationEveryHowManyYears: null,
          insulationPeriodCalculation: null,

          totalSupportingElementsReplacementPrice: null,
          totalSupportingElementsReplacementPriceCalculation: null,
          supportingElementsQuantity: null,
          supportingElementUnitPrice: null,
          supportingElementsEveryHowManyYears: null,
          supportingElementsPeriodCalculation: null,

          totalPrefabricatedModulesReplacementPrice: null,
          totalPrefabricatedModulesReplacementPriceCalculation: null,
          prefabricatedModulesQuantity: null,
          prefabricatedModulesUnitPrice: null,
          prefabricatedModulesEveryHowManyYears: null,
          prefabricatedModulesPeriodCalculation: null,

          //Transparent
          totalGlazedCellsReplacementPrice: null,
          totalGlazedCellsReplacementPriceCalculation: null,
          glazedCellsNumber: null,
          glazedCellUnitPrice: null,
          glazedCellsEveryHowManyYears: null,
          glazedCellsPeriodCalculation: null,

          totalDoubleGlazingReplacementPrice: null,
          totalDoubleGlazingReplacementPriceCalculation: null,
          doubleGlazingQuantity: null,
          doubleGlazingUnitPrice: null,
          doubleGlazingEveryHowManyYears: null,
          doubleGlazingPeriodCalculation: null,

          totalTransparentLoomFixingsReplacementPrice: null,
          totalTransparentLoomFixingsReplacementPriceCalculation: null,
          transparentLoomFixingsQuantity: null,
          transparentLoomFixingsUnitPrice: null,
          transparentLoomFixingsEveryHowManyYears: null,
          transparentLoomPeriodCalculation: null,

          totalTransparentShieldingsReplacementPrice: null,
          totalTransparentShieldingsReplacementPriceCalculation: null,
          transparentShieldingsQuantity: null,
          transparentShieldingsUnitPrice: null,
          transparentShieldingsEveryHowManyYears: null,
          transparentShieldingsPeriodCalculation: null,

          //Systems Integrations
          totalPhotovoltaicModulesReplacementPrice: null,
          totalPhotovoltaicModulesReplacementPriceCalculation: null,
          photovoltaicModulesNumber: null,
          photovoltaicModuleUnitPrice: null,
          photovoltaicModulesEveryHowManyYears: null,
          photovoltaicModulesPeriodCalculation: null,

          totalSolarPanelsReplacementPrice: null,
          totalSolarPanelsReplacementPriceCalculation: null,
          solarPanelsNumber: null,
          solarPanelUnitPrice: null,
          solarPanelsEveryHowManyYears: null,
          solarPanelsPeriodCalculation: null,

          totalVentilationDevicesReplacementPrice: null,
          totalVentilationDevicesReplacementPriceCalculation: null,
          ventilationDevicesNumber: null,
          ventilationDeviceUnitPrice: null,
          ventilationDevicesEveryHowManyYears: null,
          ventilationDevicesPeriodCalculation: null,

          totalAirConditioningDevicesReplacementPrice: null,
          totalAirConditioningDevicesReplacementPriceCalculation: null,
          airConditioningDevicesNumber: null,
          airConditioningDeviceUnitPrice: null,
          airConditioningDevicesEveryHowManyYears: null,
          airConditioningPeriodCalculation: null,

          //Cleaning
          totalCleaningPrice: null,
          totalCleaningPriceCalculation: null,
          cleaningUnitPrice: null,
          cleaningsEveryHowManyYears: null,
          cleaningsPeriodCalculation: null,

          //---------------Extraordinary maintenance---------------
          totalExtraordinaryMaintenancePrice: null,
          totalExtraordinaryMaintenancePriceCalculation: null,
          totalExtraordinaryMaintenancePercentage: null,
          totalExtraordinaryMaintenanceEveryHowManyYears: null,
          totalExtraordinaryMaintenancePeriodCalculation: null,
          actions: [],
        },
        endOfLife: {
          totalDemolitionAndDisassemblyPrice: null,
          totalDemolitionAndDisassemblyPriceCalculation: null,

          surfaceDemolitionOrDisassembly: null,

          //---------------Demolition---------------
          totalDemolitionPrice: null,
          totalDemolitionPriceCalculation: null,

          demolitionQuantity: null,
          demolitionUnitPrice: null,

          //---------------Disassebly---------------
          totalDisassemblyPrice: null,
          totalDisassemblyPriceCalculation: null,

          //Opaque disassembly
          totalWindowsDisassemblyPrice: null,
          totalWindowsDisassemblyPriceCalculation: null,
          windowsQuantity: null,
          windowsUnitPrice: null,

          totalExternalCladdingDisassemblyPrice: null,
          totalExternalCladdingDisassemblyPriceCalculation: null,
          externalCladdingQuantity: null,
          externalCladdingUnitPrice: null,

          totalInsulationDisassemblyPrice: null,
          totalInsulationDisassemblyPriceCalculation: null,
          insulationQuantity: null,
          insulationUnitPrice: null,

          totalSupportingElementsAndFixingsDisassemblyPrice: null,
          totalSupportingElementsAndFixingsDisassemblyPriceCalculation: null,
          supportingElementsAndFixingsQuantity: null,
          supportingElementsAndFixingsUnitPrice: null,

          //Transparent disassembly
          totalGlazedCellsDisassemblyPrice: null,
          totalGlazedCellsDisassemblyPriceCalculation: null,
          glazedCellsQuantity: null,
          glazedCellsUnitPrice: null,

          totalDoubleGlazingDisassemblyPrice: null,
          totalDoubleGlazingDisassemblyPriceCalculation: null,
          doubleGlazingQuantity: null,
          doubleGlazingUnitPrice: null,

          totalUprightsCrosspiecesFixingsDisassemblyPrice: null,
          totalUprightsCrosspiecesFixingsDisassemblyPriceCalculation: null,
          uprightsCrosspiecesFixingsQuantity: null,
          uprightsCrosspiecesFixingsUnitPrice: null,

          //---------------Transport---------------
          totalTransportPrice: null,
          totalTransportPriceCalculation: null,

          //Disposal transport
          totalDisposalTransportPrice: null,
          totalDisposalTransportPriceCalculation: null,
          disposalTransportQuantity: null,
          disposalTransportUnitPrice: null,

          //Recycling transport
          totalRecyclingTransportPrice: null,
          totalRecyclingTransportPriceCalculation: null,
          recyclingTransportQuantity: null,
          recyclingTransportUnitPrice: null,

          //---------------Recycling---------------
          totalRecyclingRevenue: null,
          totalRecyclingRevenueCalculation: null,

          //Windows
          totalWindowsRecyclingRevenue: null,
          totalWindowsRecyclingRevenueCalculation: null,
          windowsRecyclingQuantity: null,
          windowsRecyclingUnitPrice: null,

          //External cladding
          totalExternalCladdingRecyclingRevenue: null,
          totalExternalCladdingRevenueCalculation: null,
          externalCladdingRecyclingQuantity: null,
          externalCladdingRecyclingUnitPrice: null,

          //Insulation
          totalInsulationRecyclingRevenue: null,
          totalInsulationRevenueCalculation: null,
          insulationRecyclingQuantity: null,
          insulationRecyclingUnitPrice: null,

          //Supporting elements and fixings
          totalSupportingElementsAndFixingsRecyclingRevenue: null,
          totalSupportingElementsAndFixingsRecyclingRevenueCalculation: null,
          supportingElementsAndFixingsRecyclingQuantity: null,
          supportingElementsAndFixingsRecyclingUnitPrice: null,

          //Glazed cells
          totalGlazedCellsRecyclingRevenue: null,
          totalGlazedCellsRecyclingRevenueCalculation: null,
          glazedCellsRecyclingQuantity: null,
          glazedCellsUnitRecyclingPrice: null,

          //Double glazing
          totalDoubleGlazingRecyclingRevenue: null,
          totalDoubleGlazingRecyclingRevenueCalculation: null,
          doubleGlazingRecyclingQuantity: null,
          doubleGlazingRecyclingUnitPrice: null,

          //Uprights, Crosspieces and Fixings
          totalUprightsCrosspiecesFixingsRecyclingRevenue: null,
          totalUprightsCrosspiecesFixingsRecyclingRevenueCalculation: null,
          uprightsCrosspiecesFixingsRecyclingQuantity: null,
          uprightsCrosspiecesFixingsRecyclingUnitPrice: null,

          //---------------ReUse---------------
          totalReUseRevenue: null,
          totalReUseRevenueCalculation: null,

          //Windows
          totalWindowsReUseRevenue: null,
          totalWindowsReUseRevenueCalculation: null,
          windowsReUseQuantity: null,
          windowsReUseUnitPrice: null,

          //External cladding
          totalExternalCladdingReUseRevenue: null,
          totalExternalCladdingReUseRevenueCalculation: null,
          externalCladdingReUseQuantity: null,
          externalCladdingReUseUnitPrice: null,

          //Insulation
          totalInsulationReUseRevenue: null,
          totalInsulationReUseRevenueCalculation: null,
          insulationReUseQuantity: null,
          insulationReUseUnitPrice: null,

          //Supporting elements and fixings
          totalSupportingElementsAndFixingsReUseRevenue: null,
          totalSupportingElementsAndFixingsReUseRevenueCalculation: null,
          supportingElementsAndFixingsReUseQuantity: null,
          supportingElementsAndFixingsReUseUnitPrice: null,

          //Glazed cells
          totalGlazedCellsReUseRevenue: null,
          totalGlazedCellsReUseRevenueCalculation: null,
          glazedCellsReUseQuantity: null,
          glazedCellsUnitReUsePrice: null,

          //Double glazing
          totalDoubleGlazingReUseRevenue: null,
          totalDoubleGlazingReUseRevenueCalculation: null,
          doubleGlazingReUseQuantity: null,
          doubleGlazingReUseUnitPrice: null,

          //Uprights, Crosspieces and Fixings
          totalUprightsCrosspiecesFixingsReUseRevenue: null,
          totalUprightsCrosspiecesFixingsReUseRevenueCalculation: null,
          uprightsCrosspiecesFixingsReUseQuantity: null,
          uprightsCrosspiecesFixingsReUseUnitPrice: null,
        },
        costsActualization: {
          interestRate: null,
          priceVectorVariation: null,
          costOfLifeActualization: [],
          maintenanceCostActualization: [],
        },
      },
      projectId: projectId,
    } as Fancade;
    return fancade;
  };

  deleteFancade = async (fancade: FancadeAPI) => {
    const temp = await api.deleteFancade(fancade);
    const toFind = this.projects.find((element) => {
      return element.id == fancade.projectId;
    });
    if (toFind && toFind.fancades) {
      const fancadeToFind = toFind.fancades.find((element) => {
        return element.id == fancade.id;
      });
      if (fancadeToFind) {
        let project = this.projects[this.projects.indexOf(toFind)];
        project.fancades?.splice(project.fancades?.indexOf(fancadeToFind), 1);
        this.projects.splice(this.projects.indexOf(toFind), 1);
      }
    }
    return temp;
  };

  addFancadeToProject = (id: number, fancade: Fancade) => {
    let temp = this.projects.find((element) => {
      return element.id == id;
    });
    //console.log(temp);
    if (temp && temp.fancades) {
      //console.log(2);
      temp.fancades.push(fancade);
      //console.log(temp);
    } else if (temp) {
      //console.log(3);
      temp.fancades = [fancade];
    }
  };

  getEmptyWindowType = (fancade: Fancade): OpaqueWindow => {
    const windowType: OpaqueWindow = {
      id: fancade.fancadeTechnicalProject.windows.length + 1,
      windowsNumber: null,
      windowsUnitSurface: null,
      windowsSurface: null,
      doubleGlazingNumber: null, //vetrocamera
      doubleGlazingSurface: null, //vetrocamera
      loomHeight: null, //telaio
      loomWidth: null, //telaio
      totalLoomLength: null, //telaio
      totalGasketLength: null, //guarnizione
      totalPrice: null,
      unitPrice: null,
      loomComponentsPrice: null,
      loomUnitPrice: null,
      doubleGlazingComponentsPrice: null,
      doubleGlazingUnitPrice: null,
      gasketsComponentsPrice: null,
      gasketsUnitPrice: null,
      totalPriceCalculation: null,
      loomComponentsPriceCalculation: null,
      doubleGlazingComponentsPriceCalculation: null,
      gasketsComponentsPriceCalculation: null,
    };
    return windowType;
  };

  addWindowTypeToFancade = (
    projectId: number,
    fancadeId: number,
    windowType: OpaqueWindow
  ): OpaqueWindow[] => {
    let toReturn = this.projects
      .find((project) => {
        return project.id == projectId;
      })
      ?.fancades?.find((fancade) => {
        return fancade.id == fancadeId;
      })?.fancadeTechnicalProject;
    if (toReturn && toReturn.windows)
      //@ts-ignore
      toReturn.windows.push(windowType);
    if (toReturn) return toReturn.windows;
    else return [];
  };

  setWindowsToFancade = (
    projectId: number,
    fancadeId: number,
    windows: OpaqueWindow[]
  ) => {
    let temp = this.projects
      .find((project) => {
        return project.id == projectId;
      })
      ?.fancades?.find((fancade) => {
        return fancade.id == fancadeId;
      })?.fancadeTechnicalProject;
    if (temp) temp.windows = windows;
    return windows;
  };

  setCustomFieldsToFancade = (
    projectId: number,
    fancadeId: number,
    customFields: Pair[]
  ): Pair[] => {
    let temp = this.projects
      .find((project) => {
        return project.id == projectId;
      })
      ?.fancades?.find((fancade) => {
        return fancade.id == fancadeId;
      })?.fancadeTechnicalProject;
    if (temp) temp.customFields = customFields;
    return customFields;
  };

  setGeometricDetailsToFancade = (
    projectId: number,
    fancadeId: number,
    geometricDetails: GeometricDetail[]
  ): GeometricDetail[] => {
    let temp = this.projects
      .find((project) => {
        return project.id == projectId;
      })
      ?.fancades?.find((fancade) => {
        return fancade.id == fancadeId;
      })?.fancadeTechnicalProject;
    if (temp) temp.geometricDetails = geometricDetails;
    return geometricDetails;
  };

  setSystemsIntegrationsToFancade = (
    projectId: number,
    fancadeId: number,
    systemsIntegrations: SystemsIntegration[]
  ): SystemsIntegration[] => {
    let temp = this.projects
      .find((project) => {
        return project.id == projectId;
      })
      ?.fancades?.find((fancade) => {
        return fancade.id == fancadeId;
      })?.fancadeTechnicalProject;
    if (temp) temp.systemsIntegrations = systemsIntegrations;
    return systemsIntegrations;
  };

  setHardwareToFancade = (
    projectId: number,
    fancadeId: number,
    hardware: Hardware[]
  ): Hardware[] => {
    let temp = this.projects
      .find((project) => {
        return project.id == projectId;
      })
      ?.fancades?.find((fancade) => {
        return fancade.id == fancadeId;
      })?.fancadeProduction;
    if (temp) temp.hardware = hardware;
    return hardware;
  };

  setPackagingsToFancade = (
    projectId: number,
    fancadeId: number,
    packagings: Packaging[]
  ): Packaging[] => {
    let temp = this.projects
      .find((project) => {
        return project.id == projectId;
      })
      ?.fancades?.find((fancade) => {
        return fancade.id == fancadeId;
      })?.transport;
    if (temp) temp.packagings = packagings;
    return packagings;
  };

  setBuildingSiteMaterialsToFancade = (
    projectId: number,
    fancadeId: number,
    buildingSiteMaterials: BuildingSiteMaterial[]
  ): BuildingSiteMaterial[] => {
    let temp = this.projects
      .find((project) => {
        return project.id == projectId;
      })
      ?.fancades?.find((fancade) => {
        return fancade.id == fancadeId;
      })?.buildingSite;
    if (temp) temp.buildingSiteMaterials = buildingSiteMaterials;
    return buildingSiteMaterials;
  };

  setOtherImplantsToFancade = (
    projectId: number,
    fancadeId: number,
    implants: Implant[]
  ): Implant[] => {
    let temp = this.projects
      .find((project) => {
        return project.id == projectId;
      })
      ?.fancades?.find((fancade) => {
        return fancade.id == fancadeId;
      })?.serviceLife;

    if (temp) temp.implants = implants;
    return implants;
  };

  //------------------------------CATEGORIES------------------------------

  fetchCategories = async () => {
    this.isFetchingProjects = true;
    try {
      const retrievedCategories: Category[] = await api.getCategories();
      this.categories = retrievedCategories;
    } catch (err) {
      throw err;
    } finally {
      this.isFetchingProjects = false;
    }
  };

  createCategory = async (category: Category) => {
    const temp = await api.createCategory(category);
    this.categories.push(temp);
    return temp;
  };

  updateCategory = async (category: Category) => {
    const temp = await api.updateCategory(category);
    const toFind = this.categories.find((element) => {
      return element.id == category.id;
    });
    if (toFind) this.categories[this.categories.indexOf(toFind)] = temp;
    return temp;
  };

  getProjectCategory = (project: Project) => {
    let category: Category | undefined;
    try {
      category = this.categories.find((c) => c.id == project.categoryId);
    } catch (err) {
      throw err;
    } finally {
      this.isFetchingProjects = false;
    }

    return category;
  };

  getCategoryProjects = (category?: Category): Project[] => {
    let projects: Project[] = [];
    try {
      this.projects.map((project: Project) => {
        if (category) {
          if (project.categoryId == category.id) {
            projects.push(project);
          }
        } else {
          //Return projects without category or of another user
          if (!project.categoryId) {
            projects.push(project);
          } else {
            if (
              this.stores.session.user &&
              project.createdById != +this.stores.session.user.id
            ) {
              projects.push(project);
            }
          }
        }
      });
    } catch (err) {
      throw err;
    } finally {
      this.isFetchingProjects = false;
    }

    return projects;
  };

  deleteCategory = async (category: Category) => {
    const temp = await api.deleteCategory(category);
    const toFind = this.categories.find((element) => {
      return element.id == category.id;
    });
    if (toFind) this.categories.splice(this.categories.indexOf(toFind), 1);
    return temp;
  };
}
