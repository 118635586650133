import i18n from "../i18n";

export const getFancadeTypesOptions = (): any[] => {
  const fancadeTypes = [
    {
      value: "Trasparente - a cellule senza pannello spandrel opaco",
      name: "transparentWithoutSpandrel",
    },
    {
      value: "Trasparente - a cellule con pannello spandrel opaco",
      name: "transparentWithSpandrel",
    },
    { value: "Trasparente - montanti e traversi", name: "transparentUprights" },
    // {
    //   value: "Opaca - legno con facciata ventilata",
    //   name: "opaqueWoodWithVentilation",
    // },
    // {
    //   value: "Opaca - legno senza facciata ventilata",
    //   name: "opaqueWoodWithoutVentilation",
    // },
    // {
    //   value: "Opaca - calcestruzzo con facciata ventilata",
    //   name: "opaqueConcreteWithVentilation",
    // },
    // {
    //   value: "Opaca - calcestruzzo senza facciata ventilata",
    //   name: "opaqueConcreteWithoutVentilation",
    // },
    // {
    //   value: "Opaca - muratura con facciata ventilata",
    //   name: "opaqueWallingWithVentilation",
    // },
    // {
    //   value: "Opaca - muratura senza facciata ventilata",
    //   name: "opaqueWallingWithoutVentilation",
    // },
    {
      value: "Opaca - finestre",
      name: "opaqueWindows",
    },
    { value: "Opaca - retrofit modulare", name: "opaqueModularRetrofit" },
    //{value: "Opaca - Finestre", name: "windows"},
  ];
  let fancadeTypeOptions: any[] = [];
  fancadeTypes.map((type) => {
    fancadeTypeOptions.push({
      value: type.value,
      text: i18n.t("selectOptions." + type.name),
    });
  });

  return fancadeTypeOptions;
};

export const getPrefabricatedLevelsOptions = (): any[] => {
  const prefabricationLevels = [
    "Basso - 80% dell´assemblaggio eseguito in cantiere",
    "Medio - dal 40% all´ 80% dell´assemblaggio eseguito in cantiere",
    "Alto - meno del 40% dell´assemblaggio eseguito in cantiere",
  ];
  let prefabricationLevelsOptions: any[] = [];
  prefabricationLevels.map((type) => {
    prefabricationLevelsOptions.push({
      value: type,
      text: type,
    });
  });
  return prefabricationLevelsOptions;
};

export const getSystemsIntegrationsOptions = (): any[] => {
  const systemsIntegrations = [
    "BIPV - Moduli fotovoltaici integrati negli edifici",
    "Moduli solare termico",
    "Sistemi di schermatura",
    "Apparecchi per la ventilazione",
    "Sistemi di condizionamento aria",
  ];
  let systemsIntegrationsOptions: any[] = [];
  systemsIntegrations.map((type) => {
    systemsIntegrationsOptions.push({
      value: type,
      text: type,
    });
  });

  return systemsIntegrationsOptions;
};

export const getProjectRefLifeOptions = (): any[] => {
  const refLife = [25, 30, 35];
  let refLifeOptions: any[] = [];
  refLife.map((type) => {
    refLifeOptions.push({
      value: type,
      text: type,
    });
  });

  return refLifeOptions;
};

export const getFancadeCopyOptions = (): any[] => {
  return [
    {
      value: "all",
      text: i18n.t("titles.details.all"),
    },
    {
      value: "fancadeTechnicalProject",
      text: i18n.t("titles.details.fancadeTechnicalProject"),
    },
    {
      value: "fancadeProject",
      text: i18n.t("titles.details.fancadeProject"),
    },
    {
      value: "fancadeProduction",
      text: i18n.t("titles.details.fancadeProduction"),
    },
    {
      value: "transport",
      text: i18n.t("titles.details.transport"),
    },
    {
      value: "buildingSite",
      text: i18n.t("titles.details.buildingSite"),
    },
    {
      value: "serviceLife",
      text: i18n.t("titles.details.serviceLife"),
    },
    {
      value: "maintenance",
      text: i18n.t("titles.details.maintenance"),
    },
    {
      value: "endOfLife",
      text: i18n.t("titles.details.endOfLife"),
    },
  ];
};
