import React, { useCallback } from "react";
import ReactDOM from "react-dom";
import { Button, Center, Flex, Stack, Text, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";
import theme from "./../theme/theme";
import { Redirect, useHistory, withRouter } from "react-router-dom";
import { PrimaryButton } from "../components/Button";
import { PDFDownloadLink } from "../components/PDFDownloadLink";
import { PDFDocument } from "../components/PDFDocument";
import { transparentize } from "polished";

const PDFDownloadPage: React.FC = (props: any) => {
  const { t } = useTranslation();
  const history = useHistory();

  const toast = useToast();

  if (!props.location.state) return <Redirect to={"/"} />;
  return (
    <Center bg={theme.colors.background} style={{ minHeight: "100vh" }}>
      <StyledExternalFlex
        bg={theme.colors.formBackground}
        rounded={"10"}
        justifyContent={"center"}
        flexDirection={"column"}
      >
        <PDFDownloadLink
          pdfName={t("pdfReport")}
          document={<PDFDocument pages={props.location.state.pdf} />}
        >
          <PrimaryButton>{"Download PDF"}</PrimaryButton>
        </PDFDownloadLink>
        <StyledButtonGrey
          marginTop={"1rem"}
          onClick={() => {
            history.push(props.location.state.redirectUrl);
          }}
        >
          {t("buttons.goBack")}
        </StyledButtonGrey>
      </StyledExternalFlex>
    </Center>
  );
};

const StyledExternalFlex = styled(Flex)`
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  width: 25vw;
`;

const StyledButtonGrey = styled(Button)`
  border-radius: 500rem;
  color: ${(props) => (!props.disabled ? "white" : "black")};

  background-color: ${(props) =>
    !props.disabled ? "grey" : transparentize(0.85, "grey")};

  &:hover {
    background-color: ${transparentize(0.5, "grey")};
  }
`;

export default withRouter(PDFDownloadPage);
