import { Button } from "@chakra-ui/button";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormErrorMessage,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Formik, Form, Field, FieldProps } from "formik";
import { useTranslation } from "react-i18next";
import { PrimaryButton, SecondaryButton } from "./Button";

type Props = {
  isOpen: any;
  onClose: any;
  onSubmit: any;
};

export const ChangeFancadeNameModal: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("warnings.chooseFancadeName")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{
              customName: "",
            }}
            onSubmit={(data: any) => {
              if (data.customName === "") data.customName = null;
              props.onSubmit(data);
            }}
          >
            {(formikProps) => (
              <Form>
                <Stack spacing={"1rem"}>
                  <Text>{t("warnings.resetFancadeName")}</Text>
                  <Field name={"customName"}>
                    {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                      <FormControl
                        isInvalid={Boolean(meta.error) && meta.touched}
                      >
                        <Input
                          {...field}
                          name={field.name}
                          type={"text"}
                          value={field.value}
                          onChange={(event: any) =>
                            setFieldValue(field.name, event.target.value)
                          }
                        />
                        <FormErrorMessage name={"customName"}>
                          {meta.error}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <PrimaryButton type={"submit"} style={{ marginTop: "3em" }}>
                    {t("buttons.updateFancadeName")}
                  </PrimaryButton>
                </Stack>
              </Form>
            )}
          </Formik>
        </ModalBody>

        <ModalFooter>
          <SecondaryButton colorScheme="blue" mr={3} onClick={props.onClose}>
            {t("buttons.cancel")}
          </SecondaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
