import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

type Props = {
  pages: any[];
};

export const PDFDocument: React.FC<Props> = (props) => {
  return <Document>{props.pages}</Document>;
};
