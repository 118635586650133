import { makeAutoObservable } from "mobx";
import api from "../services/api";
import { toUser } from "../services/utils";
import { Stores, User } from "./../types";

export class SessionStore {
  isInitialized = false;
  isSubmitting = false;
  stores: Stores;
  sessionToken: string | undefined = undefined;
  currentPDFToDownload: any = undefined;

  constructor(stores: Stores) {
    this.stores = stores;
    makeAutoObservable(this);
  }

  user: User | null = null;

  setCurrentPDFToDownload = (currentPDFToDownload: any) => {
    this.currentPDFToDownload = currentPDFToDownload;
  };
  get isLogged() {
    return this.user != null;
  }

  getCurrentUser = async () => {
    this.user = toUser(await api.getCurrentUser());
    return { ...this.user };
  };

  login = async (email: string, password: string) => {
    this.isSubmitting = true;
    try {
      this.user = toUser(await api.login(email, password));
      this.sessionToken = api.getSessionToken();
      this.persistSession();
      this.getCurrentUser();
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
  };

  initialize = async () => {
    try {
      this.restoreSession();
      if (this.sessionToken) {
        this.user = toUser(await api.getCurrentUser());
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.isInitialized = true;
      console.log("initialized");
    }
  };

  restoreSession = async () => {
    const data = localStorage.getItem("session");
    const sessionData = data ? JSON.parse(data) : null;

    if (sessionData) {
      this.sessionToken = sessionData.token;
    }
  };
  persistSession = async () => {
    const data = {
      token: this.sessionToken,
    };

    localStorage.setItem("session", JSON.stringify(data));
  };

  resetSession = () => {
    this.user = null;
    this.sessionToken = undefined;
    localStorage.removeItem("session");
  };
}
