import React, { useCallback } from "react";
import ReactDOM from "react-dom";
import {
  Button,
  Flex,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../../../../hooks/useStores";
import { Redirect, useParams } from "react-router-dom";
import { Fancade, Project } from "../../../../../../types";

import styled from "styled-components";
import { transparentize } from "polished";
import theme from "../../../../../../theme/theme";
import { AiFillEdit, AiFillFolder } from "react-icons/ai";
import { DetailsTable } from "../../DetailsTable";
import { FancadeProductionForm } from "./FancadeProductionForm";
import {
  calculateBenchmark,
  calculateLaborTotal,
  calculateMaterialsTotal,
  calculateProductionTotal,
  toFancadeAPI,
} from "../../../../../../services/utils";
import { DeleteAlert } from "../../../../../Alert";
import { ChangeFancadeNameModal } from "../../../../../ChangeFancadeNameModal";

interface RouteParams {
  projectId: string;
}

type Props = {
  formikProps: any;
  tabIndex: any;
  setTabIndex: any;
  isBenchmark: boolean;
};

export const FancadeProduction: React.FC<Props> = observer((props) => {
  const { t } = useTranslation();
  const { session, ui, projects } = useStores();
  const { projectId } = useParams<RouteParams>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [deleteFancadeOpen, setDeleteFancadeOpen] = React.useState(false);
  const [fancadeToUpdate, setFancadeToUpdate] = React.useState<number>(0);
  const [update, setUpdate] = React.useState(false);

  ui.setActiveScreen("projects");

  let tabs: any[] = [];
  let tabPanels: any[] = [];

  const handleTabsChange = (index: number) => {
    props.setTabIndex(index);
  };

  const currentProject = props.formikProps.values;
  const getOneFancadeKPIsRows = (fancade: Fancade) => {
    if (fancade && fancade.fancadeProduction) {
      let kpiProduction = 0;

      if (
        +calculateProductionTotal(fancade) &&
        fancade.fancadeTechnicalProject.surface
      )
        kpiProduction =
          +calculateProductionTotal(fancade) /
          +fancade.fancadeTechnicalProject.surface;
      let rows = [
        {
          name: t("formLabels.details.fancadeProduction.totalProductionPrice"),
          values: [
            calculateProductionTotal(fancade).toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " €",
          ],
        },
        {
          name: t("formLabels.details.fancadeProduction.totalMaterialsPrice"),
          values: [
            calculateMaterialsTotal(fancade).toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " €",
          ],
        },
        {
          name: t("formLabels.details.fancadeProduction.totalLaborPrice"),
          values: [
            calculateLaborTotal(fancade).toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " €",
          ],
        },
        {
          name: t("formLabels.details.fancadeProduction.fancadeSurface"),
          values: [
            (fancade.fancadeTechnicalProject.surface
              ? +fancade.fancadeTechnicalProject.surface
              : 0
            ).toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " m²",
          ],
        },
        {
          name: t("formLabels.details.fancadeProduction.priceMaterialsRatio"),
          values: [
            (+calculateProductionTotal(fancade) != 0
              ? (+calculateMaterialsTotal(fancade) /
                  +calculateProductionTotal(fancade)) *
                100
              : 0
            ).toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " %",
            ,
          ],
        },
        {
          name: t("formLabels.details.fancadeProduction.priceLaborRatio"),
          values: [
            (+calculateProductionTotal(fancade) != 0
              ? (+calculateLaborTotal(fancade) /
                  +calculateProductionTotal(fancade)) *
                100
              : 0
            ).toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " %",
            ,
          ],
        },
        {
          name: t("formLabels.details.fancadeProduction.kpiProduction"),
          values: [
            kpiProduction.toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " €/m²",
          ],
        },
      ];

      return rows;
    }
    return [];
  };

  const getBenchmarkKPIRows = (
    project: Project,
    currentFancadeIndex: number
  ) => {
    let benchmark;
    let current;
    //@ts-ignore
    if (project && project?.fancades[0]) benchmark = project?.fancades[0];
    //@ts-ignore
    if (project && project?.fancades[currentFancadeIndex])
      current = project?.fancades[currentFancadeIndex];

    let kpiProduction = 0;

    if (
      benchmark &&
      +calculateProductionTotal(benchmark) &&
      benchmark.fancadeTechnicalProject.surface
    )
      kpiProduction =
        +calculateProductionTotal(benchmark) /
        +benchmark.fancadeTechnicalProject.surface;

    if (
      current &&
      +calculateProductionTotal(current) &&
      current.fancadeTechnicalProject.surface
    )
      kpiProduction -=
        +calculateProductionTotal(current) /
        +current.fancadeTechnicalProject.surface;

    if (benchmark && current) {
      let rows = [
        {
          name: t("formLabels.details.fancadeProduction.totalProductionPrice"),
          values: [
            calculateBenchmark(
              +calculateProductionTotal(benchmark),
              +calculateProductionTotal(current)
            ).toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " €",
            ,
          ],
        },
        {
          name: t("formLabels.details.fancadeProduction.kpiProduction"),
          values: [
            kpiProduction.toLocaleString("cs-CZ", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + " €/m²",
          ],
        },
      ];
      return rows;
    } else return [];
  };

  const getAllFancadeKPIsRows = (project: Project) => {
    let production = 0;
    let materials = 0;
    let labor = 0;
    let surface = 0;

    let priceMaterialsRatio = 0;
    let priceLaborRatio = 0;

    let kpiProduction = 0;

    project?.fancades?.map((fancade, index) => {
      production += calculateProductionTotal(fancade);
      materials += calculateMaterialsTotal(fancade);
      labor += calculateLaborTotal(fancade);
      if (fancade.fancadeTechnicalProject.surface)
        surface += fancade.fancadeTechnicalProject.surface;
    });

    if (production != 0) {
      priceMaterialsRatio += +materials / +production;
      priceLaborRatio += +labor / +production;
    }

    if (surface != 0) kpiProduction = +production / +surface;

    props.formikProps.values.kpiFancadeProduction = {
      totalProductionPrice: +production,
      totalMaterialsPrice: +materials,
      totalManpowerPrice: +labor,
      //totalFancadeSurface
      materialsProductionPriceRatio: +priceMaterialsRatio,
      manpowerProductionPriceRatio: +priceLaborRatio,
      totalProductionKPI: kpiProduction,
    };

    return [
      {
        name: t("formLabels.details.fancadeProduction.totalProductionPrice"),
        values: [
          production.toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " €",
        ],
      },
      {
        name: t("formLabels.details.fancadeProduction.totalMaterialsPrice"),
        values: [
          materials.toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " €",
        ],
      },
      {
        name: t("formLabels.details.fancadeProduction.totalLaborPrice"),
        values: [
          labor.toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " €",
        ],
      },
      {
        name: t("formLabels.details.fancadeProduction.fancadeSurface"),
        values: [
          (+surface).toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " m²",
        ],
      },
      {
        name: t("formLabels.details.fancadeProduction.priceMaterialsRatio"),
        values: [
          (priceMaterialsRatio * 100).toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " %",
        ],
      },
      {
        name: t("formLabels.details.fancadeProduction.priceLaborRatio"),
        values: [
          (priceLaborRatio * 100).toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " %",
        ],
      },
      {
        name: t("formLabels.details.fancadeProduction.kpiProduction"),
        values: [
          kpiProduction.toLocaleString("cs-CZ", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + " €/m²",
        ],
      },
    ];
  };

  //Populate the tabs
  props.formikProps.values?.fancades?.map((fancade: Fancade, index: number) => {
    if (tabs.length == 0)
      tabs.push(
        <Tab alignItems={"center"}>
          <DeleteAlert
            title={t("warnings.deleteFancadeTitle")}
            body={t("warnings.deleteFancade")}
            isOpen={deleteFancadeOpen}
            setIsOpen={setDeleteFancadeOpen}
            cancelText={t("warnings.cancel")}
            continueText={t("warnings.confirm")}
            onContinue={async () => {
              deleteFancade(fancade, index);
            }}
          />
          <Text color={theme.colors.darkGrey2}>
            {fancade.customName
              ? currentProject.isBenchmark && index == 0
                ? t("tabs.details.fancadeTechnicalProject.benchmark") +
                  fancade.customName
                : fancade.customName
              : currentProject.isBenchmark
              ? t("tabs.details.fancadeTechnicalProject.firstTab")
              : t("tabs.details.fancadeTechnicalProject.tabsAfterFirst") +
                (index + 1)}
          </Text>
          <AiFillEdit
            style={{ marginLeft: "0.3rem" }}
            onClick={() => {
              setFancadeToUpdate(index);
              onOpen();
            }}
          />
          <Text
            onClick={() => setDeleteFancadeOpen(true)}
            color={theme.colors.euracOrange}
            paddingLeft={"0.5rem"}
          >
            <strong>x</strong>
          </Text>
        </Tab>
      );
    else
      tabs.push(
        <Tab alignItems={"center"}>
          <DeleteAlert
            title={t("warnings.deleteFancadeTitle")}
            body={t("warnings.deleteFancade")}
            isOpen={deleteFancadeOpen}
            setIsOpen={setDeleteFancadeOpen}
            cancelText={t("warnings.cancel")}
            continueText={t("warnings.confirm")}
            onContinue={async () => {
              deleteFancade(fancade, index);
            }}
          />
          <Text color={theme.colors.darkGrey2}>
            {fancade.customName
              ? currentProject.isBenchmark && index == 0
                ? t("tabs.details.fancadeTechnicalProject.benchmark") +
                  fancade.customName
                : fancade.customName
              : t("tabs.details.fancadeTechnicalProject.tabsAfterFirst") +
                (tabs.length + 1)}
          </Text>
          <AiFillEdit
            style={{ marginLeft: "0.3rem" }}
            onClick={() => {
              setFancadeToUpdate(index);
              onOpen();
            }}
          />
          <Text
            onClick={() => setDeleteFancadeOpen(true)}
            color={theme.colors.euracOrange}
            paddingLeft={"0.5rem"}
          >
            <strong>x</strong>
          </Text>
        </Tab>
      );

    tabPanels.push(
      <TabPanel>
        <Stack spacing={"4rem"}>
          <FancadeProductionForm
            formikProps={props.formikProps}
            currentFancade={fancade}
            fancadeNumber={tabPanels.length + 1}
          />

          <DetailsTable
            columnsTitles={[
              t("subHeadings.fancadeProduction.fancadeProductionKPIsOne"),
            ]}
            /* @ts-ignore */
            rows={getOneFancadeKPIsRows(fancade)}
          />
          {index > 0 && props.isBenchmark ? (
            <DetailsTable
              columnsTitles={[
                t("subHeadings.fancadeProduction.benchmarkDifference"),
              ]}
              /* @ts-ignore */
              rows={getBenchmarkKPIRows(currentProject, index)}
            />
          ) : (
            <></>
          )}
          {currentProject.fancades && currentProject.fancades.length > 1 ? (
            <DetailsTable
              columnsTitles={[
                t("subHeadings.fancadeProduction.fancadeProductionKPIsAll"),
              ]}
              /* @ts-ignore */
              rows={getAllFancadeKPIsRows(currentProject, index)}
            />
          ) : (
            <></>
          )}
        </Stack>
      </TabPanel>
    );
  });

  const addFancade = useCallback(() => {
    if (!currentProject.fancades) currentProject.fancades = [];
    currentProject.fancades.push(projects.getEmptyFancade(+projectId));
    if (currentProject && currentProject.fancades) {
      props.setTabIndex(currentProject.fancades.length - 1);
    }
    setUpdate(!update);
  }, [props.tabIndex, projects, currentProject, props.formikProps, update]);

  const deleteFancade = useCallback(
    (fancade: Fancade, index: number) => {
      if (fancade.id != -1) projects.deleteFancade(toFancadeAPI(fancade));
      props.formikProps.values.fancades.splice(index, 1);
      if (props.tabIndex == index && props.tabIndex > 0) {
        props.setTabIndex(props.tabIndex - 1);
      }
    },
    [
      props.tabIndex,
      projects,
      currentProject,
      props.formikProps.values.fancades,
    ]
  );

  if (session.user)
    return (
      <Stack w={"100%"}>
        <ChangeFancadeNameModal
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={(values: any) => {
            if (currentProject && currentProject.fancades)
              currentProject.fancades[fancadeToUpdate].customName =
                values.customName;
            onClose();
          }}
        />
        <Tabs
          index={props.tabIndex}
          onChange={handleTabsChange}
          variant={"enclosed"}
          w={"100%"}
        >
          <TabList>
            {tabs}
            <Button variant={"ghost"} onClick={addFancade}>
              <Text color={theme.colors.euracOrange}>+</Text>
            </Button>
          </TabList>
          <TabPanels>{tabPanels}</TabPanels>
        </Tabs>
      </Stack>
    );
  else return <Redirect to={"/"} />;
});
