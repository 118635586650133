import { Global } from "@emotion/react";

const Fonts = () => (
  <Global
    styles={`
      /* latin */
      @font-face {
        font-family: 'Inter';
        src: url('../assets/fonts/inter.ttf') format('truetype');
      }
      `}
  />
);
export default Fonts;
