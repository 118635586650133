import React, { useCallback, useState } from "react";
import ReactDOM from "react-dom";
import {
  Button,
  ButtonGroup,
  Center,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../hooks/useStores";

import { TopBar } from "./TopBar";
import { ProjectsTable } from "./ProjectsTable";
import { Project } from "../../../types";
import { PrimaryButton, SecondaryButton } from "../../Button";
import { CategoryForm } from "../../CategoryForm";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router";
import { ChangeProjectCategoryModal } from "../../ChangeProjectCategoryModal";
import { DeleteAlert } from "../../Alert";

export const List: React.FC = observer(() => {
  const { t } = useTranslation();
  const { projects, ui, session } = useStores();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentFilter, setCurrentFilter] = useState("all");
  const [isMoveToCategoryModalOpen, setIsMoveToCategoryModalOpen] =
    useState(false);
  const [update, setUpdate] = useState(false);
  const [deleteProjectOpen, setDeleteProjectOpen] = useState(false);
  const toast = useToast();
  const history = useHistory();

  ui.setActiveScreen("projects");

  const onNewProjectSubmit = useCallback(
    async (project: Project) => {
      try {
        await projects.addProject(project);
        toast({
          title: t("successes.creates.projectSuccessful"),
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      } catch (e) {
        toast({
          title: t("errors.newProject.projectCreation"),
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      }
    },
    [projects]
  );

  const isDuplicateButtonDisabled = () => {
    if (projects.selectedProjectsIds.size == 1) return false;
    return true;
  };

  const isComparisonButtonDisabled = () => {
    if (projects.selectedProjectsIds.size == 2) return false;
    return true;
  };

  const isMoveToCategoryButtonDisabled = () => {
    if (projects.selectedProjectsIds.size != 0) return false;
    return true;
  };

  const isProjectSelected = (element: Project, index: any, array: any) => {
    return projects.selectedProjectsIds.has(element.id);
  };

  const compare = useCallback(() => {
    if (session.user) {
      history.push({
        pathname: `/user/${session.user.id}/comparison`,
      });
    }
  }, []);

  const duplicateProject = useCallback(
    async (project) => {
      try {
        await projects.duplicateProject(project);
        toast({
          title: t("successes.creates.projectSuccessful"),
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      } catch (e) {
        toast({
          title: t("errors.newProject.projectCreation"),
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      }
    },
    [projects, projects.projects]
  );

  return (
    <Stack w={"95%"} marginLeft={"4%"} spacing={"1rem"}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("buttons.addCategory")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CategoryForm
              onSubmit={async (category: string) => {
                await projects.createCategory({
                  id: -1,
                  name: category,
                  projects: [],
                });
                setUpdate(!update);
                onClose();
                toast({
                  title: t("successes.updates.categorySuccessful"),
                  status: "success",
                  duration: 4000,
                  isClosable: true,
                });
              }}
            />
          </ModalBody>

          <ModalFooter>
            <SecondaryButton onClick={onClose}>
              {t("buttons.close")}
            </SecondaryButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ChangeProjectCategoryModal
        isVisible={isMoveToCategoryModalOpen}
        categories={projects.categories}
        onSubmit={(categoryId) => {
          projects.updateProjectsCategory(
            Array.from(projects.selectedProjectsIds),
            categoryId
          );
          setIsMoveToCategoryModalOpen(false);
          toast({
            title: t("successes.updates.projectSuccessful"),
            status: "success",
            duration: 4000,
            isClosable: true,
          });
        }}
        onDismiss={() => setIsMoveToCategoryModalOpen(false)}
      />
      <TopBar onSubmit={onNewProjectSubmit} />
      <Flex>
        {session.user?.role == "admin" && (
          <>
            <Select
              onChange={(e) => {
                setCurrentFilter(e.target.value);
              }}
              w={"40%"}
            >
              <option value={"all"}>{t("selectOptions.allProjects")}</option>
              <option value={"admin"}>
                {t("selectOptions.adminOnlyProjects")}
              </option>
              <option value={"users"}>
                {t("selectOptions.usersOnlyProjects")}
              </option>
            </Select>
          </>
        )}
        <PrimaryButton marginLeft={"1rem"} onClick={onOpen}>
          {t("buttons.addCategory")}
        </PrimaryButton>
        <Spacer />
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            marginRight={"3rem"}
          >
            {t("buttons.actions")}
          </MenuButton>
          <MenuList>
            <MenuItem
              isDisabled={isDuplicateButtonDisabled()}
              onClick={async () => {
                let t = { ...projects.projects.filter(isProjectSelected)[0] };
                t.fancades = await projects.getProjectFancades(t.id);

                await duplicateProject(t);
              }}
            >
              {t("buttons.duplicateProject")}
            </MenuItem>
            <MenuItem
              isDisabled={isMoveToCategoryButtonDisabled()}
              onClick={() => {
                setIsMoveToCategoryModalOpen(true);
              }}
            >
              {t("buttons.changeCategory")}
            </MenuItem>
            <MenuItem
              isDisabled={isComparisonButtonDisabled()}
              onClick={() => compare()}
            >
              {t("buttons.compare")}
            </MenuItem>
            <MenuItem
              isDisabled={isMoveToCategoryButtonDisabled()}
              onClick={() => {
                setDeleteProjectOpen(true);
              }}
            >
              {t("buttons.deleteProjects")}
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <ProjectsTable filter={currentFilter} />
      <DeleteAlert
        title={t("warnings.deleteProjectTitle")}
        body={t("warnings.deleteProject")}
        isOpen={deleteProjectOpen}
        setIsOpen={setDeleteProjectOpen}
        cancelText={t("warnings.cancel")}
        continueText={t("warnings.confirm")}
        onContinue={async () => {
          try {
            await projects.deleteSelectedProjects();
            toast({
              title: t("successes.deletes.projectsSuccessful"),
              status: "success",
              duration: 4000,
              isClosable: true,
            });
          } catch (e) {
            console.log(e);
          }
        }}
        projects={projects.getSelectedProjectListElements()}
      />
    </Stack>
  );
});
