import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  isOpen: any;
  setIsOpen: any;
};
export const SurfaceAlert: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  const cancelRef = React.useRef<any>();
  const { t } = useTranslation();
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          setIsOpen(false);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Attenzione</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>{t("warnings.surfaceTooHigh")}</AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {t("buttons.close")}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
