import { Text } from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/modal";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { UserForm } from "../UserForm";

type Props = {
  isVisible: boolean;
  onSubmit: (data: any) => void;
  onDismiss: () => void;
};

export const UserModal: React.FC<Props> = ({
  isVisible,
  onSubmit,
  onDismiss,
}) => {
  return (
    <Modal isOpen={isVisible} size={"xl"} onClose={onDismiss}>
      <ModalOverlay />
      <ModalContent maxW={"820px"}>
        <ModalCloseButton />
        <ModalBody style={{ padding: "2rem" }}>
          <UserForm
            user={null}
            onSubmit={(data) => {
              onSubmit(data);
            }}
            isLoading={false}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const StyledLinkText = styled(Text)`
  cursor: pointer;
  color: var(--chakra-colors-teal-600);
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0.5rem;
`;
