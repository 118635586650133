import styled from "styled-components";
import { transparentize } from "polished";

import theme from "../../theme/theme";

export const NavItem = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  min-height: 42px;
  margin: 0.5em;
  margin-left: 0;
  opacity: ${(props) => (props.isActive ? 1 : 0.75)};
  font-weight: ${(props) => (props.isActive ? 500 : 400)};
  border-radius: 500rem;
  color: ${(props) => (props.isActive ? "white" : "black")};
  background-color: ${(props) =>
    props.isActive
      ? transparentize(0, theme.colors.euracOrange)
      : "transparent"};

  cursor: pointer;
  // padding-left: $ {(props) => props.theme.global.padding.xsmall};
  // padding-right: $ {(props) => props.theme.global.padding.xsmall};

  transition: background-color 1s cubic-bezier(0.2, 0, 0, 1) 0s,
    color 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;

  // svg {
  //   fill: $ {(props) => props.theme.global.colors.secondaryColor};
  //   transition: fill 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  // }

  &:hover {
    opacity: 1;
  }

  &.active {
    // opacity: 1;
    // font-weight: 500;
    // background-color: $ {(props) =>
    //   transparentize(0.9, props.theme.global.colors.brand)};
    // color: $ {(props) => props.theme.global.colors.brand};

    // svg {
    //   fill: $ {(props) => props.theme.global.colors.brand};
    // }
  }
`;
