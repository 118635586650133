import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Checkbox,
  CircularProgress,
} from "@chakra-ui/react";

import React from "react";
import ReactDOM from "react-dom";
import {
  Button,
  ButtonGroup,
  Center,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import { Project, TableRow } from "../../../../../types";
import {
  getAllFancadesCostsPerPhaseRows,
  getCostsPerPhaseBenchmarkRows,
  getCostsPerPhaseRows,
} from "../../../../../services/utils";
import { DetailsTable } from "../../details/DetailsTable";

type Props = {
  project: Project;
  fancadeIndex: number;
  benchmark?: boolean;
};

export const CostPerPhaseTable: React.FC<Props> = observer((props) => {
  const { t } = useTranslation();

  let rows: TableRow[] = [];
  if (props.fancadeIndex == -1) {
    rows = getAllFancadesCostsPerPhaseRows(
      props.project,
      props.benchmark ? true : false
    );
  } else if (props.benchmark) {
    rows = getCostsPerPhaseBenchmarkRows(props.project, props.fancadeIndex);
  } else {
    rows = getCostsPerPhaseRows(props.project, props.fancadeIndex);
  }
  if (rows[0]) rows[0].name = t("tablesVoices.report.priceForPhase.project");
  if (rows[1])
    rows[1].name = t("tablesVoices.report.priceForPhase.fancadeProduction");
  if (rows[2])
    rows[2].name = t(
      "tablesVoices.report.priceForPhase.transportToConstructionSite"
    );
  if (rows[3])
    rows[3].name = t("tablesVoices.report.priceForPhase.installation");
  if (rows[4])
    rows[4].name = t("tablesVoices.report.priceForPhase.serviceLife");
  if (rows[5])
    rows[5].name = t("tablesVoices.report.priceForPhase.maintenance");
  if (rows[6]) rows[6].name = t("tablesVoices.report.priceForPhase.disposal");
  if (rows[7])
    rows[7].name = t("tablesVoices.report.priceForPhase.recyclingRevenues");
  if (rows[8])
    rows[8].name = t("tablesVoices.report.priceForPhase.totalInvestment");
  if (rows[9])
    rows[9].name = t("tablesVoices.report.priceForPhase.LCCInvestment");

  return (
    <Flex w={"99%"}>
      {props.benchmark && props.fancadeIndex != -1 ? (
        <Stack w={"100%"} paddingTop={"1rem"}>
          <Text fontSize={"2xl"}>
            {t("tablesHeaders.report.costForPhaseBenchmark")}
          </Text>
          <DetailsTable
            columnsTitles={[
              t("tablesHeaders.report.phase"),
              t("fancade") + " #" + (+props.fancadeIndex + 1),
              "Benchmark",
              t("tablesHeaders.report.difference"),
            ]}
            rows={rows}
            colorValues={props.benchmark}
          />
        </Stack>
      ) : (
        <Stack w={"100%"} paddingTop={"1rem"}>
          <Text fontSize={"2xl"}>{t("tablesHeaders.report.costForPhase")}</Text>
          <DetailsTable
            columnsTitles={[
              t("tablesHeaders.report.phase"),
              t("tablesHeaders.report.total"),
              t("tablesHeaders.report.investmentPercentage"),
              t("tablesHeaders.report.lccPercentage"),
            ]}
            rows={rows}
            colorValues={props.benchmark}
          />
        </Stack>
      )}
    </Flex>
  );
});
