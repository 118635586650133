import {
  CircularProgress,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
} from "@chakra-ui/react";

import React, { useMemo } from "react";
import { Center, Stack, Text } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../../hooks/useStores";

import theme from "../../../../theme/theme";
import { CostPerPhaseTable } from "./tables/CostPerPhaseTable";
import { Project } from "../../../../types";
import { KPITable } from "./tables/KPITable";
import { DetailsTable } from "../details/DetailsTable";
import { BenchmarkTab } from "./BenchmarkTab";

type Props = {
  projectId: number;
  isBenchmark: boolean;
};

export const ReportTables: React.FC<Props> = observer((props) => {
  const { t } = useTranslation();
  const { ui, projects } = useStores();
  ui.setActiveScreen("projects");

  const [tabIndex, setTabIndex] = React.useState(0);
  //Find the current project
  const currentProject: Project | undefined = useMemo(
    () => projects.projects.find((project) => project.id == props.projectId),
    [projects.projects]
  );
  if (projects.isFetchingProjects) {
    return (
      <Center height={"100%"}>
        <CircularProgress
          size={"2em"}
          color={theme.colors.euracOrange}
          isIndeterminate
        />
      </Center>
    );
  }

  if (currentProject == undefined)
    return <Center>{t("errors.generic")}</Center>;

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  return (
    <Stack w={"99%"} h={"85vh"} overflowY="scroll">
      <Tabs
        index={tabIndex}
        onChange={handleTabsChange}
        variant={"enclosed"}
        w={"100%"}
        paddingTop={"0.2rem"}
        paddingLeft={"0.1rem"}
      >
        <TabList>
          {currentProject?.fancades?.map((fancade, index) => (
            <Tab alignItems={"center"}>
              <Text color={theme.colors.darkGrey2}>
                {fancade.customName
                  ? currentProject.isBenchmark && index == 0
                    ? t("tabs.details.fancadeTechnicalProject.benchmark") +
                      fancade.customName
                    : fancade.customName
                  : currentProject.isBenchmark && index == 0
                  ? t("tabs.details.fancadeTechnicalProject.firstTab")
                  : t("tabs.details.fancadeTechnicalProject.tabsAfterFirst") +
                    (index + 1)}
              </Text>
            </Tab>
          ))}
          {!currentProject.isBenchmark &&
            currentProject.fancades &&
            currentProject.fancades.length > 0 && (
              <Tab alignItems={"center"}>
                <Text color={theme.colors.darkGrey2}>
                  {t("tablesVoices.report.allFancadesTab")}
                </Text>
              </Tab>
            )}
          {currentProject.isBenchmark &&
            currentProject.fancades &&
            currentProject.fancades.length > 1 && (
              <Tab alignItems={"center"}>
                <Text color={theme.colors.darkGrey2}>
                  {t("tablesVoices.report.benchmarkTab")}
                </Text>
              </Tab>
            )}
        </TabList>
        <TabPanels>
          {currentProject?.fancades?.map((fancade, index) => (
            <TabPanel>
              <Stack w={"100%"} spacing={"2rem"}>
                <CostPerPhaseTable
                  project={currentProject}
                  fancadeIndex={index}
                />
                {/* {props.isBenchmark && index > 0 && (
                  <CostPerPhaseTable
                    project={currentProject}
                    fancadeIndex={index}
                    benchmark={true}
                  />
                )} */}
                <KPITable project={currentProject} fancadeIndex={index} />
                {/* {props.isBenchmark && index > 0 && (
                  <KPITable
                    project={currentProject}
                    fancadeIndex={index}
                    benchmark={true}
                  />
                )} */}
              </Stack>
            </TabPanel>
          ))}
          {currentProject.isBenchmark &&
            currentProject.fancades &&
            currentProject.fancades.length > 1 && (
              <TabPanel>
                <BenchmarkTab project={currentProject} />
              </TabPanel>
            )}
          {!currentProject.isBenchmark &&
            currentProject.fancades &&
            currentProject.fancades.length > 0 && (
              <TabPanel>
                <Stack w={"100%"} spacing={"2rem"}>
                  <CostPerPhaseTable
                    project={currentProject}
                    fancadeIndex={-1}
                    benchmark={props.isBenchmark}
                  />
                  <KPITable project={currentProject} fancadeIndex={-1} />
                </Stack>
              </TabPanel>
            )}
        </TabPanels>
      </Tabs>
      {!currentProject.fancades ||
        (currentProject?.fancades?.length == 0 && (
          <DetailsTable columnsTitles={[]} rows={[]} />
        ))}
    </Stack>
  );
});
