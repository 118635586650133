import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Checkbox,
  CircularProgress,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
} from "@chakra-ui/react";

import React from "react";
import ReactDOM from "react-dom";
import {
  Button,
  ButtonGroup,
  Center,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import theme from "../../theme/theme";
import { useStores } from "../../hooks/useStores";
import { Project } from "../../types";
import { DetailsTable } from "../projects/summary/details/DetailsTable";
import { CostPerPhaseTable } from "../projects/summary/report/tables/CostPerPhaseTable";
import { KPITable } from "../projects/summary/report/tables/KPITable";
import {
  getBuildingSiteScompositionPlotValues,
  getInstallationScompositionPlotValues,
  getToolsScompositionPlotValues,
} from "../../services/utils";
import Plot from "react-plotly.js";

type Props = {
  projectId: number;
  isBenchmark: boolean;
};

export const ConstructionPlots: React.FC<Props> = observer((props) => {
  const { t } = useTranslation();
  const { ui, projects } = useStores();
  ui.setActiveScreen("projects");
  const buildingSiteScompositionPlotTitle = t(
    "plotTitles.buildingSiteScomposition"
  );
  const buildingSiteScompositionPlotLabels = [
    t("plotTitles.installation"),
    t("plotTitles.security"),
  ];

  const buildingSiteScompositionPlotColors = [
    theme.colors.installationMarker,
    theme.colors.securityMarker,
  ];

  const installationScompositionPlotTitle = t(
    "plotTitles.installationScomposition"
  );
  const installationScompositionPlotLabels = [
    t("plotTitles.materials"),
    t("plotTitles.labor"),
    t("plotTitles.tools"),
  ];
  const installationScompositionPlotColors = [
    theme.colors.materialsMarker,
    theme.colors.laborMarker,
    theme.colors.toolsMarker,
  ];

  const toolsScompositionPlotTitle = t("plotTitles.toolsScomposition");
  const toolsScompositionPlotLabels = [
    t("plotTitles.crane"),
    t("plotTitles.elevatorPlatforms"),
    t("plotTitles.aerialPlatforms"),
    t("plotTitles.scaffolding"),
  ];
  const toolsScompositionPlotColors = [
    theme.colors.craneMarker,
    theme.colors.elevatorPlatformsMarker,
    theme.colors.aerialPlatformsMarker,
    theme.colors.scaffoldingMarker,
  ];

  const [tabIndex, setTabIndex] = React.useState(0);

  if (projects.isFetchingProjects) {
    return (
      <Center height={"100%"}>
        <CircularProgress
          size={"2em"}
          color={theme.colors.euracOrange}
          isIndeterminate
        />
      </Center>
    );
  }

  //Find the current project
  const currentProject: Project | undefined = projects.projects.find(
    (project) => project.id == props.projectId
  );

  if (currentProject == undefined)
    return <Center>{t("errors.generic")}</Center>;
  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };
  return (
    <Stack w={"99%"} h={"85vh"} overflowY={"scroll"}>
      <Tabs
        index={tabIndex}
        onChange={handleTabsChange}
        variant={"enclosed"}
        w={"100%"}
        paddingTop={"0.2rem"}
        paddingLeft={"0.1rem"}
      >
        <TabList>
          {currentProject?.fancades?.map((fancade, index) => (
            <Tab alignItems={"center"}>
              <Text color={theme.colors.darkGrey2}>
                {fancade.customName
                  ? currentProject.isBenchmark && index == 0
                    ? t("tabs.details.fancadeTechnicalProject.benchmark") +
                      fancade.customName
                    : fancade.customName
                  : currentProject.isBenchmark && index == 0
                  ? t("tabs.details.fancadeTechnicalProject.firstTab")
                  : currentProject.isBenchmark && index == 0
                  ? t("tabs.details.fancadeTechnicalProject.firstTab")
                  : t("tabs.details.fancadeTechnicalProject.tabsAfterFirst") +
                    (index + 1)}
              </Text>
            </Tab>
          ))}
          {!currentProject.isBenchmark &&
            currentProject.fancades &&
            currentProject.fancades.length > 0 && (
              <Tab alignItems={"center"}>
                <Text color={theme.colors.darkGrey2}>
                  {t("tablesVoices.report.allFancadesTab")}
                </Text>
              </Tab>
            )}
        </TabList>
        <TabPanels>
          {currentProject?.fancades?.map((fancade, index) => (
            <TabPanel w={"100%"}>
              <Stack w={"100%"}>
                {getBuildingSiteScompositionPlotValues(currentProject, index)
                  .length > 0 ? (
                  <Plot
                    data={[
                      {
                        y: [
                          getBuildingSiteScompositionPlotValues(
                            currentProject,
                            index
                          )[0],
                        ],
                        type: "bar",
                        name: buildingSiteScompositionPlotLabels[0],
                        marker: {
                          color: buildingSiteScompositionPlotColors[0],
                        },
                      },
                      {
                        y: [
                          getBuildingSiteScompositionPlotValues(
                            currentProject,
                            index
                          )[1],
                        ],
                        type: "bar",
                        name: buildingSiteScompositionPlotLabels[1],
                        marker: {
                          color: buildingSiteScompositionPlotColors[1],
                        },
                      },
                    ]}
                    layout={{
                      title: buildingSiteScompositionPlotTitle,
                      barmode: "stack",
                      yaxis: {
                        range: [0, 1],
                      },
                      xaxis: {
                        showticklabels: false,
                        range: [-1, 1],
                      },
                    }}
                  />
                ) : (
                  <Text textAlign={"center"} fontSize={"2xl"}>
                    {t("errors.noValuesPlot")}
                  </Text>
                )}
                {getInstallationScompositionPlotValues(currentProject, index)
                  .length > 0 ? (
                  <Plot
                    data={[
                      {
                        y: [
                          getInstallationScompositionPlotValues(
                            currentProject,
                            index
                          )[0],
                        ],
                        type: "bar",
                        name: installationScompositionPlotLabels[0],
                        marker: {
                          color: installationScompositionPlotColors[0],
                        },
                      },
                      {
                        y: [
                          getInstallationScompositionPlotValues(
                            currentProject,
                            index
                          )[1],
                        ],
                        type: "bar",
                        name: installationScompositionPlotLabels[1],
                        marker: {
                          color: installationScompositionPlotColors[1],
                        },
                      },
                      {
                        y: [
                          getInstallationScompositionPlotValues(
                            currentProject,
                            index
                          )[2],
                        ],
                        type: "bar",
                        name: installationScompositionPlotLabels[2],
                        marker: {
                          color: installationScompositionPlotColors[2],
                        },
                      },
                    ]}
                    layout={{
                      title: installationScompositionPlotTitle,
                      barmode: "stack",
                      yaxis: {
                        range: [0, 1],
                      },
                      xaxis: {
                        showticklabels: false,
                        range: [-1, 1],
                      },
                    }}
                  />
                ) : (
                  <Text textAlign={"center"} fontSize={"2xl"}>
                    {t("errors.noValuesPlot")}
                  </Text>
                )}
                {getToolsScompositionPlotValues(currentProject, index).length >
                0 ? (
                  <Plot
                    data={[
                      {
                        values: getToolsScompositionPlotValues(
                          currentProject,
                          index
                        ),
                        labels: toolsScompositionPlotLabels,
                        type: "pie",
                        marker: { colors: toolsScompositionPlotColors },
                      },
                    ]}
                    layout={{
                      title: toolsScompositionPlotTitle,
                    }}
                  />
                ) : (
                  <Text textAlign={"center"} fontSize={"2xl"}>
                    {t("errors.noValuesPlot")}
                  </Text>
                )}
              </Stack>
            </TabPanel>
          ))}

          {currentProject.fancades && currentProject.fancades.length > 0 && (
            <TabPanel w={"100%"} overflowX={"scroll"}>
              <Stack w={"100%"}>
                {getBuildingSiteScompositionPlotValues(
                  currentProject,
                  -1,
                  props.isBenchmark
                ).length > 0 ? (
                  <Plot
                    data={[
                      {
                        y: [
                          getBuildingSiteScompositionPlotValues(
                            currentProject,
                            -1,
                            props.isBenchmark
                          )[0],
                        ],
                        type: "bar",
                        name: buildingSiteScompositionPlotLabels[0],
                      },
                      {
                        y: [
                          getBuildingSiteScompositionPlotValues(
                            currentProject,
                            -1
                          )[1],
                        ],
                        type: "bar",
                        name: buildingSiteScompositionPlotLabels[1],
                      },
                    ]}
                    layout={{
                      title: buildingSiteScompositionPlotTitle,
                      barmode: "stack",
                      yaxis: {
                        range: [0, 1],
                      },
                      xaxis: {
                        showticklabels: false,
                        range: [-1, 1],
                      },
                    }}
                  />
                ) : (
                  <Text textAlign={"center"} fontSize={"2xl"}>
                    {t("errors.noValuesPlot")}
                  </Text>
                )}
                {getInstallationScompositionPlotValues(
                  currentProject,
                  -1,
                  props.isBenchmark
                ).length > 0 ? (
                  <Plot
                    data={[
                      {
                        y: [
                          getInstallationScompositionPlotValues(
                            currentProject,
                            -1,
                            props.isBenchmark
                          )[0],
                        ],
                        type: "bar",
                        name: installationScompositionPlotLabels[0],
                      },
                      {
                        y: [
                          getInstallationScompositionPlotValues(
                            currentProject,
                            -1,
                            props.isBenchmark
                          )[1],
                        ],
                        type: "bar",
                        name: installationScompositionPlotLabels[1],
                      },
                      {
                        y: [
                          getInstallationScompositionPlotValues(
                            currentProject,
                            -1,
                            props.isBenchmark
                          )[2],
                        ],
                        type: "bar",
                        name: installationScompositionPlotLabels[2],
                      },
                    ]}
                    layout={{
                      title: installationScompositionPlotTitle,
                      barmode: "stack",
                      yaxis: {
                        range: [0, 1],
                      },
                      xaxis: {
                        showticklabels: false,
                        range: [-1, 1],
                      },
                    }}
                  />
                ) : (
                  <Text textAlign={"center"} fontSize={"2xl"}>
                    {t("errors.noValuesPlot")}
                  </Text>
                )}
                {getToolsScompositionPlotValues(
                  currentProject,
                  -1,
                  props.isBenchmark
                ).length > 0 ? (
                  <Plot
                    data={[
                      {
                        values: getToolsScompositionPlotValues(
                          currentProject,
                          -1,
                          props.isBenchmark
                        ),
                        labels: toolsScompositionPlotLabels,
                        type: "pie",
                      },
                    ]}
                    layout={{
                      title: toolsScompositionPlotTitle,
                    }}
                  />
                ) : (
                  <Text textAlign={"center"} fontSize={"2xl"}>
                    {t("errors.noValuesPlot")}
                  </Text>
                )}
              </Stack>
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
      {!currentProject.fancades ||
        (currentProject?.fancades?.length == 0 && (
          <DetailsTable columnsTitles={[]} rows={[]} />
        ))}
    </Stack>
  );
});
