import { Input } from "@chakra-ui/react";
import { useEffect, useState } from "react";

type Props = {
  field: any;
  type: string;
  placeholder?: string;
  disabled?: boolean;
  setFieldValue: any;
  onChange?: any;
  name?: any;
  value?: any;
  onBlur?: any;
};
export const FormikInput: React.FC<Props> = (props: Props) => {
  const [value, setValue] = useState(props.field.value);
  const setFormikValue = (event: any) => {
    if (event && event.target && props.field.value != event.target.value) {
      if (props.type == "number") {
        props.setFieldValue(
          props.field.name,
          event.target.value.replace(/,/g, ".") //Replace commas with dots for browser compatibility
        );
      } else {
        props.setFieldValue(props.field.name, event.target.value);
      }
    }
  };

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <Input
      {...props.field}
      value={value}
      type={props.type == "number" ? "text" : props.type}
      name={props.field.name}
      placeholder={props.placeholder ? props.placeholder : undefined}
      disabled={props.disabled}
      onBlur={(event) => {
        if (props.type == "number") {
          props.setFieldValue(
            props.field.name,
            event.target.value.replace(/,/g, ".") //Replace commas with dots for browser compatibility
          );
        } else {
          props.setFieldValue(props.field.name, event.target.value);
        }
        if (event && props.onBlur) props.onBlur(event);
      }}
      onChange={(event: any) => {
        if (event && event.target) {
          if (props.type == "number") {
            setValue(
              event.target.value.replace(/,/g, ".") //Replace commas with dots for browser compatibility
            );
          } else {
            setValue(event.target.value);
          }
        }

        if (props.onChange) {
          props.onChange(event);
        }
      }}
    />
  );
};
