import {
  Flex,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { FaRegStickyNote } from "react-icons/fa";
import theme from "../theme/theme";
import { PrimaryButton, SecondaryButton } from "./Button";

type Props = {
  noteContent?: string | null;
  onBlur?: any;
};
export const NoteIcon: React.FC<Props> = ({ noteContent, onBlur }) => {
  const { t } = useTranslation();
  const { onOpen, onClose, isOpen } = useDisclosure();

  const [content, setContent] = useState(noteContent);

  const onReset = useCallback(() => {
    setContent(noteContent);
  }, [content]);

  return (
    <Popover
      isLazy
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement={"right"}
      closeOnBlur={true}
    >
      <PopoverTrigger>
        <Flex
          w={"1rem"}
          marginRight={"2rem"}
          color={content && content !== "" ? theme.colors.euracOrange : "black"}
          cursor={"pointer"}
          title={t("warnings.note")}
        >
          <FaRegStickyNote />
        </Flex>
      </PopoverTrigger>
      <PopoverContent p={6}>
        <Stack w={"100%"}>
          <Textarea
            value={content ? content : ""}
            onChange={(e) => setContent(e.target.value)}
            onBlur={(e) => onBlur(content)}
            placeholder={"Contenuto nota"}
            size={"lg"}
          />
          {/* <Flex justifyContent={"end"} w={"100%"}>
            <SecondaryButton marginRight={"1rem"} onClick={onReset}>
              {t("buttons.resetNote")}
            </SecondaryButton>
            <PrimaryButton
              onClick={() => {
                onSubmit(content);
              }}
            >
              {t("buttons.save")}
            </PrimaryButton>
          </Flex> */}
        </Stack>
        <PopoverArrow />
        <PopoverCloseButton />
      </PopoverContent>
    </Popover>
  );
};
