import {
  buildingSiteToTable,
  costPerPhaseToTable,
  endOfLifeToTable,
  fancadeProjectToTable,
  fancadeTechnicalProjectToTable,
  generalDataToTable,
  getActualizationComparisonRows,
  getComparisonRows,
  getKpiComparisonRows,
  getKpiRows,
  kpiToTable,
  maintenanceToTable,
  productionToTable,
  serviceLifeToTable,
  transportToTable,
} from "../../../../services/tabToTable";
import { Project } from "../../../../types";
import { PDFPage } from "./../../../PDFPage";
import { PDFTable, TableRow } from "./../../../PDFTable";
import { Text } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import {
  getActualizationLifeServiceRows,
  getActualizationMaintenanceRows,
} from "../../../../services/utils";

type Props = {
  project1: Project;
  project2: Project;
  fancadeIndex1: number;
  fancadeIndex2: number;
};
export const ReportPDF: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  const titles: any[] = [
    t("tablesHeaders.generalComparison"),
    "#" + props.project1.id,
    "#" + props.project2.id,
  ];

  const titles2: any[] = [
    t("attribute"),
    "#" + props.project1.id,
    "#" + props.project2.id,
  ];
  const attributes = [
    "name",
    "type",
    "useDestination",
    "constructionYear",
    "location",
  ];

  let generalBody: TableRow[] = [];

  attributes.map((attribute: string) => {
    generalBody.push({
      name: t("tablesHeaders.projectList." + attribute),
      values: [
        //@ts-ignore
        props.project1[attribute as keyof Project] || 0, //@ts-ignore
        props.project2[attribute as keyof Project] || 0,
      ],
    });
  });

  return (
    <>
      <PDFPage>
        <Text
          style={{
            textAlign: "center",
            padding: 5,
            fontSize: 25,
            fontWeight: "bold",
          }}
        >
          {t("titles.details.general")}
        </Text>
        <PDFTable
          variation={"alternate"}
          columnsTitles={titles}
          rows={generalBody}
        ></PDFTable>
      </PDFPage>
      <PDFPage>
        <Text
          style={{
            textAlign: "center",
            padding: 5,
            fontSize: 25,
            fontWeight: "bold",
          }}
        >
          {t("titles.details.fancadeTechnicalProject")}
        </Text>
        <PDFTable
          columnsTitles={titles2}
          rows={getComparisonRows(
            fancadeTechnicalProjectToTable(props.project1),
            fancadeTechnicalProjectToTable(props.project2),
            props.fancadeIndex1,
            props.fancadeIndex2
          )}
        ></PDFTable>
      </PDFPage>
      <PDFPage>
        <Text
          style={{
            textAlign: "center",
            padding: 5,
            fontSize: 25,
            fontWeight: "bold",
          }}
        >
          {t("titles.details.fancadeProject")}
        </Text>
        <PDFTable
          columnsTitles={titles2}
          rows={getComparisonRows(
            fancadeProjectToTable(props.project1),
            fancadeProjectToTable(props.project2),
            props.fancadeIndex1,
            props.fancadeIndex2
          )}
        ></PDFTable>
      </PDFPage>
      <PDFPage>
        <Text
          style={{
            textAlign: "center",
            padding: 5,
            fontSize: 25,
            fontWeight: "bold",
          }}
        >
          {t("titles.details.fancadeProduction")}
        </Text>
        <PDFTable
          columnsTitles={titles2}
          rows={getComparisonRows(
            productionToTable(props.project1),
            productionToTable(props.project2),
            props.fancadeIndex1,
            props.fancadeIndex2
          )}
        ></PDFTable>
      </PDFPage>
      <PDFPage>
        <Text
          style={{
            textAlign: "center",
            padding: 5,
            fontSize: 25,
            fontWeight: "bold",
          }}
        >
          {t("titles.details.transport")}
        </Text>
        <PDFTable
          columnsTitles={titles2}
          rows={getComparisonRows(
            transportToTable(props.project1),
            transportToTable(props.project2),
            props.fancadeIndex1,
            props.fancadeIndex2
          )}
        ></PDFTable>
      </PDFPage>
      <PDFPage>
        <Text
          style={{
            textAlign: "center",
            padding: 5,
            fontSize: 25,
            fontWeight: "bold",
          }}
        >
          {t("titles.details.buildingSite")}
        </Text>
        <PDFTable
          columnsTitles={titles2}
          rows={getComparisonRows(
            buildingSiteToTable(props.project1),
            buildingSiteToTable(props.project2),
            props.fancadeIndex1,
            props.fancadeIndex2
          )}
        ></PDFTable>
      </PDFPage>
      <PDFPage>
        <Text
          style={{
            textAlign: "center",
            padding: 5,
            fontSize: 25,
            fontWeight: "bold",
          }}
        >
          {t("titles.details.serviceLife")}
        </Text>
        <PDFTable
          columnsTitles={titles2}
          rows={getComparisonRows(
            serviceLifeToTable(props.project1),
            serviceLifeToTable(props.project2),
            props.fancadeIndex1,
            props.fancadeIndex2
          )}
        ></PDFTable>
      </PDFPage>
      <PDFPage>
        <Text
          style={{
            textAlign: "center",
            padding: 5,
            fontSize: 25,
            fontWeight: "bold",
          }}
        >
          {t("titles.details.maintenance")}
        </Text>
        <PDFTable
          columnsTitles={titles2}
          rows={getComparisonRows(
            maintenanceToTable(props.project1),
            maintenanceToTable(props.project2),
            props.fancadeIndex1,
            props.fancadeIndex2
          )}
        ></PDFTable>
      </PDFPage>
      <PDFPage>
        <Text
          style={{
            textAlign: "center",
            padding: 5,
            fontSize: 25,
            fontWeight: "bold",
          }}
        >
          {t("tablesVoices.costsActualization.serviceOfLife")}
        </Text>
        <PDFTable
          columnsTitles={[
            t("tablesVoices.costsActualization.year"),
            "#" +
              props.project1.id +
              " " +
              t("tablesVoices.costsActualization.value"),
            "#" +
              props.project1.id +
              " " +
              t("tablesVoices.costsActualization.total"),
            "#" +
              props.project2.id +
              " " +
              t("tablesVoices.costsActualization.value"),
            "#" +
              props.project2.id +
              " " +
              t("tablesVoices.costsActualization.total"),
          ]}
          rows={
            props.project1.fancades && props.project2.fancades
              ? getActualizationComparisonRows(
                  getActualizationLifeServiceRows(
                    props.project1.fancades[props.fancadeIndex1]
                  ),
                  getActualizationLifeServiceRows(
                    props.project2.fancades[props.fancadeIndex2]
                  )
                )
              : []
          }
        ></PDFTable>
      </PDFPage>
      <PDFPage>
        <Text
          style={{
            textAlign: "center",
            padding: 5,
            fontSize: 25,
            fontWeight: "bold",
          }}
        >
          {t("tablesVoices.costsActualization.serviceOfLife")}
        </Text>
        <PDFTable
          columnsTitles={[
            t("tablesVoices.costsActualization.year"),
            "#" +
              props.project1.id +
              " " +
              t("tablesVoices.costsActualization.value"),
            "#" +
              props.project1.id +
              " " +
              t("tablesVoices.costsActualization.total"),
            "#" +
              props.project2.id +
              " " +
              t("tablesVoices.costsActualization.value"),
            "#" +
              props.project2.id +
              " " +
              t("tablesVoices.costsActualization.total"),
          ]}
          rows={
            props.project1.fancades && props.project2.fancades
              ? getActualizationComparisonRows(
                  getActualizationMaintenanceRows(
                    props.project1.fancades[props.fancadeIndex1]
                  ),
                  getActualizationMaintenanceRows(
                    props.project2.fancades[props.fancadeIndex2]
                  )
                )
              : []
          }
        ></PDFTable>
      </PDFPage>
      <PDFPage>
        <Text
          style={{
            textAlign: "center",
            padding: 5,
            fontSize: 25,
            fontWeight: "bold",
          }}
        >
          {t("titles.details.endOfLife")}
        </Text>
        <PDFTable
          columnsTitles={titles2}
          rows={getComparisonRows(
            endOfLifeToTable(props.project1),
            endOfLifeToTable(props.project2),
            props.fancadeIndex1,
            props.fancadeIndex2
          )}
        ></PDFTable>
      </PDFPage>
      <PDFPage>
        <Text
          style={{
            textAlign: "center",
            padding: 5,
            fontSize: 25,
            fontWeight: "bold",
          }}
        >
          {t("KPI")}
        </Text>
        <PDFTable
          variation={"alternate"}
          columnsTitles={[
            "KPI",
            "#" + props.project1.id,
            "#" + props.project2.id,
          ]}
          rows={getKpiComparisonRows(
            getKpiRows(props.project1, props.fancadeIndex1),
            getKpiRows(props.project2, props.fancadeIndex2)
          )}
        ></PDFTable>
      </PDFPage>
    </>
  );
};
