import React, { useCallback, useMemo, useState } from "react";
import {
  Button,
  Center,
  Divider,
  Flex,
  Stack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../../../../hooks/useStores";
import { Redirect, useParams } from "react-router-dom";

import { Formik, Form, Field } from "formik";

import styled from "styled-components";
import { transparentize } from "polished";
import theme from "../../../../../../theme/theme";
import { FormRow } from "../../../../../FormRow";
import {
  Fancade,
  FancadeTechnicalProjectType,
  GeometricDetail,
  OpaqueWindow,
  Project,
  SystemsIntegration,
} from "../../../../../../types";
import { useEffect } from "react";
import {
  calculateTotalLoomLength,
  calculateTotalMainFrameLength,
  calculateWindowOpaqueSurfaceRatio,
} from "../../../../../../services/utils";
import FieldGroup from "../../../../../FieldGroup";
import {
  getFancadeTypesOptions,
  getPrefabricatedLevelsOptions,
  getSystemsIntegrationsOptions,
} from "../../../../../../services/selectsOptions";
import { SurfaceAlert } from "../../../../../SurfaceAlert";

interface RouteParams {
  projectId: string;
}

type props = {
  fancadeNumber: number;
  fancadeId: number;
  fancadeType: string | null;
  formikProps: any;
};

export const FancadeForm: React.FC<props> = observer((props) => {
  const { t } = useTranslation();
  const { session, ui, projects } = useStores();
  const { projectId } = useParams<RouteParams>();
  const [isOpaque, setIsOpaque] = useState(-1);
  const [update, setUpdate] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [surfaceAlertIsOpen, setSurfaceAlertIsOpen] = useState(false);

  ui.setActiveScreen("projects");
  const windowsSurfaces: number[] = props.formikProps.values.fancades[
    props.fancadeNumber - 1
  ]?.fancadeTechnicalProject?.windows?.map((window: OpaqueWindow) => {
    if (window.windowsSurface) return window.windowsSurface;
    return 0;
  });

  let windowsOpaque: any[] = [];
  let fancadeTypeOptions = useMemo(() => getFancadeTypesOptions(), []);
  let prefabricationLevelsOptions = useMemo(
    () => getPrefabricatedLevelsOptions(),
    []
  );
  let systemsIntegrationsOptions = useMemo(
    () => getSystemsIntegrationsOptions(),
    []
  );

  const changeOpaque = useCallback((value: string) => {
    let temp = -1;
    if (value) {
      if (value.toLocaleLowerCase().indexOf("opaca") != -1) temp = 1;
      else if (value.toLocaleLowerCase().indexOf("trasparente") != -1) temp = 0;
    }
    setIsOpaque(temp);
  }, []);
  const currentProject: Project = props.formikProps.values;
  const currentFancade = currentProject?.fancades?.find((element) => {
    return +element.id == props.fancadeId;
  });
  useEffect(() => {
    if (props.fancadeType) changeOpaque(props.fancadeType);
  }, []);

  const addNewWindowType = useCallback(
    (fancadeId: number) => {
      if (currentFancade && currentFancade.fancadeTechnicalProject) {
        if (!currentFancade.fancadeTechnicalProject.windows)
          currentFancade.fancadeTechnicalProject.windows = [];

        if (fancadeId == -1) {
          currentFancade.fancadeTechnicalProject.windows.push(
            projects.getEmptyWindowType(currentFancade)
          );
        } else {
          currentFancade.fancadeTechnicalProject.windows =
            projects.addWindowTypeToFancade(
              parseInt(projectId),
              fancadeId,
              projects.getEmptyWindowType(currentFancade)
            );
        }
      }
      setUpdate(!update);
    },
    [projects, update, currentProject, currentFancade]
  );

  const checkIntegratedSystemsSurfaces = useCallback(() => {
    if (
      currentFancade &&
      currentFancade.fancadeTechnicalProject &&
      currentFancade.fancadeTechnicalProject.surface
    ) {
      const value = +calculateSystemPercentage(
        currentFancade.fancadeTechnicalProject.systemsIntegrations
      );
      if (value > 100) {
        setSurfaceAlertIsOpen(true);
      }
    }
  }, [surfaceAlertIsOpen]);

  const addNewField = useCallback(
    (
      name: string,
      value: number | string,
      fancadeTechnicalProject: FancadeTechnicalProjectType
    ) => {
      if (!fancadeTechnicalProject.customFields) {
        fancadeTechnicalProject.customFields = [];
      }

      fancadeTechnicalProject.customFields.push({
        name,
        value,
      });

      if (currentProject && currentProject.fancades)
        currentProject.fancades[
          props.fancadeNumber - 1
        ].fancadeTechnicalProject.customFields = projects.setCustomFieldsToFancade(
          currentProject.id,
          props.fancadeId,
          fancadeTechnicalProject.customFields
        );
      setUpdate(!update);
    },

    [projects, currentProject, update]
  );

  const deleteNewField = useCallback(
    (fancadeTechnicalProject: FancadeTechnicalProjectType, index: number) => {
      fancadeTechnicalProject.customFields?.splice(index, 1);
      if (
        currentProject &&
        currentProject.fancades &&
        fancadeTechnicalProject.customFields
      )
        currentProject.fancades[
          props.fancadeNumber - 1
        ].fancadeTechnicalProject.customFields = projects.setCustomFieldsToFancade(
          currentProject.id,
          props.fancadeId,
          fancadeTechnicalProject.customFields
        );
    },
    [projects, currentProject, currentFancade, update]
  );

  const addNewGeometricDetail = useCallback(
    (fancadeTechnicalProject: FancadeTechnicalProjectType) => {
      if (!fancadeTechnicalProject.geometricDetails) {
        fancadeTechnicalProject.geometricDetails = [];
      }

      fancadeTechnicalProject.geometricDetails?.push({
        width: null,
        length: null,
        depth: null,
      });

      if (currentProject && currentProject.fancades)
        currentProject.fancades[
          props.fancadeNumber - 1
        ].fancadeTechnicalProject.geometricDetails = projects.setGeometricDetailsToFancade(
          currentProject.id,
          props.fancadeId,
          fancadeTechnicalProject.geometricDetails
        );
      setUpdate(!update);
    },
    [projects, currentProject, update]
  );

  const addNewSystemsIntegration = useCallback(
    (fancadeTechnicalProject: FancadeTechnicalProjectType) => {
      if (!fancadeTechnicalProject.systemsIntegrations) {
        fancadeTechnicalProject.systemsIntegrations = [];
      }

      fancadeTechnicalProject.systemsIntegrations?.push({
        name: null,
        units: null,
        unitSurface: null,
        totalSurface: null,
        totalPriceCalculation: null,
        totalPrice: null,
        unitPrice: null,
      });

      if (currentProject && currentProject.fancades)
        currentProject.fancades[
          props.fancadeNumber - 1
        ].fancadeTechnicalProject.systemsIntegrations = projects.setSystemsIntegrationsToFancade(
          currentProject.id,
          props.fancadeId,
          fancadeTechnicalProject.systemsIntegrations
        );
      setUpdate(!update);
    },

    [projects, currentProject, update]
  );

  const deleteWindowType = useCallback(
    (
      fancadeTechnicalProject: FancadeTechnicalProjectType,
      windowId: number
    ) => {
      fancadeTechnicalProject.windows?.splice(windowId - 1, 1);
      if (
        currentProject &&
        currentProject.fancades &&
        fancadeTechnicalProject.windows
      )
        currentProject.fancades[
          props.fancadeNumber - 1
        ].fancadeTechnicalProject.windows = projects.setWindowsToFancade(
          currentProject.id,
          props.fancadeId,
          fancadeTechnicalProject.windows
        );
      setUpdate(!update);
    },
    [projects, update]
  );

  const deleteGeometricDetail = useCallback(
    (fancadeTechnicalProject: FancadeTechnicalProjectType, index: number) => {
      fancadeTechnicalProject.geometricDetails?.splice(index, 1);
      if (
        currentProject &&
        currentProject.fancades &&
        fancadeTechnicalProject.geometricDetails
      )
        currentProject.fancades[
          props.fancadeNumber - 1
        ].fancadeTechnicalProject.geometricDetails = projects.setGeometricDetailsToFancade(
          currentProject.id,
          props.fancadeId,
          fancadeTechnicalProject.geometricDetails
        );
      setUpdate(!update);
    },
    [projects, currentProject, update]
  );

  const deleteSystemsIntegration = useCallback(
    (fancadeTechnicalProject: FancadeTechnicalProjectType, index: number) => {
      fancadeTechnicalProject.systemsIntegrations?.splice(index, 1);
      if (
        currentProject &&
        currentProject.fancades &&
        fancadeTechnicalProject.systemsIntegrations
      )
        currentProject.fancades[
          props.fancadeNumber - 1
        ].fancadeTechnicalProject.systemsIntegrations = projects.setSystemsIntegrationsToFancade(
          currentProject.id,
          props.fancadeId,
          fancadeTechnicalProject.systemsIntegrations
        );
      setUpdate(!update);
    },
    [projects, currentProject, update]
  );

  if (
    currentFancade &&
    isOpaque &&
    currentFancade.fancadeTechnicalProject?.windows
  ) {
    currentFancade.fancadeTechnicalProject.windows.map(
      (windowType: OpaqueWindow, index: number) => {
        windowsOpaque.push(
          <Stack marginBottom={"2rem"}>
            <Flex w={"100%"} alignItems={"center"}>
              <Text fontSize={"2xl"} color={theme.colors.darkGrey2}>
                {t("formLabels.details.fancadeProject.windowType.windowType")} #
                {windowType.id}
              </Text>
              <CloseIcon
                color={theme.colors.euracOrange}
                w={"0.7rem"}
                marginLeft={"0.5rem"}
                marginTop={"0.5rem"}
                onClick={() =>
                  deleteWindowType(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      ?.fancadeTechnicalProject,
                    windowType.id
                  )
                }
              />
            </Flex>
            <FormRow
              fields={[
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].fancadeTechnicalProject.windows[${
                    windowType.id - 1
                  }].windowsNumber`,
                  label:
                    t(
                      "formLabels.details.fancadeProject.windowType.windowsNumber"
                    ) + " (u)",
                  placeholder:
                    t(
                      "formPlaceholders.details.fancadeProject.windowType.windowsNumber"
                    ) + " (u)",
                  type: "number",
                  width: 50,
                  onChange: (e: any) => {
                    if (
                      currentFancade &&
                      currentFancade.fancadeTechnicalProject &&
                      currentFancade.fancadeTechnicalProject.surface
                    ) {
                      props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.windows[
                        windowType.id - 1
                      ].windowsSurface =
                        +e.target.value *
                        +props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject.windows[windowType.id - 1]
                          ?.windowsUnitSurface;
                      if (
                        +props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject.windows[windowType.id - 1]
                          .windowsSurface >
                        +currentFancade.fancadeTechnicalProject.surface
                      ) {
                        setSurfaceAlertIsOpen(true);
                      }
                    }
                  },
                },
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].fancadeTechnicalProject.windows[${
                    windowType.id - 1
                  }].windowsUnitSurface`,
                  label: t(
                    "formLabels.details.fancadeProject.windowType.windowsUnitSurface"
                  ),
                  placeholder: t(
                    "formLabels.details.fancadeProject.windowType.windowsUnitSurface"
                  ),
                  type: "number",
                  width: 50,
                  onChange: (e: any) => {
                    if (
                      currentFancade &&
                      currentFancade.fancadeTechnicalProject &&
                      currentFancade.fancadeTechnicalProject.surface
                    ) {
                      props.formikProps.values.fancades[
                        props.fancadeNumber - 1
                      ].fancadeTechnicalProject.windows[
                        windowType.id - 1
                      ].windowsSurface =
                        +e.target.value *
                        +props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject.windows[windowType.id - 1]
                          ?.windowsNumber;

                      if (
                        +props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject.windows[windowType.id - 1]
                          .windowsSurface >
                        +currentFancade.fancadeTechnicalProject.surface
                      ) {
                        setSurfaceAlertIsOpen(true);
                      }
                    }
                  },
                },
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].fancadeTechnicalProject.windows[${
                    windowType.id - 1
                  }].windowsSurface`,
                  label: t(
                    "formLabels.details.fancadeProject.windowType.windowsSurface"
                  ),
                  placeholder: t(
                    "formPlaceholders.details.fancadeProject.windowType.windowsSurface"
                  ),
                  type: "number",
                  width: 50,
                  value:
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject.windows[windowType.id - 1]
                      ?.windowsSurface,
                  disabled: true,
                },
              ]}
            />
            <FormRow
              fields={[
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].fancadeTechnicalProject.windows[${
                    windowType.id - 1
                  }].doubleGlazingNumber`,
                  label:
                    t(
                      "formLabels.details.fancadeProject.windowType.doubleGlazingNumber"
                    ) + " (u)",
                  placeholder:
                    t(
                      "formPlaceholders.details.fancadeProject.windowType.doubleGlazingNumber"
                    ) + " (u)",
                  type: "number",
                  width: 50,
                },
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].fancadeTechnicalProject.windows[${
                    windowType.id - 1
                  }].doubleGlazingSurface`,
                  label:
                    t(
                      "formLabels.details.fancadeProject.windowType.doubleGlazingSurface"
                    ) + " (m²)",
                  placeholder:
                    t(
                      "formPlaceholders.details.fancadeProject.windowType.doubleGlazingSurface"
                    ) + " (m²)",
                  type: "number",
                  width: 50,
                  onChange: (e: any) => {
                    if (
                      currentFancade &&
                      currentFancade.fancadeTechnicalProject &&
                      currentFancade.fancadeTechnicalProject.surface
                    ) {
                      if (
                        +e.target.value >
                        currentFancade.fancadeTechnicalProject.surface
                      ) {
                        setSurfaceAlertIsOpen(true);
                      }
                    }
                  },
                },
              ]}
            />

            <FormRow
              fields={[
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].fancadeTechnicalProject.windows[${
                    windowType.id - 1
                  }].loomHeight`,
                  label:
                    t(
                      "formLabels.details.fancadeProject.windowType.loomHeight"
                    ) + " (m)",
                  placeholder:
                    t(
                      "formPlaceholders.details.fancadeProject.windowType.loomHeight"
                    ) + " (m)",
                  type: "number",
                  width: 34,
                },
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].fancadeTechnicalProject.windows[${
                    windowType.id - 1
                  }].loomWidth`,
                  label:
                    t(
                      "formLabels.details.fancadeProject.windowType.loomWidth"
                    ) + " (m)",
                  placeholder:
                    t(
                      "formPlaceholders.details.fancadeProject.windowType.loomWidth"
                    ) + " (m)",
                  type: "number",
                  width: 33,
                },
                {
                  name: `fancades[${
                    props.fancadeNumber - 1
                  }].fancadeTechnicalProject.windows[${
                    windowType.id - 1
                  }].totalLoomLength`,
                  label:
                    t(
                      "formLabels.details.fancadeProject.windowType.totalLoomLength"
                    ) + " (m)",
                  placeholder:
                    t(
                      "formPlaceholders.details.fancadeProject.windowType.totalLoomLength"
                    ) + " (m)",
                  type: "number",
                  width: 33,
                  disabled: true,
                  value: calculateTotalLoomLength(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject.windows[windowType.id - 1]
                      ?.loomHeight,
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject.windows[windowType.id - 1]
                      ?.loomWidth,
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject.windows[windowType.id - 1]
                      ?.doubleGlazingNumber
                  ),
                },
              ]}
            />
            <Stack w={"50%"}>
              <FormRow
                fields={[
                  {
                    name: `fancades[${
                      props.fancadeNumber - 1
                    }].fancadeTechnicalProject.windows[${
                      windowType.id - 1
                    }].totalGasketLength`,
                    label:
                      t(
                        "formLabels.details.fancadeProject.windowType.totalGasketLength"
                      ) + " (m)",
                    placeholder:
                      t(
                        "formPlaceholders.details.fancadeProject.windowType.totalGasketLength"
                      ) + " (m)",
                    type: "number",
                    width: 100,
                  },
                ]}
              />
            </Stack>
          </Stack>
        );
      }
    );
  }
  const calculateSystemPercentage = (
    systemsIntegrations: SystemsIntegration[]
  ) => {
    if (!currentFancade?.fancadeTechnicalProject.surface) return 0;
    let systemsTotalSurface = 0;

    if (
      currentFancade.fancadeTechnicalProject.thermicSolarPanelsNumber &&
      currentFancade.fancadeTechnicalProject.thermicSolarPanelsUnitSurface
    )
      systemsTotalSurface +=
        +currentFancade.fancadeTechnicalProject.thermicSolarPanelsNumber *
        +currentFancade.fancadeTechnicalProject.thermicSolarPanelsUnitSurface;
    if (
      currentFancade.fancadeTechnicalProject.photovoltaicModulesNumber &&
      currentFancade.fancadeTechnicalProject.photovoltaicModulesUnitSurface
    )
      systemsTotalSurface +=
        +currentFancade.fancadeTechnicalProject.photovoltaicModulesNumber *
        +currentFancade.fancadeTechnicalProject.photovoltaicModulesUnitSurface;

    if (currentFancade.fancadeTechnicalProject.systemsIntegration == 1) {
      systemsIntegrations?.map(
        (systemsIntegration: SystemsIntegration, index) => {
          if (systemsIntegration.unitSurface && systemsIntegration.units)
            systemsTotalSurface +=
              +systemsIntegration.unitSurface * +systemsIntegration.units;
        }
      );
    }

    return (
      (+systemsTotalSurface / +currentFancade.fancadeTechnicalProject.surface) *
      100
    );
  };

  if (session.user)
    return (
      <Stack>
        <SurfaceAlert
          isOpen={surfaceAlertIsOpen}
          setIsOpen={setSurfaceAlertIsOpen}
        />
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {t("formLabels.details.fancadeProject.customFieldModalTitle")}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Formik
                initialValues={{ name: "", value: "" }}
                onSubmit={(values, actions) => {
                  if (values.name && values.value && currentFancade)
                    addNewField(
                      values.name,
                      values.value,
                      currentFancade.fancadeTechnicalProject
                    );
                  onClose();
                }}
              >
                {(props) => (
                  <Form>
                    <Field name="name">
                      {(modalProps: any) => (
                        <FormControl
                          isInvalid={
                            modalProps.form.errors.name &&
                            modalProps.form.touched.name
                          }
                        >
                          <FormLabel htmlFor="name">
                            {t("formLabels.details.fancadeProject.fieldName")}
                          </FormLabel>
                          <Input
                            {...modalProps.field}
                            id={"name"}
                            placeholder={t(
                              "formLabels.details.fancadeProject.fieldName"
                            )}
                          />
                          <FormErrorMessage>
                            {modalProps.form.errors.name}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="value">
                      {(modalProps: any) => (
                        <FormControl
                          isInvalid={
                            modalProps.form.errors.name &&
                            modalProps.form.touched.name
                          }
                        >
                          <FormLabel htmlFor="value" paddingTop={"1rem"}>
                            {t("formLabels.details.fancadeProject.fieldValue")}
                          </FormLabel>
                          <Input
                            {...modalProps.field}
                            id={"value"}
                            placeholder={t(
                              "formLabels.details.fancadeProject.fieldValue"
                            )}
                          />
                          <FormErrorMessage>
                            {modalProps.form.errors.value}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Flex justifyContent={"center"} alignItems={"center"}>
                      <StyledButton
                        type="submit"
                        marginTop={"1rem"}
                        disabled={!props.values.name || !props.values.value}
                      >
                        {t("buttons.addNewField")}
                      </StyledButton>
                    </Flex>
                  </Form>
                )}
              </Formik>
            </ModalBody>

            <ModalFooter>
              <StyledButtonGrey onClick={onClose}>
                {t("buttons.close")}
              </StyledButtonGrey>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Stack w={"100%"}>
          <FormRow
            onChange={(e: string) => {
              if (
                e &&
                (e.toLowerCase().indexOf("opaca") != -1 ||
                  e.toLowerCase().indexOf("trasparente") != -1)
              )
                changeOpaque(e);
              setUpdate(!update);
            }}
            fields={[
              {
                name:
                  "fancades[" +
                  (props.fancadeNumber - 1) +
                  "].fancadeTechnicalProject.type",
                label: t("formLabels.details.fancadeProject.type"),
                placeholder: t("formPlaceholders.details.fancadeProject.type"),
                type: "select",
                width: 50,
                selectOptions: fancadeTypeOptions,
              },
              {
                name:
                  "fancades[" +
                  (props.fancadeNumber - 1) +
                  "].fancadeTechnicalProject.surface",
                label: t("formLabels.details.fancadeProject.surface"),
                placeholder: t(
                  "formPlaceholders.details.fancadeProject.surface"
                ),
                type: "number",
                width: 50,
              },
            ]}
          />
        </Stack>
        {isOpaque == 1 ? (
          /* Involucro Opaco  */
          <Stack spacing={0}>
            <StyledFlexHeader>
              <Text fontSize={"xl"} fontWeight={"medium"}>
                {t("formLabels.details.fancadeProject.opaqueShell")}
              </Text>
            </StyledFlexHeader>
            <StyledFlexBody>
              <Stack w={"100%"}>
                {windowsOpaque}
                <Stack
                  width={"100%"}
                  paddingBottom={"2rem"}
                  paddingTop={"1.5rem"}
                  alignItems={"center"}
                >
                  <StyledButton
                    onClick={() => addNewWindowType(props.fancadeId)}
                  >
                    {t("buttons.addWindow")}
                  </StyledButton>
                </Stack>
                {currentFancade &&
                  currentFancade.fancadeTechnicalProject &&
                  currentFancade.fancadeTechnicalProject.type &&
                  currentFancade.fancadeTechnicalProject.type.toLowerCase() !=
                    "finestre" && (
                    <>
                      {currentFancade &&
                      currentFancade.fancadeTechnicalProject &&
                      currentFancade.fancadeTechnicalProject.type &&
                      currentFancade.fancadeTechnicalProject.type.toLowerCase() ==
                        "opaca - retrofit modulare" ? (
                        <>
                          <FormRow
                            fields={[
                              {
                                name:
                                  "fancades[" +
                                  (props.fancadeNumber - 1) +
                                  "].fancadeTechnicalProject.prefabricatedModulesQuantity",
                                label:
                                  t(
                                    "formLabels.details.fancadeProject.prefabricatedModulesQuantity"
                                  ) + " (u)",
                                placeholder:
                                  t(
                                    "formPlaceholders.details.fancadeProject.prefabricatedModulesQuantity"
                                  ) + " (u)",
                                type: "number",
                                width: 100,
                              },
                            ]}
                          />
                          <FormRow
                            fields={[
                              {
                                name:
                                  "fancades[" +
                                  (props.fancadeNumber - 1) +
                                  "].fancadeTechnicalProject.modulesSurface",
                                label: t(
                                  "formLabels.details.fancadeProject.modulesSurface"
                                ),
                                placeholder: t(
                                  "formPlaceholders.details.fancadeProject.modulesSurface"
                                ),
                                type: "number",
                                width: 30,
                              },
                              {
                                name:
                                  "fancades[" +
                                  (props.fancadeNumber - 1) +
                                  "].fancadeTechnicalProject.opaqueAnchoringsNumber",
                                label:
                                  t(
                                    "formLabels.details.fancadeProject.opaqueAnchoringsNumber"
                                  ) + " (u)",
                                placeholder:
                                  t(
                                    "formPlaceholders.details.fancadeProject.opaqueAnchoringsNumber"
                                  ) + " (u)",
                                type: "number",
                                width: 35,
                              },
                              {
                                name:
                                  "fancades[" +
                                  (props.fancadeNumber - 1) +
                                  "].fancadeTechnicalProject.opaqueFixingsNumber",
                                label:
                                  t(
                                    "formLabels.details.fancadeProject.opaqueFixingsNumber"
                                  ) + " (u)",
                                placeholder:
                                  t(
                                    "formPlaceholders.details.fancadeProject.opaqueFixingsNumber"
                                  ) + " (u)",
                                type: "number",
                                width: 35,
                              },
                            ]}
                          />
                        </>
                      ) : (
                        <>
                          <FormRow
                            fields={[
                              {
                                name:
                                  "fancades[" +
                                  (props.fancadeNumber - 1) +
                                  "].fancadeTechnicalProject.externalCladdingUnitsNumber",
                                label:
                                  t(
                                    "formLabels.details.fancadeProject.externalCladdingUnitsNumber"
                                  ) + " (u)",
                                placeholder:
                                  t(
                                    "formPlaceholders.details.fancadeProject.externalCladdingUnitsNumber"
                                  ) + " (u)",
                                type: "number",
                                width: 35,
                                onBlur: (e: any) => {
                                  const value =
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].fancadeTechnicalProject
                                      .externalCladdingUnitsSurface *
                                    +e.target.value;

                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].fancadeTechnicalProject.totalExternalCladdingSurface =
                                    value;

                                  setUpdate(!update);
                                  if (
                                    currentFancade &&
                                    currentFancade.fancadeTechnicalProject &&
                                    currentFancade.fancadeTechnicalProject
                                      .surface
                                  ) {
                                    if (
                                      value >
                                      currentFancade.fancadeTechnicalProject
                                        .surface
                                    ) {
                                      setSurfaceAlertIsOpen(true);
                                    }
                                  }
                                },
                              },
                              {
                                name:
                                  "fancades[" +
                                  (props.fancadeNumber - 1) +
                                  "].fancadeTechnicalProject.externalCladdingUnitsSurface",
                                label: t(
                                  "formLabels.details.fancadeProject.externalCladdingUnitsSurface"
                                ),
                                placeholder: t(
                                  "formPlaceholders.details.fancadeProject.externalCladdingUnitsSurface"
                                ),
                                type: "number",
                                width: 35,
                                onBlur: (e: any) => {
                                  const value =
                                    +e.target.value *
                                    +props.formikProps.values.fancades[
                                      props.fancadeNumber - 1
                                    ].fancadeTechnicalProject
                                      .externalCladdingUnitsNumber;
                                  props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].fancadeTechnicalProject.totalExternalCladdingSurface =
                                    value;
                                  setUpdate(!update);

                                  if (
                                    currentFancade &&
                                    currentFancade.fancadeTechnicalProject &&
                                    currentFancade.fancadeTechnicalProject
                                      .surface
                                  ) {
                                    if (
                                      value >
                                      currentFancade.fancadeTechnicalProject
                                        .surface
                                    ) {
                                      setSurfaceAlertIsOpen(true);
                                    }
                                  }
                                },
                              },
                              {
                                name:
                                  "fancades[" +
                                  (props.fancadeNumber - 1) +
                                  "].fancadeTechnicalProject.totalExternalCladdingSurface",
                                label:
                                  t(
                                    "formLabels.details.fancadeProject.totalExternalCladdingSurface"
                                  ) + " (m²)",
                                placeholder:
                                  t(
                                    "formPlaceholders.details.fancadeProject.totalExternalCladdingSurface"
                                  ) + " (m²)",
                                type: "number",
                                width: 35,
                                disabled: true,
                                value:
                                  +props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].fancadeTechnicalProject
                                    .externalCladdingUnitsSurface *
                                  +props.formikProps.values.fancades[
                                    props.fancadeNumber - 1
                                  ].fancadeTechnicalProject
                                    .externalCladdingUnitsNumber,
                              },
                              {
                                name:
                                  "fancades[" +
                                  (props.fancadeNumber - 1) +
                                  "].fancadeTechnicalProject.insulationSurface",
                                label:
                                  t(
                                    "formLabels.details.fancadeProject.insulationSurface"
                                  ) + " (m²)",
                                placeholder:
                                  t(
                                    "formPlaceholders.details.fancadeProject.insulationSurface"
                                  ) + " (m²)",
                                type: "number",
                                width: 30,
                                onChange: (e: any) => {
                                  if (
                                    currentFancade &&
                                    currentFancade.fancadeTechnicalProject &&
                                    currentFancade.fancadeTechnicalProject
                                      .surface
                                  ) {
                                    if (
                                      +e.target.value >
                                      currentFancade.fancadeTechnicalProject
                                        .surface
                                    ) {
                                      setSurfaceAlertIsOpen(true);
                                    }
                                  }
                                },
                              },
                            ]}
                          />
                          {currentFancade &&
                          currentFancade.fancadeTechnicalProject &&
                          currentFancade.fancadeTechnicalProject.type &&
                          (currentFancade.fancadeTechnicalProject.type.toLowerCase() ==
                              "opaca - finestre" ||
                            currentFancade.fancadeTechnicalProject.type.toLowerCase() ==
                              "opaca - retrofit modulare") ? (
                            <>
                              <FormRow
                                fields={[
                                  {
                                    name:
                                      "fancades[" +
                                      (props.fancadeNumber - 1) +
                                      "].fancadeTechnicalProject.supportingElementsQuantity",
                                    label:
                                      t(
                                        "formLabels.details.fancadeProject.supportingElementsQuantity"
                                      ) + " (m)",
                                    placeholder:
                                      t(
                                        "formPlaceholders.details.fancadeProject.supportingElementsQuantity"
                                      ) + " (m)",
                                    type: "number",
                                    width: 100,
                                  },
                                ]}
                              />
                              <FormRow
                                fields={[
                                  {
                                    name:
                                      "fancades[" +
                                      (props.fancadeNumber - 1) +
                                      "].fancadeTechnicalProject.opaqueAnchoringsNumber",
                                    label:
                                      t(
                                        "formLabels.details.fancadeProject.opaqueAnchoringsNumber"
                                      ) + " (u)",
                                    placeholder:
                                      t(
                                        "formPlaceholders.details.fancadeProject.opaqueAnchoringsNumber"
                                      ) + " (u)",
                                    type: "number",
                                    width: 50,
                                  },
                                  {
                                    name:
                                      "fancades[" +
                                      (props.fancadeNumber - 1) +
                                      "].fancadeTechnicalProject.opaqueFixingsNumber",
                                    label:
                                      t(
                                        "formLabels.details.fancadeProject.opaqueFixingsNumber"
                                      ) + " (u)",
                                    placeholder:
                                      t(
                                        "formPlaceholders.details.fancadeProject.opaqueFixingsNumber"
                                      ) + " (u)",
                                    type: "number",
                                    width: 50,
                                  },
                                ]}
                              />
                            </>
                          ) : (
                            <>
                              <FormRow
                                fields={[
                                  {
                                    name:
                                      "fancades[" +
                                      (props.fancadeNumber - 1) +
                                      "].fancadeTechnicalProject.supportingElementsQuantity",
                                    label:
                                      t(
                                        "formLabels.details.fancadeProject.supportingElementsQuantity"
                                      ) + " (m)",
                                    placeholder:
                                      t(
                                        "formPlaceholders.details.fancadeProject.supportingElementsQuantity"
                                      ) + " (m)",
                                    type: "number",
                                    width: 50,
                                  },
                                  {
                                    name:
                                      "fancades[" +
                                      (props.fancadeNumber - 1) +
                                      "].fancadeTechnicalProject.prefabricatedModulesQuantity",
                                    label:
                                      t(
                                        "formLabels.details.fancadeProject.prefabricatedModulesQuantity"
                                      ) + " (u)",
                                    placeholder:
                                      t(
                                        "formPlaceholders.details.fancadeProject.prefabricatedModulesQuantity"
                                      ) + " (u)",
                                    type: "number",
                                    width: 50,
                                  },
                                ]}
                              />
                              <FormRow
                                fields={[
                                  {
                                    name:
                                      "fancades[" +
                                      (props.fancadeNumber - 1) +
                                      "].fancadeTechnicalProject.modulesSurface",
                                    label:
                                      t(
                                        "formLabels.details.fancadeProject.modulesSurface"
                                      ) + " (m²)",
                                    placeholder:
                                      t(
                                        "formPlaceholders.details.fancadeProject.modulesSurface"
                                      ) + " (m²)",
                                    type: "number",
                                    width: 30,
                                  },
                                  {
                                    name:
                                      "fancades[" +
                                      (props.fancadeNumber - 1) +
                                      "].fancadeTechnicalProject.opaqueAnchoringsNumber",
                                    label:
                                      t(
                                        "formLabels.details.fancadeProject.opaqueAnchoringsNumber"
                                      ) + " (u)",
                                    placeholder:
                                      t(
                                        "formPlaceholders.details.fancadeProject.opaqueAnchoringsNumber"
                                      ) + " (u)",
                                    type: "number",
                                    width: 35,
                                  },
                                  {
                                    name:
                                      "fancades[" +
                                      (props.fancadeNumber - 1) +
                                      "].fancadeTechnicalProject.opaqueFixingsNumber",
                                    label:
                                      t(
                                        "formLabels.details.fancadeProject.opaqueFixingsNumber"
                                      ) + " (u)",
                                    placeholder:
                                      t(
                                        "formPlaceholders.details.fancadeProject.opaqueFixingsNumber"
                                      ) + " (u)",
                                    type: "number",
                                    width: 35,
                                  },
                                ]}
                              />
                            </>
                          )}
                        </>
                      )}
                      <Divider orientation="horizontal" />
                    </>
                  )}
              </Stack>
            </StyledFlexBody>
          </Stack>
        ) : isOpaque == 0 ? (
          /* Involucro Trasparente  */
          <Stack spacing={0}>
            <StyledFlexHeader>
              <Text fontSize={"xl"} fontWeight={"medium"}>
                {t("formLabels.details.fancadeProject.transparentShell")}
              </Text>
            </StyledFlexHeader>
            <StyledFlexBody>
              <Stack w={"100%"}>
                <FormRow
                  fields={[
                    {
                      name:
                        "fancades[" +
                        (props.fancadeNumber - 1) +
                        "].fancadeTechnicalProject.glazedCellsNumber",
                      label:
                        t(
                          "formLabels.details.fancadeProject.glazedCellsNumber"
                        ) + " (u)",
                      placeholder:
                        t(
                          "formPlaceholders.details.fancadeProject.glazedCellsNumber"
                        ) + " (u)",
                      type: "number",
                      width: 100,
                    },
                    {
                      name:
                        "fancades[" +
                        (props.fancadeNumber - 1) +
                        "].fancadeTechnicalProject.glazedCellsSurface",
                      label:
                        t(
                          "formLabels.details.fancadeProject.glazedCellsSurface"
                        ) + " (m²)",
                      placeholder:
                        t(
                          "formPlaceholders.details.fancadeProject.glazedCellsSurface"
                        ) + " (m²)",
                      type: "number",
                      width: 100,
                    },
                  ]}
                />
                <FormRow
                  fields={[
                    {
                      name:
                        "fancades[" +
                        (props.fancadeNumber - 1) +
                        "].fancadeTechnicalProject.doubleGlazingQuantity",
                      label:
                        t(
                          "formLabels.details.fancadeProject.doubleGlazingQuantity"
                        ) + " (u)",
                      placeholder:
                        t(
                          "formPlaceholders.details.fancadeProject.doubleGlazingQuantity"
                        ) + " (u)",
                      type: "number",
                      width: 100,
                    },
                    {
                      name:
                        "fancades[" +
                        (props.fancadeNumber - 1) +
                        "].fancadeTechnicalProject.doubleGlazingSurface",
                      label:
                        t(
                          "formLabels.details.fancadeProject.doubleGlazingSurface"
                        ) + " (m²)",
                      placeholder:
                        t(
                          "formPlaceholders.details.fancadeProject.doubleGlazingSurface"
                        ) + " (m²)",
                      type: "number",
                      width: 100,
                    },
                  ]}
                />
                <FormRow
                  fields={[
                    {
                      name:
                        "fancades[" +
                        (props.fancadeNumber - 1) +
                        "].fancadeTechnicalProject.mainFrameHeightPerUnit",
                      label:
                        t(
                          "formLabels.details.fancadeProject.mainFrameHeightPerUnit"
                        ) + " (m/u)",
                      placeholder:
                        t(
                          "formPlaceholders.details.fancadeProject.mainFrameHeightPerUnit"
                        ) + " (m/u)",
                      type: "number",
                      width: 100,
                    },
                    {
                      name:
                        "fancades[" +
                        (props.fancadeNumber - 1) +
                        "].fancadeTechnicalProject.mainFrameWidthPerUnit",
                      label:
                        t(
                          "formLabels.details.fancadeProject.mainFrameWidthPerUnit"
                        ) + " (m/u)",
                      placeholder:
                        t(
                          "formPlaceholders.details.fancadeProject.mainFrameWidthPerUnit"
                        ) + " (m/u)",
                      type: "number",
                      width: 100,
                    },
                  ]}
                />
                <FormRow
                  fields={[
                    {
                      name:
                        "fancades[" +
                        (props.fancadeNumber - 1) +
                        "].fancadeTechnicalProject.totalMainFrameLength",
                      label:
                        t(
                          "formLabels.details.fancadeProject.totalMainFrameLength"
                        ) + " (m)",
                      placeholder:
                        t(
                          "formPlaceholders.details.fancadeProject.totalMainFrameLength"
                        ) + " (m)",
                      type: "number",
                      width: 100,
                      disabled: true,
                      value: calculateTotalMainFrameLength(
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject.mainFrameHeightPerUnit,
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject.mainFrameWidthPerUnit,
                        props.formikProps.values.fancades[
                          props.fancadeNumber - 1
                        ].fancadeTechnicalProject.doubleGlazingQuantity
                      ),
                    },
                    {
                      name:
                        "fancades[" +
                        (props.fancadeNumber - 1) +
                        "].fancadeTechnicalProject.totalGasketLength",
                      label:
                        t(
                          "formLabels.details.fancadeProject.totalGasketLength"
                        ) + " (m)",
                      placeholder:
                        t(
                          "formPlaceholders.details.fancadeProject.totalGasketLength"
                        ) + " (m)",
                      type: "number",
                      width: 100,
                    },
                  ]}
                />
                {currentFancade &&
                  currentFancade.fancadeTechnicalProject &&
                  currentFancade.fancadeTechnicalProject.type &&
                  currentFancade.fancadeTechnicalProject.type.toLowerCase() !=
                    "trasparente - a cellule senza pannello spandrel opaco" && (
                    <FormRow
                      fields={[
                        {
                          name:
                            "fancades[" +
                            (props.fancadeNumber - 1) +
                            "].fancadeTechnicalProject.opaqueSpandrelPanelsQuantity",
                          label:
                            t(
                              "formLabels.details.fancadeProject.opaqueSpandrelPanelsQuantity"
                            ) + " (u)",
                          placeholder:
                            t(
                              "formPlaceholders.details.fancadeProject.opaqueSpandrelPanelsQuantity"
                            ) + " (u)",
                          type: "number",
                          width: 100,
                        },
                        {
                          name:
                            "fancades[" +
                            (props.fancadeNumber - 1) +
                            "].fancadeTechnicalProject.opaqueSpandrelPanelsSurface",
                          label:
                            t(
                              "formLabels.details.fancadeProject.opaqueSpandrelPanelsSurface"
                            ) + " (m)",
                          placeholder:
                            t(
                              "formPlaceholders.details.fancadeProject.opaqueSpandrelPanelsSurface"
                            ) + " (m)",
                          type: "number",
                          width: 100,
                        },
                      ]}
                    />
                  )}

                <FormRow
                  fields={[
                    {
                      name:
                        "fancades[" +
                        (props.fancadeNumber - 1) +
                        "].fancadeTechnicalProject.transparentAnchoringsNumber",
                      label:
                        t(
                          "formLabels.details.fancadeProject.transparentAnchoringsNumber"
                        ) + " (u)",
                      placeholder:
                        t(
                          "formPlaceholders.details.fancadeProject.transparentAnchoringsNumber"
                        ) + " (u)",
                      type: "number",
                      width: 100,
                    },
                    {
                      name:
                        "fancades[" +
                        (props.fancadeNumber - 1) +
                        "].fancadeTechnicalProject.transparentFixingsNumber",
                      label:
                        t(
                          "formLabels.details.fancadeProject.transparentFixingsNumber"
                        ) + " (u)",
                      placeholder:
                        t(
                          "formPlaceholders.details.fancadeProject.transparentFixingsNumber"
                        ) + " (u)",
                      type: "number",
                      width: 100,
                    },
                  ]}
                />
              </Stack>
            </StyledFlexBody>
          </Stack>
        ) : (
          <></>
        )}

        <Text
          fontSize={"2xl"}
          color={theme.colors.darkGrey2}
          paddingTop={"2rem"}
        >
          {t("formLabels.details.fancadeProject.technicalParameters")}
        </Text>
        <Stack w={"100%"}>
          <FormRow
            fields={[
              {
                name:
                  "fancades[" +
                  (props.fancadeNumber - 1) +
                  "].fancadeTechnicalProject.windowOpaqueSurfaceRatio",
                label:
                  t(
                    "formLabels.details.fancadeProject.windowOpaqueSurfaceRatio"
                  ) + " (%)",
                placeholder:
                  t(
                    "formPlaceholders.details.fancadeProject.windowOpaqueSurfaceRatio"
                  ) + " (%)",
                type: "number",
                width: 100,
                disabled: true,
                value:
                  calculateWindowOpaqueSurfaceRatio(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      ?.fancadeTechnicalProject?.surface,
                    windowsSurfaces
                  ).toLocaleString("cs-CZ", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }) + "%",
              },
            ]}
          />
        </Stack>

        <FormRow
          fields={[
            {
              name:
                "fancades[" +
                (props.fancadeNumber - 1) +
                "].fancadeTechnicalProject.opaqueUValue",
              label:
                t("formLabels.details.fancadeProject.opaqueUValue") +
                " (W/m²K)",
              placeholder:
                t("formPlaceholders.details.fancadeProject.opaqueUValue") +
                " (W/m²K)",
              type: "number",
              width: 50,
            },
            {
              name:
                "fancades[" +
                (props.fancadeNumber - 1) +
                "].fancadeTechnicalProject.transparentUValue",
              label:
                t("formLabels.details.fancadeProject.transparentUValue") +
                " (W/m²K)",
              placeholder:
                t("formPlaceholders.details.fancadeProject.transparentUValue") +
                " (W/m²K)",
              type: "number",
              width: 50,
            },
          ]}
        />
        <FormRow
          fields={[
            {
              name:
                "fancades[" +
                (props.fancadeNumber - 1) +
                "].fancadeTechnicalProject.windowGlassGValue",
              label:
                t("formLabels.details.fancadeProject.windowGlassGValue") +
                " (W/m²K)",
              placeholder:
                t("formPlaceholders.details.fancadeProject.windowGlassGValue") +
                " (W/m²K)",
              type: "number",
              width: 50,
            },
            {
              name:
                "fancades[" +
                (props.fancadeNumber - 1) +
                "].fancadeTechnicalProject.fancadeGlassGValue",
              label:
                t("formLabels.details.fancadeProject.fancadeGlassGValue") +
                " (W/m²K)",
              placeholder:
                t(
                  "formPlaceholders.details.fancadeProject.fancadeGlassGValue"
                ) + " (W/m²K)",
              type: "number",
              width: 50,
            },
          ]}
        />
        <Stack paddingTop={"2rem"}>
          {/* <Text fontSize={"2xl"} color={theme.colors.darkGrey2}>
            {t("formLabels.details.fancadeProject.customField")}
          </Text>
          {currentFancade?.fancadeTechnicalProject?.customFields?.map(
            (field, index) => (
              <Flex alignItems={"center"}>
                <FormRow
                  width={"50%"}
                  fields={[
                    {
                      name:
                        "fancades[" +
                        (props.fancadeNumber - 1) +
                        "].fancadeTechnicalProject.customFields[" +
                        index +
                        "].value",
                      label: field.name,
                      placeholder: field.name,
                      type: "text",
                      width: 100,
                    },
                  ]}
                />
                <CloseIcon
                  color={theme.colors.euracOrange}
                  w={"1rem"}
                  onClick={() =>
                    deleteNewField(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        ?.fancadeTechnicalProject,
                      index
                    )
                  }
                />
              </Flex>
            )
          )}
          <Center>
            <StyledButton onClick={onOpen}>
              {t("buttons.addNewCustomField")}
            </StyledButton>
          </Center> */}
          <Text fontSize={"2xl"} color={theme.colors.darkGrey2}>
            {t("formLabels.details.fancadeProject.plantIntegration")}
          </Text>
          <FormRow
            fields={[
              {
                name:
                  "fancades[" +
                  (props.fancadeNumber - 1) +
                  "].fancadeTechnicalProject.shieldingSystemsNumber",
                label: t(
                  "formLabels.details.fancadeProject.shieldingSystemsNumber"
                ),
                placeholder: t(
                  "formPlaceholders.details.fancadeProject.shieldingSystemsNumber"
                ),
                type: "number",
                width: 100,
              },
              {
                name:
                  "fancades[" +
                  (props.fancadeNumber - 1) +
                  "].fancadeTechnicalProject.ventilationDevicesNumber",
                label: t(
                  "formLabels.details.fancadeProject.ventilationDevicesNumber"
                ),
                placeholder: t(
                  "formPlaceholders.details.fancadeProject.ventilationDevicesNumber"
                ),
                type: "number",
                width: 100,
              },
              {
                name:
                  "fancades[" +
                  (props.fancadeNumber - 1) +
                  "].fancadeTechnicalProject.airConditioningDevicesNumber",
                label: t(
                  "formLabels.details.fancadeProject.airConditioningDevicesNumber"
                ),
                placeholder: t(
                  "formPlaceholders.details.fancadeProject.airConditioningDevicesNumber"
                ),
                type: "number",
                width: 100,
              },
            ]}
          />
          <FormRow
            fields={[
              {
                name:
                  "fancades[" +
                  (props.fancadeNumber - 1) +
                  "].fancadeTechnicalProject.thermicSolarPanelsNumber",
                label: t(
                  "formLabels.details.fancadeProject.thermicSolarPanelsNumber"
                ),
                placeholder: t(
                  "formPlaceholders.details.fancadeProject.thermicSolarPanelsNumber"
                ),
                type: "number",
                width: 100,
                onBlur: (e: any) => {
                  const value =
                    +props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject.thermicSolarPanelsUnitSurface *
                    +e.target.value;

                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].fancadeTechnicalProject.thermicSolarPanelsSurface = value;

                  setUpdate(!update);
                  if (
                    currentFancade &&
                    currentFancade.fancadeTechnicalProject &&
                    currentFancade.fancadeTechnicalProject.surface
                  ) {
                    if (
                      value > currentFancade.fancadeTechnicalProject.surface
                    ) {
                      setSurfaceAlertIsOpen(true);
                    }
                  }
                },
              },
              {
                name:
                  "fancades[" +
                  (props.fancadeNumber - 1) +
                  "].fancadeTechnicalProject.thermicSolarPanelsUnitSurface",
                label: t(
                  "formLabels.details.fancadeProject.thermicSolarPanelsUnitSurface"
                ),
                placeholder: t(
                  "formPlaceholders.details.fancadeProject.thermicSolarPanelsUnitSurface"
                ),
                type: "number",
                width: 100,
                onBlur: (e: any) => {
                  const value =
                    +e.target.value *
                    +props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject.thermicSolarPanelsNumber;

                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].fancadeTechnicalProject.thermicSolarPanelsSurface = value;

                  setUpdate(!update);
                  if (
                    currentFancade &&
                    currentFancade.fancadeTechnicalProject &&
                    currentFancade.fancadeTechnicalProject.surface
                  ) {
                    if (
                      value > currentFancade.fancadeTechnicalProject.surface
                    ) {
                      setSurfaceAlertIsOpen(true);
                    }
                  }
                },
              },
              {
                name:
                  "fancades[" +
                  (props.fancadeNumber - 1) +
                  "].fancadeTechnicalProject.thermicSolarPanelsSurface",
                label: t(
                  "formLabels.details.fancadeProject.thermicSolarPanelsSurface"
                ),
                placeholder: t(
                  "formPlaceholders.details.fancadeProject.thermicSolarPanelsSurface"
                ),
                type: "number",
                width: 100,
                disabled: true,
                value:
                  +props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject.thermicSolarPanelsUnitSurface *
                  +props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject.thermicSolarPanelsNumber,
              },
            ]}
          />
          <FormRow
            fields={[
              {
                name:
                  "fancades[" +
                  (props.fancadeNumber - 1) +
                  "].fancadeTechnicalProject.photovoltaicModulesNumber",
                label: t(
                  "formLabels.details.fancadeProject.photovoltaicModulesNumber"
                ),
                placeholder: t(
                  "formPlaceholders.details.fancadeProject.photovoltaicModulesNumber"
                ),
                type: "number",
                width: 100,
                onBlur: (e: any) => {
                  const value =
                    +props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject.photovoltaicModulesUnitSurface *
                    +e.target.value;

                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].fancadeTechnicalProject.photovoltaicModulesSurface = value;

                  setUpdate(!update);
                  if (
                    currentFancade &&
                    currentFancade.fancadeTechnicalProject &&
                    currentFancade.fancadeTechnicalProject.surface
                  ) {
                    if (
                      value > currentFancade.fancadeTechnicalProject.surface
                    ) {
                      setSurfaceAlertIsOpen(true);
                    }
                  }
                },
              },
              {
                name:
                  "fancades[" +
                  (props.fancadeNumber - 1) +
                  "].fancadeTechnicalProject.photovoltaicModulesUnitSurface",
                label: t(
                  "formLabels.details.fancadeProject.photovoltaicModulesUnitSurface"
                ),
                placeholder: t(
                  "formPlaceholders.details.fancadeProject.photovoltaicModulesUnitSurface"
                ),
                type: "number",
                width: 100,
                onBlur: (e: any) => {
                  const value =
                    +e.target.value *
                    +props.formikProps.values.fancades[props.fancadeNumber - 1]
                      .fancadeTechnicalProject.photovoltaicModulesNumber;

                  props.formikProps.values.fancades[
                    props.fancadeNumber - 1
                  ].fancadeTechnicalProject.photovoltaicModulesSurface = value;

                  setUpdate(!update);
                  if (
                    currentFancade &&
                    currentFancade.fancadeTechnicalProject &&
                    currentFancade.fancadeTechnicalProject.surface
                  ) {
                    if (
                      value > currentFancade.fancadeTechnicalProject.surface
                    ) {
                      setSurfaceAlertIsOpen(true);
                    }
                  }
                },
              },
              {
                name:
                  "fancades[" +
                  (props.fancadeNumber - 1) +
                  "].fancadeTechnicalProject.photovoltaicModulesSurface",
                label: t(
                  "formLabels.details.fancadeProject.photovoltaicModulesSurface"
                ),
                placeholder: t(
                  "formPlaceholders.details.fancadeProject.photovoltaicModulesSurface"
                ),
                type: "number",
                width: 100,
                disabled: true,
                value:
                  +props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject.photovoltaicModulesUnitSurface *
                  +props.formikProps.values.fancades[props.fancadeNumber - 1]
                    .fancadeTechnicalProject.photovoltaicModulesNumber,
              },
            ]}
          />

          {false && <FormRow
            fields={[
              {
                name:
                  "fancades[" +
                  (props.fancadeNumber - 1) +
                  "].fancadeTechnicalProject.prefabricationLevel",
                label: t(
                  "formLabels.details.fancadeProject.prefabricationLevel"
                ),
                placeholder: t(
                  "formPlaceholders.details.fancadeProject.prefabricationLevel"
                ),
                type: "select",
                width: 35,
                selectOptions: prefabricationLevelsOptions,
              },
            ]}
          />}
          {
          // props.formikProps.values.fancades[props.fancadeNumber - 1]
          //   ?.fancadeTechnicalProject?.prefabricationLevel ==
          //   "Basso - 80% dell´assemblaggio eseguito in cantiere" ||
          // !props.formikProps.values.fancades[props.fancadeNumber - 1]
          //   ?.fancadeTechnicalProject?.prefabricationLevel ? 
          true?(
            <></>
          ) : (
            <>
              {currentFancade?.fancadeTechnicalProject?.geometricDetails?.map(
                (detail: GeometricDetail, index) => (
                  <>
                    <Divider orientation="horizontal" />
                    <Flex w={"100%"} alignItems={"center"}>
                      <Text fontSize={"2xl"} color={theme.colors.darkGrey2}>
                        {t(
                          "formLabels.details.fancadeProject.geometricDetails.module"
                        )}{" "}
                        #{index + 1}
                      </Text>
                      <CloseIcon
                        color={theme.colors.euracOrange}
                        w={"0.7rem"}
                        marginLeft={"0.5rem"}
                        marginTop={"0.5rem"}
                        onClick={() =>
                          deleteGeometricDetail(
                            props.formikProps.values.fancades[
                              props.fancadeNumber - 1
                            ]?.fancadeTechnicalProject,
                            index
                          )
                        }
                      />
                    </Flex>
                    <FormRow
                      fields={[
                        {
                          name:
                            "fancades[" +
                            (props.fancadeNumber - 1) +
                            "].fancadeTechnicalProject.geometricDetails[" +
                            index +
                            "].width",
                          label: t(
                            "formLabels.details.fancadeProject.geometricDetails.width"
                          ),
                          placeholder: t(
                            "formPlaceholders.details.fancadeProject.geometricDetails.width"
                          ),
                          type: "number",
                          width: 100,
                        },
                        {
                          name:
                            "fancades[" +
                            (props.fancadeNumber - 1) +
                            "].fancadeTechnicalProject.geometricDetails[" +
                            index +
                            "].length",
                          label: t(
                            "formLabels.details.fancadeProject.geometricDetails.length"
                          ),
                          placeholder: t(
                            "formPlaceholders.details.fancadeProject.geometricDetails.length"
                          ),
                          type: "number",
                          width: 100,
                        },
                        {
                          name:
                            "fancades[" +
                            (props.fancadeNumber - 1) +
                            "].fancadeTechnicalProject.geometricDetails[" +
                            index +
                            "].depth",
                          label: t(
                            "formLabels.details.fancadeProject.geometricDetails.depth"
                          ),
                          placeholder: t(
                            "formPlaceholders.details.fancadeProject.geometricDetails.depth"
                          ),
                          type: "number",
                          width: 100,
                        },
                      ]}
                    />
                  </>
                )
              )}
              <Center paddingTop={"1rem"}>
                <StyledButton
                  onClick={() =>
                    addNewGeometricDetail(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        ?.fancadeTechnicalProject
                    )
                  }
                >
                  {t("buttons.addDetail")}
                </StyledButton>
              </Center>
              <Divider orientation="horizontal" />
            </>
          )}

          <FormRow
            fields={[
              {
                name:
                  "fancades[" +
                  (props.fancadeNumber - 1) +
                  "].fancadeTechnicalProject.systemsIntegration",
                label: t(
                  "formLabels.details.fancadeProject.systemsIntegration.commonName"
                ),
                placeholder: t(
                  "formPlaceholders.details.fancadeProject.systemsIntegration.commonName"
                ),
                type: "select",
                width: 40,
                selectOptions: [
                  {
                    value: 1,
                    text: t("yes"),
                  },
                  {
                    value: 0,
                    text: t("no"),
                  },
                ],
              },
            ]}
          />
          {props.formikProps.values.fancades[props.fancadeNumber - 1]
            ?.fancadeTechnicalProject?.systemsIntegration == 1 ? (
            <>
              <Divider orientation="horizontal" />

              {currentFancade?.fancadeTechnicalProject &&
                currentFancade?.fancadeTechnicalProject?.systemsIntegrations?.map(
                  (systemsIntegration: SystemsIntegration, index) => (
                    <>
                      <Flex w={"100%"} alignItems={"center"}>
                        <Text fontSize={"2xl"} color={theme.colors.darkGrey2}>
                          {t(
                            "formLabels.details.fancadeProject.systemsIntegration.commonName"
                          )}{" "}
                          #{index + 1}
                        </Text>
                        <CloseIcon
                          color={theme.colors.euracOrange}
                          w={"0.7rem"}
                          marginLeft={"0.5rem"}
                          marginTop={"0.5rem"}
                          onClick={() =>
                            deleteSystemsIntegration(
                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ]?.fancadeTechnicalProject,
                              index
                            )
                          }
                        />
                      </Flex>
                      <FormRow
                        fields={[
                          {
                            name:
                              "fancades[" +
                              (props.fancadeNumber - 1) +
                              "].fancadeTechnicalProject.systemsIntegrations[" +
                              index +
                              "].name",
                            label: t(
                              "formLabels.details.fancadeProject.systemsIntegration.name"
                            ),
                            placeholder: t(
                              "formPlaceholders.details.fancadeProject.systemsIntegration.name"
                            ),
                            type: "text",
                            width: 100,
                          },
                          {
                            name:
                              "fancades[" +
                              (props.fancadeNumber - 1) +
                              "].fancadeTechnicalProject.systemsIntegrations[" +
                              index +
                              "].units",
                            label: t(
                              "formLabels.details.fancadeProject.systemsIntegration.units"
                            ),
                            placeholder: t(
                              "formPlaceholders.details.fancadeProject.systemsIntegration.units"
                            ),
                            type: "number",
                            width: 100,
                            onBlur: (e: any) => {
                              const value =
                                +e.target.value *
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].fancadeTechnicalProject.systemsIntegrations[
                                  index
                                ].unitSurface;

                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject.systemsIntegrations[
                                index
                              ].totalSurface = value;

                              setUpdate(!update);
                              if (
                                currentFancade &&
                                currentFancade.fancadeTechnicalProject &&
                                currentFancade.fancadeTechnicalProject.surface
                              ) {
                                if (
                                  value >
                                  currentFancade.fancadeTechnicalProject.surface
                                ) {
                                  setSurfaceAlertIsOpen(true);
                                }
                              }
                            },
                          },
                          {
                            name:
                              "fancades[" +
                              (props.fancadeNumber - 1) +
                              "].fancadeTechnicalProject.systemsIntegrations[" +
                              index +
                              "].unitSurface",
                            label: t(
                              "formLabels.details.fancadeProject.systemsIntegration.unitSurface"
                            ),
                            placeholder: t(
                              "formPlaceholders.details.fancadeProject.systemsIntegration.unitSurface"
                            ),
                            type: "number",
                            width: 100,
                            onBlur: (e: any) => {
                              const value =
                                +props.formikProps.values.fancades[
                                  props.fancadeNumber - 1
                                ].fancadeTechnicalProject.systemsIntegrations[
                                  index
                                ].units * +e.target.value;

                              props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject.systemsIntegrations[
                                index
                              ].totalSurface = value;

                              setUpdate(!update);
                              if (
                                currentFancade &&
                                currentFancade.fancadeTechnicalProject &&
                                currentFancade.fancadeTechnicalProject.surface
                              ) {
                                if (
                                  value >
                                  currentFancade.fancadeTechnicalProject.surface
                                ) {
                                  setSurfaceAlertIsOpen(true);
                                }
                              }
                            },
                          },
                          {
                            name:
                              "fancades[" +
                              (props.fancadeNumber - 1) +
                              "].fancadeTechnicalProject.systemsIntegrations[" +
                              index +
                              "].totalSurface",
                            label: t(
                              "formLabels.details.fancadeProject.systemsIntegration.totalSurface"
                            ),
                            placeholder: t(
                              "formPlaceholders.details.fancadeProject.systemsIntegration.totalSurface"
                            ),
                            type: "number",
                            width: 100,
                            disabled: true,
                            value:
                              +props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject.systemsIntegrations[
                                index
                              ].units *
                              +props.formikProps.values.fancades[
                                props.fancadeNumber - 1
                              ].fancadeTechnicalProject.systemsIntegrations[
                                index
                              ].unitSurface,
                          },
                        ]}
                      />
                    </>
                  )
                )}
              <Center paddingTop={"1rem"}>
                <StyledButton
                  onClick={() =>
                    addNewSystemsIntegration(
                      props.formikProps.values.fancades[props.fancadeNumber - 1]
                        ?.fancadeTechnicalProject
                    )
                  }
                >
                  {t("buttons.addSystemsIntegration")}
                </StyledButton>
              </Center>
              <Divider orientation="horizontal" />
            </>
          ) : (
            <></>
          )}
          <FormRow
            fields={[
              {
                name:
                  "fancades[" +
                  (props.fancadeNumber - 1) +
                  "].fancadeTechnicalProject.systemsSurfacePercentage",
                label: t(
                  "formLabels.details.fancadeProject.systemsSurfacePercentage"
                ),
                placeholder: t(
                  "formPlaceholders.details.fancadeProject.systemsSurfacePercentage"
                ),
                type: "number",
                width: 100,
                disabled: true,
                value:
                  calculateSystemPercentage(
                    props.formikProps.values.fancades[props.fancadeNumber - 1]
                      ?.fancadeTechnicalProject.systemsIntegrations
                  ).toLocaleString("cs-CZ", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }) + "%",
              },
            ]}
          />
        </Stack>
      </Stack>
    );
  else return <Redirect to={"/"} />;
});

const StyledFlexHeader = styled(Flex)`
  border: 2px solid ${theme.colors.darkGrey};
  background-color: ${theme.colors.lightGrey2};
  color: ${theme.colors.euracOrange};
  padding: 0.8em;
`;

const StyledFlexBody = styled(Flex)`
  border: 2px solid ${theme.colors.darkGrey};
  border-top: 0px;
  padding: 0.8em;
`;

const StyledButton = styled(Button)`
  color: white;
  background-color: ${theme.colors.euracOrange};
  border-radius: 500rem;

  &:hover {
    background-color: ${transparentize(0.5, theme.colors.euracOrange)};
  }
`;

const StyledButtonGrey = styled(Button)`
  color: white;
  background-color: ${theme.colors.lightGrey};
  border-radius: 500rem;

  &:hover {
    background-color: ${transparentize(0.5, theme.colors.lightGrey)};
  }
`;
