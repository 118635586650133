import React, { useEffect } from "react";
import { Flex } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../hooks/useStores";

import styled from "styled-components";

import { Route } from "react-router-dom";

import { List } from "../../components/projects/list/List";
import { Summary } from "../../components/projects/summary/Summary";
import { Details } from "../../components/projects/summary/details/Details";
import { PlotsSummary } from "../../components/projects/summary/PlotsSummary";

export const Projects: React.FC = observer(() => {
  const { t } = useTranslation();
  const { session, ui, projects } = useStores();

  ui.setActiveScreen("projects");

  useEffect(() => {
    projects.fetchProjects();
    projects.fetchCategories();
  }, []);

  return (
    <>
      <Route path={"/user/:uid/projects/"} component={List} exact />
      <Route
        path={"/user/:uid/projects/:projectId/summary"}
        component={Summary}
        exact
      />
      <Route
        path={"/user/:uid/projects/:projectId/plots-summary"}
        component={PlotsSummary}
        exact
      />
      <Route
        path={"/user/:uid/projects/:projectId/details"}
        component={Details}
        exact
      />
    </>
  );
});
