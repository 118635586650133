import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../hooks/useStores";

import { Redirect, Route } from "react-router-dom";
import { UserList } from "./UserList";

export const Users: React.FC = observer(() => {
  const { t } = useTranslation();
  const { ui, session } = useStores();

  ui.setActiveScreen("users");
  if (session.user?.role != "admin") {
    return <Redirect to={"/"} />;
  }

  return (
    <>
      <Route path={"/user/:uid/users/"} component={UserList} exact />
    </>
  );
});
