import React from "react";
import ReactDOM from "react-dom";
import {
  Box,
  Button,
  Center,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../../hooks/useStores";
import { Link, Redirect, useParams } from "react-router-dom";
import { ReportTables } from "./ReportTables";

interface RouteParams {
  projectId: string;
}

type Props = {
  isBenchmark: boolean;
};

export const Report: React.FC<Props> = observer((props: Props) => {
  const { t } = useTranslation();
  const { session, ui } = useStores();
  const { projectId } = useParams<RouteParams>();

  ui.setActiveScreen("projects");

  if (session.user)
    return (
      <Stack w={"100%"}>
        <ReportTables
          projectId={parseInt(projectId)}
          isBenchmark={props.isBenchmark}
        />
      </Stack>
    );
  else return <Redirect to={"/"} />;
});
